import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiskCategory } from '../../api';

/**
 * Provide localized labels for the RiskCategory enum
 */
export function useRiskCategoryLabels(): Record<RiskCategory, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<RiskCategory, string> => ({
      Client: t('enumLabel.riskCategory.client'),
      Geographic: t('enumLabel.riskCategory.geographic'),
      Product: t('enumLabel.riskCategory.product'),
      Regulatory: t('enumLabel.riskCategory.regulatory'),
      Reputational: t('enumLabel.riskCategory.reputational'),
      Other: t('enumLabel.riskCategory.other'),
    }),
    [t]
  );
}
