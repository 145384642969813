import { forwardRef } from 'react';
import { CorporateIdType } from '../../../api';
import { useCorporateIdTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type CorporateIdTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a CorporateIdType
 */
export const CorporateIdTypeField = forwardRef(function CorporateIdTypeField(
  props: CorporateIdTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    CorporateIdType,
    useCorporateIdTypeLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
