import { useFieldMeta } from '@johnrom/formik-v3';
import { MenuItem } from '@mui/material';
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  AdminApiQueryKey,
  ThirdPartiesApiQueryKey,
  getThirdPartyId,
  isResourceNotFoundError,
  makeGetAllClientThirdPartySummariesFilterArgs,
  makeGetThirdPartySummariesFilterArgs,
  ThirdPartyShortSummary,
  useAdminApi,
  useThirdPartiesApi,
  GetThirdPartiesFilters,
} from '../../api';
import { useJsonMemo } from '../../utils/useJsonMemo';
import { useOpenState } from '../../utils/useOpenState';
import { useOptionLabel, useOptions, useRenderOption } from '../utils';
import {
  DynamicComboBoxField,
  DynamicComboBoxFieldProps,
  useComboBoxApiQuery,
  useInputTextState,
  useOptionsWithCurrent,
} from './internal/DynamicComboBox';

export type ThirdPartyFieldProps = Omit<
  DynamicComboBoxFieldProps,
  | 'options'
  | 'getOptionLabel'
  | 'renderOption'
  | 'getOptionDisabled'
  | 'open'
  | 'onOpen'
  | 'onClose'
  | 'onInputChange'
  | 'loading'
> & {
  /**
   * Client to use third parties from (for admin use)
   */
  clientId?: string | undefined | null;
  /**
   * Filters to use when listing third parties
   */
  filters?: Omit<GetThirdPartiesFilters, 'searchText'>;
};

interface ThirdPartyShortSummaryValue extends ThirdPartyShortSummary {
  notFound?: boolean;
  loading?: boolean;
}

export const ThirdPartyField = forwardRef(function ThirdPartyField(
  props: ThirdPartyFieldProps,
  ref: Ref<any>
) {
  const { clientId, filters: filtersProp, ...other } = props;
  const filters = useJsonMemo(filtersProp);
  const { open, openProps } = useOpenState();
  const [inputText, onInputChange] = useInputTextState();
  const searchText = inputText || undefined;
  const { value } = useFieldMeta<string | null>(other.name); // @fixme Combobox should be a hook so we can access the value

  const queryClient = useQueryClient();
  const adminApi = useAdminApi();
  const thirdPartiesApi = useThirdPartiesApi();

  const {
    oneData: oneThirdParty,
    oneError: oneThirdPartyError,
    allData: allThirdParties,
    loadingOne: loadingOneThirdParty,
    loading,
    hasNextPage,
  } = useComboBoxApiQuery<ThirdPartyShortSummary>({
    value,
    open,
    searchText,
    getOneByValue: (value) =>
      clientId
        ? adminApi.getClientThirdPartySummaryById(clientId, value)
        : thirdPartiesApi.getThirdPartyById(value),
    searchAllByValue: (searchText) =>
      clientId
        ? adminApi.getAllClientThirdPartySummaries(
            clientId,
            ...makeGetAllClientThirdPartySummariesFilterArgs({
              ...filters,
              searchText,
            })
          )
        : thirdPartiesApi.getThirdPartySummaries(
            ...makeGetThirdPartySummariesFilterArgs({ ...filters, searchText })
          ),
    preCacheOneItem: (thirdParty) => {
      if (thirdParty.Id) {
        queryClient.setQueryData(
          ThirdPartiesApiQueryKey.getThirdPartyById(thirdParty.Id),
          thirdParty
        );
      }
      if (clientId && thirdParty.Id) {
        queryClient.setQueryData(
          AdminApiQueryKey.getClientThirdPartySummaryById(
            clientId,
            thirdParty.Id
          ),
          thirdParty
        );
      }
    },
  });

  const selectedThirdParty = useMemo<ThirdPartyShortSummaryValue | null>(() => {
    if (oneThirdParty) return oneThirdParty;

    if (value) {
      return {
        Id: value,
        FirstName: value,
        notFound: isResourceNotFoundError(oneThirdPartyError),
        loading: loadingOneThirdParty,
      };
    }

    return null;
  }, [loadingOneThirdParty, oneThirdParty, oneThirdPartyError, value]);

  const getId = getThirdPartyId;
  const thirdParties = useOptionsWithCurrent(
    allThirdParties,
    selectedThirdParty,
    getId
  );
  const options = useOptions(thirdParties, getId);
  const getLabelForThirdParty = useCallback(
    (thirdParty: ThirdPartyShortSummary) => thirdParty.Name ?? '',
    []
  );
  const getOptionLabel = useOptionLabel(
    thirdParties,
    getId,
    getLabelForThirdParty
  );
  const renderThirdPartyOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      thirdParty: ThirdPartyShortSummary
    ) => <MenuItem {...props}>{getLabelForThirdParty(thirdParty)}</MenuItem>,
    [getLabelForThirdParty]
  );
  const renderOption = useRenderOption(
    thirdParties,
    getId,
    renderThirdPartyOption
  );

  let inputPrefix: ReactNode;
  if (selectedThirdParty?.notFound) {
    inputPrefix = '❌';
  } else if (selectedThirdParty?.loading) {
    inputPrefix = '⋯';
  }

  return (
    <DynamicComboBoxField
      ref={ref}
      {...other}
      {...{
        options,
        loading,
        hasNextPage,
        getOptionLabel,
        renderOption,
        onInputChange,
        inputPrefix,
      }}
      {...openProps}
    />
  );
});
