import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type SizeCodeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a size code
 */
export const SizeCodeField = forwardRef(function SizeCodeField(
  props: SizeCodeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [sizes, loadingSizes] = useCodes('sizes');
  const optionProps = useCodeOptionsProps(sizes);

  return (
    <SelectField ref={ref} {...other} {...optionProps} loading={loadingSizes} />
  );
});
