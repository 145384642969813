import { forwardRef } from 'react';
import { PersonalIdType } from '../../../api';
import { usePersonalIdTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type PersonalIdTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a PersonalIdType
 */
export const PersonalIdTypeField = forwardRef(function PersonalIdTypeField(
  props: PersonalIdTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    PersonalIdType,
    usePersonalIdTypeLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
