import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import { AppleKeyboardCaps as AppleKeyboardCapsIcon } from 'mdi-material-ui';
import { forwardRef, Ref, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCapsLockState } from '../../utils/capslock';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type PasswordFieldProps = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  CommonValidatorProps & { readOnly?: boolean };

/**
 * A <TextField> for a password
 */
export const PasswordField = forwardRef(function PasswordField(
  props: PasswordFieldProps,
  ref: Ref<HTMLInputElement>
) {
  props = usePropsWithId(props);
  const validate = useCommonValidate(props);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const capsLock = useCapsLockState();

  const textFieldProps = useTextFieldProps({
    ...props,
    validate,
    type: visible ? 'text' : 'password',
    InputProps: {
      endAdornment: (
        <>
          <InputAdornment position="end">
            {capsLock && (
              <Tooltip
                title={t('warning.capsLockIsOn') as string}
                disableInteractive
              >
                <Box
                  display="flex"
                  color="error.main"
                  aria-label={t('warning.capsLockIsOn') as string}
                >
                  <AppleKeyboardCapsIcon fontSize="small" color="inherit" />
                </Box>
              </Tooltip>
            )}
            <Tooltip
              title={String(
                visible ? t('button.hidePassword') : t('button.showPassword')
              )}
              disableInteractive
            >
              <IconButton
                aria-label={
                  visible ? t('button.hidePassword') : t('button.showPassword')
                }
                onClick={() => setVisible((visible) => !visible)}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                edge="end"
              >
                {visible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        </>
      ),
      ...props.InputProps,
    },
  });

  if (props.readOnly) return <ReadOnlyField ref={ref} {...textFieldProps} />;

  return <TextField ref={ref} {...textFieldProps} />;
});
