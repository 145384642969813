import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Attachment, AttachmentType } from '../../api';
import {
  NewDocumentDialog,
  NewDocumentDialogProps,
  NewDocumentValues,
} from './NewDocumentDialog';

export interface NewContractDocumentValues extends NewDocumentValues {}

export type NewContractDocumentDialogProps = Omit<
  NewDocumentDialogProps<NewContractDocumentValues>,
  'attachmentType' | 'initialValues' | 'onUpload' | 'children'
> & {
  onUpload?: (attachment: Attachment) => void | Promise<void>;
};

export const NewContractDocumentDialog = (
  props: NewContractDocumentDialogProps
) => {
  const { onUpload, ...other } = props;
  const { t } = useTranslation();

  const initialValues = useMemo<NewContractDocumentValues>(() => {
    return { file: null };
  }, []);

  const upload = useCallback(
    async (attachment: Attachment, values: NewContractDocumentValues) => {
      await onUpload?.(attachment);
    },
    [onUpload]
  );

  return (
    <NewDocumentDialog<NewContractDocumentValues>
      title={t('dialogTitle.uploadContractDocument')}
      {...other}
      attachmentType={AttachmentType.Contract}
      initialValues={initialValues}
      onUpload={upload}
    />
  );
};
