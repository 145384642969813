import { styled } from '@mui/material';

/**
 * Right aligned button area for forms
 */
export const FormButtons = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  }),
  { name: 'FormButtons' }
);
