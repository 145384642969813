import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '../../api';

/**
 * Provide localized labels for the Role enum
 */
export function useRoleLabels(): Record<Role, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<Role, string> => ({
      AuthorisedPerson: t('enumLabel.role.authorisedPerson'),
      BeneficialOwner: t('enumLabel.role.beneficialOwner'),
      Director: t('enumLabel.role.director'),
      Secretary: t('enumLabel.role.secretary'),
      Shareholder: t('enumLabel.role.shareholder'),
    }),
    [t]
  );
}
