import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { CheckboxProps } from '@mui/material';
import { useFieldDisabled } from '../../disabled';

export type UseCheckboxFieldProps = Omit<
  FieldHookConfig<boolean | undefined | null>,
  'type' | 'multiple'
> &
  Omit<
    CheckboxProps,
    'name' | 'onChange' | 'error' | 'checked' | 'defaultChecked' | 'type'
  >;

/**
 * useField wrapper that includes fixed variants of formik-material-ui's fieldToCheckbox
 * @fixme Does not handle field error text
 */
export function useCheckboxFieldProps({
  name,
  disabled: disabledProp,
  readOnly,
  validate,
  ...props
}: UseCheckboxFieldProps): CheckboxProps {
  const disabled = useFieldDisabled(disabledProp);
  const [{ value, ...field }] = useField({
    name,
    validate,
    ...props,
    type: 'checkbox',
  });
  const indeterminate = !Array.isArray(value) && value == null;

  return {
    ...field,
    disabled: disabled || readOnly,
    readOnly,
    indeterminate,
    value: indeterminate ? false : value,
    ...props,
  };
}
