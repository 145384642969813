import { forwardRef } from 'react';
import { PaymentType } from '../../../api';
import { useLabelFn, usePaymentTypeLabels } from '../../../intl';
import { enumArray, useSortedOptions } from '../../utils';
import { TagArrayField, TagArrayFieldProps } from '../TagArrayField';

export type PaymentTypeArrayFieldProps = Omit<
  TagArrayFieldProps<PaymentType, false>,
  'options'
>;

/**
 * A Tag array combobox field for selecting a list of PaymentTypes
 */
export const PaymentTypeArrayField = forwardRef(function PaymentTypeArrayField(
  props: PaymentTypeArrayFieldProps,
  ref: any
) {
  const { ...other } = props;
  const getPaymentTypeLabel = useLabelFn(usePaymentTypeLabels());
  const options = useSortedOptions(enumArray(PaymentType), getPaymentTypeLabel);

  return (
    <TagArrayField
      ref={ref}
      {...other}
      options={options}
      getOptionLabel={getPaymentTypeLabel}
    />
  );
});
