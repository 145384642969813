import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import SrOnly from '../utils/SrOnly';

export interface HiddenButtonProps {
  type?: 'submit' | 'reset';
  disabled?: boolean;
}

/**
 * An hidden submit button.
 *
 * Used to ensure "Enter to submit" behaviour works and
 * to give a submit button ref for out-of-form submit buttons in the header.
 */
const HiddenButton = (
  props: HiddenButtonProps,
  ref: Ref<HTMLButtonElement>
) => {
  const { type = 'submit', disabled } = props;
  const { t } = useTranslation();

  return (
    <SrOnly aria-hidden="true">
      <button
        tabIndex={-1}
        style={{ marginLeft: 1 }}
        type={type}
        disabled={disabled}
        ref={ref}
      >
        {t('button.submit')}
      </button>
    </SrOnly>
  );
};

export default forwardRef(HiddenButton);
