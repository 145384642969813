import { useCallback } from 'react';
import { NumberFormatterOptions, useUnitFormatter } from './number';

/**
 * Get a function that will format a file size cleanly
 */
export function useFileSizeFormatter(options?: NumberFormatterOptions) {
  const b = useUnitFormatter('byte', options);
  const kb = useUnitFormatter('kilobyte', options);
  const mb = useUnitFormatter('megabyte', options);
  const gb = useUnitFormatter('gigabyte', options);
  const tb = useUnitFormatter('terabyte', options);

  return useCallback(
    (fileSize: number) => {
      if (fileSize > 1_000_000_000_000) return tb(fileSize / 1_000_000_000_000);
      if (fileSize > 1_000_000_000) return gb(fileSize / 1_000_000_000);
      if (fileSize > 1_000_000) return mb(fileSize / 1_000_000);
      if (fileSize > 1_000) return kb(fileSize / 1_000);
      return b(fileSize);
    },
    [b, gb, kb, mb, tb]
  );
}
