import { styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const BreakpointIndicatorContainer = styled('ul')(({ theme }) => ({
  position: 'fixed',
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'row',
  borderTopLeftRadius: theme.shape.borderRadius,
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.snackbar,
  margin: 0,
  padding: theme.spacing(0, 1),
  listStyle: 'none',
  '&[aria-hidden="true"]': {
    display: 'none',
  },
}));

const BreakpointIndicatorItem = styled('li')(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  '&[aria-current]': {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}));

/**
 * Development-only tool that displays an indicator in the lower right with the current breakpoint as you resize
 */
const BreakpointIndicator = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [breakpoint, setBreakpoint] = useState<null | string>(null);
  useEffect(() => {
    const unsubscribes = theme.breakpoints.keys.map((key) => {
      const match = window.matchMedia(
        theme.breakpoints.only(key).replace(/@media\s+/, '')
      );
      if (match.matches) {
        setBreakpoint(key);
      }

      const handler = (e: MediaQueryListEvent) => {
        setBreakpoint((breakpoint) =>
          e.matches ? key : breakpoint === key ? null : breakpoint
        );
      };
      match.addListener(handler);

      return () => {
        match.removeListener(handler);
      };
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [theme.breakpoints]);

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    let timeoutHandle: NodeJS.Timeout | undefined;
    const handler = (e: UIEvent) => {
      setVisible(true);

      if (timeoutHandle) clearTimeout(timeoutHandle);
      timeoutHandle = setTimeout(() => {
        setVisible(false);
        timeoutHandle = undefined;
      }, 1000);
    };

    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return (
    <BreakpointIndicatorContainer
      aria-hidden={visible ? 'false' : 'true'}
      aria-label={t('button.breakpointIndicator')}
    >
      {theme.breakpoints.keys.map((key) => (
        <BreakpointIndicatorItem
          key={key}
          aria-current={key === breakpoint ? 'true' : undefined}
        >
          {key}
        </BreakpointIndicatorItem>
      ))}
    </BreakpointIndicatorContainer>
  );
};

export default BreakpointIndicator;
