import {
  Author,
  Contact,
  ContactShortSummary,
  ContactSummary,
  SignificantPersonContactSummary,
} from '../client';

/**
 * Get the ID of a Contact from either a full contact or a summary
 */
export const getContactId = (
  contact:
    | Contact
    | ContactSummary
    | ContactShortSummary
    | SignificantPersonContactSummary
): string => {
  if ('Id' in contact && contact.Id) return contact.Id;
  if ('id' in contact && contact.id) return contact.id;
  throw new Error('Contact is missing ID');
};

/**
 * Get a Contact's name
 */
export function contactName(
  contact:
    | Contact
    | ContactSummary
    | ContactShortSummary
    | SignificantPersonContactSummary
    | Author
    | undefined
    | null
): string | undefined {
  if (contact) {
    if (contact.FirstName && contact.FamilyName)
      return `${contact.FirstName} ${contact.FamilyName}`;
    else return contact.FirstName || contact.FamilyName;
  }

  return undefined;
}

/**
 * Get a Contact's emails addresses if they are contained in the contact object
 */
export function contactEmails(
  contact:
    | Contact
    | ContactSummary
    | ContactShortSummary
    | SignificantPersonContactSummary
    | undefined
    | null
): string[] {
  if (contact) {
    if ('EmailAddresses' in contact) {
      return contact.EmailAddresses?.map((email) => email.EmailAddress) ?? [];
    }

    if ('LoginEmail' in contact) {
      return contact.LoginEmail ? [contact.LoginEmail] : [];
    }
  }

  return [];
}
