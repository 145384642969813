import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalAddressType } from '../../api';

/**
 * Provide localized labels for the LegalAddressType enum
 */
export function useLegalAddressTypeLabels(): Record<LegalAddressType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<LegalAddressType, string> => ({
      Legal: t('enumLabel.legalAddressType.legal'),
      Mailing: t('enumLabel.legalAddressType.mailing'),
      PrinciplePlaceOfBusiness: t(
        'enumLabel.legalAddressType.principlePlaceOfBusiness'
      ),
    }),
    [t]
  );
}
