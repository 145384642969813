import {
  Form as FormikForm,
  // eslint-disable-next-line no-restricted-imports
  Formik,
  FormikConfig,
  FormikFormProps,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from '@johnrom/formik-v3';
import { createElement, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DisabledContext } from './disabled';
import { setFieldErrors } from './errors';

export type FormProps<Values> = Omit<
  FormikConfig<Values>,
  'children' | 'onSubmit'
> &
  Pick<FormikFormProps, 'aria-label' | 'aria-labelledby'> & {
    /**
     * Disable the usePrompt blocker that occurs when the form is dirty and you try to leave
     */
    disableBlocker?: boolean;
    formProps?: FormikFormProps;
    children?: ReactNode;
    onSubmit: (
      values: Values,
      formikHelpers: FormikHelpers<Values>
    ) => Promise<any>;
    disabled?: boolean;
  };

/**
 * Wrapper around formik's Formik and Form
 */
export const Form = <Values extends FormikValues = FormikValues>(
  props: FormProps<Values>
) => {
  const {
    disableBlocker,
    onSubmit,
    disabled = false,
    children,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
    formProps,
    ...formikProps
  } = props;
  const { t } = useTranslation();
  const submit = useCallback(
    async (values: Values, formikHelpers: FormikHelpers<Values>) => {
      try {
        return await onSubmit(values, formikHelpers);
      } catch (err) {
        setFieldErrors(t, formikHelpers, err);
        console.error(err);
      }
    },
    [onSubmit, t]
  );

  return createElement<FormikConfig<Values>>(
    Formik,
    { ...formikProps, onSubmit: submit },
    (params: FormikProps<Values>) =>
      createElement(
        DisabledContext.Provider,
        { value: disabled },
        createElement(
          FormikForm,
          {
            noValidate: true,
            'aria-label': ariaLabel,
            'aria-labelledby': ariaLabelledby,
            ...formProps,
          },
          typeof children === 'function' ? children(params) : children
        )
      )
  );
};
