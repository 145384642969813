import { IconButton, Tooltip } from '@mui/material';
import { DownloadOutline as DownloadIcon } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import { Attachment } from '../../api';

export interface DocumentDownloadButtonProps {
  attachment: Attachment | undefined;
}

export const DocumentDownloadButton = (props: DocumentDownloadButtonProps) => {
  const { attachment } = props;
  const { FileName, SignedUrl } = attachment ?? {};
  const { t } = useTranslation();

  return (
    <Tooltip title={t('button.download') as string}>
      <IconButton
        aria-label={t('button.download')}
        component="a"
        href={SignedUrl}
        download={FileName}
        target="_blank"
        rel="noopener"
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};
