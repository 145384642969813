import SidebarMenuItem, { SidebarMenuItemProps } from '../ui/SidebarMenuItem';
import SidebarNavItem, { SidebarNavItemProps } from '../ui/SidebarNavItem';

export type SidebarListSubitemProps = (SidebarNavItemProps &
  SidebarMenuItemProps) & {
  variant?: 'list' | 'menu';
};

/**
 * Wrapper that switches between SidebarListItem variant=subnav and SidebarMenuItem variant=subnav
 */
const SidebarListSubitem = (props: SidebarListSubitemProps) => {
  const { variant = 'list', ...other } = props;

  if (variant === 'menu') {
    return <SidebarMenuItem {...other} />;
  }

  return <SidebarNavItem {...other} />;
};

export default SidebarListSubitem;
