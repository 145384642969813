import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ClearingSystemFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a clearing system
 */
export const ClearingSystemField = forwardRef(function ClearingSystemField(
  props: ClearingSystemFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [clearingSystems, loadingClearingSystems] = useCodes('clearingsystems');
  const optionProps = useCodeOptionsProps(clearingSystems);

  return (
    <SelectField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingClearingSystems}
    />
  );
});
