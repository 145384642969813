import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentType } from '../../api';

/**
 * Provide localized labels for the DocumentType enum
 */
export function useDocumentTypeLabels(): Record<DocumentType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<DocumentType, string> => ({
      BankStatement: t('enumLabel.documentType.bankStatement'),
      BirthCertificate: t('enumLabel.documentType.birthCertificate'),
      Citizenship: t('enumLabel.documentType.citizenship'),
      Contract: t('enumLabel.documentType.contract'),
      CorporateRegistration: t('enumLabel.documentType.corporateRegistration'),
      DriversLicense: t('enumLabel.documentType.driversLicense'),
      IdentityCard: t('enumLabel.documentType.identityCard'),
      IncorporationNumber: t('enumLabel.documentType.incorporationNumber'),
      ListOfAuthorizedSignatories: t(
        'enumLabel.documentType.listOfAuthorizedSignatories'
      ),
      ListOfDirectors: t('enumLabel.documentType.listOfDirectors'),
      ListOfShareholders: t('enumLabel.documentType.listOfShareholders'),
      Passport: t('enumLabel.documentType.passport'),
      PermanentResidency: t('enumLabel.documentType.permanentResidency'),
      PowerOfAttorney: t('enumLabel.documentType.powerOfAttorney'),
      ProcessorLetter: t('enumLabel.documentType.processorLetter'),
      SanctionsCheck: t('enumLabel.documentType.sanctionsCheck'),
      ScreenCapture: t('enumLabel.documentType.screenCapture'),
      SocialInsurance: t('enumLabel.documentType.socialInsurance'),
      TaxReturn: t('enumLabel.documentType.taxReturn'),
      TemporaryResidency: t('enumLabel.documentType.temporaryResidency'),
      UtilityStatement: t('enumLabel.documentType.utilityStatement'),
    }),
    [t]
  );
}
