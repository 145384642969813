import {
  FormControlProps,
  FormLabel,
  InputBaseProps,
  styled,
} from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { HorizontalLine } from '../../profile/utils/HorizontalLine';
import { truncateEllipsis } from '../../utils/scroll';

interface StyleProps {
  endAdornment: boolean;
  formControl?: boolean;
  fullWidth: boolean;
  margin: 'dense' | 'normal' | 'none';
  multiline: boolean;
  shrink?: boolean;
  size: InputBaseProps['size'];
  startAdornment: boolean;
  variant: 'filled' | 'outlined';
}

const ReadOnlyFormControlRoot = styled('div', {
  name: 'ReadOnlyFormControl',
  slot: 'Root',
})<{ ownerState: StyleProps }>(({ theme, ownerState }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
  // Reset fieldset default style.
  minWidth: 0,
  padding: 0,
  margin: 0,
  border: 0,
  verticalAlign: 'top', // Fix alignment issue on Safari.
  ...(ownerState.margin === 'normal' && {
    marginTop: 16,
    marginBottom: 8,
  }),
  ...(ownerState.margin === 'dense' && {
    marginTop: 8,
    marginBottom: 4,
  }),
  ...(ownerState.fullWidth && {
    width: '100%',
  }),
}));

const ReadOnlyInputLabelRoot = styled(FormLabel, {
  name: 'ReadOnlyInputLabel',
  slot: 'Root',
})<{ ownerState: StyleProps }>(({ theme, ownerState }) => ({
  color: theme.palette.text.primary,
  display: 'block',
  transformOrigin: 'top left',
  ...truncateEllipsis,
  maxWidth: '100%',
  ...(ownerState.formControl && {
    position: 'absolute',
    left: 0,
    top: 0,
    // slight alteration to spec spacing to match visual spec result
    transform: 'translate(0, 20px) scale(1)',
  }),
  ...(ownerState.size === 'small' && {
    // Compensation for the `Input.inputSizeSmall` style.
    transform: 'translate(0, 17px) scale(1)',
  }),
  ...(ownerState.shrink && {
    transform: 'translate(0, -1.5px) scale(0.75)',
    transformOrigin: 'top left',
    maxWidth: '133%',
  }),
  ...(ownerState.variant === 'filled' && {
    // Chrome's autofill feature gives the input field a yellow background.
    // Since the input field is behind the label in the HTML tree,
    // the input field is drawn last and hides the label with an opaque background color.
    // zIndex: 1 will raise the label above opaque background-colors of input.
    zIndex: 1,
    pointerEvents: 'none',
    transform: 'translate(12px, 16px) scale(1)',
    maxWidth: 'calc(100% - 24px)',
    ...(ownerState.size === 'small' && {
      transform: 'translate(12px, 13px) scale(1)',
    }),
    ...(ownerState.shrink && {
      transform: 'translate(12px, 7px) scale(0.75)',
      maxWidth: 'calc(133% - 24px)',
      ...(ownerState.size === 'small' && {
        transform: 'translate(12px, 4px) scale(0.75)',
      }),
    }),
  }),
  ...(ownerState.variant === 'outlined' && {
    // see comment above on filled.zIndex
    zIndex: 1,
    pointerEvents: 'none',
    transform: 'translate(14px, 16px) scale(1)',
    maxWidth: 'calc(100% - 24px)',
    ...(ownerState.size === 'small' && {
      transform: 'translate(14px, 9px) scale(1)',
    }),
    ...(ownerState.shrink && {
      maxWidth: 'calc(133% - 24px)',
      transform: 'translate(14px, -9px) scale(0.75)',
    }),
  }),
}));

const ReadOnlyInputBaseRoot = styled('div', {
  name: 'ReadOnlyInputBase',
  slot: 'Root',
})<{ ownerState: StyleProps }>(({ theme, ownerState }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  lineHeight: '1.4375em', // 23px
  boxSizing: 'border-box', // Prevent padding issue with fullWidth.
  position: 'relative',
  cursor: 'text',
  display: 'inline-flex',
  alignItems: 'center',
  ...(ownerState.multiline && {
    padding: '4px 0 5px',
    ...(ownerState.size === 'small' && {
      paddingTop: 1,
    }),
  }),
  ...(ownerState.fullWidth && {
    width: '100%',
  }),
}));

const ReadOnlyFilledInputRoot = styled(ReadOnlyInputBaseRoot, {
  name: 'ReadOnlyFilledInput',
  slot: 'Root',
})<{ ownerState: StyleProps }>(({ theme, ownerState }) => ({
  position: 'relative',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  ...(ownerState.startAdornment && {
    paddingLeft: 12,
  }),
  ...(ownerState.endAdornment && {
    paddingRight: 12,
  }),
  ...(ownerState.multiline && {
    padding: '25px 12px 8px',
    ...(ownerState.size === 'small' && {
      paddingTop: 21,
      paddingBottom: 4,
    }),
  }),
}));

const ReadOnlyInputBaseInput = styled('input', {
  name: 'ReadOnlyInputBase',
  slot: 'Input',
})<{ ownerState: StyleProps }>(({ theme }) => ({ theme, ownerState }) => ({
  font: 'inherit',
  letterSpacing: 'inherit',
  color: 'currentColor',
  padding: '4px 0 5px',
  border: 0,
  boxSizing: 'content-box',
  background: 'none',
  height: '1.4375em', // Reset 23pxthe native input line-height
  margin: 0, // Reset for Safari
  WebkitTapHighlightColor: 'transparent',
  display: 'block',
  // Make the flex item shrink with Firefox
  minWidth: 0,
  width: '100%', // Fix IE11 width issue
  '&:focus': {
    outline: 0,
  },
}));

const ReadOnlyFilledInputInput = styled(ReadOnlyInputBaseInput, {
  name: 'ReadOnlyFilledInput',
  slot: 'Input',
})<{ ownerState: StyleProps }>(({ theme, ownerState }) => ({
  paddingTop: 25,
  paddingRight: 12,
  paddingBottom: 8,
  paddingLeft: 12,
  ...(ownerState.size === 'small' && {
    paddingTop: 21,
    paddingBottom: 4,
  }),
  ...(ownerState.multiline && {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  ...(ownerState.startAdornment && {
    paddingLeft: 0,
  }),
  ...(ownerState.endAdornment && {
    paddingRight: 0,
  }),
}));

const EmptyDash = () => <HorizontalLine width="1.5em" strokeThickness={1.5} />;

export interface ReadOnlyFieldProps {
  id?: string;
  name?: string;
  margin?: FormControlProps['margin'];
  fullWidth?: FormControlProps['fullWidth'];
  size?: InputBaseProps['size'];
  multiline?: boolean;
  label?: ReactNode;
  value?: any;
  children?: ReactNode;
  enableAdornment?: boolean;
  startAdornment?: InputBaseProps['startAdornment'];
  endAdornment?: InputBaseProps['endAdornment'];
  InputProps?: Pick<InputBaseProps, 'ref' | 'sx'>;
  inputProps?: InputBaseProps['inputProps'];
}

export const ReadOnlyField = forwardRef(function ReadOnlyField(
  props: ReadOnlyFieldProps,
  ref: any
) {
  const {
    id,
    name,
    margin = 'dense',
    size = 'small',
    fullWidth = true,
    multiline = false,
    label,
    value: valueProp,
    InputProps,
    inputProps,
    children,
    enableAdornment = false,
    startAdornment,
    endAdornment,
  } = props;
  const ownerState: StyleProps = {
    formControl: true,
    variant: 'filled',
    margin,
    size,
    fullWidth,
    multiline,
    shrink: true,
    startAdornment: !!(enableAdornment && startAdornment),
    endAdornment: !!(enableAdornment && endAdornment),
  };
  const value = valueProp ?? inputProps?.value;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  const input = children ? (
    <ReadOnlyFilledInputInput
      ref={inputProps?.ref}
      ownerState={ownerState}
      as="div"
    >
      {children}
    </ReadOnlyFilledInputInput>
  ) : value ? (
    <ReadOnlyFilledInputInput
      ref={inputProps?.ref}
      name={name}
      readOnly
      value={value}
      ownerState={ownerState}
      onFocus={inputProps?.onFocus}
    />
  ) : (
    <ReadOnlyFilledInputInput ownerState={ownerState} as="div">
      <EmptyDash />
      {/* Hidden empty input so Autocomplete isn't confused about there being no input */}
      <input
        ref={inputProps?.ref}
        name={name}
        readOnly
        aria-hidden="true"
        style={{ display: 'none' }}
        value=""
      />
    </ReadOnlyFilledInputInput>
  );

  return (
    <ReadOnlyFormControlRoot ref={ref} ownerState={ownerState}>
      <ReadOnlyInputLabelRoot
        htmlFor={id}
        id={inputLabelId}
        ownerState={ownerState}
      >
        {label}
      </ReadOnlyInputLabelRoot>
      <ReadOnlyFilledInputRoot
        ref={InputProps?.ref as any}
        sx={InputProps?.sx}
        ownerState={ownerState}
      >
        {enableAdornment && startAdornment}
        {input}
        {enableAdornment && endAdornment}
      </ReadOnlyFilledInputRoot>
    </ReadOnlyFormControlRoot>
  );
});
