import { ElementType, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

export interface NavigationProps {
  componentProp?: ElementType;
  disabled?: boolean;
  to?: string;
  href?: string;
  onClick?: MouseEventHandler<any>;
}

/**
 * Get props for a ListItem that will make it a link/button depending on what props are passed
 */
export function listItemPropsForNavigation({
  disabled,
  to,
  href,
  onClick,
}: NavigationProps) {
  const component = disabled ? undefined : to ? Link : href ? 'a' : undefined;
  const button = !disabled && !!(to || href || onClick);

  return {
    component: component,
    button: button,
    to: disabled ? undefined : to,
    href: disabled ? undefined : href,
    onClick: onClick,
  };
}
