import {
  AccountCircleOutlined as AccountCircleIcon,
  PublicOutlined as PublicIcon,
  VisibilityOffOutlined as VisibilityOffIcon,
} from '@mui/icons-material';
import { styled, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { SendCheckOutline, SendClockOutline } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import { contactName } from '../../../api';
import { useCan } from '../../../auth';
import {
  useLuxonLocaleFormatter,
  useLuxonRelativeFormater,
} from '../../../intl';
import MessageFormatter from '../../../utils/MessageFormatter';
import { CommentValues } from '../../common';

const ReviewCommentContainer = styled('div', {
  name: 'ReviewComment',
  slot: 'Container',
})(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
  { name: 'ReviewCommentContainer' }
);

const ReviewCommentMessage = styled('div', {
  name: 'ReviewComment',
  slot: 'Message',
})(({ theme }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.background.chatBubble,
  borderRadius: theme.spacing(2),

  '& > :first-of-type': { marginTop: 0 },
  '& > :last-of-type': { marginBottom: 0 },
}));

const ReviewCommentFooter = styled('div', {
  name: 'ReviewComment',
  slot: 'Footer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  marginTop: theme.spacing(0.5),

  '& .MuiSvgIcon-root': {
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5),
  },
}));

const ReviewCommentHorizontalBulletList = styled('div', {
  name: 'ReviewComment',
  slot: 'HorizontalBulletList',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',

  '&.flex': {
    flex: 1,
  },

  '& > *': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&:first-child::before': { display: 'none' },
    '&::before': {
      content: '"•"',
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export interface ReviewCommentProps {
  comment: CommentValues;
}

export const ReviewComment = (props: ReviewCommentProps) => {
  const { Comment, Authored, Author, IsInternal, published } = props.comment;
  const { t } = useTranslation();
  const formateRelativeTime = useLuxonRelativeFormater({ style: 'short' });
  const formateFullDateTime = useLuxonLocaleFormatter(
    DateTime.DATETIME_FULL_WITH_SECONDS
  );
  const can = useCan();
  const showVisibility = can.read('PrivClients') || IsInternal;

  return (
    <ReviewCommentContainer>
      <ReviewCommentMessage className="body">
        <MessageFormatter message={Comment} />
      </ReviewCommentMessage>

      <ReviewCommentFooter>
        <ReviewCommentHorizontalBulletList className="flex">
          {Author && (
            <Typography variant="caption" color="textSecondary">
              <AccountCircleIcon
                fontSize="small"
                sx={{ verticalAlign: 'middle', mr: 0.5 }}
              />
              {contactName(Author)}
            </Typography>
          )}
        </ReviewCommentHorizontalBulletList>
        <ReviewCommentHorizontalBulletList>
          {Authored && (
            <Typography
              component="time"
              color="textSecondary"
              variant="caption"
              dateTime={Authored}
              title={formateFullDateTime(DateTime.fromISO(Authored))}
            >
              {formateRelativeTime(DateTime.fromISO(Authored))}
            </Typography>
          )}
          {showVisibility && (
            <Tooltip
              title={
                IsInternal
                  ? t('tooltip.internalCommentNotVisibleToClient')
                  : t('tooltip.commentIsVisibleToClient')
              }
            >
              <Typography
                component="span"
                color="textSecondary"
                variant="caption"
              >
                {IsInternal ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <PublicIcon fontSize="small" />
                )}
                {IsInternal
                  ? t('enumLabel.reviewCommentVisibility.internal')
                  : t('enumLabel.reviewCommentVisibility.public')}
              </Typography>
            </Tooltip>
          )}
          <Tooltip
            title={
              published
                ? t('tooltip.commentPublished')
                : t('tooltip.commentNotPublished')
            }
          >
            <Typography
              component="span"
              color="textSecondary"
              variant="caption"
            >
              {published ? (
                <SendCheckOutline fontSize="small" />
              ) : (
                <SendClockOutline fontSize="small" />
              )}
              {published
                ? t('enumLabel.publishedEnum.published')
                : t('enumLabel.publishedEnum.draft')}
            </Typography>
          </Tooltip>
        </ReviewCommentHorizontalBulletList>
      </ReviewCommentFooter>
    </ReviewCommentContainer>
  );
};
