import invariant from 'invariant';
import { useTranslation } from 'react-i18next';
import { usePayment } from '../../api';
import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
} from '../../breadcrumbs';
import { usePaymentLocationState } from './internal/usePaymentLocationState';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const AllPaymentsTitle = t('page.allPayments');
export const OurPaymentsTitle = t('page.ourPayments');
export const PaymentDetailsTitle = p(({ clientId, paymentId }) => {
  invariant(paymentId, 'paymentId is expected');
  const { data: payment, loading } = usePayment(clientId, paymentId, {
    useErrorBoundary: false,
  });
  const title = payment?.id;

  return [title, loading, { variant: 'id' }];
});
export const NewPayoutTitle = p(() => {
  const { t } = useTranslation();
  const { type } = usePaymentLocationState();
  return [
    type === 'repeat' ? t('page.repeatPayout') : t('page.newPayout'),
    false,
  ];
});
export const NewTransferTitle = p(() => {
  const { t } = useTranslation();
  const { type } = usePaymentLocationState();
  return [
    type === 'repeat' ? t('page.repeatTransfer') : t('page.newTransfer'),
    false,
  ];
});
