import { Drawer, styled } from '@mui/material';
import { ComponentProps } from 'react';

interface OwnerState {
  expanded: boolean;
}

const MiniCollapsibleSidebarRoot = styled(Drawer, {
  name: 'MiniCollapsibleSidebar',
  slot: 'Root',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  width: theme.dimensions.drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',

  ...(ownerState.expanded
    ? {
        '&, & > .MuiDrawer-paper': {
          overflowX: 'hidden',
          width: theme.dimensions.drawerWidth,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }
    : {
        '&, & > .MuiDrawer-paper': {
          overflowX: 'hidden',
          width: theme.spacing(3 + 3 * 2),
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
      }),
}));

export type MiniCollapsibleSidebarProps = Omit<
  ComponentProps<typeof MiniCollapsibleSidebarRoot>,
  'ownerState'
> & {
  expanded?: boolean;
};

const MiniCollapsibleSidebar = (props: MiniCollapsibleSidebarProps) => {
  const { expanded = true, children, ...other } = props;

  const ownerState: OwnerState = { expanded };

  return (
    <MiniCollapsibleSidebarRoot
      variant="permanent"
      open
      {...other}
      ownerState={ownerState}
    >
      {children}
    </MiniCollapsibleSidebarRoot>
  );
};

export default MiniCollapsibleSidebar;
