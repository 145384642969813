import { forwardRef } from 'react';
import { PaymentType } from '../../../api';
import { usePaymentTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type PaymentTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a PaymentType
 */
export const PaymentTypeField = forwardRef(function PaymentTypeField(
  props: PaymentTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(PaymentType, usePaymentTypeLabels());

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
