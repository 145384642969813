import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  styled,
} from '@mui/material';
import { forwardRef, ReactElement, Ref } from 'react';
import {
  useCheckboxFieldProps,
  UseCheckboxFieldProps,
} from './internal/useCheckboxFieldProps';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';

export type SingleCheckboxFieldProps = UseCheckboxFieldProps &
  CommonValidatorProps & {
    label: string | number | ReactElement;
    LabelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
  };

const SingleCheckboxLabel = styled(FormControlLabel)(
  {
    display: 'flex',
  },
  { name: 'SingleCheckboxLabel' }
);

/**
 * A field with a single boolean checkbox
 * @todo Support `undefined` as an indeterminate state?
 */
export const SingleCheckboxField = forwardRef(function SingleCheckboxField(
  props: SingleCheckboxFieldProps,
  ref: Ref<HTMLInputElement>
) {
  const { label, LabelProps, ...other } = usePropsWithId(props);
  const validate = useCommonValidate(props);

  const checkboxProps = useCheckboxFieldProps({
    ...other,
    validate,
  });

  return (
    <SingleCheckboxLabel
      ref={ref}
      {...LabelProps}
      control={<Checkbox {...checkboxProps} />}
      label={label}
    />
  );
});
