import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { PaymentType, ThirdPartyType } from '../api';
import { breadcrumb } from '../breadcrumbs';
import Layout from '../layout/Layout';
import { page, routeVariant } from '../route';
import { AccountsBlocksTitle } from './account-blocks/breadcrumbs';
import { AccountTraceTitle } from './account-trace/breadcrumbs';
import {
  AccountDetailsTitle,
  AddAccountTitle,
  AllAccountsTitle,
  OurAccountsTitle,
} from './accounts/breadcrumbs';
import { AccountActivityTitle } from './activity/breadcrumbs';
import {
  BatchPaymentDetailsTitle,
  BatchPaymentsTitle,
} from './batch-payments/breadcrumbs';
import {
  AddClientProfileTitle,
  ClientProfileDetailsTitle,
  ClientProfilesTitle,
} from './client-profiles/breadcrumbs';
import {
  AddClientTitle,
  AllClientsTitle,
  ClientDetailsTitle,
} from './clients/breadcrumbs';
import {
  AddContactTitle,
  AllContactsTitle,
  ContactDetailsTitle,
  OurContactsTitle,
} from './contacts/breadcrumbs';
import { DashboardTitle } from './dashboard/breadcrumbs';
import { FxTitle } from './fx/breadcrumbs';
import { HelpTitle } from './help/breadcrumbs';
import PageNotFound from './PageNotFound/PageNotFound';
import {
  AllPaymentsTitle,
  NewPayoutTitle,
  NewTransferTitle,
  OurPaymentsTitle,
  PaymentDetailsTitle,
} from './payments/breadcrumbs';
import { ProfileTitle } from './profile/breadcrumbs';
import {
  AddRiskSurveyTitle,
  RiskSurveyDetailsTitle,
  RiskSurveysTitle,
} from './risk-surveys/breadcrumbs';
import {
  ClientSettingsTitle,
  SettingsTitle,
  WebhooksTitle,
} from './settings/breadcrumbs';
import {
  AddThirdPartyTitle,
  AllThirdPartiesTitle,
  OurThirdPartiesTitle,
  ThirdPartyDetailsTitle,
} from './thirdparties/breadcrumbs';
import {
  ChangePasswordTitle,
  UserApiKeysTitle,
  UserProfileTitle,
} from './user/breadcrumbs';
const Splash = lazy(() => import('./splash/Splash'));
const Login = lazy(() => import('./signin/Login'));
const Register = lazy(() => import('./signin/Register'));
const ChangePassword = lazy(() => import('./user/ChangePassword'));
const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Profile = lazy(() => import('./profile/Profile'));
const Accounts = lazy(() => import('./accounts/Accounts'));
const AccountDetails = lazy(() => import('./accounts/AccountDetails'));
const AccountActivity = lazy(() => import('./activity/AccountActivity'));
const AccountTrace = lazy(() => import('./account-trace/AccountTrace'));
const AddAccount = lazy(() => import('./accounts/AddAccount'));
const Payments = lazy(() => import('./payments/Payments'));
const PaymentDetails = lazy(() => import('./payments/PaymentDetails'));
const NewPayment = lazy(() => import('./payments/NewPayment'));
const BatchPayments = lazy(() => import('./batch-payments/BatchPayments'));
const BatchPaymentDetails = lazy(
  () => import('./batch-payments/BatchPaymentDetails')
);
const FX = lazy(() => import('./fx/FX'));
const Contacts = lazy(() => import('./contacts/Contacts'));
const ContactDetails = lazy(() => import('./contacts/ContactDetails'));
const AddContact = lazy(() => import('./contacts/AddContact'));
const ThirdParties = lazy(() => import('./thirdparties/ThirdParties'));
const ThirdPartyDetails = lazy(
  () => import('./thirdparties/ThirdPartyDetails')
);
const AddThirdParty = lazy(() => import('./thirdparties/AddThirdParty'));
const ClientSettings = lazy(() => import('./settings/ClientSettings'));
const Webhooks = lazy(() => import('./settings/Webhooks'));
const UserProfile = lazy(() => import('./user/UserProfile'));
const UserApiKeys = lazy(() => import('./user/UserApiKeys'));
const Help = lazy(() => import('./help/Help'));
const Clients = lazy(() => import('./clients/Clients'));
const AddClient = lazy(() => import('./clients/AddClient'));
const ClientDetails = lazy(() => import('./clients/ClientDetails'));
const ClientProfiles = lazy(() => import('./client-profiles/ClientProfiles'));
const ClientProfileDetails = lazy(
  () => import('./client-profiles/ClientProfileDetails')
);
const AddClientProfile = lazy(
  () => import('./client-profiles/AddClientProfile')
);
const RiskSurveys = lazy(() => import('./risk-surveys/RiskSurveys'));
const RiskSurveyDetails = lazy(
  () => import('./risk-surveys/RiskSurveyDetails')
);
const AddRiskSurvey = lazy(() => import('./risk-surveys/AddRiskSurvey'));
const AccountBlocks = lazy(() => import('./account-blocks/AccountBlocks'));
const ThemeDemo = lazy(() => import('./Demo/ThemeDemo'));

const AppRoutes = () => {
  const AccountActivityRoute = breadcrumb(
    'activity',
    'AccountActivity',
    AccountActivityTitle,
    [
      page(<AccountActivity />),
      breadcrumb(
        ':paymentId',
        'PaymentDetails',
        PaymentDetailsTitle,
        page(<PaymentDetails />)
      ),
    ]
  );

  return useRoutes([
    ...(process.env.REACT_APP_SPLASH_CAPTURE === 'true'
      ? [
          {
            path: 'splash',
            element: <Splash />,
          },
        ]
      : []),
    breadcrumb('login', 'Login', undefined, page(<Login />)),
    breadcrumb('register', 'Register', undefined, page(<Register />)),
    {
      element: <Layout />,
      children: [
        breadcrumb('', 'Dashboard', DashboardTitle, page(<Dashboard />)),
        routeVariant('client', [
          breadcrumb('profile', 'Profile', ProfileTitle, page(<Profile />)),
          breadcrumb('accounts', 'Accounts', OurAccountsTitle, [
            page(<Accounts />),
            breadcrumb(':accountId', 'AccountDetails', AccountDetailsTitle, [
              page(<AccountDetails />),
              AccountActivityRoute,
            ]),
            breadcrumb(
              'add',
              'AddAccount',
              AddAccountTitle,
              page(<AddAccount />)
            ),
          ]),
          breadcrumb('payments', 'Payments', OurPaymentsTitle, [
            page(<Payments />),
            breadcrumb(
              ':paymentId',
              'PaymentDetails',
              PaymentDetailsTitle,
              page(<PaymentDetails />)
            ),
            breadcrumb(
              'create/payout',
              'NewPayout',
              NewPayoutTitle,
              page(<NewPayment paymentType={PaymentType.Payout} />)
            ),
            breadcrumb(
              'create/transfer',
              'NewTransfer',
              NewTransferTitle,
              page(<NewPayment paymentType={PaymentType.Transfer} />)
            ),
          ]),
          breadcrumb('payments/batch', 'BatchPayments', BatchPaymentsTitle, [
            page(<BatchPayments />),
            breadcrumb(
              ':batchPaymentId',
              'BatchPaymentDetails',
              BatchPaymentDetailsTitle,
              [
                page(<BatchPaymentDetails />),
                breadcrumb(
                  ':paymentId',
                  'PaymentDetails',
                  PaymentDetailsTitle,
                  page(<PaymentDetails />)
                ),
              ]
            ),
          ]),
          breadcrumb('fx', 'FX', FxTitle, page(<FX />)),
          breadcrumb('contacts', 'Contacts', OurContactsTitle, [
            page(<Contacts />),
            breadcrumb(
              ':contactId',
              'ContactDetails',
              ContactDetailsTitle,
              page(<ContactDetails />)
            ),
            breadcrumb(
              'add',
              'AddContact',
              AddContactTitle,
              page(<AddContact />)
            ),
          ]),
          breadcrumb('thirdparties', 'ThirdParties', OurThirdPartiesTitle, [
            page(<ThirdParties />),
            breadcrumb(
              ':thirdPartyId',
              'ThirdPartyDetails',
              ThirdPartyDetailsTitle,
              page(<ThirdPartyDetails />)
            ),
            breadcrumb(
              'add/company',
              'AddThirdParty',
              AddThirdPartyTitle,
              page(
                <AddThirdParty
                  thirdPartyType={ThirdPartyType.CompanyThirdParty}
                />
              )
            ),
            breadcrumb(
              'add/individual',
              'AddThirdParty',
              AddThirdPartyTitle,
              page(
                <AddThirdParty
                  thirdPartyType={ThirdPartyType.IndividualThirdParty}
                />
              )
            ),
          ]),
          breadcrumb('settings', 'ClientSettings', SettingsTitle, [
            page(<ClientSettings />),
            breadcrumb(
              'webhooks',
              'Webhooks',
              WebhooksTitle,
              page(<Webhooks />)
            ),
          ]),
        ]),
        breadcrumb(
          'user/profile',
          'UserProfile',
          UserProfileTitle,
          page(<UserProfile />)
        ),
        breadcrumb(
          'user/apikeys',
          'UserApiKeys',
          UserApiKeysTitle,
          page(<UserApiKeys />)
        ),
        breadcrumb(
          'user/changepassword',
          'ChangePassword',
          ChangePasswordTitle,
          page(<ChangePassword />)
        ),
        breadcrumb('help', 'Help', HelpTitle, page(<Help />)),
        routeVariant('admin', [
          breadcrumb('clients', 'Clients', AllClientsTitle, [
            page(<Clients />),
            breadcrumb(':clientId', 'ClientDetails', ClientDetailsTitle, [
              page(<ClientDetails />),
              breadcrumb(
                'settings',
                'ClientSettings',
                ClientSettingsTitle,
                page(<ClientSettings />)
              ),
              breadcrumb(
                'contacts/add',
                'AddContact',
                AddContactTitle,
                page(<AddContact />)
              ),
              breadcrumb(
                'accounts/add',
                'AddAccount',
                AddAccountTitle,
                page(<AddAccount />)
              ),
            ]),
            breadcrumb('add', 'AddClient', AddClientTitle, page(<AddClient />)),
          ]),
          breadcrumb('clients/accounts', 'Accounts', AllAccountsTitle, [
            page(<Accounts />),
            breadcrumb(
              ':clientId/:accountId',
              'AccountDetails',
              AccountDetailsTitle,
              [page(<AccountDetails />), AccountActivityRoute]
            ),
            breadcrumb(
              'add',
              'AddAccount',
              AddAccountTitle,
              page(<AddAccount />)
            ),
          ]),
          breadcrumb('clients/payments', 'Payments', AllPaymentsTitle, [
            page(<Payments />),
            breadcrumb(
              ':clientId/:paymentId',
              'PaymentDetails',
              PaymentDetailsTitle,
              [page(<PaymentDetails />)]
            ),
          ]),
          breadcrumb('clients/contacts', 'Contacts', AllContactsTitle, [
            page(<Contacts />),
            breadcrumb(
              ':clientId/:contactId',
              'ContactDetails',
              ContactDetailsTitle,
              page(<ContactDetails />)
            ),
            breadcrumb(
              'add',
              'AddContact',
              AddContactTitle,
              page(<AddContact />)
            ),
          ]),
          breadcrumb(
            'clients/thirdparties',
            'ThirdParties',
            AllThirdPartiesTitle,
            [
              page(<ThirdParties />),
              breadcrumb(
                ':clientId/:thirdPartyId',
                'ThirdPartyDetails',
                ThirdPartyDetailsTitle,
                page(<ThirdPartyDetails />)
              ),
              breadcrumb(
                'add/company',
                'AddThirdParty',
                AddThirdPartyTitle,
                page(
                  <AddThirdParty
                    thirdPartyType={ThirdPartyType.CompanyThirdParty}
                  />
                )
              ),
              breadcrumb(
                'add/individual',
                'AddThirdParty',
                AddThirdPartyTitle,
                page(
                  <AddThirdParty
                    thirdPartyType={ThirdPartyType.IndividualThirdParty}
                  />
                )
              ),
            ]
          ),
          breadcrumb(
            'clients/activity',
            'AccountActivity',
            AccountActivityTitle,
            [
              page(<AccountActivity />),
              breadcrumb(
                ':clientId/:paymentId',
                'PaymentDetails',
                PaymentDetailsTitle,
                page(<PaymentDetails />)
              ),
            ]
          ),
          breadcrumb(
            'clients/audit/payments',
            'AccountTrace',
            AccountTraceTitle,
            [page(<AccountTrace />)]
          ),
          breadcrumb(
            'system-settings/client-profiles',
            'ClientProfiles',
            ClientProfilesTitle,
            [
              page(<ClientProfiles />),
              breadcrumb(
                ':clientProfileId',
                'ClientProfileDetails',
                ClientProfileDetailsTitle,
                page(<ClientProfileDetails />)
              ),
              breadcrumb(
                'add',
                'AddClientProfile',
                AddClientProfileTitle,
                page(<AddClientProfile />)
              ),
            ]
          ),
          breadcrumb(
            'system-settings/risk-surveys',
            'RiskSurveys',
            RiskSurveysTitle,
            [
              page(<RiskSurveys />),
              breadcrumb(
                ':riskSurveyId',
                'RiskSurveyDetails',
                RiskSurveyDetailsTitle,
                page(<RiskSurveyDetails />)
              ),
              breadcrumb(
                'add',
                'AddRiskSurvey',
                AddRiskSurveyTitle,
                page(<AddRiskSurvey />)
              ),
            ]
          ),
          breadcrumb(
            'system-settings/account-blocks',
            'AccountBlocks',
            AccountsBlocksTitle,
            [page(<AccountBlocks />)]
          ),
        ]),
        breadcrumb('demo/theme', undefined, undefined, page(<ThemeDemo />)),
        { path: '*', element: <PageNotFound /> },
      ],
    },
  ]);
};

export default AppRoutes;
