import { TableInstance } from 'react-table';
import { stringifyCsv } from '../csv/stringify';

/**
 * Create a CSV file string from a react-table
 */
export function tableToCsv<D extends object = {}>(
  table: Pick<TableInstance<D>, 'visibleColumns' | 'rows' | 'prepareRow'>
) {
  const { visibleColumns, rows, prepareRow } = table;
  return stringifyCsv(
    Array.from(
      (function* () {
        // Use visible columns for the export, but exclude the selection checkbox
        const headers = visibleColumns.filter(
          (column) => column.id !== 'selection' && column.id !== 'delete'
        );

        // Headers
        yield headers.map((column) => {
          // @fixme Handle headers that may use a React element renderer for the header
          return String(column.Header);
        });

        // Rows
        for (const row of rows) {
          prepareRow(row);
          yield row.cells
            .filter(
              (cell) =>
                cell.column.id !== 'selection' && cell.column.id !== 'delete'
            )
            .map((cell) => {
              return cell.column.Export(cell);
            });
        }
      })()
    )
  );
}
