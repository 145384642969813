import { Map as IMap } from 'immutable';
import {
  cloneElement,
  createElement,
  Fragment,
  ReactElement,
  ReactNode,
  useLayoutEffect,
} from 'react';
import useConstant from 'use-constant';
import { v1 as uuid1 } from 'uuid';
import { createReducerContext } from '../../reducer-context';

interface PageActionSetAction {
  type: 'SET';
  id: string;
  element: ReactElement;
  order: number;
}
interface PageActionRemoveAction {
  type: 'REMOVE';
  id: string;
  prevElement: ReactElement;
}
type PageActionAction = PageActionSetAction | PageActionRemoveAction;

interface ElItem {
  el: ReactElement;
  order: number;
}

const { Provider, useReducerDispatch, useReducerState } = createReducerContext(
  'PageActions',
  (state: IMap<string, ElItem>, action: PageActionAction) => {
    if (action.type === 'SET') {
      state = state.set(action.id, { el: action.element, order: action.order });
    } else if (
      action.type === 'REMOVE' &&
      action.prevElement === state.get(action.id)?.el
    ) {
      state = state.delete(action.id);
    }

    return state;
  },
  IMap<string, ElItem>()
);

/**
 * Provides page actions state
 */
export const PageActionsProvider = Provider;

/**
 * Get the current page actions
 */
export const usePageActions = (): ReactElement[] => {
  const state = useReducerState();

  return Array.from(state.sortBy((item) => item.order).values(), (item, key) =>
    cloneElement(item.el, { key })
  );
};

export interface PageActionsProps {
  children?: (pageActions: ReactElement[]) => ReactNode;
}

/**
 * Get the current page actions
 */
export const PageActions = ({ children }: PageActionsProps) => {
  const pageActions = usePageActions();
  return createElement(Fragment, null, children?.(pageActions));
};

let orderIndex = 0;
/**
 * Provide a page action to the page header
 */
export const usePageAction = (element: ReactElement) => {
  const dispatch = useReducerDispatch();

  const order = useConstant(() => ++orderIndex);
  const id = useConstant(() => uuid1());
  useLayoutEffect(() => {
    dispatch({ type: 'SET', id, element, order });

    return () => {
      dispatch({ type: 'REMOVE', id, prevElement: element });
    };
  }, [dispatch, element, id, order]);
};
