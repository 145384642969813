import { forwardRef } from 'react';
import { ComplianceLevel } from '../../../api';
import { useComplianceLevelLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ComplianceLevelFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a ComplianceLevel
 */
export const ComplianceLevelField = forwardRef(function ComplianceLevelField(
  props: ComplianceLevelFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    ComplianceLevel,
    useComplianceLevelLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
