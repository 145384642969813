import 'timm';
import { ReactNode, useMemo, createContext, useCallback } from 'react';
import { BrandingContext } from './context';
import { Branding, BrandingTheme } from './types';
import { useLocalStorage } from 'react-use';

interface BrandingProviderProps {
  branding: Branding;
  children?: ReactNode;
}

/**
 * Provider that overrides the branding with whitelabel branding
 */
export const BrandingProvider = (props: BrandingProviderProps) => {
  const { branding, children } = props;

  return (
    <BrandingContext.Provider value={branding}>
      {children}
    </BrandingContext.Provider>
  );
};

export const UpdateDemoThemeContext = createContext(
  (value: Partial<BrandingTheme> | null) => {}
);

/**
 * Branding provider for development that lets the ThemeDemo control the theme settings
 */
export const DemoBrandingProvider = (props: BrandingProviderProps) => {
  const { branding: baseBranding, children } = props;
  const [demoTheme, setDemoTheme] =
    useLocalStorage<Partial<BrandingTheme> | null>('demo-theme', null);
  const updateDemoTheme = useCallback(
    (value: Partial<BrandingTheme> | null) => {
      if (value === null) setDemoTheme(null);
      else setDemoTheme({ ...demoTheme, ...value });
    },
    [demoTheme, setDemoTheme]
  );

  const branding = useMemo<Branding>(
    () => ({
      ...baseBranding,
      theme: {
        ...baseBranding.theme,
        ...demoTheme,
      },
    }),
    [baseBranding, demoTheme]
  );

  return (
    <UpdateDemoThemeContext.Provider value={updateDemoTheme}>
      <BrandingContext.Provider value={branding}>
        {children}
      </BrandingContext.Provider>
    </UpdateDemoThemeContext.Provider>
  );
};
