import { getIn, useFormikContext } from '@johnrom/formik-v3';
import { mapValues } from 'lodash-es';
import { useCallback } from 'react';

/**
 * Partially "submit" a segment of the form,
 * with a dynamic name map.
 *
 * Does not actually submit the form. But runs a similar algorithim where it checks for errors
 * in that section of the form and if found marks fields as touched while returning false
 * so the action that triggered the partial submit (e.g. the "Done" button on editable cards)
 * can be cancelled.
 *
 * @note getNameMap should be memoized with useCallback
 * @see {@link usePartialSubmitFor} for use with a pre-defined nameMap
 */
export function usePartialSubmit<K extends string>(
  getNameMap: (name: string) => Record<K, string>
): (name: string) => Promise<boolean> {
  const { setFieldTouched, validateForm } = useFormikContext<any>();

  return useCallback(
    async (name: string) => {
      const formErrors = await validateForm();
      const nameMap = getNameMap(name);
      const partErrors = mapValues(nameMap, (name) => getIn(formErrors, name));
      const hasError = Object.values<string | undefined>(partErrors).some(
        (error) => !!error
      );

      if (hasError) {
        for (const name of Object.values<string>(nameMap)) {
          setFieldTouched(name, true);
        }

        return false;
      }

      return true;
    },
    [getNameMap, setFieldTouched, validateForm]
  );
}

/**
 * Partially "submit" a segment of the form,
 * with a pre-defined name map.
 *
 * @note nameMap should be memoized
 * @see {@link usePartialSubmit} for use with a dynamic nameMap
 */
export function usePartialSubmitFor<K extends string>(
  nameMap: Record<K, string>
): () => Promise<boolean> {
  const partialSubmit = usePartialSubmit(useCallback(() => nameMap, [nameMap]));
  return useCallback(() => partialSubmit(''), [partialSubmit]);
}
