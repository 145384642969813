import { ReviewStatus } from '../../api';

/**
 * Severity color for review statuses
 */
export type ReviewStatusSeverity =
  | 'neutral'
  | 'success'
  | 'warning'
  | 'failure';

const ReviewStatusSeverityMap: Record<ReviewStatus, ReviewStatusSeverity> = {
  [ReviewStatus.Approved]: 'success',
  [ReviewStatus.Rejected]: 'failure',
  [ReviewStatus.InProgress]: 'neutral',
  [ReviewStatus.UnReviewed]: 'neutral',
  [ReviewStatus.WaitingOnClient]: 'warning',
};

/**
 * Get the severity color for a compliance status
 */
export function getReviewStatusSeverity(
  status: ReviewStatus | undefined
): ReviewStatusSeverity {
  if (!status) return 'neutral';

  return ReviewStatusSeverityMap[status];
}
