import {
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  styled,
  useTheme,
} from '@mui/material';
import {
  cloneElement,
  ComponentProps,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ExpandIconButton } from '../../form';
import { getBackgroundTopColor, getSecondaryContrastText } from '../../theme';

interface OwnerState {
  disabled: boolean;
  active: boolean;
  collapseIcon: boolean;
}

const SidebarNavSectionRoot = styled(ListItemButton, {
  name: 'SidebarNavSection',
  slot: 'Root',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.getContrastText(
    getBackgroundTopColor(theme.palette.background)
  ),
  minHeight: 48,
  paddingLeft: theme.spacing(3),
  paddingRight: 0,
  '& .MuiListItemSecondaryAction-root': {
    right: 24 - 12,
  },

  ...(ownerState.disabled && {
    color: getSecondaryContrastText(
      getBackgroundTopColor(theme.palette.background)
    ),
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
}));

const SidebarNavSectionIcon = styled(ListItemIcon, {
  name: 'SidebarNavSection',
  slot: 'Icon',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  overflow: 'hidden',
  minWidth: 0,
  width: `calc(24px + ${theme.spacing(3)})`,
  color: 'inherit',
  ...(ownerState.collapseIcon && {
    width: `calc(24px + ${theme.spacing(2)})`,
  }),
  transition: theme.transitions.create(['minWidth', 'width', 'opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const SidebarNavSectionText = styled(ListItemText, {
  name: 'SidebarNavSection',
  slot: 'Text',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({}));

export type SidebarNavSectionProps = Omit<
  ComponentProps<typeof SidebarNavSectionRoot>,
  'ownerState'
> & {
  active?: boolean;
  collapseIcon?: boolean;
  disabled?: boolean;
  expandButton?: {
    enabled?: boolean;
    expanded?: boolean;
    'aria-controls'?: string;
    onExpandedChange?: (expanded: boolean) => void;
  };
  icon?: ReactElement;
  primary?: ReactNode;
  to?: string | undefined;
  variant?: 'nav' | 'subnav';
};

/**
 * Sidebar navigation section
 * (Desktop mode, top level section)
 */
const SidebarNavSection = (props: SidebarNavSectionProps) => {
  const theme = useTheme();
  const {
    disabled = false,
    collapseIcon = false,
    expandButton,
    icon,
    primary,
    active = false,
    to,
    onClick,
    className,
    ...other
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const click = useCallback(() => {
    if (to) navigate(to);
  }, [navigate, to]);

  const ownerState: OwnerState = {
    disabled,
    active,
    collapseIcon: !!collapseIcon,
  };

  return (
    <SidebarNavSectionRoot
      {...other}
      onClick={disabled ? undefined : onClick ?? click}
      ownerState={ownerState}
    >
      <SidebarNavSectionIcon color="inherit" ownerState={ownerState}>
        {icon && cloneElement(icon)}
      </SidebarNavSectionIcon>
      <SidebarNavSectionText
        primary={primary}
        primaryTypographyProps={{
          variant: 'body1',
        }}
        ownerState={ownerState}
      />
      {expandButton && (
        <Fade
          timeout={
            expandButton.expanded
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen
          }
          in={expandButton.enabled ?? true}
        >
          <ListItemSecondaryAction>
            <ExpandIconButton
              color="inherit"
              in={expandButton.expanded}
              aria-label={
                expandButton.expanded
                  ? t('button.collapseNavigation')
                  : t('button.expandNavigation')
              }
              aria-controls={expandButton['aria-controls']}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                expandButton.onExpandedChange?.(!expandButton.expanded);
              }}
            />
          </ListItemSecondaryAction>
        </Fade>
      )}
    </SidebarNavSectionRoot>
  );
};

export default SidebarNavSection;
