import {
  HelpOutline as HelpIcon,
  ShoppingCartOutlined as ShoppingCartIcon,
} from '@mui/icons-material';
import { List, styled, useMediaQuery } from '@mui/material';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PathPattern } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { useCan } from '../auth';
import {
  AccountsPageIcon,
  ClientsPageIcon,
  ContactsPageIcon,
  DashboardPageIcon,
  PaymentsPageIcon,
  ProfilePageIcon,
  SettingsPageIcon,
} from '../page-icons';
import { getBackgroundTopColor } from '../theme';
import { featFlag } from '../utils/featFlags';
import { thinScrollbar } from '../utils/scroll';
import { FooterSpacer } from './Footer';
import { useSidebarExpanded } from './sidebar-expansion';
import { SidebarHelpButton } from './sidebar/SidebarHelpButton';
import SidebarListSubitem from './sidebar/SidebarListSubitem';
import SidebarListSubnav from './sidebar/SidebarListSubnav';
import LogoBar from './ui/LogoBar';
import MiniCollapsibleSidebar, {
  MiniCollapsibleSidebarProps,
} from './ui/MiniCollapsibleSidebar';
import { SidebarCollapseButton } from './ui/SidebarCollapseButton';
import { SidebarHelpSection } from './ui/SidebarHelpSection';
import { SidebarSpacer } from './ui/SidebarSpacer';

const NavigationSidebarRoot = styled(MiniCollapsibleSidebar, {
  name: 'NavigationSidebar',
  slot: 'Root',
})(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: 'transparent',
    color: theme.palette.getContrastText(
      getBackgroundTopColor(theme.palette.background)
    ),
    border: 0,
    zIndex: 0,
  },
}));

const NavigationSidebarList = styled(List, {
  name: 'NavigationSidebar',
  slot: 'List',
})(({ theme }) => ({
  overflowX: 'hidden',
}));

const NavigationSidebarMainList = styled(NavigationSidebarList, {
  name: 'NavigationSidebar',
  slot: 'MainList',
})(({ theme }) => ({
  flex: 1,
  paddingTop: 0,
  overflowY: 'auto',
  ...thinScrollbar,
}));

interface NavigationItem {
  to: string;
  label: ReactNode;
  disabled: boolean;
  isActive: (pathname: string) => boolean;
}
interface NavigationItemOptions {
  to: string;
  label: ReactNode;
  disabled?: boolean;
  end?: boolean;
  not?: (PathPattern | string)[];
}

interface NavigationSection {
  name: string;
  icon: ReactElement;
  label: ReactNode;
  items: NavigationItem[];
}

export type NavigationSidebarProps = MiniCollapsibleSidebarProps;

const navigationSection = (
  name: NavigationSection['name'],
  section: Omit<NavigationSection, 'name' | 'items'>,
  items: (NavigationItem | undefined | null | false)[]
): NavigationSection => ({
  name,
  ...section,
  items: items.filter((item): item is NavigationItem => !!item),
});
const navigationItem = (
  to: NavigationItemOptions['to'],
  label: NavigationItemOptions['label'],
  extra?: Omit<NavigationItemOptions, 'to' | 'label'>
): NavigationItem => {
  const { end = false, not: notPatterns, disabled, ...other } = extra ?? {};
  return {
    to,
    label,
    ...other,
    disabled: disabled ?? false,
    isActive: (pathname: string) => {
      const not =
        notPatterns &&
        notPatterns.some(
          (pattern) =>
            !!matchPath(
              typeof pattern === 'string'
                ? { path: pattern, end: false }
                : { end: false, ...pattern },
              pathname
            )
        );

      return !not && !!matchPath({ path: to, end }, pathname);
    },
  };
};

const NavigationSidebar = (props: NavigationSidebarProps) => {
  const { ...other } = props;
  const [sidebarExpanded, setSidebarExpanded] = useSidebarExpanded();
  const can = useCan();
  const { t } = useTranslation();

  const isAdmin = can.read('PrivClients');
  const isTallViewport = useMediaQuery('(min-height: 1100px');
  const navigation: NavigationSection[] = useMemo(
    () => [
      navigationSection(
        'home',
        {
          label: t('page.home'),
          icon: <DashboardPageIcon />,
        },
        [navigationItem('/', t('page.dashboard'), { end: true })]
      ),
      navigationSection(
        'account',
        { icon: <AccountsPageIcon />, label: t('page.account') },
        [
          isAdmin &&
            navigationItem('/clients/accounts', t('page.allAccounts'), {
              disabled: !can.read('PrivAccounts'),
            }),
          navigationItem('/accounts', t('page.ourAccounts'), {
            disabled: !can.read('Accounts'),
          }),
          isAdmin &&
            navigationItem('/clients/activity', t('page.activity'), {
              disabled: !can.read('PrivActivities'),
            }),
          isAdmin &&
            navigationItem('/system-settings/account-blocks', t('page.blocks')),
        ]
      ),
      navigationSection(
        'payment',
        { icon: <PaymentsPageIcon />, label: t('page.payment') },
        [
          isAdmin &&
            navigationItem('/clients/payments', t('page.allPayments'), {
              disabled: !can.read('PrivPayments'),
            }),
          navigationItem('/payments', t('page.ourPayments'), {
            disabled: !can.read('Payments'),
            not: ['/payments/batch'],
          }),
          navigationItem('/payments/batch', t('page.batchPayments'), {
            disabled: !can.read('Payments'),
          }),
          isAdmin &&
            navigationItem('/clients/audit/payments', t('page.trace'), {
              disabled: !can.read('PrivPayments'),
            }),
        ]
      ),
      navigationSection(
        'contacts',
        { icon: <ContactsPageIcon />, label: t('page.contact') },
        [
          isAdmin &&
            navigationItem('/clients/contacts', t('page.allContacts'), {
              disabled: !can.read('PrivContacts'),
            }),
          navigationItem('/contacts', t('page.ourContacts'), {
            disabled: !can.read('Contacts'),
          }),
          // isAdmin &&
          //   navigationItem('/clients/thirdparties', t('page.allThirdParties'), {
          //     disabled: !can.read('PrivContacts'),
          //   }),
          // navigationItem('/thirdparties', t('page.ourThirdParties'), {
          //   disabled: !can.read('Contacts'),
          // }),
        ]
      ),
      ...(isAdmin
        ? [
            navigationSection(
              'client',
              {
                label: t('page.client'),
                icon: <ClientsPageIcon />,
              },
              [
                navigationItem('/clients', t('page.allClients'), {
                  not: [
                    '/clients/accounts',
                    '/clients/activity',
                    '/clients/payments',
                    '/clients/audit',
                    '/clients/contacts',
                    '/clients/thirdparties',
                  ],
                }),
                navigationItem(
                  '/system-settings/client-profiles',
                  t('page.clientProfiles')
                ),
                navigationItem(
                  '/system-settings/risk-surveys',
                  t('page.riskSurveys')
                ),
              ]
            ),
          ]
        : [
            navigationSection(
              'profile',
              { icon: <ProfilePageIcon />, label: t('page.profile') },
              [
                navigationItem('/profile', t('page.profile'), {
                  disabled: !can.read('Organizations'),
                }),
              ]
            ),
            navigationSection(
              'settings',
              { icon: <SettingsPageIcon />, label: t('page.settings') },
              [
                navigationItem('/settings', t('page.settings'), {
                  disabled: !can.read('Organizations'),
                  end: true,
                }),
                navigationItem('/settings/webhooks', t('page.webhooks'), {
                  disabled: !can.read('Webhooks'),
                }),
              ]
            ),
          ]),
    ],
    [can, isAdmin, t]
  );

  const { pathname } = useLocation();
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const activeSection = useMemo(() => {
    const section = navigation.find((section) => {
      return section.items.some((item) => item.isActive(pathname));
    });

    return section?.name;
  }, [navigation, pathname]);
  useEffect(() => {
    // Update expanded section on page navigation
    setExpandedSection(activeSection ?? null);
  }, [activeSection]);

  const isSectionExpanded = useCallback(
    (sectionName: string): boolean => {
      if (isTallViewport) {
        return true;
      } else {
        return expandedSection === sectionName;
      }
    },
    [expandedSection, isTallViewport]
  );
  const updateSectionExpanded = useCallback(
    (sectionName: string, expanded: boolean) => {
      if (!isTallViewport) {
        if (expanded) {
          setExpandedSection(sectionName);
        } else if (expandedSection === sectionName) {
          setExpandedSection(null);
        }
      }
    },
    [expandedSection, isTallViewport]
  );

  return (
    <NavigationSidebarRoot
      expanded={sidebarExpanded}
      PaperProps={{ elevation: 0 }}
      {...other}
    >
      <LogoBar expanded={sidebarExpanded} />
      <SidebarCollapseButton
        expanded={sidebarExpanded}
        onClick={() => setSidebarExpanded(!sidebarExpanded)}
      />
      <NavigationSidebarMainList>
        {navigation.map((section) => (
          <SidebarListSubnav
            key={section.name}
            sidebarExpanded={sidebarExpanded}
            to={section.items[0]?.to}
            icon={section.icon}
            primary={section.label}
            sectionName={section.name}
            expanded={isSectionExpanded(section.name)}
            onExpandedChange={(expanded: boolean) => {
              updateSectionExpanded(section.name, expanded);
            }}
            disableExpansion={isTallViewport}
            disabled={section.items.every((item) => item.disabled)}
            active={activeSection === section.name}
          >
            {section.items.map((item) => (
              <SidebarListSubitem
                key={item.to}
                to={item.to}
                primary={item.label}
                disabled={item.disabled}
                active={item.isActive(pathname)}
              />
            ))}
          </SidebarListSubnav>
        ))}

        <SidebarSpacer />

        <SidebarHelpSection>
          {featFlag('placeholders') && (
            <>
              <SidebarHelpButton
                expanded={sidebarExpanded}
                to="/offers"
                icon={<ShoppingCartIcon />}
                label={t('page.offsersAndServices')}
              />
              <SidebarHelpButton
                expanded={sidebarExpanded}
                to="/help"
                icon={<HelpIcon />}
                label={t('page.help')}
              />
            </>
          )}
        </SidebarHelpSection>
      </NavigationSidebarMainList>

      <FooterSpacer />
    </NavigationSidebarRoot>
  );
};

export default NavigationSidebar;
