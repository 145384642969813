import { Button, styled } from '@mui/material';
import axios from 'axios';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useError } from '../../utils/ErrorBoundary';
import { Page } from '../Page';
import { PageSheet } from '../ui';

const Dl = styled('dl')(() => ({
  '& dt': {
    '&:after': {
      content: '": "',
    },
  },
  '& dd': {},
}));

const dd = (label: string, value: ReactNode) =>
  value ? (
    <>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </>
  ) : undefined;

/**
 * Whole page error state
 */
export const ErrorPage = () => {
  const { t } = useTranslation();
  const [error, reset] = useError();

  return (
    <Page title={t('title.error')}>
      <PageSheet>
        {t('error.unexpectedErrorMessage')}
        {process.env.NODE_ENV === 'development' && (
          <Dl>
            {dd(
              'HTTP Error',
              axios.isAxiosError(error) && error?.response
                ? `${error.response?.status} ${error.response?.statusText}`
                : undefined
            )}
            {dd('Code', error?.code)}
            {dd('Path', error?.path)}
            {dd('Message', error?.message)}
            {dd(
              'Messages',
              error && 'messages' in error
                ? (error as any).messages?.map(
                    (line: string, index: number) => (
                      <Fragment key={index}>
                        {line}
                        <br />
                      </Fragment>
                    )
                  )
                : undefined
            )}
          </Dl>
        )}
        {process.env.NODE_ENV === 'development' && (
          <Button
            variant="contained"
            onClick={() => {
              reset();
            }}
          >
            {t('button.retry')}
          </Button>
        )}
      </PageSheet>
    </Page>
  );
};
