import { styled } from '@mui/material';
import { ComponentProps } from 'react';

const PageContentRoot = styled('div', { name: 'PageContent', slot: 'Root' })(
  ({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(3),
  })
);

export type PageContentProps = Omit<
  ComponentProps<typeof PageContentRoot>,
  'ownerState'
>;

/**
 * Page content wrapper that provides page gutters
 */
export const PageContent = (props: PageContentProps) => {
  const { children, ...other } = props;

  return <PageContentRoot {...other}>{children}</PageContentRoot>;
};
