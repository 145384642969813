import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CorporateIdType } from '../../api';

/**
 * Provide localized labels for the CorporateIdType enum
 */
export function useCorporateIdTypeLabels(): Record<CorporateIdType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<CorporateIdType, string> => ({
      BankStatement: t('enumLabel.corporateIdType.bankStatement'),
      Contract: t('enumLabel.corporateIdType.contract'),
      CorporateRegistration: t(
        'enumLabel.corporateIdType.corporateRegistration'
      ),
      IncorporationNumber: t('enumLabel.corporateIdType.incorporationNumber'),
      ListOfAuthorizedSignatories: t(
        'enumLabel.corporateIdType.listOfAuthorizedSignatories'
      ),
      ListOfDirectors: t('enumLabel.corporateIdType.listOfDirectors'),
      ListOfShareholders: t('enumLabel.corporateIdType.listOfShareholders'),
      PowerOfAttorney: t('enumLabel.corporateIdType.powerOfAttorney'),
      ProcessorLetter: t('enumLabel.corporateIdType.processorLetter'),
      SanctionsCheck: t('enumLabel.corporateIdType.sanctionsCheck'),
      ScreenCapture: t('enumLabel.corporateIdType.screenCapture'),
      TaxReturn: t('enumLabel.corporateIdType.taxReturn'),
      UtilityStatement: t('enumLabel.corporateIdType.utilityStatement'),
    }),
    [t]
  );
}
