import { useMemo } from 'react';
import { useJsonMemo } from '../utils/useJsonMemo';
import { useLocale } from './locale';

export interface CollatorApi {
  compare: Intl.Collator['compare'];
}

/**
 * Get a sort collator for the current locale
 */
export function useCollator(options?: Intl.CollatorOptions): CollatorApi {
  options = useJsonMemo(options);
  const locale = useLocale();
  const collator = useMemo(
    () => new Intl.Collator(locale, options),
    [locale, options]
  );

  return {
    compare: useMemo(() => collator.compare.bind(collator), [collator]),
  };
}
