import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { forwardRef, Ref } from 'react';
import {
  getMaskForDateTimeFormat,
  useDateFormatString,
  useDateTimeFormatHint,
} from '../../intl';
import { Time } from '../../utils/Time';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import {
  UseDatePickerFieldProps,
  useDatePickerFieldProps,
} from './internal/useDatePickerFieldProps';
import usePropsWithId from './internal/usePropsWithId';

export type DateFieldProps = Omit<
  UseDatePickerFieldProps,
  'dateFormat' | 'mask'
> &
  CommonValidatorProps;

/**
 * A <TextField> for a date
 */
export const DateField = forwardRef(function DateField(
  props: DateFieldProps,
  ref: Ref<HTMLInputElement>
) {
  props = usePropsWithId(props);
  const dateFormat = useDateFormatString();
  const getDateHint = useDateTimeFormatHint();
  const validate = useCommonValidate(props);

  const datePickerProps = useDatePickerFieldProps({
    ...props,
    label: `${props.label} (${getDateHint(dateFormat)})`,
    dateFormat,
    mask: getMaskForDateTimeFormat(dateFormat),
    validate,
  });

  if (props.readOnly)
    return (
      <ReadOnlyField ref={ref} {...datePickerProps}>
        {datePickerProps.value && (
          <Time
            format={DateTime.DATE_SHORT}
            dateTime={datePickerProps.value as DateTime}
          />
        )}
      </ReadOnlyField>
    );
  return <DatePicker ref={ref} {...datePickerProps} />;
});
