import { useMemo } from 'react';
import { QueryClient } from 'react-query';
import { ApiQueryOptions } from '../../api';
import {
  AdminApiQueryKey,
  ApiQueryState,
  Payment,
  PaymentsApiQueryKey,
  useAdminApi,
  useApiQuery,
  usePaymentsApi,
} from '../client';

/**
 * Handle cache updates for a Payment
 */
export function updateCachedPayment(
  queryClient: QueryClient,
  clientId: string | undefined,
  paymentId: string,
  payment?: Payment
): void {
  // Client
  if (payment) {
    queryClient.setQueryData(
      PaymentsApiQueryKey.getPayment(paymentId),
      payment
    );
  } else {
    queryClient.invalidateQueries(PaymentsApiQueryKey.getPayment(paymentId));
  }
  queryClient.invalidateQueries(PaymentsApiQueryKey.getPaymentsRoot);

  // Admin
  if (clientId) {
    // @fixme Try and get a ClientId from Payment as a fallback
    if (payment) {
      queryClient.setQueryData(
        AdminApiQueryKey.getClientPayment(clientId, paymentId),
        payment
      );
    } else {
      queryClient.invalidateQueries(
        AdminApiQueryKey.getClientPayment(clientId, paymentId)
      );
    }
    queryClient.invalidateQueries([
      AdminApiQueryKey.getClientPaymentsRoot,
      clientId,
    ]);
    queryClient.invalidateQueries(AdminApiQueryKey.getClientsPaymentsRoot);
  }
}

/**
 * Get a Payment
 *
 * @see {@link PaymentsApiMaker.GetPayment}
 * @see {@link AdminApiMaker.GetClientPayment}
 */
export function usePayment(
  clientId: string | undefined | null,
  paymentId: string,
  options: ApiQueryOptions<Payment> = {}
): ApiQueryState<Payment> {
  const paymentsApi = usePaymentsApi();
  const adminApi = useAdminApi();

  return useApiQuery(
    useMemo(() => {
      return clientId
        ? adminApi.getClientPayment(clientId ?? '', paymentId)
        : paymentsApi.getPayment(paymentId);
    }, [adminApi, clientId, paymentId, paymentsApi]),
    options
  );
}
