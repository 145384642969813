import {
  Accordion,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Box,
  IconButton,
  styled,
} from '@mui/material';
import { ExpandMoreOutlined as ExpandMoreIcon } from '@mui/icons-material';
import { forwardRef, MouseEvent, ReactNode, Ref } from 'react';
import { surfaceElevation } from '../theme';

const AccordionEntryRoot = styled(Accordion, {
  name: 'AccordionEntry',
  slot: 'Root',
})(({ theme }) => ({
  '&.Mui-expanded': {
    margin: theme.spacing(3, 0),
  },
}));

const AccordionEntrySummary = styled(AccordionSummary, {
  name: 'AccordionEntry',
  slot: 'Summary',
})({
  paddingRight: 0,
  margin: 0,
});

const AccordionEntryTitle = styled('h2', {
  name: 'AccordionEntry',
  slot: 'Title',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: 0,
  ...theme.typography.h5,
}));

const AccordionEntryDetails = styled(AccordionDetails, {
  name: 'AccordionEntry',
  slot: 'Details',
})({
  display: 'flex',
  flexDirection: 'column',
});

export type AccordionEntryProps = Omit<AccordionProps, 'children' | 'title'> & {
  /**
   * `id` used to derive ids for the accordion, summary, and contents
   */
  id?: string;
  /**
   * `icon` in the accordion summary.
   */
  icon?: ReactNode;
  /**
   * `title` in the accordion summary.
   */
  title?: ReactNode;
  /**
   * Extra end element in the accordion summary.
   */
  titleEnd?: ReactNode;
  /**
   * Disable formatted title
   */
  disableTitleTypography?: boolean;
  /**
   * Disable the expansion icon and always have the accordion expanded
   */
  disableCollapse?: boolean;
  /**
   * `children` in the accordion details.
   */
  children?: ReactNode;
  /**
   * Props applied to the `AccordionSummary` element.
   */
  AccordionSummaryProps?: AccordionSummaryProps;
  /**
   * Props applied to the `AccordionDetails` element.
   */
  AccordionDetailsProps?: AccordionDetailsProps;
  /**
   * Use a separate icon button with it's own change for the expansion icon
   */
  onIconButtonChange?: (e: MouseEvent, expanded: boolean) => void;
};

/**
 * A visually customized wrapper around Accordion, AccordionSummary, and AccordionDetails
 */
const AccordionEntry = (
  props: AccordionEntryProps,
  ref: Ref<HTMLDivElement>
) => {
  const {
    id,
    elevation = surfaceElevation,
    title,
    titleEnd,
    icon,
    children,
    disableCollapse = false,
    disableTitleTypography = false,
    AccordionSummaryProps,
    AccordionDetailsProps,
    onChange,
    onIconButtonChange,
    expanded,
    ...accordionProps
  } = props;

  return (
    <AccordionEntryRoot
      ref={ref}
      id={id}
      elevation={elevation}
      expanded={expanded ?? disableCollapse}
      onChange={onChange}
      {...accordionProps}
    >
      <AccordionEntrySummary
        expandIcon={
          disableCollapse ? null : (
            <IconButton
              color="inherit"
              onClick={
                onIconButtonChange
                  ? (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onIconButtonChange(e, !expanded);
                    }
                  : undefined
              }
            >
              <ExpandMoreIcon fontSize="large" />
            </IconButton>
          )
        }
        id={`${id}-header`}
        aria-controls={`${id}-content`}
        {...AccordionSummaryProps}
      >
        {disableTitleTypography ? (
          title
        ) : (
          <AccordionEntryTitle>
            {icon && <Box mr={1}>{icon}</Box>}
            {title}
          </AccordionEntryTitle>
        )}
        {titleEnd}
      </AccordionEntrySummary>
      <AccordionEntryDetails
        {...AccordionDetailsProps}
        // Hide elements of collapsed accordions so they don't show up in tests while accordion is collapsing
        aria-hidden={!expanded}
      >
        {children}
      </AccordionEntryDetails>
    </AccordionEntryRoot>
  );
};

export default forwardRef(AccordionEntry);
