import { Button, IconButton, styled, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { MinusCircleOutline as MinusCircleIcon } from 'mdi-material-ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationResponse } from '../../api';
import { useLuxonRelativeFormater } from '../../intl/formatter';
import { gridAreas } from '../../utils/css-grid';
import { getNotificationSingleDownloadBehaviour } from '../utils/behaviours';
import { NotificationTypeIcon } from './NotificationTypeIcon';

export interface NotificationProps {
  notification: NotificationResponse;
  markAsRead?: (ids: string[]) => void;
}

interface OwnerState {
  hasActions: boolean;
}

const NotificationRoot = styled('li', { name: 'Notification', slot: 'Root' })<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  position: 'relative',
  display: 'grid',
  gridGap: theme.spacing(0.5, 2),
  gridTemplateColumns: '1fr auto',
  gridTemplateAreas: ownerState.hasActions
    ? gridAreas([
        'title icon',
        'body body',
        'timestamp mark',
        'actions actions',
      ])
    : gridAreas(['title icon', 'body body', 'timestamp mark']),
  color: theme.palette.text.primary,
  margin: theme.spacing(1, 2),
  marginLeft: theme.spacing(3),
}));

const NotificationUnread = styled('div', {
  name: 'Notification',
  slot: 'Unread',
})<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  position: 'absolute',
  top: `calc(1.5rem / 2 - 8px / 2)`,
  left: `calc(-${theme.spacing(3)} / 2 - 8px / 2)`,
  display: 'block',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  width: 8,
  height: 8,
}));

const NotificationIcon = styled('div', { name: 'Notification', slot: 'Icon' })<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  gridArea: 'icon',
}));

const NotificationTitle = styled('h3', {
  name: 'Notification',
  slot: 'Title',
})<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  gridArea: 'title',
  ...theme.typography.body1,
  margin: 0,
}));

const NotificationBody = styled('div', { name: 'Notification', slot: 'Body' })<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  gridArea: 'body',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

const NotificationTimestamp = styled('div', {
  name: 'Notification',
  slot: 'Timestamp',
})<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  gridArea: 'timestamp',
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  alignSelf: 'center',
}));

const NotificationMark = styled('div', { name: 'Notification', slot: 'Mark' })<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  gridArea: 'mark',
}));

const NotificationActions = styled('div', {
  name: 'Notification',
  slot: 'Actions',
})<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  gridArea: 'actions',
}));

const ButtonExpiryMessageRoot = styled('span', {
  name: 'ButtonExpiryMessage',
  slot: 'Root',
})(({ theme }) => ({
  marginLeft: '1ch',
  fontWeight: 100,
  textTransform: 'none',
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
}));

function ButtonExpiryMessage({ expiry }: { expiry: string }) {
  const formatRelativeDate = useLuxonRelativeFormater();
  const relative = formatRelativeDate(DateTime.fromISO(expiry));
  const { t } = useTranslation();
  const past =
    DateTime.fromISO(expiry).toMillis() - DateTime.local().toMillis() < 0;

  return (
    <ButtonExpiryMessageRoot>
      {past
        ? t('button.expiredRelativeHint', { relative })
        : t('button.expiresRelativeHint', { relative })}
    </ButtonExpiryMessageRoot>
  );
}

/**
 * Notification list item
 */
export const Notification = (props: NotificationProps) => {
  const { notification, markAsRead } = props;
  const { id, Type, Title, Details, DateCreated, DatePublished, Read } =
    notification;
  const { t } = useTranslation();
  const formatRelativeDate = useLuxonRelativeFormater();

  let actions: ReactNode;

  const download = getNotificationSingleDownloadBehaviour(notification);
  if (download) {
    const expired =
      !!download.Expiry && DateTime.now() > DateTime.fromISO(download.Expiry);

    actions = (
      <Button
        variant="outlined"
        component="a"
        download
        href={download.FileUri}
        disabled={expired}
      >
        {t('button.download')}
        {download.Expiry && <ButtonExpiryMessage expiry={download.Expiry} />}
      </Button>
    );
  }

  const ownerState: OwnerState = {
    hasActions: !!actions,
  };

  return (
    <NotificationRoot ownerState={ownerState}>
      {!Read && (
        <NotificationUnread
          ownerState={ownerState}
          aria-label={t('tooltip.unread')}
        />
      )}
      <NotificationIcon ownerState={ownerState}>
        <NotificationTypeIcon type={Type} />
      </NotificationIcon>
      <NotificationTitle ownerState={ownerState}>{Title}</NotificationTitle>
      <NotificationBody ownerState={ownerState}>{Details}</NotificationBody>
      <NotificationTimestamp ownerState={ownerState}>
        {formatRelativeDate(DateTime.fromISO(DatePublished ?? DateCreated))}
      </NotificationTimestamp>
      {!Read && (
        <NotificationMark ownerState={ownerState}>
          <Tooltip title={t('button.markAsRead') as string}>
            <IconButton
              onClick={() => markAsRead?.([id])}
              aria-label={t('button.markAsRead')}
              size="small"
            >
              <MinusCircleIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </NotificationMark>
      )}
      {actions && (
        <NotificationActions ownerState={ownerState}>
          {actions}
        </NotificationActions>
      )}
    </NotificationRoot>
  );
};
