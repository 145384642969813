import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentType } from '../../api';

/**
 * Provide localized labels for the PaymentType enum
 */
export function usePaymentTypeLabels(): Record<PaymentType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<PaymentType, string> => ({
      Deposit: t('enumLabel.paymentType.deposit'),
      Payout: t('enumLabel.paymentType.payout'),
      Transfer: t('enumLabel.paymentType.transfer'),
    }),
    [t]
  );
}
