import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCountryNames } from '../../../intl';
import { useLabeledCodeOptionsProps } from '../../utils';
import { ComboBoxField, ComboBoxFieldProps } from '../ComboBoxField';

export type CountryFieldProps = Omit<
  ComboBoxFieldProps<string, false>,
  'options'
>;

/**
 * A <Select> field for selecting a country
 */
export const CountryField = forwardRef(function CountryField(
  props: CountryFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [countries, loadingCountries] = useCodes('countrycodes');
  const countryName = useCountryNames();
  const optionProps = useLabeledCodeOptionsProps(countries, countryName);

  return (
    <ComboBoxField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingCountries}
    />
  );
});
