import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { TextFieldProps } from '@mui/material';
import {
  ChangeEvent,
  createRef,
  FocusEvent,
  MutableRefObject,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useLocalStorage } from 'react-use';
import {
  CountryCode,
  useCountriesForCallingCode,
  useExamplePhoneNumbers,
  useLibPhoneNumber,
} from '../../../libphonenumber';
import { useFieldDisabled } from '../../disabled';
import { PhoneFlagSelect } from './PhoneFlagSelect';

export type UsePhoneFieldProps = Omit<
  FieldHookConfig<string>,
  'value' | 'multiple'
> &
  Omit<TextFieldProps, 'name' | 'value' | 'onChange' | 'error'> & {
    readOnly?: boolean;
  };

/**
 * useField wrapper that includes a variant of useTextFieldProps for PhoneField
 * @todo Consider making libphonenumber async loaded
 */
export function usePhoneFieldProps({
  name,
  disabled: disabledProp,
  helperText: helperTextProp,
  validate,
  ...props
}: UsePhoneFieldProps): TextFieldProps {
  const { AsYouType, getCountryCallingCode, parsePhoneNumberFromString } =
    useLibPhoneNumber();
  const countriesForCallingCode = useCountriesForCallingCode();
  const getExamplePhoneNumber = useExamplePhoneNumbers();
  const [initialCountry, setInitialCountry] = useLocalStorage<
    CountryCode | undefined | null
  >('initial-phone-input-country', null);
  const disabled = useFieldDisabled(disabledProp);
  const [
    { value: rawValue, onBlur: fieldOnBlur, ...field },
    meta,
    { setValue },
  ] = useField({
    name,
    validate,
    ...props,
  });
  const [{ nationalNumber, internationalNumber, country }, dispatch] =
    useReducer<
      Reducer<
        {
          country: CountryCode | undefined;
          internationalNumber: string | undefined;
          nationalNumber: string;
        },
        Partial<{
          country: CountryCode | undefined;
          internationalNumber: string;
          nationalNumber: string;
        }>
      >
    >(
      (state, action) => {
        return { ...state, ...action };
      },
      {
        country: initialCountry ?? undefined,
        internationalNumber: undefined,
        nationalNumber: '',
      }
    );

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  useEffect(() => {
    if (initialCountry === null) {
      // @todo On initial loading try using something like GeoIP and/or organization defaults to provide a default
      // setInitialCountry();
    }
  }, [initialCountry]);

  useEffect(() => {
    if (rawValue !== internationalNumber) {
      // If the international numbers dont match, the form's value probably changed
      const num = parsePhoneNumberFromString(rawValue, { extract: false });
      dispatch({
        country: num?.country ?? country,
        nationalNumber: num?.formatNational() ?? '',
        internationalNumber: num?.formatInternational() ?? '',
      });
    }
  }, [rawValue, internationalNumber, country, parsePhoneNumberFromString]);

  const { formatter, lastValueRef } = useMemo(() => {
    const formatter = new AsYouType(country);
    const lastValueRef = createRef<string>() as MutableRefObject<string>;
    return { formatter, lastValueRef };
  }, [AsYouType, country]);

  if (lastValueRef.current === null) {
    lastValueRef.current = nationalNumber;
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const lastValue = lastValueRef.current;

    const isAppend =
      rawValue.length > lastValue.length &&
      rawValue.slice(0, lastValue.length) === lastValue;
    const isTailDelete =
      rawValue.length < lastValue.length &&
      rawValue === lastValue.slice(0, rawValue.length);

    let nationalNumber = rawValue;
    if (isAppend) {
      // When appending add new characters into the formatter and update the input with the formatted value
      nationalNumber = formatter.input(rawValue.slice(lastValue.length));
    } else if (isTailDelete) {
      // AsYouType cannot format non-appending input changes so reset the formatter
      formatter.reset();
      nationalNumber = formatter.input(rawValue);
      // When the user is deleting trailing characters delete any trailing parenthesis
      // This will let the user continue to delete the numbers instead of
      // AsYouType re-inserting the parenthesis as the user deletes them
      if (nationalNumber.endsWith(')')) {
        nationalNumber = nationalNumber.substr(0, nationalNumber.length - 1);
      }
    } else {
      // AsYouType cannot format non-appending input changes so reset the formatter
      // But do not reformat as formatting on non-append input changes will cause the user to lose their caret position
      formatter.reset();
      formatter.input(rawValue);
    }
    lastValueRef.current = nationalNumber;
    const internationalNumber =
      formatter.getNumber()?.formatInternational() ?? nationalNumber;

    setValue(internationalNumber);

    dispatch({
      nationalNumber,
      internationalNumber,
    });

    const newCountry =
      internationalNumber &&
      parsePhoneNumberFromString(internationalNumber)?.country;
    if (newCountry && newCountry !== country) {
      dispatch({ country: newCountry });
    }
  };

  const onChangeCountry = useCallback(
    (newCountry: CountryCode) => {
      // Extract the national number from the raw international value
      // Use the AsYouType formatter so this works for incomplete phone numbers
      const formatter = new AsYouType();
      formatter.input(rawValue);
      const oldNational = formatter.getNationalNumber();

      // Combine the new country code with the natinoal number
      const newNumber = new AsYouType(newCountry);
      newNumber.input(oldNational);
      let internationalNumber =
        newNumber.getNumber()?.formatInternational() ?? '';
      let nationalNumber = newNumber.getNationalNumber() ?? '';

      setValue(internationalNumber);
      dispatch({ country: newCountry, nationalNumber, internationalNumber });

      setInitialCountry(newCountry);
    },
    [AsYouType, rawValue, setInitialCountry, setValue]
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      fieldOnBlur(e);

      const value = rawValue.trim();

      // Reformat with proper number formatting on blur
      const num = parsePhoneNumberFromString(value);
      if (num) {
        setValue(num.formatInternational());

        let newCountry: CountryCode | undefined = country;
        if (num.country) {
          // If the country is explicitly known from the phone number set the country and use the national number
          // This allows for switching between countries with the same calling code (e.g. US to CA based on calling code)
          newCountry = num.country;
        } else if (
          country &&
          getCountryCallingCode(country) !== num.countryCallingCode
        ) {
          // If the country code for the current country doesn't match the phone number's calling code
          // reset the country and use international formatting to avoid displaying the wrong country when the user inputs an international number
          newCountry = undefined;
        }

        dispatch({ country: newCountry });
        if (newCountry) {
          // Reformat input with a national number when country is known
          dispatch({
            nationalNumber: num.formatNational(),
            internationalNumber: num.formatInternational(),
          });
        } else {
          // Reformat input with the international number when the country is unknown
          dispatch({
            nationalNumber: num.formatInternational(),
            internationalNumber: num.formatInternational(),
          });
        }
        return;
      }

      // Clear value if it's just a +
      if (value === '+') {
        setValue('');
        dispatch({
          nationalNumber: '',
          internationalNumber: '',
        });
        return;
      }

      // Try setting the country and clearing the input if it's just a country code
      if (countriesForCallingCode.has(value)) {
        const cc = countriesForCallingCode.get(value);
        if (cc) {
          // Clear the input and update the country if the input is just a calling code that belongs to a single country
          setValue('');
          dispatch({
            country: cc,
            nationalNumber: '',
            internationalNumber: '',
          });
        } else {
          // Clear the country and use the input as the international number if it is just a calling code that belongs to multiple countries
          setValue(value);
          dispatch({
            country: undefined,
            nationalNumber: value,
            internationalNumber: value,
          });
        }
        return;
      }
    },
    [
      countriesForCallingCode,
      country,
      fieldOnBlur,
      getCountryCallingCode,
      parsePhoneNumberFromString,
      rawValue,
      setValue,
    ]
  );

  // Use the international number as default helper text when it does not match the text input
  const helperText =
    !helperTextProp &&
    internationalNumber &&
    nationalNumber !== internationalNumber
      ? internationalNumber
      : helperTextProp;

  const placeholder = useMemo(() => {
    return country ? getExamplePhoneNumber(country) : '';
  }, [country, getExamplePhoneNumber]);

  const hasFlag = !props.readOnly || !!internationalNumber;

  return {
    ...field,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled,
    placeholder,
    ...props,
    value: nationalNumber,
    onChange,
    onBlur,
    InputProps: {
      ...props.InputProps,
      startAdornment: hasFlag && (
        <PhoneFlagSelect
          value={country}
          readOnly={props.readOnly}
          disabled={disabled}
          onChange={onChangeCountry}
        />
      ),
    },
  };
}
