import { styled, Tooltip } from '@mui/material';
import { ChangeEvent, ReactNode } from 'react';
import { ReviewStatus } from '../../api';
import { useLabelFn, useReviewStatusLabels } from '../../intl';
import { getPaperBackground, surfaceElevation } from '../../theme';
import AccordionEntry, { AccordionEntryProps } from '../../ui/AccordionEntry';
import useHtmlId from '../../utils/useHtmlId';
import { ReviewStatusIcon } from './ReviewStatusIcon';

const ProfileAccordionRoot = styled(AccordionEntry, {
  name: 'ProfileAccordion',
  slot: 'Root',
})<{ ownerState: { elevation: number } }>(({ theme, ownerState }) => ({
  borderRadius: theme.shape.borderRadius,
  '&:first-of-type': { marginTop: 0 },
  '&:last-of-type': { marginBottom: 0 },
  '&:before': {
    top: -5,
    height: 5,
    opacity: 0,
    ...getPaperBackground(theme, ownerState.elevation),
  },
  '&:not(.Mui-expanded) + &&:not(.Mui-expanded):before': {
    opacity: 1,
  },
  '& > .MuiAccordionSummary-root': {
    '&.Mui-expanded': {
      minHeight: 56,
    },
    '& > .MuiAccordionSummary-content': {
      '&, &.Mui-expanded': {
        margin: theme.spacing(1, 0),
      },
    },
    // Hover styles
    '& > .MuiAccordionSummary-content, & > .MuiAccordionSummary-expandIconWrapper':
      {
        position: 'relative',
        zIndex: 2,
      },
    '&:after': {
      display: 'none',
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.action.hover,
    },
    '&:not(.Mui-expanded):hover:after': {
      display: 'block',
    },
  },
}));

export interface ProfileAccordionProps {
  status: ReviewStatus | undefined;
  title: string;
  titleEnd?: AccordionEntryProps['titleEnd'];
  expanded: boolean;
  onChange: (e: ChangeEvent<{}>) => void;
  onIconButtonChange?: AccordionEntryProps['onIconButtonChange'];
  children: ReactNode;
  disableCollapse?: boolean;
  elevation?: AccordionEntryProps['elevation'];
}

/**
 * <Accordion> customized for the Profile
 */
export const ProfileAccordion = (props: ProfileAccordionProps) => {
  const {
    status,
    title,
    titleEnd,
    expanded,
    onChange,
    onIconButtonChange,
    children,
    disableCollapse,
    elevation = surfaceElevation,
  } = props;
  const id = useHtmlId();
  const reviewStatusLabel = useLabelFn(useReviewStatusLabels());

  return (
    <ProfileAccordionRoot
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      expanded={expanded}
      onChange={onChange}
      onIconButtonChange={onIconButtonChange}
      disableCollapse={disableCollapse}
      id={id}
      icon={
        <Tooltip
          title={status ? reviewStatusLabel(status) : ''}
          disableInteractive
        >
          <ReviewStatusIcon status={status} />
        </Tooltip>
      }
      title={title}
      titleEnd={titleEnd}
      elevation={elevation}
      ownerState={{ elevation }}
    >
      {children}
    </ProfileAccordionRoot>
  );
};
