import { Avatar } from '@mui/material';
import {
  AlertOctagonOutline as AlertOctagonOutlineIcon,
  AlertOutline as AlertOutlineIcon,
  InformationOutline as InformationOutlineIcon,
} from 'mdi-material-ui';
import { cloneElement, forwardRef, ReactElement, Ref } from 'react';
import { NotificationType } from '../../api';

export interface NotificationTypeAvatarProps {
  type: NotificationType | undefined | null;
}

const FallbackNotificationTypeAvatar = (
  <Avatar sx={{ backgroundColor: 'primary.main' }}>
    <InformationOutlineIcon />
  </Avatar>
);
const NotificationTypeAvatars: Record<NotificationType, ReactElement> = {
  [NotificationType.Informational]: FallbackNotificationTypeAvatar,
  [NotificationType.Warning]: (
    <Avatar sx={{ backgroundColor: 'warning.main' }}>
      <AlertOutlineIcon />
    </Avatar>
  ),
  [NotificationType.Critical]: (
    <Avatar sx={{ backgroundColor: 'error.main' }}>
      <AlertOctagonOutlineIcon />
    </Avatar>
  ),
};

/**
 * Icon for notification type
 */
export const NotificationTypeAvatar = forwardRef(
  function NotificationTypeAvatar(
    props: NotificationTypeAvatarProps,
    ref: Ref<any>
  ) {
    const { type } = props;

    if (!type) return cloneElement(FallbackNotificationTypeAvatar, { ref });

    return cloneElement(NotificationTypeAvatars[type], { ref });
  }
);
