import { getBreadcrumbText, usePageBreadcrumbs } from '../breadcrumbs';
import { usePageTitle } from '../utils/usePageTitle';
import { PageActions, PageActionsProvider } from './actions';
import {
  PageBanner,
  PageBannerProps,
  PageContent,
  PageContentProps,
  PageHeader,
  PageHeaderProps,
} from './ui';

export type PageProps = Omit<PageContentProps, 'title'> &
  Pick<
    PageHeaderProps,
    'breadcrumbs' | 'title' | 'icon' | 'actions' | 'selection'
  > & {
    disablePageDomTitle?: boolean;
    PageHeaderProps?: PageHeaderProps;
    banner?: PageBannerProps['children'];
  };

/**
 * Page helper that combines <PageHeader> and <PageContent> together
 */
export const Page = (props: PageProps) => {
  const {
    breadcrumbs: breadcrumbsProp,
    title: titleProp,
    icon,
    disablePageDomTitle = false,
    actions,
    selection,
    children,
    PageHeaderProps,
    banner,
    ...pageContentProps
  } = props;
  const [breadcrumbsContext, titleContext] = usePageBreadcrumbs();
  const breadcrumbs = breadcrumbsProp ?? breadcrumbsContext;
  const title = titleProp ?? titleContext;

  let domTitle = undefined;
  if (!disablePageDomTitle && title) {
    domTitle = '';
    if (breadcrumbs && breadcrumbs.length > 0) {
      for (const crumb of breadcrumbs) {
        domTitle += `${getBreadcrumbText(crumb)} • `;
      }
    }
    domTitle += getBreadcrumbText(title);
  }
  usePageTitle(domTitle);

  return (
    <PageActionsProvider>
      <>
        <PageActions>
          {(pageActions) => (
            <PageHeader
              breadcrumbs={breadcrumbs}
              title={title}
              icon={icon}
              actions={
                <>
                  {actions}
                  {pageActions}
                </>
              }
              selection={selection}
              {...PageHeaderProps}
            />
          )}
        </PageActions>
        {banner ? <PageBanner>{banner}</PageBanner> : undefined}
        <PageContent {...pageContentProps}>{children}</PageContent>
      </>
    </PageActionsProvider>
  );
};
