import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ThirdPartyType } from '../../api';

/**
 * Provide localized labels for the ThirdPartyType enum
 */
export function useThirdPartyTypeLabels(): Record<ThirdPartyType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ThirdPartyType, string> => ({
      CompanyThirdParty: t('enumLabel.thirdPartyType.companyThirdParty'),
      IndividualThirdParty: t('enumLabel.thirdPartyType.individualThirdParty'),
    }),
    [t]
  );
}
