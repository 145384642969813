import clsx from 'clsx';
import { ReactElement } from 'react';
import './flag-icons.css';

export interface FlagProps {
  className?: string;
  code?: string;
  fallback?: ReactElement;
}

/**
 * Basic rectangular country flag
 */
export const Flag = (props: FlagProps) => {
  const { className, code, fallback } = props;

  if (code) {
    return <span className={clsx(className, `fi fi-${code.toLowerCase()}`)} />;
  }

  return fallback ?? null;
};
