import { IconButton, IconButtonProps } from '@mui/material';
import {
  DeleteOutlined as DeleteIcon,
  DeleteForeverOutlined as DeleteForeverIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export type DeleteIconRendererProps = IconButtonProps & {
  deleteIcon?: 'delete' | 'deleteForever';
};

export const DeleteIconRenderer = (props: DeleteIconRendererProps) => {
  const { deleteIcon = 'delete', ...other } = props;
  const { t } = useTranslation();

  let icon = <DeleteIcon />;
  if (deleteIcon === 'deleteForever') icon = <DeleteForeverIcon />;

  return (
    <IconButton {...other} aria-label={t('button.delete')}>
      {icon}
    </IconButton>
  );
};
