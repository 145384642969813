import { TextField } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { NumberInput, NumberInputProps } from '../input';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type NumberFieldProps = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  CommonValidatorProps &
  Pick<NumberInputProps, 'min' | 'max' | 'step'> & { readOnly?: boolean };

/**
 * A <TextField> for a simple number
 */
export const NumberField = forwardRef(function NumberField(
  props: NumberFieldProps,
  ref: Ref<HTMLInputElement>
) {
  const { min, max, step, ...other } = usePropsWithId(props);
  const validate = useCommonValidate(props);

  const textFieldProps = useTextFieldProps({
    ...other,
    validate,
    InputProps: {
      ...other.InputProps,
      inputComponent: NumberInput,
    },
    inputProps: {
      min,
      max,
      step,
      ...other.inputProps,
    },
  });

  if (props.readOnly) return <ReadOnlyField ref={ref} {...textFieldProps} />;

  return <TextField ref={ref} {...textFieldProps} />;
});
