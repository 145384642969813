import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ComplianceFrequency } from '../../api';

/**
 * Provide localized labels for the ComplianceFrequency enum
 */
export function useComplianceFrequencyLabels(): Record<
  ComplianceFrequency,
  string
> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ComplianceFrequency, string> => ({
      Monthly: t('enumLabel.complianceFrequency.monthly'),
      Quarterly: t('enumLabel.complianceFrequency.quarterly'),
      Yearly: t('enumLabel.complianceFrequency.yearly'),
      OnChange: t('enumLabel.complianceFrequency.onChange'),
      OnNew: t('enumLabel.complianceFrequency.onNew'),
    }),
    [t]
  );
}
