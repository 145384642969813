/**
 * Transactive server permissions
 *
 * NOTE: This file is auto generated by `npm run permissionsgen`
 * Do not edit the file manually.
 */
import { Authority } from '../api';

/**
 * User permission types
 */
export type PermissionType =
  | 'AccountBlock'
  | 'AccountWhiteLists'
  | 'Accounts'
  | 'Activities'
  | 'ClearBankPayment'
  | 'Contacts'
  | 'Contacts_Profile'
  | 'Countries'
  | 'Currencies'
  | 'Documents'
  | 'Exports'
  | 'LitasPayment'
  | 'Notifications'
  | 'OnUsPayment'
  | 'Organizations'
  | 'Payments'
  | 'PrivAccounts'
  | 'PrivActivities'
  | 'PrivAuditing'
  | 'PrivClients'
  | 'PrivContacts'
  | 'PrivDocuments'
  | 'PrivExports'
  | 'PrivNotifications'
  | 'PrivPayments'
  | 'PrivPayments_Mgmt'
  | 'PrivProducts'
  | 'PrivReports'
  | 'PrivSepa'
  | 'PrivSettings'
  | 'Recalls'
  | 'Reports'
  | 'ResetPassword'
  | 'SetPassword'
  | 'StarlingPayment'
  | 'Stats'
  | 'Watchlist'
  | 'Webhooks';

/**
 * Permissions an authority has
 */
export interface AuthorityPermissions {
  readOnly: PermissionType[];
  write: PermissionType[];
}

/**
 * Map of permissions belonging to each authority
 */
export const permissionMap: Record<Authority, AuthorityPermissions> = {
  ChangePassword: {
    readOnly: [],
    write: ['SetPassword'],
  },
  AccountInformation: {
    readOnly: [
      'AccountBlock',
      'AccountWhiteLists',
      'Accounts',
      'Activities',
      'Payments',
      'PrivExports',
      'PrivProducts',
      'PrivReports',
      'Recalls',
    ],
    write: ['Contacts_Profile', 'Notifications', 'SetPassword'],
  },
  Reporting: {
    readOnly: [
      'AccountBlock',
      'AccountWhiteLists',
      'Accounts',
      'Activities',
      'Contacts',
      'Payments',
      'PrivExports',
      'PrivProducts',
      'PrivReports',
      'Recalls',
      'Watchlist',
      'Webhooks',
    ],
    write: [
      'Contacts_Profile',
      'Exports',
      'Notifications',
      'Reports',
      'SetPassword',
    ],
  },
  Payment: {
    readOnly: [
      'Accounts',
      'Exports',
      'PrivExports',
      'PrivProducts',
      'PrivReports',
      'Reports',
    ],
    write: [
      'Contacts_Profile',
      'Notifications',
      'Payments',
      'Recalls',
      'SetPassword',
    ],
  },
  Admin: {
    readOnly: ['Exports', 'PrivProducts', 'Reports'],
    write: [
      'AccountWhiteLists',
      'Accounts',
      'Contacts',
      'Contacts_Profile',
      'Documents',
      'Notifications',
      'Organizations',
      'PrivDocuments',
      'ResetPassword',
      'SetPassword',
      'Webhooks',
    ],
  },
  Compliance: {
    readOnly: [
      'Exports',
      'PrivAuditing',
      'PrivContacts',
      'PrivExports',
      'PrivPayments',
      'PrivProducts',
      'PrivReports',
      'PrivSettings',
      'Reports',
    ],
    write: [
      'AccountBlock',
      'Contacts_Profile',
      'Documents',
      'Notifications',
      'PrivAccounts',
      'PrivActivities',
      'PrivClients',
      'PrivDocuments',
      'PrivNotifications',
      'PrivPayments_Mgmt',
      'SetPassword',
      'Watchlist',
    ],
  },
  ClientAdmin: {
    readOnly: [
      'Activities',
      'PrivActivities',
      'PrivAuditing',
      'PrivExports',
      'PrivPayments',
      'PrivReports',
    ],
    write: [
      'AccountBlock',
      'AccountWhiteLists',
      'Accounts',
      'Contacts',
      'Contacts_Profile',
      'Documents',
      'Exports',
      'Notifications',
      'Organizations',
      'Payments',
      'PrivAccounts',
      'PrivClients',
      'PrivContacts',
      'PrivDocuments',
      'PrivNotifications',
      'PrivProducts',
      'PrivSepa',
      'PrivSettings',
      'Recalls',
      'Reports',
      'ResetPassword',
      'SetPassword',
      'Watchlist',
      'Webhooks',
    ],
  },
  SuperAdmin: {
    readOnly: [
      'Activities',
      'PrivActivities',
      'PrivAuditing',
      'PrivExports',
      'PrivReports',
    ],
    write: [
      'AccountBlock',
      'AccountWhiteLists',
      'Accounts',
      'Contacts',
      'Contacts_Profile',
      'Documents',
      'Exports',
      'Notifications',
      'Organizations',
      'Payments',
      'PrivAccounts',
      'PrivClients',
      'PrivContacts',
      'PrivDocuments',
      'PrivNotifications',
      'PrivPayments',
      'PrivPayments_Mgmt',
      'PrivProducts',
      'PrivSepa',
      'PrivSettings',
      'Recalls',
      'Reports',
      'ResetPassword',
      'SetPassword',
      'Watchlist',
      'Webhooks',
    ],
  },
  PaymentOfficer: {
    readOnly: [
      'AccountBlock',
      'Activities',
      'Exports',
      'PrivActivities',
      'PrivAuditing',
      'PrivExports',
      'PrivProducts',
      'PrivReports',
      'PrivSettings',
      'Reports',
    ],
    write: [
      'Accounts',
      'Contacts',
      'Contacts_Profile',
      'Documents',
      'Notifications',
      'Payments',
      'PrivAccounts',
      'PrivClients',
      'PrivContacts',
      'PrivDocuments',
      'PrivNotifications',
      'PrivPayments',
      'PrivPayments_Mgmt',
      'PrivSepa',
      'Recalls',
      'SetPassword',
      'Watchlist',
    ],
  },
  ClientReporting: {
    readOnly: [
      'AccountBlock',
      'Activities',
      'PrivAccounts',
      'PrivActivities',
      'PrivAuditing',
      'PrivClients',
      'PrivContacts',
      'PrivExports',
      'PrivPayments',
      'PrivProducts',
      'PrivReports',
      'PrivSettings',
    ],
    write: [
      'Contacts_Profile',
      'Documents',
      'Exports',
      'Notifications',
      'PrivDocuments',
      'PrivNotifications',
      'Reports',
      'SetPassword',
    ],
  },
};
