import { AxiosResponse } from 'axios';
import invariant from 'invariant';
import { DateTime } from 'luxon';
import { MutableRefObject } from 'react';
import { promiseCancelationPassthrough } from '../../utils/CancelablePromise';
import { ApiHookOptions, ApiRequestTiming } from './types';

/**
 * Parse a HTTP Date header
 */
function parseDateHeader(dateHeader: string | undefined): DateTime | undefined {
  if (dateHeader) {
    return DateTime.fromHTTP(dateHeader);
  }

  return undefined;
}

type RequestTimingFn = <
  K extends 'requestStarted' | 'requestFinished' | 'requestDateHeader'
>(
  key: K,
  value: ApiRequestTiming[K]
) => void;

export function makeRequestTiming(
  lastRequestTiming: ApiHookOptions['lastRequestTiming']
): undefined | RequestTimingFn {
  let requestTiming: undefined | RequestTimingFn;
  if (lastRequestTiming) {
    const _requestTiming: Partial<
      Pick<
        ApiRequestTiming,
        'requestStarted' | 'requestFinished' | 'requestDateHeader'
      >
    > = {};

    requestTiming = (key, value) => {
      _requestTiming[key] = value;

      if (key === 'requestFinished') {
        invariant(lastRequestTiming, 'expected requestTiming to be a ref');
        const { requestStarted, requestFinished, requestDateHeader } =
          _requestTiming;
        invariant(requestStarted, 'requestStarted expected');
        invariant(requestFinished, 'requestFinished expected');
        lastRequestTiming.current = {
          requestStarted,
          requestFinished,
          requestDuration: requestFinished - requestStarted,
          requestDateHeader,
        };
      }
    };
  }

  return requestTiming;
}

/**
 * Wrap a promise execution with requestTiming handling
 */
export function wrapRequestTiming<TResult>(
  lastRequestTiming?: MutableRefObject<ApiRequestTiming | null>
): (
  queryPromise: Promise<AxiosResponse<TResult>>
) => Promise<AxiosResponse<TResult>> {
  const requestTiming = makeRequestTiming(lastRequestTiming);

  return (queryPromise) => {
    requestTiming?.('requestStarted', Date.now());
    return promiseCancelationPassthrough(queryPromise, (queryPromise) =>
      queryPromise
        .then((response) => {
          if (requestTiming) {
            requestTiming(
              'requestDateHeader',
              parseDateHeader(response.headers['date'])
            );
            requestTiming('requestFinished', Date.now());
          }

          return response;
        })
        .catch((err) => {
          if (err.response) {
            requestTiming?.(
              'requestDateHeader',
              parseDateHeader(err.response.headers['date'])
            );
          }
          requestTiming?.('requestFinished', Date.now());
          throw err;
        })
    );
  };
}
