import {
  MenuOpenOutlined as MenuOpenIcon,
  MenuOutlined as MenuIcon,
} from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

const SidebarCollapseButtonRoot = styled('div', {
  name: 'SidebarCollapseButton',
  slot: 'Root',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  justifyContent: 'flex-start',
  ...theme.mixins.toolbar,
  width: '100%',
  paddingLeft: 0,
}));

const SidebarCollapseButtonIconButton = styled(IconButton, {
  name: 'ComponentName',
  slot: 'IconButton',
})(({ theme }) => ({
  width: 48,
  height: 48,
  marginLeft: `calc(${theme.spacing(3)} - ${(48 - 24) / 2}px)`,
}));

const SidebarCollapseButtonIcon = styled('span', {
  name: 'SidebarCollapseButton',
  slot: 'Icon',
})(({ theme }) => ({
  position: 'relative',
  width: 24,
  height: 24,
  margin: `calc((24px - ${theme.spacing(3)}) / 2)`,
  '& > *': {
    position: 'absolute',
    top: 0,
    left: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    opacity: 1,
    '&.hidden': {
      opacity: 0,
    },
  },
}));

export type SidebarCollapseButtonProps = ComponentProps<
  typeof SidebarCollapseButtonRoot
> & {
  expanded?: boolean;
};

/**
 * Custom styled button used for expanding/collapsing sidebars
 */
export const SidebarCollapseButton = (props: SidebarCollapseButtonProps) => {
  const { expanded, className, ...other } = props;
  const { t } = useTranslation();

  return (
    <SidebarCollapseButtonRoot {...other}>
      <SidebarCollapseButtonIconButton
        color="inherit"
        aria-label={expanded ? t('collapseNavigation') : t('expandNavigation')}
      >
        <SidebarCollapseButtonIcon>
          <MenuIcon className={expanded ? 'hidden' : ''} />
          <MenuOpenIcon className={!expanded ? 'hidden' : ''} />
        </SidebarCollapseButtonIcon>
      </SidebarCollapseButtonIconButton>
    </SidebarCollapseButtonRoot>
  );
};
