import { createContext, createElement, ReactNode, useContext } from 'react';

const ReviewVisibleContext = createContext(true);

export interface ReviewVisibilityProps {
  visible: boolean;
  children?: ReactNode;
}

/**
 * Control the visibility of field reviews
 */
export const ReviewVisibility = (props: ReviewVisibilityProps) => {
  const { visible, children } = props;

  return createElement(
    ReviewVisibleContext.Provider,
    { value: visible },
    children
  );
};

/**
 * Are reviews visible
 */
export function useReviewVisible(): boolean {
  return useContext(ReviewVisibleContext);
}
