import { useMemo } from 'react';
import { CountryCode } from './types';
import { useLibPhoneNumber } from './useLibPhoneNumber';

/**
 * Get a map of countries that use a calling code
 */
export function useCountriesForCallingCode(): Map<string, CountryCode | null> {
  const { getCountries, getCountryCallingCode } = useLibPhoneNumber();
  return useMemo(() => {
    const countriesForCallingCode = new Map<string, CountryCode | null>();
    for (const country of getCountries()) {
      const callingCode = getCountryCallingCode(country);
      const prefix = '+' + callingCode;
      if (countriesForCallingCode.has(prefix)) {
        countriesForCallingCode.set(prefix, null);
      } else {
        countriesForCallingCode.set(prefix, country);
      }
    }

    return countriesForCallingCode;
  }, [getCountries, getCountryCallingCode]);
}
