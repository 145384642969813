import { CircularProgress, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Page } from './Page';

const LoadingPageRoot = styled(Page, { name: 'LoadingPage', slot: 'Root' })(
  ({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

/**
 * Whole page loading state
 */
export const LoadingPage = () => {
  const { t } = useTranslation();

  return (
    <LoadingPageRoot title={t('loadingElipsis')}>
      <CircularProgress size={64} />
    </LoadingPageRoot>
  );
};
