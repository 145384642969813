import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  FieldValidator,
} from '@johnrom/formik-v3';
import { DateTime } from 'luxon';
import { useRef } from 'react';

/**
 * Hack to make MUI's date field errors work with Formik
 *
 * MUI's date fields validate errors however the onError is not compatible with Formik.
 * Formik does have both declarative (async validate function) and imperative (setError) errors.
 * However any error set by setError is erased the next time Formik recieves user input.
 *
 * This hack works around the limitations and allows MUI's errors to be used in Formik
 */
export function useDateFieldErrorHack<Val = any>({
  validateProp,
  useFieldFn,
}: {
  validateProp: FieldValidator | undefined;
  useFieldFn: (
    validate: FieldValidator
  ) => [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>];
}): [FieldInputProps<Val>, FieldMetaProps<Val>, FieldHelperProps<Val>] {
  const lastError = useRef<string>();
  const validate: FieldValidator = (value) => {
    // If the value is invalid and we have an error from onError return it
    if (DateTime.isDateTime(value) && !value.isValid && lastError.current) {
      return lastError.current;
    }

    if (validateProp) return validateProp(value);
  };

  const [field, meta, { setError: _setError, ...helpers }] =
    useFieldFn(validate);

  const setError = (error: string | undefined): void => {
    // Store the current error for later use in
    lastError.current = error;
    // Try and wait for Formik's async validation to complete before setting the first error
    setImmediate(() => {
      _setError(error);
    });
  };

  return [field, meta, { setError, ...helpers }];
}
