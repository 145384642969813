import {
  KeyboardArrowDownOutlined as KeyboardArrowDownIcon,
  KeyboardArrowUpOutlined as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNumberFormatter } from '../intl';

const DataTablePaginationRoot = styled('div', {
  name: 'DataTablePagination',
  slot: 'Root',
})(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minHeight: 52,
}));

const DataTablePaginationLabel = styled('div', {
  name: 'DataTablePagination',
  slot: 'Label',
})(({ theme }) => ({
  ...theme.typography.body2,
  marginRight: theme.spacing(2),
}));

const DataTablePaginationActions = styled('div', {
  name: 'DataTablePagination',
  slot: 'Actions',
})(({ theme }) => ({
  '& > button + button': {
    marginLeft: theme.spacing(-1),
  },
}));

export interface DataTablePaginationProps {
  from?: number;
  to?: number;
  count?: number;
  hasMore?: boolean;
  onPrevPage?: () => void;
  onNextPage?: () => void;
}

/**
 * Table pagination UI for data tables
 */
export const DataTablePagination = forwardRef(function DataTablePagination(
  props: DataTablePaginationProps,
  ref: any
) {
  const {
    from: fromInt,
    to: toInt,
    count = undefined,
    hasMore = false,
    onPrevPage,
    onNextPage,
  } = props;
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter();
  const formatIndex = (num: number | undefined) =>
    num == null ? '?' : numberFormatter(num);

  const from = formatIndex(fromInt);
  const to = formatIndex(toInt);
  const total = formatIndex(count);
  const displayedRowsLabel: string = hasMore
    ? t('tablePagination.serverPageCount', { from, to, total })
    : t('tablePagination.pageCount', { from, to, total });

  return (
    <DataTablePaginationRoot ref={ref}>
      <DataTablePaginationLabel>{displayedRowsLabel}</DataTablePaginationLabel>
      <DataTablePaginationActions>
        <IconButton
          onClick={onPrevPage}
          disabled={fromInt == null || fromInt <= 1}
          color="inherit"
          aria-label={t('button.scrollToPreviousPage')}
          title={t('button.scrollToPreviousPage') as string}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
        <IconButton
          onClick={onNextPage}
          disabled={!count || toInt == null || (toInt >= count && !hasMore)}
          color="inherit"
          aria-label={t('button.scrollToNextPage')}
          title={t('button.scrollToNextPage') as string}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </DataTablePaginationActions>
    </DataTablePaginationRoot>
  );
});
