import { TextField } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { BicInput } from '../input';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type BicFieldProps = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  CommonValidatorProps & { readOnly?: boolean };

/**
 * A <TextField> for a BIC
 * @todo Uppercase format (on blur, using NumberField?)
 */
export const BicField = forwardRef(function BicField(
  props: BicFieldProps,
  ref: Ref<HTMLInputElement>
) {
  props = usePropsWithId(props);
  const validate = useCommonValidate(props, ({ bic }) => [bic()]);

  const textFieldProps = useTextFieldProps({
    ...props,
    validate,
    type: 'text',
    InputProps: {
      ...props.InputProps,
      inputComponent: BicInput as any,
    },
  });

  if (props.readOnly) return <ReadOnlyField ref={ref} {...textFieldProps} />;

  return <TextField ref={ref} {...textFieldProps} />;
});
