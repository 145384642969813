import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Page } from '../../page';

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <Page title={t('pageNotFound.title')}>
      <Paper>
        <Box p={1}>
          <Typography>{t('pageNotFound.message')}</Typography>
        </Box>
      </Paper>
    </Page>
  );
};

export default PageNotFound;
