import {
  ChangeEventHandler,
  forwardRef,
  useCallback,
  useMemo,
  Ref,
} from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import { useLocale } from '../../intl';

export type NumberInputProps = Omit<NumberFormatProps, 'onChange'> & {
  onChange: ChangeEventHandler<HTMLInputElement>;
  /**
   * Defines the minimum acceptable value. When used with the max and step attributes, lets you control the range and increment (such as even numbers only) that the user can enter.
   */
  min?: number;
  /**
   * Defines the maximum acceptable value. When used with the min and step attributes, lets you control the range and increment (such as only even numbers) that the user can enter.
   */
  max?: number;
  /**
   * Defines an increment or jump between values that you want to allow the user to enter. When used with the max and min attributes, lets you control the range and increment (for example, allow only even numbers) that the user can enter.
   */
  step?: number;
};

/**
 * A NumberFormat based <Input> compatible input that uses the locale's number format
 */
export const NumberInput = forwardRef(function NumberInput(
  props: NumberInputProps,
  ref: Ref<any>
) {
  const { min, max, step, onChange, ...other } = props;
  const { name } = props;
  const locale = useLocale();
  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(locale, {}),
    [locale]
  );
  const numberProps = useMemo<NumberFormatProps>(() => {
    const p = numberFormatter.formatToParts(123456789.01);
    const decimalSeparator =
      p.find((part) => part.type === 'decimal')?.value ?? '.';
    const thousandSeparator =
      p.find((part) => part.type === 'group')?.value ?? ',';

    const integerGroupPattern = p
      .filter((part) => part.type === 'integer')
      .map((part) => part.value.length)
      .join('-');
    // @note 'wan' (Chinese) style is also an option, but I can't find any locale that actually uses it
    const thousandsGroupStyle: NumberFormatProps['thousandsGroupStyle'] =
      integerGroupPattern === '2-2-2-3' ? 'lakh' : 'thousand';

    const allowNegative = !(typeof min === 'number' && min >= 0);

    return {
      min,
      max,
      step,
      thousandSeparator,
      decimalSeparator,
      thousandsGroupStyle,
      allowNegative,
    };
  }, [min, max, step, numberFormatter]);
  const change = useCallback(
    (values: NumberFormatValues) => {
      onChange?.({ target: { name, value: values.value } } as any);
    },
    [name, onChange]
  );

  return (
    <NumberFormat
      {...numberProps}
      {...other}
      isNumericString
      onValueChange={change}
      getInputRef={ref}
    />
  );
});
