import { NotificationsOffOutlined } from '@mui/icons-material';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { AlertOctagon as AlertOctagonIcon } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import { NotificationResponse } from '../../api';
import { Notification } from '../ui';
import { NotificationProps } from './Notification';

const NotificationListRoot = styled('ul', {
  name: 'NotificationList',
  slot: 'Root',
})(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

export interface NotificationListProps {
  notifications?: NotificationResponse[] | undefined | null;
  markAsRead: NotificationProps['markAsRead'];
  loading?: boolean;
  error?: boolean;
}

/**
 * A list of notifications
 */
export const NotificationList = (props: NotificationListProps) => {
  const { notifications, loading = false, error = false, markAsRead } = props;
  const { t } = useTranslation();

  if (!notifications && loading) {
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={2}
      >
        <CircularProgress variant="indeterminate" size={64} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={2}
      >
        <Typography component="p" variant="h6">
          {t('message.unexpectedError')}
        </Typography>
        <Box mt={1} fontSize={64}>
          <AlertOctagonIcon color="error" fontSize="inherit" />
        </Box>
      </Box>
    );
  }

  if (notifications && notifications.length === 0) {
    return (
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={2}
        pt={3}
        pb={2}
      >
        <Typography component="p" variant="subtitle1">
          {t('message.noNotifications')}
        </Typography>
        <Box sx={{ mt: 2, fontSize: 64 }}>
          <NotificationsOffOutlined fontSize="inherit" />
        </Box>
      </Box>
    );
  }

  return (
    <NotificationListRoot>
      {notifications?.map((notification) => (
        <Notification
          key={notification.id}
          markAsRead={markAsRead}
          notification={notification}
        />
      ))}
    </NotificationListRoot>
  );
};
