import { forwardRef } from 'react';
import { ContactAddressType } from '../../../api';
import { useContactAddressTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ContactAddressTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a ContactAddressType
 */
export const ContactAddressTypeField = forwardRef(
  function ContactAddressTypeField(
    props: ContactAddressTypeFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const optionsProps = useEnumOptions(
      ContactAddressType,
      useContactAddressTypeLabels()
    );

    return <SelectField ref={ref} {...other} {...optionsProps} />;
  }
);
