const emptyFeatFlags = {
  fullprofile: false,
  placeholders: false,
  virtualizePhoneCountries: false,
  disableResizeTableColumns: false,
  clientPagination: false,
  shortTableLimits: false,
};
type FeatFlag = keyof typeof emptyFeatFlags;

const FEATURE_FLAGS_STORAGE_KEY = 'feature-flags';
const featFlags: Record<FeatFlag, boolean> = Object.assign(
  {},
  emptyFeatFlags,
  JSON.parse(sessionStorage.getItem(FEATURE_FLAGS_STORAGE_KEY) ?? '{}')
);

/**
 * Check if a featFlag is enabled
 */
export function featFlag(flag: FeatFlag): boolean {
  if (process.env.NODE_ENV === 'test') return false;
  return featFlags[flag] ?? false;
}

/**
 * Check if any feature flags are enabled
 */
export function hasFeatFlags(): boolean {
  if (process.env.NODE_ENV === 'test') return false;
  for (const flag in emptyFeatFlags) {
    if (featFlag(flag as FeatFlag)) return true;
  }

  return false;
}

/**
 * Check if a string is a valid feature flag
 */
export function isFeatFlag(flag: string | symbol): flag is FeatFlag {
  return emptyFeatFlags.hasOwnProperty(flag);
}

if (process.env.NODE_ENV !== 'test') {
  if (typeof Proxy === 'function') {
    /**
     * Create a proxy that can be used to toggle feature flags
     */
    (window as any).featFlag = new Proxy(featFlags, {
      deleteProperty() {
        return false;
      },
      defineProperty() {
        return false;
      },
      set(featFlags, prop, value) {
        if (!isFeatFlag(prop)) return false;
        if (typeof value !== 'boolean')
          throw new TypeError('feature flags can only be in a boolean state');

        if (featFlags[prop] !== value) {
          featFlags[prop] = value;
          sessionStorage.setItem(
            FEATURE_FLAGS_STORAGE_KEY,
            JSON.stringify(featFlags)
          );

          console.warn(
            'Feature flags have changed, a refresh may be necessary.'
          );
        }

        return true;
      },
      getOwnPropertyDescriptor(featFlags, prop) {
        const desc = Object.getOwnPropertyDescriptor(featFlags, prop);
        return desc ? { ...desc, configurable: false } : undefined;
      },
    });

    // @fixme Hide these on the real non-staging production site
    if (hasFeatFlags()) {
      console.log(
        `[Feature Flags] Some feature flags enabled\n\n` +
          (Object.keys(emptyFeatFlags) as FeatFlag[])
            .map((flag) => `${flag} = ${featFlag(flag) ? 'ON' : 'OFF'}`)
            .join('\n')
      );
    } else {
      console.log('[Feature Flags] No flags enabled');
    }
  } else {
    console.log(
      '[Feature Flags] Feature flags disabled due to lack of Proxy support'
    );
  }
}
