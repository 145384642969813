import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  FormControlLabel,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Radio,
  // eslint-disable-next-line no-restricted-imports
  RadioGroup,
  RadioGroupProps,
  styled,
} from '@mui/material';
import invariant from 'invariant';
import {
  ChangeEvent,
  Fragment,
  memo,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useHtmlId from '../utils/useHtmlId';

const SimpleDialogContent = styled(DialogContent)(
  ({ theme }) => ({
    padding: theme.spacing(0, 1),
  }),
  {
    name: 'SimpleDialogContent',
  }
);

type DefaultOption<V> = { value: V; label: string };

export type PickerDialogProps<
  V extends string,
  O = { value: V; label: string }
> = Omit<DialogProps, 'onClose'> & {
  variant?: 'radio' | 'list';
  name?: string;
  value?: V;
  id?: string;
  title?: string;
  options: O[];
  onClose?: (value?: V, option?: O) => void;
  onPick?: (value: V, option?: O) => void;
  DialogTitleProps?: DialogTitleProps;
  RadioGroupProps?: RadioGroupProps;
  getValue?: (option: O) => V;
  getLabel?: (option: O) => string;
  renderListItem?: (
    option: O,
    props: Pick<ListItemButtonProps, 'onClick'>
  ) => ReactNode;
};

/**
 * Material picker dialog
 */
const PickerDialog = <V extends string, O = DefaultOption<V>>(
  props: PickerDialogProps<V, O>
) => {
  const {
    variant = 'radio',
    name,
    value: valueProp,
    id: idProp,
    title,
    options,
    onClose,
    onPick,
    getValue = (option) => (option as unknown as DefaultOption<V>).value,
    getLabel = (option) => (option as unknown as DefaultOption<V>).label,
    renderListItem = (option: O, props) => (
      <ListItemButton {...props}>
        <ListItemText primary={getLabel(option)} />
      </ListItemButton>
    ),
    DialogTitleProps,
    RadioGroupProps,
    ...other
  } = props;
  const { t } = useTranslation();
  const id = useHtmlId(name, idProp);
  const titleId = `${id}-title`;

  const [value, setValue] = useState<V | ''>(valueProp ?? '');
  const radioGroupRef = useRef(null);

  const cancel = () => {
    onClose?.();
  };
  const pick = (value: V, option?: O): void => {
    onClose?.(value, option);
    onPick?.(value, option);
  };
  const confirm = () => {
    invariant(value, 'selection required');
    pick(
      value,
      options.find((option) => getValue(option) === value)
    );
  };
  const change = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as V);
  };

  let hasConfirm = true,
    content;
  if (variant === 'list') {
    hasConfirm = false;
    content = (
      <SimpleDialogContent>
        <List>
          {options.map((option) => (
            <Fragment key={getValue(option)}>
              {renderListItem(option, {
                onClick: () => pick(getValue(option), option),
              })}
            </Fragment>
          ))}
        </List>
      </SimpleDialogContent>
    );
  } else {
    content = (
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          name={name}
          value={value}
          onChange={change}
          {...RadioGroupProps}
        >
          {options.map((option) => (
            <FormControlLabel
              key={getValue(option)}
              value={getValue(option)}
              control={<Radio />}
              label={getLabel(option)}
            />
          ))}
        </RadioGroup>
      </DialogContent>
    );
  }

  return (
    <Dialog maxWidth="xs" aria-labelledby={titleId} onClose={cancel} {...other}>
      <DialogTitle id={titleId} {...DialogTitleProps}>
        {title}
      </DialogTitle>
      {content}
      {hasConfirm && (
        <DialogActions>
          <Button onClick={cancel} color="primary">
            {t('button.cancel')}
          </Button>
          <Button disabled={!value} onClick={confirm} color="primary">
            {t('button.ok')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default memo(PickerDialog) as <V extends string, O = DefaultOption<V>>(
  props: PickerDialogProps<V, O>
) => ReactElement;
