import { Box, LinearProgressProps } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Attachment,
  AttachmentType,
  useAdminApi,
  useApiMutation,
  useDocumentsApi,
} from '../../api';
import { DialogProgress, FormDialog, FormDialogProps } from '../../dialogs';
import { fieldValue, FileField, FormError } from '../../form';
import { FilePreview } from '../../preview';

export interface NewDocumentValues {
  file: File | null;
}

export type NewDocumentDialogProps<DocumentValues extends NewDocumentValues> =
  Omit<FormDialogProps<DocumentValues>, 'onSubmit'> & {
    orgId?: string | null;
    attachmentType: AttachmentType;
    onUpload?: (
      attachment: Attachment,
      values: DocumentValues
    ) => void | Promise<void>;
  };

export const NewDocumentDialog = <DocumentValues extends NewDocumentValues>(
  props: NewDocumentDialogProps<DocumentValues>
) => {
  const { orgId = null, attachmentType, onUpload, children, ...other } = props;
  const { t } = useTranslation();
  const [progress, setProgress] = useState<LinearProgressProps | null>(null);
  const [uploadAttachment] = useApiMutation(
    useDocumentsApi().uploadAttachmentMutation()
  );
  const [uploadClientAttachment] = useApiMutation(
    useAdminApi().uploadClientAttachmentMutation()
  );
  const submit = useCallback(
    async (values: DocumentValues) => {
      const options: AxiosRequestConfig = {
        onUploadProgress({ lengthComputable, loaded, total }) {
          setProgress(
            lengthComputable
              ? { variant: 'determinate', value: (loaded / total) * 100 }
              : { variant: 'indeterminate' }
          );
        },
      };
      const attachment = orgId
        ? await uploadClientAttachment(
            orgId,
            attachmentType,
            values.file,
            options
          )
        : await uploadAttachment(attachmentType, values.file, options);

      await onUpload?.(attachment, values);
    },
    [orgId, uploadClientAttachment, attachmentType, uploadAttachment, onUpload]
  );

  return (
    <FormDialog<DocumentValues>
      maxWidth="sm"
      fullWidth
      title={t('dialogTitle.uploadDocument')}
      {...other}
      enableReinitialize
      onSubmit={submit}
      SubmitButtonProps={{
        validOnly: true,
      }}
      submitButtonText={t('button.upload')}
    >
      {children}

      {fieldValue<File | null>('file', (file, setFile) =>
        file ? (
          <Box mt={1}>
            <FilePreview file={file} onRemove={() => setFile(null)} />
          </Box>
        ) : (
          <FileField name="file" label={t('inputName.file')} required />
        )
      )}

      <FormError variant="snackbar" />

      {progress && <DialogProgress {...progress} />}
    </FormDialog>
  );
};
