import { TypeBackground } from '@mui/material/styles/createPalette';
import { CSSProperties } from 'react';

/**
 * Get the color of top of the background
 */
export function getBackgroundTopColor(themeBackground: TypeBackground): string {
  return typeof themeBackground.viewport === 'string'
    ? themeBackground.viewport
    : themeBackground.viewport
    ? themeBackground.viewport.start
    : themeBackground.default;
}

/**
 * Get the color of bottom of the background
 */
export function getBackgroundBottomColor(
  themeBackground: TypeBackground
): string {
  return typeof themeBackground.viewport === 'string'
    ? themeBackground.viewport
    : themeBackground.viewport
    ? themeBackground.viewport.end
    : themeBackground.default;
}

/**
 * Get the viewport background styles
 */
export function getViewportBackgroundStyles(
  themeBackground: TypeBackground
): CSSProperties {
  if (typeof themeBackground.viewport === 'string') {
    return {
      backgroundColor: themeBackground.viewport,
    };
  } else if (themeBackground.viewport) {
    const top = getBackgroundTopColor(themeBackground);
    const bottom = getBackgroundBottomColor(themeBackground);

    return {
      backgroundImage: `linear-gradient(to bottom, ${top} 0%, ${bottom} 100%)`,
    };
  }

  return {
    backgroundColor: themeBackground.default,
  };
}
