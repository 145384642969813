import {
  ThirdParty,
  ThirdPartyAdminSummary,
  ThirdPartySummary,
} from '../client';

/**
 * Get the ID of a ThirdParty
 */
export const getThirdPartyId = (
  thirdParty: ThirdParty | ThirdPartySummary | ThirdPartyAdminSummary
): string => {
  if ('Id' in thirdParty && thirdParty.Id) return thirdParty.Id;
  if ('id' in thirdParty && thirdParty.id) return thirdParty.id;
  throw new Error('ThirdParty is missing ID');
};
