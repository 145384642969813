import { styled } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import invariant from 'invariant';
import { useCallback, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

const SkipLinksLink = styled('a', { name: 'SkipLinks', slot: 'Link' })(
  ({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
    zIndex: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    textDecoration: 'none',

    '&:not(:focus):not(:active)': {
      ...visuallyHidden,
    },
  })
);

const SkipLinks = () => {
  const { t } = useTranslation();
  const skipLinkClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;
    invariant(target.href, 'link must have a href');
    const href = new URL(target.href, window.location.href);
    invariant(href.hash, 'link must have a hash');
    const el = document.querySelector(href.hash) as HTMLBaseElement;
    invariant(el, 'expected to find element');

    el.setAttribute('tabindex', '-1');
    el.focus();
    e.preventDefault();

    const blur = () => {
      el.removeAttribute('tabindex');
      el.removeEventListener('blur', blur);
    };

    el.addEventListener('blur', blur);
  }, []);

  return (
    <SkipLinksLink href="#content" onClick={skipLinkClick}>
      {t('navigation.skipToMainContent')}
    </SkipLinksLink>
  );
};

export default SkipLinks;
