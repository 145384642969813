import warning from 'warning';
import useConstant from 'use-constant';

let idCounter = 0;
function nextId(): number {
  idCounter += 1;
  warning(
    idCounter < 1e10,
    'Id: you might have a memory leak.' +
      'The idCounter is not supposed to grow that much.'
  );

  return idCounter;
}

/**
 * Return a unique ID string for the component
 *
 * Intended for use in the id="" of HTML elements to produce unique IDs in dialogs and forms.
 * This ensures we have id's necessary for accessibility without mandating manually written IDs where they are not needed
 *
 * - This string must be unique on the page
 * - This string must remain the same between renders of the same component until it unmounts
 * - In development mode `name` should be included in the ID simply to make finding the associated element easier in DevTools
 * - In production `name` may be ignored
 * - If the component accepts an `id` prop, that should be preferred over this value by passing it to `idFromProps`
 *
 * @param name An optional descriptive name, form fields should pass their `name` prop through, custom components may
 * @param idFromProps The `id` value
 * @example Components like form fields that take an ID:
 * ```
 * const { id: idProp } = props;
 * const id = useHtmlId(name, idProp);
 * ```
 * @example Components that take an ID and want a descriptive name:
 * ```
 * const { id: idProp } = props;
 * const id = useHtmlId('my-component', idProp);
 * ```
 * @example Components that don't take an ID but want a descriptive name to find the component:
 * ```
 * const { id: idProp } = props;
 * const id = useHtmlId('my-component');
 * ```
 * @example Where you just need an ID for accessibility but don't care about the name
 * ```
 * const id = useHtmlId();
 * ```
 */
export default function useHtmlId(
  name?: string | undefined | null,
  idFromProps?: string | undefined | null
): string {
  const id = useConstant(nextId);

  if (idFromProps) {
    return idFromProps;
  }

  if (process.env.NODE_ENV === 'production') {
    return `uid${id}`;
  }

  return `${name || 'unnamed'}-${id}`;
}
