import invariant from 'invariant';
import { ApiMutationState } from '../../client';

/**
 * Merge ApiMutationState from multiple API methods
 */
export function mergeMutationState<TResult = any>(
  ...mutationStates: ApiMutationState<TResult>[]
): ApiMutationState<TResult> {
  invariant(mutationStates[0], 'need at least one mutation state');

  return mutationStates.reduce((a, b) => {
    return {
      data: a.data ?? b.data,
      loading: a.loading || b.loading,
      error: a.error ?? b.error,
    };
  });
}
