import { ReactNode } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from './client/QueryClient';

export interface ApiProviderProps {
  children?: ReactNode;
}

export const ApiProvider = ({ children }: ApiProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools
        initialIsOpen={false}
        toggleButtonProps={{
          style: {
            margin: 8,
          },
        }}
      />
      {children}
    </QueryClientProvider>
  );
};
