import {
  RiskSurvey,
  RiskSurveySummary,
  RiskSurveyShortSummary,
} from '../client';

/**
 * Get the ID of a Risk Survey from either a full risk survey or a summary
 */
export const getRiskSurveyId = (
  riskSurvey: RiskSurvey | RiskSurveySummary | RiskSurveyShortSummary
): string => {
  if ('Id' in riskSurvey && riskSurvey.Id) return riskSurvey.Id;
  if ('id' in riskSurvey && riskSurvey.id) return riskSurvey.id;
  throw new Error('Risk Survey is missing ID');
};
