import { InputAdornment } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { NumberField, NumberFieldProps } from './NumberField';

export type PercentFieldProps = NumberFieldProps;

/**
 * A <TextField> for a percentage
 */
export const PercentField = forwardRef(function PercentField(
  props: PercentFieldProps,
  ref: Ref<HTMLInputElement>
) {
  const { InputProps, ...other } = props;

  return (
    <NumberField
      ref={ref as any}
      {...other}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
        ...InputProps,
      }}
    />
  );
});
