import { useMemo } from 'react';

/**
 * Memoize a value based on its JSON serialization
 *
 * This is typically used for basic options objects to make the options referentially equal
 * for the `deps` array in other hooks as long as the options do not change.
 *
 * @warning Will not work with values containing any complex types such as functions or symbols
 */
export function useJsonMemo<T>(value: T): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<T>(() => value, [JSON.stringify(value)]);
}
