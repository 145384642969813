import { CloseOutlined as CloseIcon } from '@mui/icons-material';
import { ButtonBase, Fade, styled, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BrandingDevtools } from '../branding/devtools/BrandingDevtools';

const DevToolCornerBoxRoot = styled('div')(
  ({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    // In development add extra padding to account for React Query's dev tools
    paddingLeft: process.env.NODE_ENV === 'development' ? 40 + 8 * 2 : 0,
    border: `1px solid ${theme.palette.divider}`,
    borderTopRightRadius: theme.shape.borderRadius,
  }),
  { name: 'DevToolCornerBoxRoot' }
);

const DevToolCloseButton = styled(ButtonBase)(
  ({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.secondary,
  }),
  { name: 'DevToolCloseButton' }
);

/**
 * Lower left corner box containing site development tools
 */
export const DevToolsCornerBox = (props: {
  stagingToolsHidden: boolean;
  setStagingToolsHidden: (stagingToolsHidden: boolean) => void;
}) => {
  const { stagingToolsHidden, setStagingToolsHidden } = props;
  const { t } = useTranslation();

  return (
    <Fade in={!stagingToolsHidden}>
      <DevToolCornerBoxRoot>
        <BrandingDevtools />
        <Tooltip
          title={t('button.hideStagingToolsToUserMenu') as string}
          onClick={() => setStagingToolsHidden(true)}
        >
          <DevToolCloseButton>
            <CloseIcon fontSize="small" />
          </DevToolCloseButton>
        </Tooltip>
      </DevToolCornerBoxRoot>
    </Fade>
  );
};
