import useHtmlId from './useHtmlId';

export interface DialogIds {
  id: string;
  titleId: string;
  descId: string;
}

/**
 * A return a unique dialog IDs for the title and description elements of a dialog
 * This wraps useHtmlId to reduce boilerplate in dialog code
 *
 * @see {@link useHtmlId} for more info
 *
 * - `titleId`: The ID for the , and `descId`
 */
export default function useDialogId(
  name?: string | undefined | null,
  idFromProps?: string | undefined | null
): DialogIds {
  const id = useHtmlId(name, idFromProps);
  const titleId = `${id}-title`;
  const descId = `${id}-description`;

  return { id, titleId, descId };
}
