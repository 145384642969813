import { useFieldMeta } from '@johnrom/formik-v3';
import { MenuItem } from '@mui/material';
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  AdminApiQueryKey,
  contactName,
  ContactsApiQueryKey,
  ContactShortSummary,
  getContactId,
  isResourceNotFoundError,
  makeGetAllClientContactSummariesFilterArgs,
  makeGetContactSummariesFilterArgs,
  useAdminApi,
  useContactsApi,
} from '../../api';
import { useOpenState } from '../../utils/useOpenState';
import { useOptionLabel, useOptions, useRenderOption } from '../utils';
import {
  DynamicComboBoxField,
  DynamicComboBoxFieldProps,
  useComboBoxApiQuery,
  useInputTextState,
  useOptionsWithCurrent,
} from './internal/DynamicComboBox';

export type ContactFieldProps = Omit<
  DynamicComboBoxFieldProps,
  | 'options'
  | 'getOptionLabel'
  | 'renderOption'
  | 'getOptionDisabled'
  | 'open'
  | 'onOpen'
  | 'onClose'
  | 'onInputChange'
  | 'loading'
> & {
  /**
   * Client to use contacts from (for admin use)
   */
  clientId?: string | undefined | null;
};

interface ContactShortSummaryValue extends ContactShortSummary {
  notFound?: boolean;
  loading?: boolean;
}

export const ContactField = forwardRef(function ContactField(
  props: ContactFieldProps,
  ref: Ref<any>
) {
  const { clientId, ...other } = props;
  const { open, openProps } = useOpenState();
  const [inputText, onInputChange] = useInputTextState();
  const searchText = inputText || undefined;
  const { value } = useFieldMeta<string | null>(other.name); // @fixme Combobox should be a hook so we can access the value

  const queryClient = useQueryClient();
  const adminApi = useAdminApi();
  const contactApi = useContactsApi();

  const {
    oneData: oneContact,
    oneError: oneContactError,
    allData: allContacts,
    loadingOne: loadingOneContact,
    loading,
    hasNextPage,
  } = useComboBoxApiQuery<ContactShortSummary>({
    value,
    open,
    searchText,
    getOneByValue: (value) =>
      clientId
        ? adminApi.getClientContactSummaryById(clientId, value)
        : contactApi.getContactById(value),
    searchAllByValue: (searchText) =>
      clientId
        ? adminApi.getAllClientContactSummaries(
            clientId,
            ...makeGetAllClientContactSummariesFilterArgs({ searchText })
          )
        : contactApi.getContactSummaries(
            ...makeGetContactSummariesFilterArgs({ searchText })
          ),
    preCacheOneItem: (contact) => {
      if (contact.Id) {
        queryClient.setQueryData(
          ContactsApiQueryKey.getContactById(contact.Id),
          contact
        );
      }
      if (clientId && contact.Id) {
        queryClient.setQueryData(
          AdminApiQueryKey.getClientContactSummaryById(clientId, contact.Id),
          contact
        );
      }
    },
  });

  const selectedContact = useMemo<ContactShortSummaryValue | null>(() => {
    if (oneContact) return oneContact;

    if (value) {
      return {
        Id: value,
        FirstName: value,
        notFound: isResourceNotFoundError(oneContactError),
        loading: loadingOneContact,
      };
    }

    return null;
  }, [loadingOneContact, oneContact, oneContactError, value]);

  const getId = getContactId;
  const contacts = useOptionsWithCurrent(allContacts, selectedContact, getId);
  const options = useOptions(contacts, getId);
  const getLabelForContact = contactName;
  const getOptionLabel = useOptionLabel(contacts, getId, getLabelForContact);
  const renderContactOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, contact: ContactShortSummary) => (
      <MenuItem {...props}>{getLabelForContact(contact)}</MenuItem>
    ),
    [getLabelForContact]
  );
  const renderOption = useRenderOption(contacts, getId, renderContactOption);

  let inputPrefix: ReactNode;
  if (selectedContact?.notFound) {
    inputPrefix = '❌';
  } else if (selectedContact?.loading) {
    inputPrefix = '⋯';
  }

  return (
    <DynamicComboBoxField
      ref={ref}
      {...other}
      {...{
        options,
        loading,
        hasNextPage,
        getOptionLabel,
        renderOption,
        onInputChange,
        inputPrefix,
      }}
      {...openProps}
    />
  );
});
