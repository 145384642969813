import { forwardRef } from 'react';
import { BeneficiaryType } from '../../../api';
import { useBeneficiaryTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type BeneficiaryTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a BeneficiaryType
 */
export const BeneficiaryTypeField = forwardRef(function BeneficiaryTypeField(
  props: BeneficiaryTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    BeneficiaryType,
    useBeneficiaryTypeLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
