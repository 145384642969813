import { SortByFn } from 'react-table';

/**
 * Create a SortByFn from a traditional x/y comparator function
 */
export function sortRowByComparator<T extends unknown>(
  comparator: (x: T, y: T) => number
): SortByFn<any> {
  return (rowX, rowY, colId) =>
    comparator(rowX.values[colId], rowY.values[colId]);
}

/**
 * Create a SortByFn from a basic logical comparison of a value accessor return
 */
export function sortRowByValue<T extends unknown>(
  fn: (value: T) => any
): SortByFn<any> {
  return sortRowByComparator<T>((x, y) => {
    const xx = fn(x);
    const yy = fn(y);

    return xx === yy ? 0 : xx > yy ? 1 : -1;
  });
}
