import { Context, createElement, ReactElement, ReactNode } from 'react';

export interface DispatchedStateProviderProps<S, D> {
  state: S;
  dispatch: D;
  children?: ReactNode;
}

/**
 * Helper utility that creates a single provider component that combines both a DispatchContext and StateContext into a single provider
 */
export function createDispatchedStateProvider<S, D>(
  StateContext: Context<S>,
  DispatchContext: Context<D>
): (props: DispatchedStateProviderProps<S, D>) => ReactElement {
  return (props: DispatchedStateProviderProps<S, D>): ReactElement => {
    const { state, dispatch, children } = props;

    return createElement(
      DispatchContext.Provider,
      { value: dispatch },
      createElement(StateContext.Provider, { value: state }, children)
    );
  };
}
