import { Fade, LinearProgress, LinearProgressProps } from '@mui/material';
import { useQueryProgress } from '../api';

export type SiteProgressProps = Omit<
  LinearProgressProps,
  'variant' | 'value' | 'valueBuffer'
>;

/**
 * Linear progress bar that shows when API querries are running
 */
const SiteProgress = (props: SiteProgressProps) => {
  const { ...other } = props;
  const { in: inProp, ...progress } = useQueryProgress();

  return (
    <Fade in={inProp} unmountOnExit>
      <LinearProgress {...other} {...progress} />
    </Fade>
  );
};

export default SiteProgress;
