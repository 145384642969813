import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactPhoneNumberType } from '../../api';

/**
 * Provide localized labels for the ContactPhoneNumberType enum
 */
export function useContactPhoneNumberTypeLabels(): Record<
  ContactPhoneNumberType,
  string
> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ContactPhoneNumberType, string> => ({
      Fax: t('enumLabel.contactPhoneNumberType.fax'),
      Home: t('enumLabel.contactPhoneNumberType.home'),
      Mobile: t('enumLabel.contactPhoneNumberType.mobile'),
      Urgent: t('enumLabel.contactPhoneNumberType.urgent'),
      Work: t('enumLabel.contactPhoneNumberType.work'),
    }),
    [t]
  );
}
