import { forwardRef } from 'react';
import { SimpleField, SimpleFieldProps } from './SimpleField';

export type MultilineFieldProps = SimpleFieldProps;

/**
 * A <TextField> for multiple lines of raw text
 */
export const MultilineField = forwardRef(function MultilineField(
  props: MultilineFieldProps,
  ref: any
) {
  return (
    <SimpleField ref={ref} {...props} multiline minRows={3} maxRows={10} />
  );
});
