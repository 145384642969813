import invariant from 'invariant';
import { useContact } from '../../api';
import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
} from '../../breadcrumbs';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const AllContactsTitle = t('page.allContacts');
export const OurContactsTitle = t('page.ourContacts');
export const ContactDetailsTitle = p(({ clientId, contactId }) => {
  invariant(contactId, 'contactId is expected');
  const { data: contact, loading } = useContact(clientId, contactId, {
    useErrorBoundary: false,
  });
  const name =
    contact?.FirstName || contact?.FamilyName
      ? `${contact.FirstName} ${contact.FamilyName}`.trim()
      : contact?.id;

  return [name, loading];
});
export const AddContactTitle = t('page.newContact');
