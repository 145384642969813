import invariant from 'invariant';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useJsonMemo } from '../utils/useJsonMemo';
import { useLocale } from './locale';

/**
 * Get a luxon format string for a format from the current locale
 * @warning Only works with numeric formats
 */
export function useLuxonFormatString(format: DateTimeFormatOptions): string {
  format = useJsonMemo(format);
  const locale = useLocale();

  return useMemo<string>(() => {
    const parts = DateTime.local()
      .setLocale(locale)
      .toLocaleParts(format) as Intl.DateTimeFormatPart[];

    return parts
      .map(({ type, value }) => {
        switch (type) {
          case 'literal':
            return value.replace(/([a-z]+)/, "'$1'");
          case 'year':
            return 'yyyy';
          case 'month':
            return 'MM';
          case 'day':
            return 'dd';
          case 'hour':
            return parts.some((part) => part.type === 'dayPeriod')
              ? 'hh'
              : 'HH';
          case 'minute':
            return 'mm';
          case 'second':
            return 'ss';
          case 'dayPeriod':
            return 'a';
          default:
            console.warn(`unknown DateTimeFormatPart type "${type}"`);
            return '';
        }
      })
      .join('');
  }, [format, locale]);
}

/**
 * Get a luxon date format string for the current locale's date format
 * @warning Only works with numeric formats
 */
export function useDateFormatString(
  format: DateTimeFormatOptions = DateTime.DATE_SHORT
): string {
  return useLuxonFormatString(format);
}

/**
 * Get a luxon date format string for the current locale's date format
 * @warning Only works with numeric formats
 */
export function useDateTimeFormatString(
  format: DateTimeFormatOptions = DateTime.DATETIME_SHORT_WITH_SECONDS
): string {
  return useLuxonFormatString(format);
}

/**
 * Get a luxon time format string for the current locale's time format
 * @warning Only works with numeric formats
 */
export function useTimeFormatString(
  format: DateTimeFormatOptions = DateTime.TIME_WITH_SECONDS
): string {
  return useLuxonFormatString(format);
}

/**
 * Accepts a luxon format string and runs a replacement function separately over the non-literal portion of the format string
 * Then returns the replacements with the literal's quoting removed.
 */
function luxonFormatStringReplace(
  format: string,
  replace: (str: string) => string
): string {
  return format
    .split(/'([^']*)'/)
    .map((m, i) => (i % 2 === 0 ? replace(m) : m))
    .join('');
}

/**
 * Get a mask string for a date/time format string
 *
 * @note @mui/picker's built-in mask generation does not work with formats like French (Canada)'s "HH 'h' mm"
 *       so for now we use this to compute the mask ourself. This may need revisiting when they release their next major version.
 */
export function getMaskForDateTimeFormat(
  format: string,
  maskChar: string = '_'
): string {
  invariant(maskChar.length === 1, 'maskChar must be a single character');
  const mask = luxonFormatStringReplace(format, (m) =>
    m.replace(/[a-z]/gi, maskChar)
  );
  return mask;
}

/**
 * Get a function that will return a localized human-friendly hint string for a date format
 */
export function useDateTimeFormatHint(): (format: string) => string {
  const { t } = useTranslation();
  return useCallback(
    (format) => {
      const hints: Record<string, string> = {
        HH: t('dateTimeCodeHints.HH'),
        MM: t('dateTimeCodeHints.MM'),
        a: t('dateTimeCodeHints.a'),
        dd: t('dateTimeCodeHints.dd'),
        hh: t('dateTimeCodeHints.hh'),
        mm: t('dateTimeCodeHints.mm'),
        ss: t('dateTimeCodeHints.ss'),
        yyyy: t('dateTimeCodeHints.yyyy'),
      };

      return luxonFormatStringReplace(format, (m) =>
        m.replace(/yyyy|MM|dd|hh|HH|mm|ss|a/g, (code) => hints[code] ?? code)
      );
    },
    [t]
  );
}
