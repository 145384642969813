import { styled } from '@mui/material';

/**
 * Spacer in between main and help navigation sections
 */
export const SidebarSpacer = styled('div', { name: 'SidebarSpacer' })(
  ({ theme }) => ({
    flex: 1,
  })
);
