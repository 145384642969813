// eslint-disable-next-line no-restricted-imports
import { InputBase, InputBaseProps, styled } from '@mui/material';

const FilterTextInputContainer = styled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    '& input': {
      padding: theme.spacing(0.5, 1),
      ...theme.typography.body2,
    },
  }),
  { name: 'FilterTextInputContainer' }
);

export type FilterTextInputProps = Pick<
  InputBaseProps,
  | 'autoComplete'
  | 'autoFocus'
  | 'defaultValue'
  | 'disabled'
  | 'id'
  | 'inputProps'
  | 'inputRef'
  | 'multiline'
  | 'name'
  | 'onBlur'
  | 'onChange'
  | 'onFocus'
  | 'required'
  | 'rows'
  | 'maxRows'
  | 'minRows'
  | 'type'
  | 'value'
> & {
  label?: string;
  InputProps?: InputBaseProps;
};

const FilterTextInput = (props: FilterTextInputProps) => {
  const {
    autoComplete,
    autoFocus,
    defaultValue,
    disabled,
    id,
    inputProps: inputPropsProps,
    InputProps: InputPropsProps,
    inputRef,
    label,
    multiline,
    name,
    onBlur,
    onChange,
    onFocus,
    required,
    rows,
    maxRows,
    minRows,
    type,
    value,
  } = props;

  const InputProps: InputBaseProps = {
    autoComplete,
    autoFocus,
    defaultValue,
    disabled,
    id,
    inputRef,
    multiline,
    name,
    onBlur,
    onChange,
    onFocus,
    required,
    rows,
    maxRows,
    minRows,
    type,
    value,
    ...InputPropsProps,
  };

  const inputProps = { 'aria-label': label, ...inputPropsProps };

  return (
    <FilterTextInputContainer>
      <InputBase placeholder={label} {...InputProps} inputProps={inputProps} />
    </FilterTextInputContainer>
  );
};

export default FilterTextInput;
