import { AxiosRequestConfig } from 'axios';
import { useCallback, useMemo } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import {
  AdminApiActivateClientContactArgs,
  AdminApiQueryKey,
  AdminApiSuspendClientContactArgs,
  AdminApiTerminateClientContactArgs,
  ApiMutationOptions,
  ApiMutationState,
  ApiQueryOptions,
  ApiQueryState,
  Contact,
  ContactsApiActivateContactArgs,
  ContactsApiQueryKey,
  ContactsApiSuspendContactArgs,
  ContactsApiTerminateContactArgs,
  ContactSummary,
  useAdminApi,
  useApiMutation,
  useApiQuery,
  useContactsApi,
} from '../client';
import {
  GetContactsFilters,
  makeGetAllClientsContactsFilterArgs,
  makeGetContactsFilterArgs,
} from '../filters';
import { mergeMutationState } from './internal/mergeMutationState';

/**
 * Handle cache updates for a Contact
 */
export function updateCachedContact(
  queryClient: QueryClient,
  clientId: string | undefined,
  contactId: string,
  contact?: Contact
): void {
  // Client
  if (contact) {
    queryClient.setQueryData(
      ContactsApiQueryKey.getContact(contactId),
      contact
    );
  } else {
    queryClient.invalidateQueries(ContactsApiQueryKey.getContact(contactId));
  }
  queryClient.invalidateQueries(ContactsApiQueryKey.getContactsRoot);
  queryClient.invalidateQueries(ContactsApiQueryKey.getContactSummariesRoot);

  // Admin
  if (clientId) {
    // @fixme Try and get a ClientId from Contact as a fallback
    if (contact) {
      queryClient.setQueryData(
        [AdminApiQueryKey.getClientContact(clientId, contactId)],
        contact
      );
    } else {
      queryClient.invalidateQueries(
        AdminApiQueryKey.getClientContact(clientId, contactId)
      );
    }
    queryClient.invalidateQueries([
      AdminApiQueryKey.getAllClientContactsRoot,
      clientId,
    ]);
    queryClient.invalidateQueries(AdminApiQueryKey.getAllClientsContactsRoot);
    queryClient.invalidateQueries([
      AdminApiQueryKey.getAllClientContactSummariesRoot,
      clientId,
    ]);
  }
}

/**
 * Get a Contact
 * @see {@link AdminApiMaker.getClientContact}
 * @see {@link ContactsApiMaker.getContact}
 */
export function useContact(
  clientId: string | undefined | null,
  contactId: string | undefined,
  options: ApiQueryOptions<Contact> = {}
): ApiQueryState<Contact> {
  const contactApi = useContactsApi();
  const adminApi = useAdminApi();

  return useApiQuery(
    useMemo(() => {
      if (!contactId) return null;
      return clientId
        ? adminApi.getClientContact(clientId, contactId)
        : contactApi.getContact(contactId);
    }, [adminApi, clientId, contactApi, contactId]),
    { ...options, skip: options.skip || !contactId }
  );
}

/**
 * Get a list of contacts
 */
export function useContactsQuery(
  variant: 'client' | 'admin',
  clientId: string | undefined | null,
  filter: GetContactsFilters | undefined,
  options?: ApiQueryOptions<Contact[] | ContactSummary[]>
) {
  const contactsApi = useContactsApi();
  const adminApi = useAdminApi();

  return useApiQuery(
    useMemo(() => {
      if (variant === 'admin') {
        return clientId
          ? adminApi.getAllClientContacts(clientId)
          : adminApi.getAllClientsContacts(
              ...makeGetAllClientsContactsFilterArgs(filter ?? {})
            );
      } else {
        return contactsApi.getContacts(
          ...makeGetContactsFilterArgs(filter ?? {})
        );
      }
    }, [adminApi, clientId, contactsApi, filter, variant]),
    options
  );
}

/**
 * Suspend a contact (client and admin compatible)
 */
export function useSuspendContactMutation(
  options: ApiMutationOptions<
    ContactsApiSuspendContactArgs | AdminApiSuspendClientContactArgs,
    Contact
  >
): [
  (clientId: string | undefined, contactId: string) => Promise<Contact>,
  ApiMutationState<Contact>
] {
  const queryClient = useQueryClient();
  const [suspendContact, clientMutationState] = useApiMutation({
    ...options,
    ...useContactsApi().suspendContactMutation(),
    onSuccess(...args) {
      const [contact, [contactId]] = args;
      updateCachedContact(queryClient, undefined, contactId, contact);
      options?.onSuccess?.(...args);
    },
  });
  const [suspendClientContact, adminMutationState] = useApiMutation({
    ...options,
    ...useAdminApi().suspendClientContactMutation(),
    onSuccess(...args) {
      const [contact, [clientId, contactId]] = args;
      updateCachedContact(queryClient, clientId, contactId, contact);
      options?.onSuccess?.(...args);
    },
  });

  return [
    useCallback(
      async (
        clientId: string | undefined,
        contactId: string,
        axiosOptions?: AxiosRequestConfig
      ) => {
        if (clientId) {
          return await suspendClientContact(clientId, contactId, axiosOptions);
        } else {
          return await suspendContact(contactId, axiosOptions);
        }
      },
      [suspendClientContact, suspendContact]
    ),
    mergeMutationState(clientMutationState, adminMutationState),
  ];
}

/**
 * Reactivate a contact (client and admin compatible)
 */
export function useActivateContactMutation(
  options: ApiMutationOptions<
    ContactsApiActivateContactArgs | AdminApiActivateClientContactArgs,
    Contact
  >
): [
  (clientId: string | undefined, contactId: string) => Promise<Contact>,
  ApiMutationState<Contact>
] {
  const queryClient = useQueryClient();
  const [activateContact, clientMutationState] = useApiMutation({
    ...options,
    ...useContactsApi().activateContactMutation(),
    onSuccess(...args) {
      const [contact, [contactId]] = args;
      updateCachedContact(queryClient, undefined, contactId, contact);
      options?.onSuccess?.(...args);
    },
  });
  const [activateClientContact, adminMutationState] = useApiMutation({
    ...options,
    ...useAdminApi().activateClientContactMutation(),
    onSuccess(...args) {
      const [contact, [clientId, contactId]] = args;
      updateCachedContact(queryClient, clientId, contactId, contact);
      options?.onSuccess?.(...args);
    },
  });

  return [
    useCallback(
      async (
        clientId: string | undefined,
        contactId: string,
        axiosOptions?: AxiosRequestConfig
      ) => {
        if (clientId) {
          return await activateClientContact(clientId, contactId, axiosOptions);
        } else {
          return await activateContact(contactId, axiosOptions);
        }
      },
      [activateClientContact, activateContact]
    ),
    mergeMutationState(clientMutationState, adminMutationState),
  ];
}

/**
 * Terminate a contact (client and admin compatible)
 */
export function useTerminateContactMutation(
  options: ApiMutationOptions<
    ContactsApiTerminateContactArgs | AdminApiTerminateClientContactArgs,
    Contact
  >
): [
  (clientId: string | undefined, contactId: string) => Promise<Contact>,
  ApiMutationState<Contact>
] {
  const queryClient = useQueryClient();
  const [terminateContact, clientMutationState] = useApiMutation({
    ...options,
    ...useContactsApi().terminateContactMutation(),
    onSuccess(...args) {
      const [contact, [contactId]] = args;
      updateCachedContact(queryClient, undefined, contactId, contact);
      options?.onSuccess?.(...args);
    },
  });
  const [terminateClientContact, adminMutationState] = useApiMutation({
    ...options,
    ...useAdminApi().terminateClientContactMutation(),
    onSuccess(...args) {
      const [contact, [clientId, contactId]] = args;
      updateCachedContact(queryClient, clientId, contactId, contact);
      options?.onSuccess?.(...args);
    },
  });

  return [
    useCallback(
      async (
        clientId: string | undefined,
        contactId: string,
        axiosOptions?: AxiosRequestConfig
      ) => {
        if (clientId) {
          return await terminateClientContact(
            clientId,
            contactId,
            axiosOptions
          );
        } else {
          return await terminateContact(contactId, axiosOptions);
        }
      },
      [terminateClientContact, terminateContact]
    ),
    mergeMutationState(clientMutationState, adminMutationState),
  ];
}
