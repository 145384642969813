import { Validator } from './validator';

/**
 * Compose multiple validation functions into one validation function
 */
export function composeValidators(...validators: Validator[]): Validator {
  return (value) => {
    for (const validator of validators) {
      const result = validator(value);
      if (result) {
        return result;
      }
    }
  };
}
