import { AccessTime as TimeIcon } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TimeZoneSetting,
  useTimeZoneSetting,
  useTimeZoneSettingDispatch,
} from '../time';
import MenuOpener from '../utils/MenuOpener';
import HeaderMenuButton from './ui/HeaderMenuButton';
import { useBreakpointDown } from '../utils/useBreakpoint';

export interface HeaderTimezoneMenuProps {}

const HeaderTimeZoneMenu = (props: HeaderTimezoneMenuProps) => {
  const { t } = useTranslation();
  const dispatch = useTimeZoneSettingDispatch();
  const timeZoneSetting = useTimeZoneSetting();
  const localZone = useMemo(() => DateTime.local().offsetNameShort, []);

  const isXS = useBreakpointDown('sm');

  const label = {
    [TimeZoneSetting.Local]: isXS
      ? t('timeZone.localShort', { zone: localZone })
      : t('timeZone.local', { zone: localZone }),
    [TimeZoneSetting.UTC]: isXS ? t('timeZone.utcShort') : t('timeZone.utc'),
  }[timeZoneSetting];

  return (
    <MenuOpener
      button={
        <HeaderMenuButton icon={<TimeIcon fontSize="small" />}>
          {label}
        </HeaderMenuButton>
      }
      menuProps={{
        keepMounted: true,
        variant: 'menu',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
      }}
    >
      <MenuItem onClick={() => dispatch(TimeZoneSetting.Local)}>
        {t('timeZone.local', { zone: localZone })}
      </MenuItem>
      <MenuItem onClick={() => dispatch(TimeZoneSetting.UTC)}>
        {t('timeZone.utc')}
      </MenuItem>
    </MenuOpener>
  );
};

export default HeaderTimeZoneMenu;
