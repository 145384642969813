import { styled } from '@mui/material';
import { ReactElement } from 'react';
import {
  ColumnsButton,
  SearchOptionsButton,
  SearchOptionsButtonProps,
} from '../table-page';
import {
  DataTableQuickFilter,
  DataTableQuickFilterProps,
} from './DataTableQuickFilter';
import { DataTableQuickSearch, DataTableQuickSearchProps } from './quickSearch';
import { ColumnsButtonProps } from '../table-page/ui/ColumnsButton';

const DataTableHeaderRoot = styled('div', {
  name: 'DataTableHeader',
  slot: 'Root',
})(({ theme }) => ({
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '&& > * + *': {
    marginLeft: theme.spacing(1),
  },
  '& > .spacer': {
    flex: 1,
  },
  '& > .quick-filter': {
    flex: 1,
  },
}));

export interface DataTableHeaderProps {
  searchOptions?: SearchOptionsButtonProps & { sideSheet?: ReactElement };
  columnsProps?: ColumnsButtonProps;
  quickFilter?: DataTableQuickFilterProps;
  quickSearch?: DataTableQuickSearchProps;
}

/**
 * DataTable header
 */
export const DataTableHeader = (props: DataTableHeaderProps) => {
  const {
    searchOptions: { sideSheet: searchOptionsSideSheet, ...searchOptions } = {},
    columnsProps,
    quickFilter,
    quickSearch,
  } = props;

  return (
    <DataTableHeaderRoot>
      {searchOptions && (
        <SearchOptionsButton {...searchOptions} className="search-options" />
      )}
      {searchOptionsSideSheet}
      {columnsProps && <ColumnsButton {...columnsProps} className="columns" />}
      {quickFilter ? (
        <DataTableQuickFilter {...quickFilter} className="quick-filter" />
      ) : (
        <div className="spacer" />
      )}
      {quickSearch && (
        <DataTableQuickSearch {...quickSearch} className="quick-search" />
      )}
    </DataTableHeaderRoot>
  );
};
