import { useCallback, useMemo } from 'react';
import { useJsonMemo } from '../utils/useJsonMemo';
import { useLocale } from './locale';

/**
 * Create an Intl.NumberFormat formatter callback for the current locale
 * @internal
 */
export function useNumberFormatFormatter(options?: Intl.NumberFormatOptions) {
  const locale = useLocale();

  const numberFormatter = useMemo<Intl.NumberFormat>(
    () => new Intl.NumberFormat(locale, options),
    [locale, options]
  );

  return useCallback(
    (value: number) => numberFormatter.format(value),
    [numberFormatter]
  );
}

export interface NumberFormatterOptions {
  notation?: Intl.NumberFormatOptions['notation'];
  signDisplay?: Intl.NumberFormatOptions['signDisplay'];
  useGrouping?: Intl.NumberFormatOptions['useGrouping'];
  minimumIntegerDigits?: Intl.NumberFormatOptions['minimumIntegerDigits'];
  minimumFractionDigits?: Intl.NumberFormatOptions['minimumFractionDigits'];
  maximumFractionDigits?: Intl.NumberFormatOptions['maximumFractionDigits'];
  minimumSignificantDigits?: Intl.NumberFormatOptions['minimumSignificantDigits'];
  maximumSignificantDigits?: Intl.NumberFormatOptions['maximumSignificantDigits'];
}

/**
 * Get a function that will format a number for the current locale
 */
export function useNumberFormatter(options?: NumberFormatterOptions) {
  return useNumberFormatFormatter(
    useJsonMemo({ ...options, style: 'decimal' })
  );
}

/**
 * Get a function that will format a number with a unit for the current locale
 */
export function useUnitFormatter(
  unit: string,
  options?: NumberFormatterOptions
) {
  return useNumberFormatFormatter(
    useJsonMemo({ ...options, style: 'unit', unit })
  );
}

/**
 * Get a function that will format a percentage number for the current locale
 */
export function usePercentFormatter(options?: NumberFormatterOptions) {
  return useNumberFormatFormatter(
    useJsonMemo({ maximumFractionDigits: 2, ...options, style: 'percent' })
  );
}
