import { IconButton, Tooltip } from '@mui/material';
import invariant from 'invariant';
import { EyeOutline as EyeIcon } from 'mdi-material-ui';
import { useModal } from 'mui-modal-provider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Attachment } from '../../api';
import { ViewerDialog } from '../../file-viewer/ViewerDialog';

export interface DocumentViewButtonProps {
  attachment: Attachment | undefined;
}

export const DocumentViewButton = (props: DocumentViewButtonProps) => {
  const { attachment } = props;
  const { FileName, FileSize, FileType, SignedUrl } = attachment ?? {};
  const { t } = useTranslation();
  const { showModal } = useModal();

  const view = useCallback(() => {
    invariant(FileName, `FileName is required`);
    invariant(FileType, `FileType is required`);
    invariant(FileSize, `FileSize is required`);
    invariant(SignedUrl, `SignedUrl is required`);

    showModal(ViewerDialog, {
      fileName: FileName,
      fileType: FileType,
      fileSize: FileSize,
      src: SignedUrl,
    });
  }, [FileName, FileSize, FileType, SignedUrl, showModal]);

  return (
    <Tooltip title={t('button.view') as string}>
      <IconButton aria-label={t('button.view')} onClick={view}>
        <EyeIcon />
      </IconButton>
    </Tooltip>
  );
};
