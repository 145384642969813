import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import {
  FormControl,
  FormControlLabel,
  formControlLabelClasses,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ChangeEvent, forwardRef, ReactNode, useCallback } from 'react';
import useHtmlId from '../../utils/useHtmlId';
import { useFieldDisabled } from '../disabled';
import { CommonValidatorProps } from './internal/useCommonValidate';

export interface RadioGroupOption {
  value: string;
  label?: ReactNode;
  helperText?: string;
}

export type RadioGroupFieldProps = Omit<
  FieldHookConfig<string>,
  'value' | 'type' | 'multiple'
> &
  Pick<
    FormControlProps,
    'id' | 'fullWidth' | 'margin' | 'required' | 'size' | 'variant'
  > &
  CommonValidatorProps & {
    disabled?: boolean;
    readOnly?: boolean;
    options: (string | RadioGroupOption)[];
    FormLabelProps?: Partial<FormLabelProps>;
    FormHelperTextProps?: Partial<FormHelperTextProps>;
    helperText?: string;
  };

export const RadioGroupField = forwardRef(function RadioGroupField(
  props: RadioGroupFieldProps,
  ref: any
) {
  const {
    name,
    disabled: disabledProp,
    validate,
    label,
    options,
    FormLabelProps,
    FormHelperTextProps,
    helperText: helperTextProp,
    // FormControl
    id: idProp,
    fullWidth,
    margin,
    required,
    size,
    variant,
    ...other
  } = props;
  const disabled = useFieldDisabled(disabledProp);
  const id = useHtmlId(name, idProp);
  const labelId = `${id}-label`;
  const [field, meta, { setValue, setTouched }] = useField<string>({
    name,
    validate,
    ...other,
  });

  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: string) => {
      setTouched(true);
      setValue(value);
    },
    [setTouched, setValue]
  );

  const error = meta.touched && !!meta.error;
  const helperText = error ? meta.error : helperTextProp;
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  return (
    <FormControl
      ref={ref}
      {...{ id, disabled, fullWidth, margin, required, size, variant, error }}
    >
      <FormLabel id={labelId} {...FormLabelProps}>
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby={labelId}
        name={name}
        value={field.value}
        onChange={change}
      >
        {options.map((option) => {
          const { value, label, helperText } =
            typeof option === 'string'
              ? ({ value: option } as RadioGroupOption)
              : option;

          let labelElement = (
            <>
              <Typography
                component="span"
                className={formControlLabelClasses.label}
              >
                {label}
              </Typography>
              {helperText && (
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  {helperText}
                </Typography>
              )}
            </>
          );

          return (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio size="small" />}
              disableTypography
              label={labelElement}
              onBlur={field.onBlur}
              sx={{
                display: 'grid',
                gridTemplateColumns: '[start] auto [lb] 1fr [end]',
                '& > .MuiRadio-root': { gridColumn: 'start / lb' },
                '& > .MuiTypography-root': { gridColumn: 'lb / end' },
              }}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText id={helperTextId} {...FormHelperTextProps}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
});
