import { forwardRef, useCallback } from 'react';
import { Checklist, useAdminApi, useApiQuery } from '../../api';
import { useOptionLabel, useOptions, useSortedOptions } from '../utils';
import { ComboBoxField, ComboBoxFieldProps } from './ComboBoxField';

export type SettingsChecklistFieldProps = Omit<
  ComboBoxFieldProps<string, false>,
  'options'
>;

/**
 * A combobox field for selecting a Settings Checklist
 */
export const SettingsChecklistField = forwardRef(
  function SettingsChecklistField(
    props: SettingsChecklistFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const { data: checklists, loading } = useApiQuery(
      useAdminApi().getChecklists()
    );
    const getId = useCallback((checklist: Checklist) => checklist.id ?? '', []);
    const getLabel = useCallback((checklist: Checklist) => checklist.Name, []);
    const getOptionLabel = useOptionLabel(checklists, getId, getLabel);
    const options = useSortedOptions(
      useOptions(checklists, getId),
      getOptionLabel
    );

    return (
      <ComboBoxField
        ref={ref}
        {...other}
        options={options}
        getOptionLabel={getOptionLabel}
        loading={loading}
      />
    );
  }
);
