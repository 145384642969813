import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { LoadingPage, PageErrorBoundary } from '../page';

/**
 * Make a route for a page
 */
export function page(element: RouteObject['element']): RouteObject {
  return {
    index: true,
    element: (
      <Suspense fallback={<LoadingPage />}>
        <PageErrorBoundary>{element}</PageErrorBoundary>
      </Suspense>
    ),
  };
}
