import { useMemo } from 'react';
import { isImage, isPDF } from '../file-types';
import { BasicPreview } from './BasicPreview';
import { ImagePreview } from './ImagePreview';
import { PdfPreview } from './PdfPreview';

export interface FilePreviewProps {
  file: File;
  onRemove?: () => void;
}

/**
 * Preview for local File objects
 */
export const FilePreview = (props: FilePreviewProps) => {
  const { file, onRemove } = props;
  const url = useMemo(() => URL.createObjectURL(file), [file]);

  if (isImage(file.type)) {
    return <ImagePreview name={file.name} src={url} onRemove={onRemove} />;
  }

  if (isPDF(file.type)) {
    return <PdfPreview name={file.name} src={url} onRemove={onRemove} />;
  }

  return <BasicPreview name={file.name} onRemove={onRemove} />;
};
