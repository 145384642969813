import { electronicFormatIBAN, friendlyFormatIBAN } from 'ibantools';
import { forwardRef, Ref, useCallback } from 'react';
import NumberFormat, { FormatInputValueFunction } from 'react-number-format';

export interface IbanInputProps {
  /**
   * Separator character to use for IBAN chunks
   */
  ibanSeparator?: false | null | '' | ' ' | '-';
}

/**
 * A NumberFormat based <Input> compatible input that formats IBAN number's usng ibantools' friendly formatters
 * @deprecated NumberFormat doesn't actually support letters so we need something else for this to work
 */
export const IbanInput = forwardRef(function IbanInput(
  props: IbanInputProps,
  ref: Ref<any>
) {
  const { ibanSeparator: ibanSeparatorProp = '', ...other } = props;
  const ibanSeparator = ibanSeparatorProp || undefined; // @note Treat false/null/'' the same

  const format = useCallback<FormatInputValueFunction>(
    (value: string) => {
      return ibanSeparator
        ? friendlyFormatIBAN(value, ibanSeparator) ?? value
        : electronicFormatIBAN(value) ?? value;
    },
    [ibanSeparator]
  );
  const removeFormatting = useCallback((formattedValue: string): string => {
    return electronicFormatIBAN(formattedValue) ?? formattedValue;
  }, []);

  return (
    <NumberFormat
      {...other}
      isNumericString
      format={format}
      removeFormatting={removeFormatting}
      getInputRef={ref}
    />
  );
});
