import '@fontsource/roboto-mono/400.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  BreakpointsOptions,
  colors,
  createTheme,
  CssBaseline,
  darken,
  getContrastRatio,
  lighten,
  PaletteMode,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import {
  dark,
  light,
  PaletteColorOptions,
} from '@mui/material/styles/createPalette';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { ThemeProvider as StylesThemeProvider } from '@mui/styles';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import warning from 'warning';
import {
  Background,
  BrandingPaletteColor,
  useBranding,
  whitelabelDarkTheme,
  whitelabelLightTheme,
} from '../branding';
import { composeProviders } from '../utils/composeProviders';
import { getBackgroundTopColor } from './gradient';
import BreakpointIndicator from './internal/BreakpointIndicator';
import { BackdropThemeContext } from './internal/context';

export interface ThemeProviderProps {
  children?: ReactNode;
}

declare module '@mui/material/styles/createTheme' {
  export interface DimensionsOptions {
    drawerWidth?: number;
  }

  export interface Dimensions {
    drawerWidth: number;
  }

  export interface ThemeOptions {
    dimensions?: DimensionsOptions;
  }

  export interface Theme {
    dimensions: Dimensions;
  }
}

declare module '@mui/material/styles/createPalette' {
  export interface Palette {
    appBar: PaletteColor;
    landing: PaletteColor;
  }

  export interface PaletteOptions {
    appBar?: PaletteColorOptions;
    landing?: PaletteColorOptions;
  }

  export interface TypeBackground {
    viewport?: Background;
    chatBubble: string;
    footer: string;
    tableHeader: string;
  }
}

declare module '@mui/material/styles/createTypography' {
  export interface FontStyle {
    fontFamilyMono: React.CSSProperties['fontFamily'];
  }
}

/**
 * Default elevation for surfaces on the backdrop
 */
export const surfaceElevation = 3;

/**
 * Expand a simple branding palette color into a full
 */
function resolveColor(
  color: BrandingPaletteColor | undefined
): PaletteColorOptions | undefined {
  if (typeof color === 'string') {
    return {
      main: color,
      light: lighten(color, 0.1),
      dark: darken(color, 0.1),
    };
  } else if (typeof color === 'object') {
    if ('name' in color) {
      return {
        main: colors[color.name][color.main],
        light: colors[color.name][color.light],
        dark: colors[color.name][color.dark],
      };
    } else {
      return color;
    }
  }
}

/**
 * Custom MUI theme provider
 */
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { theme: { themeMode, primary, secondary, background } = {} } =
    useBranding();
  const mode = themeMode; // @todo Handle 'auto' here when it's added

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (typeof background === 'object') {
        const { getContrastText } = createTheme().palette;
        warning(
          getContrastText(background.start) === getContrastText(background.end),
          "The theme's bg gradient color start/end shades are too far apart, accessibility will be broken"
        );
      }
    }
  }, [background]);

  const createSiteTheme = useCallback(
    (mode: PaletteMode): Theme => {
      const breakpoints: BreakpointsOptions = {
        values: {
          // Phones
          xs: 0,
          // Tablets
          sm: 600,
          // MD layout matches MUI v4, collapses the sidebar earlier to give form tables more space
          md: 960,
          // LG layout matches MUI v5, makes sure the collapsed form layout's inputs aren't wider than the XL layout's
          lg: 1200,
          // Target Full HD monitors for our XL layout
          xl: 1920,
        },
      };

      const {
        palette: { augmentColor },
        typography: {
          pxToRem,
          fontWeightLight,
          fontWeightRegular,
          fontWeightMedium,
          fontWeightBold,
        },
      } = createTheme({
        breakpoints,
        typography: {},
      });

      const round = (value: number) => Math.round(value * 1e5) / 1e5;
      const variant = (
        fontWeight: React.CSSProperties['fontWeight'],
        fontSize: number,
        lineHeight: number,
        letterSpacing: number,
        other?: TypographyStyleOptions
      ): TypographyStyleOptions => ({
        fontWeight,
        fontSize: pxToRem(fontSize),
        lineHeight: lineHeight / fontSize,
        letterSpacing: round(letterSpacing / fontSize) + 'em',
        ...other,
      });

      const whitelabelPalette =
        mode === 'dark' ? whitelabelDarkTheme : whitelabelLightTheme;
      const coreTheme = createTheme({
        palette: {
          mode,
          primary: resolveColor(primary ?? whitelabelPalette.primary),
          secondary: resolveColor(secondary ?? whitelabelPalette.secondary),
          appBar: augmentColor({
            color: { main: '#021b38' },
            name: 'appBar',
          }),
          landing: augmentColor({
            color: { main: '#0e80dd', light: '#6fbeeb' },
            name: 'landing',
          }),
          background: {
            viewport: background,
            tableHeader: mode === 'dark' ? '#fff' : '#000',
            default: mode === 'dark' ? dark.background.default : '#ffffff',
            // @todo Get real design colors for the chat bubbles
            chatBubble:
              mode === 'dark' ? dark.action.hover : light.action.hover,
            // footer: darken(light.background.default, 0.5),
            footer: '#021b38',
          },
        },
        dimensions: {
          drawerWidth: 228,
        },
        typography: {
          // fontFamily: [
          //   // Webfont
          //   // 'Atkinson Hyperlegible',
          //   // System font stack
          //   '-apple-system',
          //   'BlinkMacSystemFont',
          //   'Segoe UI',
          //   'Roboto',
          //   'Noto',
          //   'Helvetica Neue',
          //   'Helvetica',
          //   'Ubuntu',
          //   'Arial',
          //   'sans-serif',
          //   'Apple Color Emoji',
          //   'Segoe UI Emoji',
          //   'Segoe UI Symbol',
          // ]
          //   .map((item) => (item.includes(' ') ? `"${item}"` : item))
          //   .join(', '),
          fontFamilyMono: [
            'Roboto Mono',
            'Consolas',
            'Andale Mono WT',
            'Andale Mono',
            'Lucida Console',
            'Lucida Sans Typewriter',
            'DejaVu Sans Mono',
            'Bitstream Vera Sans Mono',
            'Liberation Mono',
            'Nimbus Mono L',
            'Monaco',
            'Courier New',
            'Courier',
            'monospace',
          ]
            .map((family) => (/^\w+$/.test(family) ? family : `"${family}"`))
            .join(', '),
          fontWeightLight,
          fontWeightRegular,
          fontWeightMedium,
          fontWeightBold,
          h1: variant(fontWeightLight, 96, 108, -2.88),
          h2: variant(fontWeightLight, 60, 68, -1.2),
          h3: variant(fontWeightLight, 48, 56, -0.96),
          h4: variant(fontWeightRegular, 32, 40, -0.64),
          h5: variant(fontWeightRegular, 24, 32, -0.24),
          h6: variant(fontWeightRegular, 20, 28, -0.2),
          subtitle1: variant(fontWeightMedium, 17, 24, 0),
          subtitle2: variant(fontWeightMedium, 15, 20, 0.07),
          body1: variant(fontWeightRegular, 16, 24, 0),
          body2: variant(fontWeightRegular, 14, 20, 0.07),
          button: variant(fontWeightMedium, 14, 20, 0.35),
          caption: variant(fontWeightRegular, 12, 16, 0.36),
          overline: variant(fontWeightRegular, 10, 16, 0.4),
        },
        mixins: {
          toolbar: {
            minHeight: 72,
          },
        },
        shape: {
          borderRadius: 0,
        },
      });

      return createTheme(coreTheme, {
        breakpoints,
        components: {
          MuiTextField: {
            defaultProps: {
              margin: 'dense',
              size: 'small',
              variant: 'filled',
              fullWidth: true,
            },
          },
          MuiSelect: {
            // Apply tweaks ListItemIcon tweaks to SelectField similar to the tweaks MenuItem uses
            // https://github.com/mui/material-ui/blob/master/packages/mui-material/src/MenuItem/MenuItem.js#L129-L145
            styleOverrides: {
              select: {
                '& .MuiListItemIcon-root': {
                  minWidth: 24,
                  verticalAlign: 'text-top',
                  '& svg': {
                    fontSize: '1.25rem',
                  },
                },
              },
            },
          },
          MuiMenuItem: {
            defaultProps: {
              dense: true,
            },
          },
          MuiToolbar: {
            styleOverrides: {
              // Adjust the gutters to account for the backdrop+card layout
              gutters: {
                paddingLeft: coreTheme.spacing(2),
                paddingRight: coreTheme.spacing(2),
                [coreTheme.breakpoints.up('sm')]: {
                  paddingLeft: coreTheme.spacing(3),
                  paddingRight: coreTheme.spacing(3),
                },
              },
            },
          },
          MuiIconButton: {
            defaultProps: {
              size: 'large',
            },
          },
          MuiButton: {
            defaultProps: {
              disableFocusRipple: true,
            },
            styleOverrides: {
              root: {
                '&:before': {
                  content: '""',
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  backgroundColor: 'currentColor',
                  zIndex: 0,

                  transition: coreTheme.transitions.create('opacity', {
                    duration: coreTheme.transitions.duration.short,
                    easing: coreTheme.transitions.easing.easeInOut,
                  }),
                },
                '&.Mui-focusVisible:before': {
                  opacity: 0.3,
                },
              },
              outlined: {
                '&.Mui-focusVisible:before': {
                  right: -1,
                  left: -1,
                },
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              fontSizeSmall: {
                fontSize: pxToRem(16),
              },
              fontSizeLarge: {
                fontSize: pxToRem(32),
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              fullWidth: {
                [coreTheme.breakpoints.up('sm')]: {
                  minWidth: 72,
                },
              },
            },
          },
          MuiFilledInput: {
            styleOverrides: {
              root: {
                '&:before': {
                  display: 'none',
                },
              },
            },
          },
        },
      });
    },
    [background, primary, secondary]
  );

  /**
   * Default theme with branding provided light/dark mode
   */
  const theme = useMemo<Theme>(
    () => createSiteTheme(mode ?? 'light'),
    [createSiteTheme, mode]
  );

  /**
   * Alt theme for use for content in the backdrop
   * @fixme This doesn't handle future branding colors that may change depending on the current theme, if those need handling
   */
  const backdropTheme = useMemo<Theme>(() => {
    const backdropColor = getBackgroundTopColor(theme.palette.background);

    if (
      getContrastRatio(theme.palette.text.primary, backdropColor) >=
      theme.palette.contrastThreshold
    ) {
      // The normal theme can just be reused if it has the right contrast
      return theme;
    }

    // Otherwise, we need to create a theme with a different mode
    return createSiteTheme(theme.palette.mode === 'dark' ? 'light' : 'dark');
  }, [createSiteTheme, theme]);

  return composeProviders(
    <StyledEngineProvider injectFirst />,
    <BackdropThemeContext.Provider value={backdropTheme} />,
    <MuiThemeProvider theme={theme} />,
    <StylesThemeProvider theme={theme} />,
    (children) => (
      <>
        <CssBaseline />
        {process.env.REACT_APP_DEVTOOLS === 'true' && <BreakpointIndicator />}
        {children}
      </>
    ),
    () => <>{children}</>
  );
};
