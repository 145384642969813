import { forwardRef, useMemo } from 'react';
import { DocumentType, useCodes } from '../../../api';
import { useDocumentTypeLabels, useLabelFn } from '../../../intl';
import { enumArray } from '../../utils';
import { useSortedOptions } from '../../utils/options';
import { SelectField, SelectFieldProps } from '../SelectField';

export type DocumentTypeFieldProps = Omit<SelectFieldProps, 'options'> & {
  documentTypeGroup?: 'client' | 'contact';
};

/**
 * A <Select> field for selecting a DocumentType
 */
export const DocumentTypeField = forwardRef(function DocumentTypeField(
  props: DocumentTypeFieldProps,
  ref: any
) {
  const { documentTypeGroup, ...other } = props;
  const [codes, loading] = useCodes(
    documentTypeGroup === 'client'
      ? 'clientdocumenttypes'
      : documentTypeGroup === 'contact'
      ? 'contactdocumenttypes'
      : null
  );
  const codeIds = useMemo(
    () => (codes ? new Set(codes.map((code) => code.Id ?? '')) : undefined),

    [codes]
  );
  const getOptionLabel = useLabelFn(useDocumentTypeLabels());
  const options = useSortedOptions(
    useMemo(
      () =>
        enumArray(DocumentType).filter((item) =>
          codeIds ? codeIds.has(item) : true
        ),
      [codeIds]
    ),
    getOptionLabel
  );

  return (
    <SelectField
      ref={ref}
      {...other}
      {...{ options, getOptionLabel, loading }}
    />
  );
});
