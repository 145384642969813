import { styled } from '@mui/material';
import { gridAreas } from '../../utils/css-grid';

export const SplashLayoutRoot = styled('div', {
  name: 'SplashLayout',
  slot: 'Root',
})(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  gridTemplateColumns: '1fr auto 1fr',
  gridTemplateRows: '1fr auto auto 1fr',
  gridTemplateAreas: gridAreas(['. . .', '. logo .', '. sitename .', '. . .']),
}));

export const SplashLayoutLogo = styled('div', {
  name: 'SplashLayout',
  slot: 'Logo',
})(({ theme }) => ({
  gridArea: 'logo',
}));
