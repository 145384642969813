import {
  AppBar,
  AppBarProps,
  styled,
  SvgIconProps,
  Toolbar,
  useScrollTrigger,
} from '@mui/material';
import { ellipsis } from 'polished';
import { cloneElement, Fragment, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, getBreadcrumbTitle } from '../../breadcrumbs';
import { ClearIconButton } from '../../form';
import { useSidebarExpanded } from '../../layout/sidebar-expansion';
import { DataTableSelected } from '../../table/useDataTable';
import {
  getBackgroundTopColor,
  getSecondaryContrastText,
  getViewportBackgroundStyles,
} from '../../theme';
import { BULLET } from '../../utils/unicode';

interface PageHeaderStyleProps {
  sticky: boolean;
  sidebarExpanded: boolean;
}

const PageHeaderRoot = styled(AppBar, { name: 'PageHeader', slot: 'Root' })<{
  ownerState: PageHeaderStyleProps;
}>(({ theme, ownerState }) => ({
  color: theme.palette.getContrastText(
    getBackgroundTopColor(theme.palette.background)
  ),
  flexDirection: 'row',

  ...(ownerState.sticky && {
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'none',
    backgroundAttachment: 'fixed',
    ...getViewportBackgroundStyles(theme.palette.background),

    // Overlap to cover the left shadow cast by pages pages
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(2),

    width: 'auto',
    left: `calc(24px + ${theme.spacing(3)} * 2)`,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(ownerState.sidebarExpanded && {
      left: theme.dimensions.drawerWidth,
      transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
}));

const PageHeaderToolbar = styled(Toolbar, {
  name: 'PageHeader',
  slot: 'Toolbar',
})<{ ownerState: PageHeaderStyleProps }>(({ theme }) => ({
  width: '100%',
  flex: 1,
  paddingLeft: 0,
  paddingRight: theme.spacing(3),
}));

const PageHeaderToolbarContent = styled('div', {
  name: 'PageHeader',
  slot: 'ToolbarContent',
})<{ ownerState: PageHeaderStyleProps }>(({ theme, ownerState }) => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'stretch',
  ...(ownerState.sticky && {
    borderBottom: `2px solid ${theme.palette.getContrastText(
      getBackgroundTopColor(theme.palette.background)
    )}`,
  }),
}));

const PageHeaderIcon = styled('div', { name: 'PageHeader', slot: 'Icon' })(
  ({ theme }) => ({
    display: 'flex',
    marginRight: theme.spacing(2),
  })
);

const PageHeaderBreadcrumbLink = styled(Link, {
  name: 'PageHeader',
  slot: 'BreadcrumbLink',
})(({ theme }) => ({
  ...theme.typography.h4,
  textDecoration: 'none',
  color: getSecondaryContrastText(
    getBackgroundTopColor(theme.palette.background)
  ),
  '.MuiAppBar-colorDefault &': {
    color: theme.palette.text.secondary,
  },
  ...ellipsis(),
}));

const PageHeaderBreadcrumbDot = styled('span', {
  name: 'PageHeader',
  slot: 'BreadcrumbDot',
})(({ theme }) => ({
  ...theme.typography.h4,
  margin: theme.spacing(0, 1),
  color: getSecondaryContrastText(
    getBackgroundTopColor(theme.palette.background)
  ),
  '.MuiAppBar-colorDefault &': {
    color: theme.palette.text.secondary,
  },
}));

const PageHeaderActions = styled('div', {
  name: 'PageHeader',
  slot: 'Actions',
})(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginLeft: theme.spacing(2),
  '& :is(.MuiButton-root, .MuiButtonGroup-root) + :is(.MuiButton-root, .MuiButtonGroup-root)':
    {
      marginLeft: theme.spacing(1),
    },
}));

const PageHeaderTitle = styled('h1', { name: 'PageHeader', slot: 'Title' })<{
  ownerState: PageHeaderStyleProps;
}>(({ theme }) => ({
  ...theme.typography.h4,
  margin: 0,
  ...ellipsis(),
}));

export type PageHeaderProps = Omit<AppBarProps, 'title' | 'children'> & {
  breadcrumbs?: Breadcrumb[];
  title?: string | Breadcrumb;
  icon?: ReactElement<SvgIconProps>;
  actions?: ReactNode;
  selection?: DataTableSelected<object>;
  children?: ReactNode;
};

/**
 * A page's header bar
 *
 * Includes the page title and optional confirmation UI.
 */
export const PageHeader = (props: PageHeaderProps) => {
  const {
    breadcrumbs: breadcrumbsProp,
    title: titleProp,
    icon,
    actions: actionsProp,
    selection,
    className,
    children,
    ...other
  } = props;
  const [sidebarExpanded] = useSidebarExpanded();

  const sticky = useScrollTrigger({
    disableHysteresis: true,
    threshold: 72, // site header height
  });

  const ownerState: PageHeaderStyleProps = {
    sticky,
    sidebarExpanded,
  };

  let breadcrumbs = breadcrumbsProp;
  let title = titleProp && getBreadcrumbTitle(titleProp);
  let actions = actionsProp;
  let iconButton: ReactElement | undefined = icon && (
    <PageHeaderIcon>
      {cloneElement(icon, {
        color: 'inherit',
        fontSize: 'large',
      })}
    </PageHeaderIcon>
  );

  if (selection && selection.selected.length > 0) {
    breadcrumbs = undefined;
    iconButton = (
      <ClearIconButton {...selection.clearSelectionProps} color="inherit" />
    );
    title = `${selection.selected.length}`;
  }

  return (
    <>
      <PageHeaderRoot
        position={sticky ? 'fixed' : 'static'}
        enableColorOnDark
        color="transparent"
        elevation={0}
        {...other}
        ownerState={ownerState}
      >
        <PageHeaderToolbar disableGutters ownerState={ownerState}>
          <PageHeaderToolbarContent ownerState={ownerState}>
            {iconButton}
            {breadcrumbs?.map((breadcrumb, index) => (
              <Fragment key={index}>
                <PageHeaderBreadcrumbLink to={breadcrumb.to}>
                  {getBreadcrumbTitle(breadcrumb)}
                </PageHeaderBreadcrumbLink>
                <PageHeaderBreadcrumbDot>{BULLET}</PageHeaderBreadcrumbDot>
              </Fragment>
            ))}
            {title && (
              <PageHeaderTitle ownerState={ownerState}>{title}</PageHeaderTitle>
            )}

            {actions && <PageHeaderActions>{actions}</PageHeaderActions>}
          </PageHeaderToolbarContent>
        </PageHeaderToolbar>
      </PageHeaderRoot>
      {sticky && <Toolbar disableGutters />}
    </>
  );
};
