import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { createReducedStateContext } from '../reducer-context';

const { Provider, useReducerDispatch, useReducerState } =
  createReducedStateContext<boolean, (expanded: boolean) => void>(
    'SidebarExpansion',
    () => {
      const theme = useTheme();
      const [sidebarExpanded, setSidebarExpanded] = useState(true);

      const wide = useMediaQuery(theme.breakpoints.up('md'));
      useEffect(() => {
        // Set the bar to be expanded/collapsed depending on the breakpoint size
        // This works on load and also when resizing crosses the breakpoint
        setSidebarExpanded(wide);
      }, [wide]);

      return [sidebarExpanded, setSidebarExpanded];
    },
    true,
    () => {
      console.warn(
        `Default sidebar displatcher called, auth root may not be setup.`
      );
    }
  );

export const SidebarExpansionProvider = Provider;

/**
 * State and dispatcher controlling whether the sidebar is currently expanded
 */
export const useSidebarExpanded = (): [
  ReturnType<typeof useReducerState>,
  ReturnType<typeof useReducerDispatch>
] => [useReducerState(), useReducerDispatch()];
