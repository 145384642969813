import {
  Box,
  Button,
  Card,
  CardActions,
  CardActionsProps,
  CardProps,
} from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Attachment } from '../../api';
import { RemoveIconButton } from '../../form';
import {
  DocumentCardHeader,
  DocumentCardHeaderProps,
} from './DocumentCardHeader';
import { DocumentDownloadButton } from './DocumentDownloadButton';
import { DocumentViewButton } from './DocumentViewButton';

export type DocumentCardProps = Omit<CardProps, 'title'> & {
  ActionsProps?: CardActionsProps;
  HeaderProps?: DocumentCardHeaderProps;
  onUpload?: () => void;
  onRemove?: () => void;
  attachment?: Attachment | undefined;
  title?: DocumentCardHeaderProps['title'];
};

export const DocumentCard = forwardRef((props: DocumentCardProps, ref: any) => {
  const {
    attachment,
    title,
    onUpload,
    onRemove,
    HeaderProps,
    ActionsProps,
    ...other
  } = props;
  const { t } = useTranslation();

  const removeButton = onRemove && (
    <RemoveIconButton onClick={() => onRemove()} />
  );
  const uploadButton = onUpload && (
    <Button onClick={onUpload}>
      {attachment ? t('button.replace') : t('button.upload')}
    </Button>
  );

  const hasCardActions = !!(removeButton || uploadButton || attachment);

  return (
    <Card ref={ref} {...other}>
      <DocumentCardHeader {...HeaderProps} {...{ attachment, title }} />

      {/* <DocumentThumb variant="card" attachment={attachment} /> */}

      {hasCardActions && (
        <CardActions disableSpacing {...ActionsProps}>
          {removeButton}
          {uploadButton}
          <Box flex={1} />
          {attachment && <DocumentViewButton attachment={attachment} />}
          {attachment && <DocumentDownloadButton attachment={attachment} />}
        </CardActions>
      )}
    </Card>
  );
});
