import { pick } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { CancelablePromise } from '../../utils/CancelablePromise';
import { wrapFileNameHandler } from './contentDisposition';
import { wrapDelay } from './delay';
import { useAccessToken } from './internal/useAccessToken';
import { useQueryAxiosOptions } from './internal/useQueryAxiosOptions';
import { useQueryKey } from './internal/useQueryKey';
import { useQueryPromiseHandler } from './internal/useQueryPromiseHandler';
import { useSkipOption } from './internal/useSkipOption';
import { wrapPagination } from './pagination';
import { wrapRequestTiming } from './requestTiming';
import {
  ApiErrorTypes,
  ApiInfiniteQueryOptions,
  ApiInfiniteQueryState,
  ApiMutationHandler,
  ApiMutationOptions,
  ApiMutationState,
  ApiQueryHandler,
  ApiQueryOptions,
  ApiQueryState,
} from './types';

/**
 * Run an API query and use its result
 */
export function useApiQuery<TData = any>(
  query: ApiQueryHandler<TData> | null,
  options: ApiQueryOptions<TData> = {}
): ApiQueryState<TData> {
  const { queryFn = null } = query ?? {};
  const queryKey = useQueryKey(query, options);
  const { auth, lastRequestTiming } = options;
  const accessToken = useAccessToken(auth);
  const skip = useSkipOption(accessToken, options);
  const getQueryAxiosOptions = useQueryAxiosOptions(options);
  const handleQueryPromise = useQueryPromiseHandler<TData>(options);
  const queryOptions = pick(options, [
    'retry',
    'retryDelay',
    'cacheTime',
    'staleTime',
    'refetchInterval',
    'refetchIntervalInBackground',
    'refetchOnWindowFocus',
    'refetchOnReconnect',
    'refetchOnMount',
    'retryOnMount',
    'onSuccess',
    'onError',
    'onSettled',
    'useErrorBoundary',
    'suspense',
    'keepPreviousData',
    'placeholderData',
  ]);

  const { data, error, isFetching, refetch } = useQuery<TData, ApiErrorTypes>({
    enabled: !skip,
    useErrorBoundary: true,
    ...queryOptions,
    queryKey,
    queryFn: queryFn
      ? (): CancelablePromise<TData> => {
          const requestTiming = wrapRequestTiming<TData>(lastRequestTiming);
          return handleQueryPromise(
            requestTiming(queryFn(getQueryAxiosOptions({})))
          );
        }
      : () => null as any,
  });

  return {
    data,
    error,
    loading: isFetching,
    refresh: refetch as any, // @fixme Why does refetch include error types in it's result type
  };
}

const pageContinuations = new WeakMap<any[], string>();

/**
 * Run an API query and use its result using useInfiniteQuery's pagination handling
 */
export function useApiInfiniteQuery<TItem = any>(
  query: ApiQueryHandler<TItem[]> | null,
  options: ApiInfiniteQueryOptions<TItem[]> = {}
): ApiInfiniteQueryState<TItem[]> {
  options = { maxItemCount: 1000, ...options };
  const { queryFn = null } = query ?? {};
  const queryKey = useQueryKey<TItem[]>(query, options);
  const { auth, lastRequestTiming } = options;
  const accessToken = useAccessToken(auth);
  const skip = useSkipOption(accessToken, options);
  const getQueryAxiosOptions = useQueryAxiosOptions(options);
  const handleQueryPromise = useQueryPromiseHandler<TItem[]>(options);
  const queryOptions = pick(options, [
    'retry',
    'retryDelay',
    'cacheTime',
    'staleTime',
    'refetchInterval',
    'refetchIntervalInBackground',
    'refetchOnWindowFocus',
    'refetchOnReconnect',
    'refetchOnMount',
    'retryOnMount',
    'onSuccess',
    'onError',
    'onSettled',
    'useErrorBoundary',
    'suspense',
    'keepPreviousData',
  ]);

  const {
    data: rawData,
    error,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = useInfiniteQuery<TItem[], ApiErrorTypes>(queryKey, {
    enabled: !skip,
    useErrorBoundary: true,
    ...queryOptions,
    queryFn: queryFn
      ? (context): CancelablePromise<TItem[]> => {
          const requestTiming = wrapRequestTiming<TItem[]>(lastRequestTiming);
          const pagination = wrapPagination(pageContinuations);
          const delay = wrapDelay(options.delay);
          return handleQueryPromise(
            pagination(
              requestTiming(
                delay(() =>
                  queryFn(
                    getQueryAxiosOptions({
                      continuation: context.pageParam,
                    })
                  )
                )
              )
            )
          );
        }
      : () => null as any,
    getNextPageParam: (lastPage) => {
      return pageContinuations.get(lastPage);
    },
  });

  const data = useMemo<TItem[] | undefined | null>(
    () => rawData && rawData.pages.flatMap((page) => page),
    [rawData]
  );

  return {
    data,
    error,
    hasNextPage,
    hasPreviousPage,
    loading: isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    refresh: refetch as any, // @fixme Why does refetch include error types in it's result type
    fetchNextPage,
    fetchPreviousPage,
  };
}

/**
 * Get a function to run an API mutation
 */
export function useApiMutation<TArgs extends unknown[] = any[], TData = any>(
  options: ApiMutationHandler<TArgs, TData> & ApiMutationOptions<TArgs, TData>
): [(...args: TArgs) => Promise<TData>, ApiMutationState<TData>] {
  const { mutationKey, mutationFn } = options;
  const { lastRequestTiming, fileNameRef } = options;
  const handleQueryPromise = useQueryPromiseHandler<TData>(options);
  const queryOptions = pick(options, [
    'onMutate',
    'onSuccess',
    'onError',
    'onSettled',
    'retry',
    'retryDelay',
    'useErrorBoundary',
  ]);

  const { data, error, isLoading, mutateAsync } = useMutation<
    TData,
    ApiErrorTypes,
    TArgs
  >(
    (args: TArgs): CancelablePromise<TData> => {
      const requestTiming = wrapRequestTiming<TData>(lastRequestTiming);
      const fileNameHandler = wrapFileNameHandler<TData>(fileNameRef);
      return handleQueryPromise(
        fileNameHandler(requestTiming(mutationFn(...args)))
      );
    },
    { mutationKey, ...queryOptions }
  );

  const mutate = useCallback(
    (...args: TArgs): Promise<TData> => mutateAsync(args),
    [mutateAsync]
  );

  return [
    mutate,
    {
      data,
      error,
      loading: isLoading,
    },
  ];
}
