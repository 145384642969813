import { forwardRef, Ref } from 'react';
import { TransferListField, TransferListFieldProps } from './TransferListField';
import { useCodes } from '../../api';
import { useCountryNames } from '../../intl';
import { useLabeledCodeOptionsProps } from '../utils';

export type CountryAllowTransferListFieldProps = Omit<
  TransferListFieldProps<string>,
  'options' | 'getOptionLabel'
>;

export const CountryAllowTransferListField = forwardRef(
  function CountryAllowTransferListField(
    props: CountryAllowTransferListFieldProps,
    ref: Ref<any>
  ) {
    const { ...other } = props;
    const [countries, loadingCountries] = useCodes('countrycodes');
    const countryName = useCountryNames();
    const optionProps = useLabeledCodeOptionsProps(countries, countryName);

    return (
      <TransferListField<string>
        ref={ref}
        {...other}
        {...optionProps}
        loading={loadingCountries}
      />
    );
  }
);
