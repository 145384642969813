import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { ComboBoxField, ComboBoxFieldProps } from '../ComboBoxField';

export type SicFieldProps = Omit<ComboBoxFieldProps<string, false>, 'options'>;

/**
 * A <Select> field for selecting a SIC code
 */
export const SicField = forwardRef(function SicField(
  props: SicFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [sicCodes, loadingSicCodes] = useCodes('siccodes');
  const optionProps = useCodeOptionsProps(sicCodes);

  return (
    <ComboBoxField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingSicCodes}
    />
  );
});
