import { Path, To } from 'history';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authority } from '../api';
import { useReturnTo } from '../utils/returnTo';
import { useAuthState, useAuthStateDispatch } from './internal';

const emptyAuthorities: readonly Authority[] = Object.freeze<Authority>([]);

/**
 * Get the current user's UID
 */
export function useCurrentUid(): string | undefined {
  const { uid } = useAuthState();
  return uid ?? undefined;
}

/**
 * Get the current user's contact ID
 */
export function useCurrentContactId(): string | undefined {
  const { uid, contactId } = useAuthState();
  return contactId ?? uid ?? undefined;
}

/**
 * Get the current user's authorities as an array
 */
export function useCurrentAuthoritiesArray(): readonly Authority[] {
  const { authorities } = useAuthState();
  return authorities ?? emptyAuthorities;
}
/**
 * Get the current user's authorities
 */
export function useCurrentAuthorities(): Set<Authority> {
  const { authorities } = useAuthState();
  return useMemo(() => new Set(authorities), [authorities]);
}

/**
 * Get a function to logout
 */
export function useLogout(): () => void {
  const dispatch = useAuthStateDispatch();
  return useCallback(() => dispatch({ type: 'LOGOUT' }), [dispatch]);
}

/**
 * Provide a returnTo object for the current page
 */
export function useCurrentPageReturnTo(): Path {
  const location = useLocation();
  const { pathname, search, hash } = location;

  return { pathname, search, hash };
}

/**
 * Get a function to navigate to the login page
 */
export function useNavigateLogin(): (returnTo?: Path) => void {
  const navigate = useNavigate();
  const currentReturnTo = useCurrentPageReturnTo();

  return useCallback(
    (returnTo) => {
      navigate('/login', { state: { returnTo: returnTo ?? currentReturnTo } });
    },
    [currentReturnTo, navigate]
  );
}

/**
 * Get a function to navigate to the change password page
 */
export function useNavigateChangePassword(): (returnTo?: Path) => void {
  const navigate = useNavigate();
  const currentReturnTo = useCurrentPageReturnTo();

  return useCallback(
    (returnTo) => {
      navigate('/user/changepassword', {
        state: { returnTo: returnTo ?? currentReturnTo },
      });
    },
    [currentReturnTo, navigate]
  );
}

/**
 * Redirects the user to the login page if they are not logged in
 */
export function useLoginExpected(): void {
  const uid = useCurrentUid();
  const goToLogin = useNavigateLogin();

  useEffect(() => {
    if (!uid) {
      goToLogin();
    }
  }, [goToLogin, uid]);
}

/**
 * A navigate function that navigates to a `returnTo` in
 */
export function useNavigateReturnTo(fallbackTo: To = '/'): () => void {
  const returnTo = useReturnTo();
  const navigate = useNavigate();

  return useCallback(
    () => navigate(returnTo ?? fallbackTo),
    [fallbackTo, navigate, returnTo]
  );
}
