import { useCallback } from 'react';
import { Options } from 'react-virtual';

/**
 * Smooth scroll implementation for useVirtual
 * Uses native smooth scrolling instead of requestAnimationFrame like the official example
 */
export function useSmoothScrollForVirtual(
  parentRef: HTMLElement | null
): Options<unknown>['scrollToFn'] {
  return useCallback(
    (offset: number) => {
      parentRef?.scrollTo({ top: offset, behavior: 'smooth' });
    },
    [parentRef]
  );
}
