import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PreviewRoot } from './ui/PreviewRoot';
import { PreviewTitle } from './ui/PreviewTitle';

export interface BasicPreviewProps {
  name: string;
  onRemove?: () => void;
}

/**
 * Preview for unknown file types
 */
export const BasicPreview = (props: BasicPreviewProps) => {
  const { name, onRemove } = props;
  const { t } = useTranslation();

  return (
    <PreviewRoot>
      <PreviewTitle title={name} onRemove={onRemove} />
      <Typography variant="caption" color="textSecondary">
        {t('label.noThumbnail')}
      </Typography>
    </PreviewRoot>
  );
};
