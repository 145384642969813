import { useFieldMeta } from '@johnrom/formik-v3';
import { createFilterOptions, styled } from '@mui/material';
import clsx from 'clsx';
import invariant from 'invariant';
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQueryClient } from 'react-query';
import {
  AccountsApiQueryKey,
  AccountShortSummary,
  ActiveType,
  AdminApiQueryKey,
  isResourceNotFoundError,
  useAccountsApi,
  useAccountShortSummariesHandler,
  useAdminApi,
} from '../../api';
import { useCurrencyFormatters } from '../../intl';
import { useOpenState } from '../../utils/useOpenState';
import { useOptionLabel, useOptions, useRenderOption } from '../utils';
import {
  DynamicComboBoxField,
  DynamicComboBoxFieldProps,
  useComboBoxApiQuery,
  useInputTextState,
  useOptionsWithCurrent,
} from './internal/DynamicComboBox';

const AccountFieldItem = styled('span', { name: 'AccountFieldItem' })(
  ({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '&.next-page-message': {
      fontStyle: 'italic',
    },
    '& .main': {
      flex: 1,
    },
    '& span + span': {
      marginLeft: theme.spacing(1),
    },
  })
);

const filter = createFilterOptions<string>();
const NEXT_PAGE_MESSAGE = '\u001B__NEXT_PAGE_MESSAGE';

export type AccountFieldProps<AllowCustomIban extends boolean> = Omit<
  DynamicComboBoxFieldProps,
  | 'options'
  | 'getOptionLabel'
  | 'renderOption'
  | 'getOptionDisabled'
  | 'open'
  | 'onOpen'
  | 'onClose'
  | 'onInputChange'
  | 'loading'
> & {
  /**
   * Client to use contacts from (for admin use)
   */
  clientId?: string | undefined | null;
  /**
   * Which account property to use as the value, account.Id or account.Iban
   */
  valueType?: 'id' | 'iban';
  /**
   * Allow custom IBANs in addition to valid account IBANs (valueType must be iban)
   */
  allowCustomIban?: AllowCustomIban;
  /**
   * Focus on IBANs over accounts
   * - Use "IBAN (Name)" display
   * - Only display IBAN for custom
   * - Auto-select custom IBAN
   */
  ibanOnly?: boolean;
};

interface AccountShortSummaryValue extends AccountShortSummary {
  notFound?: boolean;
  loading?: boolean;
}

/**
 * A <Select> field for selecting an account by id or iban
 */
export const AccountField = forwardRef(function AccountField<
  AllowCustomIban extends boolean
>(props: AccountFieldProps<AllowCustomIban>, ref: any) {
  const {
    valueType = 'id',
    allowCustomIban = false,
    ibanOnly,
    clientId,
    ...other
  } = props;
  const { t } = useTranslation();
  const { open, openProps } = useOpenState();
  const [inputText, onInputChange] = useInputTextState();
  const searchText =
    inputText && !/^.+\((?:[A-Z]{2}\d{2}\w+|\?+)\)$/.test(inputText)
      ? inputText
      : undefined;

  const { value } = useFieldMeta<string | null>(other.name); // @fixme Combobox should be a hook so we can access the value
  // @fixme Add iban validation

  invariant(
    !allowCustomIban || valueType === 'iban',
    'allowCustomIban can only be used with valueType=iban'
  );
  invariant(
    !ibanOnly || valueType === 'iban',
    'ibanOnly can only be used with valueType=iban'
  );

  const queryClient = useQueryClient();
  const adminApi = useAdminApi();
  const accountsApi = useAccountsApi();

  const accountShortSummariesHandler = useAccountShortSummariesHandler(
    clientId,
    {
      searchText: searchText,
      status: { eq: ActiveType.Active },
    }
  );

  const {
    oneData: oneAccount,
    oneError: oneAccountError,
    allData: allAccounts,
    loadingOne: loadingOneAccount,
    loading,
    hasNextPage,
  } = useComboBoxApiQuery({
    value,
    open,
    searchText,
    getOneByValue: (value) => {
      if (valueType === 'iban') {
        return clientId
          ? adminApi.getClientAccountSummaryByIban(clientId, value)
          : accountsApi.getAccountSummaryByIban(value);
      } else {
        return clientId
          ? adminApi.getClientAccountSummaryById(clientId, value)
          : accountsApi.getAccountSummaryById(value);
      }
    },
    searchAllByValue: () => accountShortSummariesHandler,
    preCacheOneItem: (account) => {
      const cacheKeys: (QueryKey | undefined | null)[] = [
        account.Id && AccountsApiQueryKey.getAccountSummaryById(account.Id),
        account.Iban &&
          AccountsApiQueryKey.getAccountSummaryByIban(account.Iban),
        clientId &&
          account.Id &&
          AdminApiQueryKey.getClientAccountSummaryById(clientId, account.Id),
        clientId &&
          account.Iban &&
          AdminApiQueryKey.getClientAccountSummaryByIban(
            clientId,
            account.Iban
          ),
      ];

      for (const cacheKey of cacheKeys) {
        if (cacheKey) {
          queryClient.setQueryData(cacheKey, account);
        }
      }
    },
  });

  const selectedAccount = useMemo<AccountShortSummaryValue | null>(() => {
    if (oneAccount) return oneAccount;

    if (valueType === 'iban' && value) {
      return {
        Id: undefined,
        Iban: value,
        Name: undefined,
        notFound: isResourceNotFoundError(oneAccountError),
        loading: loadingOneAccount,
      };
    }

    if (value) {
      return {
        Id: value,
        Iban: undefined,
        Name: value,
      };
    }

    return null;
  }, [loadingOneAccount, oneAccount, oneAccountError, value, valueType]);

  const getId = useCallback(
    (account: AccountShortSummary) =>
      (valueType === 'iban' ? account.Iban : account.Id) ?? '',
    [valueType]
  );
  const accounts = useOptionsWithCurrent(allAccounts, selectedAccount, getId);
  const options = useOptions(accounts, getId);
  const getLabelForAccount = useCallback(
    (account: AccountShortSummaryValue) => {
      const iban = account.Iban || account.Id;
      if (ibanOnly) {
        return account.Name ? `${iban} (${account.Name})` : iban;
      } else {
        return account.Name ? `${account.Name} (${iban})` : iban;
      }
    },
    [ibanOnly]
  );
  const getOptionLabel = useOptionLabel(accounts, getId, getLabelForAccount);
  const currencyFormatter = useCurrencyFormatters();
  const renderAccountOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      account: AccountShortSummaryValue
    ) => {
      const Name = account.Name
        ? account.Name
        : account.notFound
        ? allowCustomIban
          ? t('label.custom')
          : '❌'
        : account.loading
        ? '⋯'
        : '⁇';

      return (
        <AccountFieldItem {...props}>
          <span className="main">{Name}</span>
          <span>
            {typeof account.Balance === 'number'
              ? currencyFormatter(account.Currency ?? '', account.Balance)
              : ''}
          </span>
          <span>{account.Iban}</span>
        </AccountFieldItem>
      );
    },
    [allowCustomIban, currencyFormatter, t]
  );
  const renderUnknownOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, iban: string) =>
      iban === NEXT_PAGE_MESSAGE ? (
        <AccountFieldItem
          {...props}
          className={clsx(props.className, 'next-page-message')}
          key="next-page-message"
        >
          <span className="main">{t('label.refineSearchToSeeMore')}</span>
        </AccountFieldItem>
      ) : (
        <AccountFieldItem {...props} key="new-iban">
          <span className="main">{t('label.custom')}</span>
          <span></span>
          <span>{iban}</span>
        </AccountFieldItem>
      ),
    [t]
  );
  const renderOption = useRenderOption(
    accounts,
    getId,
    renderAccountOption,
    renderUnknownOption
  );

  let inputPrefix: ReactNode;
  if (!open && !ibanOnly && selectedAccount && !selectedAccount.Name) {
    if (selectedAccount.notFound) {
      if (allowCustomIban) {
        inputPrefix = t('label.custom');
      } else {
        inputPrefix = '❌';
      }
    } else if (selectedAccount.loading) {
      inputPrefix = '⋯';
    }
  }

  return (
    <DynamicComboBoxField
      ref={ref}
      {...other}
      {...{
        options,
        loading,
        hasNextPage,
        getOptionLabel,
        renderOption,
        onInputChange,
        inputPrefix,
      }}
      {...openProps}
      clearOnBlur={!(allowCustomIban && ibanOnly)}
      autoComplete={allowCustomIban && ibanOnly}
      autoHighlight={allowCustomIban && ibanOnly}
      autoSelect={allowCustomIban && ibanOnly}
      includeInputInList={allowCustomIban}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (
          allowCustomIban &&
          params.inputValue !== '' &&
          !options.includes(params.inputValue)
        ) {
          filtered.push(params.inputValue);
        }

        return filtered;
      }}
      commonValidate={{
        iban: valueType === 'iban',
      }}
    />
  );
});
