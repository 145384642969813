import invariant from 'invariant';
import { dark, light } from '@mui/material/styles/createPalette';
import { getContrastRatio } from '@mui/material';

const contrastThreshold = 3;

/**
 * Variant of MUI's getContrastText that accepts an arbitrary list of text colors
 */
export function getContrastText(background: string, textColors: string[]) {
  invariant(textColors, 'expected a list of text colors');

  let contrastText = textColors[textColors.length - 1];
  for (let i = 0; i <= textColors.length - 1; ++i) {
    if (getContrastRatio(background, textColors[i]) >= contrastThreshold) {
      contrastText = textColors[i];
      break;
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    const contrast = getContrastRatio(background, contrastText);
    if (contrast < 3) {
      console.error(
        [
          `Material-UI: The contrast ratio of ${contrast}:1 for ${contrastText} on ${background}`,
          'falls below the WCAG recommended absolute minimum contrast ratio of 3:1.',
          'https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-contrast',
        ].join('\n')
      );
    }
  }

  return contrastText;
}

/**
 * Variant of MUI's getContrastText that returns a secondary color instead of a primary color
 */
export function getSecondaryContrastText(background: string) {
  return getContrastText(background, [
    dark.text.secondary,
    light.text.secondary,
    dark.text.primary,
    light.text.primary,
  ]);
}

/**
 * Variant of MUI's getContrastText that returns a divider color
 */
export function getDividerContrast(background: string, dividerColor: string) {
  return getContrastText(background, [
    dividerColor,
    dark.divider,
    light.divider,
  ]);
}
