import { DateTime } from 'luxon';

/**
 * setTimeout wrapper that takes a DateTime when to run instead of a duration
 *
 * Also return a cancel function so it can be returned from a useEffect
 *
 * @returns Function which will cancel the timeout
 */
export function setFutureTask(
  runAt: DateTime,
  handler: () => void
): () => void {
  const dur = runAt.toMillis() - Date.now();
  // invariant(dur >= 0, 'cannot schedule a past task');

  const timeout = setTimeout(handler, Math.max(0, dur));
  return () => {
    clearTimeout(timeout);
  };
}
