import Axios, { AxiosError } from 'axios';
import { decode as decodeEntities } from 'html-entities';
import invariant from 'invariant';
import { useMemo } from 'react';

/**
 * Cleanup poor formatting of API errors from the server
 */
function normalizeApiError(data: ApiErrorData): ApiErrorData {
  const { Reason, Details, Messages } = data;
  return {
    ...data,
    Reason: Reason?.trim(),
    Details: Details && decodeEntities(Details),
    Messages: Messages?.map((Message) => {
      return decodeEntities(Message);
    }),
  };
}

/**
 * API error
 */
export interface ApiErrorData {
  Reason: string;
  Details?: string;
  Messages?: string[];
  Path?: string;
  RequestBody?: string;
}

/**
 * Error from the API
 */
export class ApiError extends Error {
  public details?: string;
  public messages?: string[];
  public code: string;
  public path?: string;
  public requestBody?: string;
  public axiosError: AxiosError | undefined;

  constructor(data: ApiErrorData, error?: AxiosError) {
    data = normalizeApiError(data);

    const message =
      data.Details ||
      (data.Messages && `${data.Reason}:\n${data.Messages.join('\n')}`) ||
      data.Reason;
    super(message);
    this.details = data.Details;
    this.messages = data.Messages;
    this.code = data.Reason;
    this.path = data.Path;
    this.requestBody = data.RequestBody;
    this.axiosError = error;
  }
}

/**
 * Check if an error is an API error of some type
 */
export const isApiError = (
  error: any,
  checkApiError?: (error: ApiError) => boolean
): error is ApiError => {
  if (error instanceof ApiError) {
    return typeof checkApiError === 'function'
      ? checkApiError(error as ApiError)
      : true;
  }

  return false;
};

/**
 * Check if an error is an AuthenticationFailed (invalid login or token) API error
 */
export const isAuthenticationFailedError = (error: any): error is ApiError =>
  isApiError(error, (err) => err.code === 'AuthenticationFailed');
/**
 * Check if an error is an AuthorizationFailed (lack of permission) API error
 */
export const isPermissionError = (error: any): error is ApiError =>
  isApiError(error, (err) => err.code === 'AuthorizationFailed');

/**
 * Check if an error is a MustChangePassword API error
 */
export const isMustChangePasswordError = (error: any): error is ApiError =>
  isApiError(error, (err) => err.code === 'MustChangePassword');

/**
 * Check if an error is a BusinessRulesFailed API error
 */
export const isBusinessRulesFailedError = (error: any): error is ApiError =>
  isApiError(error, (err) => err.code === 'BusinessRulesFailed');

/**
 * Check if an error is a ResourceAlreadyExists API error
 */
export const isResourceAlreadyExistsError = (error: any): error is ApiError =>
  isApiError(error, (err) => err.code === 'ResourceAlreadyExists');

/**
 * Check if an error is a ResourceNotFound API error
 */
export const isResourceNotFoundError = (error: any): error is ApiError =>
  isApiError(error, (err) => err.code === 'ResourceNotFound');

/**
 * Check if an error is a HTTP 404 Not Found error
 */
export const isHttpNotFoundError = (error: any): error is AxiosError =>
  Axios.isAxiosError(error) && error.response?.status === 404;

/**
 * If an error is an ApiError instance, get the pathname of that API resource to identify what API call the error pertains to
 */
export const getResourceForApi = (error: any): string | undefined => {
  if (isApiError(error)) {
    const { url, baseURL } = error.axiosError?.config ?? {};
    invariant(url, 'url is not defined');
    const resource = new URL(url, baseURL);
    return resource.pathname;
  }

  return undefined;
};
