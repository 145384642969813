import {
  ErrorOutlined as ErrorIcon,
  InfoOutlined as InfoIcon,
  WarningOutlined as WarningIcon,
} from '@mui/icons-material';
import { cloneElement, forwardRef, ReactElement, Ref } from 'react';
import { NotificationType } from '../../api';

export interface NotificationTypeIconProps {
  type: NotificationType | undefined | null;
}

const FallbackNotificationTypeIcon = (
  <InfoIcon sx={{ color: 'primary.main', display: 'flex' }} />
);
const NotificationTypeIcons: Record<NotificationType, ReactElement> = {
  [NotificationType.Informational]: FallbackNotificationTypeIcon,
  [NotificationType.Warning]: (
    <WarningIcon sx={{ color: 'warning.main', display: 'flex' }} />
  ),
  [NotificationType.Critical]: (
    <ErrorIcon sx={{ color: 'error.main', display: 'flex' }} />
  ),
};

/**
 * Icon for notification type
 */
export const NotificationTypeIcon = forwardRef(function NotificationTypeIcon(
  props: NotificationTypeIconProps,
  ref: Ref<any>
) {
  const { type } = props;

  if (!type) return cloneElement(FallbackNotificationTypeIcon, { ref });

  return cloneElement(NotificationTypeIcons[type], { ref });
});
