import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
} from '@mui/material';
import { FormikHelpers, FormikProps } from '@johnrom/formik-v3';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormProps, SubmitButton, SubmitButtonProps } from '../form';
import useDialogId from '../utils/useDialogId';

export type FormDialogProps<Values extends {}> = Omit<DialogProps, 'onSubmit'> &
  Pick<FormProps<Values>, 'initialValues' | 'onSubmit'> &
  Partial<
    Pick<
      FormProps<Values>,
      'enableReinitialize' | 'validate' | 'disableBlocker'
    >
  > & {
    name?: string;
    /**
     * Dialog title
     */
    title?: DialogTitleProps['children'];
    /**
     * Props applied to the `Form` element.
     */
    FormProps?: FormProps<Values>;
    /**
     * Props applied to the `Form` element.
     */
    DialogTitleProps?: DialogTitleProps;
    /**
     * Extra action buttons
     */
    extraActions?: ReactNode;
    /**
     * Disable the cancel button
     */
    disableCancelButton?: boolean;
    /**
     * Text for the cancel button
     */
    cancelButtonText?: ReactNode;
    /**
     * Props applied to the cancel `Button` element.
     */
    CancelButtonProps?: ButtonProps;
    /**
     * Callback fired when the cancel button is pressed.
     */
    onCancel?: () => void;
    /**
     * Disable the submit button
     */
    disableSubmitButton?: boolean;
    /**
     * Text for the submit button
     */
    submitButtonText?: ReactNode;
    /**
     * Props applied to the submit `SubmitButton` element.
     */
    SubmitButtonProps?: SubmitButtonProps;
  };

/**
 * A type of <Dialog> that includes a Formik form
 */
export const FormDialog = <Values extends {}>(
  props: FormDialogProps<Values>
) => {
  const {
    name,
    id: idProp,
    enableReinitialize,
    initialValues,
    validate,
    onSubmit,
    disableBlocker,
    FormProps,
    title,
    DialogTitleProps,
    extraActions,
    disableCancelButton,
    cancelButtonText,
    CancelButtonProps,
    onCancel,
    disableSubmitButton,
    submitButtonText,
    SubmitButtonProps,
    children,
    ...dialogProps
  } = props;
  const { t } = useTranslation();
  const { id, titleId } = useDialogId(name, idProp);
  const { onClose } = dialogProps;

  const submit = useCallback(
    async (values: Values, formikHelpers: FormikHelpers<Values>) => {
      const res = await onSubmit(values, formikHelpers);
      onClose?.({}, 'escapeKeyDown');
      return res;
    },
    [onClose, onSubmit]
  );

  const formProps: FormProps<Values> = {
    enableReinitialize,
    initialValues,
    validate,
    disableBlocker,
    ...FormProps,
    onSubmit: submit,
  };

  return (
    <Dialog {...dialogProps} id={id}>
      <Form {...formProps}>
        {({ isSubmitting }: FormikProps<Values>) => (
          <>
            <DialogTitle id={titleId} {...DialogTitleProps}>
              {title}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
              {extraActions}
              {extraActions && <Box flex={1} />}
              {!disableCancelButton && (
                <Button
                  disabled={isSubmitting}
                  onClick={(e) => {
                    onCancel?.();
                    onClose?.(e, 'escapeKeyDown');
                  }}
                  color="inherit"
                  {...CancelButtonProps}
                >
                  {cancelButtonText ?? t('button.cancel')}
                </Button>
              )}
              {!disableSubmitButton && (
                <SubmitButton color="primary" {...SubmitButtonProps}>
                  {submitButtonText ?? t('button.submit')}
                </SubmitButton>
              )}
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};
