import { QueryStringFilterValidator } from '../qs-validator';

export interface GetRiskSurveysFilters {
  searchText?: string;
}

/**
 * Cast query string arguments to GetRiskSurveysFilters
 * @note Generated by filtergen
 */
export function castGetRiskSurveysFilters(
  filters: qs.ParsedQs
): GetRiskSurveysFilters {
  const f = new QueryStringFilterValidator(filters);

  return {
    searchText: f.searchText('searchText'),
  };
}

/**
 * Make args for the GetRiskSurveySummaries query params
 * @see GET /clients/settings/risksurveys/summaries
 * @note Generated by filtergen
 */
export function makeGetRiskSurveySummariesFilterArgs(
  filters: GetRiskSurveysFilters
): [searchText: string | undefined] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
  ];
}
