import { DateTime, DurationLike } from 'luxon';
import ms from 'ms';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ApiQueryState,
  NotificationResponse,
  useApiQuery,
  useNotificationsApi,
} from '../../api';
import { setFutureTask } from '../../utils/setFutureTask';

type UseNotificationsContextResult = Pick<
  ApiQueryState<Array<NotificationResponse>>,
  'loading' | 'error'
> & {
  notifications: ApiQueryState<Array<NotificationResponse>>['data'];
};

const NotificationsResultContext = createContext<UseNotificationsContextResult>(
  {
    notifications: [],
    loading: false,
    error: undefined,
  }
);
const PrioritizeNotificationRefetchContext = createContext<
  (duration?: DurationLike) => void
>(() => {});

export interface NotificationProviderProps {
  children?: ReactNode;
}

/**
 * Helper that provides a priority boolean that can be enabled temporarily for a set period of time
 * @internal
 */
function usePrioritizeRefetch(): [
  refetchPrioritized: boolean,
  prioritizeRefetch: (duration?: DurationLike) => void
] {
  const [prioritizeRefetchUntil, setPrioritizeRefetchUntil] =
    useState<DateTime | null>(null);

  // Use an effect to set prioritizeRefetchUntil back to null once its timestamp is reached
  useEffect(() => {
    if (prioritizeRefetchUntil) {
      return setFutureTask(prioritizeRefetchUntil, () => {
        setPrioritizeRefetchUntil(null);
      });
    }
  }, [prioritizeRefetchUntil]);

  return [
    !!prioritizeRefetchUntil,
    useCallback((duration: DurationLike = { minutes: 2 }) => {
      setPrioritizeRefetchUntil((prioritizeRefetchUntil) => {
        const until = DateTime.utc().plus(duration);
        if (prioritizeRefetchUntil && prioritizeRefetchUntil > until)
          return prioritizeRefetchUntil;
        return until;
      });
    }, []),
  ];
}

/**
 * Returns a function that can be used to temporarily prioritize refetch of notifications
 */
export function usePrioritizeNotificationRefetch() {
  return useContext(PrioritizeNotificationRefetchContext);
}

/**
 * Provider that will fetch notifications
 */
export const NotificationsProvider = (props: NotificationProviderProps) => {
  const { children } = props;

  const [refetchPrioritized, prioritizeRefetch] = usePrioritizeRefetch();

  const {
    data: notifications,
    loading,
    error,
  } = useApiQuery(useNotificationsApi().getAllNotifications(undefined), {
    auth: 'AUTHENTICATED',
    noErrorRedirect: true,
    refetchInterval: refetchPrioritized ? ms('5 seconds') : ms('1 minute'),
    useErrorBoundary: false,
  });

  const result = useMemo(
    () => ({
      notifications,
      loading,
      error,
    }),
    [error, loading, notifications]
  );

  return (
    <PrioritizeNotificationRefetchContext.Provider value={prioritizeRefetch}>
      <NotificationsResultContext.Provider value={result}>
        {children}
      </NotificationsResultContext.Provider>
    </PrioritizeNotificationRefetchContext.Provider>
  );
};

export type UseNotificationsResult = UseNotificationsContextResult & {
  unreadCount: number;
};

/**
 * List unread/read or all notifications
 */
export function useNotifications(options?: {
  isRead?: boolean | undefined;
}): UseNotificationsResult {
  const { isRead } = options || {};
  const { notifications: allNotifications, ...result } = useContext(
    NotificationsResultContext
  );

  const notifications =
    isRead == null
      ? allNotifications
      : allNotifications?.filter(
          (notification) => notification.Read === isRead
        );

  const notificationCount =
    allNotifications?.filter((notification) => !notification.Read)?.length ?? 0;

  return { ...result, notifications, unreadCount: notificationCount };
}
