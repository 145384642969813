import preval from 'preval.macro';
import { ReactComponent as Logo } from '../assets/ta-logo-96.svg';
import { ReactComponent as LogoIcon } from '../assets/ta-logo.svg';
import { Branding } from './types';

const buildYear = preval`module.exports = new Date().getFullYear();`;

const branding: Branding = {
  siteName: 'Transactive',
  copyright: `©2020-${buildYear} Transactive.`,
  logoIcon: LogoIcon,
  logo: Logo,
};

export default branding;
