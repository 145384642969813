import {
  DoneOutlined as DoneIcon,
  InfoOutlined,
  RadioButtonChecked,
} from '@mui/icons-material';
import { CloseCircleOutline } from 'mdi-material-ui';
import { ReactElement } from 'react';
import { ComplianceStatus } from '../../api';

export interface ComplianceStatusIconProps {
  status: ComplianceStatus | undefined;
}

const FallbackComplianceStatusIcon = (
  <RadioButtonChecked sx={{ color: 'text.secondary' }} />
);
const ComplianceStatusIcons: Record<ComplianceStatus, ReactElement> = {
  [ComplianceStatus.Compliant]: <DoneIcon sx={{ color: 'success.main' }} />,
  [ComplianceStatus.NonCompliant]: (
    <CloseCircleOutline sx={{ color: 'error.main' }} />
  ),
  [ComplianceStatus.UnderReview]: (
    <InfoOutlined sx={{ color: 'primary.main' }} />
  ),
  [ComplianceStatus.NeedsReview]: FallbackComplianceStatusIcon,
};

/**
 * Icon for a compliance status
 */
export const ComplianceStatusIcon = (props: ComplianceStatusIconProps) => {
  const { status } = props;

  if (!status) return FallbackComplianceStatusIcon;

  return ComplianceStatusIcons[status];
};
