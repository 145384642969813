import { createContext, createElement, ReactNode, useContext } from 'react';

const ReviewEditableContext = createContext(false);

export interface ReviewEditableProps {
  editable: boolean;
  children?: ReactNode;
}

/**
 * Control the editability of field reviews
 */
export const ReviewEditable = (props: ReviewEditableProps) => {
  const { editable, children } = props;

  return createElement(
    ReviewEditableContext.Provider,
    { value: editable },
    children
  );
};

/**
 * Are we in an editable review context
 */
export function useReviewEditable(): boolean {
  return useContext(ReviewEditableContext);
}
