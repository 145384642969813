import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { useCallback } from 'react';
import useHtmlId from '../../../utils/useHtmlId';
import { TransferListControlProps } from '../../controls';
import { useFieldDisabled } from '../../disabled';

export type UseTransferListFieldProps<Option> = Omit<
  FieldHookConfig<Option[]>,
  'value' | 'type' | 'multiple'
> &
  Omit<TransferListControlProps<Option>, 'name' | 'value' | 'error'>;

/**
 * useField wrapper that includes fixed variants of formik-material-ui's fieldToTextField customized for TransferListControl
 */
export function useTransferListFieldProps<Option>({
  id: idProp,
  disabled: disabledProp,
  helperText,
  ...props
}: UseTransferListFieldProps<Option>): TransferListControlProps<Option> {
  const disabled = useFieldDisabled(disabledProp);
  const [field, meta, { setValue }] = useField<Option[]>({
    ...props,
    multiple: true,
  });
  const { name } = field;
  const id = useHtmlId(name, idProp);
  // Replace onChange with one aware of TransferListControl's onChange
  const onChange = useCallback(
    (value: Option[]) => {
      setValue(value);
    },
    [setValue]
  );

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  return {
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled,
    ...field,
    ...props,
    value: meta.value,
    onChange,
    id,
    name,
  };
}
