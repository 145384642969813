import invariant from 'invariant';
import { useAdminApi } from '../../api';
import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
  useBreadcrumbApiQuery,
} from '../../breadcrumbs';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const ClientProfilesTitle = t('page.clientProfiles');
export const ClientProfileDetailsTitle = p(({ clientProfileId }) => {
  invariant(clientProfileId, 'clientProfileId is expected');
  return useBreadcrumbApiQuery(
    useAdminApi().getClientProfile(clientProfileId),
    (clientProfile) => clientProfile?.Name
  );
});
export const AddClientProfileTitle = t('page.newClientProfile');
