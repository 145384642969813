import { Card, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SignificantPersonContactSummary } from '../../api';
import {
  useCountryNames,
  useLuxonLocaleFormatter,
  usePercentFormatter,
  useYesNoFormatter,
} from '../../intl';
import { Definition, DefinitionList } from '../../ui/Definition';

export interface SignificantPersonCardProps {
  actions?: ReactNode;
  contact: SignificantPersonContactSummary;
}

export const SignificantPersonCardCard = styled(Card)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateAreas: `"content actions"`,
    gridTemplateColumns: `1fr auto`,
    '& > .content': {
      gridArea: 'content',
      '& > :last-child': { marginBottom: 0 },
    },
    '& > .actions': { gridArea: 'actions' },
  }),
  { name: 'SignificantPersonCardCard' }
);

/**
 * A card with a Significant Person
 */
export const SignificantPersonCard = (props: SignificantPersonCardProps) => {
  const { contact, actions } = props;
  const { t } = useTranslation();
  const formatDate = useLuxonLocaleFormatter(DateTime.DATE_SHORT);
  const countryName = useCountryNames();
  const formatPercent = usePercentFormatter();
  const formatYesNo = useYesNoFormatter();

  return (
    <SignificantPersonCardCard>
      <div className="content">
        <Typography color="textSecondary" gutterBottom>
          {`${contact.FirstName} ${contact.FamilyName}`}
        </Typography>

        <DefinitionList>
          <Definition
            label={t('inputLabel.firstName')}
            value={contact.FirstName}
          />
          <Definition
            label={t('inputLabel.familyName')}
            value={contact.FamilyName}
          />
          <Definition
            label={t('definitionLabel.dob')}
            value={
              contact.Dob
                ? formatDate(DateTime.fromISO(contact.Dob).toUTC())
                : ''
            }
          />
          <Definition
            label={t('inputLabel.nationality')}
            value={contact.Nationality ? countryName(contact.Nationality) : ''}
          />
          <Definition
            label={t('inputLabel.ownershipPercentage')}
            value={
              typeof contact.OwnershipPercentage === 'number'
                ? formatPercent(contact.OwnershipPercentage / 100)
                : ''
            }
          />
          <Definition
            label={t('definitionLabel.pep')}
            value={formatYesNo(contact.PEP)}
          />
        </DefinitionList>
      </div>
      <div className="actions">{actions}</div>
    </SignificantPersonCardCard>
  );
};
