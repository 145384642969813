import { GlobalStyles, styled } from '@mui/material';
import { ComponentProps } from 'react';
import { getViewportBackgroundStyles } from '../theme/gradient';

const BaseLayoutRoot = styled('div', { name: 'BaseLayout', slot: 'Root' })(
  ({ theme }) => ({
    flex: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  })
);

/**
 * Core styles common to all layouts
 */
const BaseLayout = (props: ComponentProps<typeof BaseLayoutRoot>) => {
  const { children, ...other } = props;

  return (
    <BaseLayoutRoot {...other}>
      <GlobalStyles
        styles={(theme) => ({
          html: {
            minHeight: '100%',
          },
          'html, body, #root': {
            display: 'flex',
            flexDirection: 'column',
          },
          'body, #root': {
            flex: 1,
          },
          body: {
            backgroundColor: theme.palette.background.default,
            backgroundImage: 'none',
            backgroundAttachment: 'fixed',
            ...getViewportBackgroundStyles(theme.palette.background),
          },
        })}
      />
      {children}
    </BaseLayoutRoot>
  );
};

export default BaseLayout;
