import invariant from 'invariant';
import { cloneElement, ReactElement } from 'react';

export type ComposableProviderFunction = (
  children: ReactElement | null
) => ReactElement;
export type ComposableProvider =
  | ReactElement
  | ComposableProviderFunction
  | null;

const NullProvider = (children: ReactElement | null) => {
  invariant(children, 'expected at least one provider');
  return children;
};

/**
 * Take a list of React provider components and nest them together
 * [<A />, <B />, <C />] -> <A><B><C /></B></A>
 */
export function composeProviders(
  ...providers: ComposableProvider[]
): ReactElement {
  const composed = providers
    .map((provider): ComposableProviderFunction => {
      if (provider === null) {
        return NullProvider;
      } else if (typeof provider === 'function') {
        return provider;
      } else {
        return (children: ReactElement | null): ReactElement => {
          invariant(
            !provider.props.children,
            'provider element cannot have children, they will be replaced'
          );

          return cloneElement(provider, {}, children);
        };
      }
    })
    .reduce(
      (parent, child): ComposableProviderFunction =>
        (children) =>
          parent(child(children)),
      NullProvider
    );

  return composed(null);
}
