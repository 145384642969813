import { useMemo } from 'react';
import { QueryClient } from 'react-query';
import {
  ApiQueryOptions,
  ApiQueryState,
  ThirdPartiesApiQueryKey,
  ThirdParty,
  useAdminApi,
  useApiQuery,
  useThirdPartiesApi,
  AdminApiQueryKey,
  ThirdPartySummary,
  ThirdPartyAdminSummary,
} from '../client';
import {
  GetThirdPartiesFilters,
  makeGetAllClientsThirdPartiesFilterArgs,
  makeGetAllClientThirdPartiesFilterArgs,
  makeGetThirdPartiesFilterArgs,
} from '../filters';

/**
 * Handle cache updates for a ThirdParty
 */
export function updateCachedThirdParty(
  queryClient: QueryClient,
  clientId: string | undefined,
  thirdPartyId: string,
  thirdParty?: ThirdParty | ThirdPartySummary
): void {
  // Client
  if (thirdParty) {
    queryClient.setQueryData(
      ThirdPartiesApiQueryKey.getThirdParty(thirdPartyId),
      thirdParty
    );
  } else {
    queryClient.invalidateQueries(
      ThirdPartiesApiQueryKey.getThirdParty(thirdPartyId)
    );
  }
  queryClient.invalidateQueries(ThirdPartiesApiQueryKey.getThirdPartiesRoot);
  queryClient.invalidateQueries(
    ThirdPartiesApiQueryKey.getThirdPartySummariesRoot
  );

  // Admin
  if (clientId) {
    if (thirdParty) {
      queryClient.setQueryData(
        [AdminApiQueryKey.getClientThirdParty(clientId, thirdPartyId)],
        thirdParty
      );
    } else {
      queryClient.invalidateQueries(
        AdminApiQueryKey.getClientThirdParty(clientId, thirdPartyId)
      );
    }
    queryClient.invalidateQueries([
      AdminApiQueryKey.getAllClientThirdPartiesRoot,
      clientId,
    ]);
    queryClient.invalidateQueries(
      AdminApiQueryKey.getAllClientsThirdPartiesRoot
    );
    queryClient.invalidateQueries([
      AdminApiQueryKey.getAllClientThirdPartySummariesRoot,
      clientId,
    ]);
  }
}

/**
 * Get a ThirdParty
 */
export function useThirdParty(
  clientId: string | undefined | null,
  thirdPartyId: string | undefined,
  options: ApiQueryOptions<ThirdParty> = {}
): ApiQueryState<ThirdParty> {
  const thirdPartiesApi = useThirdPartiesApi();
  const adminApi = useAdminApi();

  return useApiQuery(
    useMemo(() => {
      if (!thirdPartyId) return null;
      return clientId
        ? adminApi.getClientThirdParty(clientId, thirdPartyId)
        : thirdPartiesApi.getThirdParty(thirdPartyId);
    }, [adminApi, clientId, thirdPartiesApi, thirdPartyId]),
    { ...options, skip: options.skip || !thirdPartyId }
  );
}

/**
 * Get a list of third parties
 */
export function useThirdPartiesQuery(
  variant: 'client' | 'admin',
  clientId: string | undefined | null,
  filter: GetThirdPartiesFilters | undefined,
  options?: ApiQueryOptions<ThirdPartySummary[] | ThirdPartyAdminSummary[]>
) {
  const thirdpartiesApi = useThirdPartiesApi();
  const adminApi = useAdminApi();

  return useApiQuery(
    useMemo(() => {
      if (variant === 'admin') {
        return clientId
          ? adminApi.getAllClientThirdParties(
              clientId,
              ...makeGetAllClientThirdPartiesFilterArgs(filter ?? {})
            )
          : adminApi.getAllClientsThirdParties(
              ...makeGetAllClientsThirdPartiesFilterArgs(filter ?? {})
            );
      } else {
        return thirdpartiesApi.getThirdParties(
          ...makeGetThirdPartiesFilterArgs(filter ?? {})
        );
      }
    }, [adminApi, clientId, thirdpartiesApi, filter, variant]),
    options
  );
}
