import { ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import { ComponentProps, ReactElement, ReactNode } from 'react';
import { getBackgroundTopColor, getSecondaryContrastText } from '../../theme';
import { listItemPropsForNavigation } from '../sidebar/utils/listItemPropsForNavigation';

interface OwnerState {
  disabled: boolean;
  active: boolean;
}

const SidebarMenuItemRoot = styled(ListItem, {
  name: 'SidebarMenuItem',
  slot: 'Root',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  '@media (pointer: fine)': {
    minHeight: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },
  ...(ownerState.disabled && {
    color: getSecondaryContrastText(
      getBackgroundTopColor(theme.palette.background)
    ),
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const SidebarMenuItemText = styled(ListItemText, {
  name: 'SidebarMenuItem',
  slot: 'Text',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  '@media (pointer: fine)': {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export type SidebarMenuItemProps = Omit<
  ComponentProps<typeof SidebarMenuItemRoot>,
  'ownerState' | 'button'
> & {
  active?: boolean;
  disabled?: boolean;
  href?: string;
  icon?: ReactElement;
  primary?: ReactNode;
  to?: string | undefined;
};

/**
 * Styled list item for navigation items inside a subnav popover
 */
const SidebarMenuItem = (props: SidebarMenuItemProps) => {
  const {
    disabled = false,
    icon,
    primary,
    active = false,
    to,
    href,
    onClick,
    className,
    ...other
  } = props;

  const { button, ...navigationProps } = listItemPropsForNavigation({
    disabled,
    to,
    href,
    onClick,
  });

  const ownerState: OwnerState = {
    disabled,
    active,
  };

  return (
    <SidebarMenuItemRoot
      {...navigationProps}
      {...other}
      ownerState={ownerState}
      as={button ? ListItemButton : ListItem}
    >
      <SidebarMenuItemText
        primary={primary}
        primaryTypographyProps={{
          variant: 'caption',
        }}
        ownerState={ownerState}
      />
    </SidebarMenuItemRoot>
  );
};

export default SidebarMenuItem;
