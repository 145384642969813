import { styled, Theme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

const xlFormPageMaxWidth = (theme: Theme) =>
  `${theme.breakpoints.values.xl}px - ${
    theme.dimensions.drawerWidth
  }px - ${theme.spacing(2 * 2)} - ${theme.spacing(3)}`;

const FormPageRoot = styled('div', { name: 'FormPage', slot: 'Root' })(
  ({ theme }) => ({
    width: '100%',
    display: 'grid',

    // Small screen friendly stacked single column layout
    maxWidth: 'none',
    gridTemplateColumns: '[start] 1fr [end]',
    gap: theme.spacing(2),

    '& > .area-title': {},
    '& > .area-intro': {},
    '& > .area-body': {
      minWidth: 100,
    },
    '& > .MuiDivider-fullWidth': {
      gridColumn: 'start / end',
    },
    '& > .MuiDivider-inset': {
      gridColumn: 'start / end',
      marginLeft: 0,
    },

    // 2·3 left aligned layout with a max-width that aligns with the XL breakpoint without scrollbar
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(${xlFormPageMaxWidth(theme)})`,
      gridTemplateColumns:
        '[start aside-start] 5fr [aside-end body-start] 7fr [body-end end]',
      gap: theme.spacing(0, 3),

      '& > .area-title': {
        gridColumn: 'start / end',
        paddingTop: theme.spacing(3 - 2),
        marginLeft: theme.spacing(6 - 2),
        marginRight: theme.spacing(0),
        paddingBottom: theme.spacing(3),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '& > .title-wrapper': {
          maxWidth: `calc((${xlFormPageMaxWidth(
            theme
          )}) * (8 / 12) + ${theme.spacing(3)})`,
        },
      },
      '& > .MuiDivider-fullWidth': {
        gridColumn: 'start / end',
        marginLeft: theme.spacing(6 - 2),
      },
      '& > .MuiDivider-inset': {
        gridColumn: 'body-start / end',
        marginLeft: theme.spacing(0),
        // marginLeft: theme.spacing(6 - 2),
      },
      '& > .area-intro': {
        gridColumn: 'aside-start / aside-end',
        marginLeft: theme.spacing(6 - 2),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(3),
      },
      '& > .area-body': {
        gridColumn: 'body-start / body-end',
        paddingTop: theme.spacing(3 - 1),
        paddingBottom: theme.spacing(3 - 0.5),
      },
    },
  })
);

export const FormPageIntro = (props: PropsWithChildren<{}>) => (
  <div className="area-intro">{props.children}</div>
);

export const FormPageBody = (props: PropsWithChildren<{}>) => (
  <div className="area-body">{props.children}</div>
);

export interface FormPageProps {
  title?: ReactNode;
  intro?: ReactNode;
  children?: ReactNode;
  /**
   * Make the right hand size full-size on large screens so it can be used for review cards
   */
  extended?: boolean;
}

/**
 * A layout component that provides a left hand text area and a centered form area
 */
export const FormPage = (props: FormPageProps) => {
  const { title, children } = props;

  return (
    <FormPageRoot>
      {title && (
        <div className="area-title">
          <div className="title-wrapper">{title}</div>
        </div>
      )}
      {children}
    </FormPageRoot>
  );
};
