import { ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import { ComponentProps, ReactElement, ReactNode } from 'react';
import { getBackgroundTopColor, getSecondaryContrastText } from '../../theme';
import { listItemPropsForNavigation } from '../sidebar/utils/listItemPropsForNavigation';

interface OwnerState {
  disabled: boolean;
  active: boolean;
}

const SidebarNavItemRoot = styled(ListItem, {
  name: 'SidebarNavItem',
  slot: 'Root',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.getContrastText(
    getBackgroundTopColor(theme.palette.background)
  ),
  minHeight: 48,
  paddingLeft: theme.spacing(3),
  paddingRight: 0,

  '@media (pointer: fine)': {
    minHeight: 24,
    paddingTop: 0,
    paddingBottom: 0,
  },

  ...(ownerState.disabled && {
    color: getSecondaryContrastText(
      getBackgroundTopColor(theme.palette.background)
    ),
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
}));

const SidebarNavItemText = styled(ListItemText, {
  name: 'SidebarNavItem',
  slot: 'Text',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  marginLeft: `calc(24px + ${theme.spacing(2)})`,

  '@media (pointer: fine)': {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export type SidebarNavItemProps = Omit<
  ComponentProps<typeof SidebarNavItemRoot>,
  'ownerState'
> & {
  active?: boolean;
  disabled?: boolean;
  href?: string;
  icon?: ReactElement;
  primary?: ReactNode;
  to?: string | undefined;
};

/**
 * Sidebar navigation item
 * (Desktop mode, nested item)
 */
const SidebarNavItem = (props: SidebarNavItemProps) => {
  const {
    disabled = false,
    icon,
    primary,
    active = false,
    to,
    href,
    onClick,
    className,
    ...other
  } = props;

  const { button, ...navigationProps } = listItemPropsForNavigation({
    disabled,
    to,
    href,
    onClick,
  });

  const ownerState: OwnerState = {
    disabled,
    active,
  };

  return (
    <SidebarNavItemRoot
      {...navigationProps}
      {...other}
      ownerState={ownerState}
      as={button ? ListItemButton : ListItem}
    >
      <SidebarNavItemText
        primary={primary}
        primaryTypographyProps={{
          variant: 'body2',
        }}
        ownerState={ownerState}
      />
    </SidebarNavItemRoot>
  );
};

export default SidebarNavItem;
