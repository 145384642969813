import { forwardRef, ReactElement, Ref } from 'react';
import { TransferListControl } from '../controls';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import {
  useTransferListFieldProps,
  UseTransferListFieldProps,
} from './internal/useTransferListFieldProps';

export type TransferListFieldProps<T extends unknown> = Omit<
  UseTransferListFieldProps<T>,
  'ref' | 'value' | 'error'
> &
  CommonValidatorProps;

export const TransferListField = forwardRef(function TransferListField<
  T extends unknown
>(props: TransferListFieldProps<T>, ref: Ref<any>) {
  const validate = useCommonValidate(props);
  const fieldProps = useTransferListFieldProps<T>({
    ...props,
    validate,
  });

  return <TransferListControl<T> ref={ref} {...fieldProps} />;
}) as <T extends unknown>(
  props: TransferListFieldProps<T> & { ref?: Ref<any> }
) => ReactElement;
