import { useFieldMeta } from '@johnrom/formik-v3';
import { MenuItem } from '@mui/material';
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  AdminApiQueryKey,
  ClientProfileShortSummary,
  getClientProfileId,
  isResourceNotFoundError,
  makeGetClientProfilesSummariesFilterArgs,
  useAdminApi,
} from '../../api';
import { useOpenState } from '../../utils/useOpenState';
import { useOptionLabel, useOptions, useRenderOption } from '../utils';
import {
  DynamicComboBoxField,
  DynamicComboBoxFieldProps,
  useComboBoxApiQuery,
  useInputTextState,
  useOptionsWithCurrent,
} from './internal/DynamicComboBox';

export type ClientProfileFieldProps = Omit<
  DynamicComboBoxFieldProps,
  | 'options'
  | 'getOptionLabel'
  | 'renderOption'
  | 'getOptionDisabled'
  | 'open'
  | 'onOpen'
  | 'onClose'
  | 'onInputChange'
  | 'loading'
> & {
  preload?: boolean;
};

interface ClientProfileShortSummaryValue extends ClientProfileShortSummary {
  notFound?: boolean;
  loading?: boolean;
}

/**
 * A combo box field for selecting a client profile
 */
export const ClientProfileField = forwardRef(function ClientProfileField(
  props: ClientProfileFieldProps,
  ref: any
) {
  const { preload = false, ...other } = props;
  const { open, openProps } = useOpenState();
  const [inputText, onInputChange] = useInputTextState();
  const searchText = inputText || undefined;
  const { value } = useFieldMeta<string | null>(other.name); // @fixme Combobox should be a hook so we can access the value

  const queryClient = useQueryClient();
  const adminApi = useAdminApi();

  const {
    oneData: oneClientProfile,
    oneError: oneClientProfileError,
    allData: allClientProfiles,
    loadingOne: loadingOneClientProfile,
    loading,
    hasNextPage,
  } = useComboBoxApiQuery<ClientProfileShortSummary>({
    value,
    open,
    searchText,
    getOneByValue: (value) => adminApi.getClientProfileSummaryById(value),
    searchAllByValue: (searchText) =>
      adminApi.getClientProfilesSummaries(
        ...makeGetClientProfilesSummariesFilterArgs({ searchText })
      ),
    preCacheOneItem: (clientProfile) => {
      queryClient.setQueryData(
        AdminApiQueryKey.getClientProfileSummaryById(clientProfile.Id ?? ''),
        clientProfile
      );
    },
    preload,
  });

  const selectedClientProfile =
    useMemo<ClientProfileShortSummaryValue | null>(() => {
      if (oneClientProfile) return oneClientProfile;

      if (value) {
        return {
          Id: value,
          Name: value,
          notFound: isResourceNotFoundError(oneClientProfileError),
          loading: loadingOneClientProfile,
        };
      }

      return null;
    }, [
      loadingOneClientProfile,
      oneClientProfile,
      oneClientProfileError,
      value,
    ]);

  const getId = getClientProfileId;
  const clientProfiles = useOptionsWithCurrent(
    allClientProfiles,
    selectedClientProfile,
    getId
  );
  const options = useOptions(clientProfiles, getId);
  const getLabelForClientProfile = useCallback(
    (client: ClientProfileShortSummary) => client.Name ?? '',
    []
  );
  const getOptionLabel = useOptionLabel(
    clientProfiles,
    getId,
    getLabelForClientProfile
  );
  const renderClientProfileOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      client: ClientProfileShortSummary
    ) => <MenuItem {...props}>{getLabelForClientProfile(client)}</MenuItem>,
    [getLabelForClientProfile]
  );
  const renderOption = useRenderOption(
    clientProfiles,
    getId,
    renderClientProfileOption
  );

  let inputPrefix: ReactNode;
  if (selectedClientProfile?.notFound) {
    inputPrefix = '❌';
  } else if (selectedClientProfile?.loading) {
    inputPrefix = '⋯';
  }

  return (
    <DynamicComboBoxField
      ref={ref}
      {...other}
      {...{
        options,
        loading,
        hasNextPage,
        getOptionLabel,
        renderOption,
        onInputChange,
        inputPrefix,
      }}
      {...openProps}
    />
  );
});
