import { forwardRef } from 'react';
import { Role } from '../../../api';
import { useRoleLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { TagArrayField, TagArrayFieldProps } from '../TagArrayField';

export type RoleArrayFieldProps = Omit<
  TagArrayFieldProps<Role, false>,
  'options'
>;

/**
 * A Tag array combobox field for selecting a list of Authorities
 */
export const RoleArrayField = forwardRef(function RoleArrayField(
  props: RoleArrayFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(Role, useRoleLabels());

  return <TagArrayField ref={ref} {...other} {...optionsProps} />;
});
