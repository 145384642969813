import { forwardRef, ReactElement, Ref } from 'react';
import ListControl from '../controls/ListControl';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import {
  UseListFieldProps,
  useListFieldProps,
} from './internal/useListFieldProps';

export type TextListFieldProps<Option = unknown> = Omit<
  UseListFieldProps<Option>,
  | 'ref'
  | 'createOption'
  | 'freeSolo'
  | 'selectOnFocus'
  | 'clearOnBlur'
  | 'handleHomeEndKeys'
> &
  CommonValidatorProps;

/**
 * A <ListControl> allowing you to add a list of freeform text items
 */
export const TextListField = forwardRef(function TextListField<
  Option extends unknown = unknown
>(props: TextListFieldProps<Option>, ref: Ref<any>) {
  const validate = useCommonValidate(props);
  const fieldProps = useListFieldProps<Option>({
    ...props,
    validate,
  });

  return (
    <ListControl<Option>
      ref={ref}
      margin="dense" // theme
      fullWidth // theme
      {...fieldProps}
      createOption
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
}) as <Option extends unknown = unknown>(
  props: TextListFieldProps<Option>
) => ReactElement;
