import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type SupplierFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a supplier
 */
export const SupplierField = forwardRef(function SupplierField(
  props: SupplierFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [supplierFields, loadingSupplierFields] = useCodes('suppliers');
  const optionProps = useCodeOptionsProps(supplierFields);

  return (
    <SelectField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingSupplierFields}
    />
  );
});
