import { useLabelFn } from '../../../intl';
import { enumArray } from '../../utils';
import { useSortedOptions } from '../options';
import { OptionsProps } from './type';

/**
 * Generate SelectField/Autocomplete props to use an Enum with localized labels as options
 */
export function useEnumOptions<E extends string, V extends string>(
  Enum: Record<E, V>,
  labels: Record<E, string>
): OptionsProps<V> {
  const getOptionLabel = useLabelFn(labels);
  const options = useSortedOptions<V>(enumArray(Enum), getOptionLabel);

  return { options, getOptionLabel };
}
