import { AppBar, Box, Button, Chip, styled, Toolbar } from '@mui/material';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthState, useCurrentPageReturnTo } from '../auth';
import { useAppEnvironmentLabels, useLabelFn } from '../intl';
import { Notifications } from '../notifications';
import { getBackgroundTopColor } from '../theme';
import { AppEnvironment, APP_ENV } from '../utils/env';
import HeaderTimeZoneMenu from './HeaderTimeZoneMenu';
import HeaderUserMenu from './HeaderUserMenu';

const SiteHeaderRoot = styled(AppBar, { name: 'SiteHeader', slot: 'Root' })(
  ({ theme }) => ({
    color: theme.palette.getContrastText(
      getBackgroundTopColor(theme.palette.background)
    ),
    // Assume the drawer is always expanded unless the screen is too small for it and inset the left to account for it
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.dimensions.drawerWidth,
    },
    zIndex: 0,
  })
);

/**
 * A spacing component with the same height as the SiteHeader
 */
const SiteHeaderSpacer = styled('div', { name: 'SiteHeader', slot: 'Spacer' })(
  ({ theme }) => ({
    ...theme.mixins.toolbar,
  })
);

/**
 * Site header including app bar
 */
const SiteHeader = (props: ComponentProps<typeof SiteHeaderRoot>) => {
  const { className, ...other } = props;
  const { t } = useTranslation();
  const { uid } = useAuthState();
  const returnTo = useCurrentPageReturnTo();
  const appEnvironmentLabel = useLabelFn(useAppEnvironmentLabels());

  return (
    <>
      <SiteHeaderRoot
        position="absolute"
        color="transparent"
        {...other}
        className={className}
        elevation={0}
      >
        <Toolbar>
          <Box sx={{ flex: 1 }} />
          {APP_ENV !== AppEnvironment.Production && (
            <Chip
              variant="filled"
              color="warning"
              label={t('title.nonProductionEnvChip', {
                environmentName: appEnvironmentLabel(APP_ENV),
              })}
            />
          )}

          <Notifications />

          <HeaderTimeZoneMenu />

          {uid ? (
            <HeaderUserMenu uid={uid} returnTo={returnTo} />
          ) : (
            <Button
              component={Link}
              to={{ pathname: '/login' }}
              state={{ returnTo }}
              color="inherit"
            >
              {t('button.signIn')}
            </Button>
          )}
        </Toolbar>
      </SiteHeaderRoot>
      {/* Static spacing element that will fill the floating header's position */}
      <SiteHeaderSpacer className={className} />
    </>
  );
};

export default SiteHeader;
