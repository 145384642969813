import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
  useBreadcrumbApiQuery,
} from '../../breadcrumbs';
import invariant from 'invariant';
import { useAdminApi } from '../../api';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const RiskSurveysTitle = t('page.riskSurveys');
export const RiskSurveyDetailsTitle = p(({ riskSurveyId }) => {
  invariant(riskSurveyId, 'riskSurveyId is expected');
  return useBreadcrumbApiQuery(
    useAdminApi().getRiskSurvey(riskSurveyId),
    (riskSurvey) => riskSurvey?.Name
  );
});
export const AddRiskSurveyTitle = t('page.addRiskSurvey');
