import { ButtonBase, styled, Typography } from '@mui/material';
import { ComponentProps, ReactElement } from 'react';

const HeaderMenuButtonRoot = styled(ButtonBase, {
  name: 'HeaderMenuButton',
  slot: 'Root',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,

  '&:before': {
    content: '""',
    opacity: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'currentColor',
    zIndex: 0,

    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '&.Mui-focusVisible:before': {
    opacity: 0.3,
  },
}));

const HeaderMenuButtonIcon = styled('div', {
  name: 'HeaderMenuButton',
  slot: 'Icon',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginLeft: theme.spacing(0.5),
}));

export type HeaderMenuButtonProps = ComponentProps<
  typeof HeaderMenuButtonRoot
> & {
  icon?: ReactElement;
};

/**
 * Button for header menus like the user menu
 *
 * Uses body text and expects an icon
 */
const HeaderMenuButton = (props: HeaderMenuButtonProps) => {
  const { children, icon, className, ...other } = props;

  return (
    <HeaderMenuButtonRoot {...other}>
      <Typography component="span" variant="body2">
        {children}
      </Typography>
      {icon && <HeaderMenuButtonIcon>{icon}</HeaderMenuButtonIcon>}
    </HeaderMenuButtonRoot>
  );
};

export default HeaderMenuButton;
