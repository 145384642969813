import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  TextField,
} from '@mui/material';
import { forwardRef, ReactElement, ReactNode, Ref } from 'react';
import {
  UseAutocompleteFieldProps,
  useAutocompleteTextFieldProps,
} from './internal/useAutocompleteFieldProps';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';

export type TagArrayFieldProps<
  T,
  DisableClearable extends boolean | undefined
> = Omit<
  UseAutocompleteFieldProps<T, true, DisableClearable, false>,
  | 'onChange'
  | 'multiple'
  | 'freeSolo'
  | 'disableCloseOnSelect'
  | 'renderOption'
  | 'renderInput'
> &
  CommonValidatorProps & { readOnly?: boolean; label?: ReactNode };

export const TagArrayField = forwardRef(function TagArrayField<
  T,
  DisableClearable extends boolean | undefined
>(props: TagArrayFieldProps<T, DisableClearable>, ref: Ref<any>) {
  const {
    required = false,
    label,
    getOptionLabel,
    ...other
  } = usePropsWithId<TagArrayFieldProps<T, DisableClearable>>(props);

  const validate = useCommonValidate(props);
  const { getAutocompleteProps, getTextFieldProps } =
    useAutocompleteTextFieldProps<T, true, DisableClearable, false>({
      ...other,
      validate,
      multiple: true,
      disableCloseOnSelect: true,
      getOptionLabel,
    });

  return (
    <Autocomplete<T, true, DisableClearable, false>
      ref={ref}
      {...getAutocompleteProps()}
      renderOption={(
        props,
        option: T,
        { selected }: AutocompleteRenderOptionState
      ) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {getOptionLabel?.(option)}
        </li>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...getTextFieldProps(params)}
          required={required}
          label={label}
        />
      )}
    />
  );
}) as <T, DisableClearable extends boolean | undefined>(
  props: TagArrayFieldProps<T, DisableClearable>,
  ref: Ref<any>
) => ReactElement;
