import { useFieldHelpers, useFieldMeta } from '@johnrom/formik-v3';
import { useSnackbar } from 'notistack';
import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type GovIdTypeFieldProps = Omit<SelectFieldProps, 'options'> & {
  govIdVariant?: 'default' | 'contact' | 'company';
};

/**
 * A <Select> field for selecting a goverment id type
 */
export const GovIdTypeField = forwardRef(function GovIdTypeField(
  props: GovIdTypeFieldProps,
  ref: any
) {
  const { govIdVariant = 'default', ...other } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { value } = useFieldMeta<string>(props.name);
  const { setValue } = useFieldHelpers(props.name);
  const [governmentIdTypes, loadingGovernmentIdTypes] = useCodes('govtidtypes');
  const [contactGovernmentIdTypes, loadingContactGovernmentIdTypes] =
    useCodes('govtcontactidtypes');
  const [companyGovernmentIdTypes, loadingCompanyGovernmentIdTypes] =
    useCodes('govtcompanyidtypes');

  let idTypes = governmentIdTypes;
  let loadingIdTypes = loadingGovernmentIdTypes;
  if (govIdVariant === 'contact') {
    idTypes = contactGovernmentIdTypes;
    loadingIdTypes = loadingContactGovernmentIdTypes;
  } else if (govIdVariant === 'company') {
    idTypes = companyGovernmentIdTypes;
    loadingIdTypes = loadingCompanyGovernmentIdTypes;
  }

  useEffect(() => {
    if (!idTypes || !governmentIdTypes) return;

    if (
      !idTypes.some((type) => type.Id === value) &&
      governmentIdTypes.some((type) => type.Id === value)
    ) {
      setValue('');
      enqueueSnackbar(t('error.invalidGovernmentIdType'), { variant: 'error' });
    }
  }, [
    enqueueSnackbar,
    governmentIdTypes,
    idTypes,
    props.name,
    setValue,
    t,
    value,
  ]);

  const optionProps = useCodeOptionsProps(idTypes);

  return (
    <SelectField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingIdTypes}
    />
  );
});
