import {
  FieldHookConfig,
  FormikHelpers,
  useField,
  useFormikContext,
} from '@johnrom/formik-v3';
import {
  AddOutlined as AddIcon,
  DoneOutlined as DoneIcon,
  EditOutlined as EditIcon,
  KeyboardArrowDownOutlined as KeyboardArrowDownIcon,
  KeyboardArrowUpOutlined as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ElementType, forwardRef, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { addLast, removeAt } from 'timm';
import { RemoveIconButton } from '../buttons';
import { useFieldDisabled } from '../disabled';

const TableFooterCell = styled(TableCell, {
  name: 'TableFooter',
  slot: 'Cell',
})(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));
const TableFooterContainer = styled('div', {
  name: 'TableFooter',
  slot: 'Container',
})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

export interface FormTableColumn<Row, V extends unknown> {
  accessor: (row: Row) => V;
  Header: string;
  valueFormatter: (params: { row: Row; value: any }) => ReactNode;
}

export interface FormTableToggleEditingParams {
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  name: string;
  editing: boolean;
}

export interface FormTableEditRowFormParams {
  index: number;
  name: string;
}

export interface FormTableNewRowParams<Row extends any> {
  push: (row: Row) => void;
}

interface FormTableRowProps<Row extends any> {
  variant: 'collapse' | 'edit';
  disabled?: boolean;
  readOnly?: boolean;
  data: Row;
  index: number;
  name: string;
  columns: FormTableColumn<Row, unknown>[];
  isEditing: boolean;
  partialSubmit?: (params: { name: string }) => Promise<boolean>;
  toggleEditing?: (params: FormTableToggleEditingParams) => void;
  editForm: (params: FormTableEditRowFormParams) => ReactNode;
  onRemove: () => void;
}

const FormTableRow = <Row extends any>(props: FormTableRowProps<Row>) => {
  const {
    variant,
    disabled = false,
    readOnly = false,
    data,
    index,
    name,
    columns,
    isEditing: open,
    partialSubmit,
    toggleEditing,
    editForm,
    onRemove,
  } = props;
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const stopEditing = async () => {
    if (!partialSubmit || (await partialSubmit({ name })))
      toggleEditing?.({
        setFieldValue,
        name,
        editing: false,
      });
  };
  const startEditing = () => {
    toggleEditing?.({ setFieldValue, name, editing: true });
  };

  return (
    <>
      <TableRow>
        {!(disabled || readOnly) && variant !== 'edit' && (
          <TableCell>
            <IconButton
              disabled={disabled || readOnly}
              aria-label={
                open ? t('button.collapseRow') : t('button.expandRow')
              }
              size="small"
              onClick={open ? stopEditing : startEditing}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((col, index) => (
          <TableCell key={index}>
            {col.valueFormatter({ row: data, value: col.accessor(data) })}
          </TableCell>
        ))}
        {!(disabled || readOnly) && (
          <TableCell>
            <Box display="flex" flexDirection="row" alignItems="center">
              {variant === 'edit' && (
                <IconButton
                  disabled={disabled || readOnly}
                  aria-label={open ? t('button.done') : t('button.edit')}
                  size="small"
                  onClick={open ? stopEditing : startEditing}
                >
                  {open ? <DoneIcon /> : <EditIcon />}
                </IconButton>
              )}
              <RemoveIconButton
                disabled={disabled || readOnly}
                size="small"
                onClick={() => onRemove()}
              />
            </Box>
          </TableCell>
        )}
      </TableRow>
      {!(disabled || readOnly) && (
        <TableRow>
          <TableCell
            style={{ padding: 0, borderBottom: open ? '' : 'none' }}
            colSpan={columns.length + (variant === 'edit' ? 1 : 2)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>{editForm({ name, index })}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export type FormTableFieldProps<Row extends any> = FieldHookConfig<Row[]> & {
  variant?: 'collapse' | 'edit';
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  labelComponent?: ElementType;
  getId: (row: Row) => string;
  columns: FormTableColumn<Row, unknown>[];
  isEditing?: (row: Row) => boolean;
  partialSubmit?: (params: { name: string }) => Promise<boolean>;
  toggleEditing?: (params: FormTableToggleEditingParams) => void;
  editForm: (params: FormTableEditRowFormParams) => ReactNode;
  newRow?: (params: FormTableNewRowParams<Row>) => void;
};

export const FormTableField = forwardRef(function FormTableField<
  Row extends any
>(props: FormTableFieldProps<Row>, ref: any) {
  const {
    variant = 'collapse',
    label,
    labelComponent = 'h3',
    getId,
    columns,
    isEditing,
    partialSubmit,
    toggleEditing,
    editForm,
    newRow,
    readOnly,
  } = props;
  const disabled = useFieldDisabled(props.disabled);
  const [field, meta, { setValue }] = useField<Row[]>({
    ...props,
  });
  const { t } = useTranslation();
  const push = (row: Row) => setValue(addLast(meta.value, row));
  const removeRowAt = (rowIndex: number) =>
    setValue(removeAt(meta.value, rowIndex));

  return (
    <>
      {label && (
        <Typography component={labelComponent} variant="h5" gutterBottom>
          {label}
        </Typography>
      )}
      <TableContainer ref={ref} component={Paper} variant="outlined">
        <Table aria-label={label}>
          <TableHead>
            <TableRow>
              {!(disabled || readOnly) && variant !== 'edit' && <TableCell />}
              {columns.map((col, index) => (
                <TableCell key={index}>{col.Header}</TableCell>
              ))}
              {!(disabled || readOnly) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {meta.value.map((row, index) => (
              <FormTableRow
                variant={variant}
                key={getId(row)}
                disabled={disabled}
                readOnly={readOnly}
                data={row}
                index={index}
                name={`${field.name}[${index}]`}
                columns={columns}
                isEditing={isEditing?.(row) ?? false}
                partialSubmit={partialSubmit}
                toggleEditing={toggleEditing}
                editForm={editForm}
                onRemove={() => removeRowAt(index)}
              />
            ))}
          </TableBody>
          {!(disabled || readOnly) && newRow && (
            <TableFooter>
              <TableRow>
                <TableFooterCell
                  colSpan={
                    columns.length +
                    (disabled || readOnly ? 0 : variant === 'edit' ? 1 : 2)
                  }
                >
                  <TableFooterContainer>
                    <IconButton
                      aria-label={t('button.addRow')}
                      onClick={() => newRow?.({ push })}
                    >
                      <AddIcon />
                    </IconButton>
                  </TableFooterContainer>
                </TableFooterCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}) as <Row extends any>(props: FormTableFieldProps<Row>) => ReactElement;
