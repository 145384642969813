import { useCallback, useMemo } from 'react';
import { useNumberFormatter } from '.';
import { Money } from '../api';
import { useJsonMemo } from '../utils/useJsonMemo';
import { useLocale } from './locale';

export interface CurrencyFormatterOptions {
  currencyDisplay: Intl.NumberFormatOptions['currencyDisplay'];
  currencySign: Intl.NumberFormatOptions['currencySign'];
  notation: Intl.NumberFormatOptions['notation'];
  signDisplay: Intl.NumberFormatOptions['signDisplay'];
  useGrouping: Intl.NumberFormatOptions['useGrouping'];
  minimumIntegerDigits: Intl.NumberFormatOptions['minimumIntegerDigits'];
  minimumFractionDigits: Intl.NumberFormatOptions['minimumFractionDigits'];
  maximumFractionDigits: Intl.NumberFormatOptions['maximumFractionDigits'];
  minimumSignificantDigits: Intl.NumberFormatOptions['minimumSignificantDigits'];
  maximumSignificantDigits: Intl.NumberFormatOptions['maximumSignificantDigits'];
}

/**
 * Get a function that will format a currency for the current locale
 */
export function useCurrencyFormatters(options?: CurrencyFormatterOptions) {
  const locale = useLocale();
  options = useJsonMemo(options);

  const {
    notation,
    signDisplay,
    useGrouping,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
  } = options ?? {};
  const numberFormatter = useNumberFormatter({
    notation,
    signDisplay,
    useGrouping,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
  });
  const getCurrencyFormatter = useMemo(() => {
    const formatters: Record<string, Intl.NumberFormat> = Object.create(null);

    return (currency: string): Intl.NumberFormat => {
      if (!(currency in formatters))
        formatters[currency] = new Intl.NumberFormat(locale, {
          ...options,
          style: 'currency',
          currency,
        });
      return formatters[currency];
    };
  }, [locale, options]);

  return useCallback(
    (currency: string | undefined, value: number) =>
      currency
        ? getCurrencyFormatter(currency).format(value)
        : numberFormatter(value),
    [getCurrencyFormatter, numberFormatter]
  );
}

/**
 * Get a function that will format a Money object for the current locale
 */
export function useMoneyFormatter(options?: CurrencyFormatterOptions) {
  const currencyFormatter = useCurrencyFormatters(options);

  return useCallback(
    (money: Money | undefined | null): string | void => {
      return (
        (money && currencyFormatter(money.Currency ?? '', money.Value ?? 0)) ??
        undefined
      );
    },
    [currencyFormatter]
  );
}
