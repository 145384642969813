import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import { CloseIconButton } from '../form';
import { FileChip } from '../preview';
import useDialogId from '../utils/useDialogId';
import { FileView } from './FileView';
import { isPDF } from '../file-types';

const ViewerDialogTitle = styled(DialogTitle, {
  name: 'ViewerDialog',
  slot: 'Title',
})({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
(ViewerDialogTitle as any).muiName = 'DialogTitle';

export type ViewerDialogProps = DialogProps & {
  name?: string;
  fileName: string;
  fileType: string;
  fileSize: string;
  src: string;
  fullHeight?: boolean;
};

export const ViewerDialog = (props: ViewerDialogProps) => {
  const {
    name,
    id: idProp,
    fileName,
    fileType,
    fileSize,
    src,
    ...other
  } = props;
  const { onClose } = other;
  const { id, titleId } = useDialogId(name, idProp);

  // Always use full height for some types of viewers
  const fullHeight = isPDF(fileType);

  return (
    <Dialog
      {...other}
      id={id}
      aria-labelledby={titleId}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: fullHeight ? { height: '100vh' } : undefined,
      }}
    >
      <ViewerDialogTitle id={titleId}>
        <Typography component="span" variant="h6">
          {fileName}{' '}
          <FileChip
            variant="outlined"
            size="small"
            fileType={fileType}
            fileSize={fileSize ? parseFloat(fileSize) : undefined}
          />
        </Typography>
        <Box flex={1} />
        <Box m={-2}>
          <CloseIconButton
            color="default"
            onClick={() => onClose?.({} as any, 'escapeKeyDown')}
          />
        </Box>
      </ViewerDialogTitle>

      <DialogContent dividers>
        <FileView fileName={fileName} fileType={fileType} src={src} />
      </DialogContent>
    </Dialog>
  );
};
