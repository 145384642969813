const self = window;

export type IntlPolyfillTypes =
  | 'DateTimeFormat'
  | 'DisplayNames'
  | 'ListFormat'
  | 'Locale'
  | 'NumberFormat'
  | 'PluralRules'
  | 'RelativeTimeFormat'
  | 'getCanonicalLocales';

/**
 * From the detect.js files in https://github.com/Financial-Times/polyfill-library/tree/master/polyfills/Intl
 * @internal
 */
export const detect: Record<IntlPolyfillTypes, boolean> = {
  DateTimeFormat:
    'Intl' in self &&
    'DateTimeFormat' in self.Intl &&
    'formatToParts' in self.Intl.DateTimeFormat.prototype &&
    new self.Intl.DateTimeFormat('en', {
      hourCycle: 'h11',
      hour: 'numeric',
    } as any).formatToParts(0)[2].type === 'dayPeriod',
  DisplayNames: 'Intl' in self && 'DisplayNames' in self.Intl,
  ListFormat: 'Intl' in self && 'ListFormat' in self.Intl,
  Locale: 'Intl' in self && 'Locale' in self.Intl,
  NumberFormat:
    'Intl' in self &&
    'NumberFormat' in self.Intl &&
    (function () {
      try {
        new Intl.NumberFormat(undefined, {
          style: 'unit',
          unit: 'byte',
        });
      } catch (e) {
        return false;
      }
      return true;
    })(),
  PluralRules: 'Intl' in self && 'PluralRules' in self.Intl,
  RelativeTimeFormat: 'Intl' in self && 'RelativeTimeFormat' in self.Intl,
  getCanonicalLocales: 'Intl' in self && 'getCanonicalLocales' in self.Intl,
};
