import { styled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

/**
 * Element that is only visible to screen-readers
 *
 * Typically used to hide headings that aren't needed for sighted users
 */
const SrOnly = styled('span', { name: 'SrOnly' })({ ...visuallyHidden });

export default SrOnly;
