import { Divider, MenuItem } from '@mui/material';
import { groupBy } from 'lodash-es';
import { forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Code, useCodes } from '../../../api';
import { useCurrencyName } from '../../../intl';
import { SelectField, SelectFieldProps } from '../SelectField';

export type CurrencyFieldProps = Omit<SelectFieldProps, 'options'> & {
  currencyVariant?: 'default' | 'payment' | 'limit' | 'account';
};

const PrimaryCurrencies = new Set<string>(['EUR', 'GBP']);

/**
 * A <Select> field for selecting a currency
 */
export const CurrencyField = forwardRef(function CurrencyField(
  props: CurrencyFieldProps,
  ref: any
) {
  const { currencyVariant, ...other } = props;
  const { t } = useTranslation();
  const [currencies, loadingCurrencies] = useCodes('currencycodes');

  const currencyName = useCurrencyName();
  const mapCurrency = (code: Code) => (
    <MenuItem key={code.Id} value={code.Id}>
      {t('option.namedCurrency', {
        name: currencyName(code.Id ?? '') || code.Name,
        code: code.Id,
      })}
    </MenuItem>
  );

  const options: ReactNode[] = [];
  const { primary, secondary } = groupBy(currencies ?? [], (code) =>
    PrimaryCurrencies.has(code.Id ?? '') ? 'primary' : 'secondary'
  );
  if (primary?.length > 0) {
    options.push(...primary.map(mapCurrency));
    options.push(<Divider key="divider" />);
  }

  if (secondary) options.push(...secondary.map(mapCurrency));

  return (
    <SelectField
      ref={ref}
      {...other}
      options={options}
      loading={loadingCurrencies}
    />
  );
});
