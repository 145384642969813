import { styled, TableFooter } from '@mui/material';
import { DataTablePagination, DataTablePaginationProps } from './pagination';

const DataTableFooterRoot = styled(TableFooter, {
  name: 'DataTableFooter',
  slot: 'Root',
})({
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export interface DataTableFooterProps {
  pagination?: DataTablePaginationProps;
}

/**
 * DataTable footer with pagination and bookmarking
 */
export const DataTableFooter = (props: DataTableFooterProps) => {
  const { pagination } = props;

  return (
    <DataTableFooterRoot as="div" role={undefined}>
      {pagination && <DataTablePagination {...pagination} />}
    </DataTableFooterRoot>
  );
};
