import { DateTime } from 'luxon';
import { dateFilterMap } from '../comparator-mappers';
import { ArrCont, Contains, EQ, GT, GTE, LT, LTE } from '../comparators';
import { parseDateTime, parseString } from '../qs-parser';
import { QueryStringFilterValidator } from '../qs-validator';
import { FilterReturnArg, makeFilter } from '../utils';

export interface GetAccountsFilters {
  searchText?: string;
  name?: EQ & Contains;
  descriptor?: EQ & Contains;
  currency?: EQ;
  balance?: LT & GT & LTE & GTE;
  creationDate?: LT<DateTime | null> &
    GT<DateTime | null> &
    LTE<DateTime | null> &
    GTE<DateTime | null>;
  createdBy?: EQ<string | null>;
  lastActivity?: LT<DateTime | null> &
    GT<DateTime | null> &
    LTE<DateTime | null> &
    GTE<DateTime | null>;
  terminationDate?: LT<DateTime | null> &
    GT<DateTime | null> &
    LTE<DateTime | null> &
    GTE<DateTime | null>;
  status?: EQ;
  iban?: EQ;
  useDynamicDescriptor?: EQ;
  isThirdParty?: EQ;
  utilized?: EQ<string>;
  beneficiaries?: ArrCont<string>;
}

/**
 * Cast query string arguments to GetAccountsFilters
 * @note Generated by filtergen
 */
export function castGetAccountsFilters(
  filters: qs.ParsedQs
): GetAccountsFilters {
  const f = new QueryStringFilterValidator(filters);

  return {
    searchText: f.searchText('searchText'),

    name: {
      eq: f.eq('name', parseString),
      contains: f.contains('name', parseString),
    },

    descriptor: {
      eq: f.eq('descriptor', parseString),
      contains: f.contains('descriptor', parseString),
    },

    currency: {
      eq: f.eq('currency', parseString),
    },

    balance: {
      lt: f.lt('balance', parseString),
      gt: f.gt('balance', parseString),
      lte: f.lte('balance', parseString),
      gte: f.gte('balance', parseString),
    },

    creationDate: {
      lt: f.lt('creationDate', parseDateTime),
      gt: f.gt('creationDate', parseDateTime),
      lte: f.lte('creationDate', parseDateTime),
      gte: f.gte('creationDate', parseDateTime),
    },

    createdBy: {
      eq: f.eq('createdBy', parseString),
    },

    lastActivity: {
      lt: f.lt('lastActivity', parseDateTime),
      gt: f.gt('lastActivity', parseDateTime),
      lte: f.lte('lastActivity', parseDateTime),
      gte: f.gte('lastActivity', parseDateTime),
    },

    terminationDate: {
      lt: f.lt('terminationDate', parseDateTime),
      gt: f.gt('terminationDate', parseDateTime),
      lte: f.lte('terminationDate', parseDateTime),
      gte: f.gte('terminationDate', parseDateTime),
    },

    status: {
      eq: f.eq('status', parseString),
    },

    iban: {
      eq: f.eq('iban', parseString),
    },

    useDynamicDescriptor: {
      eq: f.eq('useDynamicDescriptor', parseString),
    },

    isThirdParty: {
      eq: f.eq('isThirdParty', parseString),
    },

    utilized: {
      eq: f.eq('utilized', parseString),
    },

    beneficiaries: {
      arrcont: f.arrcont('beneficiaries', parseString),
    },
  };
}

/**
 * Make args for the GetAllClientAccountSummaries query params
 * @see GET /clients/accounts/{orgId}/summaries
 * @note Excludes the `orgId` params
 * @note Generated by filtergen
 */
export function makeGetAllClientAccountSummariesFilterArgs(
  filters: GetAccountsFilters
): [
  searchText: string | undefined,
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note Name
     */ makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}

/**
 * Make args for the ExportAllClientAccounts query params
 * @see GET /clients/accounts/{orgId}/export
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeExportAllClientAccountsFilterArgs(
  filters: GetAccountsFilters
): [
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}

/**
 * Make args for the GetAllClientAccounts query params
 * @see GET /clients/accounts/{orgId}
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientAccountsFilterArgs(
  filters: GetAccountsFilters
): [
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}

/**
 * Make args for the GetAllClientsAccountSummaries query params
 * @see GET /clients/accounts/summaries
 * @note Generated by filtergen
 */
export function makeGetAllClientsAccountSummariesFilterArgs(
  filters: GetAccountsFilters
): [
  searchText: string | undefined,
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note Name
     */ makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}

/**
 * Make args for the ExportAllClientsAccounts query params
 * @see GET /clients/accounts/export
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeExportAllClientsAccountsFilterArgs(
  filters: GetAccountsFilters
): [
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}

/**
 * Make args for the GetAllClientsAccounts query params
 * @see GET /clients/accounts
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientsAccountsFilterArgs(
  filters: GetAccountsFilters
): [
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}

/**
 * Make args for the GetAllAccountSummaries query params
 * @see GET /accounts/summaries
 * @note Generated by filtergen
 */
export function makeGetAllAccountSummariesFilterArgs(
  filters: GetAccountsFilters
): [
  searchText: string | undefined,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the ExportAccounts query params
 * @see GET /accounts/export
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeExportAccountsFilterArgs(
  filters: GetAccountsFilters
): [
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}

/**
 * Make args for the GetAccounts query params
 * @see GET /accounts
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetAccountsFilterArgs(
  filters: GetAccountsFilters
): [
  name: FilterReturnArg,
  descriptor: FilterReturnArg,
  currency: FilterReturnArg,
  balance: FilterReturnArg,
  creationDate: FilterReturnArg,
  createdBy: FilterReturnArg,
  lastActivity: FilterReturnArg,
  terminationDate: FilterReturnArg,
  status: FilterReturnArg,
  iban: FilterReturnArg,
  useDynamicDescriptor: FilterReturnArg,
  isThirdParty: FilterReturnArg,
  utilized: FilterReturnArg,
  beneficiaries: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Descriptor
     */ makeFilter(filters.descriptor),
    /**
     * @note Balance.Currency
     */ makeFilter(filters.currency),
    /**
     * @note Balance.Value
     */ makeFilter(filters.balance),
    /**
     * @note CreationDate
     */ makeFilter(filters.creationDate, dateFilterMap),
    /**
     * @note CreatedBy
     */ makeFilter(filters.createdBy),
    /**
     * @note LastActivity
     */ makeFilter(filters.lastActivity, dateFilterMap),
    /**
     * @note TerminationDate
     */ makeFilter(filters.terminationDate, dateFilterMap),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note Iban
     */ makeFilter(filters.iban),
    /**
     * @note UseDynamicDescriptor
     */ makeFilter(filters.useDynamicDescriptor),
    /**
     * @note IsThirdParty
     */ makeFilter(filters.isThirdParty),
    /**
     * @note utilized
     */ makeFilter(filters.utilized),
    /**
     * @note Beneficiaries
     */ makeFilter(filters.beneficiaries),
  ];
}
