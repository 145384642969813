import { forwardRef } from 'react';
import { useLabeledCodeOptionsProps } from '../..';
import { useCodes } from '../../../api';
import { useCountryNames } from '../../../intl';
import { TagArrayField, TagArrayFieldProps } from '../TagArrayField';

export type CountryArrayFieldProps = Omit<
  TagArrayFieldProps<string, false>,
  'options'
>;

/**
 * A tag array combobox field for selecting a list of countries
 */
export const CountryArrayField = forwardRef(function CountryArrayField(
  props: CountryArrayFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [countries, loadingCountries] = useCodes('countrycodes');
  const countryName = useCountryNames();
  const optionProps = useLabeledCodeOptionsProps(countries, countryName);

  return (
    <TagArrayField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingCountries}
    />
  );
});
