import { Alert, Typography } from '@mui/material';
import invariant from 'invariant';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isResourceNotFoundError } from '../../api';
import { useError } from '../../utils/ErrorBoundary';
import { Page } from '../Page';
import { getResourceForApi } from '../../api/errors';

/**
 * Whole page error state
 */
export const ResourceNotFoundErrorPage = () => {
  const { t } = useTranslation();
  const [error] = useError();

  invariant(
    isResourceNotFoundError(error),
    'Error is not a resource not found error'
  );
  const resourcePath = useMemo(() => getResourceForApi(error), [error]);

  return (
    <Page title={t('title.resourceNotFound')}>
      <Alert severity="error">
        <Typography variant="inherit" component="p" paragraph>
          {t('message.resourceNotFoundErrorMessage')}
        </Typography>
        <Typography variant="caption" component="p" color="textSecondary">
          <Trans
            i18nKey="message.resourceNotFoundErrorInfo"
            values={{ resourcePath }}
            components={{
              var: (
                <Typography
                  variant="inherit"
                  component="var"
                  sx={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'double',
                    textUnderlineOffset: 4,
                    userSelect: 'all',
                  }}
                />
              ),
            }}
          />
        </Typography>
      </Alert>
    </Page>
  );
};
