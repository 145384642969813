import { TextField } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type IbanFieldProps = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  CommonValidatorProps & {
    readOnly?: boolean;
    /**
     * Also accepts UK sort code + account number format such as "GB:40-40-40 12345678"
     */
    allowSortCodeHack?: boolean;
  };

/**
 * A <TextField> for an IBAN
 */
export const IbanField = forwardRef(function IbanField(
  props: IbanFieldProps,
  ref: Ref<HTMLInputElement>
) {
  props = usePropsWithId(props);
  const { allowSortCodeHack, ...other } = props;
  const validate = useCommonValidate(props, ({ iban }) => [
    iban({ allowSortCodeHack }),
  ]);

  const textFieldProps = useTextFieldProps({
    ...other,
    validate,
    type: 'text',
  });

  if (props.readOnly) return <ReadOnlyField ref={ref} {...textFieldProps} />;

  return <TextField ref={ref} {...textFieldProps} />;
});
