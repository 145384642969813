import { forwardRef } from 'react';
import { ContactEmailAddressType } from '../../../api';
import { useContactEmailAddressTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ContactEmailAddressTypeFieldProps = Omit<
  SelectFieldProps,
  'options'
>;

/**
 * A <Select> field for selecting a ContactEmailAddressType
 */
export const ContactEmailAddressTypeField = forwardRef(
  function ContactEmailAddressTypeField(
    props: ContactEmailAddressTypeFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const optionsProps = useEnumOptions(
      ContactEmailAddressType,
      useContactEmailAddressTypeLabels()
    );

    return <SelectField ref={ref} {...other} {...optionsProps} />;
  }
);
