import { SvgIconComponent } from '@mui/icons-material';
import {
  File as FileIcon,
  FileImage as FileImageIcon,
  FilePdfBox as FilePdfIcon,
} from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import { isGif, isImage, isJpeg, isPDF, isPng } from './mime';

/**
 * Icon and name for a file type
 */
export interface FileTypeMeta {
  /**
   * Name of the file type
   */
  name: string;
  /**
   * Icon component to use for the file type
   */
  Icon: SvgIconComponent;
}

/**
 * Get the file type meta (icon and name) for a mime type
 */
export function useFileTypeMeta(fileType: string | undefined): FileTypeMeta {
  return {
    name: useFileTypeName(fileType),
    Icon: fileTypeIcon(fileType),
  };
}

/**
 * Get icon component for a file type
 */
export function fileTypeIcon(fileType: string | undefined): SvgIconComponent {
  if (fileType) {
    if (isPDF(fileType)) return FilePdfIcon;
    if (isImage(fileType)) return FileImageIcon;
  }

  return FileIcon;
}

/**
 * Get localized name for a file type
 */
export function useFileTypeName(fileType: string | undefined): string {
  const { t } = useTranslation();

  if (fileType) {
    if (isPDF(fileType)) return t('fileType.pdf');
    if (isJpeg(fileType)) return t('fileType.jpeg');
    if (isPng(fileType)) return t('fileType.png');
    if (isGif(fileType)) return t('fileType.gif');
    if (isImage(fileType)) return t('fileType.image');
  }

  return t('fileType.unknown');
}
