import { Add } from '@mui/icons-material';
import {
  FilledInput,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  IconButton,
  InputAdornment,
  InputLabel,
  InputLabelProps,
  List,
  ListItem,
  ListItemText,
  ListProps,
  OutlinedInput,
  StandardProps,
  useForkRef,
} from '@mui/material';
import useAutocomplete, {
  AutocompleteGroupedOption,
  createFilterOptions,
  CreateFilterOptionsConfig,
} from '@mui/material/useAutocomplete';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { sortBy } from 'lodash-es';
import {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useJsonMemo } from '../../utils/useJsonMemo';

const useFieldStyles = makeStyles(
  (theme) => ({
    root: {},
    input: {},
    listbox: {
      maxHeight: '40vh',
      overflow: 'auto',
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.action.selected,
      },
      '&.Mui-focused': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:active': {
        backgroundColor: theme.palette.action.selected,
      },
      '&[aria-disabled="true"]': {
        opacity: theme.palette.action.disabledOpacity,
        pointerEvents: 'none',
      },
    },
  }),
  { name: 'ListControl' }
);

const useInputStyles = makeStyles(
  (theme) => ({
    root: {
      height: 'auto',
      padding: theme.spacing(1),
    },
  }),
  { name: 'ListInput' }
);

export type ListControlClassKey = 'root';

export interface ListControlProps<Option = unknown>
  extends StandardProps<
    FormControlProps,
    ListControlClassKey,
    // event handlers are declared on derived interfaces
    'onChange' | 'onBlur' | 'onFocus' | 'defaultValue'
  > {
  /**
   * Control if the input should be blurred when an option is selected:
   *
   * - `false` the input is not blurred.
   * - `true` the input is always blurred.
   * - `touch` the input is blurred after a touch event.
   * - `mouse` the input is blurred after a mouse event.
   */
  blurOnSelect?: 'touch' | 'mouse' | true | false;
  /**
   * If `true`, the input's text will be cleared on blur if no value is selected.
   *
   * Set to `true` if you want to help the user enter a new value.
   * Set to `false` if you want to help the user resume his search.
   */
  clearOnBlur?: boolean;
  /**
   * If `true`, clear all values when the user presses escape and the popup is closed.
   */
  clearOnEscape?: boolean;
  /**
   * If `true`, the component handles the "Home" and "End" keys when the popup is open.
   * It should move focus to the first option and last option, respectively.
   */
  handleHomeEndKeys?: boolean;
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color?: 'primary' | 'secondary';
  /**
   * Allow for the creation of new options.
   */
  createOption?: boolean;
  /**
   * If `true`, the `input` element will be disabled.
   */
  disabled?: boolean;
  /**
   * If `true`, the label will be displayed in an error state.
   */
  error?: boolean;
  /**
   * Options to use for the options filter.
   */
  filterOptions?: CreateFilterOptionsConfig<unknown>;
  /**
   * Props applied to the FormHelperText element.
   */
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  /**
   * If `true`, allows options not listed in the options to be added.
   */
  freeSolo?: boolean;
  /**
   * If `true`, the input will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * The helper text content.
   */
  helperText?: React.ReactNode;
  /**
   * Used to determine the string value for a given option.
   * It's used to fill the input (and the list box options if `renderOption` is not provided).
   *
   * @param {T} option
   * @returns {string}
   */
  getOptionLabel?: (option: Option | string) => string;
  /**
   * Used to determine if an option is selected, considering the current value.
   * Uses strict equality by default.
   *
   * @param {T} option The option to test.
   * @param {T} value The value to test against.
   * @returns {boolean}
   */
  isOptionEqualToValue?: (option: Option, value: Option) => boolean;
  /**
   * The id of the `input` element.
   * Use this prop to make `label` and `helperText` accessible for screen readers.
   */
  id?: string;
  /**
   * Props applied to the InputLabel.
   */
  InputLabelProps?: Partial<InputLabelProps>;
  /**
   * Input reference.
   */
  inputRef?: Ref<{ focus: () => void; value: unknown[] }>;
  /**
   * The label content.
   */
  label?: React.ReactNode;
  /**
   * Props applied to the options List.
   */
  ListboxProps?: Partial<ListProps>;
  /**
   * Name attribute of the `input` element.
   */
  name?: string;
  /**
   * Callback fired when the value is changed.
   */
  onChange?: any; //(event: React.ChangeEvent<{}>, value: Option[]) => void;
  /**
   * Options for when field is used as a picker.
   */
  options?: Option[];
  /**
   * If `true`, the label is displayed as required and the `input` element` will be required.
   */
  required?: boolean;
  /**
   * If `true`, the input's text will be selected on focus.
   * It helps the user clear the selected value.
   */
  selectOnFocus?: boolean;
  /**
   * The size of the text field.
   */
  size?: 'small' | 'medium';
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: Option[] | undefined;
}

const isAutocompleteGroupedOption = <Option extends unknown>(
  option: Option | AutocompleteGroupedOption<Option>
): option is AutocompleteGroupedOption<Option> =>
  typeof option === 'object' &&
  option !== null &&
  'options' in (option as object) &&
  'group' in (option as object);

const ListInput = forwardRef(function ListInput(props: any, ref: Ref<any>) {
  const classes = useInputStyles(props);
  const {
    children,
    className,
    inputRef: inputRefProp,
    id,
    disabled,
    freeSolo,
    ...other
  } = props;
  const { t } = useTranslation();
  const internalInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useForkRef(internalInputRef, inputRefProp);

  const onAdd = useCallback<React.MouseEventHandler>((e) => {
    e.preventDefault();
    internalInputRef.current?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Enter',
        bubbles: true,
        cancelable: true,
      })
    );
  }, []);

  return (
    <FormControl
      ref={ref}
      margin="none"
      fullWidth
      disabled={disabled}
      hiddenLabel
      className={clsx(classes.root, className)}
    >
      <FilledInput
        inputRef={inputRef}
        id={id}
        margin="dense"
        disableUnderline
        endAdornment={
          freeSolo && (
            <InputAdornment variant="filled" position="end">
              <IconButton
                edge="end"
                aria-label={t('button.add')}
                onClick={onAdd}
              >
                <Add />
              </IconButton>
            </InputAdornment>
          )
        }
        {...other}
      />
      {children}
    </FormControl>
  );
});

/**
 * Custom field that involves a MUI list with items that can be added/removed
 */
const ListControl = forwardRef(function ListControl<
  Option extends unknown = unknown
>(props: ListControlProps<Option>, ref: Ref<any>) {
  const classes = useFieldStyles(props);
  const {
    classes: classesProp,
    className,
    blurOnSelect,
    clearOnBlur,
    clearOnEscape,
    selectOnFocus,
    handleHomeEndKeys,
    color,
    createOption = false,
    disabled = false,
    error,
    filterOptions: filterOptionsProp,
    FormHelperTextProps,
    freeSolo = false,
    fullWidth,
    getOptionLabel = (option) => String(option),
    isOptionEqualToValue = (option, value) => option === value,
    helperText,
    InputLabelProps,
    inputRef,
    label,
    ListboxProps,
    name,
    onChange,
    options = [],
    required,
    value: valueProp,
    ...other
  } = props;
  const { t } = useTranslation();

  const filterOptionsConfig = useJsonMemo(filterOptionsProp); // @note Doesn't handle `stringify` properly
  const filter = useMemo(
    () => createFilterOptions<Option>(filterOptionsConfig),
    [filterOptionsConfig]
  );

  const {
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getListboxProps,
    getOptionProps,
    id,
    value,
    groupedOptions,
  } = useAutocomplete<Option, true, undefined, boolean>({
    ...props,
    filterOptions: (options, params) => {
      options = [...options];
      for (const val of valueProp ?? []) {
        // @todo Handle non string options
        if (!options.includes(val)) {
          options.push(val);
        }
      }
      let filtered = filter(options, params);

      filtered = sortBy(filtered, [
        (val) => (valueProp?.includes(val) ? 0 : 1),
      ]);

      if (createOption && params.inputValue !== '') {
        // @todo non-string options
        filtered.unshift(params.inputValue as any);
      }

      return filtered;
    },
    freeSolo: freeSolo || createOption,
    getOptionLabel,
    isOptionEqualToValue,
    open: true,
    multiple: true,
    options,
    componentName: 'ListControl',
  });

  // For now we dont support groups so flatten the options
  const flatOptions: Option[] = useMemo(() => {
    return groupedOptions.flatMap(
      // @fixme Remove type declaration after upgrading CRA and TypeScript
      (option: Option | AutocompleteGroupedOption<Option>) => {
        return isAutocompleteGroupedOption(option) ? option.options : option;
      }
    );
  }, [groupedOptions]);

  const domInputRef = useRef<any | undefined>(null);
  useImperativeHandle(
    inputRef,
    () => ({
      focus: () => domInputRef.current?.focus(),
      value,
    }),
    [value]
  );

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  const unusedProps: any = {
    // Null out some of TextField's unused props
    select: undefined,
  };

  return (
    <FormControl
      ref={ref}
      // component="fieldset"
      className={clsx(classes.root, className)}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      required={required}
      color={color}
      {...other}
      {...getRootProps()}
      variant="outlined"
      {...unusedProps}
    >
      <InputLabel
        shrink
        variant="outlined"
        {...getInputLabelProps()}
        {...InputLabelProps}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        notched
        inputComponent={ListInput}
        className={classes.input}
        disabled={disabled}
        inputRef={domInputRef}
        inputProps={{
          inputProps: {
            ...getInputProps(),
          },
          freeSolo,
          children:
            flatOptions.length > 0 ? (
              <List
                className={classes.listbox}
                dense
                {...getListboxProps()}
                {...ListboxProps}
              >
                {flatOptions.map((option, index) => (
                  <ListItem
                    className={classes.option}
                    {...getOptionProps({ option, index })}
                  >
                    {createOption && !value.includes(option) ? (
                      <ListItemText
                        primary={t('addOptionOption', {
                          label: getOptionLabel(option),
                        })}
                      />
                    ) : (
                      <ListItemText primary={getOptionLabel(option)} />
                    )}
                  </ListItem>
                ))}
              </List>
            ) : null,
        }}
      />
      <FormHelperText id={helperTextId} {...FormHelperTextProps}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}) as <Option extends unknown = unknown>(
  props: ListControlProps<Option>,
  ref: Ref<any>
) => ReactElement;

export default ListControl;
