import { useErrors } from '@johnrom/formik-v3';
import { Alert, Box, Snackbar, Typography, useTheme } from '@mui/material';
import { StyledComponentProps } from '@mui/styles';

/**
 * Symbol representing an error that affects the entire form
 */
export const FORM_ERROR = Symbol('FORM_ERROR');

export type FormErrorClassKey = 'root';

export type FormErrorProps = Pick<
  StyledComponentProps<FormErrorClassKey>,
  'classes'
> & {
  className?: string;
  style?: React.CSSProperties;
  variant?: 'paragraph' | 'alert' | 'snackbar';
};

/**
 * Displays a form wide error not related to a specific input
 */
export const FormError = (props: FormErrorProps) => {
  const theme = useTheme();
  const { variant = 'paragraph', className, style } = props;
  const errors = useErrors();
  // @ts-ignore
  const error = errors[FORM_ERROR as any] as string | undefined;

  if (variant === 'snackbar') {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: theme.direction === 'rtl' ? 'right' : 'left',
        }}
        open={!!error}
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    );
  }

  if (variant === 'alert' && error) {
    return (
      <Box py={2}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        className={className}
        style={style}
        role="alert"
        paragraph
        color="error"
      >
        {error}
      </Typography>
    );
  }

  return null;
};
