import { styled } from '@mui/material';
import { ReactNode } from 'react';

export const DefinitionList = styled('dl')(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    '& > dt': {
      ...theme.typography.body1,
      '&:after': {
        display: 'inline',
        content: '":"',
      },
    },
    '& > dd': {
      ...theme.typography.body1,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2),
    },
  }),
  { name: 'DefinitionList' }
);

export interface DefinitionProps {
  label?: string;
  value?: ReactNode;
}

/**
 * Definition term and definition combination
 */
export const Definition = ({ label, value }: DefinitionProps) => {
  return (
    <>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </>
  );
};
