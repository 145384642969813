import { styled } from '@mui/material';

/**
 * Right aligned button area for <FieldSet>
 */
export const FieldSetButtons = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  { name: 'FieldSetButtons' }
);
