import { DateTime } from 'luxon';
import { dateFilterMap } from '../comparator-mappers';
import { EQ, Contains, IN, GT, GTE, LT, LTE } from '../comparators';
import { parseDateTime, parseString } from '../qs-parser';
import { QueryStringFilterValidator } from '../qs-validator';
import { FilterReturnArg, makeFilter } from '../utils';

export interface GetContactsFilters {
  searchText?: string;
  loginEmail?: EQ & Contains;
  familyName?: EQ & Contains;
  firstName?: EQ & Contains;
  dob?: EQ<DateTime | null>;
  nationality?: EQ<string | null>;
  ownershipPercentage?: LT & GT & LTE & GTE;
  authorities?: EQ & IN;
  roles?: EQ & IN;
  pep?: EQ;
  status?: EQ;
}

/**
 * Cast query string arguments to GetContactsFilters
 * @note Generated by filtergen
 */
export function castGetContactsFilters(
  filters: qs.ParsedQs
): GetContactsFilters {
  const f = new QueryStringFilterValidator(filters);

  return {
    searchText: f.searchText('searchText'),

    loginEmail: {
      eq: f.eq('loginEmail', parseString),
      contains: f.contains('loginEmail', parseString),
    },

    familyName: {
      eq: f.eq('familyName', parseString),
      contains: f.contains('familyName', parseString),
    },

    firstName: {
      eq: f.eq('firstName', parseString),
      contains: f.contains('firstName', parseString),
    },

    dob: {
      eq: f.eq('dob', parseDateTime),
    },

    nationality: {
      eq: f.eq('nationality', parseString),
    },

    ownershipPercentage: {
      lt: f.lt('ownershipPercentage', parseString),
      gt: f.gt('ownershipPercentage', parseString),
      lte: f.lte('ownershipPercentage', parseString),
      gte: f.gte('ownershipPercentage', parseString),
    },

    authorities: {
      eq: f.eq('authorities', parseString),
      in: f.in('authorities', parseString),
    },

    roles: {
      eq: f.eq('roles', parseString),
      in: f.in('roles', parseString),
    },

    pep: {
      eq: f.eq('pep', parseString),
    },

    status: {
      eq: f.eq('status', parseString),
    },
  };
}

/**
 * Make args for the GetContactSummaries query params
 * @see GET /contacts/summaries
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetContactSummariesFilterArgs(
  filters: GetContactsFilters
): [
  searchText: string | undefined,
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note LoginEmail
     */ makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the ExportContacts query params
 * @see GET /contacts/export
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeExportContactsFilterArgs(
  filters: GetContactsFilters
): [
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note LoginEmail
     */
    makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the GetContacts query params
 * @see GET /contacts
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetContactsFilterArgs(
  filters: GetContactsFilters
): [
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note LoginEmail
     */
    makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the GetAllClientContactSummaries query params
 * @see GET /clients/contacts/{orgId}/summaries
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientContactSummariesFilterArgs(
  filters: GetContactsFilters
): [
  searchText: string | undefined,
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note LoginEmail
     */ makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the ExportAllClientContacts query params
 * @see GET /clients/contacts/{orgId}/export
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeExportAllClientContactsFilterArgs(
  filters: GetContactsFilters
): [
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note LoginEmail
     */
    makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the GetAllClientContacts query params
 * @see GET /clients/contacts/{orgId}
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientContactsFilterArgs(
  filters: GetContactsFilters
): [
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note LoginEmail
     */
    makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the GetAllClientsContactSummaries query params
 * @see GET /clients/contacts/summaries
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientsContactSummariesFilterArgs(
  filters: GetContactsFilters
): [
  searchText: string | undefined,
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note LoginEmail
     */ makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the ExportAllClientsContacts query params
 * @see GET /clients/contacts/export
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeExportAllClientsContactsFilterArgs(
  filters: GetContactsFilters
): [
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note LoginEmail
     */
    makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}

/**
 * Make args for the GetAllClientsContacts query params
 * @see GET /clients/contacts
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientsContactsFilterArgs(
  filters: GetContactsFilters
): [
  loginEmail: FilterReturnArg,
  familyName: FilterReturnArg,
  firstName: FilterReturnArg,
  dob: FilterReturnArg,
  nationality: FilterReturnArg,
  ownershipPercentage: FilterReturnArg,
  authorities: FilterReturnArg,
  roles: FilterReturnArg,
  pep: FilterReturnArg,
  status: FilterReturnArg
] {
  return [
    /**
     * @note LoginEmail
     */
    makeFilter(filters.loginEmail),
    /**
     * @note FamilyName
     */ makeFilter(filters.familyName),
    /**
     * @note FirstName
     */ makeFilter(filters.firstName),
    /**
     * @note Dob
     */ makeFilter(filters.dob, dateFilterMap),
    /**
     * @note Nationality
     */ makeFilter(filters.nationality),
    /**
     * @note OwnershipPercentage
     */ makeFilter(filters.ownershipPercentage),
    /**
     * @note Authorities
     */ makeFilter(filters.authorities),
    /**
     * @note Roles
     */ makeFilter(filters.roles),
    /**
     * @note PEP
     */ makeFilter(filters.pep),
    /**
     * @note Status
     */ makeFilter(filters.status),
  ];
}
