import {
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  // eslint-disable-next-line no-restricted-imports
  Switch,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useSyncedRef } from '@react-hookz/web';
import { useMeasure } from '@react-hookz/web/esm';
import clsx from 'clsx';
import invariant from 'invariant';
import { DateTime } from 'luxon';
import {
  ChangeEvent,
  CSSProperties,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Cell,
  Column,
  ColumnGroup,
  ColumnWithLooseAccessor,
  ColumnWithStrictAccessor,
  HeaderGroup,
  Row,
  SortByFn,
  SortingRule,
  TableOptions,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useResizeColumns,
  useRowSelect,
  UseRowSelectInstanceProps,
  useSortBy,
  useTable,
} from 'react-table';
import { useVirtual, VirtualItem } from 'react-virtual';
import warning from 'warning';
import { isBusinessRulesFailedError, Money } from '../api';
import { getCommonErrorMessage } from '../api/unexpected-error';
import { ColumnsButtonProps } from '../table-page/ui/ColumnsButton';
import download from '../utils/download';
import { featFlag } from '../utils/featFlags';
import useHtmlId from '../utils/useHtmlId';
import { tableToCsv } from './csv-table';
import { DataTableQuickFilterProps } from './DataTableQuickFilter';
import { DefaultColumnFilter } from './filter/DefaultColumnFilter';
import { DataTablePaginationProps } from './pagination';
import { DataTableQuickSearchProps } from './quickSearch';
import {
  DataTable,
  DataTableCell,
  DataTableEmptyCell,
  DataTableFilterRow,
  DataTableHeaderContainer,
  DataTableHeaderResizer,
  DataTableProgress,
  DataTableTableHead,
} from './ui/DataTable';
import { SortIndicator } from './ui/SortIndicator';
import { sortRowByValue } from './utils/sortRowBy';
import { useSmoothScrollForVirtual } from './utils/useSmoothScrollForVirtual';

/**
 * Standard API maxItemCount for data table pages
 * @note The shortTableLimits feature flags will reduce this to 100
 */
export const dataTableMaxItemCount = featFlag('shortTableLimits') ? 100 : 1000;

const DISABLE_RESIZE_COLUMNS = featFlag('disableResizeTableColumns');

export const TableRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  alignSelf: 'stretch',

  '&.layout-stretch': {
    position: 'relative',
    flex: '1 1 0',
    overflow: 'auto',
    minHeight: 200,
  },
});

export type DataTableColumnInterface<D extends object> = Column<D> & {
  exportFormatter?: (value: any) => string;
};
export type DataTableColumnWithLooseAccessor<D extends object> =
  ColumnWithLooseAccessor<D> & DataTableColumnInterface<D>;
export type DataTableColumnWithStrictAccessor<D extends object> =
  ColumnWithStrictAccessor<D> & DataTableColumnInterface<D>;

export type DataTableColumn<D extends object = {}> =
  | ColumnGroup<D>
  | DataTableColumnWithLooseAccessor<D>
  | DataTableColumnWithStrictAccessor<D>;

export interface UseDataTableProps<D extends object> {
  layout?: 'stretch' | 'fit-content';
  loading?: boolean;
  columns: DataTableColumn<D>[];
  data?: TableOptions<D>['data'];
  hasNextPage?: boolean | undefined;
  isFetchingNextPage?: boolean | undefined;
  fetchNextPage?: () => Promise<unknown>;
  error?: unknown;
  customError?: string;
  getRowId: TableOptions<D>['getRowId'];
  onRowDoubleClick?: (row: Row<D>) => void;
  /**
   * Are filters enabled
   */
  filters?: boolean;
  /**
   * Is sorting enabled
   */
  sortable?: boolean;
  /**
   * Use server-side sorting
   */
  manualSortBy?: boolean;
  /**
   * Sort option to use for server-side sorting
   */
  sortBy?: SortingRule<object>;
  /**
   * Callback to update the sortBy state
   */
  setSortBy?: (sortBy: SortingRule<object>) => void;
  /**
   * Has state drivven hidden columns
   */
  manualHiddenColumns?: boolean;
  /**
   * Columns that should be hidden (true), or explicitly shown (false)
   */
  hiddenColumns?: Record<string, boolean>;
  /**
   * Reset hidden columns to default
   */
  resetHiddenColumns?: () => void;
  /**
   * Callback to toggle a column's hidden state
   */
  toggleColumnHidden?: (id: string, value: boolean) => void;
  /**
   * Is selection enabled
   */
  selection?: boolean;
  /**
   * Use Quick Search input instead of Quick Filter
   */
  useQuickSearch?: boolean;
}

export interface DataTableSelected<D extends object> {
  /**
   * Currently selected items
   */
  selected: D[];
  /**
   * Props for the PageHeader's selection prop
   */
  selectionHeaderProps: any;
  /**
   * Props for the clear button
   */
  clearSelectionProps: {
    onClick: () => void;
  };
  /**
   * Conditional helper that will only return the arg it's passed when a single item is selected
   */
  single: <N extends unknown>(render: (item: D) => N) => N | undefined;
}

export interface UseDataTableResult<D extends object> {
  table: ReactElement;
  columnsProps?: ColumnsButtonProps;
  /**
   * Callback that will trigger a download for a CSV with the table's current contents
   */
  downloadAsCsv: (filename: string) => void;
  /**
   * Current quick filter text
   */
  quickFilter: string;
  /**
   * Quick filter UI props
   */
  quickFilterProps: DataTableQuickFilterProps | undefined;
  /**
   * Quick search UI props
   */
  quickSearchProps: DataTableQuickSearchProps | undefined;
  /**
   * Pagination properties
   */
  pagination: DataTablePaginationProps;
  /**
   * Selection properties
   */
  selection: DataTableSelected<D>;
}

const emptyArray: any[] = [];

const DefaultColumnExport = (cell: Cell<any>) => {
  invariant(false, `No export implemented for ${cell.column.id}`);
};

const ErrorRow = (props: { colSpan: number; error: unknown }) => {
  const { colSpan, error } = props;
  const { t } = useTranslation();
  const message: string =
    getCommonErrorMessage(t, error) ?? t('message.unexpectedError');

  return (
    <TableRow>
      <DataTableEmptyCell colSpan={colSpan}>{message}</DataTableEmptyCell>
    </TableRow>
  );
};

/**
 * useErrorBoundary for users of useDataTable
 */
export const dataTableUseErrorBoundary = (err: any) =>
  !isBusinessRulesFailedError(err);

function getCellStyle<D extends object>(
  column: Column<D> | HeaderGroup<D>
): CSSProperties | undefined {
  const maxWidth =
    column.maxWidth && column.maxWidth < Number.MAX_SAFE_INTEGER
      ? column.maxWidth
      : undefined;

  if (maxWidth) {
    return {
      maxWidth,
    };
  }

  return undefined;
}

interface DataTableRowItemProps<D extends object> {
  onRowDoubleClick: UseDataTableProps<D>['onRowDoubleClick'];
  row: Row<D>;
  toggleAllRowsSelected: UseRowSelectInstanceProps<D>['toggleAllRowsSelected'];
  toggleRowSelected: UseRowSelectInstanceProps<D>['toggleRowSelected'];
  virtualStart: number;
  virtualSize: number;
  highlighted: boolean;
  active: boolean;
}

const DataTableRowItem = <D extends object>(
  props: DataTableRowItemProps<D>
) => {
  const {
    onRowDoubleClick,
    row,
    toggleAllRowsSelected,
    toggleRowSelected,
    virtualStart,
    virtualSize,
    highlighted,
    active,
  } = props;

  return (
    <TableRow
      onClick={(e: MouseEvent<HTMLElement>) => {
        if (e.nativeEvent?.getModifierState('Shift')) {
          toggleRowSelected(row.id);
        } else {
          const closest = (e.target as any).closest(
            'tr, td, a, button, input'
          ) as HTMLElement | null;
          if (closest?.matches('tr, td') ?? true) {
            toggleAllRowsSelected(false);
            toggleRowSelected(row.id, true);
          }
        }
      }}
      onDoubleClick={() => {
        onRowDoubleClick?.(row);
      }}
      {...row.getRowProps({
        className: clsx({ 'DataTableRow-selected': row.isSelected }),
        style: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: virtualSize,
          transform: `translateY(${virtualStart}px)`,
        },
      })}
    >
      {row.cells.map((cell) => {
        return (
          <DataTableCell
            {...cell.getCellProps({
              className: clsx(
                `DataTableCell-${cell.column.id}`,
                `DataTableCell-${cell.column.noWrap ? 'nowrap' : 'wrap'}`,
                `DataTableCell-${cell.column.align}`,
                `DataTableCell-${cell.column.overflow}`,
                highlighted && `DataTableCell-highlighted`,
                active && `DataTableCell-active`
              ),
              style: getCellStyle(cell.column),
            })}
          >
            <div className="DataTableCell-content">{cell.render('Cell')}</div>
          </DataTableCell>
        );
      })}
    </TableRow>
  );
};

interface DataTableLastItemProps {
  colSpan: number;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean | undefined;
  fetchNextPage?: () => Promise<unknown>;
  virtualStart: number;
  virtualSize: number;
}

/**
 * Virtual item for things that come after the last table row like the next page loading bar
 */
const DataTableLastItem = (props: DataTableLastItemProps) => {
  const {
    colSpan,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    virtualStart,
    virtualSize,
  } = props;
  const { t } = useTranslation();

  let body: ReactNode;
  if (hasNextPage) {
    body = (
      <Button
        variant="outlined"
        disabled={isFetchingNextPage}
        onClick={() => fetchNextPage?.()}
      >
        {t('button.loadMore')}
      </Button>
    );
  }

  return (
    <TableRow
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: virtualSize,
        transform: `translateY(${virtualStart}px)`,
      }}
    >
      <TableCell
        sx={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: 'none',
        }}
        colSpan={colSpan}
      >
        {body}
      </TableCell>
    </TableRow>
  );
};

/**
 * useTable based DataTable
 */
export function useDataTable<D extends object>(
  props: UseDataTableProps<D>
): UseDataTableResult<D> {
  const {
    layout = 'stretch',
    loading = false,
    columns,
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    error,
    customError,
    getRowId,
    onRowDoubleClick,
    filters: enableFilters = true,
    sortable: enableSorting = true,
    selection: enableSelection = true,
    manualSortBy = false,
    sortBy,
    setSortBy,
    manualHiddenColumns = false,
    hiddenColumns,
    resetHiddenColumns,
    toggleColumnHidden,
    useQuickSearch = false,
  } = props;
  const id = useHtmlId('data-table');
  const { t } = useTranslation();
  const sortTypes = useMemo<Record<string, SortByFn<D>>>(() => {
    return {
      // @fixme Find some way to sort currency amounts roughly by their actual monetary value rather than saying that C$5 > US$4.99
      currencyAmount: sortRowByValue<Money | undefined>((v) => v?.Value),
      DateTime: sortRowByValue<string | undefined>((v) =>
        v ? DateTime.fromISO(v).toMillis() : 0
      ),
    };
  }, []);
  const defaultColumn = useMemo(
    (): Partial<Column<D>> => ({
      Filter: DefaultColumnFilter,
      Export: DefaultColumnExport,
      disableSortBy: true,
      noWrap: false,
      align: 'start',
      overflow: 'hidden',
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    flatHeaders,
    allColumns,
    visibleColumns,
    preGlobalFilteredRows: rawRows,
    rows: clientFilteredRows,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    state: { globalFilter },
    disableGlobalFilter,
  } = useTable(
    {
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            sortBy: manualSortBy ? (sortBy ? [sortBy] : []) : state.sortBy,
            hiddenColumns: hiddenColumns
              ? Object.entries(hiddenColumns)
                  .filter(([columnId, hidden]) => hidden)
                  .map(([columnId, hidden]) => columnId)
              : [],
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [state, manualSortBy, sortBy, hiddenColumns]
        );
      },
      defaultColumn,
      columns,
      data: data ?? emptyArray,
      getRowId,
      disableGlobalFilter: !enableFilters && !useQuickSearch,
      disableFilters: !enableFilters,
      disableSortBy: !enableSorting,
      manualSortBy,
      // manualPagination: !enablePagination,
      defaultCanSort: false,
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      sortTypes,
    },
    useFlexLayout,
    DISABLE_RESIZE_COLUMNS ? (hooks) => {} : useResizeColumns,
    useGlobalFilter,
    useFilters,
    useSortBy,
    (hooks) => {
      if (setSortBy) {
        hooks.getSortByToggleProps.push(
          (props, { instance, column, userProps }) => {
            return [
              props,
              {
                onClick: column.canSort
                  ? () => {
                      const sortId = column.id;
                      const desc = column.isSortedDesc
                        ? false
                        : column.isSorted
                        ? true
                        : column.sortDescFirst;

                      setSortBy({ id: sortId, desc });
                    }
                  : undefined,
              },
            ] as any;
          }
        );
      }

      hooks.useInstance.push((instance) => {
        if (toggleColumnHidden) {
          instance.toggleHideColumn = (columnId, value) => {
            const isVisible =
              instance.allColumns.find((column) => column.id === columnId)
                ?.isVisible ?? false;

            toggleColumnHidden(columnId, isVisible);
          };

          instance.flatHeaders.forEach((column) => {
            column.toggleHidden = (value) => {
              toggleColumnHidden(column.id, value ?? column.isVisible);
            };
          });
        }
      });
    },
    useRowSelect,
    (hooks) => {
      if (enableSelection) {
        hooks.visibleColumns.push((columns) => [
          // Selection checkbox
          {
            id: 'selection',
            width: 42,
            minWidth: 42,
            maxWidth: 42,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row, rowsById }) => (
              <Checkbox
                disabled={useQuickSearch && !rowsById[row.id]}
                {...row.getToggleRowSelectedProps()}
              />
            ),
          } as ColumnWithLooseAccessor<D>,
          ...columns,
        ]);
      }
    }
  );
  const dataLength = data?.length ?? 0;
  useEffect(() => {
    warning(
      rawRows.length === dataLength,
      `Data length (${dataLength}) and table length (${rawRows.length}) do not match. The getRowId implementation may be incorrectly giving rows duplicate ids.`
    );
  }, [dataLength, rawRows.length]);

  const rows = useQuickSearch ? rawRows : clientFilteredRows;
  const hasQuickSearch = useQuickSearch && !!globalFilter;
  const isRowInQuickSearch = useMemo((): ((row: Row<D>) => boolean) => {
    if (useQuickSearch) {
      if (!hasQuickSearch) {
        return () => false;
      }

      const filtered = new Set<string>(clientFilteredRows.map((row) => row.id));

      return (row) => filtered.has(row.id);
    } else {
      return () => false;
    }
  }, [clientFilteredRows, hasQuickSearch, useQuickSearch]);

  const [theadSize, theadRef] = useMeasure<HTMLTableSectionElement>();
  const theadHeight = theadSize?.height ?? 58 + 1;

  const [parentRef, setParentRef] = useState<null | HTMLDivElement>(null);
  const {
    virtualItems,
    totalSize: totalScrollHeight,
    scrollToIndex,
  } = useVirtual({
    size: rows.length + (hasNextPage ? 1 : 0),
    parentRef: useMemo(() => ({ current: parentRef }), [parentRef]),
    estimateSize: useCallback(() => 58 + 1, []),
    overscan: 10,
    keyExtractor: useCallback(
      (index: number) =>
        index > rows.length - 1 ? 'virtual-last-item' : rows[index].id,
      [rows]
    ),
    paddingStart: theadHeight,
    scrollPaddingStart: theadHeight,
    scrollToFn: useSmoothScrollForVirtual(parentRef),
  });

  const hasFilter = useMemo(
    () => allColumns.some((column) => column.canFilter),
    [allColumns]
  );

  // Quick filter / Quick Search
  const onGlobalFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setGlobalFilter(e.target.value);
    },
    [setGlobalFilter]
  );
  const quickFilterProps =
    disableGlobalFilter === false && !useQuickSearch
      ? {
          value: globalFilter,
          onChange: onGlobalFilterChange,
        }
      : undefined;

  const [matchIndex, setMatchIndex] = useState(0);
  const onQuickSearchChange = useCallback(
    (quickSearch: string) => {
      setGlobalFilter(quickSearch);
      setMatchIndex(0);
    },
    [setGlobalFilter]
  );
  const matchTotal = globalFilter ? clientFilteredRows.length : undefined;
  const { prevQuickSearchMatch, nextQuickSearchMatch } = useMemo(() => {
    const total = matchTotal ?? 0;
    const cap = (index: number) =>
      total === 0 ? 0 : ((index - 1 + total) % total) + 1;

    return {
      prevQuickSearchMatch: () =>
        setMatchIndex((matchIndex) =>
          matchIndex === 0 ? total : cap(matchIndex - 1)
        ),
      nextQuickSearchMatch: () =>
        setMatchIndex((matchIndex) => cap(matchIndex + 1)),
    };
  }, [matchTotal]);
  const quickSearchProps: DataTableQuickSearchProps | undefined = useQuickSearch
    ? {
        value: globalFilter ?? '',
        onChange: onQuickSearchChange,
        matchIndex,
        matchTotal,
        onPrevMatch: prevQuickSearchMatch,
        onNextMatch: nextQuickSearchMatch,
      }
    : undefined;

  const clientFilteredRowsRef = useSyncedRef(clientFilteredRows);
  useEffect(() => {
    // Scroll to quick search matches as the match index changes
    if (matchIndex > 0) {
      const row = clientFilteredRowsRef.current[matchIndex - 1];
      if (row) {
        scrollToIndex(row.index, { align: 'center' });
      }
    }
  }, [clientFilteredRowsRef, matchIndex, scrollToIndex]);

  const isRowActiveMatch = useMemo((): ((row: Row<D>) => boolean) => {
    if (matchIndex > 0) {
      const activeRow = clientFilteredRows[matchIndex - 1];

      if (activeRow) {
        return (row) => row.id === activeRow.id;
      }
    }

    return () => false;
  }, [clientFilteredRows, matchIndex]);

  // Pagination
  const virtualItemsRef = useRef<VirtualItem[] | null>();
  useLayoutEffect(() => {
    virtualItemsRef.current = virtualItems;
  }, [virtualItems]);
  const { onPrevPage, onNextPage, getPageItems } = useMemo(() => {
    const getScrollPage = () => {
      return parentRef
        ? {
            pageTop: parentRef.scrollTop + theadHeight,
            pageBottom: parentRef.scrollTop + parentRef.clientHeight,
          }
        : {
            pageTop: 0,
            pageBottom: 0,
          };
    };
    const getItem = (
      compare: (a: VirtualItem, b: VirtualItem) => VirtualItem
    ): VirtualItem | null => {
      if (!parentRef || !virtualItemsRef.current) return null;

      const { pageTop, pageBottom } = getScrollPage();
      return virtualItemsRef.current.reduce<VirtualItem | null>(
        (currentFirstItem, item) => {
          if (item.end < pageTop || item.start > pageBottom)
            return currentFirstItem;
          return currentFirstItem ? compare(currentFirstItem, item) : item;
        },
        null
      );
    };

    const getPageItems = (insetRatio: number = 0): VirtualItem[] => {
      if (!parentRef || !virtualItemsRef.current) return [];

      const { pageTop, pageBottom } = getScrollPage();

      return virtualItemsRef.current.filter((item) => {
        const top = item.end - item.size * insetRatio;
        const bottom = item.start + item.size * insetRatio;
        return top >= pageTop && bottom <= pageBottom;
      });
    };

    const onPrevPage = () => {
      warning(parentRef && virtualItemsRef.current, 'table not ready');
      const firstItem = getItem((a, b) => (a.end < b.end ? a : b));
      warning(firstItem, 'first item not found');
      if (!firstItem) return;

      scrollToIndex(firstItem.index, { align: 'end' });
    };
    const onNextPage = () => {
      warning(parentRef && virtualItemsRef.current, 'table not ready');
      const lastItem = getItem((a, b) => (a.start > b.start ? a : b));
      warning(lastItem, 'last item not found');
      if (!lastItem) return;

      scrollToIndex(lastItem.index, { align: 'start' });
    };

    return { onPrevPage, onNextPage, getPageItems };
  }, [parentRef, scrollToIndex, theadHeight]);

  const page = getPageItems(0.5);
  const currentPage: Pick<
    UseDataTableResult<any>['pagination'],
    'from' | 'to'
  > = { from: undefined, to: undefined };
  if (page.length >= 1) {
    currentPage.from = page[0].index + 1;
    currentPage.to = Math.min(page[page.length - 1].index + 1, rows.length);
  }

  const pagination: UseDataTableResult<D>['pagination'] = {
    from: currentPage.from,
    to: currentPage.to,
    count: data ? rows.length : undefined,
    hasMore: hasNextPage,
    onPrevPage,
    onNextPage,
  };

  // Row selection
  const selected = useMemo(
    () => selectedFlatRows.map((row) => row.original),
    [selectedFlatRows]
  );

  const single = useCallback(
    <N extends unknown>(render: (item: D) => N): N | undefined => {
      if (selected.length === 1) return render(selected[0]);
      return undefined;
    },
    [selected]
  );

  const clearSelectionProps = {
    onClick: useCallback(() => {
      toggleAllRowsSelected(false);
    }, [toggleAllRowsSelected]),
  };

  const selectionHeaderProps = {
    selected,
    clearSelectionProps,
  };

  // CSV generation
  const downloadCsv = useCallback(
    (filename: string) => {
      download(tableToCsv<D>({ visibleColumns, rows, prepareRow }), {
        filename,
        mimeType: 'text/csv',
      });
    },
    [prepareRow, rows, visibleColumns]
  );

  // Table
  let tableBody: ReactNode;
  if (rows.length === 0) {
    if (error) {
      tableBody = <ErrorRow {...{ colSpan: visibleColumns.length, error }} />;
    } else if (customError) {
      tableBody = (
        <TableRow>
          <DataTableEmptyCell colSpan={visibleColumns.length}>
            {customError}
          </DataTableEmptyCell>
        </TableRow>
      );
    } else if (loading) {
      tableBody = (
        <TableRow>
          <DataTableEmptyCell colSpan={visibleColumns.length}>
            <CircularProgress size={24} />
          </DataTableEmptyCell>
        </TableRow>
      );
    } else {
      tableBody = (
        <TableRow>
          <DataTableEmptyCell colSpan={visibleColumns.length}>
            {t('noRowsToShow')}
          </DataTableEmptyCell>
        </TableRow>
      );
    }
  } else {
    tableBody = virtualItems.map((item) => {
      const virtualSize = item.size;
      const virtualStart = item.start;

      if (item.index > rows.length - 1) {
        return (
          <DataTableLastItem
            key={item.key}
            {...{
              colSpan: visibleColumns.length,
              virtualSize,
              virtualStart,
              hasNextPage,
              isFetchingNextPage,
              fetchNextPage,
            }}
          />
        );
      } else {
        const row = rows[item.index];

        prepareRow(row);
        return (
          <DataTableRowItem
            key={item.key}
            {...{
              onRowDoubleClick,
              row,
              toggleAllRowsSelected,
              toggleRowSelected,
              virtualSize,
              virtualStart,
            }}
            highlighted={isRowInQuickSearch(row)}
            active={isRowActiveMatch(row)}
          />
        );
      }
    });
  }

  const columnsToggle = (
    <List>
      {resetHiddenColumns && (
        <ListItem disablePadding dense>
          <Button
            variant="text"
            fullWidth
            onClick={() => {
              resetHiddenColumns();
            }}
          >
            {t('button.resetToDefault')}
          </Button>
        </ListItem>
      )}
      {allColumns
        .filter((column) => column.id !== 'selection')
        .map((column) => {
          const labelId = `${id}-column-toggle-${column.id}`;
          const { title, ...checkboxProps } = column.getToggleHiddenProps();

          return (
            <ListItem key={column.id} disablePadding dense>
              <ListItemButton
                role={undefined}
                dense
                onClick={() => {
                  column.toggleHidden();
                }}
              >
                <ListItemIcon>
                  <Switch
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-label': title }}
                    {...checkboxProps}
                    onChange={
                      checkboxProps.onChange
                        ? (e) => {
                            e.stopPropagation();
                            checkboxProps.onChange(e);
                          }
                        : undefined
                    }
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={column.render('Header')} />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );

  const columnsProps = manualHiddenColumns
    ? {
        extraHiddenCount: allColumns.filter((column) => !column.isVisible)
          .length,
        extraVisibleCount: 0,
        columnsToggle,
      }
    : undefined;

  const table = (
    <TableRoot ref={setParentRef} className={`layout-${layout}`}>
      <DataTable
        layout="flex"
        tableLayout="flex"
        empty={rows.length === 0}
        // size="small"
        stickyHeader
        {...getTableProps()}
      >
        <DataTableTableHead ref={theadRef}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableCell
                  {...(column.id === 'selection'
                    ? column.getHeaderProps({
                        className: clsx(
                          `DataTableCell-${column.id}`,
                          `DataTableCell-${column.align}`,
                          `DataTableCell-${column.overflow}`
                        ),
                        style: getCellStyle(column),
                      })
                    : column.getHeaderProps({
                        style: getCellStyle(column),
                        className: clsx(
                          `DataTableCell-${column.id}`,
                          `DataTableCell-${column.align}`,
                          `DataTableCell-${column.overflow}`
                        ),
                      }))}
                >
                  <DataTableHeaderContainer {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    <SortIndicator
                      disabled={!column.canSort}
                      active={column.isSorted}
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    />
                  </DataTableHeaderContainer>
                  {!DISABLE_RESIZE_COLUMNS && (
                    <DataTableHeaderResizer
                      {...column.getResizerProps()}
                      aria-label={t('label.resizeColumn')}
                      className={
                        column.isResizing
                          ? 'DataTableHeaderResizer-isResizing'
                          : ''
                      }
                    />
                  )}
                </DataTableCell>
              ))}
            </TableRow>
          ))}
          {hasFilter &&
            headerGroups.map((headerGroup) => (
              <DataTableFilterRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps({
                      className: `DataTableCell-${column.id}`,
                    })}
                  >
                    {column.canFilter ? column.render('Filter') : null}
                  </TableCell>
                ))}
              </DataTableFilterRow>
            ))}
          <DataTableProgress colSpan={flatHeaders.length} loading={loading} />
        </DataTableTableHead>
        <TableBody
          style={{ height: Math.max(0, totalScrollHeight - theadHeight) }}
        >
          {tableBody}
        </TableBody>
      </DataTable>
    </TableRoot>
  );

  return {
    table,
    columnsProps,
    downloadAsCsv: downloadCsv,
    quickFilter: globalFilter,
    quickFilterProps,
    quickSearchProps,
    pagination,
    selection: { selected, selectionHeaderProps, clearSelectionProps, single },
  };
}
