import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { forwardRef, ReactElement, Ref } from 'react';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import { Validator } from '../validation/validator';
import {
  UseAutocompleteFieldProps,
  useAutocompleteTextFieldProps,
} from './internal/useAutocompleteFieldProps';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';

export type AutocompleteFieldProps<
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  UseAutocompleteFieldProps<T, false, DisableClearable, FreeSolo>,
  'onChange' | 'renderInput'
> &
  CommonValidatorProps &
  Pick<TextFieldProps, 'label' | 'InputProps'> & {
    readOnly?: boolean;
    commonValidate?: { iban?: boolean };
  };

/**
 * A base Autocomplete field implementation
 */
export const AutocompleteField = forwardRef(function AutocompleteField<
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteFieldProps<T, DisableClearable, FreeSolo>, ref: Ref<any>) {
  const {
    id: idProp,
    required = false,
    readOnly = false,
    label,
    commonValidate,
    InputProps,
    ...other
  } = usePropsWithId(props);

  const validate = useCommonValidate(props, ({ iban }) =>
    // @fixme AutocompleteField should be a hook so we can ditch this custom hardcoding
    [commonValidate?.iban && iban()].filter(
      (validator): validator is Validator => !!validator
    )
  );
  const { getAutocompleteProps, getTextFieldProps } =
    useAutocompleteTextFieldProps<T, false, DisableClearable, FreeSolo>({
      ...other,
      validate,
      multiple: false,
    });

  return (
    <Autocomplete
      ref={ref}
      {...getAutocompleteProps()}
      renderInput={(params: AutocompleteRenderInputParams) =>
        readOnly ? (
          <ReadOnlyField
            {...getTextFieldProps({
              ...params,
              InputProps: { ...params.InputProps, ...InputProps },
            })}
            label={label}
          />
        ) : (
          <TextField
            {...getTextFieldProps({
              ...params,
              InputProps: { ...params.InputProps, ...InputProps },
            })}
            required={required}
            label={label}
          />
        )
      }
    />
  );
}) as <
  T,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteFieldProps<T, DisableClearable, FreeSolo> & {
    ref?: Ref<any>;
  }
) => ReactElement;
