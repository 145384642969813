import { To } from 'history';
import { z } from 'zod';
import { useLocationState } from './location';

/**
 * Get a returnTo from the location state
 */
export function useReturnTo(): undefined | To {
  const { returnTo } = useLocationState(
    z
      .object({
        returnTo: z.union([
          z.string(),
          z
            .object({
              pathname: z.string(),
              search: z.string(),
              hash: z.string(),
            })
            .partial(),
        ]),
      })
      .partial()
  );

  return returnTo;
}
