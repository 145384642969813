import { TextField } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type EmailFieldProps = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  CommonValidatorProps & { readOnly?: boolean };

/**
 * A <TextField> for an email address
 */
export const EmailField = forwardRef(function EmailField(
  props: EmailFieldProps,
  ref: Ref<HTMLInputElement>
) {
  props = usePropsWithId(props);
  const validate = useCommonValidate(props, ({ email }) => [email()]);

  const textFieldProps = useTextFieldProps({
    ...props,
    validate,
    type: 'email',
  });

  if (props.readOnly) return <ReadOnlyField ref={ref} {...textFieldProps} />;

  return <TextField ref={ref} {...textFieldProps} />;
});
