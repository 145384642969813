import { ContactPhoneNumber, ContactPhoneNumberType } from '../../../api';

export interface PhoneNumberValues {
  type: ContactPhoneNumberType | '';
  number: string;
}

/**
 * An empty PhoneNumberValues
 */
export const emptyPhoneNumberValues: PhoneNumberValues = Object.freeze({
  type: '',
  number: '',
});

/**
 * Make a PhoneNumberValues from a PhoneNumber object
 */
export function phoneNumberToValues(
  phone: ContactPhoneNumber
): PhoneNumberValues {
  return {
    type: phone.Type ?? '',
    number: phone.PhoneNumber ?? '',
  };
}

/**
 * Convert PhoneNumberValues to a PhoneNumber object
 */
export function phoneNumberValuesToPhoneNumber(
  values: PhoneNumberValues
): ContactPhoneNumber {
  return {
    Type: values.type as ContactPhoneNumberType,
    PhoneNumber: values.number,
  };
}
