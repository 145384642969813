/* tslint:disable */
/* eslint-disable */
/**
 * Transactive API
 * A web-based electronic payment processing service.
 *
 * The version of the OpenAPI document: 2.1.6
 * Contact: it@transactiveltd.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import { merge } from 'lodash-es';
import globalAxios, {
  CancelToken,
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { QueryKey } from 'react-query';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';
import {
  ApiMethodMakerOptions,
  BaseApiMaker,
  useApiMethodMaker,
} from '../internal/apiMaker';
import { ApiMutationHandler, ApiQueryHandler } from '../types';

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {string}
   * @memberof Account
   */
  id: string;
  /**
   *
   * @type {Money}
   * @memberof Account
   */
  Balance?: Money;
  /**
   *
   * @type {Beneficiary}
   * @memberof Account
   */
  Beneficiary?: Beneficiary;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof Account
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  CreatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  CreationDate?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  Descriptor?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  FeeAccount?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  Iban?: string;
  /**
   *
   * @type {boolean}
   * @memberof Account
   */
  IsThirdParty?: boolean;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  LastActivity?: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  LedgerAccount?: string;
  /**
   *
   * @type {AccountLimits}
   * @memberof Account
   */
  Limits?: AccountLimits;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  Name?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof Account
   */
  Status?: ActiveType;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  TerminationDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof Account
   */
  UseDynamicDescriptor?: boolean;
}
/**
 *
 * @export
 * @interface AccountAdminBody
 */
export interface AccountAdminBody {
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  id?: string;
  /**
   *
   * @type {Money}
   * @memberof AccountAdminBody
   */
  Balance?: Money;
  /**
   *
   * @type {Beneficiary}
   * @memberof AccountAdminBody
   */
  Beneficiary?: Beneficiary;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  ClearingSystemAcctId?: string;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof AccountAdminBody
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  CreatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  CreationDate?: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  Descriptor?: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  FeeAccount?: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  Iban?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountAdminBody
   */
  IsThirdParty?: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  LastActivity?: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  LedgerAccount?: string;
  /**
   *
   * @type {AccountLimits}
   * @memberof AccountAdminBody
   */
  Limits?: AccountLimits;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  ProviderId?: string;
  /**
   *
   * @type {number}
   * @memberof AccountAdminBody
   */
  RiskLevel?: number;
  /**
   *
   * @type {AccountRole}
   * @memberof AccountAdminBody
   */
  Role?: AccountRole;
  /**
   *
   * @type {ActiveType}
   * @memberof AccountAdminBody
   */
  Status?: ActiveType;
  /**
   *
   * @type {string}
   * @memberof AccountAdminBody
   */
  TerminationDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountAdminBody
   */
  UseDynamicDescriptor?: boolean;
}
/**
 *
 * @export
 * @interface AccountAdminPostBody
 */
export interface AccountAdminPostBody {
  /**
   *
   * @type {Beneficiary}
   * @memberof AccountAdminPostBody
   */
  Beneficiary?: Beneficiary;
  /**
   *
   * @type {ClearingSystem}
   * @memberof AccountAdminPostBody
   */
  ClearingSystem: ClearingSystem;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof AccountAdminPostBody
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPostBody
   */
  Currency: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPostBody
   */
  Descriptor?: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPostBody
   */
  FeeAccount?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountAdminPostBody
   */
  IsThirdParty: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPostBody
   */
  LedgerAccount?: string;
  /**
   *
   * @type {AccountLimits}
   * @memberof AccountAdminPostBody
   */
  Limits?: AccountLimits;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPostBody
   */
  Name: string;
  /**
   *
   * @type {number}
   * @memberof AccountAdminPostBody
   */
  RiskLevel?: number;
  /**
   *
   * @type {boolean}
   * @memberof AccountAdminPostBody
   */
  UseDynamicDescriptor?: boolean;
}
/**
 *
 * @export
 * @interface AccountAdminPutBody
 */
export interface AccountAdminPutBody {
  /**
   *
   * @type {Beneficiary}
   * @memberof AccountAdminPutBody
   */
  Beneficiary?: Beneficiary;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof AccountAdminPutBody
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPutBody
   */
  Descriptor: string;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPutBody
   */
  FeeAccount?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountAdminPutBody
   */
  IsThirdParty: boolean;
  /**
   *
   * @type {AccountLimits}
   * @memberof AccountAdminPutBody
   */
  Limits?: AccountLimits;
  /**
   *
   * @type {string}
   * @memberof AccountAdminPutBody
   */
  Name: string;
  /**
   *
   * @type {number}
   * @memberof AccountAdminPutBody
   */
  RiskLevel?: number;
  /**
   *
   * @type {boolean}
   * @memberof AccountAdminPutBody
   */
  UseDynamicDescriptor?: boolean;
}
/**
 *
 * @export
 * @interface AccountBlock
 */
export interface AccountBlock {
  /**
   *
   * @type {string}
   * @memberof AccountBlock
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AccountBlock
   */
  Iban: string;
  /**
   *
   * @type {string}
   * @memberof AccountBlock
   */
  Notes: string;
  /**
   *
   * @type {string}
   * @memberof AccountBlock
   */
  OrgId?: string;
  /**
   *
   * @type {AccountBlockStatus}
   * @memberof AccountBlock
   */
  Status: AccountBlockStatus;
  /**
   *
   * @type {string}
   * @memberof AccountBlock
   */
  Timestamp: string;
}
/**
 *
 * @export
 * @interface AccountBlockPostBody
 */
export interface AccountBlockPostBody {
  /**
   *
   * @type {string}
   * @memberof AccountBlockPostBody
   */
  Iban: string;
  /**
   *
   * @type {string}
   * @memberof AccountBlockPostBody
   */
  Notes: string;
  /**
   *
   * @type {string}
   * @memberof AccountBlockPostBody
   */
  OrgId?: string;
}
/**
 *
 * @export
 * @interface AccountBlockPutBody
 */
export interface AccountBlockPutBody {
  /**
   *
   * @type {string}
   * @memberof AccountBlockPutBody
   */
  OrgId?: string;
  /**
   *
   * @type {string}
   * @memberof AccountBlockPutBody
   */
  Notes: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const AccountBlockStatus = {
  Blocked: 'Blocked',
  Disabled: 'Disabled',
  Pending: 'Pending',
  Whitelisted: 'Whitelisted',
} as const;

export type AccountBlockStatus =
  typeof AccountBlockStatus[keyof typeof AccountBlockStatus];

/**
 *
 * @export
 * @interface AccountLimits
 */
export interface AccountLimits {
  /**
   *
   * @type {Money}
   * @memberof AccountLimits
   */
  DailyOB?: Money;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountLimits
   */
  IBAccountWhiteList?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountLimits
   */
  IBCountryWhiteList?: Array<string>;
  /**
   *
   * @type {Money}
   * @memberof AccountLimits
   */
  IBPayment?: Money;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountLimits
   */
  OBAccountWhiteList?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof AccountLimits
   */
  OBCountryWhiteList?: Array<string>;
  /**
   *
   * @type {Money}
   * @memberof AccountLimits
   */
  OBPayment?: Money;
  /**
   *
   * @type {Money}
   * @memberof AccountLimits
   */
  OnAccount?: Money;
}
/**
 *
 * @export
 * @interface AccountPostBody
 */
export interface AccountPostBody {
  /**
   *
   * @type {Beneficiary}
   * @memberof AccountPostBody
   */
  Beneficiary?: Beneficiary;
  /**
   *
   * @type {ClearingSystem}
   * @memberof AccountPostBody
   */
  ClearingSystem: ClearingSystem;
  /**
   *
   * @type {string}
   * @memberof AccountPostBody
   */
  Currency: string;
  /**
   *
   * @type {string}
   * @memberof AccountPostBody
   */
  Descriptor?: string;
  /**
   *
   * @type {string}
   * @memberof AccountPostBody
   */
  FeeAccount?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountPostBody
   */
  IsThirdParty: boolean;
  /**
   *
   * @type {string}
   * @memberof AccountPostBody
   */
  LedgerAccount?: string;
  /**
   *
   * @type {AccountLimits}
   * @memberof AccountPostBody
   */
  Limits?: AccountLimits;
  /**
   *
   * @type {string}
   * @memberof AccountPostBody
   */
  Name: string;
}
/**
 *
 * @export
 * @interface AccountPutBody
 */
export interface AccountPutBody {
  /**
   *
   * @type {Beneficiary}
   * @memberof AccountPutBody
   */
  Beneficiary?: Beneficiary;
  /**
   *
   * @type {string}
   * @memberof AccountPutBody
   */
  FeeAccount?: string;
  /**
   *
   * @type {boolean}
   * @memberof AccountPutBody
   */
  IsThirdParty: boolean;
  /**
   *
   * @type {AccountLimits}
   * @memberof AccountPutBody
   */
  Limits?: AccountLimits;
  /**
   *
   * @type {string}
   * @memberof AccountPutBody
   */
  Name: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const AccountRole = {
  Operating: 'Operating',
  Liabilities: 'Liabilities',
  Payables: 'Payables',
} as const;

export type AccountRole = typeof AccountRole[keyof typeof AccountRole];

/**
 *
 * @export
 * @interface AccountShortSummary
 */
export interface AccountShortSummary {
  /**
   *
   * @type {string}
   * @memberof AccountShortSummary
   */
  Id?: string;
  /**
   *
   * @type {number}
   * @memberof AccountShortSummary
   */
  Balance?: number;
  /**
   *
   * @type {string}
   * @memberof AccountShortSummary
   */
  Currency?: string;
  /**
   *
   * @type {string}
   * @memberof AccountShortSummary
   */
  Iban?: string;
  /**
   *
   * @type {string}
   * @memberof AccountShortSummary
   */
  Name?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof AccountShortSummary
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface AccountWhitelist
 */
export interface AccountWhitelist {
  /**
   *
   * @type {string}
   * @memberof AccountWhitelist
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof AccountWhitelist
   */
  Iban?: string;
  /**
   *
   * @type {string}
   * @memberof AccountWhitelist
   */
  Name?: string;
  /**
   *
   * @type {PaymentType}
   * @memberof AccountWhitelist
   */
  PaymentType?: PaymentType;
  /**
   *
   * @type {string}
   * @memberof AccountWhitelist
   */
  Timestamp?: string;
}
/**
 *
 * @export
 * @interface AccountWhitelistPostBody
 */
export interface AccountWhitelistPostBody {
  /**
   *
   * @type {string}
   * @memberof AccountWhitelistPostBody
   */
  Iban: string;
  /**
   *
   * @type {string}
   * @memberof AccountWhitelistPostBody
   */
  Name: string;
  /**
   *
   * @type {PaymentType}
   * @memberof AccountWhitelistPostBody
   */
  PaymentType: PaymentType;
  /**
   *
   * @type {string}
   * @memberof AccountWhitelistPostBody
   */
  Timestamp: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ActiveType = {
  Pending: 'Pending',
  Active: 'Active',
  Suspended: 'Suspended',
  Terminated: 'Terminated',
} as const;

export type ActiveType = typeof ActiveType[keyof typeof ActiveType];

/**
 *
 * @export
 * @interface Activity
 */
export interface Activity {
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  Account?: string;
  /**
   *
   * @type {Money}
   * @memberof Activity
   */
  AccountAmount?: Money;
  /**
   *
   * @type {AccountRole}
   * @memberof Activity
   */
  AccountRole?: AccountRole;
  /**
   *
   * @type {ActivityType}
   * @memberof Activity
   */
  Activity?: ActivityType;
  /**
   *
   * @type {Money}
   * @memberof Activity
   */
  Amount?: Money;
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  ClientName?: string;
  /**
   *
   * @type {Money}
   * @memberof Activity
   */
  ClosingBalance?: Money;
  /**
   *
   * @type {EventType}
   * @memberof Activity
   */
  Event?: EventType;
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  EventId?: string;
  /**
   *
   * @type {number}
   * @memberof Activity
   */
  Exchange?: number;
  /**
   *
   * @type {Money}
   * @memberof Activity
   */
  OpeningBalance?: Money;
  /**
   *
   * @type {BankAccount}
   * @memberof Activity
   */
  PaymentCounterparty?: BankAccount;
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  PaymentDescription?: string;
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  PaymentId?: string;
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  PaymentReference?: string;
  /**
   *
   * @type {EventType}
   * @memberof Activity
   */
  PaymentStatus?: EventType;
  /**
   *
   * @type {PaymentType}
   * @memberof Activity
   */
  PaymentType?: PaymentType;
  /**
   *
   * @type {EntryRole}
   * @memberof Activity
   */
  Role?: EntryRole;
  /**
   *
   * @type {EntrySign}
   * @memberof Activity
   */
  Sign?: EntrySign;
  /**
   *
   * @type {string}
   * @memberof Activity
   */
  Timestamp?: string;
}
/**
 * High level type of Account activity.
 * @export
 * @enum {string}
 */

export const ActivityType = {
  Payout: 'Payout',
  Deposit: 'Deposit',
  XferIn: 'XferIn',
  XferOut: 'XferOut',
  Reversal: 'Reversal',
  Fee: 'Fee',
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  City: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  Country: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  Postal?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  Region?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  Street1: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  Street2?: string;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  Street3?: string;
}
/**
 * Authorities only visible to admins
 * @export
 * @enum {string}
 */

export const AdminAuthority = {
  ClientAdmin: 'ClientAdmin',
  ClientReporting: 'ClientReporting',
  Compliance: 'Compliance',
  PaymentOfficer: 'PaymentOfficer',
  SuperAdmin: 'SuperAdmin',
} as const;

export type AdminAuthority = typeof AdminAuthority[keyof typeof AdminAuthority];

/**
 *
 * @export
 * @interface Application
 */
export interface Application {
  /**
   *
   * @type {string}
   * @memberof Application
   */
  id: string;
  /**
   *
   * @type {Array<Authority>}
   * @memberof Application
   */
  Authorities?: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  Email?: string;
  /**
   *
   * @type {ContactLimits}
   * @memberof Application
   */
  FinancialLimits?: ContactLimits;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  ObfuscatedApiKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof Application
   */
  RequireAuthentication?: boolean;
  /**
   *
   * @type {ActiveType}
   * @memberof Application
   */
  Status?: ActiveType;
  /**
   *
   * @type {string}
   * @memberof Application
   */
  Timestamp?: string;
}
/**
 *
 * @export
 * @interface ApplicationPostBody
 */
export interface ApplicationPostBody {
  /**
   *
   * @type {Array<Authority>}
   * @memberof ApplicationPostBody
   */
  Authorities: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof ApplicationPostBody
   */
  Email: string;
  /**
   *
   * @type {ApplicationPostBodyFinancialLimits}
   * @memberof ApplicationPostBody
   */
  FinancialLimits?: ApplicationPostBodyFinancialLimits;
  /**
   *
   * @type {string}
   * @memberof ApplicationPostBody
   */
  Name: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationPostBody
   */
  PublicKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationPostBody
   */
  RequireAuthentication: boolean;
  /**
   *
   * @type {ActiveType}
   * @memberof ApplicationPostBody
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface ApplicationPostBodyFinancialLimits
 */
export interface ApplicationPostBodyFinancialLimits {
  /**
   *
   * @type {Money}
   * @memberof ApplicationPostBodyFinancialLimits
   */
  DailyOB?: Money;
  /**
   *
   * @type {Money}
   * @memberof ApplicationPostBodyFinancialLimits
   */
  OBPayment?: Money;
}
/**
 *
 * @export
 * @interface ApplicationRegistrationResponseBody
 */
export interface ApplicationRegistrationResponseBody {
  /**
   *
   * @type {string}
   * @memberof ApplicationRegistrationResponseBody
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationRegistrationResponseBody
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof ApplicationRegistrationResponseBody
   */
  ApiKey?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ApplicationRegistrationResponseBody
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface Attachment
 */
export interface Attachment {
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  DateUploaded?: string;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  FileName?: string;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  FileType?: string;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  FileSize?: string;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  SignedUrl?: string;
}
/**
 *
 * @export
 * @interface AttachmentPostBody
 */
export interface AttachmentPostBody {
  /**
   *
   * @type {string}
   * @memberof AttachmentPostBody
   */
  FileId?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentPostBody
   */
  FileName?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentPostBody
   */
  FileSize?: string;
  /**
   *
   * @type {string}
   * @memberof AttachmentPostBody
   */
  TempFile?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const AttachmentType = {
  SupportingDocument: 'SupportingDocument',
  Contract: 'Contract',
} as const;

export type AttachmentType = typeof AttachmentType[keyof typeof AttachmentType];

/**
 *
 * @export
 * @interface Author
 */
export interface Author {
  /**
   *
   * @type {string}
   * @memberof Author
   */
  ContactId: string;
  /**
   *
   * @type {string}
   * @memberof Author
   */
  FamilyName?: string;
  /**
   *
   * @type {string}
   * @memberof Author
   */
  FirstName?: string;
}
/**
 * Authorities in both AdminAuthority and ClientAuthority
 * @export
 * @enum {string}
 */

export const Authority = {
  AccountInformation: 'AccountInformation',
  Admin: 'Admin',
  ChangePassword: 'ChangePassword',
  ClientAdmin: 'ClientAdmin',
  ClientReporting: 'ClientReporting',
  Compliance: 'Compliance',
  Payment: 'Payment',
  PaymentOfficer: 'PaymentOfficer',
  Reporting: 'Reporting',
  SuperAdmin: 'SuperAdmin',
} as const;

export type Authority = typeof Authority[keyof typeof Authority];

/**
 *
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  Address?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  Bic?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  ClearingSystemAcctId?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  Iban: string;
  /**
   *
   * @type {string}
   * @memberof BankAccount
   */
  Name: string;
}
/**
 *
 * @export
 * @interface BankAccountLookupPostBody
 */
export interface BankAccountLookupPostBody {
  /**
   *
   * @type {string}
   * @memberof BankAccountLookupPostBody
   */
  Address?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountLookupPostBody
   */
  Bic?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountLookupPostBody
   */
  Iban: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountLookupPostBody
   */
  Name?: string;
}
/**
 *
 * @export
 * @interface BankAccountPostBody
 */
export interface BankAccountPostBody {
  /**
   *
   * @type {string}
   * @memberof BankAccountPostBody
   */
  Address?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountPostBody
   */
  Bic?: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountPostBody
   */
  Iban: string;
  /**
   *
   * @type {string}
   * @memberof BankAccountPostBody
   */
  Name: string;
}
/**
 *
 * @export
 * @interface Batch
 */
export interface Batch {
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  BatchReference?: string;
  /**
   *
   * @type {Error}
   * @memberof Batch
   */
  Error?: Error;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  FailedItems?: number;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  Output?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  ResultsReady?: string;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  SuccessfullItems?: number;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  Timestamp?: string;
  /**
   *
   * @type {number}
   * @memberof Batch
   */
  UnprocessableItems?: number;
}
/**
 *
 * @export
 * @interface BatchLoadRequest
 */
export interface BatchLoadRequest {
  /**
   *
   * @type {string}
   * @memberof BatchLoadRequest
   */
  Data?: string;
  /**
   *
   * @type {string}
   * @memberof BatchLoadRequest
   */
  Token?: string;
  /**
   *
   * @type {Batch}
   * @memberof BatchLoadRequest
   */
  Batch?: Batch;
}
/**
 *
 * @export
 * @interface BehaviourClientReview
 */
export interface BehaviourClientReview {
  /**
   *
   * @type {string}
   * @memberof BehaviourClientReview
   */
  ClientId?: string;
}
/**
 *
 * @export
 * @interface BehaviourDownload
 */
export interface BehaviourDownload {
  /**
   *
   * @type {string}
   * @memberof BehaviourDownload
   */
  Expiry?: string;
  /**
   *
   * @type {string}
   * @memberof BehaviourDownload
   */
  FileUri: string;
}
/**
 *
 * @export
 * @interface BehaviourReport
 */
export interface BehaviourReport {
  /**
   *
   * @type {string}
   * @memberof BehaviourReport
   */
  ReportId: string;
  /**
   *
   * @type {string}
   * @memberof BehaviourReport
   */
  FileUri: string;
}
/**
 *
 * @export
 * @interface Behaviours
 */
export interface Behaviours {
  /**
   *
   * @type {Array<BehaviourDownload>}
   * @memberof Behaviours
   */
  Download?: Array<BehaviourDownload>;
  /**
   *
   * @type {Array<BehaviourClientReview>}
   * @memberof Behaviours
   */
  ClientReview?: Array<BehaviourClientReview>;
  /**
   *
   * @type {Array<BehaviourReport>}
   * @memberof Behaviours
   */
  Report?: Array<BehaviourReport>;
}
/**
 *
 * @export
 * @interface Beneficiary
 */
export interface Beneficiary {
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  id?: string;
  /**
   *
   * @type {Address}
   * @memberof Beneficiary
   */
  Address?: Address;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  DoB?: string;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  Email?: string;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  EndDate?: string;
  /**
   *
   * @type {GovernmentId}
   * @memberof Beneficiary
   */
  GovernmentId?: GovernmentId;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  IncorporationCountry?: string;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  IncorporationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  Name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Beneficiary
   */
  Nationalities?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  PhoneNumber?: string;
  /**
   *
   * @type {Array<Role>}
   * @memberof Beneficiary
   */
  Roles?: Array<Role>;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  Sic?: string;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  StartDate?: string;
  /**
   *
   * @type {BeneficiaryType}
   * @memberof Beneficiary
   */
  Type: BeneficiaryType;
  /**
   *
   * @type {string}
   * @memberof Beneficiary
   */
  WebSite?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const BeneficiaryType = {
  Company: 'Company',
  Person: 'Person',
} as const;

export type BeneficiaryType =
  typeof BeneficiaryType[keyof typeof BeneficiaryType];

/**
 *
 * @export
 * @interface ChargeAndSource
 */
export interface ChargeAndSource {
  /**
   *
   * @type {Money}
   * @memberof ChargeAndSource
   */
  Amount: Money;
  /**
   *
   * @type {string}
   * @memberof ChargeAndSource
   */
  Account: string;
}
/**
 *
 * @export
 * @interface ChargeOptionalSource
 */
export interface ChargeOptionalSource {
  /**
   *
   * @type {Money}
   * @memberof ChargeOptionalSource
   */
  Amount: Money;
  /**
   *
   * @type {string}
   * @memberof ChargeOptionalSource
   */
  Account?: string;
}
/**
 * Compliance checklist of tasks
 * @export
 * @interface Checklist
 */
export interface Checklist {
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  id?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof Checklist
   */
  MaxComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {string}
   * @memberof Checklist
   */
  Name?: string;
  /**
   *
   * @type {Array<ChecklistItem>}
   * @memberof Checklist
   */
  Items?: Array<ChecklistItem>;
}
/**
 * Checklist item
 * @export
 * @interface ChecklistItem
 */
export interface ChecklistItem {
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistItem
   */
  Instructions?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItem
   */
  Required?: boolean;
}
/**
 * Checklist item
 * @export
 * @interface ChecklistItemReview
 */
export interface ChecklistItemReview {
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItemReview
   */
  Checked?: boolean;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ChecklistItemReview
   */
  Comments?: Array<Comment>;
  /**
   *
   * @type {string}
   * @memberof ChecklistItemReview
   */
  LastUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof ChecklistItemReview
   */
  LastUpdatedBy?: string;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ChecklistItemReview
   */
  Status?: ReviewStatus;
}
/**
 *
 * @export
 * @interface ChecklistReview
 */
export interface ChecklistReview {
  /**
   *
   * @type {Array<ChecklistReviewChecklist>}
   * @memberof ChecklistReview
   */
  Checklist?: Array<ChecklistReviewChecklist>;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ChecklistReview
   */
  Status?: ReviewStatus;
}
/**
 *
 * @export
 * @interface ChecklistReviewChecklist
 */
export interface ChecklistReviewChecklist {
  /**
   *
   * @type {ChecklistItem}
   * @memberof ChecklistReviewChecklist
   */
  Item1: ChecklistItem;
  /**
   *
   * @type {ChecklistItemReview}
   * @memberof ChecklistReviewChecklist
   */
  Item2: ChecklistItemReview;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ClearingSystem = {
  Bacs: 'Bacs',
  Chaps: 'Chaps',
  UKFP: 'UKFP',
  SepaND: 'SepaND',
  SepaInst: 'SepaInst',
  Seymour: 'Seymour',
  Undefined: 'Undefined',
} as const;

export type ClearingSystem = typeof ClearingSystem[keyof typeof ClearingSystem];

/**
 *
 * @export
 * @interface Client
 */
export interface Client {
  /**
   *
   * @type {string}
   * @memberof Client
   */
  id: string;
  /**
   *
   * @type {ProfileBusiness}
   * @memberof Client
   */
  Business: ProfileBusiness;
  /**
   *
   * @type {ChecklistReview}
   * @memberof Client
   */
  ChecklistReview?: ChecklistReview;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  ClientProfileId?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  ClientProfileName?: string;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof Client
   */
  ComplianceStatus: ComplianceStatus;
  /**
   *
   * @type {ProfileContract}
   * @memberof Client
   */
  Contract: ProfileContract;
  /**
   *
   * @type {ProfileGovernance}
   * @memberof Client
   */
  Governance: ProfileGovernance;
  /**
   *
   * @type {ClientIdentity}
   * @memberof Client
   */
  Identity: ClientIdentity;
  /**
   *
   * @type {boolean}
   * @memberof Client
   */
  IsLocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  LastUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  LastUpdatedBy?: string;
  /**
   *
   * @type {ProfileProduct}
   * @memberof Client
   */
  Product: ProfileProduct;
  /**
   *
   * @type {Array<Comment>}
   * @memberof Client
   */
  ReviewComments: Array<Comment>;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  ReviewCompleted?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  ReviewEnded?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  ReviewEndedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  ReviewStarted?: string;
  /**
   *
   * @type {string}
   * @memberof Client
   */
  ReviewStartedBy?: string;
  /**
   *
   * @type {ReviewStatus}
   * @memberof Client
   */
  ReviewStatus: ReviewStatus;
  /**
   *
   * @type {RiskReview}
   * @memberof Client
   */
  RiskReview?: RiskReview;
  /**
   *
   * @type {ActiveType}
   * @memberof Client
   */
  Status: ActiveType;
  /**
   *
   * @type {ProfileSupportingDocuments}
   * @memberof Client
   */
  SupportingDocuments: ProfileSupportingDocuments;
}
/**
 *
 * @export
 * @interface ClientAccountSummary
 */
export interface ClientAccountSummary {
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  Id?: string;
  /**
   *
   * @type {Money}
   * @memberof ClientAccountSummary
   */
  Balance?: Money;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  ClientName?: string;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ClientAccountSummary
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  CreatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  CreationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  Currency?: string;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  Descriptor?: string;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  Iban?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClientAccountSummary
   */
  IsThirdParty?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  LastActivity?: string;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  Name?: string;
  /**
   *
   * @type {Role}
   * @memberof ClientAccountSummary
   */
  Role?: Role;
  /**
   *
   * @type {ActiveType}
   * @memberof ClientAccountSummary
   */
  Status?: ActiveType;
  /**
   *
   * @type {string}
   * @memberof ClientAccountSummary
   */
  TerminationDate?: string;
}
/**
 * Authorities visible to clients
 * @export
 * @enum {string}
 */

export const ClientAuthority = {
  AccountInformation: 'AccountInformation',
  Admin: 'Admin',
  ChangePassword: 'ChangePassword',
  Payment: 'Payment',
  Reporting: 'Reporting',
} as const;

export type ClientAuthority =
  typeof ClientAuthority[keyof typeof ClientAuthority];

/**
 *
 * @export
 * @interface ClientIdentity
 */
export interface ClientIdentity {
  /**
   *
   * @type {Array<ClientIdentityAddresses>}
   * @memberof ClientIdentity
   */
  Addresses: Array<ClientIdentityAddresses>;
  /**
   *
   * @type {ClientIdentityIncorporationDate}
   * @memberof ClientIdentity
   */
  IncorporationDate: ClientIdentityIncorporationDate;
  /**
   *
   * @type {ClientIdentityIncorporationLoc}
   * @memberof ClientIdentity
   */
  IncorporationLoc: ClientIdentityIncorporationLoc;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ClientIdentity
   */
  IncorporationNumber: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ClientIdentity
   */
  InformalName: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ClientIdentity
   */
  LegalName: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ClientIdentity
   */
  Referrer: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {Review}
   * @memberof ClientIdentity
   */
  Review: Review;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ClientIdentity
   */
  Status: ReviewStatus;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ClientIdentity
   */
  TaxId: ClientIdentityIncorporationNumber;
}
/**
 *
 * @export
 * @interface ClientIdentityAddresses
 */
export interface ClientIdentityAddresses {
  /**
   *
   * @type {LegalAddress}
   * @memberof ClientIdentityAddresses
   */
  Item1: LegalAddress;
  /**
   *
   * @type {Review}
   * @memberof ClientIdentityAddresses
   */
  Item2: Review;
  /**
   *
   * @type {string}
   * @memberof ClientIdentityAddresses
   */
  Item3: string;
}
/**
 *
 * @export
 * @interface ClientIdentityIncorporationDate
 */
export interface ClientIdentityIncorporationDate {
  /**
   *
   * @type {string}
   * @memberof ClientIdentityIncorporationDate
   */
  Item1?: string;
  /**
   *
   * @type {Review}
   * @memberof ClientIdentityIncorporationDate
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ClientIdentityIncorporationLoc
 */
export interface ClientIdentityIncorporationLoc {
  /**
   *
   * @type {string}
   * @memberof ClientIdentityIncorporationLoc
   */
  Item1?: string;
  /**
   *
   * @type {Review}
   * @memberof ClientIdentityIncorporationLoc
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ClientIdentityIncorporationNumber
 */
export interface ClientIdentityIncorporationNumber {
  /**
   *
   * @type {string}
   * @memberof ClientIdentityIncorporationNumber
   */
  Item1?: string;
  /**
   *
   * @type {Review}
   * @memberof ClientIdentityIncorporationNumber
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ClientPostBody
 */
export interface ClientPostBody {
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  AdminContactEmail: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  AdminContactFamilyName: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  AdminContactFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  AdminContactMobileNumber: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  BusinessNarrative: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  ClientProfileId?: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  CompanyName: string;
  /**
   *
   * @type {string}
   * @memberof ClientPostBody
   */
  Country: string;
}
/**
 * Client profile
 * @export
 * @interface ClientProfile
 */
export interface ClientProfile {
  /**
   *
   * @type {string}
   * @memberof ClientProfile
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof ClientProfile
   */
  CanCreateAccounts?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ClientProfile
   */
  CanCreateUsers?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientProfile
   */
  ChecklistId?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof ClientProfile
   */
  ComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {Array<ComplianceFrequency>}
   * @memberof ClientProfile
   */
  DefaultComplianceSchedule?: Array<ComplianceFrequency>;
  /**
   *
   * @type {string}
   * @memberof ClientProfile
   */
  Description?: string;
  /**
   *
   * @type {FeesProfile}
   * @memberof ClientProfile
   */
  Fees?: FeesProfile;
  /**
   *
   * @type {ContractLimits}
   * @memberof ClientProfile
   */
  FinancialLimits?: ContractLimits;
  /**
   *
   * @type {number}
   * @memberof ClientProfile
   */
  MaxAccounts?: number;
  /**
   *
   * @type {number}
   * @memberof ClientProfile
   */
  MaxUsers?: number;
  /**
   *
   * @type {string}
   * @memberof ClientProfile
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof ClientProfile
   */
  RiskSurveyId?: string;
  /**
   *
   * @type {Supplier}
   * @memberof ClientProfile
   */
  Supplier?: Supplier;
}
/**
 * Client profile updates
 * @export
 * @interface ClientProfilePostPutBody
 */
export interface ClientProfilePostPutBody {
  /**
   *
   * @type {boolean}
   * @memberof ClientProfilePostPutBody
   */
  CanCreateAccounts: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ClientProfilePostPutBody
   */
  CanCreateUsers: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientProfilePostPutBody
   */
  ChecklistId?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof ClientProfilePostPutBody
   */
  ComplianceLevel: ComplianceLevel;
  /**
   *
   * @type {Array<ComplianceFrequency>}
   * @memberof ClientProfilePostPutBody
   */
  DefaultComplianceSchedule?: Array<ComplianceFrequency>;
  /**
   *
   * @type {string}
   * @memberof ClientProfilePostPutBody
   */
  Description?: string;
  /**
   *
   * @type {FeesProfile}
   * @memberof ClientProfilePostPutBody
   */
  Fees?: FeesProfile;
  /**
   *
   * @type {ContractLimits}
   * @memberof ClientProfilePostPutBody
   */
  FinancialLimits: ContractLimits;
  /**
   *
   * @type {number}
   * @memberof ClientProfilePostPutBody
   */
  MaxAccounts?: number;
  /**
   *
   * @type {number}
   * @memberof ClientProfilePostPutBody
   */
  MaxUsers?: number;
  /**
   *
   * @type {string}
   * @memberof ClientProfilePostPutBody
   */
  Name: string;
  /**
   *
   * @type {string}
   * @memberof ClientProfilePostPutBody
   */
  RiskSurveyId?: string;
  /**
   *
   * @type {Supplier}
   * @memberof ClientProfilePostPutBody
   */
  Supplier: Supplier;
}
/**
 * Client profile short summary
 * @export
 * @interface ClientProfileShortSummary
 */
export interface ClientProfileShortSummary {
  /**
   *
   * @type {string}
   * @memberof ClientProfileShortSummary
   */
  Id?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof ClientProfileShortSummary
   */
  ComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {string}
   * @memberof ClientProfileShortSummary
   */
  Description?: string;
  /**
   *
   * @type {string}
   * @memberof ClientProfileShortSummary
   */
  Name?: string;
  /**
   *
   * @type {Supplier}
   * @memberof ClientProfileShortSummary
   */
  Supplier?: Supplier;
}
/**
 * Client profile
 * @export
 * @interface ClientProfileSummary
 */
export interface ClientProfileSummary {
  /**
   *
   * @type {string}
   * @memberof ClientProfileSummary
   */
  Id?: string;
  /**
   *
   * @type {Money}
   * @memberof ClientProfileSummary
   */
  AccountMaintFee?: Money;
  /**
   *
   * @type {boolean}
   * @memberof ClientProfileSummary
   */
  CanCreateAccounts?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ClientProfileSummary
   */
  CanCreateUsers?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientProfileSummary
   */
  Checklist?: string;
  /**
   *
   * @type {Money}
   * @memberof ClientProfileSummary
   */
  ClientMaintFee?: Money;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof ClientProfileSummary
   */
  ComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {string}
   * @memberof ClientProfileSummary
   */
  Description?: string;
  /**
   *
   * @type {number}
   * @memberof ClientProfileSummary
   */
  ExchangeRateDiscount?: number;
  /**
   *
   * @type {Money}
   * @memberof ClientProfileSummary
   */
  MinRevenueAmount?: Money;
  /**
   *
   * @type {number}
   * @memberof ClientProfileSummary
   */
  NegInterestMarkup?: number;
  /**
   *
   * @type {number}
   * @memberof ClientProfileSummary
   */
  MaxAccounts?: number;
  /**
   *
   * @type {Money}
   * @memberof ClientProfileSummary
   */
  MaxDailyOB?: Money;
  /**
   *
   * @type {Money}
   * @memberof ClientProfileSummary
   */
  MaxOnAccount?: Money;
  /**
   *
   * @type {Money}
   * @memberof ClientProfileSummary
   */
  MaxOBPayment?: Money;
  /**
   *
   * @type {number}
   * @memberof ClientProfileSummary
   */
  MaxUsers?: number;
  /**
   *
   * @type {string}
   * @memberof ClientProfileSummary
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof ClientProfileSummary
   */
  RiskSurvey?: string;
  /**
   *
   * @type {Array<ComplianceFrequency>}
   * @memberof ClientProfileSummary
   */
  Schedule?: Array<ComplianceFrequency>;
  /**
   *
   * @type {Supplier}
   * @memberof ClientProfileSummary
   */
  Supplier?: Supplier;
}
/**
 *
 * @export
 * @interface ClientRegistrationPostBody
 */
export interface ClientRegistrationPostBody {
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationPostBody
   */
  AdminContactEmail: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationPostBody
   */
  AdminContactFamilyName: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationPostBody
   */
  AdminContactFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationPostBody
   */
  AdminContactMobileNumber: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationPostBody
   */
  BusinessNarrative: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationPostBody
   */
  CompanyName: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationPostBody
   */
  Country?: string;
  /**
   *
   * @type {Supplier}
   * @memberof ClientRegistrationPostBody
   */
  Supplier?: Supplier;
}
/**
 *
 * @export
 * @interface ClientRegistrationSummary
 */
export interface ClientRegistrationSummary {
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  AdminContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  AdminContactFamilyName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  AdminContactFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  AdminContactId?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  AdminContactMobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  AuthyId?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  ClientName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientRegistrationSummary
   */
  ClientReviewId?: string;
}
/**
 * Client review
 * @export
 * @interface ClientReview
 */
export interface ClientReview {
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  id: string;
  /**
   *
   * @type {ChecklistReview}
   * @memberof ClientReview
   */
  Checklist?: ChecklistReview;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  ClientProfileId?: string;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  ClientProfileName?: string;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ClientReview
   */
  Comments?: Array<Comment>;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  Ended?: string;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  EndedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClientReview
   */
  IsClosed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ClientReview
   */
  IsCurrent?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  LastUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  LastUpdatedBy?: string;
  /**
   *
   * @type {OrganizationReview}
   * @memberof ClientReview
   */
  OrgReview?: OrganizationReview;
  /**
   *
   * @type {RiskReview}
   * @memberof ClientReview
   */
  RiskReview?: RiskReview;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  Started?: string;
  /**
   *
   * @type {string}
   * @memberof ClientReview
   */
  StartedBy?: string;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ClientReview
   */
  Status?: ReviewStatus;
}
/**
 * Client short summary
 * @export
 * @interface ClientShortSummary
 */
export interface ClientShortSummary {
  /**
   *
   * @type {string}
   * @memberof ClientShortSummary
   */
  Id?: string;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ClientShortSummary
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof ClientShortSummary
   */
  InformalName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientShortSummary
   */
  LegalAddressCountry?: string;
  /**
   *
   * @type {string}
   * @memberof ClientShortSummary
   */
  LegalName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientShortSummary
   */
  SIC?: string;
  /**
   *
   * @type {Supplier}
   * @memberof ClientShortSummary
   */
  Supplier?: Supplier;
  /**
   *
   * @type {ActiveType}
   * @memberof ClientShortSummary
   */
  Status?: ActiveType;
}
/**
 * Client summary info.
 * @export
 * @interface ClientSummary
 */
export interface ClientSummary {
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  Id: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  ClientProfileName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  ClientProfileComplianceLevel?: string;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ClientSummary
   */
  ComplianceStatus: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  IncorporationLoc?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  IncorporationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  IncorporationDate?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  InformalName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClientSummary
   */
  IsLocked: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  LastKycReview?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  LegalAddressCountry?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  LegalName: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  Referrer?: string;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ClientSummary
   */
  ReviewStatus: ReviewStatus;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  SIC?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  StartDate?: string;
  /**
   *
   * @type {Supplier}
   * @memberof ClientSummary
   */
  Supplier: Supplier;
  /**
   *
   * @type {ActiveType}
   * @memberof ClientSummary
   */
  Status: ActiveType;
  /**
   *
   * @type {string}
   * @memberof ClientSummary
   */
  WebSite?: string;
}
/**
 *
 * @export
 * @interface ClientTerminationAccountsSummary
 */
export interface ClientTerminationAccountsSummary {
  /**
   *
   * @type {number}
   * @memberof ClientTerminationAccountsSummary
   */
  SuccessfullyTerminated: number;
  /**
   *
   * @type {number}
   * @memberof ClientTerminationAccountsSummary
   */
  FailedToTerminate: number;
  /**
   *
   * @type {number}
   * @memberof ClientTerminationAccountsSummary
   */
  SuccessfullySuspended: number;
  /**
   *
   * @type {number}
   * @memberof ClientTerminationAccountsSummary
   */
  FailedToSuspend: number;
  /**
   *
   * @type {number}
   * @memberof ClientTerminationAccountsSummary
   */
  TotalNonZeroBalanceAccounts: number;
}
/**
 *
 * @export
 * @interface ClientTerminationContactsSummary
 */
export interface ClientTerminationContactsSummary {
  /**
   *
   * @type {number}
   * @memberof ClientTerminationContactsSummary
   */
  SuccessfullyTerminated: number;
  /**
   *
   * @type {number}
   * @memberof ClientTerminationContactsSummary
   */
  FailedToTerminate: number;
}
/**
 *
 * @export
 * @interface ClientTerminationSummary
 */
export interface ClientTerminationSummary {
  /**
   *
   * @type {ActiveType}
   * @memberof ClientTerminationSummary
   */
  InitialStatus: ActiveType;
  /**
   *
   * @type {ActiveType}
   * @memberof ClientTerminationSummary
   */
  Status: ActiveType;
  /**
   *
   * @type {ClientTerminationAccountsSummary}
   * @memberof ClientTerminationSummary
   */
  Accounts: ClientTerminationAccountsSummary;
  /**
   *
   * @type {ClientTerminationContactsSummary}
   * @memberof ClientTerminationSummary
   */
  Contacts: ClientTerminationContactsSummary;
}
/**
 * Code values
 * @export
 * @interface Code
 */
export interface Code {
  /**
   *
   * @type {string}
   * @memberof Code
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof Code
   */
  Name?: string;
}
/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  Comment: string;
  /**
   *
   * @type {Author}
   * @memberof Comment
   */
  Author?: Author;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  Authored: string;
  /**
   *
   * @type {boolean}
   * @memberof Comment
   */
  IsInternal?: boolean;
}
/**
 * compliance check frequency
 * @export
 * @enum {string}
 */

export const ComplianceFrequency = {
  Monthly: 'Monthly',
  Quarterly: 'Quarterly',
  Yearly: 'Yearly',
  OnChange: 'OnChange',
  OnNew: 'OnNew',
} as const;

export type ComplianceFrequency =
  typeof ComplianceFrequency[keyof typeof ComplianceFrequency];

/**
 * compliance level
 * @export
 * @enum {string}
 */

export const ComplianceLevel = {
  Trial: 'Trial',
  Level0: 'Level0',
  Level1: 'Level1',
  Level2: 'Level2',
  Level3: 'Level3',
  Level4: 'Level4',
  Level5: 'Level5',
} as const;

export type ComplianceLevel =
  typeof ComplianceLevel[keyof typeof ComplianceLevel];

/**
 *
 * @export
 * @enum {string}
 */

export const ComplianceStatus = {
  Compliant: 'Compliant',
  NonCompliant: 'NonCompliant',
  NeedsReview: 'NeedsReview',
  UnderReview: 'UnderReview',
} as const;

export type ComplianceStatus =
  typeof ComplianceStatus[keyof typeof ComplianceStatus];

/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  id: string;
  /**
   *
   * @type {Array<ContactAddress>}
   * @memberof Contact
   */
  Addresses?: Array<ContactAddress>;
  /**
   *
   * @type {Array<Application>}
   * @memberof Contact
   */
  Applications?: Array<Application>;
  /**
   *
   * @type {Array<Authority>}
   * @memberof Contact
   */
  Authorities?: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  Dob?: string;
  /**
   *
   * @type {Array<ContactEmailAddress>}
   * @memberof Contact
   */
  EmailAddresses?: Array<ContactEmailAddress>;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  FamilyName?: string;
  /**
   *
   * @type {ContactLimits}
   * @memberof Contact
   */
  FinancialLimits?: ContactLimits;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  FirstName?: string;
  /**
   *
   * @type {Array<PersonalId>}
   * @memberof Contact
   */
  Identifications?: Array<PersonalId>;
  /**
   *
   * @type {LoginDetails}
   * @memberof Contact
   */
  LoginDetails?: LoginDetails;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  Nationality?: string;
  /**
   *
   * @type {number}
   * @memberof Contact
   */
  OwnershipPercentage?: number;
  /**
   *
   * @type {boolean}
   * @memberof Contact
   */
  PEP?: boolean;
  /**
   *
   * @type {Array<ContactPhoneNumber>}
   * @memberof Contact
   */
  PhoneNumbers?: Array<ContactPhoneNumber>;
  /**
   *
   * @type {Array<Role>}
   * @memberof Contact
   */
  Roles?: Array<Role>;
  /**
   *
   * @type {ActiveType}
   * @memberof Contact
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface ContactAddress
 */
export interface ContactAddress {
  /**
   *
   * @type {string}
   * @memberof ContactAddress
   */
  Street1: string;
  /**
   *
   * @type {string}
   * @memberof ContactAddress
   */
  Street2?: string;
  /**
   *
   * @type {string}
   * @memberof ContactAddress
   */
  Street3?: string;
  /**
   *
   * @type {string}
   * @memberof ContactAddress
   */
  City: string;
  /**
   *
   * @type {string}
   * @memberof ContactAddress
   */
  Region?: string;
  /**
   *
   * @type {string}
   * @memberof ContactAddress
   */
  Country: string;
  /**
   *
   * @type {string}
   * @memberof ContactAddress
   */
  Postal?: string;
  /**
   *
   * @type {ContactAddressType}
   * @memberof ContactAddress
   */
  Type: ContactAddressType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContactAddressType = {
  Home: 'Home',
  Work: 'Work',
} as const;

export type ContactAddressType =
  typeof ContactAddressType[keyof typeof ContactAddressType];

/**
 *
 * @export
 * @interface ContactEmailAddress
 */
export interface ContactEmailAddress {
  /**
   *
   * @type {string}
   * @memberof ContactEmailAddress
   */
  EmailAddress: string;
  /**
   *
   * @type {ContactEmailAddressType}
   * @memberof ContactEmailAddress
   */
  Type: ContactEmailAddressType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContactEmailAddressType = {
  Work: 'Work',
  Personal: 'Personal',
} as const;

export type ContactEmailAddressType =
  typeof ContactEmailAddressType[keyof typeof ContactEmailAddressType];

/**
 *
 * @export
 * @interface ContactLimits
 */
export interface ContactLimits {
  /**
   *
   * @type {Money}
   * @memberof ContactLimits
   */
  DailyOB?: Money;
  /**
   *
   * @type {Money}
   * @memberof ContactLimits
   */
  OBPayment?: Money;
  /**
   *
   * @type {Money}
   * @memberof ContactLimits
   */
  OBPaymentApproval?: Money;
}
/**
 *
 * @export
 * @interface ContactPhoneNumber
 */
export interface ContactPhoneNumber {
  /**
   *
   * @type {string}
   * @memberof ContactPhoneNumber
   */
  PhoneNumber: string;
  /**
   *
   * @type {ContactPhoneNumberType}
   * @memberof ContactPhoneNumber
   */
  Type: ContactPhoneNumberType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContactPhoneNumberType = {
  Fax: 'Fax',
  Home: 'Home',
  Mobile: 'Mobile',
  Urgent: 'Urgent',
  Work: 'Work',
} as const;

export type ContactPhoneNumberType =
  typeof ContactPhoneNumberType[keyof typeof ContactPhoneNumberType];

/**
 *
 * @export
 * @interface ContactPostBody
 */
export interface ContactPostBody {
  /**
   *
   * @type {Array<ContactAddress>}
   * @memberof ContactPostBody
   */
  Addresses?: Array<ContactAddress>;
  /**
   *
   * @type {Array<Authority>}
   * @memberof ContactPostBody
   */
  Authorities?: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof ContactPostBody
   */
  Dob?: string;
  /**
   *
   * @type {Array<ContactEmailAddress>}
   * @memberof ContactPostBody
   */
  EmailAddresses?: Array<ContactEmailAddress>;
  /**
   *
   * @type {string}
   * @memberof ContactPostBody
   */
  FamilyName: string;
  /**
   *
   * @type {ContactLimits}
   * @memberof ContactPostBody
   */
  FinancialLimits?: ContactLimits;
  /**
   *
   * @type {string}
   * @memberof ContactPostBody
   */
  FirstName: string;
  /**
   *
   * @type {Array<PersonalId>}
   * @memberof ContactPostBody
   */
  Identifications?: Array<PersonalId>;
  /**
   *
   * @type {ContactPostBodyLoginDetails}
   * @memberof ContactPostBody
   */
  LoginDetails?: ContactPostBodyLoginDetails;
  /**
   *
   * @type {string}
   * @memberof ContactPostBody
   */
  Nationality?: string;
  /**
   *
   * @type {number}
   * @memberof ContactPostBody
   */
  OwnershipPercentage?: number;
  /**
   *
   * @type {boolean}
   * @memberof ContactPostBody
   */
  PEP?: boolean;
  /**
   *
   * @type {Array<ContactPhoneNumber>}
   * @memberof ContactPostBody
   */
  PhoneNumbers?: Array<ContactPhoneNumber>;
  /**
   *
   * @type {Array<Role>}
   * @memberof ContactPostBody
   */
  Roles?: Array<Role>;
  /**
   *
   * @type {ActiveType}
   * @memberof ContactPostBody
   */
  Status: ActiveType;
}
/**
 *
 * @export
 * @interface ContactPostBodyLoginDetails
 */
export interface ContactPostBodyLoginDetails {
  /**
   *
   * @type {string}
   * @memberof ContactPostBodyLoginDetails
   */
  Email: string;
  /**
   *
   * @type {string}
   * @memberof ContactPostBodyLoginDetails
   */
  MobileNumber: string;
  /**
   *
   * @type {string}
   * @memberof ContactPostBodyLoginDetails
   */
  Password?: string;
}
/**
 *
 * @export
 * @interface ContactPutBody
 */
export interface ContactPutBody {
  /**
   *
   * @type {Array<ContactAddress>}
   * @memberof ContactPutBody
   */
  Addresses?: Array<ContactAddress>;
  /**
   *
   * @type {Array<Authority>}
   * @memberof ContactPutBody
   */
  Authorities?: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof ContactPutBody
   */
  Dob?: string;
  /**
   *
   * @type {Array<ContactEmailAddress>}
   * @memberof ContactPutBody
   */
  EmailAddresses?: Array<ContactEmailAddress>;
  /**
   *
   * @type {string}
   * @memberof ContactPutBody
   */
  FamilyName: string;
  /**
   *
   * @type {ContactLimits}
   * @memberof ContactPutBody
   */
  FinancialLimits?: ContactLimits;
  /**
   *
   * @type {string}
   * @memberof ContactPutBody
   */
  FirstName: string;
  /**
   *
   * @type {Array<PersonalId>}
   * @memberof ContactPutBody
   */
  Identifications?: Array<PersonalId>;
  /**
   *
   * @type {ContactPostBodyLoginDetails}
   * @memberof ContactPutBody
   */
  LoginDetails?: ContactPostBodyLoginDetails;
  /**
   *
   * @type {string}
   * @memberof ContactPutBody
   */
  Nationality?: string;
  /**
   *
   * @type {number}
   * @memberof ContactPutBody
   */
  OwnershipPercentage?: number;
  /**
   *
   * @type {boolean}
   * @memberof ContactPutBody
   */
  PEP?: boolean;
  /**
   *
   * @type {Array<ContactPhoneNumber>}
   * @memberof ContactPutBody
   */
  PhoneNumbers?: Array<ContactPhoneNumber>;
  /**
   *
   * @type {Array<Role>}
   * @memberof ContactPutBody
   */
  Roles?: Array<Role>;
}
/**
 *
 * @export
 * @interface ContactShortSummary
 */
export interface ContactShortSummary {
  /**
   *
   * @type {string}
   * @memberof ContactShortSummary
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof ContactShortSummary
   */
  Dob?: string;
  /**
   *
   * @type {string}
   * @memberof ContactShortSummary
   */
  FamilyName?: string;
  /**
   *
   * @type {string}
   * @memberof ContactShortSummary
   */
  FirstName?: string;
  /**
   *
   * @type {string}
   * @memberof ContactShortSummary
   */
  Nationality?: string;
  /**
   *
   * @type {boolean}
   * @memberof ContactShortSummary
   */
  PEP?: boolean;
  /**
   *
   * @type {ActiveType}
   * @memberof ContactShortSummary
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface ContactSummary
 */
export interface ContactSummary {
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  AddressCity?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  AddressCountry?: string;
  /**
   *
   * @type {Array<Authority>}
   * @memberof ContactSummary
   */
  Authorities?: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  AuthyId?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  ClientName?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  Dob?: string;
  /**
   *
   * @type {Array<ContactEmailAddress>}
   * @memberof ContactSummary
   */
  Emails?: Array<ContactEmailAddress>;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  FamilyName?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  FirstName?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  LoginEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  LoginMobileNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSummary
   */
  Nationality?: string;
  /**
   *
   * @type {number}
   * @memberof ContactSummary
   */
  OwnershipPercentage?: number;
  /**
   *
   * @type {boolean}
   * @memberof ContactSummary
   */
  PEP?: boolean;
  /**
   *
   * @type {Array<Role>}
   * @memberof ContactSummary
   */
  Roles?: Array<Role>;
  /**
   *
   * @type {ActiveType}
   * @memberof ContactSummary
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface Contract
 */
export interface Contract {
  /**
   *
   * @type {boolean}
   * @memberof Contract
   */
  AllowAccountCreation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Contract
   */
  AllowUserCreation?: boolean;
  /**
   *
   * @type {string}
   * @memberof Contract
   */
  EndDate?: string;
  /**
   *
   * @type {FeeStructure}
   * @memberof Contract
   */
  FeeStructure?: FeeStructure;
  /**
   *
   * @type {ContractLimits}
   * @memberof Contract
   */
  FinancialLimits?: ContractLimits;
  /**
   *
   * @type {number}
   * @memberof Contract
   */
  MaxAccounts?: number;
  /**
   *
   * @type {number}
   * @memberof Contract
   */
  MaxUsers?: number;
  /**
   *
   * @type {string}
   * @memberof Contract
   */
  StartDate?: string;
  /**
   *
   * @type {Supplier}
   * @memberof Contract
   */
  Supplier?: Supplier;
}
/**
 *
 * @export
 * @interface ContractLimits
 */
export interface ContractLimits {
  /**
   *
   * @type {Array<ClearingSystem>}
   * @memberof ContractLimits
   */
  ClearingSystems: Array<ClearingSystem>;
  /**
   *
   * @type {Money}
   * @memberof ContractLimits
   */
  DailyOB: Money;
  /**
   *
   * @type {Money}
   * @memberof ContractLimits
   */
  OBPayment: Money;
  /**
   *
   * @type {Money}
   * @memberof ContractLimits
   */
  OnAccount: Money;
}
/**
 *
 * @export
 * @interface ContractPutBody
 */
export interface ContractPutBody {
  /**
   *
   * @type {boolean}
   * @memberof ContractPutBody
   */
  AllowAccountCreation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContractPutBody
   */
  AllowUserCreation?: boolean;
  /**
   *
   * @type {string}
   * @memberof ContractPutBody
   */
  EndDate?: string;
  /**
   *
   * @type {FeeStructure}
   * @memberof ContractPutBody
   */
  FeeStructure?: FeeStructure;
  /**
   *
   * @type {ContractLimits}
   * @memberof ContractPutBody
   */
  FinancialLimits?: ContractLimits;
  /**
   *
   * @type {number}
   * @memberof ContractPutBody
   */
  MaxAccounts?: number;
  /**
   *
   * @type {number}
   * @memberof ContractPutBody
   */
  MaxUsers?: number;
  /**
   *
   * @type {string}
   * @memberof ContractPutBody
   */
  StartDate?: string;
  /**
   *
   * @type {Supplier}
   * @memberof ContractPutBody
   */
  Supplier?: Supplier;
}
/**
 *
 * @export
 * @interface CorporateId
 */
export interface CorporateId {
  /**
   *
   * @type {string}
   * @memberof CorporateId
   */
  Number?: string;
  /**
   *
   * @type {string}
   * @memberof CorporateId
   */
  ExpiryDate?: string;
  /**
   *
   * @type {string}
   * @memberof CorporateId
   */
  IssuedDate?: string;
  /**
   *
   * @type {string}
   * @memberof CorporateId
   */
  IssuingCountry?: string;
  /**
   *
   * @type {string}
   * @memberof CorporateId
   */
  IssuingRegion?: string;
  /**
   *
   * @type {CorporateRegistry}
   * @memberof CorporateId
   */
  Registry?: CorporateRegistry;
  /**
   *
   * @type {Attachment}
   * @memberof CorporateId
   */
  SupportingDocument?: Attachment;
  /**
   *
   * @type {CorporateIdType}
   * @memberof CorporateId
   */
  Type: CorporateIdType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const CorporateIdType = {
  BankStatement: 'BankStatement',
  Contract: 'Contract',
  CorporateRegistration: 'CorporateRegistration',
  IncorporationNumber: 'IncorporationNumber',
  ListOfAuthorizedSignatories: 'ListOfAuthorizedSignatories',
  ListOfDirectors: 'ListOfDirectors',
  ListOfShareholders: 'ListOfShareholders',
  PowerOfAttorney: 'PowerOfAttorney',
  ProcessorLetter: 'ProcessorLetter',
  SanctionsCheck: 'SanctionsCheck',
  ScreenCapture: 'ScreenCapture',
  TaxReturn: 'TaxReturn',
  UtilityStatement: 'UtilityStatement',
} as const;

export type CorporateIdType =
  typeof CorporateIdType[keyof typeof CorporateIdType];

/**
 *
 * @export
 * @enum {string}
 */

export const CorporateRegistry = {
  CompaniesHouse: 'CompaniesHouse',
} as const;

export type CorporateRegistry =
  typeof CorporateRegistry[keyof typeof CorporateRegistry];

/**
 *
 * @export
 * @interface CountryDetails
 */
export interface CountryDetails {
  /**
   *
   * @type {string}
   * @memberof CountryDetails
   */
  Code?: string;
  /**
   *
   * @type {number}
   * @memberof CountryDetails
   */
  Number?: number;
  /**
   *
   * @type {boolean}
   * @memberof CountryDetails
   */
  Sepa?: boolean;
  /**
   *
   * @type {string}
   * @memberof CountryDetails
   */
  Name?: string;
}
/**
 *
 * @export
 * @interface CurrencyDetails
 */
export interface CurrencyDetails {
  /**
   *
   * @type {string}
   * @memberof CurrencyDetails
   */
  Currency?: string;
  /**
   *
   * @type {number}
   * @memberof CurrencyDetails
   */
  Number?: number;
  /**
   *
   * @type {string}
   * @memberof CurrencyDetails
   */
  Name?: string;
  /**
   *
   * @type {number}
   * @memberof CurrencyDetails
   */
  Decimals?: number;
  /**
   *
   * @type {number}
   * @memberof CurrencyDetails
   */
  BuySpread?: number;
  /**
   *
   * @type {number}
   * @memberof CurrencyDetails
   */
  SellSpread?: number;
}
/**
 *
 * @export
 * @interface DeregisteredApplicationResponseBody
 */
export interface DeregisteredApplicationResponseBody {
  /**
   *
   * @type {string}
   * @memberof DeregisteredApplicationResponseBody
   */
  Id?: string;
  /**
   *
   * @type {Array<Authority>}
   * @memberof DeregisteredApplicationResponseBody
   */
  Authorities?: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof DeregisteredApplicationResponseBody
   */
  Email?: string;
  /**
   *
   * @type {ContactLimits}
   * @memberof DeregisteredApplicationResponseBody
   */
  FinancialLimits?: ContactLimits;
  /**
   *
   * @type {string}
   * @memberof DeregisteredApplicationResponseBody
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof DeregisteredApplicationResponseBody
   */
  ObfuscatedApiKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof DeregisteredApplicationResponseBody
   */
  RequireAuthentication?: boolean;
  /**
   *
   * @type {ActiveType}
   * @memberof DeregisteredApplicationResponseBody
   */
  Status?: ActiveType;
  /**
   *
   * @type {string}
   * @memberof DeregisteredApplicationResponseBody
   */
  Timestamp?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DocumentType = {
  BankStatement: 'BankStatement',
  BirthCertificate: 'BirthCertificate',
  Citizenship: 'Citizenship',
  Contract: 'Contract',
  CorporateRegistration: 'CorporateRegistration',
  DriversLicense: 'DriversLicense',
  IdentityCard: 'IdentityCard',
  IncorporationNumber: 'IncorporationNumber',
  ListOfAuthorizedSignatories: 'ListOfAuthorizedSignatories',
  ListOfDirectors: 'ListOfDirectors',
  ListOfShareholders: 'ListOfShareholders',
  Passport: 'Passport',
  PermanentResidency: 'PermanentResidency',
  PowerOfAttorney: 'PowerOfAttorney',
  ProcessorLetter: 'ProcessorLetter',
  SanctionsCheck: 'SanctionsCheck',
  ScreenCapture: 'ScreenCapture',
  SocialInsurance: 'SocialInsurance',
  TaxReturn: 'TaxReturn',
  TemporaryResidency: 'TemporaryResidency',
  UtilityStatement: 'UtilityStatement',
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];

/**
 *
 * @export
 * @enum {string}
 */

export const EmployeeNumberCategory = {
  Between1and10: 'Between1and10',
  Between11and100: 'Between11and100',
  Between101and1000: 'Between101and1000',
  Over1001: 'Over1001',
} as const;

export type EmployeeNumberCategory =
  typeof EmployeeNumberCategory[keyof typeof EmployeeNumberCategory];

/**
 *
 * @export
 * @interface Entry
 */
export interface Entry {
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  Account?: string;
  /**
   *
   * @type {Money}
   * @memberof Entry
   */
  AccountAmount?: Money;
  /**
   *
   * @type {AccountRole}
   * @memberof Entry
   */
  AccountRole?: AccountRole;
  /**
   *
   * @type {Money}
   * @memberof Entry
   */
  Amount?: Money;
  /**
   *
   * @type {number}
   * @memberof Entry
   */
  Exchange?: number;
  /**
   *
   * @type {EntryRole}
   * @memberof Entry
   */
  Role?: EntryRole;
  /**
   *
   * @type {EntrySign}
   * @memberof Entry
   */
  Sign?: EntrySign;
}
/**
 *
 * @export
 * @enum {string}
 */

export const EntryRole = {
  Fee: 'Fee',
  Principle: 'Principle',
} as const;

export type EntryRole = typeof EntryRole[keyof typeof EntryRole];

/**
 *
 * @export
 * @enum {string}
 */

export const EntrySign = {
  Credit: 'Credit',
  Debit: 'Debit',
} as const;

export type EntrySign = typeof EntrySign[keyof typeof EntrySign];

/**
 *
 * @export
 * @interface Event
 */
export interface Event {
  /**
   *
   * @type {string}
   * @memberof Event
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Data?: string;
  /**
   *
   * @type {Array<Entry>}
   * @memberof Event
   */
  Entries?: Array<Entry>;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  OperationId?: string;
  /**
   *
   * @type {string}
   * @memberof Event
   */
  Timestamp?: string;
  /**
   *
   * @type {EventType}
   * @memberof Event
   */
  Type?: EventType;
  /**
   *
   * @type {EventSubtype}
   * @memberof Event
   */
  Subtype?: EventSubtype;
}
/**
 *
 * @export
 * @enum {string}
 */

export const EventSubtype = {
  InvalidOutboundType: 'InvalidOutboundType',
  InactiveOrganization: 'InactiveOrganization',
  InactiveFromAccount: 'InactiveFromAccount',
  InactiveToAccount: 'InactiveToAccount',
  DisallowedFromAccount: 'DisallowedFromAccount',
  DisallowedToAccount: 'DisallowedToAccount',
  NoSuchFromAccount: 'NoSuchFromAccount',
  NoSuchToAccount: 'NoSuchToAccount',
  NSFFromAccount: 'NSFFromAccount',
  NSFFeeAccount: 'NSFFeeAccount',
  InvalidDescription: 'InvalidDescription',
  InvalidReference: 'InvalidReference',
  SameToAndFromAccount: 'SameToAndFromAccount',
  InvalidToAccount: 'InvalidToAccount',
  TransferDisallowed: 'TransferDisallowed',
  NoBicFoundForToAccount: 'NoBicFoundForToAccount',
  UnWhitelistedToAccount: 'UnWhitelistedToAccount',
  InvalidAmount: 'InvalidAmount',
  MaxOnAccountExceeded: 'MaxOnAccountExceeded',
  MaxOBPaymentAmountExceeded: 'MaxOBPaymentAmountExceeded',
  MaxOBDailyLimitExceeded: 'MaxOBDailyLimitExceeded',
  NoClearingSystemResolved: 'NoClearingSystemResolved',
  NoSenderAddress: 'NoSenderAddress',
  ClearingSystemDisallowed: 'ClearingSystemDisallowed',
  InvalidToBeneficiary: 'InvalidToBeneficiary',
  AccountBlocked: 'AccountBlocked',
  TooManyRetries: 'TooManyRetries',
  UpstreamError: 'UpstreamError',
  ManualReversal: 'ManualReversal',
  NotApproved: 'NotApproved',
} as const;

export type EventSubtype = typeof EventSubtype[keyof typeof EventSubtype];

/**
 *
 * @export
 * @enum {string}
 */

export const EventType = {
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Submitted: 'Submitted',
  Executed: 'Executed',
  Reversed: 'Reversed',
  Stopped: 'Stopped',
  Held: 'Held',
} as const;

export type EventType = typeof EventType[keyof typeof EventType];

/**
 *
 * @export
 * @interface ExportResponse
 */
export interface ExportResponse {
  /**
   *
   * @type {string}
   * @memberof ExportResponse
   */
  ReportId: string;
}
/**
 *
 * @export
 * @interface Fee
 */
export interface Fee {
  /**
   *
   * @type {number}
   * @memberof Fee
   */
  Rate?: number;
  /**
   *
   * @type {Money}
   * @memberof Fee
   */
  MinFee?: Money;
  /**
   *
   * @type {Money}
   * @memberof Fee
   */
  MaxFee?: Money;
  /**
   *
   * @type {string}
   * @memberof Fee
   */
  Currency?: string;
}
/**
 *
 * @export
 * @interface FeeStructure
 */
export interface FeeStructure {
  /**
   *
   * @type {ChargeOptionalSource}
   * @memberof FeeStructure
   */
  AccountMaintFee?: ChargeOptionalSource;
  /**
   *
   * @type {ChargeAndSource}
   * @memberof FeeStructure
   */
  ClientMaintFee?: ChargeAndSource;
  /**
   *
   * @type {TransactionalFees}
   * @memberof FeeStructure
   */
  TransactionalFees?: TransactionalFees;
  /**
   *
   * @type {RateOptionalSource}
   * @memberof FeeStructure
   */
  NegInterestMarkup?: RateOptionalSource;
  /**
   *
   * @type {number}
   * @memberof FeeStructure
   */
  ExchangeRateDiscount?: number;
}
/**
 *
 * @export
 * @interface FeesProfile
 */
export interface FeesProfile {
  /**
   *
   * @type {Money}
   * @memberof FeesProfile
   */
  AccountMaintFee?: Money;
  /**
   *
   * @type {Money}
   * @memberof FeesProfile
   */
  ClientMaintFee?: Money;
  /**
   *
   * @type {number}
   * @memberof FeesProfile
   */
  ExchangeRateDiscount?: number;
  /**
   *
   * @type {Money}
   * @memberof FeesProfile
   */
  MinRevenueAmount?: Money;
  /**
   *
   * @type {number}
   * @memberof FeesProfile
   */
  NegInterestMarkup?: number;
  /**
   *
   * @type {Array<PriceLine>}
   * @memberof FeesProfile
   */
  TransactionalFeeTable?: Array<PriceLine>;
}
/**
 * @type FilterParameter
 * @export
 */
export type FilterParameter = Array<string> | string;

/**
 *
 * @export
 * @interface GovernmentId
 */
export interface GovernmentId {
  /**
   *
   * @type {string}
   * @memberof GovernmentId
   */
  Id: string;
  /**
   *
   * @type {string}
   * @memberof GovernmentId
   */
  ExpiryDate?: string;
  /**
   *
   * @type {string}
   * @memberof GovernmentId
   */
  IssuedDate?: string;
  /**
   *
   * @type {string}
   * @memberof GovernmentId
   */
  Issuer: string;
  /**
   *
   * @type {string}
   * @memberof GovernmentId
   */
  IssuingCountry?: string;
  /**
   *
   * @type {Attachment}
   * @memberof GovernmentId
   */
  SupportingDocument?: Attachment;
  /**
   *
   * @type {DocumentType}
   * @memberof GovernmentId
   */
  Type: DocumentType;
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {Array<string>}
   * @memberof InlineObject2
   */
  ids?: Array<string>;
}
/**
 *
 * @export
 * @interface LegalAddress
 */
export interface LegalAddress {
  /**
   *
   * @type {string}
   * @memberof LegalAddress
   */
  City?: string;
  /**
   *
   * @type {string}
   * @memberof LegalAddress
   */
  Country?: string;
  /**
   *
   * @type {string}
   * @memberof LegalAddress
   */
  Postal?: string;
  /**
   *
   * @type {string}
   * @memberof LegalAddress
   */
  Region?: string;
  /**
   *
   * @type {string}
   * @memberof LegalAddress
   */
  Street1?: string;
  /**
   *
   * @type {string}
   * @memberof LegalAddress
   */
  Street2?: string;
  /**
   *
   * @type {string}
   * @memberof LegalAddress
   */
  Street3?: string;
  /**
   *
   * @type {LegalAddressType}
   * @memberof LegalAddress
   */
  Type?: LegalAddressType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const LegalAddressType = {
  Legal: 'Legal',
  Mailing: 'Mailing',
  PrinciplePlaceOfBusiness: 'PrinciplePlaceOfBusiness',
} as const;

export type LegalAddressType =
  typeof LegalAddressType[keyof typeof LegalAddressType];

/**
 *
 * @export
 * @interface LegalEntity
 */
export interface LegalEntity {
  /**
   *
   * @type {Array<LegalAddress>}
   * @memberof LegalEntity
   */
  Addresses?: Array<LegalAddress>;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  BusinessNarrative?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  IncorporationDate?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  IncorporationLoc?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  IncorporationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  InformalName?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  LastKycReview?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  LegalName?: string;
  /**
   *
   * @type {EmployeeNumberCategory}
   * @memberof LegalEntity
   */
  NumberOfEmployees?: EmployeeNumberCategory;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  Regulator?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  SIC?: string;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  TaxId?: string;
  /**
   *
   * @type {Array<TradingName>}
   * @memberof LegalEntity
   */
  TradingAs?: Array<TradingName>;
  /**
   *
   * @type {string}
   * @memberof LegalEntity
   */
  WebSite?: string;
}
/**
 *
 * @export
 * @interface LoginDetails
 */
export interface LoginDetails {
  /**
   *
   * @type {string}
   * @memberof LoginDetails
   */
  AuthyId?: string;
  /**
   *
   * @type {string}
   * @memberof LoginDetails
   */
  Email?: string;
  /**
   *
   * @type {string}
   * @memberof LoginDetails
   */
  MobileNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof LoginDetails
   */
  MustChangePassword?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LoginDetails
   */
  Notified?: boolean;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  Reason?: string;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  Details?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ModelError
   */
  Errors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  RequestBody?: string;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  Path?: string;
}
/**
 *
 * @export
 * @interface Money
 */
export interface Money {
  /**
   *
   * @type {string}
   * @memberof Money
   */
  Currency?: string;
  /**
   *
   * @type {number}
   * @memberof Money
   */
  Value?: number;
}
/**
 *
 * @export
 * @interface NotificationBroadcastRequest
 */
export interface NotificationBroadcastRequest {
  /**
   *
   * @type {string}
   * @memberof NotificationBroadcastRequest
   */
  Title: string;
  /**
   *
   * @type {string}
   * @memberof NotificationBroadcastRequest
   */
  Details: string;
  /**
   *
   * @type {NotificationType}
   * @memberof NotificationBroadcastRequest
   */
  Type: NotificationType;
  /**
   *
   * @type {Array<string>}
   * @memberof NotificationBroadcastRequest
   */
  Recipients: Array<string>;
}
/**
 *
 * @export
 * @interface NotificationDeleteResponse
 */
export interface NotificationDeleteResponse {
  /**
   *
   * @type {string}
   * @memberof NotificationDeleteResponse
   */
  Reason?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDeleteResponse
   */
  Details?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDeleteResponse
   */
  RequestBody?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationDeleteResponse
   */
  Path?: string;
}
/**
 *
 * @export
 * @interface NotificationPostPutRequest
 */
export interface NotificationPostPutRequest {
  /**
   *
   * @type {string}
   * @memberof NotificationPostPutRequest
   */
  Title: string;
  /**
   *
   * @type {string}
   * @memberof NotificationPostPutRequest
   */
  Details: string;
  /**
   *
   * @type {NotificationType}
   * @memberof NotificationPostPutRequest
   */
  Type: NotificationType;
  /**
   *
   * @type {string}
   * @memberof NotificationPostPutRequest
   */
  DatePublished?: string;
}
/**
 *
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
  /**
   *
   * @type {string}
   * @memberof NotificationResponse
   */
  id: string;
  /**
   *
   * @type {Behaviours}
   * @memberof NotificationResponse
   */
  BehavioursAsMap?: Behaviours;
  /**
   *
   * @type {string}
   * @memberof NotificationResponse
   */
  Title: string;
  /**
   *
   * @type {string}
   * @memberof NotificationResponse
   */
  Details: string;
  /**
   *
   * @type {NotificationType}
   * @memberof NotificationResponse
   */
  Type: NotificationType;
  /**
   *
   * @type {string}
   * @memberof NotificationResponse
   */
  DatePublished?: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationResponse
   */
  Read: boolean;
  /**
   *
   * @type {string}
   * @memberof NotificationResponse
   */
  ReadByUser?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationResponse
   */
  DateRead?: string;
  /**
   *
   * @type {string}
   * @memberof NotificationResponse
   */
  DateCreated: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const NotificationType = {
  Informational: 'Informational',
  Warning: 'Warning',
  Critical: 'Critical',
} as const;

export type NotificationType =
  typeof NotificationType[keyof typeof NotificationType];

/**
 *
 * @export
 * @interface OrganizationEvent
 */
export interface OrganizationEvent {
  /**
   *
   * @type {ComplianceStatus}
   * @memberof OrganizationEvent
   */
  EventType?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof OrganizationEvent
   */
  Timestamp?: string;
}
/**
 *
 * @export
 * @interface OrganizationReview
 */
export interface OrganizationReview {
  /**
   *
   * @type {ProfileBusiness}
   * @memberof OrganizationReview
   */
  Business?: ProfileBusiness;
  /**
   *
   * @type {ProfileContract}
   * @memberof OrganizationReview
   */
  Contract?: ProfileContract;
  /**
   *
   * @type {ProfileGovernance}
   * @memberof OrganizationReview
   */
  Governance?: ProfileGovernance;
  /**
   *
   * @type {ProfileIdentity}
   * @memberof OrganizationReview
   */
  Identity?: ProfileIdentity;
  /**
   *
   * @type {ProfileProduct}
   * @memberof OrganizationReview
   */
  Product?: ProfileProduct;
  /**
   *
   * @type {ReviewStatus}
   * @memberof OrganizationReview
   */
  Status?: ReviewStatus;
  /**
   *
   * @type {ProfileSupportingDocuments}
   * @memberof OrganizationReview
   */
  SupportingDocuments?: ProfileSupportingDocuments;
}
/**
 *
 * @export
 * @interface PasswordChangeBody
 */
export interface PasswordChangeBody {
  /**
   *
   * @type {string}
   * @memberof PasswordChangeBody
   */
  NewPassword: string;
}
/**
 *
 * @export
 * @interface PasswordChangeResponse
 */
export interface PasswordChangeResponse {
  /**
   *
   * @type {boolean}
   * @memberof PasswordChangeResponse
   */
  MustChangePassword?: boolean;
}
/**
 *
 * @export
 * @interface Payment
 */
export interface Payment {
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  id: string;
  /**
   *
   * @type {Money}
   * @memberof Payment
   */
  Amount: Money;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  BatchReference?: string;
  /**
   *
   * @type {QualityOfService}
   * @memberof Payment
   */
  Class: QualityOfService;
  /**
   *
   * @type {ClearingSystem}
   * @memberof Payment
   */
  ClearingSystem?: ClearingSystem;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  ClearingSystemTxId?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  Description: string;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  EndToEndTxId?: string;
  /**
   *
   * @type {Array<Event>}
   * @memberof Payment
   */
  Events: Array<Event>;
  /**
   *
   * @type {BankAccount}
   * @memberof Payment
   */
  From: BankAccount;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  InstructionId: string;
  /**
   *
   * @type {PaymentMeta}
   * @memberof Payment
   */
  MetaData?: PaymentMeta;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  SenderReference: string;
  /**
   *
   * @type {EventType}
   * @memberof Payment
   */
  Status: EventType;
  /**
   *
   * @type {string}
   * @memberof Payment
   */
  TimeStamp: string;
  /**
   *
   * @type {BankAccount}
   * @memberof Payment
   */
  To: BankAccount;
  /**
   *
   * @type {PaymentType}
   * @memberof Payment
   */
  Type: PaymentType;
}
/**
 *
 * @export
 * @interface PaymentAuditingEntry
 */
export interface PaymentAuditingEntry {
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  Timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  FromAccountIban?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  FromAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  ToAccountIban?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  ToAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  IpAddress?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentAuditingEntry
   */
  OperationId?: string;
}
/**
 * Payment cancellation info
 * @export
 * @interface PaymentCancellation
 */
export interface PaymentCancellation {
  /**
   *
   * @type {string}
   * @memberof PaymentCancellation
   */
  Reason: string;
}
/**
 *
 * @export
 * @interface PaymentMeta
 */
export interface PaymentMeta {
  /**
   *
   * @type {string}
   * @memberof PaymentMeta
   */
  Agent: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMeta
   */
  ApplicationId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMeta
   */
  Certigicate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMeta
   */
  Comment?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMeta
   */
  ContactId?: string;
  /**
   *
   * @type {Array<ProcessingDirective>}
   * @memberof PaymentMeta
   */
  Directives?: Array<ProcessingDirective>;
  /**
   *
   * @type {string}
   * @memberof PaymentMeta
   */
  InstructingParty: string;
}
/**
 *
 * @export
 * @interface PaymentPostBody
 */
export interface PaymentPostBody {
  /**
   *
   * @type {Money}
   * @memberof PaymentPostBody
   */
  Amount: Money;
  /**
   *
   * @type {string}
   * @memberof PaymentPostBody
   */
  BatchReference?: string;
  /**
   *
   * @type {QualityOfService}
   * @memberof PaymentPostBody
   */
  Class: QualityOfService;
  /**
   *
   * @type {string}
   * @memberof PaymentPostBody
   */
  Description: string;
  /**
   *
   * @type {BankAccountPostBody}
   * @memberof PaymentPostBody
   */
  From: BankAccountPostBody;
  /**
   *
   * @type {string}
   * @memberof PaymentPostBody
   */
  InstructionId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPostBody
   */
  MetaComment?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentPostBody
   */
  SenderReference: string;
  /**
   *
   * @type {BankAccountPostBody}
   * @memberof PaymentPostBody
   */
  To: BankAccountPostBody;
  /**
   *
   * @type {PaymentType}
   * @memberof PaymentPostBody
   */
  Type: PaymentType;
}
/**
 * Payment release info
 * @export
 * @interface PaymentRelease
 */
export interface PaymentRelease {
  /**
   *
   * @type {string}
   * @memberof PaymentRelease
   */
  Reason: string;
}
/**
 * Client Payment
 * @export
 * @interface PaymentSummary
 */
export interface PaymentSummary {
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  Id?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentSummary
   */
  Amount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  BatchReference?: string;
  /**
   *
   * @type {ClearingSystem}
   * @memberof PaymentSummary
   */
  ClearingSystem?: ClearingSystem;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  ClearingSystemTxId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  ClientName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  Currency?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  Description?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  FromIban?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  FromName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  InstructionId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  MetaComment?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  SenderReference?: string;
  /**
   *
   * @type {EventType}
   * @memberof PaymentSummary
   */
  Status?: EventType;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  TimeStamp?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  ToIban?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSummary
   */
  ToName?: string;
  /**
   *
   * @type {PaymentType}
   * @memberof PaymentSummary
   */
  Type?: PaymentType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PaymentType = {
  Deposit: 'Deposit',
  Payout: 'Payout',
  Transfer: 'Transfer',
} as const;

export type PaymentType = typeof PaymentType[keyof typeof PaymentType];

/**
 *
 * @export
 * @interface PaymentWithLookupPostBody
 */
export interface PaymentWithLookupPostBody {
  /**
   *
   * @type {Money}
   * @memberof PaymentWithLookupPostBody
   */
  Amount: Money;
  /**
   *
   * @type {string}
   * @memberof PaymentWithLookupPostBody
   */
  BatchReference?: string;
  /**
   *
   * @type {QualityOfService}
   * @memberof PaymentWithLookupPostBody
   */
  Class: QualityOfService;
  /**
   *
   * @type {string}
   * @memberof PaymentWithLookupPostBody
   */
  Description: string;
  /**
   *
   * @type {BankAccountLookupPostBody}
   * @memberof PaymentWithLookupPostBody
   */
  From: BankAccountLookupPostBody;
  /**
   *
   * @type {string}
   * @memberof PaymentWithLookupPostBody
   */
  InstructionId: string;
  /**
   *
   * @type {string}
   * @memberof PaymentWithLookupPostBody
   */
  MetaComment?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentWithLookupPostBody
   */
  SenderReference: string;
  /**
   *
   * @type {BankAccountLookupPostBody}
   * @memberof PaymentWithLookupPostBody
   */
  To: BankAccountLookupPostBody;
  /**
   *
   * @type {PaymentType}
   * @memberof PaymentWithLookupPostBody
   */
  Type: PaymentType;
}
/**
 *
 * @export
 * @interface PersonalId
 */
export interface PersonalId {
  /**
   *
   * @type {string}
   * @memberof PersonalId
   */
  Number: string;
  /**
   *
   * @type {string}
   * @memberof PersonalId
   */
  ExpiryDate?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalId
   */
  IssuedDate?: string;
  /**
   *
   * @type {string}
   * @memberof PersonalId
   */
  IssuingCountry: string;
  /**
   *
   * @type {string}
   * @memberof PersonalId
   */
  IssuingRegion?: string;
  /**
   *
   * @type {Attachment}
   * @memberof PersonalId
   */
  SupportingDocument?: Attachment;
  /**
   *
   * @type {PersonalIdType}
   * @memberof PersonalId
   */
  Type: PersonalIdType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PersonalIdType = {
  BirthCertificate: 'BirthCertificate',
  Citizenship: 'Citizenship',
  DriversLicense: 'DriversLicense',
  IdentityCard: 'IdentityCard',
  Passport: 'Passport',
  PermanentResidency: 'PermanentResidency',
  SocialInsurance: 'SocialInsurance',
  TemporaryResidency: 'TemporaryResidency',
} as const;

export type PersonalIdType = typeof PersonalIdType[keyof typeof PersonalIdType];

/**
 *
 * @export
 * @interface PriceLine
 */
export interface PriceLine {
  /**
   *
   * @type {EventType}
   * @memberof PriceLine
   */
  EventType?: EventType;
  /**
   *
   * @type {Array<PaymentType>}
   * @memberof PriceLine
   */
  PaymentTypes?: Array<PaymentType>;
  /**
   *
   * @type {ClearingSystem}
   * @memberof PriceLine
   */
  ClearingSystem?: ClearingSystem;
  /**
   *
   * @type {Fee}
   * @memberof PriceLine
   */
  Fee?: Fee;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ProcessingDirective = {
  NoFee: 'NoFee',
  AllowNegative: 'AllowNegative',
  NoOBLimit: 'NoOBLimit',
  AllowInactiveOrg: 'AllowInactiveOrg',
  AllowSuspendedAccount: 'AllowSuspendedAccount',
  AllowTransfer: 'AllowTransfer',
  SkipApproval: 'SkipApproval',
  SuppressBlocks: 'SuppressBlocks',
} as const;

export type ProcessingDirective =
  typeof ProcessingDirective[keyof typeof ProcessingDirective];

/**
 *
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {string}
   * @memberof Product
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  Description?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  Marketing?: string;
  /**
   *
   * @type {ProductType}
   * @memberof Product
   */
  Type?: ProductType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ProductType = {
  Accounts: 'Accounts',
  Payments: 'Payments',
  Security: 'Security',
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];

/**
 *
 * @export
 * @interface Profile
 */
export interface Profile {
  /**
   *
   * @type {ProfileBusiness}
   * @memberof Profile
   */
  Business: ProfileBusiness;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof Profile
   */
  ComplianceStatus: ComplianceStatus;
  /**
   *
   * @type {ProfileContract}
   * @memberof Profile
   */
  Contract: ProfileContract;
  /**
   *
   * @type {ProfileGovernance}
   * @memberof Profile
   */
  Governance: ProfileGovernance;
  /**
   *
   * @type {ProfileIdentity}
   * @memberof Profile
   */
  Identity: ProfileIdentity;
  /**
   *
   * @type {boolean}
   * @memberof Profile
   */
  IsLocked: boolean;
  /**
   *
   * @type {ProfileProduct}
   * @memberof Profile
   */
  Product: ProfileProduct;
  /**
   *
   * @type {ReviewStatus}
   * @memberof Profile
   */
  ReviewStatus: ReviewStatus;
  /**
   *
   * @type {ActiveType}
   * @memberof Profile
   */
  Status: ActiveType;
  /**
   *
   * @type {ProfileSupportingDocuments}
   * @memberof Profile
   */
  SupportingDocuments: ProfileSupportingDocuments;
}
/**
 *
 * @export
 * @interface ProfileBusiness
 */
export interface ProfileBusiness {
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ProfileBusiness
   */
  BusinessNarrative: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {ProfileBusinessExpectedMaxDailyOutbound}
   * @memberof ProfileBusiness
   */
  ExpectedMaxDailyOutbound: ProfileBusinessExpectedMaxDailyOutbound;
  /**
   *
   * @type {ProfileBusinessExpectedMaxDailyOutbound}
   * @memberof ProfileBusiness
   */
  ExpectedMaxOnAccount: ProfileBusinessExpectedMaxDailyOutbound;
  /**
   *
   * @type {ProfileBusinessExpectedMaxDailyOutbound}
   * @memberof ProfileBusiness
   */
  ExpectedMaxSingle: ProfileBusinessExpectedMaxDailyOutbound;
  /**
   *
   * @type {ProfileBusinessNumberOfEmployees}
   * @memberof ProfileBusiness
   */
  NumberOfEmployees: ProfileBusinessNumberOfEmployees;
  /**
   *
   * @type {ProfileBusinessRegulator}
   * @memberof ProfileBusiness
   */
  Regulator: ProfileBusinessRegulator;
  /**
   *
   * @type {Review}
   * @memberof ProfileBusiness
   */
  Review: Review;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ProfileBusiness
   */
  Status: ReviewStatus;
  /**
   *
   * @type {ProfileBusinessSIC}
   * @memberof ProfileBusiness
   */
  SIC: ProfileBusinessSIC;
  /**
   *
   * @type {Array<ProfileBusinessTradingAs>}
   * @memberof ProfileBusiness
   */
  TradingAs: Array<ProfileBusinessTradingAs>;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ProfileBusiness
   */
  WebSite: ClientIdentityIncorporationNumber;
}
/**
 *
 * @export
 * @interface ProfileBusinessExpectedMaxDailyOutbound
 */
export interface ProfileBusinessExpectedMaxDailyOutbound {
  /**
   *
   * @type {Money}
   * @memberof ProfileBusinessExpectedMaxDailyOutbound
   */
  Item1?: Money;
  /**
   *
   * @type {Review}
   * @memberof ProfileBusinessExpectedMaxDailyOutbound
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileBusinessNumberOfEmployees
 */
export interface ProfileBusinessNumberOfEmployees {
  /**
   *
   * @type {EmployeeNumberCategory}
   * @memberof ProfileBusinessNumberOfEmployees
   */
  Item1?: EmployeeNumberCategory;
  /**
   *
   * @type {Review}
   * @memberof ProfileBusinessNumberOfEmployees
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileBusinessRegulator
 */
export interface ProfileBusinessRegulator {
  /**
   *
   * @type {string}
   * @memberof ProfileBusinessRegulator
   */
  Item1?: string;
  /**
   *
   * @type {Review}
   * @memberof ProfileBusinessRegulator
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileBusinessSIC
 */
export interface ProfileBusinessSIC {
  /**
   *
   * @type {string}
   * @memberof ProfileBusinessSIC
   */
  Item1?: string;
  /**
   *
   * @type {Review}
   * @memberof ProfileBusinessSIC
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileBusinessTradingAs
 */
export interface ProfileBusinessTradingAs {
  /**
   *
   * @type {TradingName}
   * @memberof ProfileBusinessTradingAs
   */
  Item1: TradingName;
  /**
   *
   * @type {Review}
   * @memberof ProfileBusinessTradingAs
   */
  Item2: Review;
  /**
   *
   * @type {string}
   * @memberof ProfileBusinessTradingAs
   */
  Item3: string;
}
/**
 *
 * @export
 * @interface ProfileContract
 */
export interface ProfileContract {
  /**
   *
   * @type {ProfileContractCanCreateAccounts}
   * @memberof ProfileContract
   */
  CanCreateAccounts: ProfileContractCanCreateAccounts;
  /**
   *
   * @type {ProfileContractCanCreateAccounts}
   * @memberof ProfileContract
   */
  CanCreateThirdPartyAccounts: ProfileContractCanCreateAccounts;
  /**
   *
   * @type {ProfileContractCanCreateAccounts}
   * @memberof ProfileContract
   */
  CanCreateUsers: ProfileContractCanCreateAccounts;
  /**
   *
   * @type {ProfileContractContractDocument}
   * @memberof ProfileContract
   */
  ContractDocument: ProfileContractContractDocument;
  /**
   *
   * @type {ClientIdentityIncorporationDate}
   * @memberof ProfileContract
   */
  EndDate: ClientIdentityIncorporationDate;
  /**
   *
   * @type {ProfileContractFees}
   * @memberof ProfileContract
   */
  Fees: ProfileContractFees;
  /**
   *
   * @type {ProfileContractMaxAccounts}
   * @memberof ProfileContract
   */
  MaxAccounts: ProfileContractMaxAccounts;
  /**
   *
   * @type {ProfileBusinessExpectedMaxDailyOutbound}
   * @memberof ProfileContract
   */
  MaxDailyOB: ProfileBusinessExpectedMaxDailyOutbound;
  /**
   *
   * @type {ProfileBusinessExpectedMaxDailyOutbound}
   * @memberof ProfileContract
   */
  MaxOBPayment: ProfileBusinessExpectedMaxDailyOutbound;
  /**
   *
   * @type {ProfileBusinessExpectedMaxDailyOutbound}
   * @memberof ProfileContract
   */
  MaxOnAccount: ProfileBusinessExpectedMaxDailyOutbound;
  /**
   *
   * @type {ProfileContractMaxAccounts}
   * @memberof ProfileContract
   */
  MaxUsers: ProfileContractMaxAccounts;
  /**
   *
   * @type {Review}
   * @memberof ProfileContract
   */
  Review: Review;
  /**
   *
   * @type {ProfileContractCanCreateAccounts}
   * @memberof ProfileContract
   */
  Signed: ProfileContractCanCreateAccounts;
  /**
   *
   * @type {ClientIdentityIncorporationDate}
   * @memberof ProfileContract
   */
  StartDate: ClientIdentityIncorporationDate;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ProfileContract
   */
  Status: ReviewStatus;
  /**
   *
   * @type {ProfileContractSupplier}
   * @memberof ProfileContract
   */
  Supplier: ProfileContractSupplier;
}
/**
 *
 * @export
 * @interface ProfileContractCanCreateAccounts
 */
export interface ProfileContractCanCreateAccounts {
  /**
   *
   * @type {boolean}
   * @memberof ProfileContractCanCreateAccounts
   */
  Item1?: boolean;
  /**
   *
   * @type {Review}
   * @memberof ProfileContractCanCreateAccounts
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileContractContractDocument
 */
export interface ProfileContractContractDocument {
  /**
   *
   * @type {Attachment}
   * @memberof ProfileContractContractDocument
   */
  Item1?: Attachment;
  /**
   *
   * @type {Review}
   * @memberof ProfileContractContractDocument
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileContractFees
 */
export interface ProfileContractFees {
  /**
   *
   * @type {FeesProfile}
   * @memberof ProfileContractFees
   */
  Item1?: FeesProfile;
  /**
   *
   * @type {Review}
   * @memberof ProfileContractFees
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileContractMaxAccounts
 */
export interface ProfileContractMaxAccounts {
  /**
   *
   * @type {number}
   * @memberof ProfileContractMaxAccounts
   */
  Item1?: number;
  /**
   *
   * @type {Review}
   * @memberof ProfileContractMaxAccounts
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileContractSupplier
 */
export interface ProfileContractSupplier {
  /**
   *
   * @type {Supplier}
   * @memberof ProfileContractSupplier
   */
  Item1?: Supplier;
  /**
   *
   * @type {Review}
   * @memberof ProfileContractSupplier
   */
  Item2: Review;
}
/**
 *
 * @export
 * @interface ProfileGovernance
 */
export interface ProfileGovernance {
  /**
   *
   * @type {Array<ProfileGovernanceSignificantPersons>}
   * @memberof ProfileGovernance
   */
  SignificantPersons: Array<ProfileGovernanceSignificantPersons>;
  /**
   *
   * @type {Review}
   * @memberof ProfileGovernance
   */
  Review: Review;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ProfileGovernance
   */
  Status: ReviewStatus;
}
/**
 *
 * @export
 * @interface ProfileGovernanceSignificantPersons
 */
export interface ProfileGovernanceSignificantPersons {
  /**
   *
   * @type {SignificantPerson}
   * @memberof ProfileGovernanceSignificantPersons
   */
  Item1: SignificantPerson;
  /**
   *
   * @type {Review}
   * @memberof ProfileGovernanceSignificantPersons
   */
  Item2: Review;
  /**
   *
   * @type {string}
   * @memberof ProfileGovernanceSignificantPersons
   */
  Item3: string;
}
/**
 *
 * @export
 * @interface ProfileIdentity
 */
export interface ProfileIdentity {
  /**
   *
   * @type {Array<ClientIdentityAddresses>}
   * @memberof ProfileIdentity
   */
  Addresses: Array<ClientIdentityAddresses>;
  /**
   *
   * @type {ClientIdentityIncorporationDate}
   * @memberof ProfileIdentity
   */
  IncorporationDate: ClientIdentityIncorporationDate;
  /**
   *
   * @type {ClientIdentityIncorporationLoc}
   * @memberof ProfileIdentity
   */
  IncorporationLoc: ClientIdentityIncorporationLoc;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ProfileIdentity
   */
  IncorporationNumber: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ProfileIdentity
   */
  LegalName: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ProfileIdentity
   */
  Referrer: ClientIdentityIncorporationNumber;
  /**
   *
   * @type {Review}
   * @memberof ProfileIdentity
   */
  Review: Review;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ProfileIdentity
   */
  Status: ReviewStatus;
  /**
   *
   * @type {ClientIdentityIncorporationNumber}
   * @memberof ProfileIdentity
   */
  TaxId: ClientIdentityIncorporationNumber;
}
/**
 *
 * @export
 * @interface ProfileProduct
 */
export interface ProfileProduct {
  /**
   *
   * @type {Array<ProfileProductProducts>}
   * @memberof ProfileProduct
   */
  Products: Array<ProfileProductProducts>;
  /**
   *
   * @type {Review}
   * @memberof ProfileProduct
   */
  Review: Review;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ProfileProduct
   */
  Status: ReviewStatus;
}
/**
 *
 * @export
 * @interface ProfileProductProducts
 */
export interface ProfileProductProducts {
  /**
   *
   * @type {Product}
   * @memberof ProfileProductProducts
   */
  Item1: Product;
  /**
   *
   * @type {Review}
   * @memberof ProfileProductProducts
   */
  Item2: Review;
  /**
   *
   * @type {string}
   * @memberof ProfileProductProducts
   */
  Item3: string;
}
/**
 *
 * @export
 * @interface ProfilePutBody
 */
export interface ProfilePutBody {
  /**
   *
   * @type {ProfileBusiness}
   * @memberof ProfilePutBody
   */
  Business: ProfileBusiness;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ProfilePutBody
   */
  ComplianceStatus: ComplianceStatus;
  /**
   *
   * @type {ProfileContract}
   * @memberof ProfilePutBody
   */
  Contract: ProfileContract;
  /**
   *
   * @type {ProfileGovernance}
   * @memberof ProfilePutBody
   */
  Governance: ProfileGovernance;
  /**
   *
   * @type {ProfileIdentity}
   * @memberof ProfilePutBody
   */
  Identity: ProfileIdentity;
  /**
   *
   * @type {ProfileProduct}
   * @memberof ProfilePutBody
   */
  Product: ProfileProduct;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ProfilePutBody
   */
  ReviewStatus: ReviewStatus;
  /**
   *
   * @type {ActiveType}
   * @memberof ProfilePutBody
   */
  Status: ActiveType;
  /**
   *
   * @type {ProfileSupportingDocuments}
   * @memberof ProfilePutBody
   */
  SupportingDocuments: ProfileSupportingDocuments;
}
/**
 *
 * @export
 * @interface ProfileSupportingDocuments
 */
export interface ProfileSupportingDocuments {
  /**
   *
   * @type {Array<ProfileSupportingDocumentsClientDocuments>}
   * @memberof ProfileSupportingDocuments
   */
  ClientDocuments: Array<ProfileSupportingDocumentsClientDocuments>;
  /**
   *
   * @type {Review}
   * @memberof ProfileSupportingDocuments
   */
  Review: Review;
  /**
   *
   * @type {ReviewStatus}
   * @memberof ProfileSupportingDocuments
   */
  Status: ReviewStatus;
}
/**
 *
 * @export
 * @interface ProfileSupportingDocumentsClientDocuments
 */
export interface ProfileSupportingDocumentsClientDocuments {
  /**
   *
   * @type {CorporateId}
   * @memberof ProfileSupportingDocumentsClientDocuments
   */
  Item1: CorporateId;
  /**
   *
   * @type {Review}
   * @memberof ProfileSupportingDocumentsClientDocuments
   */
  Item2: Review;
  /**
   *
   * @type {string}
   * @memberof ProfileSupportingDocumentsClientDocuments
   */
  Item3: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const QualityOfService = {
  Instant: 'Instant',
  ASAP: 'ASAP',
} as const;

export type QualityOfService =
  typeof QualityOfService[keyof typeof QualityOfService];

/**
 *
 * @export
 * @interface RateOptionalSource
 */
export interface RateOptionalSource {
  /**
   *
   * @type {number}
   * @memberof RateOptionalSource
   */
  Rate: number;
  /**
   *
   * @type {string}
   * @memberof RateOptionalSource
   */
  Account?: string;
}
/**
 *
 * @export
 * @interface ResetPasswordPutBody
 */
export interface ResetPasswordPutBody {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordPutBody
   */
  NewPassword?: string;
}
/**
 *
 * @export
 * @interface Review
 */
export interface Review {
  /**
   *
   * @type {Author}
   * @memberof Review
   */
  AssignedTo?: Author;
  /**
   *
   * @type {Array<Comment>}
   * @memberof Review
   */
  Comments?: Array<Comment>;
  /**
   *
   * @type {Array<ReviewIssue>}
   * @memberof Review
   */
  Issues?: Array<ReviewIssue>;
  /**
   *
   * @type {string}
   * @memberof Review
   */
  LastUpdated?: string;
  /**
   *
   * @type {Author}
   * @memberof Review
   */
  LastUpdatedBy?: Author;
  /**
   *
   * @type {ReviewStatus}
   * @memberof Review
   */
  Status: ReviewStatus;
}
/**
 *
 * @export
 * @interface ReviewIssue
 */
export interface ReviewIssue {
  /**
   *
   * @type {string}
   * @memberof ReviewIssue
   */
  Description?: string;
  /**
   *
   * @type {ReviewIssueType}
   * @memberof ReviewIssue
   */
  Type?: ReviewIssueType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ReviewIssueType = {
  Informational: 'Informational',
  Severe: 'Severe',
} as const;

export type ReviewIssueType =
  typeof ReviewIssueType[keyof typeof ReviewIssueType];

/**
 *
 * @export
 * @enum {string}
 */

export const ReviewStatus = {
  Approved: 'Approved',
  InProgress: 'InProgress',
  Rejected: 'Rejected',
  UnReviewed: 'UnReviewed',
  WaitingOnClient: 'WaitingOnClient',
} as const;

export type ReviewStatus = typeof ReviewStatus[keyof typeof ReviewStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const RiskCategory = {
  Client: 'Client',
  Geographic: 'Geographic',
  Product: 'Product',
  Regulatory: 'Regulatory',
  Reputational: 'Reputational',
  Other: 'Other',
} as const;

export type RiskCategory = typeof RiskCategory[keyof typeof RiskCategory];

/**
 *
 * @export
 * @interface RiskReview
 */
export interface RiskReview {
  /**
   *
   * @type {Review}
   * @memberof RiskReview
   */
  Review: Review;
  /**
   *
   * @type {ProfileContractMaxAccounts}
   * @memberof RiskReview
   */
  Score: ProfileContractMaxAccounts;
  /**
   *
   * @type {ReviewStatus}
   * @memberof RiskReview
   */
  Status: ReviewStatus;
}
/**
 *
 * @export
 * @interface RiskSurvey
 */
export interface RiskSurvey {
  /**
   *
   * @type {string}
   * @memberof RiskSurvey
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof RiskSurvey
   */
  Description?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof RiskSurvey
   */
  MaxComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {string}
   * @memberof RiskSurvey
   */
  Name?: string;
  /**
   *
   * @type {Array<RiskSurveyQuestion>}
   * @memberof RiskSurvey
   */
  Questions?: Array<RiskSurveyQuestion>;
  /**
   *
   * @type {ActiveType}
   * @memberof RiskSurvey
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface RiskSurveyPostPutBody
 */
export interface RiskSurveyPostPutBody {
  /**
   *
   * @type {string}
   * @memberof RiskSurveyPostPutBody
   */
  Description?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof RiskSurveyPostPutBody
   */
  MaxComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyPostPutBody
   */
  Name?: string;
  /**
   *
   * @type {Array<RiskSurveyQuestion>}
   * @memberof RiskSurveyPostPutBody
   */
  Questions?: Array<RiskSurveyQuestion>;
  /**
   *
   * @type {ActiveType}
   * @memberof RiskSurveyPostPutBody
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface RiskSurveyQuestion
 */
export interface RiskSurveyQuestion {
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestion
   */
  Id?: string;
  /**
   *
   * @type {RiskCategory}
   * @memberof RiskSurveyQuestion
   */
  Category?: RiskCategory;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestion
   */
  Description?: string;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestion
   */
  Name?: string;
  /**
   *
   * @type {Array<RiskSurveyQuestionAnswer>}
   * @memberof RiskSurveyQuestion
   */
  Scoring?: Array<RiskSurveyQuestionAnswer>;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestion
   */
  Text?: string;
}
/**
 *
 * @export
 * @interface RiskSurveyQuestionAnswer
 */
export interface RiskSurveyQuestionAnswer {
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestionAnswer
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestionAnswer
   */
  Description?: string;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestionAnswer
   */
  Name?: string;
  /**
   *
   * @type {number}
   * @memberof RiskSurveyQuestionAnswer
   */
  Score?: number;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyQuestionAnswer
   */
  Text?: string;
}
/**
 *
 * @export
 * @interface RiskSurveyShortSummary
 */
export interface RiskSurveyShortSummary {
  /**
   *
   * @type {string}
   * @memberof RiskSurveyShortSummary
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyShortSummary
   */
  Description?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof RiskSurveyShortSummary
   */
  MaxComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {string}
   * @memberof RiskSurveyShortSummary
   */
  Name?: string;
}
/**
 *
 * @export
 * @interface RiskSurveySummary
 */
export interface RiskSurveySummary {
  /**
   *
   * @type {string}
   * @memberof RiskSurveySummary
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof RiskSurveySummary
   */
  Description?: string;
  /**
   *
   * @type {ComplianceLevel}
   * @memberof RiskSurveySummary
   */
  MaxComplianceLevel?: ComplianceLevel;
  /**
   *
   * @type {string}
   * @memberof RiskSurveySummary
   */
  Name?: string;
  /**
   *
   * @type {number}
   * @memberof RiskSurveySummary
   */
  Size?: number;
  /**
   *
   * @type {ActiveType}
   * @memberof RiskSurveySummary
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const Role = {
  AuthorisedPerson: 'AuthorisedPerson',
  BeneficialOwner: 'BeneficialOwner',
  Director: 'Director',
  Secretary: 'Secretary',
  Shareholder: 'Shareholder',
} as const;

export type Role = typeof Role[keyof typeof Role];

/**
 *
 * @export
 * @interface Settings
 */
export interface Settings {
  /**
   *
   * @type {SettingsContactInfo}
   * @memberof Settings
   */
  ContactInfo?: SettingsContactInfo;
  /**
   *
   * @type {SettingsFees}
   * @memberof Settings
   */
  Fees?: SettingsFees;
}
/**
 *
 * @export
 * @interface SettingsContactInfo
 */
export interface SettingsContactInfo {
  /**
   *
   * @type {string}
   * @memberof SettingsContactInfo
   */
  AlertEmail?: string;
  /**
   *
   * @type {string}
   * @memberof SettingsContactInfo
   */
  PrimaryContact?: string;
}
/**
 *
 * @export
 * @interface SettingsFees
 */
export interface SettingsFees {
  /**
   *
   * @type {ChargeOptionalSource}
   * @memberof SettingsFees
   */
  AccountMaintFee?: ChargeOptionalSource;
  /**
   *
   * @type {ChargeOptionalSource}
   * @memberof SettingsFees
   */
  ClientMaintFee?: ChargeOptionalSource;
  /**
   *
   * @type {number}
   * @memberof SettingsFees
   */
  ExchangeRateDiscount?: number;
  /**
   *
   * @type {ChargeOptionalSource}
   * @memberof SettingsFees
   */
  MinRevenueAmount?: ChargeOptionalSource;
  /**
   *
   * @type {RateOptionalSource}
   * @memberof SettingsFees
   */
  NegInterestMarkup?: RateOptionalSource;
  /**
   *
   * @type {string}
   * @memberof SettingsFees
   */
  TransactionalFeesAccount?: string;
}
/**
 *
 * @export
 * @interface SignificantPerson
 */
export interface SignificantPerson {
  /**
   *
   * @type {SignificantPersonContactSummary}
   * @memberof SignificantPerson
   */
  Contact: SignificantPersonContactSummary;
  /**
   *
   * @type {Array<PersonalId>}
   * @memberof SignificantPerson
   */
  Identifications?: Array<PersonalId>;
  /**
   *
   * @type {Array<Role>}
   * @memberof SignificantPerson
   */
  Roles?: Array<Role>;
}
/**
 *
 * @export
 * @interface SignificantPersonContactSummary
 */
export interface SignificantPersonContactSummary {
  /**
   *
   * @type {string}
   * @memberof SignificantPersonContactSummary
   */
  Id?: string;
  /**
   *
   * @type {Array<ContactAddress>}
   * @memberof SignificantPersonContactSummary
   */
  Addresses?: Array<ContactAddress>;
  /**
   *
   * @type {string}
   * @memberof SignificantPersonContactSummary
   */
  Dob?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantPersonContactSummary
   */
  FamilyName?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantPersonContactSummary
   */
  FirstName?: string;
  /**
   *
   * @type {string}
   * @memberof SignificantPersonContactSummary
   */
  Nationality?: string;
  /**
   *
   * @type {number}
   * @memberof SignificantPersonContactSummary
   */
  OwnershipPercentage?: number;
  /**
   *
   * @type {boolean}
   * @memberof SignificantPersonContactSummary
   */
  PEP?: boolean;
}
/**
 * Statistics about your usage of Seymour and Seymour\'s health.
 * @export
 * @interface Stats
 */
export interface Stats {
  /**
   *
   * @type {string}
   * @memberof Stats
   */
  SystemTime?: string;
  /**
   *
   * @type {string}
   * @memberof Stats
   */
  BuildDate?: string;
  /**
   *
   * @type {string}
   * @memberof Stats
   */
  Build?: string;
  /**
   *
   * @type {string}
   * @memberof Stats
   */
  Commit?: string;
  /**
   *
   * @type {string}
   * @memberof Stats
   */
  Deployed?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const Supplier = {
  TransactiveCA: 'TransactiveCA',
  TransactiveLI: 'TransactiveLI',
  TransactiveUK: 'TransactiveUK',
  TransactiveUS: 'TransactiveUS',
} as const;

export type Supplier = typeof Supplier[keyof typeof Supplier];

/**
 *
 * @export
 * @interface ThirdParty
 */
export interface ThirdParty {
  /**
   *
   * @type {string}
   * @memberof ThirdParty
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ThirdParty
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdParty
   */
  ContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdParty
   */
  ContactPhoneNumber?: string;
  /**
   *
   * @type {ThirdPartyCompanyDetails}
   * @memberof ThirdParty
   */
  CompanyDetails?: ThirdPartyCompanyDetails;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ThirdParty
   */
  ComplianceStatus: ComplianceStatus;
  /**
   *
   * @type {boolean}
   * @memberof ThirdParty
   */
  HasSignedAgreement: boolean;
  /**
   *
   * @type {ThirdPartyIndividualDetails}
   * @memberof ThirdParty
   */
  IndividualDetails?: ThirdPartyIndividualDetails;
  /**
   *
   * @type {string}
   * @memberof ThirdParty
   */
  Name: string;
  /**
   *
   * @type {Attachment}
   * @memberof ThirdParty
   */
  SignedAgreement?: Attachment;
  /**
   *
   * @type {string}
   * @memberof ThirdParty
   */
  SignedAgreementEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdParty
   */
  SignedAgreementStartDate?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ThirdParty
   */
  Status: ActiveType;
  /**
   *
   * @type {ThirdPartyType}
   * @memberof ThirdParty
   */
  Type: ThirdPartyType;
}
/**
 *
 * @export
 * @interface ThirdPartyAdminPostPutBody
 */
export interface ThirdPartyAdminPostPutBody {
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminPostPutBody
   */
  ContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminPostPutBody
   */
  ContactPhoneNumber?: string;
  /**
   *
   * @type {ThirdPartyCompanyDetails}
   * @memberof ThirdPartyAdminPostPutBody
   */
  CompanyDetails?: ThirdPartyCompanyDetails;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ThirdPartyAdminPostPutBody
   */
  ComplianceStatus: ComplianceStatus;
  /**
   *
   * @type {boolean}
   * @memberof ThirdPartyAdminPostPutBody
   */
  HasSignedAgreement: boolean;
  /**
   *
   * @type {ThirdPartyIndividualDetails}
   * @memberof ThirdPartyAdminPostPutBody
   */
  IndividualDetails?: ThirdPartyIndividualDetails;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminPostPutBody
   */
  Name: string;
  /**
   *
   * @type {Attachment}
   * @memberof ThirdPartyAdminPostPutBody
   */
  SignedAgreement?: Attachment;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminPostPutBody
   */
  SignedAgreementEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminPostPutBody
   */
  SignedAgreementStartDate?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ThirdPartyAdminPostPutBody
   */
  Status: ActiveType;
  /**
   *
   * @type {ThirdPartyType}
   * @memberof ThirdPartyAdminPostPutBody
   */
  Type: ThirdPartyType;
}
/**
 *
 * @export
 * @interface ThirdPartyAdminResponseBody
 */
export interface ThirdPartyAdminResponseBody {
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminResponseBody
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminResponseBody
   */
  ClientName: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminResponseBody
   */
  ContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminResponseBody
   */
  ContactPhoneNumber?: string;
  /**
   *
   * @type {ThirdPartyCompanyDetails}
   * @memberof ThirdPartyAdminResponseBody
   */
  CompanyDetails?: ThirdPartyCompanyDetails;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ThirdPartyAdminResponseBody
   */
  ComplianceStatus: ComplianceStatus;
  /**
   *
   * @type {boolean}
   * @memberof ThirdPartyAdminResponseBody
   */
  HasSignedAgreement: boolean;
  /**
   *
   * @type {ThirdPartyIndividualDetails}
   * @memberof ThirdPartyAdminResponseBody
   */
  IndividualDetails?: ThirdPartyIndividualDetails;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminResponseBody
   */
  Name: string;
  /**
   *
   * @type {Attachment}
   * @memberof ThirdPartyAdminResponseBody
   */
  SignedAgreement?: Attachment;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminResponseBody
   */
  SignedAgreementEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminResponseBody
   */
  SignedAgreementStartDate?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ThirdPartyAdminResponseBody
   */
  Status: ActiveType;
  /**
   *
   * @type {ThirdPartyType}
   * @memberof ThirdPartyAdminResponseBody
   */
  Type: ThirdPartyType;
}
/**
 *
 * @export
 * @interface ThirdPartyAdminSummary
 */
export interface ThirdPartyAdminSummary {
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  ClientId?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  ClientName?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  ContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  ContactPhoneNumber?: string;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ThirdPartyAdminSummary
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  Country?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  Dob?: string;
  /**
   *
   * @type {boolean}
   * @memberof ThirdPartyAdminSummary
   */
  HasSignedAgreement?: boolean;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  Name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ThirdPartyAdminSummary
   */
  Nationalities?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  PEP?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  SIC?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  SignedAgreementEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyAdminSummary
   */
  SignedAgreementStartDate?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ThirdPartyAdminSummary
   */
  Status?: ActiveType;
  /**
   *
   * @type {ThirdPartyType}
   * @memberof ThirdPartyAdminSummary
   */
  Type?: ThirdPartyType;
}
/**
 *
 * @export
 * @interface ThirdPartyCompanyDetails
 */
export interface ThirdPartyCompanyDetails {
  /**
   *
   * @type {Array<LegalAddress>}
   * @memberof ThirdPartyCompanyDetails
   */
  Addresses?: Array<LegalAddress>;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyCompanyDetails
   */
  BusinessNarrative?: string;
  /**
   *
   * @type {Array<CorporateId>}
   * @memberof ThirdPartyCompanyDetails
   */
  Identification?: Array<CorporateId>;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyCompanyDetails
   */
  IncorporationDate: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyCompanyDetails
   */
  IncorporationNumber: string;
  /**
   *
   * @type {EmployeeNumberCategory}
   * @memberof ThirdPartyCompanyDetails
   */
  NumberOfEmployees?: EmployeeNumberCategory;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyCompanyDetails
   */
  Regulator?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyCompanyDetails
   */
  SIC: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyCompanyDetails
   */
  TaxId?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyCompanyDetails
   */
  WebSite?: string;
}
/**
 *
 * @export
 * @interface ThirdPartyIndividualDetails
 */
export interface ThirdPartyIndividualDetails {
  /**
   *
   * @type {Array<ContactAddress>}
   * @memberof ThirdPartyIndividualDetails
   */
  Addresses?: Array<ContactAddress>;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyIndividualDetails
   */
  Dob: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyIndividualDetails
   */
  FirstName: string;
  /**
   *
   * @type {Array<PersonalId>}
   * @memberof ThirdPartyIndividualDetails
   */
  Identification?: Array<PersonalId>;
  /**
   *
   * @type {Array<string>}
   * @memberof ThirdPartyIndividualDetails
   */
  Nationalities: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ThirdPartyIndividualDetails
   */
  PEP: boolean;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyIndividualDetails
   */
  TaxId?: string;
}
/**
 *
 * @export
 * @interface ThirdPartyPostPutBody
 */
export interface ThirdPartyPostPutBody {
  /**
   *
   * @type {string}
   * @memberof ThirdPartyPostPutBody
   */
  ContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyPostPutBody
   */
  ContactPhoneNumber?: string;
  /**
   *
   * @type {ThirdPartyCompanyDetails}
   * @memberof ThirdPartyPostPutBody
   */
  CompanyDetails?: ThirdPartyCompanyDetails;
  /**
   *
   * @type {boolean}
   * @memberof ThirdPartyPostPutBody
   */
  HasSignedAgreement: boolean;
  /**
   *
   * @type {ThirdPartyIndividualDetails}
   * @memberof ThirdPartyPostPutBody
   */
  IndividualDetails?: ThirdPartyIndividualDetails;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyPostPutBody
   */
  Name: string;
  /**
   *
   * @type {Attachment}
   * @memberof ThirdPartyPostPutBody
   */
  SignedAgreement?: Attachment;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyPostPutBody
   */
  SignedAgreementEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyPostPutBody
   */
  SignedAgreementStartDate?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ThirdPartyPostPutBody
   */
  Status: ActiveType;
  /**
   *
   * @type {ThirdPartyType}
   * @memberof ThirdPartyPostPutBody
   */
  Type: ThirdPartyType;
}
/**
 *
 * @export
 * @interface ThirdPartyShortSummary
 */
export interface ThirdPartyShortSummary {
  /**
   *
   * @type {string}
   * @memberof ThirdPartyShortSummary
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartyShortSummary
   */
  Name?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ThirdPartyShortSummary
   */
  Status?: ActiveType;
  /**
   *
   * @type {ThirdPartyType}
   * @memberof ThirdPartyShortSummary
   */
  Type?: ThirdPartyType;
}
/**
 *
 * @export
 * @interface ThirdPartySummary
 */
export interface ThirdPartySummary {
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  Id?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  ContactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  ContactPhoneNumber?: string;
  /**
   *
   * @type {ComplianceStatus}
   * @memberof ThirdPartySummary
   */
  ComplianceStatus?: ComplianceStatus;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  Country?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  Dob?: string;
  /**
   *
   * @type {boolean}
   * @memberof ThirdPartySummary
   */
  HasSignedAgreement?: boolean;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  Name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ThirdPartySummary
   */
  Nationalities?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  PEP?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  SIC?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  SignedAgreementEndDate?: string;
  /**
   *
   * @type {string}
   * @memberof ThirdPartySummary
   */
  SignedAgreementStartDate?: string;
  /**
   *
   * @type {ActiveType}
   * @memberof ThirdPartySummary
   */
  Status?: ActiveType;
  /**
   *
   * @type {ThirdPartyType}
   * @memberof ThirdPartySummary
   */
  Type?: ThirdPartyType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ThirdPartyType = {
  CompanyThirdParty: 'CompanyThirdParty',
  IndividualThirdParty: 'IndividualThirdParty',
} as const;

export type ThirdPartyType = typeof ThirdPartyType[keyof typeof ThirdPartyType];

/**
 *
 * @export
 * @interface Token
 */
export interface Token {
  /**
   *
   * @type {string}
   * @memberof Token
   */
  ContactId?: string;
  /**
   * User being authenticated
   * @type {string}
   * @memberof Token
   */
  Uid?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  Token?: string;
  /**
   *
   * @type {Array<Authority>}
   * @memberof Token
   */
  Authorities?: Array<Authority>;
}
/**
 *
 * @export
 * @interface TradingName
 */
export interface TradingName {
  /**
   *
   * @type {string}
   * @memberof TradingName
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof TradingName
   */
  WebSite?: string;
  /**
   *
   * @type {string}
   * @memberof TradingName
   */
  SIC?: string;
}
/**
 *
 * @export
 * @interface TransactionalFees
 */
export interface TransactionalFees {
  /**
   *
   * @type {string}
   * @memberof TransactionalFees
   */
  Account?: string;
  /**
   *
   * @type {ChargeAndSource}
   * @memberof TransactionalFees
   */
  MinRevenueAmount?: ChargeAndSource;
  /**
   *
   * @type {Array<PriceLine>}
   * @memberof TransactionalFees
   */
  TransactionalFeeTable?: Array<PriceLine>;
}
/**
 *
 * @export
 * @interface UserPutBody
 */
export interface UserPutBody {
  /**
   *
   * @type {Array<ContactAddress>}
   * @memberof UserPutBody
   */
  Addresses: Array<ContactAddress>;
  /**
   *
   * @type {string}
   * @memberof UserPutBody
   */
  Dob?: string;
  /**
   *
   * @type {Array<ContactEmailAddress>}
   * @memberof UserPutBody
   */
  EmailAddresses: Array<ContactEmailAddress>;
  /**
   *
   * @type {string}
   * @memberof UserPutBody
   */
  FamilyName: string;
  /**
   *
   * @type {string}
   * @memberof UserPutBody
   */
  FirstName: string;
  /**
   *
   * @type {Array<PersonalId>}
   * @memberof UserPutBody
   */
  Identifications?: Array<PersonalId>;
  /**
   *
   * @type {Array<ContactPhoneNumber>}
   * @memberof UserPutBody
   */
  PhoneNumbers: Array<ContactPhoneNumber>;
}
/**
 *
 * @export
 * @interface UserResponseBody
 */
export interface UserResponseBody {
  /**
   *
   * @type {Array<ContactAddress>}
   * @memberof UserResponseBody
   */
  Addresses?: Array<ContactAddress>;
  /**
   *
   * @type {Array<Application>}
   * @memberof UserResponseBody
   */
  Applications?: Array<Application>;
  /**
   *
   * @type {Array<Authority>}
   * @memberof UserResponseBody
   */
  Authorities?: Array<Authority>;
  /**
   *
   * @type {string}
   * @memberof UserResponseBody
   */
  Dob?: string;
  /**
   *
   * @type {Array<ContactEmailAddress>}
   * @memberof UserResponseBody
   */
  EmailAddresses?: Array<ContactEmailAddress>;
  /**
   *
   * @type {string}
   * @memberof UserResponseBody
   */
  FamilyName?: string;
  /**
   *
   * @type {ContactLimits}
   * @memberof UserResponseBody
   */
  FinancialLimits?: ContactLimits;
  /**
   *
   * @type {string}
   * @memberof UserResponseBody
   */
  FirstName?: string;
  /**
   *
   * @type {LoginDetails}
   * @memberof UserResponseBody
   */
  LoginDetails?: LoginDetails;
  /**
   *
   * @type {string}
   * @memberof UserResponseBody
   */
  Nationality?: string;
  /**
   *
   * @type {number}
   * @memberof UserResponseBody
   */
  OwnershipPercentage?: number;
  /**
   *
   * @type {Array<PersonalId>}
   * @memberof UserResponseBody
   */
  Identifications?: Array<PersonalId>;
  /**
   *
   * @type {boolean}
   * @memberof UserResponseBody
   */
  PEP?: boolean;
  /**
   *
   * @type {Array<ContactPhoneNumber>}
   * @memberof UserResponseBody
   */
  PhoneNumbers?: Array<ContactPhoneNumber>;
  /**
   *
   * @type {Array<Role>}
   * @memberof UserResponseBody
   */
  Roles?: Array<Role>;
  /**
   *
   * @type {ActiveType}
   * @memberof UserResponseBody
   */
  Status?: ActiveType;
}
/**
 *
 * @export
 * @interface Webhook
 */
export interface Webhook {
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof Webhook
   */
  Enabled?: boolean;
  /**
   *
   * @type {EventType}
   * @memberof Webhook
   */
  EventType?: EventType;
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  Name?: string;
  /**
   *
   * @type {string}
   * @memberof Webhook
   */
  URL?: string;
}
/**
 *
 * @export
 * @interface WebhookPostPutBody
 */
export interface WebhookPostPutBody {
  /**
   *
   * @type {boolean}
   * @memberof WebhookPostPutBody
   */
  Enabled?: boolean;
  /**
   *
   * @type {EventType}
   * @memberof WebhookPostPutBody
   */
  EventType: EventType;
  /**
   *
   * @type {string}
   * @memberof WebhookPostPutBody
   */
  Name: string;
  /**
   *
   * @type {string}
   * @memberof WebhookPostPutBody
   */
  URL: string;
}

/**
 * AccessControlApi - axios parameter creator
 * @export
 */
export const AccessControlApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Change a contact\'s own password
     * @param {PasswordChangeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword: async (
      body: PasswordChangeBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('changePassword', 'body', body);
      const localVarPath = `/auth/changepassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/refreshtoken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication basicAuth required
      // http basic authentication required
      setBasicAuthToObject(localVarRequestOptions, configuration);

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccessControlApi - functional programming interface
 * @export
 */
export const AccessControlApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AccessControlApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Change a contact\'s own password
     * @param {PasswordChangeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePassword(
      body: PasswordChangeBody,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PasswordChangeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AccessControlApi - factory interface
 * @export
 */
export const AccessControlApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccessControlApiFp(configuration);
  return {
    /**
     *
     * @summary Change a contact\'s own password
     * @param {PasswordChangeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(
      body: PasswordChangeBody,
      options?: any
    ): AxiosPromise<PasswordChangeResponse> {
      return localVarFp
        .changePassword(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(options?: any): AxiosPromise<Token> {
      return localVarFp
        .refreshToken(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccessControlApi - object-oriented interface
 * @export
 * @class AccessControlApi
 * @extends {BaseAPI}
 */
export class AccessControlApi extends BaseAPI {
  /**
   *
   * @summary Change a contact\'s own password
   * @param {PasswordChangeBody} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControlApi
   */
  public changePassword(
    body: PasswordChangeBody,
    options?: AxiosRequestConfig
  ) {
    return AccessControlApiFp(this.configuration)
      .changePassword(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccessControlApi
   */
  public refreshToken(options?: AxiosRequestConfig) {
    return AccessControlApiFp(this.configuration)
      .refreshToken(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary List account whitelists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts/whitelists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove an account whitelist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsIdDelete: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('accountsWhitelistsIdDelete', 'id', id);
      const localVarPath = `/accounts/whitelists/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of an account whitelist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsIdGet: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('accountsWhitelistsIdGet', 'id', id);
      const localVarPath = `/accounts/whitelists/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add an account whitelist
     * @param {AccountWhitelistPostBody} [accountWhitelistPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsPost: async (
      accountWhitelistPostBody?: AccountWhitelistPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts/whitelists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountWhitelistPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reactivate an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateAccount: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('activateAccount', 'id', id);
      const localVarPath = `/accounts/{id}/activate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add an account
     * @param {AccountPostBody} [accountPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount: async (
      accountPostBody?: AccountPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export activities for an account
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAccountActivity: async (
      id: string,
      timestamp?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('exportAccountActivity', 'id', id);
      const localVarPath = `/accounts/{id}/activity/export`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAccounts: async (
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate account statement as downloadable PDF
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAccountStatement: async (
      id: string,
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('generateAccountStatement', 'id', id);
      const localVarPath = `/accounts/{id}/statement`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAccount', 'id', id);
      const localVarPath = `/accounts/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List activities for an account
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountActivity: async (
      id: string,
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAccountActivity', 'id', id);
      const localVarPath = `/accounts/{id}/activity`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account summary using Iban
     * @param {string} iban
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountSummaryByIban: async (
      iban: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'iban' is not null or undefined
      assertParamExists('getAccountSummaryByIban', 'iban', iban);
      const localVarPath = `/accountbyiban/{iban}`.replace(
        `{${'iban'}}`,
        encodeURIComponent(String(iban))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountSummaryById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAccountSummaryById', 'id', id);
      const localVarPath = `/accountbyid/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts: async (
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Summaries of all accounts
     * @param {string} [searchtext]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAccountSummaries: async (
      searchtext?: string,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      status?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List activity for all accounts
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAccountsActivity: async (
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts/activity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get total number of accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNumAccounts: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/accounts/size`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Temporarily disable an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendAccount: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('suspendAccount', 'id', id);
      const localVarPath = `/accounts/{id}/suspend`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Permanently close an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateAccount: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('terminateAccount', 'id', id);
      const localVarPath = `/accounts/{id}/terminate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update properties of an account
     * @param {string} id
     * @param {AccountPutBody} [accountPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount: async (
      id: string,
      accountPutBody?: AccountPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateAccount', 'id', id);
      const localVarPath = `/accounts/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List account whitelists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountsWhitelistsGet(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountWhitelist>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.accountsWhitelistsGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Remove an account whitelist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountsWhitelistsIdDelete(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountWhitelist>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.accountsWhitelistsIdDelete(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of an account whitelist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountsWhitelistsIdGet(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountWhitelist>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.accountsWhitelistsIdGet(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add an account whitelist
     * @param {AccountWhitelistPostBody} [accountWhitelistPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountsWhitelistsPost(
      accountWhitelistPostBody?: AccountWhitelistPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountWhitelist>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.accountsWhitelistsPost(
          accountWhitelistPostBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Reactivate an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateAccount(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activateAccount(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add an account
     * @param {AccountPostBody} [accountPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAccount(
      accountPostBody?: AccountPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(
        accountPostBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export activities for an account
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAccountActivity(
      id: string,
      timestamp?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAccountActivity(
          id,
          timestamp,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Generate account statement as downloadable PDF
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateAccountStatement(
      id: string,
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateAccountStatement(
          id,
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccount(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List activities for an account
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountActivity(
      id: string,
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Activity>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAccountActivity(
          id,
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get account summary using Iban
     * @param {string} iban
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountSummaryByIban(
      iban: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAccountSummaryByIban(iban, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get account summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountSummaryById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAccountSummaryById(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Summaries of all accounts
     * @param {string} [searchtext]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAccountSummaries(
      searchtext?: string,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      status?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<AccountShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAccountSummaries(
          searchtext,
          balanceCurrency,
          balanceValue,
          status,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List activity for all accounts
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAccountsActivity(
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Activity>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAccountsActivity(
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get total number of accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNumAccounts(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNumAccounts(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Temporarily disable an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suspendAccount(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suspendAccount(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Permanently close an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async terminateAccount(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.terminateAccount(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update properties of an account
     * @param {string} id
     * @param {AccountPutBody} [accountPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccount(
      id: string,
      accountPutBody?: AccountPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(
        id,
        accountPutBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AccountsApiFp(configuration);
  return {
    /**
     *
     * @summary List account whitelists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsGet(options?: any): AxiosPromise<AccountWhitelist> {
      return localVarFp
        .accountsWhitelistsGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Remove an account whitelist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsIdDelete(
      id: string,
      options?: any
    ): AxiosPromise<AccountWhitelist> {
      return localVarFp
        .accountsWhitelistsIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of an account whitelist
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsIdGet(
      id: string,
      options?: any
    ): AxiosPromise<AccountWhitelist> {
      return localVarFp
        .accountsWhitelistsIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add an account whitelist
     * @param {AccountWhitelistPostBody} [accountWhitelistPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountsWhitelistsPost(
      accountWhitelistPostBody?: AccountWhitelistPostBody,
      options?: any
    ): AxiosPromise<AccountWhitelist> {
      return localVarFp
        .accountsWhitelistsPost(accountWhitelistPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reactivate an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateAccount(id: string, options?: any): AxiosPromise<Account> {
      return localVarFp
        .activateAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add an account
     * @param {AccountPostBody} [accountPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccount(
      accountPostBody?: AccountPostBody,
      options?: any
    ): AxiosPromise<Account> {
      return localVarFp
        .createAccount(accountPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export activities for an account
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAccountActivity(
      id: string,
      timestamp?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAccountActivity(id, timestamp, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAccounts(
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generate account statement as downloadable PDF
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateAccountStatement(
      id: string,
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .generateAccountStatement(id, timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccount(id: string, options?: any): AxiosPromise<Account> {
      return localVarFp
        .getAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List activities for an account
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountActivity(
      id: string,
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<Activity>> {
      return localVarFp
        .getAccountActivity(id, timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get account summary using Iban
     * @param {string} iban
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountSummaryByIban(
      iban: string,
      options?: any
    ): AxiosPromise<AccountShortSummary> {
      return localVarFp
        .getAccountSummaryByIban(iban, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get account summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountSummaryById(
      id: string,
      options?: any
    ): AxiosPromise<AccountShortSummary> {
      return localVarFp
        .getAccountSummaryById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<Account>> {
      return localVarFp
        .getAccounts(
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Summaries of all accounts
     * @param {string} [searchtext]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAccountSummaries(
      searchtext?: string,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      status?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<AccountShortSummary>> {
      return localVarFp
        .getAllAccountSummaries(
          searchtext,
          balanceCurrency,
          balanceValue,
          status,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List activity for all accounts
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAccountsActivity(
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<Activity>> {
      return localVarFp
        .getAllAccountsActivity(timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get total number of accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNumAccounts(options?: any): AxiosPromise<number> {
      return localVarFp
        .getNumAccounts(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Temporarily disable an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendAccount(id: string, options?: any): AxiosPromise<Account> {
      return localVarFp
        .suspendAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Permanently close an account
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateAccount(id: string, options?: any): AxiosPromise<Account> {
      return localVarFp
        .terminateAccount(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update properties of an account
     * @param {string} id
     * @param {AccountPutBody} [accountPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccount(
      id: string,
      accountPutBody?: AccountPutBody,
      options?: any
    ): AxiosPromise<Account> {
      return localVarFp
        .updateAccount(id, accountPutBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
  /**
   *
   * @summary List account whitelists
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public accountsWhitelistsGet(options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .accountsWhitelistsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove an account whitelist
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public accountsWhitelistsIdDelete(id: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .accountsWhitelistsIdDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of an account whitelist
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public accountsWhitelistsIdGet(id: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .accountsWhitelistsIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add an account whitelist
   * @param {AccountWhitelistPostBody} [accountWhitelistPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public accountsWhitelistsPost(
    accountWhitelistPostBody?: AccountWhitelistPostBody,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .accountsWhitelistsPost(accountWhitelistPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reactivate an account
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public activateAccount(id: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .activateAccount(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add an account
   * @param {AccountPostBody} [accountPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public createAccount(
    accountPostBody?: AccountPostBody,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .createAccount(accountPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export activities for an account
   * @param {string} id
   * @param {FilterParameter} [timestamp]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public exportAccountActivity(
    id: string,
    timestamp?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .exportAccountActivity(id, timestamp, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export accounts
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public exportAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .exportAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generate account statement as downloadable PDF
   * @param {string} id
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public generateAccountStatement(
    id: string,
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .generateAccountStatement(id, timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of an account
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAccount(id: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .getAccount(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List activities for an account
   * @param {string} id
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAccountActivity(
    id: string,
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .getAccountActivity(id, timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get account summary using Iban
   * @param {string} iban
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAccountSummaryByIban(iban: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .getAccountSummaryByIban(iban, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get account summary using Id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAccountSummaryById(id: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .getAccountSummaryById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List accounts
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .getAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Summaries of all accounts
   * @param {string} [searchtext]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAllAccountSummaries(
    searchtext?: string,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    status?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .getAllAccountSummaries(
        searchtext,
        balanceCurrency,
        balanceValue,
        status,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List activity for all accounts
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getAllAccountsActivity(
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .getAllAccountsActivity(timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get total number of accounts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public getNumAccounts(options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .getNumAccounts(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Temporarily disable an account
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public suspendAccount(id: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .suspendAccount(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Permanently close an account
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public terminateAccount(id: string, options?: AxiosRequestConfig) {
    return AccountsApiFp(this.configuration)
      .terminateAccount(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update properties of an account
   * @param {string} id
   * @param {AccountPutBody} [accountPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountsApi
   */
  public updateAccount(
    id: string,
    accountPutBody?: AccountPutBody,
    options?: AxiosRequestConfig
  ) {
    return AccountsApiFp(this.configuration)
      .updateAccount(id, accountPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Change a client\'s operational status to Active
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClient: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('activateClient', 'id', id);
      const localVarPath = `/clients/orgs/{id}/opstatus/activate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Activate a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClientAccount: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('activateClientAccount', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('activateClientAccount', 'id', id);
      const localVarPath = `/clients/accounts/{orgId}/{id}/activate`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Activate a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClientContact: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('activateClientContact', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('activateClientContact', 'id', id);
      const localVarPath = `/clients/contacts/{orgId}/{id}/activate`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a new client Contact
     * @param {string} orgId
     * @param {ContactPostBody} [contactPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addClientContact: async (
      orgId: string,
      contactPostBody?: ContactPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('addClientContact', 'orgId', orgId);
      const localVarPath = `/clients/contacts/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contactPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a new client third party
     * @param {string} orgId
     * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addClientThirdParty: async (
      orgId: string,
      thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('addClientThirdParty', 'orgId', orgId);
      const localVarPath = `/clients/thirdparties/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        thirdPartyAdminPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Approve pending updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveClientUpdates: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('approveClientUpdates', 'id', id);
      const localVarPath = `/clients/orgs/{id}/approveupdates`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Bless account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blessAccountBlock: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('blessAccountBlock', 'id', id);
      const localVarPath = `/clients/settings/accountblocks/{id}/bless`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Block account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockAccountBlock: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('blockAccountBlock', 'id', id);
      const localVarPath = `/clients/settings/accountblocks/{id}/block`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Broadcast a new client notification to 1 or more clients
     * @param {NotificationBroadcastRequest} [notificationBroadcastRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    broadcastNotification: async (
      notificationBroadcastRequest?: NotificationBroadcastRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/notifications/broadcast`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationBroadcastRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancels a held client payment
     * @param {string} orgId
     * @param {string} id
     * @param {PaymentCancellation} paymentCancellation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelHeldClientPayment: async (
      orgId: string,
      id: string,
      paymentCancellation: PaymentCancellation,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('cancelHeldClientPayment', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cancelHeldClientPayment', 'id', id);
      // verify required parameter 'paymentCancellation' is not null or undefined
      assertParamExists(
        'cancelHeldClientPayment',
        'paymentCancellation',
        paymentCancellation
      );
      const localVarPath = `/clients/payments/{orgId}/{id}/cancel`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentCancellation,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new account block
     * @param {AccountBlockPostBody} [accountBlockPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountBlock: async (
      accountBlockPostBody?: AccountBlockPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/accountblocks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountBlockPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new client
     * @param {ClientPostBody} [clientPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClient: async (
      clientPostBody?: ClientPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/orgs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add an account for a specified client
     * @param {string} orgId
     * @param {AccountAdminPostBody} [accountAdminPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClientAccount: async (
      orgId: string,
      accountAdminPostBody?: AccountAdminPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createClientAccount', 'orgId', orgId);
      const localVarPath = `/clients/accounts/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountAdminPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new client profile
     * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClientProfile: async (
      clientProfilePostPutBody?: ClientProfilePostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/clientprofiles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientProfilePostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new client notification
     * @param {string} orgId
     * @param {NotificationPostPutRequest} [notificationPostPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNotification: async (
      orgId: string,
      notificationPostPutRequest?: NotificationPostPutRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createNotification', 'orgId', orgId);
      const localVarPath = `/clients/notifications/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationPostPutRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a new risk survey
     * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRiskSurvey: async (
      riskSurveyPostPutBody?: RiskSurveyPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/risksurveys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        riskSurveyPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a client notification
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClientNotification: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('deleteClientNotification', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteClientNotification', 'id', id);
      const localVarPath = `/clients/notifications/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Bless account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAccountBlock: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('disableAccountBlock', 'id', id);
      const localVarPath =
        `/clients/settings/accountblocks/{id}/disable`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all account blocks
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAccountBlocks: async (
      iban?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/accountblocks/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all client accounts
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientAccounts: async (
      orgId: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('exportAllClientAccounts', 'orgId', orgId);
      const localVarPath = `/clients/accounts/{orgId}/export`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export activity for a client
     * @param {string} orgId
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientActivity: async (
      orgId: string,
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('exportAllClientActivity', 'orgId', orgId);
      const localVarPath = `/clients/activity/{orgId}/export`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export client contacts
     * @param {any} orgId
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientContacts: async (
      orgId: any,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('exportAllClientContacts', 'orgId', orgId);
      const localVarPath = `/clients/contacts/{orgId}/export`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export client third parties
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientThirdParties: async (
      orgId: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('exportAllClientThirdParties', 'orgId', orgId);
      const localVarPath = `/clients/thirdparties/{orgId}/export`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export clients accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsAccounts: async (
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/accounts/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export activity for all clients
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsActivity: async (
      timestamp?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/activity/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export clients contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsContacts: async (
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/contacts/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export clients third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsThirdParties: async (
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/thirdparties/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export activity for a client account
     * @param {string} orgId
     * @param {string} accountId
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClientAccountActivity: async (
      orgId: string,
      accountId: string,
      timestamp?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('exportClientAccountActivity', 'orgId', orgId);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('exportClientAccountActivity', 'accountId', accountId);
      const localVarPath = `/clients/activity/{orgId}/{accountId}/export`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export client payments
     * @param {string} orgId
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClientPayments: async (
      orgId: string,
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('exportClientPayments', 'orgId', orgId);
      const localVarPath = `/clients/payments/{orgId}/export`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (amountCurrency !== undefined) {
        localVarQueryParameter['Amount.Currency'] = amountCurrency;
      }

      if (amountValue !== undefined) {
        localVarQueryParameter['Amount.Value'] = amountValue;
      }

      if (_class !== undefined) {
        localVarQueryParameter['Class'] = _class;
      }

      if (fromIban !== undefined) {
        localVarQueryParameter['From.Iban'] = fromIban;
      }

      if (fromName !== undefined) {
        localVarQueryParameter['From.Name'] = fromName;
      }

      if (toIban !== undefined) {
        localVarQueryParameter['To.Iban'] = toIban;
      }

      if (toName !== undefined) {
        localVarQueryParameter['To.Name'] = toName;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (timeStamp !== undefined) {
        localVarQueryParameter['TimeStamp'] = timeStamp;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (clearingSystem !== undefined) {
        localVarQueryParameter['ClearingSystem'] = clearingSystem;
      }

      if (clearingSystemTxId !== undefined) {
        localVarQueryParameter['ClearingSystemTxId'] = clearingSystemTxId;
      }

      if (instructionId !== undefined) {
        localVarQueryParameter['InstructionId'] = instructionId;
      }

      if (description !== undefined) {
        localVarQueryParameter['Description'] = description;
      }

      if (senderReference !== undefined) {
        localVarQueryParameter['SenderReference'] = senderReference;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export clients
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClients: async (
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/orgs/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (supplier !== undefined) {
        localVarQueryParameter['Supplier'] = supplier;
      }

      if (legalName !== undefined) {
        localVarQueryParameter['LegalName'] = legalName;
      }

      if (informalName !== undefined) {
        localVarQueryParameter['InformalName'] = informalName;
      }

      if (incorporationLoc !== undefined) {
        localVarQueryParameter['IncorporationLoc'] = incorporationLoc;
      }

      if (incorporationNumber !== undefined) {
        localVarQueryParameter['IncorporationNumber'] = incorporationNumber;
      }

      if (incorporationDate !== undefined) {
        localVarQueryParameter['IncorporationDate'] = incorporationDate;
      }

      if (sIC !== undefined) {
        localVarQueryParameter['SIC'] = sIC;
      }

      if (lastKycReview !== undefined) {
        localVarQueryParameter['LastKycReview'] = lastKycReview;
      }

      if (clientProfileComplianceLevel !== undefined) {
        localVarQueryParameter['ClientProfileComplianceLevel'] =
          clientProfileComplianceLevel;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['StartDate'] = startDate;
      }

      if (webSite !== undefined) {
        localVarQueryParameter['WebSite'] = webSite;
      }

      if (clientProfile !== undefined) {
        localVarQueryParameter['ClientProfile'] = clientProfile;
      }

      if (referrer !== undefined) {
        localVarQueryParameter['Referrer'] = referrer;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export all clients payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClientsPayments: async (
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/payments/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (amountCurrency !== undefined) {
        localVarQueryParameter['Amount.Currency'] = amountCurrency;
      }

      if (amountValue !== undefined) {
        localVarQueryParameter['Amount.Value'] = amountValue;
      }

      if (_class !== undefined) {
        localVarQueryParameter['Class'] = _class;
      }

      if (fromIban !== undefined) {
        localVarQueryParameter['From.Iban'] = fromIban;
      }

      if (fromName !== undefined) {
        localVarQueryParameter['From.Name'] = fromName;
      }

      if (toIban !== undefined) {
        localVarQueryParameter['To.Iban'] = toIban;
      }

      if (toName !== undefined) {
        localVarQueryParameter['To.Name'] = toName;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (timeStamp !== undefined) {
        localVarQueryParameter['TimeStamp'] = timeStamp;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (clearingSystem !== undefined) {
        localVarQueryParameter['ClearingSystem'] = clearingSystem;
      }

      if (clearingSystemTxId !== undefined) {
        localVarQueryParameter['ClearingSystemTxId'] = clearingSystemTxId;
      }

      if (instructionId !== undefined) {
        localVarQueryParameter['InstructionId'] = instructionId;
      }

      if (description !== undefined) {
        localVarQueryParameter['Description'] = description;
      }

      if (senderReference !== undefined) {
        localVarQueryParameter['SenderReference'] = senderReference;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate client account statement as downloadable PDF
     * @param {string} orgId
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateClientAccountStatement: async (
      orgId: string,
      id: string,
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('generateClientAccountStatement', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('generateClientAccountStatement', 'id', id);
      const localVarPath = `/clients/accounts/{orgId}/{id}/statement`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountBlock: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAccountBlock', 'id', id);
      const localVarPath = `/clients/settings/accountblocks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all account blocks
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountBlocks: async (
      iban?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/accountblocks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all clients attachments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAttachments: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/docs/attachments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client account summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientAccountSummaries: async (
      orgId: string,
      searchtext?: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientAccountSummaries', 'orgId', orgId);
      const localVarPath = `/clients/accounts/{orgId}/summaries`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client accounts
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientAccounts: async (
      orgId: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientAccounts', 'orgId', orgId);
      const localVarPath = `/clients/accounts/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List activity for a client
     * @param {string} orgId
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientActivity: async (
      orgId: string,
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientActivity', 'orgId', orgId);
      const localVarPath = `/clients/activity/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all client attachments
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientAttachments: async (
      orgId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientAttachments', 'orgId', orgId);
      const localVarPath = `/clients/docs/attachments/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client contact summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientContactSummaries: async (
      orgId: string,
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientContactSummaries', 'orgId', orgId);
      const localVarPath = `/clients/contacts/{orgId}/summaries`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client contacts
     * @param {string} orgId
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientContacts: async (
      orgId: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientContacts', 'orgId', orgId);
      const localVarPath = `/clients/contacts/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all clients notifications
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientNotifications: async (
      orgId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientNotifications', 'orgId', orgId);
      const localVarPath = `/clients/notifications/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client third parties
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientThirdParties: async (
      orgId: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientThirdParties', 'orgId', orgId);
      const localVarPath = `/clients/thirdparties/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client third party summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientThirdPartySummaries: async (
      orgId: string,
      searchtext?: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getAllClientThirdPartySummaries', 'orgId', orgId);
      const localVarPath = `/clients/thirdparties/{orgId}/summaries`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients account summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsAccountSummaries: async (
      searchtext?: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/accounts/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsAccounts: async (
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/accounts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (descriptor !== undefined) {
        localVarQueryParameter['Descriptor'] = descriptor;
      }

      if (balanceCurrency !== undefined) {
        localVarQueryParameter['Balance.Currency'] = balanceCurrency;
      }

      if (balanceValue !== undefined) {
        localVarQueryParameter['Balance.Value'] = balanceValue;
      }

      if (creationDate !== undefined) {
        localVarQueryParameter['CreationDate'] = creationDate;
      }

      if (createdBy !== undefined) {
        localVarQueryParameter['CreatedBy'] = createdBy;
      }

      if (lastActivity !== undefined) {
        localVarQueryParameter['LastActivity'] = lastActivity;
      }

      if (terminationDate !== undefined) {
        localVarQueryParameter['TerminationDate'] = terminationDate;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (iban !== undefined) {
        localVarQueryParameter['Iban'] = iban;
      }

      if (useDynamicDescriptor !== undefined) {
        localVarQueryParameter['UseDynamicDescriptor'] = useDynamicDescriptor;
      }

      if (isThirdParty !== undefined) {
        localVarQueryParameter['IsThirdParty'] = isThirdParty;
      }

      if (utilized !== undefined) {
        localVarQueryParameter['utilized'] = utilized;
      }

      if (beneficiaries !== undefined) {
        localVarQueryParameter['Beneficiaries'] = beneficiaries;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List activity for all clients
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsActivity: async (
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/activity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients contact summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsContactSummaries: async (
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/contacts/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsContacts: async (
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsThirdParties: async (
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/thirdparties`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients third parties summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsThirdPartySummaries: async (
      searchtext?: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/thirdparties/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List payment auditing entries originating from all accounts
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [fromAccountIban]
     * @param {FilterParameter} [toAccountIban]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaymentAuditingEntries: async (
      timestamp?: FilterParameter,
      fromAccountIban?: FilterParameter,
      toAccountIban?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/auditing/iptrace`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      if (fromAccountIban !== undefined) {
        localVarQueryParameter['FromAccountIban'] = fromAccountIban;
      }

      if (toAccountIban !== undefined) {
        localVarQueryParameter['ToAccountIban'] = toAccountIban;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all compliance check lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChecklists: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/checklists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClient: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClient', 'id', id);
      const localVarPath = `/clients/orgs/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccount: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientAccount', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientAccount', 'id', id);
      const localVarPath = `/clients/accounts/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List activities for a client account
     * @param {string} orgId
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccountActivity: async (
      orgId: string,
      id: string,
      timestamp?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientAccountActivity', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientAccountActivity', 'id', id);
      const localVarPath = `/clients/accounts/{orgId}/{id}/activity`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client account summary using Iban
     * @param {string} orgId
     * @param {string} iban
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccountSummaryByIban: async (
      orgId: string,
      iban: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientAccountSummaryByIban', 'orgId', orgId);
      // verify required parameter 'iban' is not null or undefined
      assertParamExists('getClientAccountSummaryByIban', 'iban', iban);
      const localVarPath = `/clients/accountbyiban/{orgId}/{iban}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'iban'}}`, encodeURIComponent(String(iban)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client account summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccountSummaryById: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientAccountSummaryById', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientAccountSummaryById', 'id', id);
      const localVarPath = `/clients/accountbyid/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client attachment
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAttachment: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientAttachment', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientAttachment', 'id', id);
      const localVarPath = `/clients/docs/attachments/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientContact: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientContact', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientContact', 'id', id);
      const localVarPath = `/clients/contacts/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client contact summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientContactSummaryById: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientContactSummaryById', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientContactSummaryById', 'id', id);
      const localVarPath = `/clients/contactbyid/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client\'s current review
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCurrentReview: async (
      orgId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientCurrentReview', 'orgId', orgId);
      const localVarPath = `/clients/reviews/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client notification
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientNotification: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientNotification', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientNotification', 'id', id);
      const localVarPath = `/clients/notifications/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client payment
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientPayment: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientPayment', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientPayment', 'id', id);
      const localVarPath = `/clients/payments/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client payments
     * @param {string} orgId
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientPayments: async (
      orgId: string,
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientPayments', 'orgId', orgId);
      const localVarPath = `/clients/payments/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (amountCurrency !== undefined) {
        localVarQueryParameter['Amount.Currency'] = amountCurrency;
      }

      if (amountValue !== undefined) {
        localVarQueryParameter['Amount.Value'] = amountValue;
      }

      if (_class !== undefined) {
        localVarQueryParameter['Class'] = _class;
      }

      if (fromIban !== undefined) {
        localVarQueryParameter['From.Iban'] = fromIban;
      }

      if (fromName !== undefined) {
        localVarQueryParameter['From.Name'] = fromName;
      }

      if (toIban !== undefined) {
        localVarQueryParameter['To.Iban'] = toIban;
      }

      if (toName !== undefined) {
        localVarQueryParameter['To.Name'] = toName;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (timeStamp !== undefined) {
        localVarQueryParameter['TimeStamp'] = timeStamp;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (clearingSystem !== undefined) {
        localVarQueryParameter['ClearingSystem'] = clearingSystem;
      }

      if (clearingSystemTxId !== undefined) {
        localVarQueryParameter['ClearingSystemTxId'] = clearingSystemTxId;
      }

      if (instructionId !== undefined) {
        localVarQueryParameter['InstructionId'] = instructionId;
      }

      if (description !== undefined) {
        localVarQueryParameter['Description'] = description;
      }

      if (senderReference !== undefined) {
        localVarQueryParameter['SenderReference'] = senderReference;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfile: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientProfile', 'id', id);
      const localVarPath = `/clients/settings/clientprofiles/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client profile summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfileSummaryById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientProfileSummaryById', 'id', id);
      const localVarPath = `/clients/clientprofilebyid/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client profiles
     * @param {FilterParameter} [supplier]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfiles: async (
      supplier?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/clientprofiles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (supplier !== undefined) {
        localVarQueryParameter['Supplier'] = supplier;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List client profile summaries
     * @param {string} [searchtext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfilesSummaries: async (
      searchtext?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/clientprofiles/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a client organization settings, including fee accounts
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientSettings: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientSettings', 'id', id);
      const localVarPath = `/clients/orgs/{id}/settings`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client summary
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientSummaryById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientSummaryById', 'id', id);
      const localVarPath = `/clients/clientbyid/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client third party
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientThirdParty: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientThirdParty', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientThirdParty', 'id', id);
      const localVarPath = `/clients/thirdparties/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get client third party summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientThirdPartySummaryById: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getClientThirdPartySummaryById', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getClientThirdPartySummaryById', 'id', id);
      const localVarPath = `/clients/thirdpartybyid/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients: async (
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/orgs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (supplier !== undefined) {
        localVarQueryParameter['Supplier'] = supplier;
      }

      if (legalName !== undefined) {
        localVarQueryParameter['LegalName'] = legalName;
      }

      if (informalName !== undefined) {
        localVarQueryParameter['InformalName'] = informalName;
      }

      if (incorporationLoc !== undefined) {
        localVarQueryParameter['IncorporationLoc'] = incorporationLoc;
      }

      if (incorporationNumber !== undefined) {
        localVarQueryParameter['IncorporationNumber'] = incorporationNumber;
      }

      if (incorporationDate !== undefined) {
        localVarQueryParameter['IncorporationDate'] = incorporationDate;
      }

      if (sIC !== undefined) {
        localVarQueryParameter['SIC'] = sIC;
      }

      if (lastKycReview !== undefined) {
        localVarQueryParameter['LastKycReview'] = lastKycReview;
      }

      if (clientProfileComplianceLevel !== undefined) {
        localVarQueryParameter['ClientProfileComplianceLevel'] =
          clientProfileComplianceLevel;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['StartDate'] = startDate;
      }

      if (webSite !== undefined) {
        localVarQueryParameter['WebSite'] = webSite;
      }

      if (clientProfile !== undefined) {
        localVarQueryParameter['ClientProfile'] = clientProfile;
      }

      if (referrer !== undefined) {
        localVarQueryParameter['Referrer'] = referrer;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all clients payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsPayments: async (
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/payments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (amountCurrency !== undefined) {
        localVarQueryParameter['Amount.Currency'] = amountCurrency;
      }

      if (amountValue !== undefined) {
        localVarQueryParameter['Amount.Value'] = amountValue;
      }

      if (_class !== undefined) {
        localVarQueryParameter['Class'] = _class;
      }

      if (fromIban !== undefined) {
        localVarQueryParameter['From.Iban'] = fromIban;
      }

      if (fromName !== undefined) {
        localVarQueryParameter['From.Name'] = fromName;
      }

      if (toIban !== undefined) {
        localVarQueryParameter['To.Iban'] = toIban;
      }

      if (toName !== undefined) {
        localVarQueryParameter['To.Name'] = toName;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (timeStamp !== undefined) {
        localVarQueryParameter['TimeStamp'] = timeStamp;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (clearingSystem !== undefined) {
        localVarQueryParameter['ClearingSystem'] = clearingSystem;
      }

      if (clearingSystemTxId !== undefined) {
        localVarQueryParameter['ClearingSystemTxId'] = clearingSystemTxId;
      }

      if (instructionId !== undefined) {
        localVarQueryParameter['InstructionId'] = instructionId;
      }

      if (description !== undefined) {
        localVarQueryParameter['Description'] = description;
      }

      if (senderReference !== undefined) {
        localVarQueryParameter['SenderReference'] = senderReference;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List clients summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsSummaries: async (
      searchtext?: string,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/orgs/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (supplier !== undefined) {
        localVarQueryParameter['Supplier'] = supplier;
      }

      if (legalName !== undefined) {
        localVarQueryParameter['LegalName'] = legalName;
      }

      if (informalName !== undefined) {
        localVarQueryParameter['InformalName'] = informalName;
      }

      if (incorporationLoc !== undefined) {
        localVarQueryParameter['IncorporationLoc'] = incorporationLoc;
      }

      if (incorporationNumber !== undefined) {
        localVarQueryParameter['IncorporationNumber'] = incorporationNumber;
      }

      if (incorporationDate !== undefined) {
        localVarQueryParameter['IncorporationDate'] = incorporationDate;
      }

      if (sIC !== undefined) {
        localVarQueryParameter['SIC'] = sIC;
      }

      if (lastKycReview !== undefined) {
        localVarQueryParameter['LastKycReview'] = lastKycReview;
      }

      if (clientProfileComplianceLevel !== undefined) {
        localVarQueryParameter['ClientProfileComplianceLevel'] =
          clientProfileComplianceLevel;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['StartDate'] = startDate;
      }

      if (webSite !== undefined) {
        localVarQueryParameter['WebSite'] = webSite;
      }

      if (clientProfile !== undefined) {
        localVarQueryParameter['ClientProfile'] = clientProfile;
      }

      if (referrer !== undefined) {
        localVarQueryParameter['Referrer'] = referrer;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProducts: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get risk survey
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurvey: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getRiskSurvey', 'id', id);
      const localVarPath = `/clients/settings/risksurveys/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List risk survey summaries
     * @param {string} [searchtext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurveySummaries: async (
      searchtext?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/risksurveys/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get risk survey summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurveySummaryById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getRiskSurveySummaryById', 'id', id);
      const localVarPath = `/clients/risksurveybyid/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all risk surveys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurveys: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/clients/settings/risksurveys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lock a client\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockClientProfile: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('lockClientProfile', 'id', id);
      const localVarPath = `/clients/orgs/{id}/opstatus/lock`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reject pending updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectClientUpdates: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('rejectClientUpdates', 'id', id);
      const localVarPath = `/clients/orgs/{id}/rejectupdates`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Releases a held client payment
     * @param {string} orgId
     * @param {string} id
     * @param {PaymentRelease} paymentRelease
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseHeldClientPayment: async (
      orgId: string,
      id: string,
      paymentRelease: PaymentRelease,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('releaseHeldClientPayment', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('releaseHeldClientPayment', 'id', id);
      // verify required parameter 'paymentRelease' is not null or undefined
      assertParamExists(
        'releaseHeldClientPayment',
        'paymentRelease',
        paymentRelease
      );
      const localVarPath = `/clients/payments/{orgId}/{id}/release`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentRelease,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reset a client contact password
     * @param {string} orgId
     * @param {string} id
     * @param {ResetPasswordPutBody} resetPasswordPutBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetClientContactPassword: async (
      orgId: string,
      id: string,
      resetPasswordPutBody: ResetPasswordPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('resetClientContactPassword', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('resetClientContactPassword', 'id', id);
      // verify required parameter 'resetPasswordPutBody' is not null or undefined
      assertParamExists(
        'resetClientContactPassword',
        'resetPasswordPutBody',
        resetPasswordPutBody
      );
      const localVarPath = `/clients/contacts/{orgId}/{id}/resetpassword`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Review/make updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewClientUpdates: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('reviewClientUpdates', 'id', id);
      const localVarPath = `/clients/orgs/{id}/review`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Change a client\'s operational status to Suspended
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendClient: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('suspendClient', 'id', id);
      const localVarPath = `/clients/orgs/{id}/opstatus/suspend`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Suspend a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendClientAccount: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('suspendClientAccount', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('suspendClientAccount', 'id', id);
      const localVarPath = `/clients/accounts/{orgId}/{id}/suspend`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Suspend a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendClientContact: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('suspendClientContact', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('suspendClientContact', 'id', id);
      const localVarPath = `/clients/contacts/{orgId}/{id}/suspend`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateClient: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('terminateClient', 'id', id);
      const localVarPath = `/clients/orgs/{id}/opstatus/terminate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Terminate a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateClientAccount: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('terminateClientAccount', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('terminateClientAccount', 'id', id);
      const localVarPath = `/clients/accounts/{orgId}/{id}/terminate`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Terminate a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateClientContact: async (
      orgId: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('terminateClientContact', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('terminateClientContact', 'id', id);
      const localVarPath = `/clients/contacts/{orgId}/{id}/terminate`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unlock a client\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockClientProfile: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('unlockClientProfile', 'id', id);
      const localVarPath = `/clients/orgs/{id}/opstatus/unlock`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update account block
     * @param {string} id
     * @param {AccountBlockPutBody} [accountBlockPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountBlock: async (
      id: string,
      accountBlockPutBody?: AccountBlockPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateAccountBlock', 'id', id);
      const localVarPath = `/clients/settings/accountblocks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountBlockPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a client
     * @param {string} id
     * @param {Client} [client]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClient: async (
      id: string,
      client?: Client,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClient', 'id', id);
      const localVarPath = `/clients/orgs/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        client,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a client account
     * @param {string} orgId
     * @param {string} id
     * @param {AccountAdminPutBody} [accountAdminPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientAccount: async (
      orgId: string,
      id: string,
      accountAdminPutBody?: AccountAdminPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateClientAccount', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClientAccount', 'id', id);
      const localVarPath = `/clients/accounts/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accountAdminPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {ContactPutBody} [contactPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientContact: async (
      orgId: string,
      id: string,
      contactPutBody?: ContactPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateClientContact', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClientContact', 'id', id);
      const localVarPath = `/clients/contacts/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contactPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a client notification
     * @param {string} orgId
     * @param {string} id
     * @param {NotificationPostPutRequest} [notificationPostPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientNotification: async (
      orgId: string,
      id: string,
      notificationPostPutRequest?: NotificationPostPutRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateClientNotification', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClientNotification', 'id', id);
      const localVarPath = `/clients/notifications/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notificationPostPutRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update client profile
     * @param {string} id
     * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientProfile: async (
      id: string,
      clientProfilePostPutBody?: ClientProfilePostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClientProfile', 'id', id);
      const localVarPath = `/clients/settings/clientprofiles/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientProfilePostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a client organization settings, including fee accounts
     * @param {string} id
     * @param {Settings} [settings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientSettings: async (
      id: string,
      settings?: Settings,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClientSettings', 'id', id);
      const localVarPath = `/clients/orgs/{id}/settings`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        settings,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a client third party
     * @param {string} orgId
     * @param {string} id
     * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientThirdParty: async (
      orgId: string,
      id: string,
      thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateClientThirdParty', 'orgId', orgId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateClientThirdParty', 'id', id);
      const localVarPath = `/clients/thirdparties/{orgId}/{id}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        thirdPartyAdminPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update risk survey
     * @param {string} id
     * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRiskSurvey: async (
      id: string,
      riskSurveyPostPutBody?: RiskSurveyPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateRiskSurvey', 'id', id);
      const localVarPath = `/clients/settings/risksurveys/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        riskSurveyPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload a new attachment
     * @param {string} orgId
     * @param {string} type
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadClientAttachment: async (
      orgId: string,
      type: string,
      file: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('uploadClientAttachment', 'orgId', orgId);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('uploadClientAttachment', 'type', type);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('uploadClientAttachment', 'file', file);
      const localVarPath = `/clients/docs/attachments/{orgId}`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (type !== undefined) {
        localVarFormParams.append('type', type as any);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Change a client\'s operational status to Active
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateClient(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activateClient(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Activate a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateClientAccount(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountAdminBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.activateClientAccount(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Activate a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateClientContact(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.activateClientContact(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add a new client Contact
     * @param {string} orgId
     * @param {ContactPostBody} [contactPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addClientContact(
      orgId: string,
      contactPostBody?: ContactPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addClientContact(
          orgId,
          contactPostBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add a new client third party
     * @param {string} orgId
     * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addClientThirdParty(
      orgId: string,
      thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdParty>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addClientThirdParty(
          orgId,
          thirdPartyAdminPostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Approve pending updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveClientUpdates(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.approveClientUpdates(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Bless account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blessAccountBlock(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBlock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.blessAccountBlock(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Block account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async blockAccountBlock(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBlock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.blockAccountBlock(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Broadcast a new client notification to 1 or more clients
     * @param {NotificationBroadcastRequest} [notificationBroadcastRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async broadcastNotification(
      notificationBroadcastRequest?: NotificationBroadcastRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.broadcastNotification(
          notificationBroadcastRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Cancels a held client payment
     * @param {string} orgId
     * @param {string} id
     * @param {PaymentCancellation} paymentCancellation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelHeldClientPayment(
      orgId: string,
      id: string,
      paymentCancellation: PaymentCancellation,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.cancelHeldClientPayment(
          orgId,
          id,
          paymentCancellation,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Create a new account block
     * @param {AccountBlockPostBody} [accountBlockPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAccountBlock(
      accountBlockPostBody?: AccountBlockPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBlock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAccountBlock(
          accountBlockPostBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Create a new client
     * @param {ClientPostBody} [clientPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createClient(
      clientPostBody?: ClientPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createClient(
        clientPostBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add an account for a specified client
     * @param {string} orgId
     * @param {AccountAdminPostBody} [accountAdminPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createClientAccount(
      orgId: string,
      accountAdminPostBody?: AccountAdminPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createClientAccount(
          orgId,
          accountAdminPostBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Create a new client profile
     * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createClientProfile(
      clientProfilePostPutBody?: ClientProfilePostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientProfile>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createClientProfile(
          clientProfilePostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Create a new client notification
     * @param {string} orgId
     * @param {NotificationPostPutRequest} [notificationPostPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createNotification(
      orgId: string,
      notificationPostPutRequest?: NotificationPostPutRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createNotification(
          orgId,
          notificationPostPutRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Create a new risk survey
     * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRiskSurvey(
      riskSurveyPostPutBody?: RiskSurveyPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskSurvey>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createRiskSurvey(
          riskSurveyPostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Delete a client notification
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteClientNotification(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationDeleteResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteClientNotification(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Bless account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableAccountBlock(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBlock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.disableAccountBlock(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export all account blocks
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAccountBlocks(
      iban?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAccountBlocks(
          iban,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export all client accounts
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientAccounts(
      orgId: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientAccounts(
          orgId,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export activity for a client
     * @param {string} orgId
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientActivity(
      orgId: string,
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientActivity(
          orgId,
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export client contacts
     * @param {any} orgId
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientContacts(
      orgId: any,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientContacts(
          orgId,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export client third parties
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientThirdParties(
      orgId: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientThirdParties(
          orgId,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export clients accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientsAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientsAccounts(
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export activity for all clients
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientsActivity(
      timestamp?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientsActivity(
          timestamp,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export clients contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientsContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientsContacts(
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export clients third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportAllClientsThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportAllClientsThirdParties(
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export activity for a client account
     * @param {string} orgId
     * @param {string} accountId
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportClientAccountActivity(
      orgId: string,
      accountId: string,
      timestamp?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportClientAccountActivity(
          orgId,
          accountId,
          timestamp,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export client payments
     * @param {string} orgId
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportClientPayments(
      orgId: string,
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportClientPayments(
          orgId,
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export clients
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportClients(
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportClients(
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export all clients payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportClientsPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportClientsPayments(
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Generate client account statement as downloadable PDF
     * @param {string} orgId
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generateClientAccountStatement(
      orgId: string,
      id: string,
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.generateClientAccountStatement(
          orgId,
          id,
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountBlock(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBlock>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountBlock(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all account blocks
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccountBlocks(
      iban?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<AccountBlock>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAccountBlocks(
          iban,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all clients attachments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllAttachments(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllAttachments(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client account summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientAccountSummaries(
      orgId: string,
      searchtext?: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<AccountShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientAccountSummaries(
          orgId,
          searchtext,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client accounts
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientAccounts(
      orgId: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ClientAccountSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientAccounts(
          orgId,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List activity for a client
     * @param {string} orgId
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientActivity(
      orgId: string,
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Activity>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientActivity(
          orgId,
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all client attachments
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientAttachments(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientAttachments(orgId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client contact summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientContactSummaries(
      orgId: string,
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ContactShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientContactSummaries(
          orgId,
          searchtext,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client contacts
     * @param {string} orgId
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientContacts(
      orgId: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ContactSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientContacts(
          orgId,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all clients notifications
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientNotifications(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientNotifications(
          orgId,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client third parties
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientThirdParties(
      orgId: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ThirdPartyAdminSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientThirdParties(
          orgId,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client third party summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientThirdPartySummaries(
      orgId: string,
      searchtext?: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ThirdPartyShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientThirdPartySummaries(
          orgId,
          searchtext,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients account summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientsAccountSummaries(
      searchtext?: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<AccountShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientsAccountSummaries(
          searchtext,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientsAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ClientAccountSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientsAccounts(
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List activity for all clients
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientsActivity(
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Activity>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientsActivity(
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients contact summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientsContactSummaries(
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ContactShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientsContactSummaries(
          searchtext,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientsContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ContactSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientsContacts(
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientsThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ThirdPartyAdminSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientsThirdParties(
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients third parties summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllClientsThirdPartySummaries(
      searchtext?: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ThirdPartyShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllClientsThirdPartySummaries(
          searchtext,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List payment auditing entries originating from all accounts
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [fromAccountIban]
     * @param {FilterParameter} [toAccountIban]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPaymentAuditingEntries(
      timestamp?: FilterParameter,
      fromAccountIban?: FilterParameter,
      toAccountIban?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<PaymentAuditingEntry>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllPaymentAuditingEntries(
          timestamp,
          fromAccountIban,
          toAccountIban,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all compliance check lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChecklists(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Checklist>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklists(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClient(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClient(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientAccount(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountAdminBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientAccount(orgId, id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List activities for a client account
     * @param {string} orgId
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientAccountActivity(
      orgId: string,
      id: string,
      timestamp?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Activity>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientAccountActivity(
          orgId,
          id,
          timestamp,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client account summary using Iban
     * @param {string} orgId
     * @param {string} iban
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientAccountSummaryByIban(
      orgId: string,
      iban: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientAccountSummaryByIban(
          orgId,
          iban,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client account summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientAccountSummaryById(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientAccountSummaryById(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client attachment
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientAttachment(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientAttachment(orgId, id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientContact(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientContact(orgId, id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client contact summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientContactSummaryById(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ContactShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientContactSummaryById(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client\'s current review
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientCurrentReview(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientReview>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientCurrentReview(orgId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client notification
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientNotification(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientNotification(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client payment
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientPayment(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientPayment(orgId, id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client payments
     * @param {string} orgId
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientPayments(
      orgId: string,
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<PaymentSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientPayments(
          orgId,
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientProfile(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientProfile>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientProfile(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client profile summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientProfileSummaryById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ClientProfileShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientProfileSummaryById(
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client profiles
     * @param {FilterParameter} [supplier]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientProfiles(
      supplier?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ClientProfileSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientProfiles(
          supplier,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List client profile summaries
     * @param {string} [searchtext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientProfilesSummaries(
      searchtext?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ClientProfileShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientProfilesSummaries(
          searchtext,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get a client organization settings, including fee accounts
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientSettings(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientSettings(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client summary
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientSummaryById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ClientShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientSummaryById(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client third party
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientThirdParty(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ThirdPartyAdminResponseBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientThirdParty(orgId, id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get client third party summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientThirdPartySummaryById(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ThirdPartyShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientThirdPartySummaryById(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClients(
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ClientSummary>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClients(
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all clients payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientsPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<PaymentSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientsPayments(
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List clients summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientsSummaries(
      searchtext?: string,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ClientShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getClientsSummaries(
          searchtext,
          status,
          complianceStatus,
          supplier,
          legalName,
          informalName,
          incorporationLoc,
          incorporationNumber,
          incorporationDate,
          sIC,
          lastKycReview,
          clientProfileComplianceLevel,
          startDate,
          webSite,
          clientProfile,
          referrer,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProducts(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Product>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get risk survey
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRiskSurvey(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskSurvey>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskSurvey(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List risk survey summaries
     * @param {string} [searchtext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRiskSurveySummaries(
      searchtext?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RiskSurveyShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRiskSurveySummaries(
          searchtext,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get risk survey summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRiskSurveySummaryById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<RiskSurveyShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getRiskSurveySummaryById(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get all risk surveys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRiskSurveys(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<RiskSurveySummary>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskSurveys(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Lock a client\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lockClientProfile(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.lockClientProfile(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Reject pending updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectClientUpdates(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.rejectClientUpdates(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Releases a held client payment
     * @param {string} orgId
     * @param {string} id
     * @param {PaymentRelease} paymentRelease
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async releaseHeldClientPayment(
      orgId: string,
      id: string,
      paymentRelease: PaymentRelease,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.releaseHeldClientPayment(
          orgId,
          id,
          paymentRelease,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Reset a client contact password
     * @param {string} orgId
     * @param {string} id
     * @param {ResetPasswordPutBody} resetPasswordPutBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetClientContactPassword(
      orgId: string,
      id: string,
      resetPasswordPutBody: ResetPasswordPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.resetClientContactPassword(
          orgId,
          id,
          resetPasswordPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Review/make updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reviewClientUpdates(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.reviewClientUpdates(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Change a client\'s operational status to Suspended
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suspendClient(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suspendClient(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Suspend a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suspendClientAccount(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountAdminBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.suspendClientAccount(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Suspend a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suspendClientContact(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.suspendClientContact(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async terminateClient(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ClientTerminationSummary>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.terminateClient(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Terminate a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async terminateClientAccount(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountAdminBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.terminateClientAccount(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Terminate a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async terminateClientContact(
      orgId: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.terminateClientContact(
          orgId,
          id,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Unlock a client\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlockClientProfile(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.unlockClientProfile(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update account block
     * @param {string} id
     * @param {AccountBlockPutBody} [accountBlockPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAccountBlock(
      id: string,
      accountBlockPutBody?: AccountBlockPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountBlock>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateAccountBlock(
          id,
          accountBlockPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update a client
     * @param {string} id
     * @param {Client} [client]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClient(
      id: string,
      client?: Client,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateClient(
        id,
        client,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update a client account
     * @param {string} orgId
     * @param {string} id
     * @param {AccountAdminPutBody} [accountAdminPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClientAccount(
      orgId: string,
      id: string,
      accountAdminPutBody?: AccountAdminPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AccountAdminBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateClientAccount(
          orgId,
          id,
          accountAdminPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {ContactPutBody} [contactPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClientContact(
      orgId: string,
      id: string,
      contactPutBody?: ContactPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateClientContact(
          orgId,
          id,
          contactPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update a client notification
     * @param {string} orgId
     * @param {string} id
     * @param {NotificationPostPutRequest} [notificationPostPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClientNotification(
      orgId: string,
      id: string,
      notificationPostPutRequest?: NotificationPostPutRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateClientNotification(
          orgId,
          id,
          notificationPostPutRequest,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update client profile
     * @param {string} id
     * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClientProfile(
      id: string,
      clientProfilePostPutBody?: ClientProfilePostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientProfile>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateClientProfile(
          id,
          clientProfilePostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update a client organization settings, including fee accounts
     * @param {string} id
     * @param {Settings} [settings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClientSettings(
      id: string,
      settings?: Settings,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateClientSettings(
          id,
          settings,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update a client third party
     * @param {string} orgId
     * @param {string} id
     * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateClientThirdParty(
      orgId: string,
      id: string,
      thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdParty>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateClientThirdParty(
          orgId,
          id,
          thirdPartyAdminPostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update risk survey
     * @param {string} id
     * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateRiskSurvey(
      id: string,
      riskSurveyPostPutBody?: RiskSurveyPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskSurvey>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateRiskSurvey(
          id,
          riskSurveyPostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Upload a new attachment
     * @param {string} orgId
     * @param {string} type
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadClientAttachment(
      orgId: string,
      type: string,
      file: any,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadClientAttachment(
          orgId,
          type,
          file,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminApiFp(configuration);
  return {
    /**
     *
     * @summary Change a client\'s operational status to Active
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClient(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .activateClient(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Activate a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClientAccount(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<AccountAdminBody> {
      return localVarFp
        .activateClientAccount(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Activate a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateClientContact(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .activateClientContact(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add a new client Contact
     * @param {string} orgId
     * @param {ContactPostBody} [contactPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addClientContact(
      orgId: string,
      contactPostBody?: ContactPostBody,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .addClientContact(orgId, contactPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add a new client third party
     * @param {string} orgId
     * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addClientThirdParty(
      orgId: string,
      thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
      options?: any
    ): AxiosPromise<ThirdParty> {
      return localVarFp
        .addClientThirdParty(orgId, thirdPartyAdminPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Approve pending updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveClientUpdates(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .approveClientUpdates(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Bless account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blessAccountBlock(id: string, options?: any): AxiosPromise<AccountBlock> {
      return localVarFp
        .blessAccountBlock(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Block account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    blockAccountBlock(id: string, options?: any): AxiosPromise<AccountBlock> {
      return localVarFp
        .blockAccountBlock(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Broadcast a new client notification to 1 or more clients
     * @param {NotificationBroadcastRequest} [notificationBroadcastRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    broadcastNotification(
      notificationBroadcastRequest?: NotificationBroadcastRequest,
      options?: any
    ): AxiosPromise<Array<string>> {
      return localVarFp
        .broadcastNotification(notificationBroadcastRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cancels a held client payment
     * @param {string} orgId
     * @param {string} id
     * @param {PaymentCancellation} paymentCancellation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelHeldClientPayment(
      orgId: string,
      id: string,
      paymentCancellation: PaymentCancellation,
      options?: any
    ): AxiosPromise<Payment> {
      return localVarFp
        .cancelHeldClientPayment(orgId, id, paymentCancellation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new account block
     * @param {AccountBlockPostBody} [accountBlockPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAccountBlock(
      accountBlockPostBody?: AccountBlockPostBody,
      options?: any
    ): AxiosPromise<AccountBlock> {
      return localVarFp
        .createAccountBlock(accountBlockPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new client
     * @param {ClientPostBody} [clientPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClient(
      clientPostBody?: ClientPostBody,
      options?: any
    ): AxiosPromise<Client> {
      return localVarFp
        .createClient(clientPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add an account for a specified client
     * @param {string} orgId
     * @param {AccountAdminPostBody} [accountAdminPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClientAccount(
      orgId: string,
      accountAdminPostBody?: AccountAdminPostBody,
      options?: any
    ): AxiosPromise<Account> {
      return localVarFp
        .createClientAccount(orgId, accountAdminPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new client profile
     * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createClientProfile(
      clientProfilePostPutBody?: ClientProfilePostPutBody,
      options?: any
    ): AxiosPromise<ClientProfile> {
      return localVarFp
        .createClientProfile(clientProfilePostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new client notification
     * @param {string} orgId
     * @param {NotificationPostPutRequest} [notificationPostPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createNotification(
      orgId: string,
      notificationPostPutRequest?: NotificationPostPutRequest,
      options?: any
    ): AxiosPromise<NotificationResponse> {
      return localVarFp
        .createNotification(orgId, notificationPostPutRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a new risk survey
     * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRiskSurvey(
      riskSurveyPostPutBody?: RiskSurveyPostPutBody,
      options?: any
    ): AxiosPromise<RiskSurvey> {
      return localVarFp
        .createRiskSurvey(riskSurveyPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a client notification
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteClientNotification(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<NotificationDeleteResponse> {
      return localVarFp
        .deleteClientNotification(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Bless account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAccountBlock(id: string, options?: any): AxiosPromise<AccountBlock> {
      return localVarFp
        .disableAccountBlock(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export all account blocks
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAccountBlocks(
      iban?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAccountBlocks(iban, status, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export all client accounts
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientAccounts(
      orgId: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientAccounts(
          orgId,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export activity for a client
     * @param {string} orgId
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientActivity(
      orgId: string,
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientActivity(orgId, timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export client contacts
     * @param {any} orgId
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientContacts(
      orgId: any,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientContacts(
          orgId,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export client third parties
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientThirdParties(
      orgId: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientThirdParties(
          orgId,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export clients accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientsAccounts(
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export activity for all clients
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsActivity(
      timestamp?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientsActivity(timestamp, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export clients contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientsContacts(
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export clients third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportAllClientsThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportAllClientsThirdParties(
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export activity for a client account
     * @param {string} orgId
     * @param {string} accountId
     * @param {FilterParameter} [timestamp]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClientAccountActivity(
      orgId: string,
      accountId: string,
      timestamp?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportClientAccountActivity(orgId, accountId, timestamp, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export client payments
     * @param {string} orgId
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClientPayments(
      orgId: string,
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportClientPayments(
          orgId,
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export clients
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClients(
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportClients(
          status,
          complianceStatus,
          supplier,
          legalName,
          informalName,
          incorporationLoc,
          incorporationNumber,
          incorporationDate,
          sIC,
          lastKycReview,
          clientProfileComplianceLevel,
          startDate,
          webSite,
          clientProfile,
          referrer,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export all clients payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportClientsPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportClientsPayments(
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generate client account statement as downloadable PDF
     * @param {string} orgId
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generateClientAccountStatement(
      orgId: string,
      id: string,
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .generateClientAccountStatement(orgId, id, timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get account block
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountBlock(id: string, options?: any): AxiosPromise<AccountBlock> {
      return localVarFp
        .getAccountBlock(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all account blocks
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccountBlocks(
      iban?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<AccountBlock>> {
      return localVarFp
        .getAccountBlocks(iban, status, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all clients attachments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllAttachments(options?: any): AxiosPromise<Attachment> {
      return localVarFp
        .getAllAttachments(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client account summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientAccountSummaries(
      orgId: string,
      searchtext?: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<AccountShortSummary>> {
      return localVarFp
        .getAllClientAccountSummaries(
          orgId,
          searchtext,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client accounts
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientAccounts(
      orgId: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ClientAccountSummary>> {
      return localVarFp
        .getAllClientAccounts(
          orgId,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List activity for a client
     * @param {string} orgId
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientActivity(
      orgId: string,
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<Activity>> {
      return localVarFp
        .getAllClientActivity(orgId, timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all client attachments
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientAttachments(
      orgId: string,
      options?: any
    ): AxiosPromise<Attachment> {
      return localVarFp
        .getAllClientAttachments(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client contact summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientContactSummaries(
      orgId: string,
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ContactShortSummary>> {
      return localVarFp
        .getAllClientContactSummaries(
          orgId,
          searchtext,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client contacts
     * @param {string} orgId
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientContacts(
      orgId: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ContactSummary>> {
      return localVarFp
        .getAllClientContacts(
          orgId,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all clients notifications
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientNotifications(
      orgId: string,
      options?: any
    ): AxiosPromise<NotificationResponse> {
      return localVarFp
        .getAllClientNotifications(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client third parties
     * @param {string} orgId
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientThirdParties(
      orgId: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ThirdPartyAdminSummary>> {
      return localVarFp
        .getAllClientThirdParties(
          orgId,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client third party summaries
     * @param {string} orgId
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientThirdPartySummaries(
      orgId: string,
      searchtext?: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ThirdPartyShortSummary>> {
      return localVarFp
        .getAllClientThirdPartySummaries(
          orgId,
          searchtext,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients account summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsAccountSummaries(
      searchtext?: string,
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<AccountShortSummary>> {
      return localVarFp
        .getAllClientsAccountSummaries(
          searchtext,
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients accounts
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [descriptor]
     * @param {FilterParameter} [balanceCurrency]
     * @param {FilterParameter} [balanceValue]
     * @param {FilterParameter} [creationDate]
     * @param {FilterParameter} [createdBy]
     * @param {FilterParameter} [lastActivity]
     * @param {FilterParameter} [terminationDate]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [iban]
     * @param {FilterParameter} [useDynamicDescriptor]
     * @param {FilterParameter} [isThirdParty]
     * @param {FilterParameter} [utilized]
     * @param {FilterParameter} [beneficiaries]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsAccounts(
      name?: FilterParameter,
      descriptor?: FilterParameter,
      balanceCurrency?: FilterParameter,
      balanceValue?: FilterParameter,
      creationDate?: FilterParameter,
      createdBy?: FilterParameter,
      lastActivity?: FilterParameter,
      terminationDate?: FilterParameter,
      status?: FilterParameter,
      iban?: FilterParameter,
      useDynamicDescriptor?: FilterParameter,
      isThirdParty?: FilterParameter,
      utilized?: FilterParameter,
      beneficiaries?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ClientAccountSummary>> {
      return localVarFp
        .getAllClientsAccounts(
          name,
          descriptor,
          balanceCurrency,
          balanceValue,
          creationDate,
          createdBy,
          lastActivity,
          terminationDate,
          status,
          iban,
          useDynamicDescriptor,
          isThirdParty,
          utilized,
          beneficiaries,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List activity for all clients
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsActivity(
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<Activity>> {
      return localVarFp
        .getAllClientsActivity(timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients contact summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsContactSummaries(
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ContactShortSummary>> {
      return localVarFp
        .getAllClientsContactSummaries(
          searchtext,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ContactSummary>> {
      return localVarFp
        .getAllClientsContacts(
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ThirdPartyAdminSummary>> {
      return localVarFp
        .getAllClientsThirdParties(
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients third parties summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllClientsThirdPartySummaries(
      searchtext?: string,
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ThirdPartyShortSummary>> {
      return localVarFp
        .getAllClientsThirdPartySummaries(
          searchtext,
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List payment auditing entries originating from all accounts
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [fromAccountIban]
     * @param {FilterParameter} [toAccountIban]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPaymentAuditingEntries(
      timestamp?: FilterParameter,
      fromAccountIban?: FilterParameter,
      toAccountIban?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<PaymentAuditingEntry>> {
      return localVarFp
        .getAllPaymentAuditingEntries(
          timestamp,
          fromAccountIban,
          toAccountIban,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all compliance check lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChecklists(options?: any): AxiosPromise<Array<Checklist>> {
      return localVarFp
        .getChecklists(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClient(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .getClient(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccount(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<AccountAdminBody> {
      return localVarFp
        .getClientAccount(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List activities for a client account
     * @param {string} orgId
     * @param {string} id
     * @param {FilterParameter} [timestamp]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccountActivity(
      orgId: string,
      id: string,
      timestamp?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<Activity>> {
      return localVarFp
        .getClientAccountActivity(orgId, id, timestamp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client account summary using Iban
     * @param {string} orgId
     * @param {string} iban
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccountSummaryByIban(
      orgId: string,
      iban: string,
      options?: any
    ): AxiosPromise<AccountShortSummary> {
      return localVarFp
        .getClientAccountSummaryByIban(orgId, iban, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client account summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAccountSummaryById(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<AccountShortSummary> {
      return localVarFp
        .getClientAccountSummaryById(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client attachment
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientAttachment(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<Attachment> {
      return localVarFp
        .getClientAttachment(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientContact(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .getClientContact(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client contact summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientContactSummaryById(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<ContactShortSummary> {
      return localVarFp
        .getClientContactSummaryById(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client\'s current review
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientCurrentReview(
      orgId: string,
      options?: any
    ): AxiosPromise<ClientReview> {
      return localVarFp
        .getClientCurrentReview(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client notification
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientNotification(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<NotificationResponse> {
      return localVarFp
        .getClientNotification(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client payment
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientPayment(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<Payment> {
      return localVarFp
        .getClientPayment(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client payments
     * @param {string} orgId
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientPayments(
      orgId: string,
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<PaymentSummary>> {
      return localVarFp
        .getClientPayments(
          orgId,
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfile(id: string, options?: any): AxiosPromise<ClientProfile> {
      return localVarFp
        .getClientProfile(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client profile summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfileSummaryById(
      id: string,
      options?: any
    ): AxiosPromise<ClientProfileShortSummary> {
      return localVarFp
        .getClientProfileSummaryById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client profiles
     * @param {FilterParameter} [supplier]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfiles(
      supplier?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ClientProfileSummary>> {
      return localVarFp
        .getClientProfiles(supplier, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List client profile summaries
     * @param {string} [searchtext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientProfilesSummaries(
      searchtext?: string,
      options?: any
    ): AxiosPromise<Array<ClientProfileShortSummary>> {
      return localVarFp
        .getClientProfilesSummaries(searchtext, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a client organization settings, including fee accounts
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientSettings(id: string, options?: any): AxiosPromise<Settings> {
      return localVarFp
        .getClientSettings(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client summary
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientSummaryById(
      id: string,
      options?: any
    ): AxiosPromise<ClientShortSummary> {
      return localVarFp
        .getClientSummaryById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client third party
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientThirdParty(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<ThirdPartyAdminResponseBody> {
      return localVarFp
        .getClientThirdParty(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get client third party summary using Id
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientThirdPartySummaryById(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<ThirdPartyShortSummary> {
      return localVarFp
        .getClientThirdPartySummaryById(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClients(
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ClientSummary>> {
      return localVarFp
        .getClients(
          status,
          complianceStatus,
          supplier,
          legalName,
          informalName,
          incorporationLoc,
          incorporationNumber,
          incorporationDate,
          sIC,
          lastKycReview,
          clientProfileComplianceLevel,
          startDate,
          webSite,
          clientProfile,
          referrer,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all clients payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<PaymentSummary>> {
      return localVarFp
        .getClientsPayments(
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List clients summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {FilterParameter} [supplier]
     * @param {FilterParameter} [legalName]
     * @param {FilterParameter} [informalName]
     * @param {FilterParameter} [incorporationLoc]
     * @param {FilterParameter} [incorporationNumber]
     * @param {FilterParameter} [incorporationDate]
     * @param {FilterParameter} [sIC]
     * @param {FilterParameter} [lastKycReview]
     * @param {FilterParameter} [clientProfileComplianceLevel]
     * @param {FilterParameter} [startDate]
     * @param {FilterParameter} [webSite]
     * @param {FilterParameter} [clientProfile]
     * @param {FilterParameter} [referrer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientsSummaries(
      searchtext?: string,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      supplier?: FilterParameter,
      legalName?: FilterParameter,
      informalName?: FilterParameter,
      incorporationLoc?: FilterParameter,
      incorporationNumber?: FilterParameter,
      incorporationDate?: FilterParameter,
      sIC?: FilterParameter,
      lastKycReview?: FilterParameter,
      clientProfileComplianceLevel?: FilterParameter,
      startDate?: FilterParameter,
      webSite?: FilterParameter,
      clientProfile?: FilterParameter,
      referrer?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<ClientShortSummary>> {
      return localVarFp
        .getClientsSummaries(
          searchtext,
          status,
          complianceStatus,
          supplier,
          legalName,
          informalName,
          incorporationLoc,
          incorporationNumber,
          incorporationDate,
          sIC,
          lastKycReview,
          clientProfileComplianceLevel,
          startDate,
          webSite,
          clientProfile,
          referrer,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProducts(options?: any): AxiosPromise<Array<Product>> {
      return localVarFp
        .getProducts(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get risk survey
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurvey(id: string, options?: any): AxiosPromise<RiskSurvey> {
      return localVarFp
        .getRiskSurvey(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List risk survey summaries
     * @param {string} [searchtext]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurveySummaries(
      searchtext?: string,
      options?: any
    ): AxiosPromise<Array<RiskSurveyShortSummary>> {
      return localVarFp
        .getRiskSurveySummaries(searchtext, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get risk survey summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurveySummaryById(
      id: string,
      options?: any
    ): AxiosPromise<RiskSurveyShortSummary> {
      return localVarFp
        .getRiskSurveySummaryById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all risk surveys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRiskSurveys(options?: any): AxiosPromise<Array<RiskSurveySummary>> {
      return localVarFp
        .getRiskSurveys(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lock a client\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lockClientProfile(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .lockClientProfile(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reject pending updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectClientUpdates(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .rejectClientUpdates(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Releases a held client payment
     * @param {string} orgId
     * @param {string} id
     * @param {PaymentRelease} paymentRelease
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    releaseHeldClientPayment(
      orgId: string,
      id: string,
      paymentRelease: PaymentRelease,
      options?: any
    ): AxiosPromise<Payment> {
      return localVarFp
        .releaseHeldClientPayment(orgId, id, paymentRelease, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reset a client contact password
     * @param {string} orgId
     * @param {string} id
     * @param {ResetPasswordPutBody} resetPasswordPutBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetClientContactPassword(
      orgId: string,
      id: string,
      resetPasswordPutBody: ResetPasswordPutBody,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .resetClientContactPassword(orgId, id, resetPasswordPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Review/make updates to a client
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reviewClientUpdates(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .reviewClientUpdates(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Change a client\'s operational status to Suspended
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendClient(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .suspendClient(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Suspend a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendClientAccount(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<AccountAdminBody> {
      return localVarFp
        .suspendClientAccount(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Suspend a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendClientContact(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .suspendClientContact(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateClient(
      id: string,
      options?: any
    ): AxiosPromise<ClientTerminationSummary> {
      return localVarFp
        .terminateClient(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Terminate a client account
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateClientAccount(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<AccountAdminBody> {
      return localVarFp
        .terminateClientAccount(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Terminate a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateClientContact(
      orgId: string,
      id: string,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .terminateClientContact(orgId, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Unlock a client\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlockClientProfile(id: string, options?: any): AxiosPromise<Client> {
      return localVarFp
        .unlockClientProfile(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update account block
     * @param {string} id
     * @param {AccountBlockPutBody} [accountBlockPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAccountBlock(
      id: string,
      accountBlockPutBody?: AccountBlockPutBody,
      options?: any
    ): AxiosPromise<AccountBlock> {
      return localVarFp
        .updateAccountBlock(id, accountBlockPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a client
     * @param {string} id
     * @param {Client} [client]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClient(
      id: string,
      client?: Client,
      options?: any
    ): AxiosPromise<Client> {
      return localVarFp
        .updateClient(id, client, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a client account
     * @param {string} orgId
     * @param {string} id
     * @param {AccountAdminPutBody} [accountAdminPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientAccount(
      orgId: string,
      id: string,
      accountAdminPutBody?: AccountAdminPutBody,
      options?: any
    ): AxiosPromise<AccountAdminBody> {
      return localVarFp
        .updateClientAccount(orgId, id, accountAdminPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a client contact
     * @param {string} orgId
     * @param {string} id
     * @param {ContactPutBody} [contactPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientContact(
      orgId: string,
      id: string,
      contactPutBody?: ContactPutBody,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .updateClientContact(orgId, id, contactPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a client notification
     * @param {string} orgId
     * @param {string} id
     * @param {NotificationPostPutRequest} [notificationPostPutRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientNotification(
      orgId: string,
      id: string,
      notificationPostPutRequest?: NotificationPostPutRequest,
      options?: any
    ): AxiosPromise<NotificationResponse> {
      return localVarFp
        .updateClientNotification(
          orgId,
          id,
          notificationPostPutRequest,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update client profile
     * @param {string} id
     * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientProfile(
      id: string,
      clientProfilePostPutBody?: ClientProfilePostPutBody,
      options?: any
    ): AxiosPromise<ClientProfile> {
      return localVarFp
        .updateClientProfile(id, clientProfilePostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a client organization settings, including fee accounts
     * @param {string} id
     * @param {Settings} [settings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientSettings(
      id: string,
      settings?: Settings,
      options?: any
    ): AxiosPromise<Settings> {
      return localVarFp
        .updateClientSettings(id, settings, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a client third party
     * @param {string} orgId
     * @param {string} id
     * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateClientThirdParty(
      orgId: string,
      id: string,
      thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
      options?: any
    ): AxiosPromise<ThirdParty> {
      return localVarFp
        .updateClientThirdParty(orgId, id, thirdPartyAdminPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update risk survey
     * @param {string} id
     * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateRiskSurvey(
      id: string,
      riskSurveyPostPutBody?: RiskSurveyPostPutBody,
      options?: any
    ): AxiosPromise<RiskSurvey> {
      return localVarFp
        .updateRiskSurvey(id, riskSurveyPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload a new attachment
     * @param {string} orgId
     * @param {string} type
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadClientAttachment(
      orgId: string,
      type: string,
      file: any,
      options?: any
    ): AxiosPromise<Attachment> {
      return localVarFp
        .uploadClientAttachment(orgId, type, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @summary Change a client\'s operational status to Active
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public activateClient(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .activateClient(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Activate a client account
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public activateClientAccount(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .activateClientAccount(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Activate a client contact
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public activateClientContact(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .activateClientContact(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a new client Contact
   * @param {string} orgId
   * @param {ContactPostBody} [contactPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public addClientContact(
    orgId: string,
    contactPostBody?: ContactPostBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .addClientContact(orgId, contactPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a new client third party
   * @param {string} orgId
   * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public addClientThirdParty(
    orgId: string,
    thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .addClientThirdParty(orgId, thirdPartyAdminPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Approve pending updates to a client
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public approveClientUpdates(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .approveClientUpdates(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Bless account block
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public blessAccountBlock(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .blessAccountBlock(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Block account block
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public blockAccountBlock(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .blockAccountBlock(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Broadcast a new client notification to 1 or more clients
   * @param {NotificationBroadcastRequest} [notificationBroadcastRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public broadcastNotification(
    notificationBroadcastRequest?: NotificationBroadcastRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .broadcastNotification(notificationBroadcastRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancels a held client payment
   * @param {string} orgId
   * @param {string} id
   * @param {PaymentCancellation} paymentCancellation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public cancelHeldClientPayment(
    orgId: string,
    id: string,
    paymentCancellation: PaymentCancellation,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .cancelHeldClientPayment(orgId, id, paymentCancellation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new account block
   * @param {AccountBlockPostBody} [accountBlockPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createAccountBlock(
    accountBlockPostBody?: AccountBlockPostBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createAccountBlock(accountBlockPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new client
   * @param {ClientPostBody} [clientPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createClient(
    clientPostBody?: ClientPostBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createClient(clientPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add an account for a specified client
   * @param {string} orgId
   * @param {AccountAdminPostBody} [accountAdminPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createClientAccount(
    orgId: string,
    accountAdminPostBody?: AccountAdminPostBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createClientAccount(orgId, accountAdminPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new client profile
   * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createClientProfile(
    clientProfilePostPutBody?: ClientProfilePostPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createClientProfile(clientProfilePostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new client notification
   * @param {string} orgId
   * @param {NotificationPostPutRequest} [notificationPostPutRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createNotification(
    orgId: string,
    notificationPostPutRequest?: NotificationPostPutRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createNotification(orgId, notificationPostPutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a new risk survey
   * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public createRiskSurvey(
    riskSurveyPostPutBody?: RiskSurveyPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .createRiskSurvey(riskSurveyPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a client notification
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public deleteClientNotification(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .deleteClientNotification(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Bless account block
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public disableAccountBlock(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .disableAccountBlock(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export all account blocks
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAccountBlocks(
    iban?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAccountBlocks(iban, status, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export all client accounts
   * @param {string} orgId
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientAccounts(
    orgId: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientAccounts(
        orgId,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export activity for a client
   * @param {string} orgId
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientActivity(
    orgId: string,
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientActivity(orgId, timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export client contacts
   * @param {any} orgId
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientContacts(
    orgId: any,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientContacts(
        orgId,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export client third parties
   * @param {string} orgId
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientThirdParties(
    orgId: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientThirdParties(
        orgId,
        name,
        type,
        status,
        complianceStatus,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export clients accounts
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientsAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientsAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export activity for all clients
   * @param {FilterParameter} [timestamp]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientsActivity(
    timestamp?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientsActivity(timestamp, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export clients contacts
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientsContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientsContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export clients third parties
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportAllClientsThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportAllClientsThirdParties(
        name,
        type,
        status,
        complianceStatus,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export activity for a client account
   * @param {string} orgId
   * @param {string} accountId
   * @param {FilterParameter} [timestamp]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportClientAccountActivity(
    orgId: string,
    accountId: string,
    timestamp?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportClientAccountActivity(orgId, accountId, timestamp, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export client payments
   * @param {string} orgId
   * @param {FilterParameter} [amountCurrency]
   * @param {FilterParameter} [amountValue]
   * @param {FilterParameter} [_class]
   * @param {FilterParameter} [fromIban]
   * @param {FilterParameter} [fromName]
   * @param {FilterParameter} [toIban]
   * @param {FilterParameter} [toName]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [timeStamp]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [id]
   * @param {FilterParameter} [clearingSystem]
   * @param {FilterParameter} [clearingSystemTxId]
   * @param {FilterParameter} [instructionId]
   * @param {FilterParameter} [description]
   * @param {FilterParameter} [senderReference]
   * @param {FilterParameter} [batchReference]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportClientPayments(
    orgId: string,
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportClientPayments(
        orgId,
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export clients
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {FilterParameter} [supplier]
   * @param {FilterParameter} [legalName]
   * @param {FilterParameter} [informalName]
   * @param {FilterParameter} [incorporationLoc]
   * @param {FilterParameter} [incorporationNumber]
   * @param {FilterParameter} [incorporationDate]
   * @param {FilterParameter} [sIC]
   * @param {FilterParameter} [lastKycReview]
   * @param {FilterParameter} [clientProfileComplianceLevel]
   * @param {FilterParameter} [startDate]
   * @param {FilterParameter} [webSite]
   * @param {FilterParameter} [clientProfile]
   * @param {FilterParameter} [referrer]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportClients(
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportClients(
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export all clients payments
   * @param {FilterParameter} [amountCurrency]
   * @param {FilterParameter} [amountValue]
   * @param {FilterParameter} [_class]
   * @param {FilterParameter} [fromIban]
   * @param {FilterParameter} [fromName]
   * @param {FilterParameter} [toIban]
   * @param {FilterParameter} [toName]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [timeStamp]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [id]
   * @param {FilterParameter} [clearingSystem]
   * @param {FilterParameter} [clearingSystemTxId]
   * @param {FilterParameter} [instructionId]
   * @param {FilterParameter} [description]
   * @param {FilterParameter} [senderReference]
   * @param {FilterParameter} [batchReference]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public exportClientsPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .exportClientsPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generate client account statement as downloadable PDF
   * @param {string} orgId
   * @param {string} id
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public generateClientAccountStatement(
    orgId: string,
    id: string,
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .generateClientAccountStatement(orgId, id, timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get account block
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAccountBlock(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getAccountBlock(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all account blocks
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAccountBlocks(
    iban?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAccountBlocks(iban, status, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all clients attachments
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllAttachments(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getAllAttachments(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client account summaries
   * @param {string} orgId
   * @param {string} [searchtext]
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientAccountSummaries(
    orgId: string,
    searchtext?: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientAccountSummaries(
        orgId,
        searchtext,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client accounts
   * @param {string} orgId
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientAccounts(
    orgId: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientAccounts(
        orgId,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List activity for a client
   * @param {string} orgId
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientActivity(
    orgId: string,
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientActivity(orgId, timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all client attachments
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientAttachments(orgId: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getAllClientAttachments(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client contact summaries
   * @param {string} orgId
   * @param {string} [searchtext]
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientContactSummaries(
    orgId: string,
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientContactSummaries(
        orgId,
        searchtext,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client contacts
   * @param {string} orgId
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientContacts(
    orgId: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientContacts(
        orgId,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all clients notifications
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientNotifications(
    orgId: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientNotifications(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client third parties
   * @param {string} orgId
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientThirdParties(
    orgId: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientThirdParties(
        orgId,
        name,
        type,
        status,
        complianceStatus,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client third party summaries
   * @param {string} orgId
   * @param {string} [searchtext]
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientThirdPartySummaries(
    orgId: string,
    searchtext?: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientThirdPartySummaries(
        orgId,
        searchtext,
        name,
        type,
        status,
        complianceStatus,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients account summaries
   * @param {string} [searchtext]
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientsAccountSummaries(
    searchtext?: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientsAccountSummaries(
        searchtext,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients accounts
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [descriptor]
   * @param {FilterParameter} [balanceCurrency]
   * @param {FilterParameter} [balanceValue]
   * @param {FilterParameter} [creationDate]
   * @param {FilterParameter} [createdBy]
   * @param {FilterParameter} [lastActivity]
   * @param {FilterParameter} [terminationDate]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [iban]
   * @param {FilterParameter} [useDynamicDescriptor]
   * @param {FilterParameter} [isThirdParty]
   * @param {FilterParameter} [utilized]
   * @param {FilterParameter} [beneficiaries]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientsAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientsAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List activity for all clients
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientsActivity(
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientsActivity(timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients contact summaries
   * @param {string} [searchtext]
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientsContactSummaries(
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientsContactSummaries(
        searchtext,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients contacts
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientsContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientsContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients third parties
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientsThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientsThirdParties(
        name,
        type,
        status,
        complianceStatus,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients third parties summaries
   * @param {string} [searchtext]
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllClientsThirdPartySummaries(
    searchtext?: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllClientsThirdPartySummaries(
        searchtext,
        name,
        type,
        status,
        complianceStatus,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List payment auditing entries originating from all accounts
   * @param {FilterParameter} [timestamp]
   * @param {FilterParameter} [fromAccountIban]
   * @param {FilterParameter} [toAccountIban]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getAllPaymentAuditingEntries(
    timestamp?: FilterParameter,
    fromAccountIban?: FilterParameter,
    toAccountIban?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getAllPaymentAuditingEntries(
        timestamp,
        fromAccountIban,
        toAccountIban,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all compliance check lists
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getChecklists(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getChecklists(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClient(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getClient(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client account
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientAccount(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientAccount(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List activities for a client account
   * @param {string} orgId
   * @param {string} id
   * @param {FilterParameter} [timestamp]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientAccountActivity(
    orgId: string,
    id: string,
    timestamp?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientAccountActivity(orgId, id, timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client account summary using Iban
   * @param {string} orgId
   * @param {string} iban
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientAccountSummaryByIban(
    orgId: string,
    iban: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientAccountSummaryByIban(orgId, iban, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client account summary using Id
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientAccountSummaryById(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientAccountSummaryById(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client attachment
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientAttachment(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientAttachment(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client contact
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientContact(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientContact(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client contact summary using Id
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientContactSummaryById(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientContactSummaryById(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client\'s current review
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientCurrentReview(orgId: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getClientCurrentReview(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client notification
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientNotification(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientNotification(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client payment
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientPayment(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientPayment(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client payments
   * @param {string} orgId
   * @param {FilterParameter} [amountCurrency]
   * @param {FilterParameter} [amountValue]
   * @param {FilterParameter} [_class]
   * @param {FilterParameter} [fromIban]
   * @param {FilterParameter} [fromName]
   * @param {FilterParameter} [toIban]
   * @param {FilterParameter} [toName]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [timeStamp]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [id]
   * @param {FilterParameter} [clearingSystem]
   * @param {FilterParameter} [clearingSystemTxId]
   * @param {FilterParameter} [instructionId]
   * @param {FilterParameter} [description]
   * @param {FilterParameter} [senderReference]
   * @param {FilterParameter} [batchReference]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientPayments(
    orgId: string,
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientPayments(
        orgId,
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client profile
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientProfile(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getClientProfile(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client profile summary using Id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientProfileSummaryById(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getClientProfileSummaryById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client profiles
   * @param {FilterParameter} [supplier]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientProfiles(
    supplier?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientProfiles(supplier, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List client profile summaries
   * @param {string} [searchtext]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientProfilesSummaries(
    searchtext?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientProfilesSummaries(searchtext, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a client organization settings, including fee accounts
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientSettings(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getClientSettings(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client summary
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientSummaryById(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getClientSummaryById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client third party
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientThirdParty(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientThirdParty(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get client third party summary using Id
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientThirdPartySummaryById(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientThirdPartySummaryById(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {FilterParameter} [supplier]
   * @param {FilterParameter} [legalName]
   * @param {FilterParameter} [informalName]
   * @param {FilterParameter} [incorporationLoc]
   * @param {FilterParameter} [incorporationNumber]
   * @param {FilterParameter} [incorporationDate]
   * @param {FilterParameter} [sIC]
   * @param {FilterParameter} [lastKycReview]
   * @param {FilterParameter} [clientProfileComplianceLevel]
   * @param {FilterParameter} [startDate]
   * @param {FilterParameter} [webSite]
   * @param {FilterParameter} [clientProfile]
   * @param {FilterParameter} [referrer]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClients(
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClients(
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all clients payments
   * @param {FilterParameter} [amountCurrency]
   * @param {FilterParameter} [amountValue]
   * @param {FilterParameter} [_class]
   * @param {FilterParameter} [fromIban]
   * @param {FilterParameter} [fromName]
   * @param {FilterParameter} [toIban]
   * @param {FilterParameter} [toName]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [timeStamp]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [id]
   * @param {FilterParameter} [clearingSystem]
   * @param {FilterParameter} [clearingSystemTxId]
   * @param {FilterParameter} [instructionId]
   * @param {FilterParameter} [description]
   * @param {FilterParameter} [senderReference]
   * @param {FilterParameter} [batchReference]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientsPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientsPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List clients summaries
   * @param {string} [searchtext]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {FilterParameter} [supplier]
   * @param {FilterParameter} [legalName]
   * @param {FilterParameter} [informalName]
   * @param {FilterParameter} [incorporationLoc]
   * @param {FilterParameter} [incorporationNumber]
   * @param {FilterParameter} [incorporationDate]
   * @param {FilterParameter} [sIC]
   * @param {FilterParameter} [lastKycReview]
   * @param {FilterParameter} [clientProfileComplianceLevel]
   * @param {FilterParameter} [startDate]
   * @param {FilterParameter} [webSite]
   * @param {FilterParameter} [clientProfile]
   * @param {FilterParameter} [referrer]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getClientsSummaries(
    searchtext?: string,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getClientsSummaries(
        searchtext,
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all products
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getProducts(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getProducts(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get risk survey
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getRiskSurvey(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getRiskSurvey(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List risk survey summaries
   * @param {string} [searchtext]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getRiskSurveySummaries(
    searchtext?: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .getRiskSurveySummaries(searchtext, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get risk survey summary using Id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getRiskSurveySummaryById(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getRiskSurveySummaryById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all risk surveys
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public getRiskSurveys(options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .getRiskSurveys(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Lock a client\'s profile
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public lockClientProfile(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .lockClientProfile(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reject pending updates to a client
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public rejectClientUpdates(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .rejectClientUpdates(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Releases a held client payment
   * @param {string} orgId
   * @param {string} id
   * @param {PaymentRelease} paymentRelease
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public releaseHeldClientPayment(
    orgId: string,
    id: string,
    paymentRelease: PaymentRelease,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .releaseHeldClientPayment(orgId, id, paymentRelease, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reset a client contact password
   * @param {string} orgId
   * @param {string} id
   * @param {ResetPasswordPutBody} resetPasswordPutBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public resetClientContactPassword(
    orgId: string,
    id: string,
    resetPasswordPutBody: ResetPasswordPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .resetClientContactPassword(orgId, id, resetPasswordPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Review/make updates to a client
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public reviewClientUpdates(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .reviewClientUpdates(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Change a client\'s operational status to Suspended
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public suspendClient(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .suspendClient(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Suspend a client account
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public suspendClientAccount(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .suspendClientAccount(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Suspend a client contact
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public suspendClientContact(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .suspendClientContact(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public terminateClient(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .terminateClient(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Terminate a client account
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public terminateClientAccount(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .terminateClientAccount(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Terminate a client contact
   * @param {string} orgId
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public terminateClientContact(
    orgId: string,
    id: string,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .terminateClientContact(orgId, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unlock a client\'s profile
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public unlockClientProfile(id: string, options?: AxiosRequestConfig) {
    return AdminApiFp(this.configuration)
      .unlockClientProfile(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update account block
   * @param {string} id
   * @param {AccountBlockPutBody} [accountBlockPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateAccountBlock(
    id: string,
    accountBlockPutBody?: AccountBlockPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateAccountBlock(id, accountBlockPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a client
   * @param {string} id
   * @param {Client} [client]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateClient(
    id: string,
    client?: Client,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateClient(id, client, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a client account
   * @param {string} orgId
   * @param {string} id
   * @param {AccountAdminPutBody} [accountAdminPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateClientAccount(
    orgId: string,
    id: string,
    accountAdminPutBody?: AccountAdminPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateClientAccount(orgId, id, accountAdminPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a client contact
   * @param {string} orgId
   * @param {string} id
   * @param {ContactPutBody} [contactPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateClientContact(
    orgId: string,
    id: string,
    contactPutBody?: ContactPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateClientContact(orgId, id, contactPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a client notification
   * @param {string} orgId
   * @param {string} id
   * @param {NotificationPostPutRequest} [notificationPostPutRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateClientNotification(
    orgId: string,
    id: string,
    notificationPostPutRequest?: NotificationPostPutRequest,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateClientNotification(orgId, id, notificationPostPutRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update client profile
   * @param {string} id
   * @param {ClientProfilePostPutBody} [clientProfilePostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateClientProfile(
    id: string,
    clientProfilePostPutBody?: ClientProfilePostPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateClientProfile(id, clientProfilePostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a client organization settings, including fee accounts
   * @param {string} id
   * @param {Settings} [settings]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateClientSettings(
    id: string,
    settings?: Settings,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateClientSettings(id, settings, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a client third party
   * @param {string} orgId
   * @param {string} id
   * @param {ThirdPartyAdminPostPutBody} [thirdPartyAdminPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateClientThirdParty(
    orgId: string,
    id: string,
    thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateClientThirdParty(orgId, id, thirdPartyAdminPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update risk survey
   * @param {string} id
   * @param {RiskSurveyPostPutBody} [riskSurveyPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public updateRiskSurvey(
    id: string,
    riskSurveyPostPutBody?: RiskSurveyPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .updateRiskSurvey(id, riskSurveyPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload a new attachment
   * @param {string} orgId
   * @param {string} type
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public uploadClientAttachment(
    orgId: string,
    type: string,
    file: any,
    options?: AxiosRequestConfig
  ) {
    return AdminApiFp(this.configuration)
      .uploadClientAttachment(orgId, type, file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ContactsApi - axios parameter creator
 * @export
 */
export const ContactsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Activate a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateContact: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('activateContact', 'id', id);
      const localVarPath = `/contacts/{id}/activate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a contact
     * @param {ContactPostBody} [contactPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContact: async (
      contactPostBody?: ContactPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contactPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a contact application
     * @param {string} id
     * @param {ApplicationPostBody} [applicationPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserApplication: async (
      id: string,
      applicationPostBody?: ApplicationPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createUserApplication', 'id', id);
      const localVarPath = `/contacts/{id}/apps`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete a contact application
     * @param {string} id
     * @param {string} appid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserApplication: async (
      id: string,
      appid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteUserApplication', 'id', id);
      // verify required parameter 'appid' is not null or undefined
      assertParamExists('deleteUserApplication', 'appid', appid);
      const localVarPath = `/contacts/{id}/apps/{appid}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'appid'}}`, encodeURIComponent(String(appid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportContacts: async (
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContact: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getContact', 'id', id);
      const localVarPath = `/contacts/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get contact summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContactById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getContactById', 'id', id);
      const localVarPath = `/contactbyid/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get contact summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContactSummaries: async (
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContacts: async (
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (loginEmail !== undefined) {
        localVarQueryParameter['LoginEmail'] = loginEmail;
      }

      if (familyName !== undefined) {
        localVarQueryParameter['FamilyName'] = familyName;
      }

      if (firstName !== undefined) {
        localVarQueryParameter['FirstName'] = firstName;
      }

      if (dob !== undefined) {
        localVarQueryParameter['Dob'] = dob;
      }

      if (nationality !== undefined) {
        localVarQueryParameter['Nationality'] = nationality;
      }

      if (ownershipPercentage !== undefined) {
        localVarQueryParameter['OwnershipPercentage'] = ownershipPercentage;
      }

      if (authorities !== undefined) {
        localVarQueryParameter['Authorities'] = authorities;
      }

      if (roles !== undefined) {
        localVarQueryParameter['Roles'] = roles;
      }

      if (pEP !== undefined) {
        localVarQueryParameter['PEP'] = pEP;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of a contact application
     * @param {string} id
     * @param {string} appid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserApplication: async (
      id: string,
      appid: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUserApplication', 'id', id);
      // verify required parameter 'appid' is not null or undefined
      assertParamExists('getUserApplication', 'appid', appid);
      const localVarPath = `/contacts/{id}/apps/{appid}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'appid'}}`, encodeURIComponent(String(appid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a contact\'s applications
     * @param {string} id
     * @param {FilterParameter} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserApplications: async (
      id: string,
      status?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUserApplications', 'id', id);
      const localVarPath = `/contacts/{id}/apps`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a contact\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettings: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUserSettings', 'id', id);
      const localVarPath = `/contacts/{id}/profile`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reset a contact\'s password
     * @param {string} id
     * @param {PasswordChangeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword: async (
      id: string,
      body: PasswordChangeBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('resetPassword', 'id', id);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('resetPassword', 'body', body);
      const localVarPath = `/contacts/{id}/resetpassword`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Suspend a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendContact: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('suspendContact', 'id', id);
      const localVarPath = `/contacts/{id}/suspend`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Terminate a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateContact: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('terminateContact', 'id', id);
      const localVarPath = `/contacts/{id}/terminate`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update properties of a contact
     * @param {string} id
     * @param {ContactPutBody} [contactPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContact: async (
      id: string,
      contactPutBody?: ContactPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateContact', 'id', id);
      const localVarPath = `/contacts/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        contactPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a contact\'s profile
     * @param {string} id
     * @param {UserPutBody} [userPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserSettings: async (
      id: string,
      userPutBody?: UserPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateUserSettings', 'id', id);
      const localVarPath = `/contacts/{id}/profile`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ContactsApi - functional programming interface
 * @export
 */
export const ContactsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ContactsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Activate a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activateContact(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activateContact(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add a contact
     * @param {ContactPostBody} [contactPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createContact(
      contactPostBody?: ContactPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(
        contactPostBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add a contact application
     * @param {string} id
     * @param {ApplicationPostBody} [applicationPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUserApplication(
      id: string,
      applicationPostBody?: ApplicationPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApplicationRegistrationResponseBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createUserApplication(
          id,
          applicationPostBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Delete a contact application
     * @param {string} id
     * @param {string} appid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserApplication(
      id: string,
      appid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ApplicationRegistrationResponseBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteUserApplication(
          id,
          appid,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContact(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContact(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get contact summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContactById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ContactShortSummary>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContactById(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get contact summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContactSummaries(
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ContactShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getContactSummaries(
          searchtext,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contact>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of a contact application
     * @param {string} id
     * @param {string} appid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserApplication(
      id: string,
      appid: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserApplication(id, appid, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get a contact\'s applications
     * @param {string} id
     * @param {FilterParameter} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserApplications(
      id: string,
      status?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Application>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUserApplications(
          id,
          status,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get a contact\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserSettings(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserResponseBody>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSettings(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Reset a contact\'s password
     * @param {string} id
     * @param {PasswordChangeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPassword(
      id: string,
      body: PasswordChangeBody,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PasswordChangeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(
        id,
        body,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Suspend a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async suspendContact(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.suspendContact(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Terminate a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async terminateContact(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.terminateContact(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update properties of a contact
     * @param {string} id
     * @param {ContactPutBody} [contactPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateContact(
      id: string,
      contactPutBody?: ContactPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(
        id,
        contactPutBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update a contact\'s profile
     * @param {string} id
     * @param {UserPutBody} [userPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUserSettings(
      id: string,
      userPutBody?: UserPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<UserResponseBody>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateUserSettings(
          id,
          userPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ContactsApi - factory interface
 * @export
 */
export const ContactsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ContactsApiFp(configuration);
  return {
    /**
     *
     * @summary Activate a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activateContact(id: string, options?: any): AxiosPromise<Contact> {
      return localVarFp
        .activateContact(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add a contact
     * @param {ContactPostBody} [contactPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createContact(
      contactPostBody?: ContactPostBody,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .createContact(contactPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add a contact application
     * @param {string} id
     * @param {ApplicationPostBody} [applicationPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUserApplication(
      id: string,
      applicationPostBody?: ApplicationPostBody,
      options?: any
    ): AxiosPromise<ApplicationRegistrationResponseBody> {
      return localVarFp
        .createUserApplication(id, applicationPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete a contact application
     * @param {string} id
     * @param {string} appid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserApplication(
      id: string,
      appid: string,
      options?: any
    ): AxiosPromise<ApplicationRegistrationResponseBody> {
      return localVarFp
        .deleteUserApplication(id, appid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportContacts(
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContact(id: string, options?: any): AxiosPromise<Contact> {
      return localVarFp
        .getContact(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get contact summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContactById(
      id: string,
      options?: any
    ): AxiosPromise<ContactShortSummary> {
      return localVarFp
        .getContactById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get contact summaries
     * @param {string} [searchtext]
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContactSummaries(
      searchtext?: string,
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ContactShortSummary>> {
      return localVarFp
        .getContactSummaries(
          searchtext,
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List contacts
     * @param {FilterParameter} [loginEmail]
     * @param {FilterParameter} [familyName]
     * @param {FilterParameter} [firstName]
     * @param {FilterParameter} [dob]
     * @param {FilterParameter} [nationality]
     * @param {FilterParameter} [ownershipPercentage]
     * @param {FilterParameter} [authorities]
     * @param {FilterParameter} [roles]
     * @param {FilterParameter} [pEP]
     * @param {FilterParameter} [status]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContacts(
      loginEmail?: FilterParameter,
      familyName?: FilterParameter,
      firstName?: FilterParameter,
      dob?: FilterParameter,
      nationality?: FilterParameter,
      ownershipPercentage?: FilterParameter,
      authorities?: FilterParameter,
      roles?: FilterParameter,
      pEP?: FilterParameter,
      status?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<Contact>> {
      return localVarFp
        .getContacts(
          loginEmail,
          familyName,
          firstName,
          dob,
          nationality,
          ownershipPercentage,
          authorities,
          roles,
          pEP,
          status,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of a contact application
     * @param {string} id
     * @param {string} appid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserApplication(
      id: string,
      appid: string,
      options?: any
    ): AxiosPromise<Application> {
      return localVarFp
        .getUserApplication(id, appid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a contact\'s applications
     * @param {string} id
     * @param {FilterParameter} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserApplications(
      id: string,
      status?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<Application>> {
      return localVarFp
        .getUserApplications(id, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a contact\'s profile
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettings(id: string, options?: any): AxiosPromise<UserResponseBody> {
      return localVarFp
        .getUserSettings(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reset a contact\'s password
     * @param {string} id
     * @param {PasswordChangeBody} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      id: string,
      body: PasswordChangeBody,
      options?: any
    ): AxiosPromise<PasswordChangeResponse> {
      return localVarFp
        .resetPassword(id, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Suspend a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    suspendContact(id: string, options?: any): AxiosPromise<Contact> {
      return localVarFp
        .suspendContact(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Terminate a contact
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    terminateContact(id: string, options?: any): AxiosPromise<Contact> {
      return localVarFp
        .terminateContact(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update properties of a contact
     * @param {string} id
     * @param {ContactPutBody} [contactPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateContact(
      id: string,
      contactPutBody?: ContactPutBody,
      options?: any
    ): AxiosPromise<Contact> {
      return localVarFp
        .updateContact(id, contactPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a contact\'s profile
     * @param {string} id
     * @param {UserPutBody} [userPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUserSettings(
      id: string,
      userPutBody?: UserPutBody,
      options?: any
    ): AxiosPromise<UserResponseBody> {
      return localVarFp
        .updateUserSettings(id, userPutBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ContactsApi - object-oriented interface
 * @export
 * @class ContactsApi
 * @extends {BaseAPI}
 */
export class ContactsApi extends BaseAPI {
  /**
   *
   * @summary Activate a contact
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public activateContact(id: string, options?: AxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .activateContact(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a contact
   * @param {ContactPostBody} [contactPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public createContact(
    contactPostBody?: ContactPostBody,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .createContact(contactPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a contact application
   * @param {string} id
   * @param {ApplicationPostBody} [applicationPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public createUserApplication(
    id: string,
    applicationPostBody?: ApplicationPostBody,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .createUserApplication(id, applicationPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete a contact application
   * @param {string} id
   * @param {string} appid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public deleteUserApplication(
    id: string,
    appid: string,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .deleteUserApplication(id, appid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export contacts
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public exportContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .exportContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of a contact
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getContact(id: string, options?: AxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .getContact(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get contact summary using Id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getContactById(id: string, options?: AxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .getContactById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get contact summaries
   * @param {string} [searchtext]
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getContactSummaries(
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .getContactSummaries(
        searchtext,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List contacts
   * @param {FilterParameter} [loginEmail]
   * @param {FilterParameter} [familyName]
   * @param {FilterParameter} [firstName]
   * @param {FilterParameter} [dob]
   * @param {FilterParameter} [nationality]
   * @param {FilterParameter} [ownershipPercentage]
   * @param {FilterParameter} [authorities]
   * @param {FilterParameter} [roles]
   * @param {FilterParameter} [pEP]
   * @param {FilterParameter} [status]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .getContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of a contact application
   * @param {string} id
   * @param {string} appid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getUserApplication(
    id: string,
    appid: string,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .getUserApplication(id, appid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a contact\'s applications
   * @param {string} id
   * @param {FilterParameter} [status]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getUserApplications(
    id: string,
    status?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .getUserApplications(id, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a contact\'s profile
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public getUserSettings(id: string, options?: AxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .getUserSettings(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reset a contact\'s password
   * @param {string} id
   * @param {PasswordChangeBody} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public resetPassword(
    id: string,
    body: PasswordChangeBody,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .resetPassword(id, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Suspend a contact
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public suspendContact(id: string, options?: AxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .suspendContact(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Terminate a contact
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public terminateContact(id: string, options?: AxiosRequestConfig) {
    return ContactsApiFp(this.configuration)
      .terminateContact(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update properties of a contact
   * @param {string} id
   * @param {ContactPutBody} [contactPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public updateContact(
    id: string,
    contactPutBody?: ContactPutBody,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .updateContact(id, contactPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a contact\'s profile
   * @param {string} id
   * @param {UserPutBody} [userPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ContactsApi
   */
  public updateUserSettings(
    id: string,
    userPutBody?: UserPutBody,
    options?: AxiosRequestConfig
  ) {
    return ContactsApiFp(this.configuration)
      .updateUserSettings(id, userPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get properties of an attachment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAttachment: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAttachment', 'id', id);
      const localVarPath = `/docs/attachments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List attachments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAttachments: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/docs/attachments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Uploads a new attachment
     * @param {string} type
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAttachment: async (
      type: string,
      file: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('uploadAttachment', 'type', type);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('uploadAttachment', 'file', file);
      const localVarPath = `/docs/attachments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (type !== undefined) {
        localVarFormParams.append('type', type as any);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DocumentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get properties of an attachment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAttachment(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachment(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List attachments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAttachments(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<Attachment>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachments(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Uploads a new attachment
     * @param {string} type
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadAttachment(
      type: string,
      file: any,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attachment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.uploadAttachment(type, file, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DocumentsApiFp(configuration);
  return {
    /**
     *
     * @summary Get properties of an attachment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAttachment(id: string, options?: any): AxiosPromise<Attachment> {
      return localVarFp
        .getAttachment(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List attachments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAttachments(options?: any): AxiosPromise<Array<Attachment>> {
      return localVarFp
        .getAttachments(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Uploads a new attachment
     * @param {string} type
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadAttachment(
      type: string,
      file: any,
      options?: any
    ): AxiosPromise<Attachment> {
      return localVarFp
        .uploadAttachment(type, file, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
  /**
   *
   * @summary Get properties of an attachment
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public getAttachment(id: string, options?: AxiosRequestConfig) {
    return DocumentsApiFp(this.configuration)
      .getAttachment(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List attachments
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public getAttachments(options?: AxiosRequestConfig) {
    return DocumentsApiFp(this.configuration)
      .getAttachments(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Uploads a new attachment
   * @param {string} type
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public uploadAttachment(
    type: string,
    file: any,
    options?: AxiosRequestConfig
  ) {
    return DocumentsApiFp(this.configuration)
      .uploadAttachment(type, file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MiscApi - axios parameter creator
 * @export
 */
export const MiscApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Retrieve various codes ( eg clearing systems, payment types)
     * @param {'accounts' | 'beneficiarytypes' | 'clearingsystems' | 'clientdocumenttypes' | 'contactdocumenttypes' | 'corporateregistries' | 'countrycodes' | 'currencycodes' | 'govtidtypes' | 'govtcontactidtypes' | 'govtcompanyidtypes' | 'govtidissuers' | 'govtcontactidissuers' | 'govtcompanyidissuers' | 'jurisdictions' | 'paymenttypes' | 'producttypes' | 'regulators' | 'siccodes' | 'sizes' | 'suppliers'} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCodes: async (
      code:
        | 'accounts'
        | 'beneficiarytypes'
        | 'clearingsystems'
        | 'clientdocumenttypes'
        | 'contactdocumenttypes'
        | 'corporateregistries'
        | 'countrycodes'
        | 'currencycodes'
        | 'govtidtypes'
        | 'govtcontactidtypes'
        | 'govtcompanyidtypes'
        | 'govtidissuers'
        | 'govtcontactidissuers'
        | 'govtcompanyidissuers'
        | 'jurisdictions'
        | 'paymenttypes'
        | 'producttypes'
        | 'regulators'
        | 'siccodes'
        | 'sizes'
        | 'suppliers',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('getCodes', 'code', code);
      const localVarPath = `/codes/{code}`.replace(
        `{${'code'}}`,
        encodeURIComponent(String(code))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve all countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountries: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieve all currencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencies: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/currencies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStats: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stats`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MiscApi - functional programming interface
 * @export
 */
export const MiscApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MiscApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Retrieve various codes ( eg clearing systems, payment types)
     * @param {'accounts' | 'beneficiarytypes' | 'clearingsystems' | 'clientdocumenttypes' | 'contactdocumenttypes' | 'corporateregistries' | 'countrycodes' | 'currencycodes' | 'govtidtypes' | 'govtcontactidtypes' | 'govtcompanyidtypes' | 'govtidissuers' | 'govtcontactidissuers' | 'govtcompanyidissuers' | 'jurisdictions' | 'paymenttypes' | 'producttypes' | 'regulators' | 'siccodes' | 'sizes' | 'suppliers'} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCodes(
      code:
        | 'accounts'
        | 'beneficiarytypes'
        | 'clearingsystems'
        | 'clientdocumenttypes'
        | 'contactdocumenttypes'
        | 'corporateregistries'
        | 'countrycodes'
        | 'currencycodes'
        | 'govtidtypes'
        | 'govtcontactidtypes'
        | 'govtcompanyidtypes'
        | 'govtidissuers'
        | 'govtcontactidissuers'
        | 'govtcompanyidissuers'
        | 'jurisdictions'
        | 'paymenttypes'
        | 'producttypes'
        | 'regulators'
        | 'siccodes'
        | 'sizes'
        | 'suppliers',
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Code>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCodes(
        code,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Retrieve all countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCountries(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CountryDetails>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Retrieve all currencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrencies(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<CurrencyDetails>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrencies(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStats(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Stats>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStats(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * MiscApi - factory interface
 * @export
 */
export const MiscApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MiscApiFp(configuration);
  return {
    /**
     *
     * @summary Retrieve various codes ( eg clearing systems, payment types)
     * @param {'accounts' | 'beneficiarytypes' | 'clearingsystems' | 'clientdocumenttypes' | 'contactdocumenttypes' | 'corporateregistries' | 'countrycodes' | 'currencycodes' | 'govtidtypes' | 'govtcontactidtypes' | 'govtcompanyidtypes' | 'govtidissuers' | 'govtcontactidissuers' | 'govtcompanyidissuers' | 'jurisdictions' | 'paymenttypes' | 'producttypes' | 'regulators' | 'siccodes' | 'sizes' | 'suppliers'} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCodes(
      code:
        | 'accounts'
        | 'beneficiarytypes'
        | 'clearingsystems'
        | 'clientdocumenttypes'
        | 'contactdocumenttypes'
        | 'corporateregistries'
        | 'countrycodes'
        | 'currencycodes'
        | 'govtidtypes'
        | 'govtcontactidtypes'
        | 'govtcompanyidtypes'
        | 'govtidissuers'
        | 'govtcontactidissuers'
        | 'govtcompanyidissuers'
        | 'jurisdictions'
        | 'paymenttypes'
        | 'producttypes'
        | 'regulators'
        | 'siccodes'
        | 'sizes'
        | 'suppliers',
      options?: any
    ): AxiosPromise<Array<Code>> {
      return localVarFp
        .getCodes(code, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve all countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountries(options?: any): AxiosPromise<Array<CountryDetails>> {
      return localVarFp
        .getCountries(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieve all currencies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencies(options?: any): AxiosPromise<Array<CurrencyDetails>> {
      return localVarFp
        .getCurrencies(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStats(options?: any): AxiosPromise<Stats> {
      return localVarFp
        .getStats(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MiscApi - object-oriented interface
 * @export
 * @class MiscApi
 * @extends {BaseAPI}
 */
export class MiscApi extends BaseAPI {
  /**
   *
   * @summary Retrieve various codes ( eg clearing systems, payment types)
   * @param {'accounts' | 'beneficiarytypes' | 'clearingsystems' | 'clientdocumenttypes' | 'contactdocumenttypes' | 'corporateregistries' | 'countrycodes' | 'currencycodes' | 'govtidtypes' | 'govtcontactidtypes' | 'govtcompanyidtypes' | 'govtidissuers' | 'govtcontactidissuers' | 'govtcompanyidissuers' | 'jurisdictions' | 'paymenttypes' | 'producttypes' | 'regulators' | 'siccodes' | 'sizes' | 'suppliers'} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiscApi
   */
  public getCodes(
    code:
      | 'accounts'
      | 'beneficiarytypes'
      | 'clearingsystems'
      | 'clientdocumenttypes'
      | 'contactdocumenttypes'
      | 'corporateregistries'
      | 'countrycodes'
      | 'currencycodes'
      | 'govtidtypes'
      | 'govtcontactidtypes'
      | 'govtcompanyidtypes'
      | 'govtidissuers'
      | 'govtcontactidissuers'
      | 'govtcompanyidissuers'
      | 'jurisdictions'
      | 'paymenttypes'
      | 'producttypes'
      | 'regulators'
      | 'siccodes'
      | 'sizes'
      | 'suppliers',
    options?: AxiosRequestConfig
  ) {
    return MiscApiFp(this.configuration)
      .getCodes(code, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve all countries
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiscApi
   */
  public getCountries(options?: AxiosRequestConfig) {
    return MiscApiFp(this.configuration)
      .getCountries(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieve all currencies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiscApi
   */
  public getCurrencies(options?: AxiosRequestConfig) {
    return MiscApiFp(this.configuration)
      .getCurrencies(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get stats
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MiscApi
   */
  public getStats(options?: AxiosRequestConfig) {
    return MiscApiFp(this.configuration)
      .getStats(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary List all notifications
     * @param {FilterParameter} [read]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNotifications: async (
      read?: FilterParameter,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (read !== undefined) {
        localVarQueryParameter['Read'] = read;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of a notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotification: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getNotification', 'id', id);
      const localVarPath = `/notifications/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Marks one or more Notifications as read
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markNotificationsAsRead: async (
      inlineObject2?: InlineObject2,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/notifications/markasread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject2,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List all notifications
     * @param {FilterParameter} [read]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllNotifications(
      read?: FilterParameter,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<NotificationResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllNotifications(read, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of a notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotification(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<NotificationResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNotification(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Marks one or more Notifications as read
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markNotificationsAsRead(
      inlineObject2?: InlineObject2,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<NotificationResponse>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.markNotificationsAsRead(
          inlineObject2,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationsApiFp(configuration);
  return {
    /**
     *
     * @summary List all notifications
     * @param {FilterParameter} [read]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllNotifications(
      read?: FilterParameter,
      options?: any
    ): AxiosPromise<Array<NotificationResponse>> {
      return localVarFp
        .getAllNotifications(read, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of a notification
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotification(
      id: string,
      options?: any
    ): AxiosPromise<NotificationResponse> {
      return localVarFp
        .getNotification(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Marks one or more Notifications as read
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markNotificationsAsRead(
      inlineObject2?: InlineObject2,
      options?: any
    ): AxiosPromise<Array<NotificationResponse>> {
      return localVarFp
        .markNotificationsAsRead(inlineObject2, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
  /**
   *
   * @summary List all notifications
   * @param {FilterParameter} [read]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public getAllNotifications(
    read?: FilterParameter,
    options?: AxiosRequestConfig
  ) {
    return NotificationsApiFp(this.configuration)
      .getAllNotifications(read, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of a notification
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public getNotification(id: string, options?: AxiosRequestConfig) {
    return NotificationsApiFp(this.configuration)
      .getNotification(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Marks one or more Notifications as read
   * @param {InlineObject2} [inlineObject2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public markNotificationsAsRead(
    inlineObject2?: InlineObject2,
    options?: AxiosRequestConfig
  ) {
    return NotificationsApiFp(this.configuration)
      .markNotificationsAsRead(inlineObject2, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get organization profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/orgs/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get organization settings, including fee accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/orgs/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register as a new client
     * @param {ClientRegistrationPostBody} [clientRegistrationPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register: async (
      clientRegistrationPostBody?: ClientRegistrationPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/orgs/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clientRegistrationPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update properties of an organization\'s profile
     * @param {ProfilePutBody} [profilePutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile: async (
      profilePutBody?: ProfilePutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/orgs/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update organization settings, including fee accounts
     * @param {Settings} [settings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSettings: async (
      settings?: Settings,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/orgs/settings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        settings,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    OrganizationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get organization profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProfile(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get organization settings, including fee accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSettings(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Register as a new client
     * @param {ClientRegistrationPostBody} [clientRegistrationPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register(
      clientRegistrationPostBody?: ClientRegistrationPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ClientRegistrationSummary>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register(
        clientRegistrationPostBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update properties of an organization\'s profile
     * @param {ProfilePutBody} [profilePutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProfile(
      profilePutBody?: ProfilePutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(
        profilePutBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update organization settings, including fee accounts
     * @param {Settings} [settings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateSettings(
      settings?: Settings,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Settings>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(
        settings,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OrganizationApiFp(configuration);
  return {
    /**
     *
     * @summary Get organization profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(options?: any): AxiosPromise<Profile> {
      return localVarFp
        .getProfile(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get organization settings, including fee accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSettings(options?: any): AxiosPromise<Settings> {
      return localVarFp
        .getSettings(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Register as a new client
     * @param {ClientRegistrationPostBody} [clientRegistrationPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(
      clientRegistrationPostBody?: ClientRegistrationPostBody,
      options?: any
    ): AxiosPromise<ClientRegistrationSummary> {
      return localVarFp
        .register(clientRegistrationPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update properties of an organization\'s profile
     * @param {ProfilePutBody} [profilePutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(
      profilePutBody?: ProfilePutBody,
      options?: any
    ): AxiosPromise<Profile> {
      return localVarFp
        .updateProfile(profilePutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update organization settings, including fee accounts
     * @param {Settings} [settings]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSettings(settings?: Settings, options?: any): AxiosPromise<Settings> {
      return localVarFp
        .updateSettings(settings, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
  /**
   *
   * @summary Get organization profile
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public getProfile(options?: AxiosRequestConfig) {
    return OrganizationApiFp(this.configuration)
      .getProfile(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get organization settings, including fee accounts
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public getSettings(options?: AxiosRequestConfig) {
    return OrganizationApiFp(this.configuration)
      .getSettings(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register as a new client
   * @param {ClientRegistrationPostBody} [clientRegistrationPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public register(
    clientRegistrationPostBody?: ClientRegistrationPostBody,
    options?: AxiosRequestConfig
  ) {
    return OrganizationApiFp(this.configuration)
      .register(clientRegistrationPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update properties of an organization\'s profile
   * @param {ProfilePutBody} [profilePutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public updateProfile(
    profilePutBody?: ProfilePutBody,
    options?: AxiosRequestConfig
  ) {
    return OrganizationApiFp(this.configuration)
      .updateProfile(profilePutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update organization settings, including fee accounts
   * @param {Settings} [settings]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public updateSettings(settings?: Settings, options?: AxiosRequestConfig) {
    return OrganizationApiFp(this.configuration)
      .updateSettings(settings, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Uploads a batch of payments
     * @param {string} [batchreference]
     * @param {any} [batchfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBatch: async (
      batchreference?: string,
      batchfile?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/payments/batchload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (batchreference !== undefined) {
        localVarFormParams.append('batchreference', batchreference as any);
      }

      if (batchfile !== undefined) {
        localVarFormParams.append('batchfile', batchfile as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a payment
     * @param {PaymentPostBody} [paymentPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayment: async (
      paymentPostBody?: PaymentPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/payments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a payment
     * @param {PaymentWithLookupPostBody} [paymentWithLookupPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaymentWithLookup: async (
      paymentWithLookupPostBody?: PaymentWithLookupPostBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/payments/withlookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentWithLookupPostBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export payment batches
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPaymentBatches: async (
      timestamp?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/payments/batches/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPayments: async (
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/payments/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (amountCurrency !== undefined) {
        localVarQueryParameter['Amount.Currency'] = amountCurrency;
      }

      if (amountValue !== undefined) {
        localVarQueryParameter['Amount.Value'] = amountValue;
      }

      if (_class !== undefined) {
        localVarQueryParameter['Class'] = _class;
      }

      if (fromIban !== undefined) {
        localVarQueryParameter['From.Iban'] = fromIban;
      }

      if (fromName !== undefined) {
        localVarQueryParameter['From.Name'] = fromName;
      }

      if (toIban !== undefined) {
        localVarQueryParameter['To.Iban'] = toIban;
      }

      if (toName !== undefined) {
        localVarQueryParameter['To.Name'] = toName;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (timeStamp !== undefined) {
        localVarQueryParameter['TimeStamp'] = timeStamp;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (clearingSystem !== undefined) {
        localVarQueryParameter['ClearingSystem'] = clearingSystem;
      }

      if (clearingSystemTxId !== undefined) {
        localVarQueryParameter['ClearingSystemTxId'] = clearingSystemTxId;
      }

      if (instructionId !== undefined) {
        localVarQueryParameter['InstructionId'] = instructionId;
      }

      if (description !== undefined) {
        localVarQueryParameter['Description'] = description;
      }

      if (senderReference !== undefined) {
        localVarQueryParameter['SenderReference'] = senderReference;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get batch details (payment request summaries)
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatch: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getBatch', 'id', id);
      const localVarPath = `/payments/batches/{id}/full`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List batches
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatches: async (
      timestamp?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/payments/batches`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (timestamp !== undefined) {
        localVarQueryParameter['Timestamp'] = timestamp;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of a payment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayment: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPayment', 'id', id);
      const localVarPath = `/payments/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayments: async (
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/payments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (amountCurrency !== undefined) {
        localVarQueryParameter['Amount.Currency'] = amountCurrency;
      }

      if (amountValue !== undefined) {
        localVarQueryParameter['Amount.Value'] = amountValue;
      }

      if (_class !== undefined) {
        localVarQueryParameter['Class'] = _class;
      }

      if (fromIban !== undefined) {
        localVarQueryParameter['From.Iban'] = fromIban;
      }

      if (fromName !== undefined) {
        localVarQueryParameter['From.Name'] = fromName;
      }

      if (toIban !== undefined) {
        localVarQueryParameter['To.Iban'] = toIban;
      }

      if (toName !== undefined) {
        localVarQueryParameter['To.Name'] = toName;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (timeStamp !== undefined) {
        localVarQueryParameter['TimeStamp'] = timeStamp;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id;
      }

      if (clearingSystem !== undefined) {
        localVarQueryParameter['ClearingSystem'] = clearingSystem;
      }

      if (clearingSystemTxId !== undefined) {
        localVarQueryParameter['ClearingSystemTxId'] = clearingSystemTxId;
      }

      if (instructionId !== undefined) {
        localVarQueryParameter['InstructionId'] = instructionId;
      }

      if (description !== undefined) {
        localVarQueryParameter['Description'] = description;
      }

      if (senderReference !== undefined) {
        localVarQueryParameter['SenderReference'] = senderReference;
      }

      if (batchReference !== undefined) {
        localVarQueryParameter['BatchReference'] = batchReference;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Uploads a batch of payments
     * @param {string} [batchreference]
     * @param {any} [batchfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBatch(
      batchreference?: string,
      batchfile?: any,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<BatchLoadRequest>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBatch(
        batchreference,
        batchfile,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add a payment
     * @param {PaymentPostBody} [paymentPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPayment(
      paymentPostBody?: PaymentPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPayment(
        paymentPostBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add a payment
     * @param {PaymentWithLookupPostBody} [paymentWithLookupPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPaymentWithLookup(
      paymentWithLookupPostBody?: PaymentWithLookupPostBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createPaymentWithLookup(
          paymentWithLookupPostBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export payment batches
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportPaymentBatches(
      timestamp?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportPaymentBatches(
          timestamp,
          batchReference,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get batch details (payment request summaries)
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatch(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Batch>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBatch(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List batches
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBatches(
      timestamp?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Batch>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBatches(
        timestamp,
        batchReference,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of a payment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayment(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPayment(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payment>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PaymentsApiFp(configuration);
  return {
    /**
     *
     * @summary Uploads a batch of payments
     * @param {string} [batchreference]
     * @param {any} [batchfile]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBatch(
      batchreference?: string,
      batchfile?: any,
      options?: any
    ): AxiosPromise<Array<BatchLoadRequest>> {
      return localVarFp
        .createBatch(batchreference, batchfile, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add a payment
     * @param {PaymentPostBody} [paymentPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPayment(
      paymentPostBody?: PaymentPostBody,
      options?: any
    ): AxiosPromise<Payment> {
      return localVarFp
        .createPayment(paymentPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add a payment
     * @param {PaymentWithLookupPostBody} [paymentWithLookupPostBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPaymentWithLookup(
      paymentWithLookupPostBody?: PaymentWithLookupPostBody,
      options?: any
    ): AxiosPromise<Payment> {
      return localVarFp
        .createPaymentWithLookup(paymentWithLookupPostBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export payment batches
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPaymentBatches(
      timestamp?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportPaymentBatches(timestamp, batchReference, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportPayments(
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get batch details (payment request summaries)
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatch(id: string, options?: any): AxiosPromise<Batch> {
      return localVarFp
        .getBatch(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List batches
     * @param {FilterParameter} [timestamp]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBatches(
      timestamp?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<Batch>> {
      return localVarFp
        .getBatches(timestamp, batchReference, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of a payment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayment(id: string, options?: any): AxiosPromise<Payment> {
      return localVarFp
        .getPayment(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List payments
     * @param {FilterParameter} [amountCurrency]
     * @param {FilterParameter} [amountValue]
     * @param {FilterParameter} [_class]
     * @param {FilterParameter} [fromIban]
     * @param {FilterParameter} [fromName]
     * @param {FilterParameter} [toIban]
     * @param {FilterParameter} [toName]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [timeStamp]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [id]
     * @param {FilterParameter} [clearingSystem]
     * @param {FilterParameter} [clearingSystemTxId]
     * @param {FilterParameter} [instructionId]
     * @param {FilterParameter} [description]
     * @param {FilterParameter} [senderReference]
     * @param {FilterParameter} [batchReference]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPayments(
      amountCurrency?: FilterParameter,
      amountValue?: FilterParameter,
      _class?: FilterParameter,
      fromIban?: FilterParameter,
      fromName?: FilterParameter,
      toIban?: FilterParameter,
      toName?: FilterParameter,
      status?: FilterParameter,
      timeStamp?: FilterParameter,
      type?: FilterParameter,
      id?: FilterParameter,
      clearingSystem?: FilterParameter,
      clearingSystemTxId?: FilterParameter,
      instructionId?: FilterParameter,
      description?: FilterParameter,
      senderReference?: FilterParameter,
      batchReference?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<Payment>> {
      return localVarFp
        .getPayments(
          amountCurrency,
          amountValue,
          _class,
          fromIban,
          fromName,
          toIban,
          toName,
          status,
          timeStamp,
          type,
          id,
          clearingSystem,
          clearingSystemTxId,
          instructionId,
          description,
          senderReference,
          batchReference,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
  /**
   *
   * @summary Uploads a batch of payments
   * @param {string} [batchreference]
   * @param {any} [batchfile]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public createBatch(
    batchreference?: string,
    batchfile?: any,
    options?: AxiosRequestConfig
  ) {
    return PaymentsApiFp(this.configuration)
      .createBatch(batchreference, batchfile, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a payment
   * @param {PaymentPostBody} [paymentPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public createPayment(
    paymentPostBody?: PaymentPostBody,
    options?: AxiosRequestConfig
  ) {
    return PaymentsApiFp(this.configuration)
      .createPayment(paymentPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a payment
   * @param {PaymentWithLookupPostBody} [paymentWithLookupPostBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public createPaymentWithLookup(
    paymentWithLookupPostBody?: PaymentWithLookupPostBody,
    options?: AxiosRequestConfig
  ) {
    return PaymentsApiFp(this.configuration)
      .createPaymentWithLookup(paymentWithLookupPostBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export payment batches
   * @param {FilterParameter} [timestamp]
   * @param {FilterParameter} [batchReference]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public exportPaymentBatches(
    timestamp?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return PaymentsApiFp(this.configuration)
      .exportPaymentBatches(timestamp, batchReference, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export payments
   * @param {FilterParameter} [amountCurrency]
   * @param {FilterParameter} [amountValue]
   * @param {FilterParameter} [_class]
   * @param {FilterParameter} [fromIban]
   * @param {FilterParameter} [fromName]
   * @param {FilterParameter} [toIban]
   * @param {FilterParameter} [toName]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [timeStamp]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [id]
   * @param {FilterParameter} [clearingSystem]
   * @param {FilterParameter} [clearingSystemTxId]
   * @param {FilterParameter} [instructionId]
   * @param {FilterParameter} [description]
   * @param {FilterParameter} [senderReference]
   * @param {FilterParameter} [batchReference]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public exportPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return PaymentsApiFp(this.configuration)
      .exportPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get batch details (payment request summaries)
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public getBatch(id: string, options?: AxiosRequestConfig) {
    return PaymentsApiFp(this.configuration)
      .getBatch(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List batches
   * @param {FilterParameter} [timestamp]
   * @param {FilterParameter} [batchReference]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public getBatches(
    timestamp?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return PaymentsApiFp(this.configuration)
      .getBatches(timestamp, batchReference, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of a payment
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public getPayment(id: string, options?: AxiosRequestConfig) {
    return PaymentsApiFp(this.configuration)
      .getPayment(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List payments
   * @param {FilterParameter} [amountCurrency]
   * @param {FilterParameter} [amountValue]
   * @param {FilterParameter} [_class]
   * @param {FilterParameter} [fromIban]
   * @param {FilterParameter} [fromName]
   * @param {FilterParameter} [toIban]
   * @param {FilterParameter} [toName]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [timeStamp]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [id]
   * @param {FilterParameter} [clearingSystem]
   * @param {FilterParameter} [clearingSystemTxId]
   * @param {FilterParameter} [instructionId]
   * @param {FilterParameter} [description]
   * @param {FilterParameter} [senderReference]
   * @param {FilterParameter} [batchReference]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public getPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return PaymentsApiFp(this.configuration)
      .getPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ThirdPartiesApi - axios parameter creator
 * @export
 */
export const ThirdPartiesApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add a third party
     * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createThirdParty: async (
      thirdPartyPostPutBody?: ThirdPartyPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/thirdparties`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        thirdPartyPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportThirdParties: async (
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/thirdparties/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdParties: async (
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/thirdparties`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of third party
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdParty: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getThirdParty', 'id', id);
      const localVarPath = `/thirdparties/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get third party summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdPartyById: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getThirdPartyById', 'id', id);
      const localVarPath = `/thirdpartybyid/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get third party summaries
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [searchtext]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdPartySummaries: async (
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      searchtext?: string,
      sort?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/thirdparties/summaries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (name !== undefined) {
        localVarQueryParameter['Name'] = name;
      }

      if (type !== undefined) {
        localVarQueryParameter['Type'] = type;
      }

      if (status !== undefined) {
        localVarQueryParameter['Status'] = status;
      }

      if (complianceStatus !== undefined) {
        localVarQueryParameter['ComplianceStatus'] = complianceStatus;
      }

      if (searchtext !== undefined) {
        localVarQueryParameter['searchtext'] = searchtext;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update properties of a third party
     * @param {string} id
     * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateThirdParty: async (
      id: string,
      thirdPartyPostPutBody?: ThirdPartyPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateThirdParty', 'id', id);
      const localVarPath = `/thirdparties/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        thirdPartyPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ThirdPartiesApi - functional programming interface
 * @export
 */
export const ThirdPartiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ThirdPartiesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add a third party
     * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createThirdParty(
      thirdPartyPostPutBody?: ThirdPartyPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdParty>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createThirdParty(
          thirdPartyPostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Export third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportThirdParties(
          name,
          type,
          status,
          complianceStatus,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary List third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ThirdPartySummary>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getThirdParties(
        name,
        type,
        status,
        complianceStatus,
        sort,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of third party
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getThirdParty(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdParty>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getThirdParty(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get third party summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getThirdPartyById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ThirdPartyShortSummary>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getThirdPartyById(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get third party summaries
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [searchtext]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getThirdPartySummaries(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      searchtext?: string,
      sort?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<ThirdPartyShortSummary>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getThirdPartySummaries(
          name,
          type,
          status,
          complianceStatus,
          searchtext,
          sort,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update properties of a third party
     * @param {string} id
     * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateThirdParty(
      id: string,
      thirdPartyPostPutBody?: ThirdPartyPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThirdParty>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateThirdParty(
          id,
          thirdPartyPostPutBody,
          options
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * ThirdPartiesApi - factory interface
 * @export
 */
export const ThirdPartiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ThirdPartiesApiFp(configuration);
  return {
    /**
     *
     * @summary Add a third party
     * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createThirdParty(
      thirdPartyPostPutBody?: ThirdPartyPostPutBody,
      options?: any
    ): AxiosPromise<ThirdParty> {
      return localVarFp
        .createThirdParty(thirdPartyPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<ExportResponse> {
      return localVarFp
        .exportThirdParties(name, type, status, complianceStatus, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List third parties
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdParties(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ThirdPartySummary>> {
      return localVarFp
        .getThirdParties(name, type, status, complianceStatus, sort, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of third party
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdParty(id: string, options?: any): AxiosPromise<ThirdParty> {
      return localVarFp
        .getThirdParty(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get third party summary using Id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdPartyById(
      id: string,
      options?: any
    ): AxiosPromise<ThirdPartyShortSummary> {
      return localVarFp
        .getThirdPartyById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get third party summaries
     * @param {FilterParameter} [name]
     * @param {FilterParameter} [type]
     * @param {FilterParameter} [status]
     * @param {FilterParameter} [complianceStatus]
     * @param {string} [searchtext]
     * @param {string} [sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getThirdPartySummaries(
      name?: FilterParameter,
      type?: FilterParameter,
      status?: FilterParameter,
      complianceStatus?: FilterParameter,
      searchtext?: string,
      sort?: string,
      options?: any
    ): AxiosPromise<Array<ThirdPartyShortSummary>> {
      return localVarFp
        .getThirdPartySummaries(
          name,
          type,
          status,
          complianceStatus,
          searchtext,
          sort,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update properties of a third party
     * @param {string} id
     * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateThirdParty(
      id: string,
      thirdPartyPostPutBody?: ThirdPartyPostPutBody,
      options?: any
    ): AxiosPromise<ThirdParty> {
      return localVarFp
        .updateThirdParty(id, thirdPartyPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ThirdPartiesApi - object-oriented interface
 * @export
 * @class ThirdPartiesApi
 * @extends {BaseAPI}
 */
export class ThirdPartiesApi extends BaseAPI {
  /**
   *
   * @summary Add a third party
   * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ThirdPartiesApi
   */
  public createThirdParty(
    thirdPartyPostPutBody?: ThirdPartyPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return ThirdPartiesApiFp(this.configuration)
      .createThirdParty(thirdPartyPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export third parties
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ThirdPartiesApi
   */
  public exportThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return ThirdPartiesApiFp(this.configuration)
      .exportThirdParties(name, type, status, complianceStatus, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List third parties
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ThirdPartiesApi
   */
  public getThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return ThirdPartiesApiFp(this.configuration)
      .getThirdParties(name, type, status, complianceStatus, sort, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of third party
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ThirdPartiesApi
   */
  public getThirdParty(id: string, options?: AxiosRequestConfig) {
    return ThirdPartiesApiFp(this.configuration)
      .getThirdParty(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get third party summary using Id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ThirdPartiesApi
   */
  public getThirdPartyById(id: string, options?: AxiosRequestConfig) {
    return ThirdPartiesApiFp(this.configuration)
      .getThirdPartyById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get third party summaries
   * @param {FilterParameter} [name]
   * @param {FilterParameter} [type]
   * @param {FilterParameter} [status]
   * @param {FilterParameter} [complianceStatus]
   * @param {string} [searchtext]
   * @param {string} [sort]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ThirdPartiesApi
   */
  public getThirdPartySummaries(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    searchtext?: string,
    sort?: string,
    options?: AxiosRequestConfig
  ) {
    return ThirdPartiesApiFp(this.configuration)
      .getThirdPartySummaries(
        name,
        type,
        status,
        complianceStatus,
        searchtext,
        sort,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update properties of a third party
   * @param {string} id
   * @param {ThirdPartyPostPutBody} [thirdPartyPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ThirdPartiesApi
   */
  public updateThirdParty(
    id: string,
    thirdPartyPostPutBody?: ThirdPartyPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return ThirdPartiesApiFp(this.configuration)
      .updateThirdParty(id, thirdPartyPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary List webhooks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksGet: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/webhooks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Remove a webhook
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksIdDelete: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('webhooksIdDelete', 'id', id);
      const localVarPath = `/webhooks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get properties of a webhook
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksIdGet: async (
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('webhooksIdGet', 'id', id);
      const localVarPath = `/webhooks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update properties of a webhook
     * @param {string} id
     * @param {WebhookPostPutBody} [webhookPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksIdPut: async (
      id: string,
      webhookPostPutBody?: WebhookPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('webhooksIdPut', 'id', id);
      const localVarPath = `/webhooks/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        webhookPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add a webhook
     * @param {WebhookPostPutBody} [webhookPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksPost: async (
      webhookPostPutBody?: WebhookPostPutBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/webhooks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        webhookPostPutBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List webhooks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksGet(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Webhook>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksGet(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Remove a webhook
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksIdDelete(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.webhooksIdDelete(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Get properties of a webhook
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksIdGet(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksIdGet(
        id,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Update properties of a webhook
     * @param {string} id
     * @param {WebhookPostPutBody} [webhookPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksIdPut(
      id: string,
      webhookPostPutBody?: WebhookPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksIdPut(
        id,
        webhookPostPutBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     *
     * @summary Add a webhook
     * @param {WebhookPostPutBody} [webhookPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksPost(
      webhookPostPutBody?: WebhookPostPutBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Webhook>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webhooksPost(
        webhookPostPutBody,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WebhooksApiFp(configuration);
  return {
    /**
     *
     * @summary List webhooks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksGet(options?: any): AxiosPromise<Array<Webhook>> {
      return localVarFp
        .webhooksGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Remove a webhook
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksIdDelete(id: string, options?: any): AxiosPromise<Webhook> {
      return localVarFp
        .webhooksIdDelete(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get properties of a webhook
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksIdGet(id: string, options?: any): AxiosPromise<Webhook> {
      return localVarFp
        .webhooksIdGet(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update properties of a webhook
     * @param {string} id
     * @param {WebhookPostPutBody} [webhookPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksIdPut(
      id: string,
      webhookPostPutBody?: WebhookPostPutBody,
      options?: any
    ): AxiosPromise<Webhook> {
      return localVarFp
        .webhooksIdPut(id, webhookPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add a webhook
     * @param {WebhookPostPutBody} [webhookPostPutBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksPost(
      webhookPostPutBody?: WebhookPostPutBody,
      options?: any
    ): AxiosPromise<Webhook> {
      return localVarFp
        .webhooksPost(webhookPostPutBody, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
  /**
   *
   * @summary List webhooks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksGet(options?: AxiosRequestConfig) {
    return WebhooksApiFp(this.configuration)
      .webhooksGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Remove a webhook
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksIdDelete(id: string, options?: AxiosRequestConfig) {
    return WebhooksApiFp(this.configuration)
      .webhooksIdDelete(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get properties of a webhook
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksIdGet(id: string, options?: AxiosRequestConfig) {
    return WebhooksApiFp(this.configuration)
      .webhooksIdGet(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update properties of a webhook
   * @param {string} id
   * @param {WebhookPostPutBody} [webhookPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksIdPut(
    id: string,
    webhookPostPutBody?: WebhookPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return WebhooksApiFp(this.configuration)
      .webhooksIdPut(id, webhookPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add a webhook
   * @param {WebhookPostPutBody} [webhookPostPutBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksPost(
    webhookPostPutBody?: WebhookPostPutBody,
    options?: AxiosRequestConfig
  ) {
    return WebhooksApiFp(this.configuration)
      .webhooksPost(webhookPostPutBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * Arguments for the AccessControlApi.ChangePassword API query
 */
export type AccessControlApiChangePasswordArgs = [
  body: PasswordChangeBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccessControlApi.RefreshToken API query
 */
export type AccessControlApiRefreshTokenArgs = [
  axiosOptions?: AxiosRequestConfig
];

/**
 * AccessControlApi - react-query query key creator
 */
export const AccessControlApiQueryKey = {
  /**
   *
   * @summary Change a contact\'s own password
   */
  changePasswordRoot: 'AccessControlApiChangePassword' as QueryKey,

  /**
   *
   * @summary Change a contact\'s own password
   */
  changePassword(body: PasswordChangeBody): QueryKey {
    return ['AccessControlApiChangePassword', body];
  },
  /**
   *
   * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
   */
  refreshTokenRoot: 'AccessControlApiRefreshToken' as QueryKey,

  /**
   *
   * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
   */
  refreshToken(): QueryKey {
    return ['AccessControlApiRefreshToken'];
  },
};

/**
 * AccessControlApi - react-query method creator
 */
export class AccessControlApiMaker extends BaseApiMaker<AccessControlApi> {
  /**
   * Get an AccessControlApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): AccessControlApi {
    return new AccessControlApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Change a contact\'s own password
   * @memberof AccessControlApi
   */
  public changePassword(
    body: PasswordChangeBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<PasswordChangeResponse> {
    return {
      queryKey: AccessControlApiQueryKey.changePassword(body),
      queryFn: (queryOptions) =>
        this.runQueryFn<PasswordChangeResponse>((api) =>
          api.changePassword(body, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Change a contact\'s own password
   * @memberof AccessControlApi
   */
  public changePasswordMutation(): ApiMutationHandler<
    AccessControlApiChangePasswordArgs,
    PasswordChangeResponse
  > {
    return {
      mutationKey: 'AccessControlApiChangePassword',
      mutationFn: (
        body: PasswordChangeBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<PasswordChangeResponse>((api) =>
          api.changePassword(body, options)
        ),
    };
  }

  /**
   *
   * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
   * @memberof AccessControlApi
   */
  public refreshToken(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Token> {
    return {
      queryKey: AccessControlApiQueryKey.refreshToken(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Token>((api) =>
          api.refreshToken(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get a time limited bearer token for a contact, using either basic authorization (for initial login) or bearer authorization (for extending a login period)
   * @memberof AccessControlApi
   */
  public refreshTokenMutation(): ApiMutationHandler<
    AccessControlApiRefreshTokenArgs,
    Token
  > {
    return {
      mutationKey: 'AccessControlApiRefreshToken',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Token>((api) => api.refreshToken(options)),
    };
  }
}

/**
 * AccessControlApi - react-query api
 */
export function useAccessControlApi(
  options?: ApiMethodMakerOptions
): AccessControlApiMaker {
  return useApiMethodMaker<AccessControlApiMaker>(
    AccessControlApiMaker,
    options
  );
}

/**
 * Arguments for the AccountsApi.AccountsWhitelistsGet API query
 */
export type AccountsApiAccountsWhitelistsGetArgs = [
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.AccountsWhitelistsIdDelete API query
 */
export type AccountsApiAccountsWhitelistsIdDeleteArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.AccountsWhitelistsIdGet API query
 */
export type AccountsApiAccountsWhitelistsIdGetArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.AccountsWhitelistsPost API query
 */
export type AccountsApiAccountsWhitelistsPostArgs = [
  accountWhitelistPostBody?: AccountWhitelistPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.ActivateAccount API query
 */
export type AccountsApiActivateAccountArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.CreateAccount API query
 */
export type AccountsApiCreateAccountArgs = [
  accountPostBody?: AccountPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.ExportAccountActivity API query
 */
export type AccountsApiExportAccountActivityArgs = [
  id: string,
  timestamp?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.ExportAccounts API query
 */
export type AccountsApiExportAccountsArgs = [
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GenerateAccountStatement API query
 */
export type AccountsApiGenerateAccountStatementArgs = [
  id: string,
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetAccount API query
 */
export type AccountsApiGetAccountArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetAccountActivity API query
 */
export type AccountsApiGetAccountActivityArgs = [
  id: string,
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetAccountSummaryByIban API query
 */
export type AccountsApiGetAccountSummaryByIbanArgs = [
  iban: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetAccountSummaryById API query
 */
export type AccountsApiGetAccountSummaryByIdArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetAccounts API query
 */
export type AccountsApiGetAccountsArgs = [
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetAllAccountSummaries API query
 */
export type AccountsApiGetAllAccountSummariesArgs = [
  searchtext?: string,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  status?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetAllAccountsActivity API query
 */
export type AccountsApiGetAllAccountsActivityArgs = [
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.GetNumAccounts API query
 */
export type AccountsApiGetNumAccountsArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the AccountsApi.SuspendAccount API query
 */
export type AccountsApiSuspendAccountArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.TerminateAccount API query
 */
export type AccountsApiTerminateAccountArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AccountsApi.UpdateAccount API query
 */
export type AccountsApiUpdateAccountArgs = [
  id: string,
  accountPutBody?: AccountPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * AccountsApi - react-query query key creator
 */
export const AccountsApiQueryKey = {
  /**
   *
   * @summary List account whitelists
   */
  accountsWhitelistsGetRoot: 'AccountsApiAccountsWhitelistsGet' as QueryKey,

  /**
   *
   * @summary List account whitelists
   */
  accountsWhitelistsGet(): QueryKey {
    return ['AccountsApiAccountsWhitelistsGet'];
  },
  /**
   *
   * @summary Remove an account whitelist
   */
  accountsWhitelistsIdDeleteRoot:
    'AccountsApiAccountsWhitelistsIdDelete' as QueryKey,

  /**
   *
   * @summary Remove an account whitelist
   */
  accountsWhitelistsIdDelete(id: string): QueryKey {
    return ['AccountsApiAccountsWhitelistsIdDelete', id];
  },
  /**
   *
   * @summary Get properties of an account whitelist
   */
  accountsWhitelistsIdGetRoot: 'AccountsApiAccountsWhitelistsIdGet' as QueryKey,

  /**
   *
   * @summary Get properties of an account whitelist
   */
  accountsWhitelistsIdGet(id: string): QueryKey {
    return ['AccountsApiAccountsWhitelistsIdGet', id];
  },
  /**
   *
   * @summary Add an account whitelist
   */
  accountsWhitelistsPostRoot: 'AccountsApiAccountsWhitelistsPost' as QueryKey,

  /**
   *
   * @summary Add an account whitelist
   */
  accountsWhitelistsPost(
    accountWhitelistPostBody?: AccountWhitelistPostBody
  ): QueryKey {
    return ['AccountsApiAccountsWhitelistsPost', accountWhitelistPostBody];
  },
  /**
   *
   * @summary Reactivate an account
   */
  activateAccountRoot: 'AccountsApiActivateAccount' as QueryKey,

  /**
   *
   * @summary Reactivate an account
   */
  activateAccount(id: string): QueryKey {
    return ['AccountsApiActivateAccount', id];
  },
  /**
   *
   * @summary Add an account
   */
  createAccountRoot: 'AccountsApiCreateAccount' as QueryKey,

  /**
   *
   * @summary Add an account
   */
  createAccount(accountPostBody?: AccountPostBody): QueryKey {
    return ['AccountsApiCreateAccount', accountPostBody];
  },
  /**
   *
   * @summary Export activities for an account
   */
  exportAccountActivityRoot: 'AccountsApiExportAccountActivity' as QueryKey,

  /**
   *
   * @summary Export activities for an account
   */
  exportAccountActivity(
    id: string,
    timestamp?: FilterParameter,
    sort?: string
  ): QueryKey {
    return ['AccountsApiExportAccountActivity', id, timestamp, sort];
  },
  /**
   *
   * @summary Export accounts
   */
  exportAccountsRoot: 'AccountsApiExportAccounts' as QueryKey,

  /**
   *
   * @summary Export accounts
   */
  exportAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AccountsApiExportAccounts',
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
      sort,
    ];
  },
  /**
   *
   * @summary Generate account statement as downloadable PDF
   */
  generateAccountStatementRoot:
    'AccountsApiGenerateAccountStatement' as QueryKey,

  /**
   *
   * @summary Generate account statement as downloadable PDF
   */
  generateAccountStatement(id: string, timestamp?: FilterParameter): QueryKey {
    return ['AccountsApiGenerateAccountStatement', id, timestamp];
  },
  /**
   *
   * @summary Get properties of an account
   */
  getAccountRoot: 'AccountsApiGetAccount' as QueryKey,

  /**
   *
   * @summary Get properties of an account
   */
  getAccount(id: string): QueryKey {
    return ['AccountsApiGetAccount', id];
  },
  /**
   *
   * @summary List activities for an account
   */
  getAccountActivityRoot: 'AccountsApiGetAccountActivity' as QueryKey,

  /**
   *
   * @summary List activities for an account
   */
  getAccountActivity(id: string, timestamp?: FilterParameter): QueryKey {
    return ['AccountsApiGetAccountActivity', id, timestamp];
  },
  /**
   *
   * @summary Get account summary using Iban
   */
  getAccountSummaryByIbanRoot: 'AccountsApiGetAccountSummaryByIban' as QueryKey,

  /**
   *
   * @summary Get account summary using Iban
   */
  getAccountSummaryByIban(iban: string): QueryKey {
    return ['AccountsApiGetAccountSummaryByIban', iban];
  },
  /**
   *
   * @summary Get account summary using Id
   */
  getAccountSummaryByIdRoot: 'AccountsApiGetAccountSummaryById' as QueryKey,

  /**
   *
   * @summary Get account summary using Id
   */
  getAccountSummaryById(id: string): QueryKey {
    return ['AccountsApiGetAccountSummaryById', id];
  },
  /**
   *
   * @summary List accounts
   */
  getAccountsRoot: 'AccountsApiGetAccounts' as QueryKey,

  /**
   *
   * @summary List accounts
   */
  getAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AccountsApiGetAccounts',
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
      sort,
    ];
  },
  /**
   *
   * @summary Summaries of all accounts
   */
  getAllAccountSummariesRoot: 'AccountsApiGetAllAccountSummaries' as QueryKey,

  /**
   *
   * @summary Summaries of all accounts
   */
  getAllAccountSummaries(
    searchtext?: string,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    status?: FilterParameter
  ): QueryKey {
    return [
      'AccountsApiGetAllAccountSummaries',
      searchtext,
      balanceCurrency,
      balanceValue,
      status,
    ];
  },
  /**
   *
   * @summary List activity for all accounts
   */
  getAllAccountsActivityRoot: 'AccountsApiGetAllAccountsActivity' as QueryKey,

  /**
   *
   * @summary List activity for all accounts
   */
  getAllAccountsActivity(timestamp?: FilterParameter): QueryKey {
    return ['AccountsApiGetAllAccountsActivity', timestamp];
  },
  /**
   *
   * @summary Get total number of accounts
   */
  getNumAccountsRoot: 'AccountsApiGetNumAccounts' as QueryKey,

  /**
   *
   * @summary Get total number of accounts
   */
  getNumAccounts(): QueryKey {
    return ['AccountsApiGetNumAccounts'];
  },
  /**
   *
   * @summary Temporarily disable an account
   */
  suspendAccountRoot: 'AccountsApiSuspendAccount' as QueryKey,

  /**
   *
   * @summary Temporarily disable an account
   */
  suspendAccount(id: string): QueryKey {
    return ['AccountsApiSuspendAccount', id];
  },
  /**
   *
   * @summary Permanently close an account
   */
  terminateAccountRoot: 'AccountsApiTerminateAccount' as QueryKey,

  /**
   *
   * @summary Permanently close an account
   */
  terminateAccount(id: string): QueryKey {
    return ['AccountsApiTerminateAccount', id];
  },
  /**
   *
   * @summary Update properties of an account
   */
  updateAccountRoot: 'AccountsApiUpdateAccount' as QueryKey,

  /**
   *
   * @summary Update properties of an account
   */
  updateAccount(id: string, accountPutBody?: AccountPutBody): QueryKey {
    return ['AccountsApiUpdateAccount', id, accountPutBody];
  },
};

/**
 * AccountsApi - react-query method creator
 */
export class AccountsApiMaker extends BaseApiMaker<AccountsApi> {
  /**
   * Get an AccountsApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): AccountsApi {
    return new AccountsApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary List account whitelists
   * @memberof AccountsApi
   */
  public accountsWhitelistsGet(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountWhitelist> {
    return {
      queryKey: AccountsApiQueryKey.accountsWhitelistsGet(),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsGet(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List account whitelists
   * @memberof AccountsApi
   */
  public accountsWhitelistsGetMutation(): ApiMutationHandler<
    AccountsApiAccountsWhitelistsGetArgs,
    AccountWhitelist
  > {
    return {
      mutationKey: 'AccountsApiAccountsWhitelistsGet',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsGet(options)
        ),
    };
  }

  /**
   *
   * @summary Remove an account whitelist
   * @memberof AccountsApi
   */
  public accountsWhitelistsIdDelete(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountWhitelist> {
    return {
      queryKey: AccountsApiQueryKey.accountsWhitelistsIdDelete(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsIdDelete(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Remove an account whitelist
   * @memberof AccountsApi
   */
  public accountsWhitelistsIdDeleteMutation(): ApiMutationHandler<
    AccountsApiAccountsWhitelistsIdDeleteArgs,
    AccountWhitelist
  > {
    return {
      mutationKey: 'AccountsApiAccountsWhitelistsIdDelete',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsIdDelete(id, options)
        ),
    };
  }

  /**
   *
   * @summary Get properties of an account whitelist
   * @memberof AccountsApi
   */
  public accountsWhitelistsIdGet(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountWhitelist> {
    return {
      queryKey: AccountsApiQueryKey.accountsWhitelistsIdGet(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsIdGet(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of an account whitelist
   * @memberof AccountsApi
   */
  public accountsWhitelistsIdGetMutation(): ApiMutationHandler<
    AccountsApiAccountsWhitelistsIdGetArgs,
    AccountWhitelist
  > {
    return {
      mutationKey: 'AccountsApiAccountsWhitelistsIdGet',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsIdGet(id, options)
        ),
    };
  }

  /**
   *
   * @summary Add an account whitelist
   * @memberof AccountsApi
   */
  public accountsWhitelistsPost(
    accountWhitelistPostBody?: AccountWhitelistPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountWhitelist> {
    return {
      queryKey: AccountsApiQueryKey.accountsWhitelistsPost(
        accountWhitelistPostBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsPost(
            accountWhitelistPostBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Add an account whitelist
   * @memberof AccountsApi
   */
  public accountsWhitelistsPostMutation(): ApiMutationHandler<
    AccountsApiAccountsWhitelistsPostArgs,
    AccountWhitelist
  > {
    return {
      mutationKey: 'AccountsApiAccountsWhitelistsPost',
      mutationFn: (
        accountWhitelistPostBody?: AccountWhitelistPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountWhitelist>((api) =>
          api.accountsWhitelistsPost(accountWhitelistPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Reactivate an account
   * @memberof AccountsApi
   */
  public activateAccount(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Account> {
    return {
      queryKey: AccountsApiQueryKey.activateAccount(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Account>((api) =>
          api.activateAccount(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Reactivate an account
   * @memberof AccountsApi
   */
  public activateAccountMutation(): ApiMutationHandler<
    AccountsApiActivateAccountArgs,
    Account
  > {
    return {
      mutationKey: 'AccountsApiActivateAccount',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Account>((api) => api.activateAccount(id, options)),
    };
  }

  /**
   *
   * @summary Add an account
   * @memberof AccountsApi
   */
  public createAccount(
    accountPostBody?: AccountPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Account> {
    return {
      queryKey: AccountsApiQueryKey.createAccount(accountPostBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Account>((api) =>
          api.createAccount(accountPostBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Add an account
   * @memberof AccountsApi
   */
  public createAccountMutation(): ApiMutationHandler<
    AccountsApiCreateAccountArgs,
    Account
  > {
    return {
      mutationKey: 'AccountsApiCreateAccount',
      mutationFn: (
        accountPostBody?: AccountPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Account>((api) =>
          api.createAccount(accountPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Export activities for an account
   * @memberof AccountsApi
   */
  public exportAccountActivity(
    id: string,
    timestamp?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AccountsApiQueryKey.exportAccountActivity(id, timestamp, sort),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAccountActivity(
            id,
            timestamp,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export activities for an account
   * @memberof AccountsApi
   */
  public exportAccountActivityMutation(): ApiMutationHandler<
    AccountsApiExportAccountActivityArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AccountsApiExportAccountActivity',
      mutationFn: (
        id: string,
        timestamp?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAccountActivity(id, timestamp, sort, options)
        ),
    };
  }

  /**
   *
   * @summary Export accounts
   * @memberof AccountsApi
   */
  public exportAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AccountsApiQueryKey.exportAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export accounts
   * @memberof AccountsApi
   */
  public exportAccountsMutation(): ApiMutationHandler<
    AccountsApiExportAccountsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AccountsApiExportAccounts',
      mutationFn: (
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Generate account statement as downloadable PDF
   * @memberof AccountsApi
   */
  public generateAccountStatement(
    id: string,
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AccountsApiQueryKey.generateAccountStatement(id, timestamp),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.generateAccountStatement(
            id,
            timestamp,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Generate account statement as downloadable PDF
   * @memberof AccountsApi
   */
  public generateAccountStatementMutation(): ApiMutationHandler<
    AccountsApiGenerateAccountStatementArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AccountsApiGenerateAccountStatement',
      mutationFn: (
        id: string,
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.generateAccountStatement(id, timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary Get properties of an account
   * @memberof AccountsApi
   */
  public getAccount(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Account> {
    return {
      queryKey: AccountsApiQueryKey.getAccount(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Account>((api) =>
          api.getAccount(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of an account
   * @memberof AccountsApi
   */
  public getAccountMutation(): ApiMutationHandler<
    AccountsApiGetAccountArgs,
    Account
  > {
    return {
      mutationKey: 'AccountsApiGetAccount',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Account>((api) => api.getAccount(id, options)),
    };
  }

  /**
   *
   * @summary List activities for an account
   * @memberof AccountsApi
   */
  public getAccountActivity(
    id: string,
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Activity>> {
    return {
      queryKey: AccountsApiQueryKey.getAccountActivity(id, timestamp),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAccountActivity(id, timestamp, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List activities for an account
   * @memberof AccountsApi
   */
  public getAccountActivityMutation(): ApiMutationHandler<
    AccountsApiGetAccountActivityArgs,
    Array<Activity>
  > {
    return {
      mutationKey: 'AccountsApiGetAccountActivity',
      mutationFn: (
        id: string,
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAccountActivity(id, timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary Get account summary using Iban
   * @memberof AccountsApi
   */
  public getAccountSummaryByIban(
    iban: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountShortSummary> {
    return {
      queryKey: AccountsApiQueryKey.getAccountSummaryByIban(iban),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getAccountSummaryByIban(iban, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get account summary using Iban
   * @memberof AccountsApi
   */
  public getAccountSummaryByIbanMutation(): ApiMutationHandler<
    AccountsApiGetAccountSummaryByIbanArgs,
    AccountShortSummary
  > {
    return {
      mutationKey: 'AccountsApiGetAccountSummaryByIban',
      mutationFn: (iban: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getAccountSummaryByIban(iban, options)
        ),
    };
  }

  /**
   *
   * @summary Get account summary using Id
   * @memberof AccountsApi
   */
  public getAccountSummaryById(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountShortSummary> {
    return {
      queryKey: AccountsApiQueryKey.getAccountSummaryById(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getAccountSummaryById(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get account summary using Id
   * @memberof AccountsApi
   */
  public getAccountSummaryByIdMutation(): ApiMutationHandler<
    AccountsApiGetAccountSummaryByIdArgs,
    AccountShortSummary
  > {
    return {
      mutationKey: 'AccountsApiGetAccountSummaryById',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getAccountSummaryById(id, options)
        ),
    };
  }

  /**
   *
   * @summary List accounts
   * @memberof AccountsApi
   */
  public getAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Account>> {
    return {
      queryKey: AccountsApiQueryKey.getAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Account>>((api) =>
          api.getAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List accounts
   * @memberof AccountsApi
   */
  public getAccountsMutation(): ApiMutationHandler<
    AccountsApiGetAccountsArgs,
    Array<Account>
  > {
    return {
      mutationKey: 'AccountsApiGetAccounts',
      mutationFn: (
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Account>>((api) =>
          api.getAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Summaries of all accounts
   * @memberof AccountsApi
   */
  public getAllAccountSummaries(
    searchtext?: string,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    status?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<AccountShortSummary>> {
    return {
      queryKey: AccountsApiQueryKey.getAllAccountSummaries(
        searchtext,
        balanceCurrency,
        balanceValue,
        status
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<AccountShortSummary>>((api) =>
          api.getAllAccountSummaries(
            searchtext,
            balanceCurrency,
            balanceValue,
            status,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Summaries of all accounts
   * @memberof AccountsApi
   */
  public getAllAccountSummariesMutation(): ApiMutationHandler<
    AccountsApiGetAllAccountSummariesArgs,
    Array<AccountShortSummary>
  > {
    return {
      mutationKey: 'AccountsApiGetAllAccountSummaries',
      mutationFn: (
        searchtext?: string,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        status?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<AccountShortSummary>>((api) =>
          api.getAllAccountSummaries(
            searchtext,
            balanceCurrency,
            balanceValue,
            status,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List activity for all accounts
   * @memberof AccountsApi
   */
  public getAllAccountsActivity(
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Activity>> {
    return {
      queryKey: AccountsApiQueryKey.getAllAccountsActivity(timestamp),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAllAccountsActivity(timestamp, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List activity for all accounts
   * @memberof AccountsApi
   */
  public getAllAccountsActivityMutation(): ApiMutationHandler<
    AccountsApiGetAllAccountsActivityArgs,
    Array<Activity>
  > {
    return {
      mutationKey: 'AccountsApiGetAllAccountsActivity',
      mutationFn: (
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAllAccountsActivity(timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary Get total number of accounts
   * @memberof AccountsApi
   */
  public getNumAccounts(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<number> {
    return {
      queryKey: AccountsApiQueryKey.getNumAccounts(),
      queryFn: (queryOptions) =>
        this.runQueryFn<number>((api) =>
          api.getNumAccounts(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get total number of accounts
   * @memberof AccountsApi
   */
  public getNumAccountsMutation(): ApiMutationHandler<
    AccountsApiGetNumAccountsArgs,
    number
  > {
    return {
      mutationKey: 'AccountsApiGetNumAccounts',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<number>((api) => api.getNumAccounts(options)),
    };
  }

  /**
   *
   * @summary Temporarily disable an account
   * @memberof AccountsApi
   */
  public suspendAccount(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Account> {
    return {
      queryKey: AccountsApiQueryKey.suspendAccount(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Account>((api) =>
          api.suspendAccount(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Temporarily disable an account
   * @memberof AccountsApi
   */
  public suspendAccountMutation(): ApiMutationHandler<
    AccountsApiSuspendAccountArgs,
    Account
  > {
    return {
      mutationKey: 'AccountsApiSuspendAccount',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Account>((api) => api.suspendAccount(id, options)),
    };
  }

  /**
   *
   * @summary Permanently close an account
   * @memberof AccountsApi
   */
  public terminateAccount(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Account> {
    return {
      queryKey: AccountsApiQueryKey.terminateAccount(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Account>((api) =>
          api.terminateAccount(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Permanently close an account
   * @memberof AccountsApi
   */
  public terminateAccountMutation(): ApiMutationHandler<
    AccountsApiTerminateAccountArgs,
    Account
  > {
    return {
      mutationKey: 'AccountsApiTerminateAccount',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Account>((api) => api.terminateAccount(id, options)),
    };
  }

  /**
   *
   * @summary Update properties of an account
   * @memberof AccountsApi
   */
  public updateAccount(
    id: string,
    accountPutBody?: AccountPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Account> {
    return {
      queryKey: AccountsApiQueryKey.updateAccount(id, accountPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Account>((api) =>
          api.updateAccount(id, accountPutBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Update properties of an account
   * @memberof AccountsApi
   */
  public updateAccountMutation(): ApiMutationHandler<
    AccountsApiUpdateAccountArgs,
    Account
  > {
    return {
      mutationKey: 'AccountsApiUpdateAccount',
      mutationFn: (
        id: string,
        accountPutBody?: AccountPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Account>((api) =>
          api.updateAccount(id, accountPutBody, options)
        ),
    };
  }
}

/**
 * AccountsApi - react-query api
 */
export function useAccountsApi(
  options?: ApiMethodMakerOptions
): AccountsApiMaker {
  return useApiMethodMaker<AccountsApiMaker>(AccountsApiMaker, options);
}

/**
 * Arguments for the AdminApi.ActivateClient API query
 */
export type AdminApiActivateClientArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ActivateClientAccount API query
 */
export type AdminApiActivateClientAccountArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ActivateClientContact API query
 */
export type AdminApiActivateClientContactArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.AddClientContact API query
 */
export type AdminApiAddClientContactArgs = [
  orgId: string,
  contactPostBody?: ContactPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.AddClientThirdParty API query
 */
export type AdminApiAddClientThirdPartyArgs = [
  orgId: string,
  thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ApproveClientUpdates API query
 */
export type AdminApiApproveClientUpdatesArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.BlessAccountBlock API query
 */
export type AdminApiBlessAccountBlockArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.BlockAccountBlock API query
 */
export type AdminApiBlockAccountBlockArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.BroadcastNotification API query
 */
export type AdminApiBroadcastNotificationArgs = [
  notificationBroadcastRequest?: NotificationBroadcastRequest,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.CancelHeldClientPayment API query
 */
export type AdminApiCancelHeldClientPaymentArgs = [
  orgId: string,
  id: string,
  paymentCancellation: PaymentCancellation,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.CreateAccountBlock API query
 */
export type AdminApiCreateAccountBlockArgs = [
  accountBlockPostBody?: AccountBlockPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.CreateClient API query
 */
export type AdminApiCreateClientArgs = [
  clientPostBody?: ClientPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.CreateClientAccount API query
 */
export type AdminApiCreateClientAccountArgs = [
  orgId: string,
  accountAdminPostBody?: AccountAdminPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.CreateClientProfile API query
 */
export type AdminApiCreateClientProfileArgs = [
  clientProfilePostPutBody?: ClientProfilePostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.CreateNotification API query
 */
export type AdminApiCreateNotificationArgs = [
  orgId: string,
  notificationPostPutRequest?: NotificationPostPutRequest,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.CreateRiskSurvey API query
 */
export type AdminApiCreateRiskSurveyArgs = [
  riskSurveyPostPutBody?: RiskSurveyPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.DeleteClientNotification API query
 */
export type AdminApiDeleteClientNotificationArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.DisableAccountBlock API query
 */
export type AdminApiDisableAccountBlockArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAccountBlocks API query
 */
export type AdminApiExportAccountBlocksArgs = [
  iban?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientAccounts API query
 */
export type AdminApiExportAllClientAccountsArgs = [
  orgId: string,
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientActivity API query
 */
export type AdminApiExportAllClientActivityArgs = [
  orgId: string,
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientContacts API query
 */
export type AdminApiExportAllClientContactsArgs = [
  orgId: any,
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientThirdParties API query
 */
export type AdminApiExportAllClientThirdPartiesArgs = [
  orgId: string,
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientsAccounts API query
 */
export type AdminApiExportAllClientsAccountsArgs = [
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientsActivity API query
 */
export type AdminApiExportAllClientsActivityArgs = [
  timestamp?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientsContacts API query
 */
export type AdminApiExportAllClientsContactsArgs = [
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportAllClientsThirdParties API query
 */
export type AdminApiExportAllClientsThirdPartiesArgs = [
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportClientAccountActivity API query
 */
export type AdminApiExportClientAccountActivityArgs = [
  orgId: string,
  accountId: string,
  timestamp?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportClientPayments API query
 */
export type AdminApiExportClientPaymentsArgs = [
  orgId: string,
  amountCurrency?: FilterParameter,
  amountValue?: FilterParameter,
  _class?: FilterParameter,
  fromIban?: FilterParameter,
  fromName?: FilterParameter,
  toIban?: FilterParameter,
  toName?: FilterParameter,
  status?: FilterParameter,
  timeStamp?: FilterParameter,
  type?: FilterParameter,
  id?: FilterParameter,
  clearingSystem?: FilterParameter,
  clearingSystemTxId?: FilterParameter,
  instructionId?: FilterParameter,
  description?: FilterParameter,
  senderReference?: FilterParameter,
  batchReference?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportClients API query
 */
export type AdminApiExportClientsArgs = [
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  supplier?: FilterParameter,
  legalName?: FilterParameter,
  informalName?: FilterParameter,
  incorporationLoc?: FilterParameter,
  incorporationNumber?: FilterParameter,
  incorporationDate?: FilterParameter,
  sIC?: FilterParameter,
  lastKycReview?: FilterParameter,
  clientProfileComplianceLevel?: FilterParameter,
  startDate?: FilterParameter,
  webSite?: FilterParameter,
  clientProfile?: FilterParameter,
  referrer?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ExportClientsPayments API query
 */
export type AdminApiExportClientsPaymentsArgs = [
  amountCurrency?: FilterParameter,
  amountValue?: FilterParameter,
  _class?: FilterParameter,
  fromIban?: FilterParameter,
  fromName?: FilterParameter,
  toIban?: FilterParameter,
  toName?: FilterParameter,
  status?: FilterParameter,
  timeStamp?: FilterParameter,
  type?: FilterParameter,
  id?: FilterParameter,
  clearingSystem?: FilterParameter,
  clearingSystemTxId?: FilterParameter,
  instructionId?: FilterParameter,
  description?: FilterParameter,
  senderReference?: FilterParameter,
  batchReference?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GenerateClientAccountStatement API query
 */
export type AdminApiGenerateClientAccountStatementArgs = [
  orgId: string,
  id: string,
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAccountBlock API query
 */
export type AdminApiGetAccountBlockArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAccountBlocks API query
 */
export type AdminApiGetAccountBlocksArgs = [
  iban?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllAttachments API query
 */
export type AdminApiGetAllAttachmentsArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the AdminApi.GetAllClientAccountSummaries API query
 */
export type AdminApiGetAllClientAccountSummariesArgs = [
  orgId: string,
  searchtext?: string,
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientAccounts API query
 */
export type AdminApiGetAllClientAccountsArgs = [
  orgId: string,
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientActivity API query
 */
export type AdminApiGetAllClientActivityArgs = [
  orgId: string,
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientAttachments API query
 */
export type AdminApiGetAllClientAttachmentsArgs = [
  orgId: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientContactSummaries API query
 */
export type AdminApiGetAllClientContactSummariesArgs = [
  orgId: string,
  searchtext?: string,
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientContacts API query
 */
export type AdminApiGetAllClientContactsArgs = [
  orgId: string,
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientNotifications API query
 */
export type AdminApiGetAllClientNotificationsArgs = [
  orgId: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientThirdParties API query
 */
export type AdminApiGetAllClientThirdPartiesArgs = [
  orgId: string,
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientThirdPartySummaries API query
 */
export type AdminApiGetAllClientThirdPartySummariesArgs = [
  orgId: string,
  searchtext?: string,
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientsAccountSummaries API query
 */
export type AdminApiGetAllClientsAccountSummariesArgs = [
  searchtext?: string,
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientsAccounts API query
 */
export type AdminApiGetAllClientsAccountsArgs = [
  name?: FilterParameter,
  descriptor?: FilterParameter,
  balanceCurrency?: FilterParameter,
  balanceValue?: FilterParameter,
  creationDate?: FilterParameter,
  createdBy?: FilterParameter,
  lastActivity?: FilterParameter,
  terminationDate?: FilterParameter,
  status?: FilterParameter,
  iban?: FilterParameter,
  useDynamicDescriptor?: FilterParameter,
  isThirdParty?: FilterParameter,
  utilized?: FilterParameter,
  beneficiaries?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientsActivity API query
 */
export type AdminApiGetAllClientsActivityArgs = [
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientsContactSummaries API query
 */
export type AdminApiGetAllClientsContactSummariesArgs = [
  searchtext?: string,
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientsContacts API query
 */
export type AdminApiGetAllClientsContactsArgs = [
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientsThirdParties API query
 */
export type AdminApiGetAllClientsThirdPartiesArgs = [
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllClientsThirdPartySummaries API query
 */
export type AdminApiGetAllClientsThirdPartySummariesArgs = [
  searchtext?: string,
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetAllPaymentAuditingEntries API query
 */
export type AdminApiGetAllPaymentAuditingEntriesArgs = [
  timestamp?: FilterParameter,
  fromAccountIban?: FilterParameter,
  toAccountIban?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetChecklists API query
 */
export type AdminApiGetChecklistsArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the AdminApi.GetClient API query
 */
export type AdminApiGetClientArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientAccount API query
 */
export type AdminApiGetClientAccountArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientAccountActivity API query
 */
export type AdminApiGetClientAccountActivityArgs = [
  orgId: string,
  id: string,
  timestamp?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientAccountSummaryByIban API query
 */
export type AdminApiGetClientAccountSummaryByIbanArgs = [
  orgId: string,
  iban: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientAccountSummaryById API query
 */
export type AdminApiGetClientAccountSummaryByIdArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientAttachment API query
 */
export type AdminApiGetClientAttachmentArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientContact API query
 */
export type AdminApiGetClientContactArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientContactSummaryById API query
 */
export type AdminApiGetClientContactSummaryByIdArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientCurrentReview API query
 */
export type AdminApiGetClientCurrentReviewArgs = [
  orgId: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientNotification API query
 */
export type AdminApiGetClientNotificationArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientPayment API query
 */
export type AdminApiGetClientPaymentArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientPayments API query
 */
export type AdminApiGetClientPaymentsArgs = [
  orgId: string,
  amountCurrency?: FilterParameter,
  amountValue?: FilterParameter,
  _class?: FilterParameter,
  fromIban?: FilterParameter,
  fromName?: FilterParameter,
  toIban?: FilterParameter,
  toName?: FilterParameter,
  status?: FilterParameter,
  timeStamp?: FilterParameter,
  type?: FilterParameter,
  id?: FilterParameter,
  clearingSystem?: FilterParameter,
  clearingSystemTxId?: FilterParameter,
  instructionId?: FilterParameter,
  description?: FilterParameter,
  senderReference?: FilterParameter,
  batchReference?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientProfile API query
 */
export type AdminApiGetClientProfileArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientProfileSummaryById API query
 */
export type AdminApiGetClientProfileSummaryByIdArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientProfiles API query
 */
export type AdminApiGetClientProfilesArgs = [
  supplier?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientProfilesSummaries API query
 */
export type AdminApiGetClientProfilesSummariesArgs = [
  searchtext?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientSettings API query
 */
export type AdminApiGetClientSettingsArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientSummaryById API query
 */
export type AdminApiGetClientSummaryByIdArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientThirdParty API query
 */
export type AdminApiGetClientThirdPartyArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientThirdPartySummaryById API query
 */
export type AdminApiGetClientThirdPartySummaryByIdArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClients API query
 */
export type AdminApiGetClientsArgs = [
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  supplier?: FilterParameter,
  legalName?: FilterParameter,
  informalName?: FilterParameter,
  incorporationLoc?: FilterParameter,
  incorporationNumber?: FilterParameter,
  incorporationDate?: FilterParameter,
  sIC?: FilterParameter,
  lastKycReview?: FilterParameter,
  clientProfileComplianceLevel?: FilterParameter,
  startDate?: FilterParameter,
  webSite?: FilterParameter,
  clientProfile?: FilterParameter,
  referrer?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientsPayments API query
 */
export type AdminApiGetClientsPaymentsArgs = [
  amountCurrency?: FilterParameter,
  amountValue?: FilterParameter,
  _class?: FilterParameter,
  fromIban?: FilterParameter,
  fromName?: FilterParameter,
  toIban?: FilterParameter,
  toName?: FilterParameter,
  status?: FilterParameter,
  timeStamp?: FilterParameter,
  type?: FilterParameter,
  id?: FilterParameter,
  clearingSystem?: FilterParameter,
  clearingSystemTxId?: FilterParameter,
  instructionId?: FilterParameter,
  description?: FilterParameter,
  senderReference?: FilterParameter,
  batchReference?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetClientsSummaries API query
 */
export type AdminApiGetClientsSummariesArgs = [
  searchtext?: string,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  supplier?: FilterParameter,
  legalName?: FilterParameter,
  informalName?: FilterParameter,
  incorporationLoc?: FilterParameter,
  incorporationNumber?: FilterParameter,
  incorporationDate?: FilterParameter,
  sIC?: FilterParameter,
  lastKycReview?: FilterParameter,
  clientProfileComplianceLevel?: FilterParameter,
  startDate?: FilterParameter,
  webSite?: FilterParameter,
  clientProfile?: FilterParameter,
  referrer?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetProducts API query
 */
export type AdminApiGetProductsArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the AdminApi.GetRiskSurvey API query
 */
export type AdminApiGetRiskSurveyArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetRiskSurveySummaries API query
 */
export type AdminApiGetRiskSurveySummariesArgs = [
  searchtext?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetRiskSurveySummaryById API query
 */
export type AdminApiGetRiskSurveySummaryByIdArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.GetRiskSurveys API query
 */
export type AdminApiGetRiskSurveysArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the AdminApi.LockClientProfile API query
 */
export type AdminApiLockClientProfileArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.RejectClientUpdates API query
 */
export type AdminApiRejectClientUpdatesArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ReleaseHeldClientPayment API query
 */
export type AdminApiReleaseHeldClientPaymentArgs = [
  orgId: string,
  id: string,
  paymentRelease: PaymentRelease,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ResetClientContactPassword API query
 */
export type AdminApiResetClientContactPasswordArgs = [
  orgId: string,
  id: string,
  resetPasswordPutBody: ResetPasswordPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.ReviewClientUpdates API query
 */
export type AdminApiReviewClientUpdatesArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.SuspendClient API query
 */
export type AdminApiSuspendClientArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.SuspendClientAccount API query
 */
export type AdminApiSuspendClientAccountArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.SuspendClientContact API query
 */
export type AdminApiSuspendClientContactArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.TerminateClient API query
 */
export type AdminApiTerminateClientArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.TerminateClientAccount API query
 */
export type AdminApiTerminateClientAccountArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.TerminateClientContact API query
 */
export type AdminApiTerminateClientContactArgs = [
  orgId: string,
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UnlockClientProfile API query
 */
export type AdminApiUnlockClientProfileArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateAccountBlock API query
 */
export type AdminApiUpdateAccountBlockArgs = [
  id: string,
  accountBlockPutBody?: AccountBlockPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateClient API query
 */
export type AdminApiUpdateClientArgs = [
  id: string,
  client?: Client,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateClientAccount API query
 */
export type AdminApiUpdateClientAccountArgs = [
  orgId: string,
  id: string,
  accountAdminPutBody?: AccountAdminPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateClientContact API query
 */
export type AdminApiUpdateClientContactArgs = [
  orgId: string,
  id: string,
  contactPutBody?: ContactPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateClientNotification API query
 */
export type AdminApiUpdateClientNotificationArgs = [
  orgId: string,
  id: string,
  notificationPostPutRequest?: NotificationPostPutRequest,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateClientProfile API query
 */
export type AdminApiUpdateClientProfileArgs = [
  id: string,
  clientProfilePostPutBody?: ClientProfilePostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateClientSettings API query
 */
export type AdminApiUpdateClientSettingsArgs = [
  id: string,
  settings?: Settings,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateClientThirdParty API query
 */
export type AdminApiUpdateClientThirdPartyArgs = [
  orgId: string,
  id: string,
  thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UpdateRiskSurvey API query
 */
export type AdminApiUpdateRiskSurveyArgs = [
  id: string,
  riskSurveyPostPutBody?: RiskSurveyPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the AdminApi.UploadClientAttachment API query
 */
export type AdminApiUploadClientAttachmentArgs = [
  orgId: string,
  type: string,
  file: any,
  axiosOptions?: AxiosRequestConfig
];

/**
 * AdminApi - react-query query key creator
 */
export const AdminApiQueryKey = {
  /**
   *
   * @summary Change a client\'s operational status to Active
   */
  activateClientRoot: 'AdminApiActivateClient' as QueryKey,

  /**
   *
   * @summary Change a client\'s operational status to Active
   */
  activateClient(id: string): QueryKey {
    return ['AdminApiActivateClient', id];
  },
  /**
   *
   * @summary Activate a client account
   */
  activateClientAccountRoot: 'AdminApiActivateClientAccount' as QueryKey,

  /**
   *
   * @summary Activate a client account
   */
  activateClientAccount(orgId: string, id: string): QueryKey {
    return ['AdminApiActivateClientAccount', orgId, id];
  },
  /**
   *
   * @summary Activate a client contact
   */
  activateClientContactRoot: 'AdminApiActivateClientContact' as QueryKey,

  /**
   *
   * @summary Activate a client contact
   */
  activateClientContact(orgId: string, id: string): QueryKey {
    return ['AdminApiActivateClientContact', orgId, id];
  },
  /**
   *
   * @summary Add a new client Contact
   */
  addClientContactRoot: 'AdminApiAddClientContact' as QueryKey,

  /**
   *
   * @summary Add a new client Contact
   */
  addClientContact(orgId: string, contactPostBody?: ContactPostBody): QueryKey {
    return ['AdminApiAddClientContact', orgId, contactPostBody];
  },
  /**
   *
   * @summary Add a new client third party
   */
  addClientThirdPartyRoot: 'AdminApiAddClientThirdParty' as QueryKey,

  /**
   *
   * @summary Add a new client third party
   */
  addClientThirdParty(
    orgId: string,
    thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody
  ): QueryKey {
    return ['AdminApiAddClientThirdParty', orgId, thirdPartyAdminPostPutBody];
  },
  /**
   *
   * @summary Approve pending updates to a client
   */
  approveClientUpdatesRoot: 'AdminApiApproveClientUpdates' as QueryKey,

  /**
   *
   * @summary Approve pending updates to a client
   */
  approveClientUpdates(id: string): QueryKey {
    return ['AdminApiApproveClientUpdates', id];
  },
  /**
   *
   * @summary Bless account block
   */
  blessAccountBlockRoot: 'AdminApiBlessAccountBlock' as QueryKey,

  /**
   *
   * @summary Bless account block
   */
  blessAccountBlock(id: string): QueryKey {
    return ['AdminApiBlessAccountBlock', id];
  },
  /**
   *
   * @summary Block account block
   */
  blockAccountBlockRoot: 'AdminApiBlockAccountBlock' as QueryKey,

  /**
   *
   * @summary Block account block
   */
  blockAccountBlock(id: string): QueryKey {
    return ['AdminApiBlockAccountBlock', id];
  },
  /**
   *
   * @summary Broadcast a new client notification to 1 or more clients
   */
  broadcastNotificationRoot: 'AdminApiBroadcastNotification' as QueryKey,

  /**
   *
   * @summary Broadcast a new client notification to 1 or more clients
   */
  broadcastNotification(
    notificationBroadcastRequest?: NotificationBroadcastRequest
  ): QueryKey {
    return ['AdminApiBroadcastNotification', notificationBroadcastRequest];
  },
  /**
   *
   * @summary Cancels a held client payment
   */
  cancelHeldClientPaymentRoot: 'AdminApiCancelHeldClientPayment' as QueryKey,

  /**
   *
   * @summary Cancels a held client payment
   */
  cancelHeldClientPayment(
    orgId: string,
    id: string,
    paymentCancellation: PaymentCancellation
  ): QueryKey {
    return ['AdminApiCancelHeldClientPayment', orgId, id, paymentCancellation];
  },
  /**
   *
   * @summary Create a new account block
   */
  createAccountBlockRoot: 'AdminApiCreateAccountBlock' as QueryKey,

  /**
   *
   * @summary Create a new account block
   */
  createAccountBlock(accountBlockPostBody?: AccountBlockPostBody): QueryKey {
    return ['AdminApiCreateAccountBlock', accountBlockPostBody];
  },
  /**
   *
   * @summary Create a new client
   */
  createClientRoot: 'AdminApiCreateClient' as QueryKey,

  /**
   *
   * @summary Create a new client
   */
  createClient(clientPostBody?: ClientPostBody): QueryKey {
    return ['AdminApiCreateClient', clientPostBody];
  },
  /**
   *
   * @summary Add an account for a specified client
   */
  createClientAccountRoot: 'AdminApiCreateClientAccount' as QueryKey,

  /**
   *
   * @summary Add an account for a specified client
   */
  createClientAccount(
    orgId: string,
    accountAdminPostBody?: AccountAdminPostBody
  ): QueryKey {
    return ['AdminApiCreateClientAccount', orgId, accountAdminPostBody];
  },
  /**
   *
   * @summary Create a new client profile
   */
  createClientProfileRoot: 'AdminApiCreateClientProfile' as QueryKey,

  /**
   *
   * @summary Create a new client profile
   */
  createClientProfile(
    clientProfilePostPutBody?: ClientProfilePostPutBody
  ): QueryKey {
    return ['AdminApiCreateClientProfile', clientProfilePostPutBody];
  },
  /**
   *
   * @summary Create a new client notification
   */
  createNotificationRoot: 'AdminApiCreateNotification' as QueryKey,

  /**
   *
   * @summary Create a new client notification
   */
  createNotification(
    orgId: string,
    notificationPostPutRequest?: NotificationPostPutRequest
  ): QueryKey {
    return ['AdminApiCreateNotification', orgId, notificationPostPutRequest];
  },
  /**
   *
   * @summary Create a new risk survey
   */
  createRiskSurveyRoot: 'AdminApiCreateRiskSurvey' as QueryKey,

  /**
   *
   * @summary Create a new risk survey
   */
  createRiskSurvey(riskSurveyPostPutBody?: RiskSurveyPostPutBody): QueryKey {
    return ['AdminApiCreateRiskSurvey', riskSurveyPostPutBody];
  },
  /**
   *
   * @summary Delete a client notification
   */
  deleteClientNotificationRoot: 'AdminApiDeleteClientNotification' as QueryKey,

  /**
   *
   * @summary Delete a client notification
   */
  deleteClientNotification(orgId: string, id: string): QueryKey {
    return ['AdminApiDeleteClientNotification', orgId, id];
  },
  /**
   *
   * @summary Bless account block
   */
  disableAccountBlockRoot: 'AdminApiDisableAccountBlock' as QueryKey,

  /**
   *
   * @summary Bless account block
   */
  disableAccountBlock(id: string): QueryKey {
    return ['AdminApiDisableAccountBlock', id];
  },
  /**
   *
   * @summary Export all account blocks
   */
  exportAccountBlocksRoot: 'AdminApiExportAccountBlocks' as QueryKey,

  /**
   *
   * @summary Export all account blocks
   */
  exportAccountBlocks(
    iban?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return ['AdminApiExportAccountBlocks', iban, status, sort];
  },
  /**
   *
   * @summary Export all client accounts
   */
  exportAllClientAccountsRoot: 'AdminApiExportAllClientAccounts' as QueryKey,

  /**
   *
   * @summary Export all client accounts
   */
  exportAllClientAccounts(
    orgId: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportAllClientAccounts',
      orgId,
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
      sort,
    ];
  },
  /**
   *
   * @summary Export activity for a client
   */
  exportAllClientActivityRoot: 'AdminApiExportAllClientActivity' as QueryKey,

  /**
   *
   * @summary Export activity for a client
   */
  exportAllClientActivity(
    orgId: string,
    timestamp?: FilterParameter
  ): QueryKey {
    return ['AdminApiExportAllClientActivity', orgId, timestamp];
  },
  /**
   *
   * @summary Export client contacts
   */
  exportAllClientContactsRoot: 'AdminApiExportAllClientContacts' as QueryKey,

  /**
   *
   * @summary Export client contacts
   */
  exportAllClientContacts(
    orgId: any,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportAllClientContacts',
      orgId,
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary Export client third parties
   */
  exportAllClientThirdPartiesRoot:
    'AdminApiExportAllClientThirdParties' as QueryKey,

  /**
   *
   * @summary Export client third parties
   */
  exportAllClientThirdParties(
    orgId: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportAllClientThirdParties',
      orgId,
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary Export clients accounts
   */
  exportAllClientsAccountsRoot: 'AdminApiExportAllClientsAccounts' as QueryKey,

  /**
   *
   * @summary Export clients accounts
   */
  exportAllClientsAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportAllClientsAccounts',
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
      sort,
    ];
  },
  /**
   *
   * @summary Export activity for all clients
   */
  exportAllClientsActivityRoot: 'AdminApiExportAllClientsActivity' as QueryKey,

  /**
   *
   * @summary Export activity for all clients
   */
  exportAllClientsActivity(
    timestamp?: FilterParameter,
    sort?: string
  ): QueryKey {
    return ['AdminApiExportAllClientsActivity', timestamp, sort];
  },
  /**
   *
   * @summary Export clients contacts
   */
  exportAllClientsContactsRoot: 'AdminApiExportAllClientsContacts' as QueryKey,

  /**
   *
   * @summary Export clients contacts
   */
  exportAllClientsContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportAllClientsContacts',
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary Export clients third parties
   */
  exportAllClientsThirdPartiesRoot:
    'AdminApiExportAllClientsThirdParties' as QueryKey,

  /**
   *
   * @summary Export clients third parties
   */
  exportAllClientsThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportAllClientsThirdParties',
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary Export activity for a client account
   */
  exportClientAccountActivityRoot:
    'AdminApiExportClientAccountActivity' as QueryKey,

  /**
   *
   * @summary Export activity for a client account
   */
  exportClientAccountActivity(
    orgId: string,
    accountId: string,
    timestamp?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportClientAccountActivity',
      orgId,
      accountId,
      timestamp,
      sort,
    ];
  },
  /**
   *
   * @summary Export client payments
   */
  exportClientPaymentsRoot: 'AdminApiExportClientPayments' as QueryKey,

  /**
   *
   * @summary Export client payments
   */
  exportClientPayments(
    orgId: string,
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportClientPayments',
      orgId,
      amountCurrency,
      amountValue,
      _class,
      fromIban,
      fromName,
      toIban,
      toName,
      status,
      timeStamp,
      type,
      id,
      clearingSystem,
      clearingSystemTxId,
      instructionId,
      description,
      senderReference,
      batchReference,
      sort,
    ];
  },
  /**
   *
   * @summary Export clients
   */
  exportClientsRoot: 'AdminApiExportClients' as QueryKey,

  /**
   *
   * @summary Export clients
   */
  exportClients(
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiExportClients',
      status,
      complianceStatus,
      supplier,
      legalName,
      informalName,
      incorporationLoc,
      incorporationNumber,
      incorporationDate,
      sIC,
      lastKycReview,
      clientProfileComplianceLevel,
      startDate,
      webSite,
      clientProfile,
      referrer,
      sort,
    ];
  },
  /**
   *
   * @summary Export all clients payments
   */
  exportClientsPaymentsRoot: 'AdminApiExportClientsPayments' as QueryKey,

  /**
   *
   * @summary Export all clients payments
   */
  exportClientsPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter
  ): QueryKey {
    return [
      'AdminApiExportClientsPayments',
      amountCurrency,
      amountValue,
      _class,
      fromIban,
      fromName,
      toIban,
      toName,
      status,
      timeStamp,
      type,
      id,
      clearingSystem,
      clearingSystemTxId,
      instructionId,
      description,
      senderReference,
      batchReference,
    ];
  },
  /**
   *
   * @summary Generate client account statement as downloadable PDF
   */
  generateClientAccountStatementRoot:
    'AdminApiGenerateClientAccountStatement' as QueryKey,

  /**
   *
   * @summary Generate client account statement as downloadable PDF
   */
  generateClientAccountStatement(
    orgId: string,
    id: string,
    timestamp?: FilterParameter
  ): QueryKey {
    return ['AdminApiGenerateClientAccountStatement', orgId, id, timestamp];
  },
  /**
   *
   * @summary Get account block
   */
  getAccountBlockRoot: 'AdminApiGetAccountBlock' as QueryKey,

  /**
   *
   * @summary Get account block
   */
  getAccountBlock(id: string): QueryKey {
    return ['AdminApiGetAccountBlock', id];
  },
  /**
   *
   * @summary Get all account blocks
   */
  getAccountBlocksRoot: 'AdminApiGetAccountBlocks' as QueryKey,

  /**
   *
   * @summary Get all account blocks
   */
  getAccountBlocks(
    iban?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return ['AdminApiGetAccountBlocks', iban, status, sort];
  },
  /**
   *
   * @summary Get all clients attachments
   */
  getAllAttachmentsRoot: 'AdminApiGetAllAttachments' as QueryKey,

  /**
   *
   * @summary Get all clients attachments
   */
  getAllAttachments(): QueryKey {
    return ['AdminApiGetAllAttachments'];
  },
  /**
   *
   * @summary List client account summaries
   */
  getAllClientAccountSummariesRoot:
    'AdminApiGetAllClientAccountSummaries' as QueryKey,

  /**
   *
   * @summary List client account summaries
   */
  getAllClientAccountSummaries(
    orgId: string,
    searchtext?: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter
  ): QueryKey {
    return [
      'AdminApiGetAllClientAccountSummaries',
      orgId,
      searchtext,
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
    ];
  },
  /**
   *
   * @summary List client accounts
   */
  getAllClientAccountsRoot: 'AdminApiGetAllClientAccounts' as QueryKey,

  /**
   *
   * @summary List client accounts
   */
  getAllClientAccounts(
    orgId: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientAccounts',
      orgId,
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
      sort,
    ];
  },
  /**
   *
   * @summary List activity for a client
   */
  getAllClientActivityRoot: 'AdminApiGetAllClientActivity' as QueryKey,

  /**
   *
   * @summary List activity for a client
   */
  getAllClientActivity(orgId: string, timestamp?: FilterParameter): QueryKey {
    return ['AdminApiGetAllClientActivity', orgId, timestamp];
  },
  /**
   *
   * @summary Get all client attachments
   */
  getAllClientAttachmentsRoot: 'AdminApiGetAllClientAttachments' as QueryKey,

  /**
   *
   * @summary Get all client attachments
   */
  getAllClientAttachments(orgId: string): QueryKey {
    return ['AdminApiGetAllClientAttachments', orgId];
  },
  /**
   *
   * @summary List client contact summaries
   */
  getAllClientContactSummariesRoot:
    'AdminApiGetAllClientContactSummaries' as QueryKey,

  /**
   *
   * @summary List client contact summaries
   */
  getAllClientContactSummaries(
    orgId: string,
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientContactSummaries',
      orgId,
      searchtext,
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary List client contacts
   */
  getAllClientContactsRoot: 'AdminApiGetAllClientContacts' as QueryKey,

  /**
   *
   * @summary List client contacts
   */
  getAllClientContacts(
    orgId: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientContacts',
      orgId,
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary Get all clients notifications
   */
  getAllClientNotificationsRoot:
    'AdminApiGetAllClientNotifications' as QueryKey,

  /**
   *
   * @summary Get all clients notifications
   */
  getAllClientNotifications(orgId: string): QueryKey {
    return ['AdminApiGetAllClientNotifications', orgId];
  },
  /**
   *
   * @summary List client third parties
   */
  getAllClientThirdPartiesRoot: 'AdminApiGetAllClientThirdParties' as QueryKey,

  /**
   *
   * @summary List client third parties
   */
  getAllClientThirdParties(
    orgId: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientThirdParties',
      orgId,
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary List client third party summaries
   */
  getAllClientThirdPartySummariesRoot:
    'AdminApiGetAllClientThirdPartySummaries' as QueryKey,

  /**
   *
   * @summary List client third party summaries
   */
  getAllClientThirdPartySummaries(
    orgId: string,
    searchtext?: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientThirdPartySummaries',
      orgId,
      searchtext,
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary List clients account summaries
   */
  getAllClientsAccountSummariesRoot:
    'AdminApiGetAllClientsAccountSummaries' as QueryKey,

  /**
   *
   * @summary List clients account summaries
   */
  getAllClientsAccountSummaries(
    searchtext?: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter
  ): QueryKey {
    return [
      'AdminApiGetAllClientsAccountSummaries',
      searchtext,
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
    ];
  },
  /**
   *
   * @summary List clients accounts
   */
  getAllClientsAccountsRoot: 'AdminApiGetAllClientsAccounts' as QueryKey,

  /**
   *
   * @summary List clients accounts
   */
  getAllClientsAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientsAccounts',
      name,
      descriptor,
      balanceCurrency,
      balanceValue,
      creationDate,
      createdBy,
      lastActivity,
      terminationDate,
      status,
      iban,
      useDynamicDescriptor,
      isThirdParty,
      utilized,
      beneficiaries,
      sort,
    ];
  },
  /**
   *
   * @summary List activity for all clients
   */
  getAllClientsActivityRoot: 'AdminApiGetAllClientsActivity' as QueryKey,

  /**
   *
   * @summary List activity for all clients
   */
  getAllClientsActivity(timestamp?: FilterParameter): QueryKey {
    return ['AdminApiGetAllClientsActivity', timestamp];
  },
  /**
   *
   * @summary List clients contact summaries
   */
  getAllClientsContactSummariesRoot:
    'AdminApiGetAllClientsContactSummaries' as QueryKey,

  /**
   *
   * @summary List clients contact summaries
   */
  getAllClientsContactSummaries(
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientsContactSummaries',
      searchtext,
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary List clients contacts
   */
  getAllClientsContactsRoot: 'AdminApiGetAllClientsContacts' as QueryKey,

  /**
   *
   * @summary List clients contacts
   */
  getAllClientsContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientsContacts',
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary List clients third parties
   */
  getAllClientsThirdPartiesRoot:
    'AdminApiGetAllClientsThirdParties' as QueryKey,

  /**
   *
   * @summary List clients third parties
   */
  getAllClientsThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientsThirdParties',
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary List clients third parties summaries
   */
  getAllClientsThirdPartySummariesRoot:
    'AdminApiGetAllClientsThirdPartySummaries' as QueryKey,

  /**
   *
   * @summary List clients third parties summaries
   */
  getAllClientsThirdPartySummaries(
    searchtext?: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetAllClientsThirdPartySummaries',
      searchtext,
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary List payment auditing entries originating from all accounts
   */
  getAllPaymentAuditingEntriesRoot:
    'AdminApiGetAllPaymentAuditingEntries' as QueryKey,

  /**
   *
   * @summary List payment auditing entries originating from all accounts
   */
  getAllPaymentAuditingEntries(
    timestamp?: FilterParameter,
    fromAccountIban?: FilterParameter,
    toAccountIban?: FilterParameter
  ): QueryKey {
    return [
      'AdminApiGetAllPaymentAuditingEntries',
      timestamp,
      fromAccountIban,
      toAccountIban,
    ];
  },
  /**
   *
   * @summary Get all compliance check lists
   */
  getChecklistsRoot: 'AdminApiGetChecklists' as QueryKey,

  /**
   *
   * @summary Get all compliance check lists
   */
  getChecklists(): QueryKey {
    return ['AdminApiGetChecklists'];
  },
  /**
   *
   * @summary Get client
   */
  getClientRoot: 'AdminApiGetClient' as QueryKey,

  /**
   *
   * @summary Get client
   */
  getClient(id: string): QueryKey {
    return ['AdminApiGetClient', id];
  },
  /**
   *
   * @summary Get client account
   */
  getClientAccountRoot: 'AdminApiGetClientAccount' as QueryKey,

  /**
   *
   * @summary Get client account
   */
  getClientAccount(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientAccount', orgId, id];
  },
  /**
   *
   * @summary List activities for a client account
   */
  getClientAccountActivityRoot: 'AdminApiGetClientAccountActivity' as QueryKey,

  /**
   *
   * @summary List activities for a client account
   */
  getClientAccountActivity(
    orgId: string,
    id: string,
    timestamp?: FilterParameter
  ): QueryKey {
    return ['AdminApiGetClientAccountActivity', orgId, id, timestamp];
  },
  /**
   *
   * @summary Get client account summary using Iban
   */
  getClientAccountSummaryByIbanRoot:
    'AdminApiGetClientAccountSummaryByIban' as QueryKey,

  /**
   *
   * @summary Get client account summary using Iban
   */
  getClientAccountSummaryByIban(orgId: string, iban: string): QueryKey {
    return ['AdminApiGetClientAccountSummaryByIban', orgId, iban];
  },
  /**
   *
   * @summary Get client account summary using Id
   */
  getClientAccountSummaryByIdRoot:
    'AdminApiGetClientAccountSummaryById' as QueryKey,

  /**
   *
   * @summary Get client account summary using Id
   */
  getClientAccountSummaryById(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientAccountSummaryById', orgId, id];
  },
  /**
   *
   * @summary Get client attachment
   */
  getClientAttachmentRoot: 'AdminApiGetClientAttachment' as QueryKey,

  /**
   *
   * @summary Get client attachment
   */
  getClientAttachment(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientAttachment', orgId, id];
  },
  /**
   *
   * @summary Get client contact
   */
  getClientContactRoot: 'AdminApiGetClientContact' as QueryKey,

  /**
   *
   * @summary Get client contact
   */
  getClientContact(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientContact', orgId, id];
  },
  /**
   *
   * @summary Get client contact summary using Id
   */
  getClientContactSummaryByIdRoot:
    'AdminApiGetClientContactSummaryById' as QueryKey,

  /**
   *
   * @summary Get client contact summary using Id
   */
  getClientContactSummaryById(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientContactSummaryById', orgId, id];
  },
  /**
   *
   * @summary Get client\'s current review
   */
  getClientCurrentReviewRoot: 'AdminApiGetClientCurrentReview' as QueryKey,

  /**
   *
   * @summary Get client\'s current review
   */
  getClientCurrentReview(orgId: string): QueryKey {
    return ['AdminApiGetClientCurrentReview', orgId];
  },
  /**
   *
   * @summary Get client notification
   */
  getClientNotificationRoot: 'AdminApiGetClientNotification' as QueryKey,

  /**
   *
   * @summary Get client notification
   */
  getClientNotification(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientNotification', orgId, id];
  },
  /**
   *
   * @summary Get client payment
   */
  getClientPaymentRoot: 'AdminApiGetClientPayment' as QueryKey,

  /**
   *
   * @summary Get client payment
   */
  getClientPayment(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientPayment', orgId, id];
  },
  /**
   *
   * @summary Get client payments
   */
  getClientPaymentsRoot: 'AdminApiGetClientPayments' as QueryKey,

  /**
   *
   * @summary Get client payments
   */
  getClientPayments(
    orgId: string,
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetClientPayments',
      orgId,
      amountCurrency,
      amountValue,
      _class,
      fromIban,
      fromName,
      toIban,
      toName,
      status,
      timeStamp,
      type,
      id,
      clearingSystem,
      clearingSystemTxId,
      instructionId,
      description,
      senderReference,
      batchReference,
      sort,
    ];
  },
  /**
   *
   * @summary Get client profile
   */
  getClientProfileRoot: 'AdminApiGetClientProfile' as QueryKey,

  /**
   *
   * @summary Get client profile
   */
  getClientProfile(id: string): QueryKey {
    return ['AdminApiGetClientProfile', id];
  },
  /**
   *
   * @summary Get client profile summary using Id
   */
  getClientProfileSummaryByIdRoot:
    'AdminApiGetClientProfileSummaryById' as QueryKey,

  /**
   *
   * @summary Get client profile summary using Id
   */
  getClientProfileSummaryById(id: string): QueryKey {
    return ['AdminApiGetClientProfileSummaryById', id];
  },
  /**
   *
   * @summary List client profiles
   */
  getClientProfilesRoot: 'AdminApiGetClientProfiles' as QueryKey,

  /**
   *
   * @summary List client profiles
   */
  getClientProfiles(supplier?: FilterParameter, sort?: string): QueryKey {
    return ['AdminApiGetClientProfiles', supplier, sort];
  },
  /**
   *
   * @summary List client profile summaries
   */
  getClientProfilesSummariesRoot:
    'AdminApiGetClientProfilesSummaries' as QueryKey,

  /**
   *
   * @summary List client profile summaries
   */
  getClientProfilesSummaries(searchtext?: string): QueryKey {
    return ['AdminApiGetClientProfilesSummaries', searchtext];
  },
  /**
   *
   * @summary Get a client organization settings, including fee accounts
   */
  getClientSettingsRoot: 'AdminApiGetClientSettings' as QueryKey,

  /**
   *
   * @summary Get a client organization settings, including fee accounts
   */
  getClientSettings(id: string): QueryKey {
    return ['AdminApiGetClientSettings', id];
  },
  /**
   *
   * @summary Get client summary
   */
  getClientSummaryByIdRoot: 'AdminApiGetClientSummaryById' as QueryKey,

  /**
   *
   * @summary Get client summary
   */
  getClientSummaryById(id: string): QueryKey {
    return ['AdminApiGetClientSummaryById', id];
  },
  /**
   *
   * @summary Get client third party
   */
  getClientThirdPartyRoot: 'AdminApiGetClientThirdParty' as QueryKey,

  /**
   *
   * @summary Get client third party
   */
  getClientThirdParty(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientThirdParty', orgId, id];
  },
  /**
   *
   * @summary Get client third party summary using Id
   */
  getClientThirdPartySummaryByIdRoot:
    'AdminApiGetClientThirdPartySummaryById' as QueryKey,

  /**
   *
   * @summary Get client third party summary using Id
   */
  getClientThirdPartySummaryById(orgId: string, id: string): QueryKey {
    return ['AdminApiGetClientThirdPartySummaryById', orgId, id];
  },
  /**
   *
   * @summary List clients
   */
  getClientsRoot: 'AdminApiGetClients' as QueryKey,

  /**
   *
   * @summary List clients
   */
  getClients(
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetClients',
      status,
      complianceStatus,
      supplier,
      legalName,
      informalName,
      incorporationLoc,
      incorporationNumber,
      incorporationDate,
      sIC,
      lastKycReview,
      clientProfileComplianceLevel,
      startDate,
      webSite,
      clientProfile,
      referrer,
      sort,
    ];
  },
  /**
   *
   * @summary Get all clients payments
   */
  getClientsPaymentsRoot: 'AdminApiGetClientsPayments' as QueryKey,

  /**
   *
   * @summary Get all clients payments
   */
  getClientsPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'AdminApiGetClientsPayments',
      amountCurrency,
      amountValue,
      _class,
      fromIban,
      fromName,
      toIban,
      toName,
      status,
      timeStamp,
      type,
      id,
      clearingSystem,
      clearingSystemTxId,
      instructionId,
      description,
      senderReference,
      batchReference,
      sort,
    ];
  },
  /**
   *
   * @summary List clients summaries
   */
  getClientsSummariesRoot: 'AdminApiGetClientsSummaries' as QueryKey,

  /**
   *
   * @summary List clients summaries
   */
  getClientsSummaries(
    searchtext?: string,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter
  ): QueryKey {
    return [
      'AdminApiGetClientsSummaries',
      searchtext,
      status,
      complianceStatus,
      supplier,
      legalName,
      informalName,
      incorporationLoc,
      incorporationNumber,
      incorporationDate,
      sIC,
      lastKycReview,
      clientProfileComplianceLevel,
      startDate,
      webSite,
      clientProfile,
      referrer,
    ];
  },
  /**
   *
   * @summary Get all products
   */
  getProductsRoot: 'AdminApiGetProducts' as QueryKey,

  /**
   *
   * @summary Get all products
   */
  getProducts(): QueryKey {
    return ['AdminApiGetProducts'];
  },
  /**
   *
   * @summary Get risk survey
   */
  getRiskSurveyRoot: 'AdminApiGetRiskSurvey' as QueryKey,

  /**
   *
   * @summary Get risk survey
   */
  getRiskSurvey(id: string): QueryKey {
    return ['AdminApiGetRiskSurvey', id];
  },
  /**
   *
   * @summary List risk survey summaries
   */
  getRiskSurveySummariesRoot: 'AdminApiGetRiskSurveySummaries' as QueryKey,

  /**
   *
   * @summary List risk survey summaries
   */
  getRiskSurveySummaries(searchtext?: string): QueryKey {
    return ['AdminApiGetRiskSurveySummaries', searchtext];
  },
  /**
   *
   * @summary Get risk survey summary using Id
   */
  getRiskSurveySummaryByIdRoot: 'AdminApiGetRiskSurveySummaryById' as QueryKey,

  /**
   *
   * @summary Get risk survey summary using Id
   */
  getRiskSurveySummaryById(id: string): QueryKey {
    return ['AdminApiGetRiskSurveySummaryById', id];
  },
  /**
   *
   * @summary Get all risk surveys
   */
  getRiskSurveysRoot: 'AdminApiGetRiskSurveys' as QueryKey,

  /**
   *
   * @summary Get all risk surveys
   */
  getRiskSurveys(): QueryKey {
    return ['AdminApiGetRiskSurveys'];
  },
  /**
   *
   * @summary Lock a client\'s profile
   */
  lockClientProfileRoot: 'AdminApiLockClientProfile' as QueryKey,

  /**
   *
   * @summary Lock a client\'s profile
   */
  lockClientProfile(id: string): QueryKey {
    return ['AdminApiLockClientProfile', id];
  },
  /**
   *
   * @summary Reject pending updates to a client
   */
  rejectClientUpdatesRoot: 'AdminApiRejectClientUpdates' as QueryKey,

  /**
   *
   * @summary Reject pending updates to a client
   */
  rejectClientUpdates(id: string): QueryKey {
    return ['AdminApiRejectClientUpdates', id];
  },
  /**
   *
   * @summary Releases a held client payment
   */
  releaseHeldClientPaymentRoot: 'AdminApiReleaseHeldClientPayment' as QueryKey,

  /**
   *
   * @summary Releases a held client payment
   */
  releaseHeldClientPayment(
    orgId: string,
    id: string,
    paymentRelease: PaymentRelease
  ): QueryKey {
    return ['AdminApiReleaseHeldClientPayment', orgId, id, paymentRelease];
  },
  /**
   *
   * @summary Reset a client contact password
   */
  resetClientContactPasswordRoot:
    'AdminApiResetClientContactPassword' as QueryKey,

  /**
   *
   * @summary Reset a client contact password
   */
  resetClientContactPassword(
    orgId: string,
    id: string,
    resetPasswordPutBody: ResetPasswordPutBody
  ): QueryKey {
    return [
      'AdminApiResetClientContactPassword',
      orgId,
      id,
      resetPasswordPutBody,
    ];
  },
  /**
   *
   * @summary Review/make updates to a client
   */
  reviewClientUpdatesRoot: 'AdminApiReviewClientUpdates' as QueryKey,

  /**
   *
   * @summary Review/make updates to a client
   */
  reviewClientUpdates(id: string): QueryKey {
    return ['AdminApiReviewClientUpdates', id];
  },
  /**
   *
   * @summary Change a client\'s operational status to Suspended
   */
  suspendClientRoot: 'AdminApiSuspendClient' as QueryKey,

  /**
   *
   * @summary Change a client\'s operational status to Suspended
   */
  suspendClient(id: string): QueryKey {
    return ['AdminApiSuspendClient', id];
  },
  /**
   *
   * @summary Suspend a client account
   */
  suspendClientAccountRoot: 'AdminApiSuspendClientAccount' as QueryKey,

  /**
   *
   * @summary Suspend a client account
   */
  suspendClientAccount(orgId: string, id: string): QueryKey {
    return ['AdminApiSuspendClientAccount', orgId, id];
  },
  /**
   *
   * @summary Suspend a client contact
   */
  suspendClientContactRoot: 'AdminApiSuspendClientContact' as QueryKey,

  /**
   *
   * @summary Suspend a client contact
   */
  suspendClientContact(orgId: string, id: string): QueryKey {
    return ['AdminApiSuspendClientContact', orgId, id];
  },
  /**
   *
   * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
   */
  terminateClientRoot: 'AdminApiTerminateClient' as QueryKey,

  /**
   *
   * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
   */
  terminateClient(id: string): QueryKey {
    return ['AdminApiTerminateClient', id];
  },
  /**
   *
   * @summary Terminate a client account
   */
  terminateClientAccountRoot: 'AdminApiTerminateClientAccount' as QueryKey,

  /**
   *
   * @summary Terminate a client account
   */
  terminateClientAccount(orgId: string, id: string): QueryKey {
    return ['AdminApiTerminateClientAccount', orgId, id];
  },
  /**
   *
   * @summary Terminate a client contact
   */
  terminateClientContactRoot: 'AdminApiTerminateClientContact' as QueryKey,

  /**
   *
   * @summary Terminate a client contact
   */
  terminateClientContact(orgId: string, id: string): QueryKey {
    return ['AdminApiTerminateClientContact', orgId, id];
  },
  /**
   *
   * @summary Unlock a client\'s profile
   */
  unlockClientProfileRoot: 'AdminApiUnlockClientProfile' as QueryKey,

  /**
   *
   * @summary Unlock a client\'s profile
   */
  unlockClientProfile(id: string): QueryKey {
    return ['AdminApiUnlockClientProfile', id];
  },
  /**
   *
   * @summary Update account block
   */
  updateAccountBlockRoot: 'AdminApiUpdateAccountBlock' as QueryKey,

  /**
   *
   * @summary Update account block
   */
  updateAccountBlock(
    id: string,
    accountBlockPutBody?: AccountBlockPutBody
  ): QueryKey {
    return ['AdminApiUpdateAccountBlock', id, accountBlockPutBody];
  },
  /**
   *
   * @summary Update a client
   */
  updateClientRoot: 'AdminApiUpdateClient' as QueryKey,

  /**
   *
   * @summary Update a client
   */
  updateClient(id: string, client?: Client): QueryKey {
    return ['AdminApiUpdateClient', id, client];
  },
  /**
   *
   * @summary Update a client account
   */
  updateClientAccountRoot: 'AdminApiUpdateClientAccount' as QueryKey,

  /**
   *
   * @summary Update a client account
   */
  updateClientAccount(
    orgId: string,
    id: string,
    accountAdminPutBody?: AccountAdminPutBody
  ): QueryKey {
    return ['AdminApiUpdateClientAccount', orgId, id, accountAdminPutBody];
  },
  /**
   *
   * @summary Update a client contact
   */
  updateClientContactRoot: 'AdminApiUpdateClientContact' as QueryKey,

  /**
   *
   * @summary Update a client contact
   */
  updateClientContact(
    orgId: string,
    id: string,
    contactPutBody?: ContactPutBody
  ): QueryKey {
    return ['AdminApiUpdateClientContact', orgId, id, contactPutBody];
  },
  /**
   *
   * @summary Update a client notification
   */
  updateClientNotificationRoot: 'AdminApiUpdateClientNotification' as QueryKey,

  /**
   *
   * @summary Update a client notification
   */
  updateClientNotification(
    orgId: string,
    id: string,
    notificationPostPutRequest?: NotificationPostPutRequest
  ): QueryKey {
    return [
      'AdminApiUpdateClientNotification',
      orgId,
      id,
      notificationPostPutRequest,
    ];
  },
  /**
   *
   * @summary Update client profile
   */
  updateClientProfileRoot: 'AdminApiUpdateClientProfile' as QueryKey,

  /**
   *
   * @summary Update client profile
   */
  updateClientProfile(
    id: string,
    clientProfilePostPutBody?: ClientProfilePostPutBody
  ): QueryKey {
    return ['AdminApiUpdateClientProfile', id, clientProfilePostPutBody];
  },
  /**
   *
   * @summary Update a client organization settings, including fee accounts
   */
  updateClientSettingsRoot: 'AdminApiUpdateClientSettings' as QueryKey,

  /**
   *
   * @summary Update a client organization settings, including fee accounts
   */
  updateClientSettings(id: string, settings?: Settings): QueryKey {
    return ['AdminApiUpdateClientSettings', id, settings];
  },
  /**
   *
   * @summary Update a client third party
   */
  updateClientThirdPartyRoot: 'AdminApiUpdateClientThirdParty' as QueryKey,

  /**
   *
   * @summary Update a client third party
   */
  updateClientThirdParty(
    orgId: string,
    id: string,
    thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody
  ): QueryKey {
    return [
      'AdminApiUpdateClientThirdParty',
      orgId,
      id,
      thirdPartyAdminPostPutBody,
    ];
  },
  /**
   *
   * @summary Update risk survey
   */
  updateRiskSurveyRoot: 'AdminApiUpdateRiskSurvey' as QueryKey,

  /**
   *
   * @summary Update risk survey
   */
  updateRiskSurvey(
    id: string,
    riskSurveyPostPutBody?: RiskSurveyPostPutBody
  ): QueryKey {
    return ['AdminApiUpdateRiskSurvey', id, riskSurveyPostPutBody];
  },
  /**
   *
   * @summary Upload a new attachment
   */
  uploadClientAttachmentRoot: 'AdminApiUploadClientAttachment' as QueryKey,

  /**
   *
   * @summary Upload a new attachment
   */
  uploadClientAttachment(orgId: string, type: string, file: any): QueryKey {
    return ['AdminApiUploadClientAttachment', orgId, type, file];
  },
};

/**
 * AdminApi - react-query method creator
 */
export class AdminApiMaker extends BaseApiMaker<AdminApi> {
  /**
   * Get an AdminApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): AdminApi {
    return new AdminApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Change a client\'s operational status to Active
   * @memberof AdminApi
   */
  public activateClient(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.activateClient(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.activateClient(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Change a client\'s operational status to Active
   * @memberof AdminApi
   */
  public activateClientMutation(): ApiMutationHandler<
    AdminApiActivateClientArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiActivateClient',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.activateClient(id, options)),
    };
  }

  /**
   *
   * @summary Activate a client account
   * @memberof AdminApi
   */
  public activateClientAccount(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountAdminBody> {
    return {
      queryKey: AdminApiQueryKey.activateClientAccount(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.activateClientAccount(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Activate a client account
   * @memberof AdminApi
   */
  public activateClientAccountMutation(): ApiMutationHandler<
    AdminApiActivateClientAccountArgs,
    AccountAdminBody
  > {
    return {
      mutationKey: 'AdminApiActivateClientAccount',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.activateClientAccount(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Activate a client contact
   * @memberof AdminApi
   */
  public activateClientContact(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: AdminApiQueryKey.activateClientContact(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.activateClientContact(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Activate a client contact
   * @memberof AdminApi
   */
  public activateClientContactMutation(): ApiMutationHandler<
    AdminApiActivateClientContactArgs,
    Contact
  > {
    return {
      mutationKey: 'AdminApiActivateClientContact',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.activateClientContact(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Add a new client Contact
   * @memberof AdminApi
   */
  public addClientContact(
    orgId: string,
    contactPostBody?: ContactPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: AdminApiQueryKey.addClientContact(orgId, contactPostBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.addClientContact(
            orgId,
            contactPostBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Add a new client Contact
   * @memberof AdminApi
   */
  public addClientContactMutation(): ApiMutationHandler<
    AdminApiAddClientContactArgs,
    Contact
  > {
    return {
      mutationKey: 'AdminApiAddClientContact',
      mutationFn: (
        orgId: string,
        contactPostBody?: ContactPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.addClientContact(orgId, contactPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Add a new client third party
   * @memberof AdminApi
   */
  public addClientThirdParty(
    orgId: string,
    thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdParty> {
    return {
      queryKey: AdminApiQueryKey.addClientThirdParty(
        orgId,
        thirdPartyAdminPostPutBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.addClientThirdParty(
            orgId,
            thirdPartyAdminPostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Add a new client third party
   * @memberof AdminApi
   */
  public addClientThirdPartyMutation(): ApiMutationHandler<
    AdminApiAddClientThirdPartyArgs,
    ThirdParty
  > {
    return {
      mutationKey: 'AdminApiAddClientThirdParty',
      mutationFn: (
        orgId: string,
        thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.addClientThirdParty(orgId, thirdPartyAdminPostPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Approve pending updates to a client
   * @memberof AdminApi
   */
  public approveClientUpdates(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.approveClientUpdates(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.approveClientUpdates(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Approve pending updates to a client
   * @memberof AdminApi
   */
  public approveClientUpdatesMutation(): ApiMutationHandler<
    AdminApiApproveClientUpdatesArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiApproveClientUpdates',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.approveClientUpdates(id, options)),
    };
  }

  /**
   *
   * @summary Bless account block
   * @memberof AdminApi
   */
  public blessAccountBlock(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountBlock> {
    return {
      queryKey: AdminApiQueryKey.blessAccountBlock(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.blessAccountBlock(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Bless account block
   * @memberof AdminApi
   */
  public blessAccountBlockMutation(): ApiMutationHandler<
    AdminApiBlessAccountBlockArgs,
    AccountBlock
  > {
    return {
      mutationKey: 'AdminApiBlessAccountBlock',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.blessAccountBlock(id, options)
        ),
    };
  }

  /**
   *
   * @summary Block account block
   * @memberof AdminApi
   */
  public blockAccountBlock(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountBlock> {
    return {
      queryKey: AdminApiQueryKey.blockAccountBlock(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.blockAccountBlock(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Block account block
   * @memberof AdminApi
   */
  public blockAccountBlockMutation(): ApiMutationHandler<
    AdminApiBlockAccountBlockArgs,
    AccountBlock
  > {
    return {
      mutationKey: 'AdminApiBlockAccountBlock',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.blockAccountBlock(id, options)
        ),
    };
  }

  /**
   *
   * @summary Broadcast a new client notification to 1 or more clients
   * @memberof AdminApi
   */
  public broadcastNotification(
    notificationBroadcastRequest?: NotificationBroadcastRequest,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<string>> {
    return {
      queryKey: AdminApiQueryKey.broadcastNotification(
        notificationBroadcastRequest
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<string>>((api) =>
          api.broadcastNotification(
            notificationBroadcastRequest,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Broadcast a new client notification to 1 or more clients
   * @memberof AdminApi
   */
  public broadcastNotificationMutation(): ApiMutationHandler<
    AdminApiBroadcastNotificationArgs,
    Array<string>
  > {
    return {
      mutationKey: 'AdminApiBroadcastNotification',
      mutationFn: (
        notificationBroadcastRequest?: NotificationBroadcastRequest,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<string>>((api) =>
          api.broadcastNotification(notificationBroadcastRequest, options)
        ),
    };
  }

  /**
   *
   * @summary Cancels a held client payment
   * @memberof AdminApi
   */
  public cancelHeldClientPayment(
    orgId: string,
    id: string,
    paymentCancellation: PaymentCancellation,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Payment> {
    return {
      queryKey: AdminApiQueryKey.cancelHeldClientPayment(
        orgId,
        id,
        paymentCancellation
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Payment>((api) =>
          api.cancelHeldClientPayment(
            orgId,
            id,
            paymentCancellation,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Cancels a held client payment
   * @memberof AdminApi
   */
  public cancelHeldClientPaymentMutation(): ApiMutationHandler<
    AdminApiCancelHeldClientPaymentArgs,
    Payment
  > {
    return {
      mutationKey: 'AdminApiCancelHeldClientPayment',
      mutationFn: (
        orgId: string,
        id: string,
        paymentCancellation: PaymentCancellation,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Payment>((api) =>
          api.cancelHeldClientPayment(orgId, id, paymentCancellation, options)
        ),
    };
  }

  /**
   *
   * @summary Create a new account block
   * @memberof AdminApi
   */
  public createAccountBlock(
    accountBlockPostBody?: AccountBlockPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountBlock> {
    return {
      queryKey: AdminApiQueryKey.createAccountBlock(accountBlockPostBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.createAccountBlock(
            accountBlockPostBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Create a new account block
   * @memberof AdminApi
   */
  public createAccountBlockMutation(): ApiMutationHandler<
    AdminApiCreateAccountBlockArgs,
    AccountBlock
  > {
    return {
      mutationKey: 'AdminApiCreateAccountBlock',
      mutationFn: (
        accountBlockPostBody?: AccountBlockPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.createAccountBlock(accountBlockPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Create a new client
   * @memberof AdminApi
   */
  public createClient(
    clientPostBody?: ClientPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.createClient(clientPostBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.createClient(clientPostBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Create a new client
   * @memberof AdminApi
   */
  public createClientMutation(): ApiMutationHandler<
    AdminApiCreateClientArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiCreateClient',
      mutationFn: (
        clientPostBody?: ClientPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Client>((api) =>
          api.createClient(clientPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Add an account for a specified client
   * @memberof AdminApi
   */
  public createClientAccount(
    orgId: string,
    accountAdminPostBody?: AccountAdminPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Account> {
    return {
      queryKey: AdminApiQueryKey.createClientAccount(
        orgId,
        accountAdminPostBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Account>((api) =>
          api.createClientAccount(
            orgId,
            accountAdminPostBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Add an account for a specified client
   * @memberof AdminApi
   */
  public createClientAccountMutation(): ApiMutationHandler<
    AdminApiCreateClientAccountArgs,
    Account
  > {
    return {
      mutationKey: 'AdminApiCreateClientAccount',
      mutationFn: (
        orgId: string,
        accountAdminPostBody?: AccountAdminPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Account>((api) =>
          api.createClientAccount(orgId, accountAdminPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Create a new client profile
   * @memberof AdminApi
   */
  public createClientProfile(
    clientProfilePostPutBody?: ClientProfilePostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientProfile> {
    return {
      queryKey: AdminApiQueryKey.createClientProfile(clientProfilePostPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientProfile>((api) =>
          api.createClientProfile(
            clientProfilePostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Create a new client profile
   * @memberof AdminApi
   */
  public createClientProfileMutation(): ApiMutationHandler<
    AdminApiCreateClientProfileArgs,
    ClientProfile
  > {
    return {
      mutationKey: 'AdminApiCreateClientProfile',
      mutationFn: (
        clientProfilePostPutBody?: ClientProfilePostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ClientProfile>((api) =>
          api.createClientProfile(clientProfilePostPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Create a new client notification
   * @memberof AdminApi
   */
  public createNotification(
    orgId: string,
    notificationPostPutRequest?: NotificationPostPutRequest,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<NotificationResponse> {
    return {
      queryKey: AdminApiQueryKey.createNotification(
        orgId,
        notificationPostPutRequest
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.createNotification(
            orgId,
            notificationPostPutRequest,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Create a new client notification
   * @memberof AdminApi
   */
  public createNotificationMutation(): ApiMutationHandler<
    AdminApiCreateNotificationArgs,
    NotificationResponse
  > {
    return {
      mutationKey: 'AdminApiCreateNotification',
      mutationFn: (
        orgId: string,
        notificationPostPutRequest?: NotificationPostPutRequest,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.createNotification(orgId, notificationPostPutRequest, options)
        ),
    };
  }

  /**
   *
   * @summary Create a new risk survey
   * @memberof AdminApi
   */
  public createRiskSurvey(
    riskSurveyPostPutBody?: RiskSurveyPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<RiskSurvey> {
    return {
      queryKey: AdminApiQueryKey.createRiskSurvey(riskSurveyPostPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<RiskSurvey>((api) =>
          api.createRiskSurvey(
            riskSurveyPostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Create a new risk survey
   * @memberof AdminApi
   */
  public createRiskSurveyMutation(): ApiMutationHandler<
    AdminApiCreateRiskSurveyArgs,
    RiskSurvey
  > {
    return {
      mutationKey: 'AdminApiCreateRiskSurvey',
      mutationFn: (
        riskSurveyPostPutBody?: RiskSurveyPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<RiskSurvey>((api) =>
          api.createRiskSurvey(riskSurveyPostPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Delete a client notification
   * @memberof AdminApi
   */
  public deleteClientNotification(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<NotificationDeleteResponse> {
    return {
      queryKey: AdminApiQueryKey.deleteClientNotification(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<NotificationDeleteResponse>((api) =>
          api.deleteClientNotification(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Delete a client notification
   * @memberof AdminApi
   */
  public deleteClientNotificationMutation(): ApiMutationHandler<
    AdminApiDeleteClientNotificationArgs,
    NotificationDeleteResponse
  > {
    return {
      mutationKey: 'AdminApiDeleteClientNotification',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<NotificationDeleteResponse>((api) =>
          api.deleteClientNotification(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Bless account block
   * @memberof AdminApi
   */
  public disableAccountBlock(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountBlock> {
    return {
      queryKey: AdminApiQueryKey.disableAccountBlock(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.disableAccountBlock(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Bless account block
   * @memberof AdminApi
   */
  public disableAccountBlockMutation(): ApiMutationHandler<
    AdminApiDisableAccountBlockArgs,
    AccountBlock
  > {
    return {
      mutationKey: 'AdminApiDisableAccountBlock',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.disableAccountBlock(id, options)
        ),
    };
  }

  /**
   *
   * @summary Export all account blocks
   * @memberof AdminApi
   */
  public exportAccountBlocks(
    iban?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAccountBlocks(iban, status, sort),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAccountBlocks(
            iban,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export all account blocks
   * @memberof AdminApi
   */
  public exportAccountBlocksMutation(): ApiMutationHandler<
    AdminApiExportAccountBlocksArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAccountBlocks',
      mutationFn: (
        iban?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAccountBlocks(iban, status, sort, options)
        ),
    };
  }

  /**
   *
   * @summary Export all client accounts
   * @memberof AdminApi
   */
  public exportAllClientAccounts(
    orgId: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientAccounts(
        orgId,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientAccounts(
            orgId,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export all client accounts
   * @memberof AdminApi
   */
  public exportAllClientAccountsMutation(): ApiMutationHandler<
    AdminApiExportAllClientAccountsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientAccounts',
      mutationFn: (
        orgId: string,
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientAccounts(
            orgId,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export activity for a client
   * @memberof AdminApi
   */
  public exportAllClientActivity(
    orgId: string,
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientActivity(orgId, timestamp),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientActivity(
            orgId,
            timestamp,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export activity for a client
   * @memberof AdminApi
   */
  public exportAllClientActivityMutation(): ApiMutationHandler<
    AdminApiExportAllClientActivityArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientActivity',
      mutationFn: (
        orgId: string,
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientActivity(orgId, timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary Export client contacts
   * @memberof AdminApi
   */
  public exportAllClientContacts(
    orgId: any,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientContacts(
        orgId,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientContacts(
            orgId,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export client contacts
   * @memberof AdminApi
   */
  public exportAllClientContactsMutation(): ApiMutationHandler<
    AdminApiExportAllClientContactsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientContacts',
      mutationFn: (
        orgId: any,
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientContacts(
            orgId,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export client third parties
   * @memberof AdminApi
   */
  public exportAllClientThirdParties(
    orgId: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientThirdParties(
        orgId,
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientThirdParties(
            orgId,
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export client third parties
   * @memberof AdminApi
   */
  public exportAllClientThirdPartiesMutation(): ApiMutationHandler<
    AdminApiExportAllClientThirdPartiesArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientThirdParties',
      mutationFn: (
        orgId: string,
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientThirdParties(
            orgId,
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export clients accounts
   * @memberof AdminApi
   */
  public exportAllClientsAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientsAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export clients accounts
   * @memberof AdminApi
   */
  public exportAllClientsAccountsMutation(): ApiMutationHandler<
    AdminApiExportAllClientsAccountsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientsAccounts',
      mutationFn: (
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export activity for all clients
   * @memberof AdminApi
   */
  public exportAllClientsActivity(
    timestamp?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientsActivity(timestamp, sort),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsActivity(
            timestamp,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export activity for all clients
   * @memberof AdminApi
   */
  public exportAllClientsActivityMutation(): ApiMutationHandler<
    AdminApiExportAllClientsActivityArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientsActivity',
      mutationFn: (
        timestamp?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsActivity(timestamp, sort, options)
        ),
    };
  }

  /**
   *
   * @summary Export clients contacts
   * @memberof AdminApi
   */
  public exportAllClientsContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientsContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export clients contacts
   * @memberof AdminApi
   */
  public exportAllClientsContactsMutation(): ApiMutationHandler<
    AdminApiExportAllClientsContactsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientsContacts',
      mutationFn: (
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export clients third parties
   * @memberof AdminApi
   */
  public exportAllClientsThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportAllClientsThirdParties(
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export clients third parties
   * @memberof AdminApi
   */
  public exportAllClientsThirdPartiesMutation(): ApiMutationHandler<
    AdminApiExportAllClientsThirdPartiesArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportAllClientsThirdParties',
      mutationFn: (
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportAllClientsThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export activity for a client account
   * @memberof AdminApi
   */
  public exportClientAccountActivity(
    orgId: string,
    accountId: string,
    timestamp?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportClientAccountActivity(
        orgId,
        accountId,
        timestamp,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClientAccountActivity(
            orgId,
            accountId,
            timestamp,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export activity for a client account
   * @memberof AdminApi
   */
  public exportClientAccountActivityMutation(): ApiMutationHandler<
    AdminApiExportClientAccountActivityArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportClientAccountActivity',
      mutationFn: (
        orgId: string,
        accountId: string,
        timestamp?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClientAccountActivity(
            orgId,
            accountId,
            timestamp,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export client payments
   * @memberof AdminApi
   */
  public exportClientPayments(
    orgId: string,
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportClientPayments(
        orgId,
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClientPayments(
            orgId,
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export client payments
   * @memberof AdminApi
   */
  public exportClientPaymentsMutation(): ApiMutationHandler<
    AdminApiExportClientPaymentsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportClientPayments',
      mutationFn: (
        orgId: string,
        amountCurrency?: FilterParameter,
        amountValue?: FilterParameter,
        _class?: FilterParameter,
        fromIban?: FilterParameter,
        fromName?: FilterParameter,
        toIban?: FilterParameter,
        toName?: FilterParameter,
        status?: FilterParameter,
        timeStamp?: FilterParameter,
        type?: FilterParameter,
        id?: FilterParameter,
        clearingSystem?: FilterParameter,
        clearingSystemTxId?: FilterParameter,
        instructionId?: FilterParameter,
        description?: FilterParameter,
        senderReference?: FilterParameter,
        batchReference?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClientPayments(
            orgId,
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export clients
   * @memberof AdminApi
   */
  public exportClients(
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportClients(
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClients(
            status,
            complianceStatus,
            supplier,
            legalName,
            informalName,
            incorporationLoc,
            incorporationNumber,
            incorporationDate,
            sIC,
            lastKycReview,
            clientProfileComplianceLevel,
            startDate,
            webSite,
            clientProfile,
            referrer,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export clients
   * @memberof AdminApi
   */
  public exportClientsMutation(): ApiMutationHandler<
    AdminApiExportClientsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportClients',
      mutationFn: (
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        supplier?: FilterParameter,
        legalName?: FilterParameter,
        informalName?: FilterParameter,
        incorporationLoc?: FilterParameter,
        incorporationNumber?: FilterParameter,
        incorporationDate?: FilterParameter,
        sIC?: FilterParameter,
        lastKycReview?: FilterParameter,
        clientProfileComplianceLevel?: FilterParameter,
        startDate?: FilterParameter,
        webSite?: FilterParameter,
        clientProfile?: FilterParameter,
        referrer?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClients(
            status,
            complianceStatus,
            supplier,
            legalName,
            informalName,
            incorporationLoc,
            incorporationNumber,
            incorporationDate,
            sIC,
            lastKycReview,
            clientProfileComplianceLevel,
            startDate,
            webSite,
            clientProfile,
            referrer,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Export all clients payments
   * @memberof AdminApi
   */
  public exportClientsPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.exportClientsPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClientsPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export all clients payments
   * @memberof AdminApi
   */
  public exportClientsPaymentsMutation(): ApiMutationHandler<
    AdminApiExportClientsPaymentsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiExportClientsPayments',
      mutationFn: (
        amountCurrency?: FilterParameter,
        amountValue?: FilterParameter,
        _class?: FilterParameter,
        fromIban?: FilterParameter,
        fromName?: FilterParameter,
        toIban?: FilterParameter,
        toName?: FilterParameter,
        status?: FilterParameter,
        timeStamp?: FilterParameter,
        type?: FilterParameter,
        id?: FilterParameter,
        clearingSystem?: FilterParameter,
        clearingSystemTxId?: FilterParameter,
        instructionId?: FilterParameter,
        description?: FilterParameter,
        senderReference?: FilterParameter,
        batchReference?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportClientsPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Generate client account statement as downloadable PDF
   * @memberof AdminApi
   */
  public generateClientAccountStatement(
    orgId: string,
    id: string,
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: AdminApiQueryKey.generateClientAccountStatement(
        orgId,
        id,
        timestamp
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.generateClientAccountStatement(
            orgId,
            id,
            timestamp,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Generate client account statement as downloadable PDF
   * @memberof AdminApi
   */
  public generateClientAccountStatementMutation(): ApiMutationHandler<
    AdminApiGenerateClientAccountStatementArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'AdminApiGenerateClientAccountStatement',
      mutationFn: (
        orgId: string,
        id: string,
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.generateClientAccountStatement(orgId, id, timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary Get account block
   * @memberof AdminApi
   */
  public getAccountBlock(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountBlock> {
    return {
      queryKey: AdminApiQueryKey.getAccountBlock(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.getAccountBlock(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get account block
   * @memberof AdminApi
   */
  public getAccountBlockMutation(): ApiMutationHandler<
    AdminApiGetAccountBlockArgs,
    AccountBlock
  > {
    return {
      mutationKey: 'AdminApiGetAccountBlock',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.getAccountBlock(id, options)
        ),
    };
  }

  /**
   *
   * @summary Get all account blocks
   * @memberof AdminApi
   */
  public getAccountBlocks(
    iban?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<AccountBlock>> {
    return {
      queryKey: AdminApiQueryKey.getAccountBlocks(iban, status, sort),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<AccountBlock>>((api) =>
          api.getAccountBlocks(iban, status, sort, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get all account blocks
   * @memberof AdminApi
   */
  public getAccountBlocksMutation(): ApiMutationHandler<
    AdminApiGetAccountBlocksArgs,
    Array<AccountBlock>
  > {
    return {
      mutationKey: 'AdminApiGetAccountBlocks',
      mutationFn: (
        iban?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<AccountBlock>>((api) =>
          api.getAccountBlocks(iban, status, sort, options)
        ),
    };
  }

  /**
   *
   * @summary Get all clients attachments
   * @memberof AdminApi
   */
  public getAllAttachments(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Attachment> {
    return {
      queryKey: AdminApiQueryKey.getAllAttachments(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Attachment>((api) =>
          api.getAllAttachments(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get all clients attachments
   * @memberof AdminApi
   */
  public getAllAttachmentsMutation(): ApiMutationHandler<
    AdminApiGetAllAttachmentsArgs,
    Attachment
  > {
    return {
      mutationKey: 'AdminApiGetAllAttachments',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Attachment>((api) => api.getAllAttachments(options)),
    };
  }

  /**
   *
   * @summary List client account summaries
   * @memberof AdminApi
   */
  public getAllClientAccountSummaries(
    orgId: string,
    searchtext?: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<AccountShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientAccountSummaries(
        orgId,
        searchtext,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<AccountShortSummary>>((api) =>
          api.getAllClientAccountSummaries(
            orgId,
            searchtext,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List client account summaries
   * @memberof AdminApi
   */
  public getAllClientAccountSummariesMutation(): ApiMutationHandler<
    AdminApiGetAllClientAccountSummariesArgs,
    Array<AccountShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientAccountSummaries',
      mutationFn: (
        orgId: string,
        searchtext?: string,
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<AccountShortSummary>>((api) =>
          api.getAllClientAccountSummaries(
            orgId,
            searchtext,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List client accounts
   * @memberof AdminApi
   */
  public getAllClientAccounts(
    orgId: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ClientAccountSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientAccounts(
        orgId,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ClientAccountSummary>>((api) =>
          api.getAllClientAccounts(
            orgId,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List client accounts
   * @memberof AdminApi
   */
  public getAllClientAccountsMutation(): ApiMutationHandler<
    AdminApiGetAllClientAccountsArgs,
    Array<ClientAccountSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientAccounts',
      mutationFn: (
        orgId: string,
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ClientAccountSummary>>((api) =>
          api.getAllClientAccounts(
            orgId,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List activity for a client
   * @memberof AdminApi
   */
  public getAllClientActivity(
    orgId: string,
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Activity>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientActivity(orgId, timestamp),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAllClientActivity(
            orgId,
            timestamp,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List activity for a client
   * @memberof AdminApi
   */
  public getAllClientActivityMutation(): ApiMutationHandler<
    AdminApiGetAllClientActivityArgs,
    Array<Activity>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientActivity',
      mutationFn: (
        orgId: string,
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAllClientActivity(orgId, timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary Get all client attachments
   * @memberof AdminApi
   */
  public getAllClientAttachments(
    orgId: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Attachment> {
    return {
      queryKey: AdminApiQueryKey.getAllClientAttachments(orgId),
      queryFn: (queryOptions) =>
        this.runQueryFn<Attachment>((api) =>
          api.getAllClientAttachments(orgId, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get all client attachments
   * @memberof AdminApi
   */
  public getAllClientAttachmentsMutation(): ApiMutationHandler<
    AdminApiGetAllClientAttachmentsArgs,
    Attachment
  > {
    return {
      mutationKey: 'AdminApiGetAllClientAttachments',
      mutationFn: (orgId: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Attachment>((api) =>
          api.getAllClientAttachments(orgId, options)
        ),
    };
  }

  /**
   *
   * @summary List client contact summaries
   * @memberof AdminApi
   */
  public getAllClientContactSummaries(
    orgId: string,
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ContactShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientContactSummaries(
        orgId,
        searchtext,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ContactShortSummary>>((api) =>
          api.getAllClientContactSummaries(
            orgId,
            searchtext,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List client contact summaries
   * @memberof AdminApi
   */
  public getAllClientContactSummariesMutation(): ApiMutationHandler<
    AdminApiGetAllClientContactSummariesArgs,
    Array<ContactShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientContactSummaries',
      mutationFn: (
        orgId: string,
        searchtext?: string,
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ContactShortSummary>>((api) =>
          api.getAllClientContactSummaries(
            orgId,
            searchtext,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List client contacts
   * @memberof AdminApi
   */
  public getAllClientContacts(
    orgId: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ContactSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientContacts(
        orgId,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ContactSummary>>((api) =>
          api.getAllClientContacts(
            orgId,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List client contacts
   * @memberof AdminApi
   */
  public getAllClientContactsMutation(): ApiMutationHandler<
    AdminApiGetAllClientContactsArgs,
    Array<ContactSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientContacts',
      mutationFn: (
        orgId: string,
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ContactSummary>>((api) =>
          api.getAllClientContacts(
            orgId,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get all clients notifications
   * @memberof AdminApi
   */
  public getAllClientNotifications(
    orgId: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<NotificationResponse> {
    return {
      queryKey: AdminApiQueryKey.getAllClientNotifications(orgId),
      queryFn: (queryOptions) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.getAllClientNotifications(orgId, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get all clients notifications
   * @memberof AdminApi
   */
  public getAllClientNotificationsMutation(): ApiMutationHandler<
    AdminApiGetAllClientNotificationsArgs,
    NotificationResponse
  > {
    return {
      mutationKey: 'AdminApiGetAllClientNotifications',
      mutationFn: (orgId: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.getAllClientNotifications(orgId, options)
        ),
    };
  }

  /**
   *
   * @summary List client third parties
   * @memberof AdminApi
   */
  public getAllClientThirdParties(
    orgId: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ThirdPartyAdminSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientThirdParties(
        orgId,
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ThirdPartyAdminSummary>>((api) =>
          api.getAllClientThirdParties(
            orgId,
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List client third parties
   * @memberof AdminApi
   */
  public getAllClientThirdPartiesMutation(): ApiMutationHandler<
    AdminApiGetAllClientThirdPartiesArgs,
    Array<ThirdPartyAdminSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientThirdParties',
      mutationFn: (
        orgId: string,
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ThirdPartyAdminSummary>>((api) =>
          api.getAllClientThirdParties(
            orgId,
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List client third party summaries
   * @memberof AdminApi
   */
  public getAllClientThirdPartySummaries(
    orgId: string,
    searchtext?: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ThirdPartyShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientThirdPartySummaries(
        orgId,
        searchtext,
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ThirdPartyShortSummary>>((api) =>
          api.getAllClientThirdPartySummaries(
            orgId,
            searchtext,
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List client third party summaries
   * @memberof AdminApi
   */
  public getAllClientThirdPartySummariesMutation(): ApiMutationHandler<
    AdminApiGetAllClientThirdPartySummariesArgs,
    Array<ThirdPartyShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientThirdPartySummaries',
      mutationFn: (
        orgId: string,
        searchtext?: string,
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ThirdPartyShortSummary>>((api) =>
          api.getAllClientThirdPartySummaries(
            orgId,
            searchtext,
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List clients account summaries
   * @memberof AdminApi
   */
  public getAllClientsAccountSummaries(
    searchtext?: string,
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<AccountShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientsAccountSummaries(
        searchtext,
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<AccountShortSummary>>((api) =>
          api.getAllClientsAccountSummaries(
            searchtext,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients account summaries
   * @memberof AdminApi
   */
  public getAllClientsAccountSummariesMutation(): ApiMutationHandler<
    AdminApiGetAllClientsAccountSummariesArgs,
    Array<AccountShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientsAccountSummaries',
      mutationFn: (
        searchtext?: string,
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<AccountShortSummary>>((api) =>
          api.getAllClientsAccountSummaries(
            searchtext,
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List clients accounts
   * @memberof AdminApi
   */
  public getAllClientsAccounts(
    name?: FilterParameter,
    descriptor?: FilterParameter,
    balanceCurrency?: FilterParameter,
    balanceValue?: FilterParameter,
    creationDate?: FilterParameter,
    createdBy?: FilterParameter,
    lastActivity?: FilterParameter,
    terminationDate?: FilterParameter,
    status?: FilterParameter,
    iban?: FilterParameter,
    useDynamicDescriptor?: FilterParameter,
    isThirdParty?: FilterParameter,
    utilized?: FilterParameter,
    beneficiaries?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ClientAccountSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientsAccounts(
        name,
        descriptor,
        balanceCurrency,
        balanceValue,
        creationDate,
        createdBy,
        lastActivity,
        terminationDate,
        status,
        iban,
        useDynamicDescriptor,
        isThirdParty,
        utilized,
        beneficiaries,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ClientAccountSummary>>((api) =>
          api.getAllClientsAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients accounts
   * @memberof AdminApi
   */
  public getAllClientsAccountsMutation(): ApiMutationHandler<
    AdminApiGetAllClientsAccountsArgs,
    Array<ClientAccountSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientsAccounts',
      mutationFn: (
        name?: FilterParameter,
        descriptor?: FilterParameter,
        balanceCurrency?: FilterParameter,
        balanceValue?: FilterParameter,
        creationDate?: FilterParameter,
        createdBy?: FilterParameter,
        lastActivity?: FilterParameter,
        terminationDate?: FilterParameter,
        status?: FilterParameter,
        iban?: FilterParameter,
        useDynamicDescriptor?: FilterParameter,
        isThirdParty?: FilterParameter,
        utilized?: FilterParameter,
        beneficiaries?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ClientAccountSummary>>((api) =>
          api.getAllClientsAccounts(
            name,
            descriptor,
            balanceCurrency,
            balanceValue,
            creationDate,
            createdBy,
            lastActivity,
            terminationDate,
            status,
            iban,
            useDynamicDescriptor,
            isThirdParty,
            utilized,
            beneficiaries,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List activity for all clients
   * @memberof AdminApi
   */
  public getAllClientsActivity(
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Activity>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientsActivity(timestamp),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAllClientsActivity(timestamp, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List activity for all clients
   * @memberof AdminApi
   */
  public getAllClientsActivityMutation(): ApiMutationHandler<
    AdminApiGetAllClientsActivityArgs,
    Array<Activity>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientsActivity',
      mutationFn: (
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getAllClientsActivity(timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary List clients contact summaries
   * @memberof AdminApi
   */
  public getAllClientsContactSummaries(
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ContactShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientsContactSummaries(
        searchtext,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ContactShortSummary>>((api) =>
          api.getAllClientsContactSummaries(
            searchtext,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients contact summaries
   * @memberof AdminApi
   */
  public getAllClientsContactSummariesMutation(): ApiMutationHandler<
    AdminApiGetAllClientsContactSummariesArgs,
    Array<ContactShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientsContactSummaries',
      mutationFn: (
        searchtext?: string,
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ContactShortSummary>>((api) =>
          api.getAllClientsContactSummaries(
            searchtext,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List clients contacts
   * @memberof AdminApi
   */
  public getAllClientsContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ContactSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientsContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ContactSummary>>((api) =>
          api.getAllClientsContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients contacts
   * @memberof AdminApi
   */
  public getAllClientsContactsMutation(): ApiMutationHandler<
    AdminApiGetAllClientsContactsArgs,
    Array<ContactSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientsContacts',
      mutationFn: (
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ContactSummary>>((api) =>
          api.getAllClientsContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List clients third parties
   * @memberof AdminApi
   */
  public getAllClientsThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ThirdPartyAdminSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientsThirdParties(
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ThirdPartyAdminSummary>>((api) =>
          api.getAllClientsThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients third parties
   * @memberof AdminApi
   */
  public getAllClientsThirdPartiesMutation(): ApiMutationHandler<
    AdminApiGetAllClientsThirdPartiesArgs,
    Array<ThirdPartyAdminSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientsThirdParties',
      mutationFn: (
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ThirdPartyAdminSummary>>((api) =>
          api.getAllClientsThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List clients third parties summaries
   * @memberof AdminApi
   */
  public getAllClientsThirdPartySummaries(
    searchtext?: string,
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ThirdPartyShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getAllClientsThirdPartySummaries(
        searchtext,
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ThirdPartyShortSummary>>((api) =>
          api.getAllClientsThirdPartySummaries(
            searchtext,
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients third parties summaries
   * @memberof AdminApi
   */
  public getAllClientsThirdPartySummariesMutation(): ApiMutationHandler<
    AdminApiGetAllClientsThirdPartySummariesArgs,
    Array<ThirdPartyShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetAllClientsThirdPartySummaries',
      mutationFn: (
        searchtext?: string,
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ThirdPartyShortSummary>>((api) =>
          api.getAllClientsThirdPartySummaries(
            searchtext,
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List payment auditing entries originating from all accounts
   * @memberof AdminApi
   */
  public getAllPaymentAuditingEntries(
    timestamp?: FilterParameter,
    fromAccountIban?: FilterParameter,
    toAccountIban?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<PaymentAuditingEntry>> {
    return {
      queryKey: AdminApiQueryKey.getAllPaymentAuditingEntries(
        timestamp,
        fromAccountIban,
        toAccountIban
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<PaymentAuditingEntry>>((api) =>
          api.getAllPaymentAuditingEntries(
            timestamp,
            fromAccountIban,
            toAccountIban,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List payment auditing entries originating from all accounts
   * @memberof AdminApi
   */
  public getAllPaymentAuditingEntriesMutation(): ApiMutationHandler<
    AdminApiGetAllPaymentAuditingEntriesArgs,
    Array<PaymentAuditingEntry>
  > {
    return {
      mutationKey: 'AdminApiGetAllPaymentAuditingEntries',
      mutationFn: (
        timestamp?: FilterParameter,
        fromAccountIban?: FilterParameter,
        toAccountIban?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<PaymentAuditingEntry>>((api) =>
          api.getAllPaymentAuditingEntries(
            timestamp,
            fromAccountIban,
            toAccountIban,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get all compliance check lists
   * @memberof AdminApi
   */
  public getChecklists(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Checklist>> {
    return {
      queryKey: AdminApiQueryKey.getChecklists(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Checklist>>((api) =>
          api.getChecklists(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get all compliance check lists
   * @memberof AdminApi
   */
  public getChecklistsMutation(): ApiMutationHandler<
    AdminApiGetChecklistsArgs,
    Array<Checklist>
  > {
    return {
      mutationKey: 'AdminApiGetChecklists',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<Checklist>>((api) => api.getChecklists(options)),
    };
  }

  /**
   *
   * @summary Get client
   * @memberof AdminApi
   */
  public getClient(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.getClient(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.getClient(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client
   * @memberof AdminApi
   */
  public getClientMutation(): ApiMutationHandler<
    AdminApiGetClientArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiGetClient',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.getClient(id, options)),
    };
  }

  /**
   *
   * @summary Get client account
   * @memberof AdminApi
   */
  public getClientAccount(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountAdminBody> {
    return {
      queryKey: AdminApiQueryKey.getClientAccount(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.getClientAccount(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client account
   * @memberof AdminApi
   */
  public getClientAccountMutation(): ApiMutationHandler<
    AdminApiGetClientAccountArgs,
    AccountAdminBody
  > {
    return {
      mutationKey: 'AdminApiGetClientAccount',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.getClientAccount(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary List activities for a client account
   * @memberof AdminApi
   */
  public getClientAccountActivity(
    orgId: string,
    id: string,
    timestamp?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Activity>> {
    return {
      queryKey: AdminApiQueryKey.getClientAccountActivity(orgId, id, timestamp),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getClientAccountActivity(
            orgId,
            id,
            timestamp,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List activities for a client account
   * @memberof AdminApi
   */
  public getClientAccountActivityMutation(): ApiMutationHandler<
    AdminApiGetClientAccountActivityArgs,
    Array<Activity>
  > {
    return {
      mutationKey: 'AdminApiGetClientAccountActivity',
      mutationFn: (
        orgId: string,
        id: string,
        timestamp?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Activity>>((api) =>
          api.getClientAccountActivity(orgId, id, timestamp, options)
        ),
    };
  }

  /**
   *
   * @summary Get client account summary using Iban
   * @memberof AdminApi
   */
  public getClientAccountSummaryByIban(
    orgId: string,
    iban: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountShortSummary> {
    return {
      queryKey: AdminApiQueryKey.getClientAccountSummaryByIban(orgId, iban),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getClientAccountSummaryByIban(
            orgId,
            iban,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get client account summary using Iban
   * @memberof AdminApi
   */
  public getClientAccountSummaryByIbanMutation(): ApiMutationHandler<
    AdminApiGetClientAccountSummaryByIbanArgs,
    AccountShortSummary
  > {
    return {
      mutationKey: 'AdminApiGetClientAccountSummaryByIban',
      mutationFn: (
        orgId: string,
        iban: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getClientAccountSummaryByIban(orgId, iban, options)
        ),
    };
  }

  /**
   *
   * @summary Get client account summary using Id
   * @memberof AdminApi
   */
  public getClientAccountSummaryById(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountShortSummary> {
    return {
      queryKey: AdminApiQueryKey.getClientAccountSummaryById(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getClientAccountSummaryById(
            orgId,
            id,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get client account summary using Id
   * @memberof AdminApi
   */
  public getClientAccountSummaryByIdMutation(): ApiMutationHandler<
    AdminApiGetClientAccountSummaryByIdArgs,
    AccountShortSummary
  > {
    return {
      mutationKey: 'AdminApiGetClientAccountSummaryById',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountShortSummary>((api) =>
          api.getClientAccountSummaryById(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client attachment
   * @memberof AdminApi
   */
  public getClientAttachment(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Attachment> {
    return {
      queryKey: AdminApiQueryKey.getClientAttachment(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Attachment>((api) =>
          api.getClientAttachment(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client attachment
   * @memberof AdminApi
   */
  public getClientAttachmentMutation(): ApiMutationHandler<
    AdminApiGetClientAttachmentArgs,
    Attachment
  > {
    return {
      mutationKey: 'AdminApiGetClientAttachment',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Attachment>((api) =>
          api.getClientAttachment(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client contact
   * @memberof AdminApi
   */
  public getClientContact(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: AdminApiQueryKey.getClientContact(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.getClientContact(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client contact
   * @memberof AdminApi
   */
  public getClientContactMutation(): ApiMutationHandler<
    AdminApiGetClientContactArgs,
    Contact
  > {
    return {
      mutationKey: 'AdminApiGetClientContact',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.getClientContact(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client contact summary using Id
   * @memberof AdminApi
   */
  public getClientContactSummaryById(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ContactShortSummary> {
    return {
      queryKey: AdminApiQueryKey.getClientContactSummaryById(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ContactShortSummary>((api) =>
          api.getClientContactSummaryById(
            orgId,
            id,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get client contact summary using Id
   * @memberof AdminApi
   */
  public getClientContactSummaryByIdMutation(): ApiMutationHandler<
    AdminApiGetClientContactSummaryByIdArgs,
    ContactShortSummary
  > {
    return {
      mutationKey: 'AdminApiGetClientContactSummaryById',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ContactShortSummary>((api) =>
          api.getClientContactSummaryById(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client\'s current review
   * @memberof AdminApi
   */
  public getClientCurrentReview(
    orgId: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientReview> {
    return {
      queryKey: AdminApiQueryKey.getClientCurrentReview(orgId),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientReview>((api) =>
          api.getClientCurrentReview(orgId, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client\'s current review
   * @memberof AdminApi
   */
  public getClientCurrentReviewMutation(): ApiMutationHandler<
    AdminApiGetClientCurrentReviewArgs,
    ClientReview
  > {
    return {
      mutationKey: 'AdminApiGetClientCurrentReview',
      mutationFn: (orgId: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ClientReview>((api) =>
          api.getClientCurrentReview(orgId, options)
        ),
    };
  }

  /**
   *
   * @summary Get client notification
   * @memberof AdminApi
   */
  public getClientNotification(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<NotificationResponse> {
    return {
      queryKey: AdminApiQueryKey.getClientNotification(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.getClientNotification(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client notification
   * @memberof AdminApi
   */
  public getClientNotificationMutation(): ApiMutationHandler<
    AdminApiGetClientNotificationArgs,
    NotificationResponse
  > {
    return {
      mutationKey: 'AdminApiGetClientNotification',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.getClientNotification(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client payment
   * @memberof AdminApi
   */
  public getClientPayment(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Payment> {
    return {
      queryKey: AdminApiQueryKey.getClientPayment(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Payment>((api) =>
          api.getClientPayment(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client payment
   * @memberof AdminApi
   */
  public getClientPaymentMutation(): ApiMutationHandler<
    AdminApiGetClientPaymentArgs,
    Payment
  > {
    return {
      mutationKey: 'AdminApiGetClientPayment',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Payment>((api) =>
          api.getClientPayment(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client payments
   * @memberof AdminApi
   */
  public getClientPayments(
    orgId: string,
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<PaymentSummary>> {
    return {
      queryKey: AdminApiQueryKey.getClientPayments(
        orgId,
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<PaymentSummary>>((api) =>
          api.getClientPayments(
            orgId,
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get client payments
   * @memberof AdminApi
   */
  public getClientPaymentsMutation(): ApiMutationHandler<
    AdminApiGetClientPaymentsArgs,
    Array<PaymentSummary>
  > {
    return {
      mutationKey: 'AdminApiGetClientPayments',
      mutationFn: (
        orgId: string,
        amountCurrency?: FilterParameter,
        amountValue?: FilterParameter,
        _class?: FilterParameter,
        fromIban?: FilterParameter,
        fromName?: FilterParameter,
        toIban?: FilterParameter,
        toName?: FilterParameter,
        status?: FilterParameter,
        timeStamp?: FilterParameter,
        type?: FilterParameter,
        id?: FilterParameter,
        clearingSystem?: FilterParameter,
        clearingSystemTxId?: FilterParameter,
        instructionId?: FilterParameter,
        description?: FilterParameter,
        senderReference?: FilterParameter,
        batchReference?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<PaymentSummary>>((api) =>
          api.getClientPayments(
            orgId,
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get client profile
   * @memberof AdminApi
   */
  public getClientProfile(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientProfile> {
    return {
      queryKey: AdminApiQueryKey.getClientProfile(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientProfile>((api) =>
          api.getClientProfile(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client profile
   * @memberof AdminApi
   */
  public getClientProfileMutation(): ApiMutationHandler<
    AdminApiGetClientProfileArgs,
    ClientProfile
  > {
    return {
      mutationKey: 'AdminApiGetClientProfile',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ClientProfile>((api) =>
          api.getClientProfile(id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client profile summary using Id
   * @memberof AdminApi
   */
  public getClientProfileSummaryById(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientProfileShortSummary> {
    return {
      queryKey: AdminApiQueryKey.getClientProfileSummaryById(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientProfileShortSummary>((api) =>
          api.getClientProfileSummaryById(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client profile summary using Id
   * @memberof AdminApi
   */
  public getClientProfileSummaryByIdMutation(): ApiMutationHandler<
    AdminApiGetClientProfileSummaryByIdArgs,
    ClientProfileShortSummary
  > {
    return {
      mutationKey: 'AdminApiGetClientProfileSummaryById',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ClientProfileShortSummary>((api) =>
          api.getClientProfileSummaryById(id, options)
        ),
    };
  }

  /**
   *
   * @summary List client profiles
   * @memberof AdminApi
   */
  public getClientProfiles(
    supplier?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ClientProfileSummary>> {
    return {
      queryKey: AdminApiQueryKey.getClientProfiles(supplier, sort),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ClientProfileSummary>>((api) =>
          api.getClientProfiles(supplier, sort, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List client profiles
   * @memberof AdminApi
   */
  public getClientProfilesMutation(): ApiMutationHandler<
    AdminApiGetClientProfilesArgs,
    Array<ClientProfileSummary>
  > {
    return {
      mutationKey: 'AdminApiGetClientProfiles',
      mutationFn: (
        supplier?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ClientProfileSummary>>((api) =>
          api.getClientProfiles(supplier, sort, options)
        ),
    };
  }

  /**
   *
   * @summary List client profile summaries
   * @memberof AdminApi
   */
  public getClientProfilesSummaries(
    searchtext?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ClientProfileShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getClientProfilesSummaries(searchtext),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ClientProfileShortSummary>>((api) =>
          api.getClientProfilesSummaries(
            searchtext,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List client profile summaries
   * @memberof AdminApi
   */
  public getClientProfilesSummariesMutation(): ApiMutationHandler<
    AdminApiGetClientProfilesSummariesArgs,
    Array<ClientProfileShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetClientProfilesSummaries',
      mutationFn: (searchtext?: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<ClientProfileShortSummary>>((api) =>
          api.getClientProfilesSummaries(searchtext, options)
        ),
    };
  }

  /**
   *
   * @summary Get a client organization settings, including fee accounts
   * @memberof AdminApi
   */
  public getClientSettings(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Settings> {
    return {
      queryKey: AdminApiQueryKey.getClientSettings(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Settings>((api) =>
          api.getClientSettings(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get a client organization settings, including fee accounts
   * @memberof AdminApi
   */
  public getClientSettingsMutation(): ApiMutationHandler<
    AdminApiGetClientSettingsArgs,
    Settings
  > {
    return {
      mutationKey: 'AdminApiGetClientSettings',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Settings>((api) => api.getClientSettings(id, options)),
    };
  }

  /**
   *
   * @summary Get client summary
   * @memberof AdminApi
   */
  public getClientSummaryById(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientShortSummary> {
    return {
      queryKey: AdminApiQueryKey.getClientSummaryById(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientShortSummary>((api) =>
          api.getClientSummaryById(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client summary
   * @memberof AdminApi
   */
  public getClientSummaryByIdMutation(): ApiMutationHandler<
    AdminApiGetClientSummaryByIdArgs,
    ClientShortSummary
  > {
    return {
      mutationKey: 'AdminApiGetClientSummaryById',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ClientShortSummary>((api) =>
          api.getClientSummaryById(id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client third party
   * @memberof AdminApi
   */
  public getClientThirdParty(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdPartyAdminResponseBody> {
    return {
      queryKey: AdminApiQueryKey.getClientThirdParty(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdPartyAdminResponseBody>((api) =>
          api.getClientThirdParty(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get client third party
   * @memberof AdminApi
   */
  public getClientThirdPartyMutation(): ApiMutationHandler<
    AdminApiGetClientThirdPartyArgs,
    ThirdPartyAdminResponseBody
  > {
    return {
      mutationKey: 'AdminApiGetClientThirdParty',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ThirdPartyAdminResponseBody>((api) =>
          api.getClientThirdParty(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Get client third party summary using Id
   * @memberof AdminApi
   */
  public getClientThirdPartySummaryById(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdPartyShortSummary> {
    return {
      queryKey: AdminApiQueryKey.getClientThirdPartySummaryById(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdPartyShortSummary>((api) =>
          api.getClientThirdPartySummaryById(
            orgId,
            id,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get client third party summary using Id
   * @memberof AdminApi
   */
  public getClientThirdPartySummaryByIdMutation(): ApiMutationHandler<
    AdminApiGetClientThirdPartySummaryByIdArgs,
    ThirdPartyShortSummary
  > {
    return {
      mutationKey: 'AdminApiGetClientThirdPartySummaryById',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ThirdPartyShortSummary>((api) =>
          api.getClientThirdPartySummaryById(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary List clients
   * @memberof AdminApi
   */
  public getClients(
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ClientSummary>> {
    return {
      queryKey: AdminApiQueryKey.getClients(
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ClientSummary>>((api) =>
          api.getClients(
            status,
            complianceStatus,
            supplier,
            legalName,
            informalName,
            incorporationLoc,
            incorporationNumber,
            incorporationDate,
            sIC,
            lastKycReview,
            clientProfileComplianceLevel,
            startDate,
            webSite,
            clientProfile,
            referrer,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients
   * @memberof AdminApi
   */
  public getClientsMutation(): ApiMutationHandler<
    AdminApiGetClientsArgs,
    Array<ClientSummary>
  > {
    return {
      mutationKey: 'AdminApiGetClients',
      mutationFn: (
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        supplier?: FilterParameter,
        legalName?: FilterParameter,
        informalName?: FilterParameter,
        incorporationLoc?: FilterParameter,
        incorporationNumber?: FilterParameter,
        incorporationDate?: FilterParameter,
        sIC?: FilterParameter,
        lastKycReview?: FilterParameter,
        clientProfileComplianceLevel?: FilterParameter,
        startDate?: FilterParameter,
        webSite?: FilterParameter,
        clientProfile?: FilterParameter,
        referrer?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ClientSummary>>((api) =>
          api.getClients(
            status,
            complianceStatus,
            supplier,
            legalName,
            informalName,
            incorporationLoc,
            incorporationNumber,
            incorporationDate,
            sIC,
            lastKycReview,
            clientProfileComplianceLevel,
            startDate,
            webSite,
            clientProfile,
            referrer,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get all clients payments
   * @memberof AdminApi
   */
  public getClientsPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<PaymentSummary>> {
    return {
      queryKey: AdminApiQueryKey.getClientsPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<PaymentSummary>>((api) =>
          api.getClientsPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get all clients payments
   * @memberof AdminApi
   */
  public getClientsPaymentsMutation(): ApiMutationHandler<
    AdminApiGetClientsPaymentsArgs,
    Array<PaymentSummary>
  > {
    return {
      mutationKey: 'AdminApiGetClientsPayments',
      mutationFn: (
        amountCurrency?: FilterParameter,
        amountValue?: FilterParameter,
        _class?: FilterParameter,
        fromIban?: FilterParameter,
        fromName?: FilterParameter,
        toIban?: FilterParameter,
        toName?: FilterParameter,
        status?: FilterParameter,
        timeStamp?: FilterParameter,
        type?: FilterParameter,
        id?: FilterParameter,
        clearingSystem?: FilterParameter,
        clearingSystemTxId?: FilterParameter,
        instructionId?: FilterParameter,
        description?: FilterParameter,
        senderReference?: FilterParameter,
        batchReference?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<PaymentSummary>>((api) =>
          api.getClientsPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List clients summaries
   * @memberof AdminApi
   */
  public getClientsSummaries(
    searchtext?: string,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    supplier?: FilterParameter,
    legalName?: FilterParameter,
    informalName?: FilterParameter,
    incorporationLoc?: FilterParameter,
    incorporationNumber?: FilterParameter,
    incorporationDate?: FilterParameter,
    sIC?: FilterParameter,
    lastKycReview?: FilterParameter,
    clientProfileComplianceLevel?: FilterParameter,
    startDate?: FilterParameter,
    webSite?: FilterParameter,
    clientProfile?: FilterParameter,
    referrer?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ClientShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getClientsSummaries(
        searchtext,
        status,
        complianceStatus,
        supplier,
        legalName,
        informalName,
        incorporationLoc,
        incorporationNumber,
        incorporationDate,
        sIC,
        lastKycReview,
        clientProfileComplianceLevel,
        startDate,
        webSite,
        clientProfile,
        referrer
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ClientShortSummary>>((api) =>
          api.getClientsSummaries(
            searchtext,
            status,
            complianceStatus,
            supplier,
            legalName,
            informalName,
            incorporationLoc,
            incorporationNumber,
            incorporationDate,
            sIC,
            lastKycReview,
            clientProfileComplianceLevel,
            startDate,
            webSite,
            clientProfile,
            referrer,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List clients summaries
   * @memberof AdminApi
   */
  public getClientsSummariesMutation(): ApiMutationHandler<
    AdminApiGetClientsSummariesArgs,
    Array<ClientShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetClientsSummaries',
      mutationFn: (
        searchtext?: string,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        supplier?: FilterParameter,
        legalName?: FilterParameter,
        informalName?: FilterParameter,
        incorporationLoc?: FilterParameter,
        incorporationNumber?: FilterParameter,
        incorporationDate?: FilterParameter,
        sIC?: FilterParameter,
        lastKycReview?: FilterParameter,
        clientProfileComplianceLevel?: FilterParameter,
        startDate?: FilterParameter,
        webSite?: FilterParameter,
        clientProfile?: FilterParameter,
        referrer?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ClientShortSummary>>((api) =>
          api.getClientsSummaries(
            searchtext,
            status,
            complianceStatus,
            supplier,
            legalName,
            informalName,
            incorporationLoc,
            incorporationNumber,
            incorporationDate,
            sIC,
            lastKycReview,
            clientProfileComplianceLevel,
            startDate,
            webSite,
            clientProfile,
            referrer,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get all products
   * @memberof AdminApi
   */
  public getProducts(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Product>> {
    return {
      queryKey: AdminApiQueryKey.getProducts(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Product>>((api) =>
          api.getProducts(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get all products
   * @memberof AdminApi
   */
  public getProductsMutation(): ApiMutationHandler<
    AdminApiGetProductsArgs,
    Array<Product>
  > {
    return {
      mutationKey: 'AdminApiGetProducts',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<Product>>((api) => api.getProducts(options)),
    };
  }

  /**
   *
   * @summary Get risk survey
   * @memberof AdminApi
   */
  public getRiskSurvey(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<RiskSurvey> {
    return {
      queryKey: AdminApiQueryKey.getRiskSurvey(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<RiskSurvey>((api) =>
          api.getRiskSurvey(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get risk survey
   * @memberof AdminApi
   */
  public getRiskSurveyMutation(): ApiMutationHandler<
    AdminApiGetRiskSurveyArgs,
    RiskSurvey
  > {
    return {
      mutationKey: 'AdminApiGetRiskSurvey',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<RiskSurvey>((api) => api.getRiskSurvey(id, options)),
    };
  }

  /**
   *
   * @summary List risk survey summaries
   * @memberof AdminApi
   */
  public getRiskSurveySummaries(
    searchtext?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<RiskSurveyShortSummary>> {
    return {
      queryKey: AdminApiQueryKey.getRiskSurveySummaries(searchtext),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<RiskSurveyShortSummary>>((api) =>
          api.getRiskSurveySummaries(searchtext, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List risk survey summaries
   * @memberof AdminApi
   */
  public getRiskSurveySummariesMutation(): ApiMutationHandler<
    AdminApiGetRiskSurveySummariesArgs,
    Array<RiskSurveyShortSummary>
  > {
    return {
      mutationKey: 'AdminApiGetRiskSurveySummaries',
      mutationFn: (searchtext?: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<RiskSurveyShortSummary>>((api) =>
          api.getRiskSurveySummaries(searchtext, options)
        ),
    };
  }

  /**
   *
   * @summary Get risk survey summary using Id
   * @memberof AdminApi
   */
  public getRiskSurveySummaryById(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<RiskSurveyShortSummary> {
    return {
      queryKey: AdminApiQueryKey.getRiskSurveySummaryById(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<RiskSurveyShortSummary>((api) =>
          api.getRiskSurveySummaryById(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get risk survey summary using Id
   * @memberof AdminApi
   */
  public getRiskSurveySummaryByIdMutation(): ApiMutationHandler<
    AdminApiGetRiskSurveySummaryByIdArgs,
    RiskSurveyShortSummary
  > {
    return {
      mutationKey: 'AdminApiGetRiskSurveySummaryById',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<RiskSurveyShortSummary>((api) =>
          api.getRiskSurveySummaryById(id, options)
        ),
    };
  }

  /**
   *
   * @summary Get all risk surveys
   * @memberof AdminApi
   */
  public getRiskSurveys(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<RiskSurveySummary>> {
    return {
      queryKey: AdminApiQueryKey.getRiskSurveys(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<RiskSurveySummary>>((api) =>
          api.getRiskSurveys(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get all risk surveys
   * @memberof AdminApi
   */
  public getRiskSurveysMutation(): ApiMutationHandler<
    AdminApiGetRiskSurveysArgs,
    Array<RiskSurveySummary>
  > {
    return {
      mutationKey: 'AdminApiGetRiskSurveys',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<RiskSurveySummary>>((api) =>
          api.getRiskSurveys(options)
        ),
    };
  }

  /**
   *
   * @summary Lock a client\'s profile
   * @memberof AdminApi
   */
  public lockClientProfile(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.lockClientProfile(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.lockClientProfile(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Lock a client\'s profile
   * @memberof AdminApi
   */
  public lockClientProfileMutation(): ApiMutationHandler<
    AdminApiLockClientProfileArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiLockClientProfile',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.lockClientProfile(id, options)),
    };
  }

  /**
   *
   * @summary Reject pending updates to a client
   * @memberof AdminApi
   */
  public rejectClientUpdates(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.rejectClientUpdates(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.rejectClientUpdates(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Reject pending updates to a client
   * @memberof AdminApi
   */
  public rejectClientUpdatesMutation(): ApiMutationHandler<
    AdminApiRejectClientUpdatesArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiRejectClientUpdates',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.rejectClientUpdates(id, options)),
    };
  }

  /**
   *
   * @summary Releases a held client payment
   * @memberof AdminApi
   */
  public releaseHeldClientPayment(
    orgId: string,
    id: string,
    paymentRelease: PaymentRelease,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Payment> {
    return {
      queryKey: AdminApiQueryKey.releaseHeldClientPayment(
        orgId,
        id,
        paymentRelease
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Payment>((api) =>
          api.releaseHeldClientPayment(
            orgId,
            id,
            paymentRelease,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Releases a held client payment
   * @memberof AdminApi
   */
  public releaseHeldClientPaymentMutation(): ApiMutationHandler<
    AdminApiReleaseHeldClientPaymentArgs,
    Payment
  > {
    return {
      mutationKey: 'AdminApiReleaseHeldClientPayment',
      mutationFn: (
        orgId: string,
        id: string,
        paymentRelease: PaymentRelease,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Payment>((api) =>
          api.releaseHeldClientPayment(orgId, id, paymentRelease, options)
        ),
    };
  }

  /**
   *
   * @summary Reset a client contact password
   * @memberof AdminApi
   */
  public resetClientContactPassword(
    orgId: string,
    id: string,
    resetPasswordPutBody: ResetPasswordPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: AdminApiQueryKey.resetClientContactPassword(
        orgId,
        id,
        resetPasswordPutBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.resetClientContactPassword(
            orgId,
            id,
            resetPasswordPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Reset a client contact password
   * @memberof AdminApi
   */
  public resetClientContactPasswordMutation(): ApiMutationHandler<
    AdminApiResetClientContactPasswordArgs,
    Contact
  > {
    return {
      mutationKey: 'AdminApiResetClientContactPassword',
      mutationFn: (
        orgId: string,
        id: string,
        resetPasswordPutBody: ResetPasswordPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.resetClientContactPassword(
            orgId,
            id,
            resetPasswordPutBody,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Review/make updates to a client
   * @memberof AdminApi
   */
  public reviewClientUpdates(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.reviewClientUpdates(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.reviewClientUpdates(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Review/make updates to a client
   * @memberof AdminApi
   */
  public reviewClientUpdatesMutation(): ApiMutationHandler<
    AdminApiReviewClientUpdatesArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiReviewClientUpdates',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.reviewClientUpdates(id, options)),
    };
  }

  /**
   *
   * @summary Change a client\'s operational status to Suspended
   * @memberof AdminApi
   */
  public suspendClient(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.suspendClient(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.suspendClient(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Change a client\'s operational status to Suspended
   * @memberof AdminApi
   */
  public suspendClientMutation(): ApiMutationHandler<
    AdminApiSuspendClientArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiSuspendClient',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.suspendClient(id, options)),
    };
  }

  /**
   *
   * @summary Suspend a client account
   * @memberof AdminApi
   */
  public suspendClientAccount(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountAdminBody> {
    return {
      queryKey: AdminApiQueryKey.suspendClientAccount(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.suspendClientAccount(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Suspend a client account
   * @memberof AdminApi
   */
  public suspendClientAccountMutation(): ApiMutationHandler<
    AdminApiSuspendClientAccountArgs,
    AccountAdminBody
  > {
    return {
      mutationKey: 'AdminApiSuspendClientAccount',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.suspendClientAccount(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Suspend a client contact
   * @memberof AdminApi
   */
  public suspendClientContact(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: AdminApiQueryKey.suspendClientContact(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.suspendClientContact(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Suspend a client contact
   * @memberof AdminApi
   */
  public suspendClientContactMutation(): ApiMutationHandler<
    AdminApiSuspendClientContactArgs,
    Contact
  > {
    return {
      mutationKey: 'AdminApiSuspendClientContact',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.suspendClientContact(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
   * @memberof AdminApi
   */
  public terminateClient(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientTerminationSummary> {
    return {
      queryKey: AdminApiQueryKey.terminateClient(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientTerminationSummary>((api) =>
          api.terminateClient(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Change a client\'s operational status to Terminated; terminate client\'s account and contacts.
   * @memberof AdminApi
   */
  public terminateClientMutation(): ApiMutationHandler<
    AdminApiTerminateClientArgs,
    ClientTerminationSummary
  > {
    return {
      mutationKey: 'AdminApiTerminateClient',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ClientTerminationSummary>((api) =>
          api.terminateClient(id, options)
        ),
    };
  }

  /**
   *
   * @summary Terminate a client account
   * @memberof AdminApi
   */
  public terminateClientAccount(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountAdminBody> {
    return {
      queryKey: AdminApiQueryKey.terminateClientAccount(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.terminateClientAccount(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Terminate a client account
   * @memberof AdminApi
   */
  public terminateClientAccountMutation(): ApiMutationHandler<
    AdminApiTerminateClientAccountArgs,
    AccountAdminBody
  > {
    return {
      mutationKey: 'AdminApiTerminateClientAccount',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.terminateClientAccount(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Terminate a client contact
   * @memberof AdminApi
   */
  public terminateClientContact(
    orgId: string,
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: AdminApiQueryKey.terminateClientContact(orgId, id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.terminateClientContact(orgId, id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Terminate a client contact
   * @memberof AdminApi
   */
  public terminateClientContactMutation(): ApiMutationHandler<
    AdminApiTerminateClientContactArgs,
    Contact
  > {
    return {
      mutationKey: 'AdminApiTerminateClientContact',
      mutationFn: (
        orgId: string,
        id: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.terminateClientContact(orgId, id, options)
        ),
    };
  }

  /**
   *
   * @summary Unlock a client\'s profile
   * @memberof AdminApi
   */
  public unlockClientProfile(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.unlockClientProfile(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.unlockClientProfile(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Unlock a client\'s profile
   * @memberof AdminApi
   */
  public unlockClientProfileMutation(): ApiMutationHandler<
    AdminApiUnlockClientProfileArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiUnlockClientProfile',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Client>((api) => api.unlockClientProfile(id, options)),
    };
  }

  /**
   *
   * @summary Update account block
   * @memberof AdminApi
   */
  public updateAccountBlock(
    id: string,
    accountBlockPutBody?: AccountBlockPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountBlock> {
    return {
      queryKey: AdminApiQueryKey.updateAccountBlock(id, accountBlockPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.updateAccountBlock(
            id,
            accountBlockPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update account block
   * @memberof AdminApi
   */
  public updateAccountBlockMutation(): ApiMutationHandler<
    AdminApiUpdateAccountBlockArgs,
    AccountBlock
  > {
    return {
      mutationKey: 'AdminApiUpdateAccountBlock',
      mutationFn: (
        id: string,
        accountBlockPutBody?: AccountBlockPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountBlock>((api) =>
          api.updateAccountBlock(id, accountBlockPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Update a client
   * @memberof AdminApi
   */
  public updateClient(
    id: string,
    client?: Client,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Client> {
    return {
      queryKey: AdminApiQueryKey.updateClient(id, client),
      queryFn: (queryOptions) =>
        this.runQueryFn<Client>((api) =>
          api.updateClient(id, client, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Update a client
   * @memberof AdminApi
   */
  public updateClientMutation(): ApiMutationHandler<
    AdminApiUpdateClientArgs,
    Client
  > {
    return {
      mutationKey: 'AdminApiUpdateClient',
      mutationFn: (
        id: string,
        client?: Client,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Client>((api) => api.updateClient(id, client, options)),
    };
  }

  /**
   *
   * @summary Update a client account
   * @memberof AdminApi
   */
  public updateClientAccount(
    orgId: string,
    id: string,
    accountAdminPutBody?: AccountAdminPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<AccountAdminBody> {
    return {
      queryKey: AdminApiQueryKey.updateClientAccount(
        orgId,
        id,
        accountAdminPutBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.updateClientAccount(
            orgId,
            id,
            accountAdminPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update a client account
   * @memberof AdminApi
   */
  public updateClientAccountMutation(): ApiMutationHandler<
    AdminApiUpdateClientAccountArgs,
    AccountAdminBody
  > {
    return {
      mutationKey: 'AdminApiUpdateClientAccount',
      mutationFn: (
        orgId: string,
        id: string,
        accountAdminPutBody?: AccountAdminPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<AccountAdminBody>((api) =>
          api.updateClientAccount(orgId, id, accountAdminPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Update a client contact
   * @memberof AdminApi
   */
  public updateClientContact(
    orgId: string,
    id: string,
    contactPutBody?: ContactPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: AdminApiQueryKey.updateClientContact(orgId, id, contactPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.updateClientContact(
            orgId,
            id,
            contactPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update a client contact
   * @memberof AdminApi
   */
  public updateClientContactMutation(): ApiMutationHandler<
    AdminApiUpdateClientContactArgs,
    Contact
  > {
    return {
      mutationKey: 'AdminApiUpdateClientContact',
      mutationFn: (
        orgId: string,
        id: string,
        contactPutBody?: ContactPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.updateClientContact(orgId, id, contactPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Update a client notification
   * @memberof AdminApi
   */
  public updateClientNotification(
    orgId: string,
    id: string,
    notificationPostPutRequest?: NotificationPostPutRequest,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<NotificationResponse> {
    return {
      queryKey: AdminApiQueryKey.updateClientNotification(
        orgId,
        id,
        notificationPostPutRequest
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.updateClientNotification(
            orgId,
            id,
            notificationPostPutRequest,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update a client notification
   * @memberof AdminApi
   */
  public updateClientNotificationMutation(): ApiMutationHandler<
    AdminApiUpdateClientNotificationArgs,
    NotificationResponse
  > {
    return {
      mutationKey: 'AdminApiUpdateClientNotification',
      mutationFn: (
        orgId: string,
        id: string,
        notificationPostPutRequest?: NotificationPostPutRequest,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.updateClientNotification(
            orgId,
            id,
            notificationPostPutRequest,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Update client profile
   * @memberof AdminApi
   */
  public updateClientProfile(
    id: string,
    clientProfilePostPutBody?: ClientProfilePostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientProfile> {
    return {
      queryKey: AdminApiQueryKey.updateClientProfile(
        id,
        clientProfilePostPutBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientProfile>((api) =>
          api.updateClientProfile(
            id,
            clientProfilePostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update client profile
   * @memberof AdminApi
   */
  public updateClientProfileMutation(): ApiMutationHandler<
    AdminApiUpdateClientProfileArgs,
    ClientProfile
  > {
    return {
      mutationKey: 'AdminApiUpdateClientProfile',
      mutationFn: (
        id: string,
        clientProfilePostPutBody?: ClientProfilePostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ClientProfile>((api) =>
          api.updateClientProfile(id, clientProfilePostPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Update a client organization settings, including fee accounts
   * @memberof AdminApi
   */
  public updateClientSettings(
    id: string,
    settings?: Settings,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Settings> {
    return {
      queryKey: AdminApiQueryKey.updateClientSettings(id, settings),
      queryFn: (queryOptions) =>
        this.runQueryFn<Settings>((api) =>
          api.updateClientSettings(id, settings, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Update a client organization settings, including fee accounts
   * @memberof AdminApi
   */
  public updateClientSettingsMutation(): ApiMutationHandler<
    AdminApiUpdateClientSettingsArgs,
    Settings
  > {
    return {
      mutationKey: 'AdminApiUpdateClientSettings',
      mutationFn: (
        id: string,
        settings?: Settings,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Settings>((api) =>
          api.updateClientSettings(id, settings, options)
        ),
    };
  }

  /**
   *
   * @summary Update a client third party
   * @memberof AdminApi
   */
  public updateClientThirdParty(
    orgId: string,
    id: string,
    thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdParty> {
    return {
      queryKey: AdminApiQueryKey.updateClientThirdParty(
        orgId,
        id,
        thirdPartyAdminPostPutBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.updateClientThirdParty(
            orgId,
            id,
            thirdPartyAdminPostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update a client third party
   * @memberof AdminApi
   */
  public updateClientThirdPartyMutation(): ApiMutationHandler<
    AdminApiUpdateClientThirdPartyArgs,
    ThirdParty
  > {
    return {
      mutationKey: 'AdminApiUpdateClientThirdParty',
      mutationFn: (
        orgId: string,
        id: string,
        thirdPartyAdminPostPutBody?: ThirdPartyAdminPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.updateClientThirdParty(
            orgId,
            id,
            thirdPartyAdminPostPutBody,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Update risk survey
   * @memberof AdminApi
   */
  public updateRiskSurvey(
    id: string,
    riskSurveyPostPutBody?: RiskSurveyPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<RiskSurvey> {
    return {
      queryKey: AdminApiQueryKey.updateRiskSurvey(id, riskSurveyPostPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<RiskSurvey>((api) =>
          api.updateRiskSurvey(
            id,
            riskSurveyPostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update risk survey
   * @memberof AdminApi
   */
  public updateRiskSurveyMutation(): ApiMutationHandler<
    AdminApiUpdateRiskSurveyArgs,
    RiskSurvey
  > {
    return {
      mutationKey: 'AdminApiUpdateRiskSurvey',
      mutationFn: (
        id: string,
        riskSurveyPostPutBody?: RiskSurveyPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<RiskSurvey>((api) =>
          api.updateRiskSurvey(id, riskSurveyPostPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Upload a new attachment
   * @memberof AdminApi
   */
  public uploadClientAttachment(
    orgId: string,
    type: string,
    file: any,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Attachment> {
    return {
      queryKey: AdminApiQueryKey.uploadClientAttachment(orgId, type, file),
      queryFn: (queryOptions) =>
        this.runQueryFn<Attachment>((api) =>
          api.uploadClientAttachment(
            orgId,
            type,
            file,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Upload a new attachment
   * @memberof AdminApi
   */
  public uploadClientAttachmentMutation(): ApiMutationHandler<
    AdminApiUploadClientAttachmentArgs,
    Attachment
  > {
    return {
      mutationKey: 'AdminApiUploadClientAttachment',
      mutationFn: (
        orgId: string,
        type: string,
        file: any,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Attachment>((api) =>
          api.uploadClientAttachment(orgId, type, file, options)
        ),
    };
  }
}

/**
 * AdminApi - react-query api
 */
export function useAdminApi(options?: ApiMethodMakerOptions): AdminApiMaker {
  return useApiMethodMaker<AdminApiMaker>(AdminApiMaker, options);
}

/**
 * Arguments for the ContactsApi.ActivateContact API query
 */
export type ContactsApiActivateContactArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.CreateContact API query
 */
export type ContactsApiCreateContactArgs = [
  contactPostBody?: ContactPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.CreateUserApplication API query
 */
export type ContactsApiCreateUserApplicationArgs = [
  id: string,
  applicationPostBody?: ApplicationPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.DeleteUserApplication API query
 */
export type ContactsApiDeleteUserApplicationArgs = [
  id: string,
  appid: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.ExportContacts API query
 */
export type ContactsApiExportContactsArgs = [
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.GetContact API query
 */
export type ContactsApiGetContactArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.GetContactById API query
 */
export type ContactsApiGetContactByIdArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.GetContactSummaries API query
 */
export type ContactsApiGetContactSummariesArgs = [
  searchtext?: string,
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.GetContacts API query
 */
export type ContactsApiGetContactsArgs = [
  loginEmail?: FilterParameter,
  familyName?: FilterParameter,
  firstName?: FilterParameter,
  dob?: FilterParameter,
  nationality?: FilterParameter,
  ownershipPercentage?: FilterParameter,
  authorities?: FilterParameter,
  roles?: FilterParameter,
  pEP?: FilterParameter,
  status?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.GetUserApplication API query
 */
export type ContactsApiGetUserApplicationArgs = [
  id: string,
  appid: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.GetUserApplications API query
 */
export type ContactsApiGetUserApplicationsArgs = [
  id: string,
  status?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.GetUserSettings API query
 */
export type ContactsApiGetUserSettingsArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.ResetPassword API query
 */
export type ContactsApiResetPasswordArgs = [
  id: string,
  body: PasswordChangeBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.SuspendContact API query
 */
export type ContactsApiSuspendContactArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.TerminateContact API query
 */
export type ContactsApiTerminateContactArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.UpdateContact API query
 */
export type ContactsApiUpdateContactArgs = [
  id: string,
  contactPutBody?: ContactPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ContactsApi.UpdateUserSettings API query
 */
export type ContactsApiUpdateUserSettingsArgs = [
  id: string,
  userPutBody?: UserPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * ContactsApi - react-query query key creator
 */
export const ContactsApiQueryKey = {
  /**
   *
   * @summary Activate a contact
   */
  activateContactRoot: 'ContactsApiActivateContact' as QueryKey,

  /**
   *
   * @summary Activate a contact
   */
  activateContact(id: string): QueryKey {
    return ['ContactsApiActivateContact', id];
  },
  /**
   *
   * @summary Add a contact
   */
  createContactRoot: 'ContactsApiCreateContact' as QueryKey,

  /**
   *
   * @summary Add a contact
   */
  createContact(contactPostBody?: ContactPostBody): QueryKey {
    return ['ContactsApiCreateContact', contactPostBody];
  },
  /**
   *
   * @summary Add a contact application
   */
  createUserApplicationRoot: 'ContactsApiCreateUserApplication' as QueryKey,

  /**
   *
   * @summary Add a contact application
   */
  createUserApplication(
    id: string,
    applicationPostBody?: ApplicationPostBody
  ): QueryKey {
    return ['ContactsApiCreateUserApplication', id, applicationPostBody];
  },
  /**
   *
   * @summary Delete a contact application
   */
  deleteUserApplicationRoot: 'ContactsApiDeleteUserApplication' as QueryKey,

  /**
   *
   * @summary Delete a contact application
   */
  deleteUserApplication(id: string, appid: string): QueryKey {
    return ['ContactsApiDeleteUserApplication', id, appid];
  },
  /**
   *
   * @summary Export contacts
   */
  exportContactsRoot: 'ContactsApiExportContacts' as QueryKey,

  /**
   *
   * @summary Export contacts
   */
  exportContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'ContactsApiExportContacts',
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary Get properties of a contact
   */
  getContactRoot: 'ContactsApiGetContact' as QueryKey,

  /**
   *
   * @summary Get properties of a contact
   */
  getContact(id: string): QueryKey {
    return ['ContactsApiGetContact', id];
  },
  /**
   *
   * @summary Get contact summary using Id
   */
  getContactByIdRoot: 'ContactsApiGetContactById' as QueryKey,

  /**
   *
   * @summary Get contact summary using Id
   */
  getContactById(id: string): QueryKey {
    return ['ContactsApiGetContactById', id];
  },
  /**
   *
   * @summary Get contact summaries
   */
  getContactSummariesRoot: 'ContactsApiGetContactSummaries' as QueryKey,

  /**
   *
   * @summary Get contact summaries
   */
  getContactSummaries(
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'ContactsApiGetContactSummaries',
      searchtext,
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary List contacts
   */
  getContactsRoot: 'ContactsApiGetContacts' as QueryKey,

  /**
   *
   * @summary List contacts
   */
  getContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'ContactsApiGetContacts',
      loginEmail,
      familyName,
      firstName,
      dob,
      nationality,
      ownershipPercentage,
      authorities,
      roles,
      pEP,
      status,
      sort,
    ];
  },
  /**
   *
   * @summary Get properties of a contact application
   */
  getUserApplicationRoot: 'ContactsApiGetUserApplication' as QueryKey,

  /**
   *
   * @summary Get properties of a contact application
   */
  getUserApplication(id: string, appid: string): QueryKey {
    return ['ContactsApiGetUserApplication', id, appid];
  },
  /**
   *
   * @summary Get a contact\'s applications
   */
  getUserApplicationsRoot: 'ContactsApiGetUserApplications' as QueryKey,

  /**
   *
   * @summary Get a contact\'s applications
   */
  getUserApplications(id: string, status?: FilterParameter): QueryKey {
    return ['ContactsApiGetUserApplications', id, status];
  },
  /**
   *
   * @summary Get a contact\'s profile
   */
  getUserSettingsRoot: 'ContactsApiGetUserSettings' as QueryKey,

  /**
   *
   * @summary Get a contact\'s profile
   */
  getUserSettings(id: string): QueryKey {
    return ['ContactsApiGetUserSettings', id];
  },
  /**
   *
   * @summary Reset a contact\'s password
   */
  resetPasswordRoot: 'ContactsApiResetPassword' as QueryKey,

  /**
   *
   * @summary Reset a contact\'s password
   */
  resetPassword(id: string, body: PasswordChangeBody): QueryKey {
    return ['ContactsApiResetPassword', id, body];
  },
  /**
   *
   * @summary Suspend a contact
   */
  suspendContactRoot: 'ContactsApiSuspendContact' as QueryKey,

  /**
   *
   * @summary Suspend a contact
   */
  suspendContact(id: string): QueryKey {
    return ['ContactsApiSuspendContact', id];
  },
  /**
   *
   * @summary Terminate a contact
   */
  terminateContactRoot: 'ContactsApiTerminateContact' as QueryKey,

  /**
   *
   * @summary Terminate a contact
   */
  terminateContact(id: string): QueryKey {
    return ['ContactsApiTerminateContact', id];
  },
  /**
   *
   * @summary Update properties of a contact
   */
  updateContactRoot: 'ContactsApiUpdateContact' as QueryKey,

  /**
   *
   * @summary Update properties of a contact
   */
  updateContact(id: string, contactPutBody?: ContactPutBody): QueryKey {
    return ['ContactsApiUpdateContact', id, contactPutBody];
  },
  /**
   *
   * @summary Update a contact\'s profile
   */
  updateUserSettingsRoot: 'ContactsApiUpdateUserSettings' as QueryKey,

  /**
   *
   * @summary Update a contact\'s profile
   */
  updateUserSettings(id: string, userPutBody?: UserPutBody): QueryKey {
    return ['ContactsApiUpdateUserSettings', id, userPutBody];
  },
};

/**
 * ContactsApi - react-query method creator
 */
export class ContactsApiMaker extends BaseApiMaker<ContactsApi> {
  /**
   * Get an ContactsApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): ContactsApi {
    return new ContactsApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Activate a contact
   * @memberof ContactsApi
   */
  public activateContact(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: ContactsApiQueryKey.activateContact(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.activateContact(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Activate a contact
   * @memberof ContactsApi
   */
  public activateContactMutation(): ApiMutationHandler<
    ContactsApiActivateContactArgs,
    Contact
  > {
    return {
      mutationKey: 'ContactsApiActivateContact',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Contact>((api) => api.activateContact(id, options)),
    };
  }

  /**
   *
   * @summary Add a contact
   * @memberof ContactsApi
   */
  public createContact(
    contactPostBody?: ContactPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: ContactsApiQueryKey.createContact(contactPostBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.createContact(contactPostBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Add a contact
   * @memberof ContactsApi
   */
  public createContactMutation(): ApiMutationHandler<
    ContactsApiCreateContactArgs,
    Contact
  > {
    return {
      mutationKey: 'ContactsApiCreateContact',
      mutationFn: (
        contactPostBody?: ContactPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.createContact(contactPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Add a contact application
   * @memberof ContactsApi
   */
  public createUserApplication(
    id: string,
    applicationPostBody?: ApplicationPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ApplicationRegistrationResponseBody> {
    return {
      queryKey: ContactsApiQueryKey.createUserApplication(
        id,
        applicationPostBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ApplicationRegistrationResponseBody>((api) =>
          api.createUserApplication(
            id,
            applicationPostBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Add a contact application
   * @memberof ContactsApi
   */
  public createUserApplicationMutation(): ApiMutationHandler<
    ContactsApiCreateUserApplicationArgs,
    ApplicationRegistrationResponseBody
  > {
    return {
      mutationKey: 'ContactsApiCreateUserApplication',
      mutationFn: (
        id: string,
        applicationPostBody?: ApplicationPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ApplicationRegistrationResponseBody>((api) =>
          api.createUserApplication(id, applicationPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Delete a contact application
   * @memberof ContactsApi
   */
  public deleteUserApplication(
    id: string,
    appid: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ApplicationRegistrationResponseBody> {
    return {
      queryKey: ContactsApiQueryKey.deleteUserApplication(id, appid),
      queryFn: (queryOptions) =>
        this.runQueryFn<ApplicationRegistrationResponseBody>((api) =>
          api.deleteUserApplication(id, appid, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Delete a contact application
   * @memberof ContactsApi
   */
  public deleteUserApplicationMutation(): ApiMutationHandler<
    ContactsApiDeleteUserApplicationArgs,
    ApplicationRegistrationResponseBody
  > {
    return {
      mutationKey: 'ContactsApiDeleteUserApplication',
      mutationFn: (
        id: string,
        appid: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ApplicationRegistrationResponseBody>((api) =>
          api.deleteUserApplication(id, appid, options)
        ),
    };
  }

  /**
   *
   * @summary Export contacts
   * @memberof ContactsApi
   */
  public exportContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: ContactsApiQueryKey.exportContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export contacts
   * @memberof ContactsApi
   */
  public exportContactsMutation(): ApiMutationHandler<
    ContactsApiExportContactsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'ContactsApiExportContacts',
      mutationFn: (
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get properties of a contact
   * @memberof ContactsApi
   */
  public getContact(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: ContactsApiQueryKey.getContact(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.getContact(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of a contact
   * @memberof ContactsApi
   */
  public getContactMutation(): ApiMutationHandler<
    ContactsApiGetContactArgs,
    Contact
  > {
    return {
      mutationKey: 'ContactsApiGetContact',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Contact>((api) => api.getContact(id, options)),
    };
  }

  /**
   *
   * @summary Get contact summary using Id
   * @memberof ContactsApi
   */
  public getContactById(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ContactShortSummary> {
    return {
      queryKey: ContactsApiQueryKey.getContactById(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ContactShortSummary>((api) =>
          api.getContactById(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get contact summary using Id
   * @memberof ContactsApi
   */
  public getContactByIdMutation(): ApiMutationHandler<
    ContactsApiGetContactByIdArgs,
    ContactShortSummary
  > {
    return {
      mutationKey: 'ContactsApiGetContactById',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ContactShortSummary>((api) =>
          api.getContactById(id, options)
        ),
    };
  }

  /**
   *
   * @summary Get contact summaries
   * @memberof ContactsApi
   */
  public getContactSummaries(
    searchtext?: string,
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ContactShortSummary>> {
    return {
      queryKey: ContactsApiQueryKey.getContactSummaries(
        searchtext,
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ContactShortSummary>>((api) =>
          api.getContactSummaries(
            searchtext,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get contact summaries
   * @memberof ContactsApi
   */
  public getContactSummariesMutation(): ApiMutationHandler<
    ContactsApiGetContactSummariesArgs,
    Array<ContactShortSummary>
  > {
    return {
      mutationKey: 'ContactsApiGetContactSummaries',
      mutationFn: (
        searchtext?: string,
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ContactShortSummary>>((api) =>
          api.getContactSummaries(
            searchtext,
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List contacts
   * @memberof ContactsApi
   */
  public getContacts(
    loginEmail?: FilterParameter,
    familyName?: FilterParameter,
    firstName?: FilterParameter,
    dob?: FilterParameter,
    nationality?: FilterParameter,
    ownershipPercentage?: FilterParameter,
    authorities?: FilterParameter,
    roles?: FilterParameter,
    pEP?: FilterParameter,
    status?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Contact>> {
    return {
      queryKey: ContactsApiQueryKey.getContacts(
        loginEmail,
        familyName,
        firstName,
        dob,
        nationality,
        ownershipPercentage,
        authorities,
        roles,
        pEP,
        status,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Contact>>((api) =>
          api.getContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List contacts
   * @memberof ContactsApi
   */
  public getContactsMutation(): ApiMutationHandler<
    ContactsApiGetContactsArgs,
    Array<Contact>
  > {
    return {
      mutationKey: 'ContactsApiGetContacts',
      mutationFn: (
        loginEmail?: FilterParameter,
        familyName?: FilterParameter,
        firstName?: FilterParameter,
        dob?: FilterParameter,
        nationality?: FilterParameter,
        ownershipPercentage?: FilterParameter,
        authorities?: FilterParameter,
        roles?: FilterParameter,
        pEP?: FilterParameter,
        status?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Contact>>((api) =>
          api.getContacts(
            loginEmail,
            familyName,
            firstName,
            dob,
            nationality,
            ownershipPercentage,
            authorities,
            roles,
            pEP,
            status,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get properties of a contact application
   * @memberof ContactsApi
   */
  public getUserApplication(
    id: string,
    appid: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Application> {
    return {
      queryKey: ContactsApiQueryKey.getUserApplication(id, appid),
      queryFn: (queryOptions) =>
        this.runQueryFn<Application>((api) =>
          api.getUserApplication(id, appid, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of a contact application
   * @memberof ContactsApi
   */
  public getUserApplicationMutation(): ApiMutationHandler<
    ContactsApiGetUserApplicationArgs,
    Application
  > {
    return {
      mutationKey: 'ContactsApiGetUserApplication',
      mutationFn: (
        id: string,
        appid: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Application>((api) =>
          api.getUserApplication(id, appid, options)
        ),
    };
  }

  /**
   *
   * @summary Get a contact\'s applications
   * @memberof ContactsApi
   */
  public getUserApplications(
    id: string,
    status?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Application>> {
    return {
      queryKey: ContactsApiQueryKey.getUserApplications(id, status),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Application>>((api) =>
          api.getUserApplications(id, status, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get a contact\'s applications
   * @memberof ContactsApi
   */
  public getUserApplicationsMutation(): ApiMutationHandler<
    ContactsApiGetUserApplicationsArgs,
    Array<Application>
  > {
    return {
      mutationKey: 'ContactsApiGetUserApplications',
      mutationFn: (
        id: string,
        status?: FilterParameter,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Application>>((api) =>
          api.getUserApplications(id, status, options)
        ),
    };
  }

  /**
   *
   * @summary Get a contact\'s profile
   * @memberof ContactsApi
   */
  public getUserSettings(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<UserResponseBody> {
    return {
      queryKey: ContactsApiQueryKey.getUserSettings(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<UserResponseBody>((api) =>
          api.getUserSettings(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get a contact\'s profile
   * @memberof ContactsApi
   */
  public getUserSettingsMutation(): ApiMutationHandler<
    ContactsApiGetUserSettingsArgs,
    UserResponseBody
  > {
    return {
      mutationKey: 'ContactsApiGetUserSettings',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<UserResponseBody>((api) =>
          api.getUserSettings(id, options)
        ),
    };
  }

  /**
   *
   * @summary Reset a contact\'s password
   * @memberof ContactsApi
   */
  public resetPassword(
    id: string,
    body: PasswordChangeBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<PasswordChangeResponse> {
    return {
      queryKey: ContactsApiQueryKey.resetPassword(id, body),
      queryFn: (queryOptions) =>
        this.runQueryFn<PasswordChangeResponse>((api) =>
          api.resetPassword(id, body, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Reset a contact\'s password
   * @memberof ContactsApi
   */
  public resetPasswordMutation(): ApiMutationHandler<
    ContactsApiResetPasswordArgs,
    PasswordChangeResponse
  > {
    return {
      mutationKey: 'ContactsApiResetPassword',
      mutationFn: (
        id: string,
        body: PasswordChangeBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<PasswordChangeResponse>((api) =>
          api.resetPassword(id, body, options)
        ),
    };
  }

  /**
   *
   * @summary Suspend a contact
   * @memberof ContactsApi
   */
  public suspendContact(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: ContactsApiQueryKey.suspendContact(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.suspendContact(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Suspend a contact
   * @memberof ContactsApi
   */
  public suspendContactMutation(): ApiMutationHandler<
    ContactsApiSuspendContactArgs,
    Contact
  > {
    return {
      mutationKey: 'ContactsApiSuspendContact',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Contact>((api) => api.suspendContact(id, options)),
    };
  }

  /**
   *
   * @summary Terminate a contact
   * @memberof ContactsApi
   */
  public terminateContact(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: ContactsApiQueryKey.terminateContact(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.terminateContact(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Terminate a contact
   * @memberof ContactsApi
   */
  public terminateContactMutation(): ApiMutationHandler<
    ContactsApiTerminateContactArgs,
    Contact
  > {
    return {
      mutationKey: 'ContactsApiTerminateContact',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Contact>((api) => api.terminateContact(id, options)),
    };
  }

  /**
   *
   * @summary Update properties of a contact
   * @memberof ContactsApi
   */
  public updateContact(
    id: string,
    contactPutBody?: ContactPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Contact> {
    return {
      queryKey: ContactsApiQueryKey.updateContact(id, contactPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Contact>((api) =>
          api.updateContact(id, contactPutBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Update properties of a contact
   * @memberof ContactsApi
   */
  public updateContactMutation(): ApiMutationHandler<
    ContactsApiUpdateContactArgs,
    Contact
  > {
    return {
      mutationKey: 'ContactsApiUpdateContact',
      mutationFn: (
        id: string,
        contactPutBody?: ContactPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Contact>((api) =>
          api.updateContact(id, contactPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Update a contact\'s profile
   * @memberof ContactsApi
   */
  public updateUserSettings(
    id: string,
    userPutBody?: UserPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<UserResponseBody> {
    return {
      queryKey: ContactsApiQueryKey.updateUserSettings(id, userPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<UserResponseBody>((api) =>
          api.updateUserSettings(id, userPutBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Update a contact\'s profile
   * @memberof ContactsApi
   */
  public updateUserSettingsMutation(): ApiMutationHandler<
    ContactsApiUpdateUserSettingsArgs,
    UserResponseBody
  > {
    return {
      mutationKey: 'ContactsApiUpdateUserSettings',
      mutationFn: (
        id: string,
        userPutBody?: UserPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<UserResponseBody>((api) =>
          api.updateUserSettings(id, userPutBody, options)
        ),
    };
  }
}

/**
 * ContactsApi - react-query api
 */
export function useContactsApi(
  options?: ApiMethodMakerOptions
): ContactsApiMaker {
  return useApiMethodMaker<ContactsApiMaker>(ContactsApiMaker, options);
}

/**
 * Arguments for the DocumentsApi.GetAttachment API query
 */
export type DocumentsApiGetAttachmentArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the DocumentsApi.GetAttachments API query
 */
export type DocumentsApiGetAttachmentsArgs = [
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the DocumentsApi.UploadAttachment API query
 */
export type DocumentsApiUploadAttachmentArgs = [
  type: string,
  file: any,
  axiosOptions?: AxiosRequestConfig
];

/**
 * DocumentsApi - react-query query key creator
 */
export const DocumentsApiQueryKey = {
  /**
   *
   * @summary Get properties of an attachment
   */
  getAttachmentRoot: 'DocumentsApiGetAttachment' as QueryKey,

  /**
   *
   * @summary Get properties of an attachment
   */
  getAttachment(id: string): QueryKey {
    return ['DocumentsApiGetAttachment', id];
  },
  /**
   *
   * @summary List attachments
   */
  getAttachmentsRoot: 'DocumentsApiGetAttachments' as QueryKey,

  /**
   *
   * @summary List attachments
   */
  getAttachments(): QueryKey {
    return ['DocumentsApiGetAttachments'];
  },
  /**
   *
   * @summary Uploads a new attachment
   */
  uploadAttachmentRoot: 'DocumentsApiUploadAttachment' as QueryKey,

  /**
   *
   * @summary Uploads a new attachment
   */
  uploadAttachment(type: string, file: any): QueryKey {
    return ['DocumentsApiUploadAttachment', type, file];
  },
};

/**
 * DocumentsApi - react-query method creator
 */
export class DocumentsApiMaker extends BaseApiMaker<DocumentsApi> {
  /**
   * Get an DocumentsApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): DocumentsApi {
    return new DocumentsApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Get properties of an attachment
   * @memberof DocumentsApi
   */
  public getAttachment(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Attachment> {
    return {
      queryKey: DocumentsApiQueryKey.getAttachment(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Attachment>((api) =>
          api.getAttachment(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of an attachment
   * @memberof DocumentsApi
   */
  public getAttachmentMutation(): ApiMutationHandler<
    DocumentsApiGetAttachmentArgs,
    Attachment
  > {
    return {
      mutationKey: 'DocumentsApiGetAttachment',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Attachment>((api) => api.getAttachment(id, options)),
    };
  }

  /**
   *
   * @summary List attachments
   * @memberof DocumentsApi
   */
  public getAttachments(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Attachment>> {
    return {
      queryKey: DocumentsApiQueryKey.getAttachments(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Attachment>>((api) =>
          api.getAttachments(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List attachments
   * @memberof DocumentsApi
   */
  public getAttachmentsMutation(): ApiMutationHandler<
    DocumentsApiGetAttachmentsArgs,
    Array<Attachment>
  > {
    return {
      mutationKey: 'DocumentsApiGetAttachments',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<Attachment>>((api) =>
          api.getAttachments(options)
        ),
    };
  }

  /**
   *
   * @summary Uploads a new attachment
   * @memberof DocumentsApi
   */
  public uploadAttachment(
    type: string,
    file: any,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Attachment> {
    return {
      queryKey: DocumentsApiQueryKey.uploadAttachment(type, file),
      queryFn: (queryOptions) =>
        this.runQueryFn<Attachment>((api) =>
          api.uploadAttachment(type, file, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Uploads a new attachment
   * @memberof DocumentsApi
   */
  public uploadAttachmentMutation(): ApiMutationHandler<
    DocumentsApiUploadAttachmentArgs,
    Attachment
  > {
    return {
      mutationKey: 'DocumentsApiUploadAttachment',
      mutationFn: (type: string, file: any, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Attachment>((api) =>
          api.uploadAttachment(type, file, options)
        ),
    };
  }
}

/**
 * DocumentsApi - react-query api
 */
export function useDocumentsApi(
  options?: ApiMethodMakerOptions
): DocumentsApiMaker {
  return useApiMethodMaker<DocumentsApiMaker>(DocumentsApiMaker, options);
}

/**
 * Arguments for the MiscApi.GetCodes API query
 */
export type MiscApiGetCodesArgs = [
  code:
    | 'accounts'
    | 'beneficiarytypes'
    | 'clearingsystems'
    | 'clientdocumenttypes'
    | 'contactdocumenttypes'
    | 'corporateregistries'
    | 'countrycodes'
    | 'currencycodes'
    | 'govtidtypes'
    | 'govtcontactidtypes'
    | 'govtcompanyidtypes'
    | 'govtidissuers'
    | 'govtcontactidissuers'
    | 'govtcompanyidissuers'
    | 'jurisdictions'
    | 'paymenttypes'
    | 'producttypes'
    | 'regulators'
    | 'siccodes'
    | 'sizes'
    | 'suppliers',
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the MiscApi.GetCountries API query
 */
export type MiscApiGetCountriesArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the MiscApi.GetCurrencies API query
 */
export type MiscApiGetCurrenciesArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the MiscApi.GetStats API query
 */
export type MiscApiGetStatsArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * MiscApi - react-query query key creator
 */
export const MiscApiQueryKey = {
  /**
   *
   * @summary Retrieve various codes ( eg clearing systems, payment types)
   */
  getCodesRoot: 'MiscApiGetCodes' as QueryKey,

  /**
   *
   * @summary Retrieve various codes ( eg clearing systems, payment types)
   */
  getCodes(
    code:
      | 'accounts'
      | 'beneficiarytypes'
      | 'clearingsystems'
      | 'clientdocumenttypes'
      | 'contactdocumenttypes'
      | 'corporateregistries'
      | 'countrycodes'
      | 'currencycodes'
      | 'govtidtypes'
      | 'govtcontactidtypes'
      | 'govtcompanyidtypes'
      | 'govtidissuers'
      | 'govtcontactidissuers'
      | 'govtcompanyidissuers'
      | 'jurisdictions'
      | 'paymenttypes'
      | 'producttypes'
      | 'regulators'
      | 'siccodes'
      | 'sizes'
      | 'suppliers'
  ): QueryKey {
    return ['MiscApiGetCodes', code];
  },
  /**
   *
   * @summary Retrieve all countries
   */
  getCountriesRoot: 'MiscApiGetCountries' as QueryKey,

  /**
   *
   * @summary Retrieve all countries
   */
  getCountries(): QueryKey {
    return ['MiscApiGetCountries'];
  },
  /**
   *
   * @summary Retrieve all currencies
   */
  getCurrenciesRoot: 'MiscApiGetCurrencies' as QueryKey,

  /**
   *
   * @summary Retrieve all currencies
   */
  getCurrencies(): QueryKey {
    return ['MiscApiGetCurrencies'];
  },
  /**
   *
   * @summary Get stats
   */
  getStatsRoot: 'MiscApiGetStats' as QueryKey,

  /**
   *
   * @summary Get stats
   */
  getStats(): QueryKey {
    return ['MiscApiGetStats'];
  },
};

/**
 * MiscApi - react-query method creator
 */
export class MiscApiMaker extends BaseApiMaker<MiscApi> {
  /**
   * Get an MiscApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): MiscApi {
    return new MiscApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Retrieve various codes ( eg clearing systems, payment types)
   * @memberof MiscApi
   */
  public getCodes(
    code:
      | 'accounts'
      | 'beneficiarytypes'
      | 'clearingsystems'
      | 'clientdocumenttypes'
      | 'contactdocumenttypes'
      | 'corporateregistries'
      | 'countrycodes'
      | 'currencycodes'
      | 'govtidtypes'
      | 'govtcontactidtypes'
      | 'govtcompanyidtypes'
      | 'govtidissuers'
      | 'govtcontactidissuers'
      | 'govtcompanyidissuers'
      | 'jurisdictions'
      | 'paymenttypes'
      | 'producttypes'
      | 'regulators'
      | 'siccodes'
      | 'sizes'
      | 'suppliers',
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Code>> {
    return {
      queryKey: MiscApiQueryKey.getCodes(code),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Code>>((api) =>
          api.getCodes(code, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Retrieve various codes ( eg clearing systems, payment types)
   * @memberof MiscApi
   */
  public getCodesMutation(): ApiMutationHandler<
    MiscApiGetCodesArgs,
    Array<Code>
  > {
    return {
      mutationKey: 'MiscApiGetCodes',
      mutationFn: (
        code:
          | 'accounts'
          | 'beneficiarytypes'
          | 'clearingsystems'
          | 'clientdocumenttypes'
          | 'contactdocumenttypes'
          | 'corporateregistries'
          | 'countrycodes'
          | 'currencycodes'
          | 'govtidtypes'
          | 'govtcontactidtypes'
          | 'govtcompanyidtypes'
          | 'govtidissuers'
          | 'govtcontactidissuers'
          | 'govtcompanyidissuers'
          | 'jurisdictions'
          | 'paymenttypes'
          | 'producttypes'
          | 'regulators'
          | 'siccodes'
          | 'sizes'
          | 'suppliers',
        options: AxiosRequestConfig = {}
      ) => this.runQueryFn<Array<Code>>((api) => api.getCodes(code, options)),
    };
  }

  /**
   *
   * @summary Retrieve all countries
   * @memberof MiscApi
   */
  public getCountries(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<CountryDetails>> {
    return {
      queryKey: MiscApiQueryKey.getCountries(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<CountryDetails>>((api) =>
          api.getCountries(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Retrieve all countries
   * @memberof MiscApi
   */
  public getCountriesMutation(): ApiMutationHandler<
    MiscApiGetCountriesArgs,
    Array<CountryDetails>
  > {
    return {
      mutationKey: 'MiscApiGetCountries',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<CountryDetails>>((api) =>
          api.getCountries(options)
        ),
    };
  }

  /**
   *
   * @summary Retrieve all currencies
   * @memberof MiscApi
   */
  public getCurrencies(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<CurrencyDetails>> {
    return {
      queryKey: MiscApiQueryKey.getCurrencies(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<CurrencyDetails>>((api) =>
          api.getCurrencies(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Retrieve all currencies
   * @memberof MiscApi
   */
  public getCurrenciesMutation(): ApiMutationHandler<
    MiscApiGetCurrenciesArgs,
    Array<CurrencyDetails>
  > {
    return {
      mutationKey: 'MiscApiGetCurrencies',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<CurrencyDetails>>((api) =>
          api.getCurrencies(options)
        ),
    };
  }

  /**
   *
   * @summary Get stats
   * @memberof MiscApi
   */
  public getStats(options: AxiosRequestConfig = {}): ApiQueryHandler<Stats> {
    return {
      queryKey: MiscApiQueryKey.getStats(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Stats>((api) =>
          api.getStats(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get stats
   * @memberof MiscApi
   */
  public getStatsMutation(): ApiMutationHandler<MiscApiGetStatsArgs, Stats> {
    return {
      mutationKey: 'MiscApiGetStats',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Stats>((api) => api.getStats(options)),
    };
  }
}

/**
 * MiscApi - react-query api
 */
export function useMiscApi(options?: ApiMethodMakerOptions): MiscApiMaker {
  return useApiMethodMaker<MiscApiMaker>(MiscApiMaker, options);
}

/**
 * Arguments for the NotificationsApi.GetAllNotifications API query
 */
export type NotificationsApiGetAllNotificationsArgs = [
  read?: FilterParameter,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the NotificationsApi.GetNotification API query
 */
export type NotificationsApiGetNotificationArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the NotificationsApi.MarkNotificationsAsRead API query
 */
export type NotificationsApiMarkNotificationsAsReadArgs = [
  inlineObject2?: InlineObject2,
  axiosOptions?: AxiosRequestConfig
];

/**
 * NotificationsApi - react-query query key creator
 */
export const NotificationsApiQueryKey = {
  /**
   *
   * @summary List all notifications
   */
  getAllNotificationsRoot: 'NotificationsApiGetAllNotifications' as QueryKey,

  /**
   *
   * @summary List all notifications
   */
  getAllNotifications(read?: FilterParameter): QueryKey {
    return ['NotificationsApiGetAllNotifications', read];
  },
  /**
   *
   * @summary Get properties of a notification
   */
  getNotificationRoot: 'NotificationsApiGetNotification' as QueryKey,

  /**
   *
   * @summary Get properties of a notification
   */
  getNotification(id: string): QueryKey {
    return ['NotificationsApiGetNotification', id];
  },
  /**
   *
   * @summary Marks one or more Notifications as read
   */
  markNotificationsAsReadRoot:
    'NotificationsApiMarkNotificationsAsRead' as QueryKey,

  /**
   *
   * @summary Marks one or more Notifications as read
   */
  markNotificationsAsRead(inlineObject2?: InlineObject2): QueryKey {
    return ['NotificationsApiMarkNotificationsAsRead', inlineObject2];
  },
};

/**
 * NotificationsApi - react-query method creator
 */
export class NotificationsApiMaker extends BaseApiMaker<NotificationsApi> {
  /**
   * Get an NotificationsApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): NotificationsApi {
    return new NotificationsApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary List all notifications
   * @memberof NotificationsApi
   */
  public getAllNotifications(
    read?: FilterParameter,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<NotificationResponse>> {
    return {
      queryKey: NotificationsApiQueryKey.getAllNotifications(read),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<NotificationResponse>>((api) =>
          api.getAllNotifications(read, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List all notifications
   * @memberof NotificationsApi
   */
  public getAllNotificationsMutation(): ApiMutationHandler<
    NotificationsApiGetAllNotificationsArgs,
    Array<NotificationResponse>
  > {
    return {
      mutationKey: 'NotificationsApiGetAllNotifications',
      mutationFn: (read?: FilterParameter, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<NotificationResponse>>((api) =>
          api.getAllNotifications(read, options)
        ),
    };
  }

  /**
   *
   * @summary Get properties of a notification
   * @memberof NotificationsApi
   */
  public getNotification(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<NotificationResponse> {
    return {
      queryKey: NotificationsApiQueryKey.getNotification(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.getNotification(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of a notification
   * @memberof NotificationsApi
   */
  public getNotificationMutation(): ApiMutationHandler<
    NotificationsApiGetNotificationArgs,
    NotificationResponse
  > {
    return {
      mutationKey: 'NotificationsApiGetNotification',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<NotificationResponse>((api) =>
          api.getNotification(id, options)
        ),
    };
  }

  /**
   *
   * @summary Marks one or more Notifications as read
   * @memberof NotificationsApi
   */
  public markNotificationsAsRead(
    inlineObject2?: InlineObject2,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<NotificationResponse>> {
    return {
      queryKey: NotificationsApiQueryKey.markNotificationsAsRead(inlineObject2),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<NotificationResponse>>((api) =>
          api.markNotificationsAsRead(
            inlineObject2,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Marks one or more Notifications as read
   * @memberof NotificationsApi
   */
  public markNotificationsAsReadMutation(): ApiMutationHandler<
    NotificationsApiMarkNotificationsAsReadArgs,
    Array<NotificationResponse>
  > {
    return {
      mutationKey: 'NotificationsApiMarkNotificationsAsRead',
      mutationFn: (
        inlineObject2?: InlineObject2,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<NotificationResponse>>((api) =>
          api.markNotificationsAsRead(inlineObject2, options)
        ),
    };
  }
}

/**
 * NotificationsApi - react-query api
 */
export function useNotificationsApi(
  options?: ApiMethodMakerOptions
): NotificationsApiMaker {
  return useApiMethodMaker<NotificationsApiMaker>(
    NotificationsApiMaker,
    options
  );
}

/**
 * Arguments for the OrganizationApi.GetProfile API query
 */
export type OrganizationApiGetProfileArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the OrganizationApi.GetSettings API query
 */
export type OrganizationApiGetSettingsArgs = [
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the OrganizationApi.Register API query
 */
export type OrganizationApiRegisterArgs = [
  clientRegistrationPostBody?: ClientRegistrationPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the OrganizationApi.UpdateProfile API query
 */
export type OrganizationApiUpdateProfileArgs = [
  profilePutBody?: ProfilePutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the OrganizationApi.UpdateSettings API query
 */
export type OrganizationApiUpdateSettingsArgs = [
  settings?: Settings,
  axiosOptions?: AxiosRequestConfig
];

/**
 * OrganizationApi - react-query query key creator
 */
export const OrganizationApiQueryKey = {
  /**
   *
   * @summary Get organization profile
   */
  getProfileRoot: 'OrganizationApiGetProfile' as QueryKey,

  /**
   *
   * @summary Get organization profile
   */
  getProfile(): QueryKey {
    return ['OrganizationApiGetProfile'];
  },
  /**
   *
   * @summary Get organization settings, including fee accounts
   */
  getSettingsRoot: 'OrganizationApiGetSettings' as QueryKey,

  /**
   *
   * @summary Get organization settings, including fee accounts
   */
  getSettings(): QueryKey {
    return ['OrganizationApiGetSettings'];
  },
  /**
   *
   * @summary Register as a new client
   */
  registerRoot: 'OrganizationApiRegister' as QueryKey,

  /**
   *
   * @summary Register as a new client
   */
  register(clientRegistrationPostBody?: ClientRegistrationPostBody): QueryKey {
    return ['OrganizationApiRegister', clientRegistrationPostBody];
  },
  /**
   *
   * @summary Update properties of an organization\'s profile
   */
  updateProfileRoot: 'OrganizationApiUpdateProfile' as QueryKey,

  /**
   *
   * @summary Update properties of an organization\'s profile
   */
  updateProfile(profilePutBody?: ProfilePutBody): QueryKey {
    return ['OrganizationApiUpdateProfile', profilePutBody];
  },
  /**
   *
   * @summary Update organization settings, including fee accounts
   */
  updateSettingsRoot: 'OrganizationApiUpdateSettings' as QueryKey,

  /**
   *
   * @summary Update organization settings, including fee accounts
   */
  updateSettings(settings?: Settings): QueryKey {
    return ['OrganizationApiUpdateSettings', settings];
  },
};

/**
 * OrganizationApi - react-query method creator
 */
export class OrganizationApiMaker extends BaseApiMaker<OrganizationApi> {
  /**
   * Get an OrganizationApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): OrganizationApi {
    return new OrganizationApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Get organization profile
   * @memberof OrganizationApi
   */
  public getProfile(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Profile> {
    return {
      queryKey: OrganizationApiQueryKey.getProfile(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Profile>((api) =>
          api.getProfile(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get organization profile
   * @memberof OrganizationApi
   */
  public getProfileMutation(): ApiMutationHandler<
    OrganizationApiGetProfileArgs,
    Profile
  > {
    return {
      mutationKey: 'OrganizationApiGetProfile',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Profile>((api) => api.getProfile(options)),
    };
  }

  /**
   *
   * @summary Get organization settings, including fee accounts
   * @memberof OrganizationApi
   */
  public getSettings(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Settings> {
    return {
      queryKey: OrganizationApiQueryKey.getSettings(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Settings>((api) =>
          api.getSettings(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get organization settings, including fee accounts
   * @memberof OrganizationApi
   */
  public getSettingsMutation(): ApiMutationHandler<
    OrganizationApiGetSettingsArgs,
    Settings
  > {
    return {
      mutationKey: 'OrganizationApiGetSettings',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Settings>((api) => api.getSettings(options)),
    };
  }

  /**
   *
   * @summary Register as a new client
   * @memberof OrganizationApi
   */
  public register(
    clientRegistrationPostBody?: ClientRegistrationPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ClientRegistrationSummary> {
    return {
      queryKey: OrganizationApiQueryKey.register(clientRegistrationPostBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<ClientRegistrationSummary>((api) =>
          api.register(clientRegistrationPostBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Register as a new client
   * @memberof OrganizationApi
   */
  public registerMutation(): ApiMutationHandler<
    OrganizationApiRegisterArgs,
    ClientRegistrationSummary
  > {
    return {
      mutationKey: 'OrganizationApiRegister',
      mutationFn: (
        clientRegistrationPostBody?: ClientRegistrationPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ClientRegistrationSummary>((api) =>
          api.register(clientRegistrationPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Update properties of an organization\'s profile
   * @memberof OrganizationApi
   */
  public updateProfile(
    profilePutBody?: ProfilePutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Profile> {
    return {
      queryKey: OrganizationApiQueryKey.updateProfile(profilePutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Profile>((api) =>
          api.updateProfile(profilePutBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Update properties of an organization\'s profile
   * @memberof OrganizationApi
   */
  public updateProfileMutation(): ApiMutationHandler<
    OrganizationApiUpdateProfileArgs,
    Profile
  > {
    return {
      mutationKey: 'OrganizationApiUpdateProfile',
      mutationFn: (
        profilePutBody?: ProfilePutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Profile>((api) =>
          api.updateProfile(profilePutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Update organization settings, including fee accounts
   * @memberof OrganizationApi
   */
  public updateSettings(
    settings?: Settings,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Settings> {
    return {
      queryKey: OrganizationApiQueryKey.updateSettings(settings),
      queryFn: (queryOptions) =>
        this.runQueryFn<Settings>((api) =>
          api.updateSettings(settings, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Update organization settings, including fee accounts
   * @memberof OrganizationApi
   */
  public updateSettingsMutation(): ApiMutationHandler<
    OrganizationApiUpdateSettingsArgs,
    Settings
  > {
    return {
      mutationKey: 'OrganizationApiUpdateSettings',
      mutationFn: (settings?: Settings, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Settings>((api) =>
          api.updateSettings(settings, options)
        ),
    };
  }
}

/**
 * OrganizationApi - react-query api
 */
export function useOrganizationApi(
  options?: ApiMethodMakerOptions
): OrganizationApiMaker {
  return useApiMethodMaker<OrganizationApiMaker>(OrganizationApiMaker, options);
}

/**
 * Arguments for the PaymentsApi.CreateBatch API query
 */
export type PaymentsApiCreateBatchArgs = [
  batchreference?: string,
  batchfile?: any,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.CreatePayment API query
 */
export type PaymentsApiCreatePaymentArgs = [
  paymentPostBody?: PaymentPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.CreatePaymentWithLookup API query
 */
export type PaymentsApiCreatePaymentWithLookupArgs = [
  paymentWithLookupPostBody?: PaymentWithLookupPostBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.ExportPaymentBatches API query
 */
export type PaymentsApiExportPaymentBatchesArgs = [
  timestamp?: FilterParameter,
  batchReference?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.ExportPayments API query
 */
export type PaymentsApiExportPaymentsArgs = [
  amountCurrency?: FilterParameter,
  amountValue?: FilterParameter,
  _class?: FilterParameter,
  fromIban?: FilterParameter,
  fromName?: FilterParameter,
  toIban?: FilterParameter,
  toName?: FilterParameter,
  status?: FilterParameter,
  timeStamp?: FilterParameter,
  type?: FilterParameter,
  id?: FilterParameter,
  clearingSystem?: FilterParameter,
  clearingSystemTxId?: FilterParameter,
  instructionId?: FilterParameter,
  description?: FilterParameter,
  senderReference?: FilterParameter,
  batchReference?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.GetBatch API query
 */
export type PaymentsApiGetBatchArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.GetBatches API query
 */
export type PaymentsApiGetBatchesArgs = [
  timestamp?: FilterParameter,
  batchReference?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.GetPayment API query
 */
export type PaymentsApiGetPaymentArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the PaymentsApi.GetPayments API query
 */
export type PaymentsApiGetPaymentsArgs = [
  amountCurrency?: FilterParameter,
  amountValue?: FilterParameter,
  _class?: FilterParameter,
  fromIban?: FilterParameter,
  fromName?: FilterParameter,
  toIban?: FilterParameter,
  toName?: FilterParameter,
  status?: FilterParameter,
  timeStamp?: FilterParameter,
  type?: FilterParameter,
  id?: FilterParameter,
  clearingSystem?: FilterParameter,
  clearingSystemTxId?: FilterParameter,
  instructionId?: FilterParameter,
  description?: FilterParameter,
  senderReference?: FilterParameter,
  batchReference?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * PaymentsApi - react-query query key creator
 */
export const PaymentsApiQueryKey = {
  /**
   *
   * @summary Uploads a batch of payments
   */
  createBatchRoot: 'PaymentsApiCreateBatch' as QueryKey,

  /**
   *
   * @summary Uploads a batch of payments
   */
  createBatch(batchreference?: string, batchfile?: any): QueryKey {
    return ['PaymentsApiCreateBatch', batchreference, batchfile];
  },
  /**
   *
   * @summary Add a payment
   */
  createPaymentRoot: 'PaymentsApiCreatePayment' as QueryKey,

  /**
   *
   * @summary Add a payment
   */
  createPayment(paymentPostBody?: PaymentPostBody): QueryKey {
    return ['PaymentsApiCreatePayment', paymentPostBody];
  },
  /**
   *
   * @summary Add a payment
   */
  createPaymentWithLookupRoot: 'PaymentsApiCreatePaymentWithLookup' as QueryKey,

  /**
   *
   * @summary Add a payment
   */
  createPaymentWithLookup(
    paymentWithLookupPostBody?: PaymentWithLookupPostBody
  ): QueryKey {
    return ['PaymentsApiCreatePaymentWithLookup', paymentWithLookupPostBody];
  },
  /**
   *
   * @summary Export payment batches
   */
  exportPaymentBatchesRoot: 'PaymentsApiExportPaymentBatches' as QueryKey,

  /**
   *
   * @summary Export payment batches
   */
  exportPaymentBatches(
    timestamp?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string
  ): QueryKey {
    return ['PaymentsApiExportPaymentBatches', timestamp, batchReference, sort];
  },
  /**
   *
   * @summary Export payments
   */
  exportPaymentsRoot: 'PaymentsApiExportPayments' as QueryKey,

  /**
   *
   * @summary Export payments
   */
  exportPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'PaymentsApiExportPayments',
      amountCurrency,
      amountValue,
      _class,
      fromIban,
      fromName,
      toIban,
      toName,
      status,
      timeStamp,
      type,
      id,
      clearingSystem,
      clearingSystemTxId,
      instructionId,
      description,
      senderReference,
      batchReference,
      sort,
    ];
  },
  /**
   *
   * @summary Get batch details (payment request summaries)
   */
  getBatchRoot: 'PaymentsApiGetBatch' as QueryKey,

  /**
   *
   * @summary Get batch details (payment request summaries)
   */
  getBatch(id: string): QueryKey {
    return ['PaymentsApiGetBatch', id];
  },
  /**
   *
   * @summary List batches
   */
  getBatchesRoot: 'PaymentsApiGetBatches' as QueryKey,

  /**
   *
   * @summary List batches
   */
  getBatches(
    timestamp?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string
  ): QueryKey {
    return ['PaymentsApiGetBatches', timestamp, batchReference, sort];
  },
  /**
   *
   * @summary Get properties of a payment
   */
  getPaymentRoot: 'PaymentsApiGetPayment' as QueryKey,

  /**
   *
   * @summary Get properties of a payment
   */
  getPayment(id: string): QueryKey {
    return ['PaymentsApiGetPayment', id];
  },
  /**
   *
   * @summary List payments
   */
  getPaymentsRoot: 'PaymentsApiGetPayments' as QueryKey,

  /**
   *
   * @summary List payments
   */
  getPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'PaymentsApiGetPayments',
      amountCurrency,
      amountValue,
      _class,
      fromIban,
      fromName,
      toIban,
      toName,
      status,
      timeStamp,
      type,
      id,
      clearingSystem,
      clearingSystemTxId,
      instructionId,
      description,
      senderReference,
      batchReference,
      sort,
    ];
  },
};

/**
 * PaymentsApi - react-query method creator
 */
export class PaymentsApiMaker extends BaseApiMaker<PaymentsApi> {
  /**
   * Get an PaymentsApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): PaymentsApi {
    return new PaymentsApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Uploads a batch of payments
   * @memberof PaymentsApi
   */
  public createBatch(
    batchreference?: string,
    batchfile?: any,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<BatchLoadRequest>> {
    return {
      queryKey: PaymentsApiQueryKey.createBatch(batchreference, batchfile),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<BatchLoadRequest>>((api) =>
          api.createBatch(
            batchreference,
            batchfile,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Uploads a batch of payments
   * @memberof PaymentsApi
   */
  public createBatchMutation(): ApiMutationHandler<
    PaymentsApiCreateBatchArgs,
    Array<BatchLoadRequest>
  > {
    return {
      mutationKey: 'PaymentsApiCreateBatch',
      mutationFn: (
        batchreference?: string,
        batchfile?: any,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<BatchLoadRequest>>((api) =>
          api.createBatch(batchreference, batchfile, options)
        ),
    };
  }

  /**
   *
   * @summary Add a payment
   * @memberof PaymentsApi
   */
  public createPayment(
    paymentPostBody?: PaymentPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Payment> {
    return {
      queryKey: PaymentsApiQueryKey.createPayment(paymentPostBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Payment>((api) =>
          api.createPayment(paymentPostBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Add a payment
   * @memberof PaymentsApi
   */
  public createPaymentMutation(): ApiMutationHandler<
    PaymentsApiCreatePaymentArgs,
    Payment
  > {
    return {
      mutationKey: 'PaymentsApiCreatePayment',
      mutationFn: (
        paymentPostBody?: PaymentPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Payment>((api) =>
          api.createPayment(paymentPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Add a payment
   * @memberof PaymentsApi
   */
  public createPaymentWithLookup(
    paymentWithLookupPostBody?: PaymentWithLookupPostBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Payment> {
    return {
      queryKey: PaymentsApiQueryKey.createPaymentWithLookup(
        paymentWithLookupPostBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Payment>((api) =>
          api.createPaymentWithLookup(
            paymentWithLookupPostBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Add a payment
   * @memberof PaymentsApi
   */
  public createPaymentWithLookupMutation(): ApiMutationHandler<
    PaymentsApiCreatePaymentWithLookupArgs,
    Payment
  > {
    return {
      mutationKey: 'PaymentsApiCreatePaymentWithLookup',
      mutationFn: (
        paymentWithLookupPostBody?: PaymentWithLookupPostBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Payment>((api) =>
          api.createPaymentWithLookup(paymentWithLookupPostBody, options)
        ),
    };
  }

  /**
   *
   * @summary Export payment batches
   * @memberof PaymentsApi
   */
  public exportPaymentBatches(
    timestamp?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: PaymentsApiQueryKey.exportPaymentBatches(
        timestamp,
        batchReference,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportPaymentBatches(
            timestamp,
            batchReference,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export payment batches
   * @memberof PaymentsApi
   */
  public exportPaymentBatchesMutation(): ApiMutationHandler<
    PaymentsApiExportPaymentBatchesArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'PaymentsApiExportPaymentBatches',
      mutationFn: (
        timestamp?: FilterParameter,
        batchReference?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportPaymentBatches(timestamp, batchReference, sort, options)
        ),
    };
  }

  /**
   *
   * @summary Export payments
   * @memberof PaymentsApi
   */
  public exportPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: PaymentsApiQueryKey.exportPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export payments
   * @memberof PaymentsApi
   */
  public exportPaymentsMutation(): ApiMutationHandler<
    PaymentsApiExportPaymentsArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'PaymentsApiExportPayments',
      mutationFn: (
        amountCurrency?: FilterParameter,
        amountValue?: FilterParameter,
        _class?: FilterParameter,
        fromIban?: FilterParameter,
        fromName?: FilterParameter,
        toIban?: FilterParameter,
        toName?: FilterParameter,
        status?: FilterParameter,
        timeStamp?: FilterParameter,
        type?: FilterParameter,
        id?: FilterParameter,
        clearingSystem?: FilterParameter,
        clearingSystemTxId?: FilterParameter,
        instructionId?: FilterParameter,
        description?: FilterParameter,
        senderReference?: FilterParameter,
        batchReference?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get batch details (payment request summaries)
   * @memberof PaymentsApi
   */
  public getBatch(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Batch> {
    return {
      queryKey: PaymentsApiQueryKey.getBatch(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Batch>((api) =>
          api.getBatch(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get batch details (payment request summaries)
   * @memberof PaymentsApi
   */
  public getBatchMutation(): ApiMutationHandler<
    PaymentsApiGetBatchArgs,
    Batch
  > {
    return {
      mutationKey: 'PaymentsApiGetBatch',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Batch>((api) => api.getBatch(id, options)),
    };
  }

  /**
   *
   * @summary List batches
   * @memberof PaymentsApi
   */
  public getBatches(
    timestamp?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Batch>> {
    return {
      queryKey: PaymentsApiQueryKey.getBatches(timestamp, batchReference, sort),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Batch>>((api) =>
          api.getBatches(
            timestamp,
            batchReference,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List batches
   * @memberof PaymentsApi
   */
  public getBatchesMutation(): ApiMutationHandler<
    PaymentsApiGetBatchesArgs,
    Array<Batch>
  > {
    return {
      mutationKey: 'PaymentsApiGetBatches',
      mutationFn: (
        timestamp?: FilterParameter,
        batchReference?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Batch>>((api) =>
          api.getBatches(timestamp, batchReference, sort, options)
        ),
    };
  }

  /**
   *
   * @summary Get properties of a payment
   * @memberof PaymentsApi
   */
  public getPayment(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Payment> {
    return {
      queryKey: PaymentsApiQueryKey.getPayment(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Payment>((api) =>
          api.getPayment(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of a payment
   * @memberof PaymentsApi
   */
  public getPaymentMutation(): ApiMutationHandler<
    PaymentsApiGetPaymentArgs,
    Payment
  > {
    return {
      mutationKey: 'PaymentsApiGetPayment',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Payment>((api) => api.getPayment(id, options)),
    };
  }

  /**
   *
   * @summary List payments
   * @memberof PaymentsApi
   */
  public getPayments(
    amountCurrency?: FilterParameter,
    amountValue?: FilterParameter,
    _class?: FilterParameter,
    fromIban?: FilterParameter,
    fromName?: FilterParameter,
    toIban?: FilterParameter,
    toName?: FilterParameter,
    status?: FilterParameter,
    timeStamp?: FilterParameter,
    type?: FilterParameter,
    id?: FilterParameter,
    clearingSystem?: FilterParameter,
    clearingSystemTxId?: FilterParameter,
    instructionId?: FilterParameter,
    description?: FilterParameter,
    senderReference?: FilterParameter,
    batchReference?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Payment>> {
    return {
      queryKey: PaymentsApiQueryKey.getPayments(
        amountCurrency,
        amountValue,
        _class,
        fromIban,
        fromName,
        toIban,
        toName,
        status,
        timeStamp,
        type,
        id,
        clearingSystem,
        clearingSystemTxId,
        instructionId,
        description,
        senderReference,
        batchReference,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Payment>>((api) =>
          api.getPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List payments
   * @memberof PaymentsApi
   */
  public getPaymentsMutation(): ApiMutationHandler<
    PaymentsApiGetPaymentsArgs,
    Array<Payment>
  > {
    return {
      mutationKey: 'PaymentsApiGetPayments',
      mutationFn: (
        amountCurrency?: FilterParameter,
        amountValue?: FilterParameter,
        _class?: FilterParameter,
        fromIban?: FilterParameter,
        fromName?: FilterParameter,
        toIban?: FilterParameter,
        toName?: FilterParameter,
        status?: FilterParameter,
        timeStamp?: FilterParameter,
        type?: FilterParameter,
        id?: FilterParameter,
        clearingSystem?: FilterParameter,
        clearingSystemTxId?: FilterParameter,
        instructionId?: FilterParameter,
        description?: FilterParameter,
        senderReference?: FilterParameter,
        batchReference?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<Payment>>((api) =>
          api.getPayments(
            amountCurrency,
            amountValue,
            _class,
            fromIban,
            fromName,
            toIban,
            toName,
            status,
            timeStamp,
            type,
            id,
            clearingSystem,
            clearingSystemTxId,
            instructionId,
            description,
            senderReference,
            batchReference,
            sort,
            options
          )
        ),
    };
  }
}

/**
 * PaymentsApi - react-query api
 */
export function usePaymentsApi(
  options?: ApiMethodMakerOptions
): PaymentsApiMaker {
  return useApiMethodMaker<PaymentsApiMaker>(PaymentsApiMaker, options);
}

/**
 * Arguments for the ThirdPartiesApi.CreateThirdParty API query
 */
export type ThirdPartiesApiCreateThirdPartyArgs = [
  thirdPartyPostPutBody?: ThirdPartyPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ThirdPartiesApi.ExportThirdParties API query
 */
export type ThirdPartiesApiExportThirdPartiesArgs = [
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ThirdPartiesApi.GetThirdParties API query
 */
export type ThirdPartiesApiGetThirdPartiesArgs = [
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ThirdPartiesApi.GetThirdParty API query
 */
export type ThirdPartiesApiGetThirdPartyArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ThirdPartiesApi.GetThirdPartyById API query
 */
export type ThirdPartiesApiGetThirdPartyByIdArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ThirdPartiesApi.GetThirdPartySummaries API query
 */
export type ThirdPartiesApiGetThirdPartySummariesArgs = [
  name?: FilterParameter,
  type?: FilterParameter,
  status?: FilterParameter,
  complianceStatus?: FilterParameter,
  searchtext?: string,
  sort?: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the ThirdPartiesApi.UpdateThirdParty API query
 */
export type ThirdPartiesApiUpdateThirdPartyArgs = [
  id: string,
  thirdPartyPostPutBody?: ThirdPartyPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * ThirdPartiesApi - react-query query key creator
 */
export const ThirdPartiesApiQueryKey = {
  /**
   *
   * @summary Add a third party
   */
  createThirdPartyRoot: 'ThirdPartiesApiCreateThirdParty' as QueryKey,

  /**
   *
   * @summary Add a third party
   */
  createThirdParty(thirdPartyPostPutBody?: ThirdPartyPostPutBody): QueryKey {
    return ['ThirdPartiesApiCreateThirdParty', thirdPartyPostPutBody];
  },
  /**
   *
   * @summary Export third parties
   */
  exportThirdPartiesRoot: 'ThirdPartiesApiExportThirdParties' as QueryKey,

  /**
   *
   * @summary Export third parties
   */
  exportThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'ThirdPartiesApiExportThirdParties',
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary List third parties
   */
  getThirdPartiesRoot: 'ThirdPartiesApiGetThirdParties' as QueryKey,

  /**
   *
   * @summary List third parties
   */
  getThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string
  ): QueryKey {
    return [
      'ThirdPartiesApiGetThirdParties',
      name,
      type,
      status,
      complianceStatus,
      sort,
    ];
  },
  /**
   *
   * @summary Get properties of third party
   */
  getThirdPartyRoot: 'ThirdPartiesApiGetThirdParty' as QueryKey,

  /**
   *
   * @summary Get properties of third party
   */
  getThirdParty(id: string): QueryKey {
    return ['ThirdPartiesApiGetThirdParty', id];
  },
  /**
   *
   * @summary Get third party summary using Id
   */
  getThirdPartyByIdRoot: 'ThirdPartiesApiGetThirdPartyById' as QueryKey,

  /**
   *
   * @summary Get third party summary using Id
   */
  getThirdPartyById(id: string): QueryKey {
    return ['ThirdPartiesApiGetThirdPartyById', id];
  },
  /**
   *
   * @summary Get third party summaries
   */
  getThirdPartySummariesRoot:
    'ThirdPartiesApiGetThirdPartySummaries' as QueryKey,

  /**
   *
   * @summary Get third party summaries
   */
  getThirdPartySummaries(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    searchtext?: string,
    sort?: string
  ): QueryKey {
    return [
      'ThirdPartiesApiGetThirdPartySummaries',
      name,
      type,
      status,
      complianceStatus,
      searchtext,
      sort,
    ];
  },
  /**
   *
   * @summary Update properties of a third party
   */
  updateThirdPartyRoot: 'ThirdPartiesApiUpdateThirdParty' as QueryKey,

  /**
   *
   * @summary Update properties of a third party
   */
  updateThirdParty(
    id: string,
    thirdPartyPostPutBody?: ThirdPartyPostPutBody
  ): QueryKey {
    return ['ThirdPartiesApiUpdateThirdParty', id, thirdPartyPostPutBody];
  },
};

/**
 * ThirdPartiesApi - react-query method creator
 */
export class ThirdPartiesApiMaker extends BaseApiMaker<ThirdPartiesApi> {
  /**
   * Get an ThirdPartiesApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): ThirdPartiesApi {
    return new ThirdPartiesApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary Add a third party
   * @memberof ThirdPartiesApi
   */
  public createThirdParty(
    thirdPartyPostPutBody?: ThirdPartyPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdParty> {
    return {
      queryKey: ThirdPartiesApiQueryKey.createThirdParty(thirdPartyPostPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.createThirdParty(
            thirdPartyPostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Add a third party
   * @memberof ThirdPartiesApi
   */
  public createThirdPartyMutation(): ApiMutationHandler<
    ThirdPartiesApiCreateThirdPartyArgs,
    ThirdParty
  > {
    return {
      mutationKey: 'ThirdPartiesApiCreateThirdParty',
      mutationFn: (
        thirdPartyPostPutBody?: ThirdPartyPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.createThirdParty(thirdPartyPostPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Export third parties
   * @memberof ThirdPartiesApi
   */
  public exportThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ExportResponse> {
    return {
      queryKey: ThirdPartiesApiQueryKey.exportThirdParties(
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Export third parties
   * @memberof ThirdPartiesApi
   */
  public exportThirdPartiesMutation(): ApiMutationHandler<
    ThirdPartiesApiExportThirdPartiesArgs,
    ExportResponse
  > {
    return {
      mutationKey: 'ThirdPartiesApiExportThirdParties',
      mutationFn: (
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ExportResponse>((api) =>
          api.exportThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary List third parties
   * @memberof ThirdPartiesApi
   */
  public getThirdParties(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ThirdPartySummary>> {
    return {
      queryKey: ThirdPartiesApiQueryKey.getThirdParties(
        name,
        type,
        status,
        complianceStatus,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ThirdPartySummary>>((api) =>
          api.getThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary List third parties
   * @memberof ThirdPartiesApi
   */
  public getThirdPartiesMutation(): ApiMutationHandler<
    ThirdPartiesApiGetThirdPartiesArgs,
    Array<ThirdPartySummary>
  > {
    return {
      mutationKey: 'ThirdPartiesApiGetThirdParties',
      mutationFn: (
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ThirdPartySummary>>((api) =>
          api.getThirdParties(
            name,
            type,
            status,
            complianceStatus,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Get properties of third party
   * @memberof ThirdPartiesApi
   */
  public getThirdParty(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdParty> {
    return {
      queryKey: ThirdPartiesApiQueryKey.getThirdParty(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.getThirdParty(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of third party
   * @memberof ThirdPartiesApi
   */
  public getThirdPartyMutation(): ApiMutationHandler<
    ThirdPartiesApiGetThirdPartyArgs,
    ThirdParty
  > {
    return {
      mutationKey: 'ThirdPartiesApiGetThirdParty',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ThirdParty>((api) => api.getThirdParty(id, options)),
    };
  }

  /**
   *
   * @summary Get third party summary using Id
   * @memberof ThirdPartiesApi
   */
  public getThirdPartyById(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdPartyShortSummary> {
    return {
      queryKey: ThirdPartiesApiQueryKey.getThirdPartyById(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdPartyShortSummary>((api) =>
          api.getThirdPartyById(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get third party summary using Id
   * @memberof ThirdPartiesApi
   */
  public getThirdPartyByIdMutation(): ApiMutationHandler<
    ThirdPartiesApiGetThirdPartyByIdArgs,
    ThirdPartyShortSummary
  > {
    return {
      mutationKey: 'ThirdPartiesApiGetThirdPartyById',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<ThirdPartyShortSummary>((api) =>
          api.getThirdPartyById(id, options)
        ),
    };
  }

  /**
   *
   * @summary Get third party summaries
   * @memberof ThirdPartiesApi
   */
  public getThirdPartySummaries(
    name?: FilterParameter,
    type?: FilterParameter,
    status?: FilterParameter,
    complianceStatus?: FilterParameter,
    searchtext?: string,
    sort?: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<ThirdPartyShortSummary>> {
    return {
      queryKey: ThirdPartiesApiQueryKey.getThirdPartySummaries(
        name,
        type,
        status,
        complianceStatus,
        searchtext,
        sort
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<ThirdPartyShortSummary>>((api) =>
          api.getThirdPartySummaries(
            name,
            type,
            status,
            complianceStatus,
            searchtext,
            sort,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Get third party summaries
   * @memberof ThirdPartiesApi
   */
  public getThirdPartySummariesMutation(): ApiMutationHandler<
    ThirdPartiesApiGetThirdPartySummariesArgs,
    Array<ThirdPartyShortSummary>
  > {
    return {
      mutationKey: 'ThirdPartiesApiGetThirdPartySummaries',
      mutationFn: (
        name?: FilterParameter,
        type?: FilterParameter,
        status?: FilterParameter,
        complianceStatus?: FilterParameter,
        searchtext?: string,
        sort?: string,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Array<ThirdPartyShortSummary>>((api) =>
          api.getThirdPartySummaries(
            name,
            type,
            status,
            complianceStatus,
            searchtext,
            sort,
            options
          )
        ),
    };
  }

  /**
   *
   * @summary Update properties of a third party
   * @memberof ThirdPartiesApi
   */
  public updateThirdParty(
    id: string,
    thirdPartyPostPutBody?: ThirdPartyPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<ThirdParty> {
    return {
      queryKey: ThirdPartiesApiQueryKey.updateThirdParty(
        id,
        thirdPartyPostPutBody
      ),
      queryFn: (queryOptions) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.updateThirdParty(
            id,
            thirdPartyPostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update properties of a third party
   * @memberof ThirdPartiesApi
   */
  public updateThirdPartyMutation(): ApiMutationHandler<
    ThirdPartiesApiUpdateThirdPartyArgs,
    ThirdParty
  > {
    return {
      mutationKey: 'ThirdPartiesApiUpdateThirdParty',
      mutationFn: (
        id: string,
        thirdPartyPostPutBody?: ThirdPartyPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<ThirdParty>((api) =>
          api.updateThirdParty(id, thirdPartyPostPutBody, options)
        ),
    };
  }
}

/**
 * ThirdPartiesApi - react-query api
 */
export function useThirdPartiesApi(
  options?: ApiMethodMakerOptions
): ThirdPartiesApiMaker {
  return useApiMethodMaker<ThirdPartiesApiMaker>(ThirdPartiesApiMaker, options);
}

/**
 * Arguments for the WebhooksApi.WebhooksGet API query
 */
export type WebhooksApiWebhooksGetArgs = [axiosOptions?: AxiosRequestConfig];

/**
 * Arguments for the WebhooksApi.WebhooksIdDelete API query
 */
export type WebhooksApiWebhooksIdDeleteArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the WebhooksApi.WebhooksIdGet API query
 */
export type WebhooksApiWebhooksIdGetArgs = [
  id: string,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the WebhooksApi.WebhooksIdPut API query
 */
export type WebhooksApiWebhooksIdPutArgs = [
  id: string,
  webhookPostPutBody?: WebhookPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * Arguments for the WebhooksApi.WebhooksPost API query
 */
export type WebhooksApiWebhooksPostArgs = [
  webhookPostPutBody?: WebhookPostPutBody,
  axiosOptions?: AxiosRequestConfig
];

/**
 * WebhooksApi - react-query query key creator
 */
export const WebhooksApiQueryKey = {
  /**
   *
   * @summary List webhooks
   */
  webhooksGetRoot: 'WebhooksApiWebhooksGet' as QueryKey,

  /**
   *
   * @summary List webhooks
   */
  webhooksGet(): QueryKey {
    return ['WebhooksApiWebhooksGet'];
  },
  /**
   *
   * @summary Remove a webhook
   */
  webhooksIdDeleteRoot: 'WebhooksApiWebhooksIdDelete' as QueryKey,

  /**
   *
   * @summary Remove a webhook
   */
  webhooksIdDelete(id: string): QueryKey {
    return ['WebhooksApiWebhooksIdDelete', id];
  },
  /**
   *
   * @summary Get properties of a webhook
   */
  webhooksIdGetRoot: 'WebhooksApiWebhooksIdGet' as QueryKey,

  /**
   *
   * @summary Get properties of a webhook
   */
  webhooksIdGet(id: string): QueryKey {
    return ['WebhooksApiWebhooksIdGet', id];
  },
  /**
   *
   * @summary Update properties of a webhook
   */
  webhooksIdPutRoot: 'WebhooksApiWebhooksIdPut' as QueryKey,

  /**
   *
   * @summary Update properties of a webhook
   */
  webhooksIdPut(id: string, webhookPostPutBody?: WebhookPostPutBody): QueryKey {
    return ['WebhooksApiWebhooksIdPut', id, webhookPostPutBody];
  },
  /**
   *
   * @summary Add a webhook
   */
  webhooksPostRoot: 'WebhooksApiWebhooksPost' as QueryKey,

  /**
   *
   * @summary Add a webhook
   */
  webhooksPost(webhookPostPutBody?: WebhookPostPutBody): QueryKey {
    return ['WebhooksApiWebhooksPost', webhookPostPutBody];
  },
};

/**
 * WebhooksApi - react-query method creator
 */
export class WebhooksApiMaker extends BaseApiMaker<WebhooksApi> {
  /**
   * Get an WebhooksApi instance for the current context
   */
  protected getAPI(cancelToken: CancelToken): WebhooksApi {
    return new WebhooksApi(
      this.getConfiguration(cancelToken),
      this.basePath,
      this.axios
    );
  }

  /**
   *
   * @summary List webhooks
   * @memberof WebhooksApi
   */
  public webhooksGet(
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Array<Webhook>> {
    return {
      queryKey: WebhooksApiQueryKey.webhooksGet(),
      queryFn: (queryOptions) =>
        this.runQueryFn<Array<Webhook>>((api) =>
          api.webhooksGet(merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary List webhooks
   * @memberof WebhooksApi
   */
  public webhooksGetMutation(): ApiMutationHandler<
    WebhooksApiWebhooksGetArgs,
    Array<Webhook>
  > {
    return {
      mutationKey: 'WebhooksApiWebhooksGet',
      mutationFn: (options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Array<Webhook>>((api) => api.webhooksGet(options)),
    };
  }

  /**
   *
   * @summary Remove a webhook
   * @memberof WebhooksApi
   */
  public webhooksIdDelete(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Webhook> {
    return {
      queryKey: WebhooksApiQueryKey.webhooksIdDelete(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Webhook>((api) =>
          api.webhooksIdDelete(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Remove a webhook
   * @memberof WebhooksApi
   */
  public webhooksIdDeleteMutation(): ApiMutationHandler<
    WebhooksApiWebhooksIdDeleteArgs,
    Webhook
  > {
    return {
      mutationKey: 'WebhooksApiWebhooksIdDelete',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Webhook>((api) => api.webhooksIdDelete(id, options)),
    };
  }

  /**
   *
   * @summary Get properties of a webhook
   * @memberof WebhooksApi
   */
  public webhooksIdGet(
    id: string,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Webhook> {
    return {
      queryKey: WebhooksApiQueryKey.webhooksIdGet(id),
      queryFn: (queryOptions) =>
        this.runQueryFn<Webhook>((api) =>
          api.webhooksIdGet(id, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Get properties of a webhook
   * @memberof WebhooksApi
   */
  public webhooksIdGetMutation(): ApiMutationHandler<
    WebhooksApiWebhooksIdGetArgs,
    Webhook
  > {
    return {
      mutationKey: 'WebhooksApiWebhooksIdGet',
      mutationFn: (id: string, options: AxiosRequestConfig = {}) =>
        this.runQueryFn<Webhook>((api) => api.webhooksIdGet(id, options)),
    };
  }

  /**
   *
   * @summary Update properties of a webhook
   * @memberof WebhooksApi
   */
  public webhooksIdPut(
    id: string,
    webhookPostPutBody?: WebhookPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Webhook> {
    return {
      queryKey: WebhooksApiQueryKey.webhooksIdPut(id, webhookPostPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Webhook>((api) =>
          api.webhooksIdPut(
            id,
            webhookPostPutBody,
            merge(queryOptions, options)
          )
        ),
    };
  }

  /**
   *
   * @summary Update properties of a webhook
   * @memberof WebhooksApi
   */
  public webhooksIdPutMutation(): ApiMutationHandler<
    WebhooksApiWebhooksIdPutArgs,
    Webhook
  > {
    return {
      mutationKey: 'WebhooksApiWebhooksIdPut',
      mutationFn: (
        id: string,
        webhookPostPutBody?: WebhookPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Webhook>((api) =>
          api.webhooksIdPut(id, webhookPostPutBody, options)
        ),
    };
  }

  /**
   *
   * @summary Add a webhook
   * @memberof WebhooksApi
   */
  public webhooksPost(
    webhookPostPutBody?: WebhookPostPutBody,
    options: AxiosRequestConfig = {}
  ): ApiQueryHandler<Webhook> {
    return {
      queryKey: WebhooksApiQueryKey.webhooksPost(webhookPostPutBody),
      queryFn: (queryOptions) =>
        this.runQueryFn<Webhook>((api) =>
          api.webhooksPost(webhookPostPutBody, merge(queryOptions, options))
        ),
    };
  }

  /**
   *
   * @summary Add a webhook
   * @memberof WebhooksApi
   */
  public webhooksPostMutation(): ApiMutationHandler<
    WebhooksApiWebhooksPostArgs,
    Webhook
  > {
    return {
      mutationKey: 'WebhooksApiWebhooksPost',
      mutationFn: (
        webhookPostPutBody?: WebhookPostPutBody,
        options: AxiosRequestConfig = {}
      ) =>
        this.runQueryFn<Webhook>((api) =>
          api.webhooksPost(webhookPostPutBody, options)
        ),
    };
  }
}

/**
 * WebhooksApi - react-query api
 */
export function useWebhooksApi(
  options?: ApiMethodMakerOptions
): WebhooksApiMaker {
  return useApiMethodMaker<WebhooksApiMaker>(WebhooksApiMaker, options);
}
