import { DateTime, DateTimeFormatOptions } from 'luxon';
import { useLuxonLocaleFormatter } from '../intl';

const isDateOnly = (format: DateTimeFormatOptions) => !format.hour;

export interface TimeProps {
  format?: DateTimeFormatOptions;
  dateTime?: DateTime;
}

/**
 * An automaticly locale formatted HTML <time> element
 */
export const Time = (props: TimeProps) => {
  const { format = DateTime.DATETIME_FULL_WITH_SECONDS, dateTime } = props;
  const formatDateTime = useLuxonLocaleFormatter(format);
  const formatFullDateTime = useLuxonLocaleFormatter(
    isDateOnly(format)
      ? DateTime.DATE_FULL
      : DateTime.DATETIME_FULL_WITH_SECONDS
  );

  if (!dateTime) return null;

  return (
    <time
      dateTime={isDateOnly(format) ? dateTime.toISODate() : dateTime.toISO()}
      title={formatFullDateTime(dateTime)}
    >
      {formatDateTime(dateTime)}
    </time>
  );
};
