import { Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PdfPage } from '../pdf';
import { PreviewImage, PreviewRoot } from './ui/PreviewRoot';
import { PreviewTitle } from './ui/PreviewTitle';

export interface PdfPreviewProps {
  name: string;
  src: string;
  onRemove?: () => void;
}

/**
 * Preview for PDF files
 */
export const PdfPreview = (props: PdfPreviewProps) => {
  const { name, src, onRemove } = props;
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number | null>(null);
  const loadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  return (
    <PreviewRoot>
      <PreviewTitle onRemove={onRemove}>
        <Typography variant="body2">{name}</Typography>
        {numPages && (
          <Typography variant="caption" color="textSecondary">
            {t('pageOfPages', { numPages })}
          </Typography>
        )}
      </PreviewTitle>
      <PreviewImage data-variant="document">
        <PdfPage file={src} onLoadSuccess={loadSuccess} pageNumber={1} />
      </PreviewImage>
    </PreviewRoot>
  );
};
