import { Button } from '@mui/material';
import { useSessionStorageValue } from '@react-hookz/web/esm';
import { useSnackbar } from 'notistack';
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { addLast } from 'timm';
import { BehaviourReport } from '../../api';
import { DismissIconButton } from '../../form';
import {
  getNotificationSingleReportBehaviour,
  useNotifications,
} from '../../notifications';

export type ReportWatcherProps = {
  children?: ReactElement;
};

const AddReportWatcher = createContext<(reportId: string) => void>(() => {});

/**
 * Get a function to add a report ID to emit snackbar download notifications
 */
export function useAddReportWatcher() {
  return useContext(AddReportWatcher);
}

interface ReportDownloadSnackbarOptions {
  report: BehaviourReport | undefined;
  dismissReport: (reportId: string) => void;
}

/**
 * Manages the emitting of a single snackbar to download a report
 * @internal
 */
function useReportDownloadSnackbar({
  report,
  dismissReport,
}: ReportDownloadSnackbarOptions) {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!report) return;
    const dismiss = () => {
      dismissReport(report.ReportId);
    };

    const key = enqueueSnackbar(t('snackbar.yourReportIsReady'), {
      variant: 'success',
      persist: true,
      action: (
        <>
          <Button
            color="inherit"
            component="a"
            download
            href={report.FileUri}
            onClick={dismiss}
          >
            {t('button.download')}
          </Button>
          <DismissIconButton color="inherit" onClick={dismiss} />
        </>
      ),
    });

    return () => {
      closeSnackbar(key);
    };
  }, [closeSnackbar, dismissReport, enqueueSnackbar, report, t]);
}

/**
 * Watches notifications for reports created in this session and emits snackbar notifications
 */
export const ReportWatcher = (props: ReportWatcherProps) => {
  const { children } = props;
  const { notifications } = useNotifications({ isRead: false });
  const [watchReportIds, setWatchReportIds] = useSessionStorageValue<string[]>(
    'transactive-watch-report-ids',
    []
  );

  const earliestReport = useMemo<BehaviourReport | undefined>(() => {
    if (!notifications) return undefined;

    // Notifications are ordered reverse chronilogically so the last report is the first
    for (let i = notifications.length - 1; i >= 0; --i) {
      const notification = notifications[i];
      const report = getNotificationSingleReportBehaviour(notification);
      if (report && watchReportIds.includes(report.ReportId)) {
        return report;
      }
    }
  }, [notifications, watchReportIds]);

  useReportDownloadSnackbar({
    report: earliestReport,
    dismissReport: useCallback(
      (reportId: string) => {
        setWatchReportIds((watchReportIds) =>
          watchReportIds.filter((id) => id !== reportId)
        );
      },
      [setWatchReportIds]
    ),
  });

  const addReportWatcher = useCallback(
    (reportId: string) => {
      setWatchReportIds((watchReportIds) => addLast(watchReportIds, reportId));
    },
    [setWatchReportIds]
  );

  return (
    <AddReportWatcher.Provider value={addReportWatcher}>
      {children}
    </AddReportWatcher.Provider>
  );
};
