import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactEmailAddressType } from '../../api';

/**
 * Provide localized labels for the ContactEmailAddressType enum
 */
export function useContactEmailAddressTypeLabels(): Record<
  ContactEmailAddressType,
  string
> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ContactEmailAddressType, string> => ({
      Work: t('enumLabel.contactEmailAddressType.work'),
      Personal: t('enumLabel.contactEmailAddressType.personal'),
    }),
    [t]
  );
}
