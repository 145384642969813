import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { EventOutlined as EventIcon } from '@mui/icons-material';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { createElement } from 'react';
import { useFieldDisabled } from '../../disabled';
import { useDateFieldErrorHack } from './useDateFieldErrorHack';
import {
  useDatePickerOnError,
  UseDatePickerOnErrorParams,
} from './useDatePickerOnError';

export type UseDatePickerFieldProps = Omit<
  FieldHookConfig<DateTime | null>,
  'value' | 'type' | 'multiple'
> &
  Pick<TextFieldProps, 'helperText' | 'required'> &
  Omit<
    DatePickerProps<DateTime>,
    | 'name'
    | 'value'
    | 'onChange'
    | 'onError'
    | 'error'
    | 'format'
    | 'renderInput'
  > & {
    readOnly?: boolean;
    // Rename KeyboardDatePicker's format prop to dateFormat
    dateFormat?: DatePickerProps<DateTime>['inputFormat'];
    onChange?: DatePickerProps<DateTime>['onChange'];
    getShouldDisableDateError?: UseDatePickerOnErrorParams['getShouldDisableDateError'];
  };

/**
 * useField wrapper that includes fixed variants of formik-material-ui's fieldToKeyboardDatePicker
 */
export function useDatePickerFieldProps({
  name,
  disabled: disabledProp,
  required,
  helperText,
  dateFormat,
  validate: validateProp,
  ...props
}: UseDatePickerFieldProps): DatePickerProps<DateTime> {
  const disabled = useFieldDisabled(disabledProp);
  const [field, meta, { setValue, setError, setTouched }] =
    useDateFieldErrorHack<DateTime | null>({
      validateProp,
      useFieldFn: (validate) =>
        useField<DateTime | null>({
          name,
          validate,
          ...props,
        }),
    });
  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  const { maxDate, minDate, getShouldDisableDateError } = props;
  const onError = useDatePickerOnError({
    setError,
    maxDate,
    minDate,
    getShouldDisableDateError,
  });

  return {
    ...field,
    disabled,
    onError,
    onChange: (date: DateTime | null) => {
      setValue(date);
      setTouched(true);
    },
    inputFormat: dateFormat,
    ...props,
    components: {
      ...props.components,
      OpenPickerIcon: EventIcon,
    },
    renderInput: (props) =>
      createElement(TextField, {
        ...props,
        required,
        error: showError,
        helperText: showError ? fieldError : helperText,
      }),
  };
}
