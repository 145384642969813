import { styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { PdfPage } from '../pdf';
import { ViewerControls } from './ui/ViewerControls';

export const PdfViewerRoot = styled('div', { name: 'PdfViewer', slot: 'Root' })(
  {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
);

export interface PdfViewerProps {
  name: string;
  src: string;
}

/**
 * Viewer for PDFs
 */
export const PdfViewer = (props: PdfViewerProps) => {
  const { src } = props;
  const [page, goToPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);

  const loadSuccess = useCallback(
    ({ numPages }) => {
      setTotal(numPages);
    },
    [setTotal]
  );

  return (
    <PdfViewerRoot>
      <PdfPage
        file={src}
        onLoadSuccess={loadSuccess}
        pageNumber={page}
        scale={scale}
      />
      <ViewerControls {...{ page, total, goToPage, scale, setScale }} />
    </PdfViewerRoot>
  );
};
