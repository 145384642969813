import { DeleteOutlined as DeleteIcon } from '@mui/icons-material';
import { Button, ButtonProps, ButtonTypeMap } from '@mui/material';
import { ElementType, forwardRef } from 'react';
import { useFieldDisabled } from '../../form';

/**
 * A common "Remove" button with a delete icon
 */
export const RemoveWideButton = forwardRef(function RemoveWideButton<
  D extends ElementType = ButtonTypeMap['defaultComponent'],
  P = {}
>(props: ButtonProps<D, P>, ref: any) {
  const disabled = useFieldDisabled(props.disabled);
  return (
    <Button
      ref={ref}
      variant="outlined"
      startIcon={<DeleteIcon color="error" />}
      color="error"
      {...props}
      disabled={disabled}
    />
  );
}) as <D extends ElementType = ButtonTypeMap['defaultComponent'], P = {}>(
  props: ButtonProps<D, P>
) => JSX.Element;
