import invariant from 'invariant';
import { createContext, createElement, ReactNode, useContext } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

/**
 * Variant for a route indicating whether it's part of the client or the admin side
 * Used for parts of the site that have both a client version and an admin version under Clients
 */
export type RouteVariant = 'client' | 'admin';

const RouteVariantContext = createContext<RouteVariant | null>(null);

/**
 * Get the variant for a route indicating whether it's part of the client or the admin side
 */
export function useRouteVariant(): RouteVariant {
  const routeVariant = useContext(RouteVariantContext);
  invariant(routeVariant, 'needs to be used in a RouteVariantProvider context');
  return routeVariant;
}

export interface RouteVariantProviderProps {
  variant: RouteVariant;
  children?: ReactNode;
}

/**
 * Provide a RouteVariant to child routes
 *
 * @see {@link RouteVariant}
 */
export const RouteVariantProvider = (props: RouteVariantProviderProps) => {
  const { variant, children } = props;

  return createElement(
    RouteVariantContext.Provider,
    { value: variant },
    children
  );
};

/**
 * Make a route that provides a route variant to a series of child routes
 */
export function routeVariant(
  variant: RouteVariant,
  children: RouteObject[]
): RouteObject {
  return {
    element: createElement(
      RouteVariantProvider,
      { variant },
      createElement(Outlet)
    ),
    children,
  };
}
