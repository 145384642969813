import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { TextFieldProps } from '@mui/material';
import { useFieldDisabled } from '../../disabled';

export type UseTextFieldProps = Omit<
  FieldHookConfig<string>,
  'value' | 'type' | 'multiple'
> &
  Omit<TextFieldProps, 'name' | 'value' | 'onChange' | 'error'>;

/**
 * useField wrapper that includes fixed variants of formik-material-ui's fieldToTextField
 */
export function useTextFieldProps({
  name,
  disabled: disabledProp,
  helperText,
  validate,
  ...props
}: UseTextFieldProps): TextFieldProps {
  const disabled = useFieldDisabled(disabledProp);
  const [field, meta] = useField<string>({
    name,
    validate,
    ...props,
  });

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  return {
    ...field,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled,
    ...props,
  };
}
