import { AxiosInstance } from 'axios';
import Axios from 'axios';
import { createContext } from 'react';
import { API_BASE } from '../config';

export interface ApiAxiosContext {
  axios: AxiosInstance;
  basePath: string;
}

export const AxiosContext = createContext<ApiAxiosContext>({
  axios: Axios.create({
    headers: {
      'VND.TransactiveLtd.Agent': 'PortalV2',
    },
  }),
  basePath: API_BASE,
});
