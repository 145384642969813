import invariant from 'invariant';
import { useAdminApi } from '../../api';
import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
  useBreadcrumbApiQuery,
} from '../../breadcrumbs';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const AllClientsTitle = t('page.allClients');
export const ClientDetailsTitle = p(({ clientId }) => {
  invariant(clientId, 'clientId is expected');
  return useBreadcrumbApiQuery(
    useAdminApi().getClient(clientId),
    (client) => client?.Identity?.LegalName?.Item1 ?? client?.ClientProfileName
  );
});
export const AddClientTitle = t('page.newClient');
