import invariant from 'invariant';
import { FieldValidator } from '@johnrom/formik-v3';
import { useValidators, ValidatorLibrary } from '../../validation';
import { Validator } from '../../validation/validator';

export interface CommonValidatorProps {
  /**
   * If `true`, the label is displayed as required and the `input` element` will be required.
   */
  required?: boolean;
  /**
   * Defines the minimum acceptable value. When used with the max and step attributes, lets you control the range and increment (such as even numbers only) that the user can enter.
   */
  min?: number;
  /**
   * Defines the maximum acceptable value. When used with the min and step attributes, lets you control the range and increment (such as only even numbers) that the user can enter.
   */
  max?: number;
  /**
   * Require a minimum length (only applies when a non-empty value is present)
   */
  minLength?: number;
  /**
   * Limit field to a maximum length
   */
  maxLength?: number;
  /**
   * The label content.
   */
  label?: string;
  /**
   * Validate a single field value independently
   */
  validate?: FieldValidator;
}

/**
 * Create an automatic validator that incorporates common validation props like `required`
 */
export default function useCommonValidate(
  props: CommonValidatorProps,
  extraValidators?: (V: ValidatorLibrary) => Validator[]
): FieldValidator | undefined {
  const V = useValidators();
  const { v, required, min, max, minLength, maxLength } = V;

  const validators: Validator[] = [];

  if (props.required) {
    invariant(
      props.label,
      'label is required to implement required validation'
    );
    validators.push(required(props.label));
  }

  if (props.min) validators.push(min(props.min));
  if (props.max) validators.push(max(props.max));
  if (props.minLength) validators.push(minLength(props.minLength));
  if (props.maxLength) validators.push(maxLength(props.maxLength));

  if (extraValidators) {
    validators.push(...extraValidators(V));
  }

  const validate = validators.length > 0 ? v(...validators) : undefined;

  if (validate && props.validate) {
    return (value) => validate(value) ?? props.validate?.(value);
  }

  if (validate) return validate;
  if (props.validate) return props.validate;
}
