import { Box } from '@mui/material';
import {
  Sort as SortIcon,
  SortAscending as SortAscendingIcon,
  SortDescending as SortDescendingIcon,
} from 'mdi-material-ui';

export interface SortIndicatorProps {
  disabled?: boolean;
  active: boolean;
  direction?: 'asc' | 'desc';
}

export const SortIndicator = (props: SortIndicatorProps) => {
  const { disabled = false, active, direction = 'asc' } = props;

  if (disabled) return null;

  let icon = <SortIcon fontSize="small" />;
  if (active)
    icon =
      direction === 'desc' ? (
        <SortDescendingIcon fontSize="small" />
      ) : (
        <SortAscendingIcon fontSize="small" />
      );

  return (
    <Box ml={1} display="flex" flexDirection="row" alignItems="center">
      {icon}
    </Box>
  );
};
