/* eslint-disable no-restricted-imports */
import {
  countrySpecs,
  validateIBAN as validateRealIBAN,
  ValidateIBANResult,
  ValidationErrorsIBAN,
} from 'ibantools';
import invariant from 'invariant';
import { ibanCustomCodes } from './internal/customCodes';
import { extractElectronicIBAN } from './extractElectronicIBAN';

// Add a custom XX validator to ibantools
countrySpecs.XX = {
  chars: 14,
  bban_regexp: '^TR[0-9A-Z]{8}$',
  IBANRegistry: false,
  SEPA: false,
};

const ibanCustomBBANMap = new Map<string, string>();
for (const ibanCustomCode of ibanCustomCodes) {
  const iban = extractElectronicIBAN(ibanCustomCode);
  invariant(iban && iban.countryCode === 'XX', 'unexpected custom code format');

  ibanCustomBBANMap.set(iban.bban, iban.checksum);
}

/**
 * Check if an IBAN is a custom code and manually validate the checksum if so
 * Returns a validation result if IBAN matches the custom code structure
 * Returns null if the IBAN is not a custom code
 */
function validateCustomCode(value: string): ValidateIBANResult | null {
  const iban = extractElectronicIBAN(value);
  if (iban && iban.countryCode === 'XX') {
    const checksum = ibanCustomBBANMap.get(iban.bban);
    if (checksum) {
      // This is a custom code, now compare the checksums
      if (iban.checksum === checksum) {
        return {
          valid: true,
          errorCodes: [],
        };
      } else {
        return {
          valid: false,
          errorCodes: [ValidationErrorsIBAN.WrongIBANChecksum],
        };
      }
    }
  }

  return null;
}

/**
 * IBAN validation from ibantools extended with support for unknown countries, custom XX##TR account codes, and custom special codes.
 * @see {@link validateRealIBAN}
 */
export function validateIBAN(value: string): ValidateIBANResult {
  const customCodeResult = validateCustomCode(value);
  if (customCodeResult) return customCodeResult;

  const { errorCodes: originalErrorCodes } = validateRealIBAN(value);

  // Ignore NoIBANCountry errors so that IBAN codes for unknown countries like US stay valid
  // However do not ignore it if the IBAN is too short as that would consider short strings to be valid IBANs
  // Ignore WrongAccountBankBranchChecksum due to false positives the validator yields (see support case CS-2104)
  const errorCodes =
    value.length > 2
      ? originalErrorCodes.filter(
          (code) =>
            code !== ValidationErrorsIBAN.NoIBANCountry &&
            code !== ValidationErrorsIBAN.WrongAccountBankBranchChecksum
        )
      : originalErrorCodes;
  return {
    errorCodes,
    valid: errorCodes.length === 0,
  };
}
