import { MenuItem } from '@mui/material';
import { ReactNode } from 'react';

export interface MapToOptionsOptions<O> {
  getOptionValue: (option: O) => string;
  getOptionLabel?: (option: O) => ReactNode;
}

/**
 * Turn a array to a list of menu items for a select
 */
export function mapToOptions<O>(
  values: O[],
  options: MapToOptionsOptions<O>
): ReactNode[] {
  const { getOptionValue, getOptionLabel = options.getOptionValue } = options;

  return (
    values?.map((option: O) => (
      <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>
        {getOptionLabel(option)}
      </MenuItem>
    )) ?? []
  );
}

/**
 *
 * @fixme Make SelectField accept this natively as the default options type
 */
export function wrapOptions(
  options: { value: string; label: string }[]
): ReactNode[] {
  return mapToOptions(options, {
    getOptionValue: (option) => option.value,
    getOptionLabel: (option) => option.label,
  });
}
