/**
 * Is mime type an image
 */
export const isImage = (mime: string) => /^image\/.+$/.test(mime);

/**
 * Is mime type a JPEG image
 */
export const isJpeg = (mime: string) => /^image\/jpeg$/.test(mime);

/**
 * Is mime type a PNG image
 */
export const isPng = (mime: string) => /^image\/png$/.test(mime);

/**
 * Is mime type a GIF image
 */
export const isGif = (mime: string) => /^image\/gif$/.test(mime);

/**
 * Is mime type a PDF document
 */
export const isPDF = (mime: string) =>
  /^application\/(x-(bz|gz)?)?pdf$/.test(mime);
