import { forwardRef } from 'react';
import { ComplianceStatus } from '../../../api';
import { useComplianceStatusLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ComplianceStatusFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a ComplianceStatus
 */
export const ComplianceStatusField = forwardRef(function ComplianceStatusField(
  props: ComplianceStatusFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    ComplianceStatus,
    useComplianceStatusLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
