import {
  InputAdornment,
  CircularProgress,
  InputBaseProps,
} from '@mui/material';

/**
 * An input adornment containing a circular spinner
 *
 * Used for loading states in the endAdornment on inputs
 */
export const LoadingAdornment = () => {
  return (
    <InputAdornment position="end">
      <CircularProgress size={24} />
    </InputAdornment>
  );
};

/**
 * A helper that outputs props to add a loading state to text inputs
 *
 * - Does not modify props when `loading: false` so you can just pass a loading boolean
 * - Passes `select={false}` to disable the select field icon
 * - Adds `disabled` to prevent eroneous input
 * - Adds a LoadingAdornment to the end of the input
 * - Accepts props to merge so that non-loading adornments can be merged/passed through without extra effort
 *
 * Example:
 *  <TextField {...inputLoadingProps(loading, { InputProps: { startAdornment: <InputAdornment position="start">$</InputAdornment> } })} />
 * @deprecated
 */
export function fieldLoadingProps<
  Props extends { InputProps?: InputBaseProps }
>(loading: boolean, props?: Props) {
  if (loading) {
    return {
      ...props,
      select: false,
      disabled: true,
      InputProps: {
        ...props?.InputProps,
        endAdornment: <LoadingAdornment />,
      },
    };
  }

  return props;
}
