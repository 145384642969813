import { IconButton, IconButtonProps } from '@mui/material';
import { CheckOutlined as CheckIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/**
 * IconButton for non-save completion
 */
export const DoneIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton aria-label={t('button.done')} color="primary" {...props}>
      <CheckIcon />
    </IconButton>
  );
};
