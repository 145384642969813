import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Get a formatter that will format booleans into Yes/No text
 */
export function useYesNoFormatter() {
  const { t } = useTranslation();
  return useCallback(
    (value: unknown) => {
      return value === undefined || value === false
        ? t('enumLabel.yesNo.no')
        : value === true
        ? t('enumLabel.yesNo.yes')
        : String(value ?? '');
    },
    [t]
  );
}
