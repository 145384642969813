import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../../api';

/**
 * Provide localized labels for the NotificationType enum
 */
export function useNotificationTypeLabels(): Record<NotificationType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<NotificationType, string> => ({
      Informational: t('enumLabel.notificationType.informational'),
      Warning: t('enumLabel.notificationType.warning'),
      Critical: t('enumLabel.notificationType.critical'),
    }),
    [t]
  );
}
