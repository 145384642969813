import { LinearProgress, styled } from '@mui/material';

/**
 * Progress bar on the top of a dialog
 */
export const DialogProgress = styled(LinearProgress)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
});
