import { Outlet, RouteObject } from 'react-router-dom';
import { BreadcrumbProvider } from './provider';
import { BreadcrumbTitleComponent } from './title';
import { Breadcrumb } from './types';

/**
 * Create a route wrapper that'll provide a breadcrumb to all the child routes inside it
 */
export function breadcrumb(
  path: RouteObject['path'],
  id: Breadcrumb['id'],
  TitleComponent: BreadcrumbTitleComponent | undefined,
  children: RouteObject[] | RouteObject
): RouteObject {
  if (!TitleComponent) {
    TitleComponent = ({ children }) =>
      children({
        title: undefined,
        loading: false,
      });
  }

  return {
    path,
    element: TitleComponent ? (
      <TitleComponent>
        {({ title, loading, params }) => (
          <BreadcrumbProvider
            id={id}
            title={title}
            loading={loading}
            params={params}
          >
            <Outlet />
          </BreadcrumbProvider>
        )}
      </TitleComponent>
    ) : (
      <Outlet />
    ),
    children: Array.isArray(children) ? children : [children],
  };
}
