import { Button, ButtonProps, styled } from '@mui/material';
import { TableColumnWidth as TableColumnWidthIcon } from 'mdi-material-ui';
import { useTranslation } from 'react-i18next';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/es/hooks';
import useHtmlId from '../../utils/useHtmlId';
import { ColumnsPopover } from './ColumnsPopover';
import { ReactElement } from 'react';
import { useNumberFormatter } from '../../intl';

const ColumnsButtonDeltaLabel = styled('small', {
  name: 'ColumnsButton',
  slot: 'DeltaLabel',
})(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  ...theme.typography.caption,
  verticalAlign: 'middle',
  lineHeight: 1,
}));

export interface ColumnsButtonProps
  extends Omit<ButtonProps, 'variant' | 'onClick' | 'onChange'> {
  extraHiddenCount?: number;
  extraVisibleCount?: number;
  columnsToggle?: ReactElement;
}

export const ColumnsButton = (props: ColumnsButtonProps) => {
  const { extraHiddenCount, extraVisibleCount, columnsToggle, ...other } =
    props;
  const { t } = useTranslation();
  const n = useNumberFormatter();
  const popupId = useHtmlId(other.name);
  const popupState = usePopupState({ variant: 'popover', popupId });

  let deltaLabel: string | undefined;
  if (extraHiddenCount && extraVisibleCount) {
    deltaLabel = `(-${n(extraHiddenCount)}, +${n(extraVisibleCount)})`;
  } else if (extraHiddenCount) {
    deltaLabel = `(-${n(extraHiddenCount)})`;
  } else if (extraVisibleCount) {
    deltaLabel = `(+${n(extraVisibleCount)})`;
  }

  return (
    <>
      <Button
        {...other}
        variant="outlined"
        endIcon={<TableColumnWidthIcon />}
        {...bindTrigger(popupState)}
      >
        {t('button.columns')}
        {deltaLabel && (
          <>
            {' '}
            <ColumnsButtonDeltaLabel>{deltaLabel}</ColumnsButtonDeltaLabel>
          </>
        )}
      </Button>
      <ColumnsPopover {...bindPopover(popupState)}>
        {columnsToggle}
      </ColumnsPopover>
    </>
  );
};
