import { useMemo } from 'react';
import { useJsonMemo } from '../../utils/useJsonMemo';
import {
  AccountShortSummary,
  ApiQueryHandler,
  useAccountsApi,
  useAdminApi,
} from '../client';
import {
  GetAccountsFilters,
  makeGetAllAccountSummariesFilterArgs,
  makeGetAllClientAccountSummariesFilterArgs,
} from '../filters';

/**
 * Get a list of account summaries
 *
 * @see {@link AdminApiMaker.getAllClientAccountSummaries}
 * @see {@link AccountsApiMaker.getAllAccountSummaries}
 */
export function useAccountShortSummariesHandler(
  clientId: string | undefined | null,
  filters: Pick<
    GetAccountsFilters,
    'searchText' | 'currency' | 'balance' | 'status'
  > = {}
): ApiQueryHandler<AccountShortSummary[]> {
  const accountsApi = useAccountsApi();
  const adminApi = useAdminApi();
  filters = useJsonMemo(filters);

  return useMemo(() => {
    if (clientId) {
      return adminApi.getAllClientAccountSummaries(
        clientId,
        ...makeGetAllClientAccountSummariesFilterArgs(filters)
      );
    } else {
      return accountsApi.getAllAccountSummaries(
        ...makeGetAllAccountSummariesFilterArgs(filters)
      );
    }
  }, [accountsApi, adminApi, clientId, filters]);
}
