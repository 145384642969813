import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
} from 'axios';
import { useContext, useMemo } from 'react';
import { CancelablePromise } from '../../../utils/CancelablePromise';
import { Configuration } from '../gen';
import { BaseAPI } from '../gen/base';
import { AuthOption } from '../types';
import { AxiosContext } from './axiosContext';
import { useAccessToken } from './useAccessToken';

export interface ApiMakerOptions {
  accessToken: string | undefined;
  axios: AxiosInstance;
  basePath: string;
}

export interface ApiMethodMakerOptions {
  auth?: AuthOption;
}

export interface BaseApiMakerConstructor<ApiMaker extends BaseApiMaker<any>> {
  new (options: ApiMakerOptions): ApiMaker;
}

export abstract class BaseApiMaker<API extends BaseAPI> {
  protected accessToken: string | undefined;
  protected axios: AxiosInstance;
  protected basePath: string;

  constructor(options: ApiMakerOptions) {
    this.accessToken = options.accessToken;
    this.axios = options.axios;
    this.basePath = options.basePath;
  }

  protected getConfiguration(cancelToken: CancelToken) {
    const baseOptions: AxiosRequestConfig = {
      cancelToken,
    };

    return new Configuration({
      baseOptions,
      accessToken: this.accessToken,
    });
  }

  protected abstract getAPI(cancelToken: CancelToken): API;

  protected runQueryFn<TData = any>(
    fn: (api: API) => Promise<AxiosResponse<TData>>
  ): CancelablePromise<AxiosResponse<TData>> {
    const source = Axios.CancelToken.source();
    const api = this.getAPI(source.token);
    const promise: CancelablePromise<AxiosResponse<TData>> = fn(api);
    promise.cancel = () => {
      source.cancel();
    };

    return promise;
  }
}

/**
 * Hook used in OpenAPI generated api method maker hooks
 * @internal
 */
export function useApiMethodMaker<ApiMakerT extends BaseApiMaker<any>>(
  ApiMaker: BaseApiMakerConstructor<ApiMakerT>,
  options?: ApiMethodMakerOptions
): ApiMakerT {
  const { axios, basePath } = useContext(AxiosContext);
  const accessToken = useAccessToken(options?.auth);

  return useMemo<ApiMakerT>(() => {
    return new ApiMaker({
      accessToken,
      axios,
      basePath,
    });
  }, [ApiMaker, accessToken, axios, basePath]);
}
