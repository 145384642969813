import { Card, styled, Typography } from '@mui/material';
import { formatAddress } from 'localized-address-format';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactAddressType, LegalAddressType } from '../../api';
import {
  useContactAddressTypeLabels,
  useCountryNames,
  useLegalAddressTypeLabels,
} from '../../intl';
import { AddressValues } from '../prefabs';

export type AddressCardProps = {
  actions?: ReactNode;
} & (
  | {
      variant: 'legal';
      address?: AddressValues<LegalAddressType>;
    }
  | {
      variant: 'contact';
      address?: AddressValues<ContactAddressType>;
    }
);

export const AddressCardCard = styled(Card)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateAreas: `"content actions"`,
    gridTemplateColumns: `1fr auto`,
    '& > .content': { gridArea: 'content' },
    '& > .actions': { gridArea: 'actions' },
  }),
  { name: 'AddressCardCard' }
);

/**
 * A card with a contract address type and formatted address
 */
export const AddressCard = (props: AddressCardProps) => {
  const { variant, address: addr, actions } = props;
  const { t } = useTranslation();
  const legalAddressTypeLabels = useLegalAddressTypeLabels();
  const contactAddressTypeLabels = useContactAddressTypeLabels();
  const countryName = useCountryNames();
  const address = formatAddress({
    addressLines: [addr?.street1, addr?.street2, addr?.street3].filter(
      (street) => street
    ) as string[],
    locality: addr?.city,
    administrativeArea: addr?.region,
    postalCountry: addr?.country ?? '',
    postalCode: addr?.postal,
  });
  if (addr?.country) {
    address.push(countryName(addr.country));
  }

  const label =
    variant === 'legal' && addr?.type
      ? legalAddressTypeLabels[addr.type as LegalAddressType]
      : variant === 'contact' && addr?.type
      ? contactAddressTypeLabels[addr.type as ContactAddressType]
      : t('enumLabel.contactAddressType.unknown');

  return (
    <AddressCardCard>
      <div className="content">
        <Typography color="textSecondary" gutterBottom>
          {label}
        </Typography>
        {address.map((line, index) => (
          <Fragment key={index}>
            {line}
            {index < address.length - 1 && <br />}
          </Fragment>
        ))}
      </div>
      <div className="actions">{actions}</div>
    </AddressCardCard>
  );
};
