import { Alert, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { isPermissionError } from '../../api';
import { getResourceForApi } from '../../api/errors';
import { useError } from '../../utils/ErrorBoundary';
import { Page } from '../Page';

/**
 * Whole page error state
 */
export const PermissionErrorPage = () => {
  const { t } = useTranslation();
  const [error] = useError();

  const resourcePath = useMemo(
    () => isPermissionError(error) && getResourceForApi(error),
    [error]
  );

  return (
    <Page title={t('title.permissionError')}>
      <Alert severity="error">
        <Typography variant="inherit" component="p" paragraph={!!resourcePath}>
          {t('error.permissionErrorMessage')}
        </Typography>
        <Typography variant="caption" component="p" color="textSecondary">
          <Trans
            i18nKey="message.resourcePermissionErrorInfo"
            values={{ resourcePath }}
            components={{
              var: (
                <Typography
                  variant="inherit"
                  component="var"
                  sx={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'double',
                    textUnderlineOffset: 4,
                    userSelect: 'all',
                  }}
                />
              ),
            }}
          />
        </Typography>
      </Alert>
    </Page>
  );
};
