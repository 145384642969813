import { Card, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { TradingName } from '../../api';
import { Definition, DefinitionList } from '../../ui/Definition';
import { useTranslation } from 'react-i18next';

export interface TradingAsCardProps {
  actions?: ReactNode;
  tradingName: TradingName;
}

export const TradingAsCardCard = styled(Card)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateAreas: `"content actions"`,
    gridTemplateColumns: `1fr auto`,
    '& > .content': { gridArea: 'content' },
    '& > .actions': { gridArea: 'actions' },
  }),
  { name: 'TradingAsCardCard' }
);

/**
 * A card with a trading name
 */
export const TradingAsCard = (props: TradingAsCardProps) => {
  const { tradingName, actions } = props;
  const { t } = useTranslation();

  return (
    <TradingAsCardCard>
      <div className="content">
        <Typography color="textSecondary" gutterBottom>
          {tradingName.Name}
        </Typography>

        <DefinitionList>
          <Definition
            label={t('definitionLabel.website')}
            value={tradingName.WebSite}
          />
          <Definition
            label={t('definitionLabel.sic')}
            value={tradingName.SIC}
          />
        </DefinitionList>
      </div>
      <div className="actions">{actions}</div>
    </TradingAsCardCard>
  );
};
