import { useLayoutEffect, useState } from 'react';

export interface UseDelayedToggleOptions {
  defaultValue?: boolean;
  delay: number;
}

/**
 * Delays the enabling of a toggle until after a delay has elapsed.
 */
export function useDelayedToggle(
  value: boolean,
  options: UseDelayedToggleOptions
): boolean {
  const { defaultValue = false, delay } = options;
  const [delayPassed, setDelayPassed] = useState(false);

  const isValueDefault = value === defaultValue;
  useLayoutEffect(() => {
    if (isValueDefault) {
      setDelayPassed(false);
    } else {
      const timeout = setTimeout(() => {
        setDelayPassed(true);
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [delay, isValueDefault]);

  if (delayPassed) return value;

  return defaultValue;
}
