import Axios, { AxiosResponse } from 'axios';
import { CancelablePromise } from '../../utils/CancelablePromise';
import { promiseDelay } from '../../utils/promiseDelay';

/**
 * Wrap a
 */
export function wrapDelay(
  /**
   * Time to wait before starting the API query
   */
  delay?: number
): (
  queryFn: () => CancelablePromise<AxiosResponse<any[]>>
) => CancelablePromise<AxiosResponse<any[]>> {
  return (queryFn) => {
    let canceled = false;
    let cancel: CancelablePromise<unknown>['cancel'] | undefined;

    const promise: CancelablePromise<AxiosResponse<any[]>> =
      Promise.resolve().then(async () => {
        if (delay != null && delay > 0) {
          await promiseDelay(delay);
        }
        if (canceled) {
          throw new Axios.Cancel('Aborted before request start');
        }

        const queryPromise = queryFn();
        cancel = queryPromise.cancel;
        return queryPromise;
      });

    promise.cancel = () => {
      canceled = true;
      if (cancel) {
        return cancel();
      }
    };

    return promise;
  };
}
