import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBranding } from '../branding';
import { getBackgroundBottomColor } from '../theme';

interface OwnerState {
  variant: 'default' | 'inherit';
}

const FooterRoot = styled('div', { name: 'Footer', slot: 'Root' })<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  height: 72,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  ...(ownerState.variant === 'default'
    ? {
        color: theme.palette.getContrastText(
          getBackgroundBottomColor(theme.palette.background)
        ),
      }
    : undefined),
}));

/**
 * A spacing component with the same height as the SiteHeader
 */
export const FooterSpacer = styled('div', { name: 'Footer', slot: 'Spacer' })(
  ({ theme }) => ({
    height: 72,
  })
);

export interface FooterProps {
  variant?: 'default' | 'inherit';
}

/**
 * Footer with
 */
const Footer = (props: FooterProps) => {
  const { variant = 'default' } = props;
  const { copyright } = useBranding();
  const { t } = useTranslation();

  const ownerState: OwnerState = { variant };

  return (
    <FooterRoot ownerState={ownerState}>
      <Typography component="p" variant="caption" color="inherit">
        {copyright} {t('allRightsReserved')}
      </Typography>
    </FooterRoot>
  );
};

export default Footer;
