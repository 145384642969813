import { EQ } from '../comparators';
import { parseString } from '../qs-parser';
import { QueryStringFilterValidator } from '../qs-validator';
import { FilterReturnArg, makeFilter } from '../utils';

export interface GetClientProfilesFilters {
  searchText?: string;
  supplier?: EQ;
}

/**
 * Cast query string arguments to GetClientProfilesFilters
 * @note Generated by filtergen
 */
export function castGetClientProfilesFilters(
  filters: qs.ParsedQs
): GetClientProfilesFilters {
  const f = new QueryStringFilterValidator(filters);

  return {
    searchText: f.searchText('searchText'),

    supplier: {
      eq: f.eq('supplier', parseString),
    },
  };
}

/**
 * Make args for the GetClientProfilesSummaries query params
 * @see GET /clients/settings/clientprofiles/summaries
 * @note Generated by filtergen
 */
export function makeGetClientProfilesSummariesFilterArgs(
  filters: GetClientProfilesFilters
): [searchText: string | undefined] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
  ];
}

/**
 * Make args for the GetClientProfiles query params
 * @see GET /clients/settings/clientprofiles
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetClientProfilesFilterArgs(
  filters: GetClientProfilesFilters
): [supplier: FilterReturnArg] {
  return [
    /**
     * @note Supplier
     */
    makeFilter(filters.supplier),
  ];
}
