import invariant from 'invariant';
import { QueryKey } from 'react-query';
import { useLocale } from '../../../intl';
import {
  ApiInfiniteQueryOptions,
  ApiQueryHandler,
  ApiQueryOptions,
} from '../types';

/**
 * Get the QueryKey for a query, modifying it if necessary
 */
export function useQueryKey<TData = any>(
  query: ApiQueryHandler<unknown> | null,
  options: ApiQueryOptions<TData> | ApiInfiniteQueryOptions<TData>
): QueryKey {
  const locale = useLocale();
  const { queryKey } = query ?? {};

  if (!queryKey) return [];

  if (options.refetchOnLocale) {
    invariant(
      typeof queryKey !== 'string',
      'cannot add locale to string based query key'
    );
    const [rootKey, ...keyOptions] = queryKey;

    return [rootKey, locale, ...keyOptions];
  }

  return queryKey;
}
