import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountBlockStatus } from '../../api';

/**
 * Provide localized labels for the AccountBlockStatus enum
 */
export function useAccountBlockStatusLabels(): Record<
  AccountBlockStatus,
  string
> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<AccountBlockStatus, string> => ({
      Blocked: t('enumLabel.accountBlockStatus.blocked'),
      Disabled: t('enumLabel.accountBlockStatus.disabled'),
      Pending: t('enumLabel.accountBlockStatus.pending'),
      Whitelisted: t('enumLabel.accountBlockStatus.whitelisted'),
    }),
    [t]
  );
}
