import { AxiosResponse } from 'axios';
import { MutableRefObject } from 'react';
import { promiseCancelationPassthrough } from '../../utils/CancelablePromise';
import { parse as parseContentDispositionHeader } from 'content-disposition-header';

/**
 * Parse the filename (and UTF-8 version) from a Content-Disposition header
 */
export function parseFilenameFromContentDisposition(
  contentDisposition: string | undefined
): string | undefined {
  if (contentDisposition) {
    const { parameters } = parseContentDispositionHeader(
      contentDisposition.trim()
    );
    return parameters.filename;
  }

  return undefined;
}

/**
 * Wrap a promise execution with fileNameRef handling
 */
export function wrapFileNameHandler<TResult>(
  fileNameRef?: MutableRefObject<string | undefined>
): (
  queryPromise: Promise<AxiosResponse<TResult>>
) => Promise<AxiosResponse<TResult>> {
  return (queryPromise) => {
    if (fileNameRef) fileNameRef.current = undefined;
    return promiseCancelationPassthrough(queryPromise, (queryPromise) =>
      queryPromise.then((response) => {
        if (fileNameRef) {
          const contentDisposition = response.headers['content-disposition'];
          const fileName =
            parseFilenameFromContentDisposition(contentDisposition);
          if (fileName) fileNameRef.current = fileName;
        }

        return response;
      })
    );
  };
}
