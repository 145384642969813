import { CSSProperties } from 'react';
import { alpha, Theme } from '@mui/material';

// Coppied from https://github.com/mui/material-ui/blob/ba2fce4/packages/mui-material/src/Paper/Paper.js
const getOverlayAlpha = (elevation: number) => {
  let alphaValue;
  if (elevation < 1) {
    alphaValue = 5.11916 * elevation ** 2;
  } else {
    alphaValue = 4.5 * Math.log(elevation + 1) + 2;
  }
  return alphaValue / 100;
};

/**
 * Get background css properties that match the background used by MUI's <Paper>
 */
export function getPaperBackground(
  theme: Theme,
  elevation: number
): Pick<CSSProperties, 'backgroundColor' | 'backgroundImage'> {
  return {
    backgroundColor: theme.palette.background.paper,
    ...(theme.palette.mode === 'dark' && {
      backgroundImage: `linear-gradient(${alpha(
        '#fff',
        getOverlayAlpha(elevation)
      )}, ${alpha('#fff', getOverlayAlpha(elevation))})`,
    }),
  };
}
