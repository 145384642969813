import { useFieldHelpers, useFieldMeta } from '@johnrom/formik-v3';
import { ReactNode } from 'react';

export interface FieldValueProps<T> {
  name: string;
  children: (value: T, setValue: (value: T) => void) => ReactNode;
}

/**
 * Render function component that provides the value for a Formik field to use as a dynamic prop
 */
export const FieldValue = <T extends any = any>(props: FieldValueProps<T>) => {
  const { name, children } = props;
  const { value } = useFieldMeta<T>(name);
  const { setValue } = useFieldHelpers(name);

  return <>{children(value, setValue)}</>;
};

/**
 * Helper for <FieldValue> that provides a simple way to write dynamic fields dependent on another field
 *
 * @see FieldValue
 */
export function fieldValue<T extends any = any>(
  name: string,
  render: (value: T, setValue: (value: T) => void) => ReactNode
) {
  return <FieldValue name={name}>{render}</FieldValue>;
}
