import { Consumer, Dispatch, ReactElement, Reducer, useReducer } from 'react';
import { createReducedStateContext } from './createReducedStateContext';

export interface ReducerContext<S, A> {
  Provider: (props: { children?: ReactElement }) => ReactElement;
  StateConsumer: Consumer<S>;
  /**
   * Get the dispatch function for a reducer context
   */
  useReducerDispatch: () => Dispatch<A>;
  /**
   * Get the current state for a reducer context
   */
  useReducerState: () => S;
}

/**
 * A dispatch+state context that hoists state up in the tree and manages it with useReducer
 */
export function createReducerContext<S, A>(
  displayName: string,
  reducer: Reducer<S, A>,
  initialState: S
): ReducerContext<S, A> {
  return createReducedStateContext<S, Dispatch<A>>(
    displayName,
    () => useReducer<Reducer<S, A>>(reducer, initialState),
    initialState,
    () => {
      console.warn(
        `Default ${displayName} dispatch called, a context root may not be setup.`
      );
    }
  );
}
