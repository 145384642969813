import {
  Component,
  createContext,
  ErrorInfo,
  FunctionComponent,
  isValidElement,
  PropsWithChildren,
  PropsWithRef,
  ReactElement,
  useContext,
} from 'react';

interface ErrorBoundaryProps {
  onReset?: (...args: Array<unknown>) => void;
  onError?: (error: Error, info: { componentStack: string }) => void;
  is?: (error: Error) => boolean;
  fallback: ReactElement<
    unknown,
    string | FunctionComponent | typeof Component
  > | null;
}

type ErrorBoundaryState = { error: Error | null };

const initialState: ErrorBoundaryState = { error: null };

const ResetErrorBoundaryContext = createContext(
  (...args: Array<unknown>) => {}
);
const ErrorStateContext = createContext<Error | null>(null);

export class ErrorBoundary extends Component<
  PropsWithRef<PropsWithChildren<ErrorBoundaryProps>>,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  state = initialState;

  resetErrorBoundary = (...args: Array<unknown>) => {
    this.props.onReset?.(...args);
    this.reset();
  };

  reset() {
    this.setState(initialState);
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.onError?.(error, info);
  }

  render() {
    const { error } = this.state;
    const { is = () => true, fallback } = this.props;

    if (error && !is(error)) {
      throw error;
    }

    if (error !== null) {
      if (isValidElement(fallback)) {
        return (
          <ResetErrorBoundaryContext.Provider value={this.resetErrorBoundary}>
            <ErrorStateContext.Provider value={error}>
              {fallback}
            </ErrorStateContext.Provider>
          </ResetErrorBoundaryContext.Provider>
        );
      } else {
        throw new Error(
          'react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop'
        );
      }
    }

    return this.props.children;
  }
}

/**
 * Get the content and reset function for the current error
 */
export function useError(): [any | null, (...args: Array<unknown>) => void] {
  return [useContext(ErrorStateContext), useContext(ResetErrorBoundaryContext)];
}
