import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalIdType } from '../../api';

/**
 * Provide localized labels for the PersonalIdType enum
 */
export function usePersonalIdTypeLabels(): Record<PersonalIdType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<PersonalIdType, string> => ({
      BirthCertificate: t('enumLabel.personalIdType.birthCertificate'),
      Citizenship: t('enumLabel.personalIdType.citizenship'),
      DriversLicense: t('enumLabel.personalIdType.driversLicense'),
      IdentityCard: t('enumLabel.personalIdType.identityCard'),
      Passport: t('enumLabel.personalIdType.passport'),
      PermanentResidency: t('enumLabel.personalIdType.permanentResidency'),
      SocialInsurance: t('enumLabel.personalIdType.socialInsurance'),
      TemporaryResidency: t('enumLabel.personalIdType.temporaryResidency'),
    }),
    [t]
  );
}
