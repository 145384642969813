import * as validatorFunctions from './validators';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { composeValidators } from './utils';

type ValidatorFunctions = typeof validatorFunctions;

export type Validators = {
  [K in keyof ValidatorFunctions]: ReturnType<ValidatorFunctions[K]>;
};

export type ValidatorLibrary = Validators & {
  /**
   * Compose multiple validation functions into one validation function
   */
  composeValidators: typeof composeValidators;
  /**
   * Alias of composeValidators
   * @see composeValidators
   */
  v: typeof composeValidators;
};

/**
 * Return a library of localized validation functions
 */
export function useValidators(): ValidatorLibrary {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return useMemo<ValidatorLibrary>(() => {
    const validators = Object.fromEntries(
      Object.entries(validatorFunctions).map(([name, makeValidator]) => [
        name,
        makeValidator(t, language),
      ])
    ) as Validators;

    return {
      ...validators,
      composeValidators,
      v: composeValidators,
    };
  }, [language, t]);
}
