import { styled } from '@mui/material';
import { thinScrollbar } from '../../utils/scroll';

export const PreviewRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  }),
  { name: 'PreviewRoot' }
);

export const PreviewImage = styled('div')(
  ({ theme }) => ({
    margin: theme.spacing(-1),
    marginTop: theme.spacing(1),
    overflow: 'auto',
    ...thinScrollbar,
    '&[data-variant="document"]': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '& > img': {
      display: 'block',
      minWidth: 0,
      maxWidth: '100%',
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  }),
  { name: 'PreviewRoot' }
);
