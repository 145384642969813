export interface CancelablePromise<T> extends Promise<T> {
  /**
   * Cancel the promise
   */
  cancel?: () => void;
}

/**
 * Copy the cancelation function from a promise if it has one
 */
export function inheritPromiseCancelation(
  destPromise: CancelablePromise<unknown>,
  sourcePromise: CancelablePromise<unknown>
): void {
  destPromise.cancel = sourcePromise.cancel;
}

/**
 * Utility to .then/.catch a promise while passing through the cancelation function on the source promise
 */
export function promiseCancelationPassthrough<SourceT, ResultT>(
  sourcePromise: CancelablePromise<SourceT>,
  run: (sourcePromise: CancelablePromise<SourceT>) => Promise<ResultT>
): CancelablePromise<ResultT> {
  const destPromise = run(sourcePromise);
  inheritPromiseCancelation(destPromise, sourcePromise);
  return destPromise;
}
