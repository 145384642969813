import { keyframes } from '@emotion/react';
import { styled } from '@mui/material';
import clsx from 'clsx';

const loadingEllipsisAnimate = keyframes({
  '0%': {
    marginBottom: '0',
  },
  '50%': {
    marginBottom: '0.25em',
  },
  '100%': {
    marginBottom: '0',
  },
});

const em = (em: number) => em.toFixed(3) + 'em';

const Dot = styled('span')(
  {
    display: 'inline-block',
    backgroundColor: 'currentColor',
    borderRadius: '.2em',
    width: em(1 / 8),
    height: em(1 / 8),
    margin: `0 ${em(1 / 16)}`,
    '.dots-loading &': {
      animation: `${loadingEllipsisAnimate} .75s linear infinite`,
    },
  },
  { name: 'Dot' }
);

const Dot1 = styled(Dot)({ '.dots-loading &': { animationDelay: '0s' } });
const Dot2 = styled(Dot)({ '.dots-loading &': { animationDelay: '.1s' } });
const Dot3 = styled(Dot)({ '.dots-loading &': { animationDelay: '.2s' } });

const DotWrapper = styled('span')(
  {
    display: 'inline-block',
  },
  { name: 'DotWrapper' }
);

export interface LoadingEllipsisProps {
  loading?: boolean;
}

/**
 * A set of ellipsis which bounce to indicate loading
 */
const LoadingEllipsis = (props: LoadingEllipsisProps) => {
  const { loading = true } = props;

  return (
    <DotWrapper className={clsx({ 'dots-loading': loading })}>
      <Dot1 />
      <Dot2 />
      <Dot3 />
    </DotWrapper>
  );
};

export default LoadingEllipsis;
