import { useContext, useMemo } from 'react';
import { BreadcrumbsContext } from './internal/context';
import { Breadcrumb } from './types';

/**
 * Get the breadcrumb list from the current context
 */
export function useBreadcrumbs(): Breadcrumb[] {
  return useContext(BreadcrumbsContext);
}

/**
 * Get the automatic breadcrumbs and title for the current page
 */
export function usePageBreadcrumbs(): [Breadcrumb[], Breadcrumb | undefined] {
  const breadcrumbs = useBreadcrumbs();

  return useMemo<[Breadcrumb[], Breadcrumb | undefined]>(
    () =>
      breadcrumbs.length > 0
        ? [
            breadcrumbs.slice(0, breadcrumbs.length - 1),
            breadcrumbs[breadcrumbs.length - 1],
          ]
        : [[], undefined],
    [breadcrumbs]
  );
}
