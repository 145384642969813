import useHtmlId from '../../../utils/useHtmlId';

export interface BaseUsePropsWithIdBaseProps {
  id?: string;
  name: string;
}

/**
 * Props but with a guaranteed ID
 */
export type PropsWithId<P extends BaseUsePropsWithIdBaseProps> = Omit<
  P,
  'id'
> & { id: string };

/**
 * Takes a props object and returns it with an automatic id if none was provides
 */
export default function usePropsWithId<P extends BaseUsePropsWithIdBaseProps>(
  props: P
): PropsWithId<P> {
  const { id: idProp, ...other } = props;
  const id = useHtmlId(props.name, idProp);

  return { id, ...other };
}
