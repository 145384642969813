import { TFunction } from 'i18next';
import {
  electronicFormatIBAN,
  getCountrySpecifications as getCountryIbanSpecifications,
  isValidBIC,
  ValidationErrorsIBAN,
} from 'ibantools';
import validator from 'validator';
import { isSortCodeHackString, validateIBAN } from '../../iban';
import { Validator } from './validator';
import {
  electronicFormatSortCodeHack,
  validateSortCodeHack,
  SortCodeHackError,
} from '../../iban/sortCodeHack';
const { isEmail } = validator;

/**
 * Make a field required
 */
export const required =
  (t: TFunction) =>
  (label: string): Validator =>
  (value) => {
    const empty =
      value == null ||
      value === false ||
      value === '' ||
      (Array.isArray(value) && value.length === 0);

    if (empty) {
      return t('inputRequired', {
        label,
      });
    }
  };

/**
 * Validate an email field
 */
export const email = (t: TFunction) => (): Validator => (value) => {
  if (value && !isEmail(value)) {
    return t('invalidEmail');
  }
};

/**
 * Validate a BIC
 */
export const bic =
  (t: TFunction, locale: string) => (): Validator => (value) => {
    if (value && typeof value === 'string') {
      value = value.trim().toUpperCase();
      if (value.length !== 11 && value.length !== 8)
        return t('error.bicWrongSize');
      const countrySpecs = getCountryIbanSpecifications();
      const cc = value.slice(4, 6);
      const spec = countrySpecs[cc];
      if (!/^[A-Z]{2}$/.test(cc)) return t('error.invalidBicCountryCode');
      if (!spec) {
        return t('error.bicUnsupportedCountry', {
          country: new Intl.DisplayNames(locale, { type: 'region' }).of(cc),
        });
      }
      // We've pre-validated everything else, so the only remaining error is the regexp which ibantools validates characters withf
      if (!isValidBIC(value)) return t('error.invalidBicCharacters');
    }
  };

interface IbanValidatorOptions {
  /**
   * Also accepts UK sort code + account number format such as "GB:40-40-40 12345678"
   */
  allowSortCodeHack?: boolean;
}

/**
 * Validate an IBAN
 */
export const iban =
  (t: TFunction, locale: string) =>
  (opts: IbanValidatorOptions = {}): Validator =>
  (value) => {
    const { allowSortCodeHack = false } = opts;

    if (value && typeof value === 'string') {
      value = value.trim();

      if (allowSortCodeHack && isSortCodeHackString(value)) {
        value = electronicFormatSortCodeHack(value);
        const { valid, errorCodes } = validateSortCodeHack(value);

        if (valid) return;

        if (errorCodes.includes(SortCodeHackError.InvalidCharacters))
          return t('error.invalidCharactersInSortCodeAccountNumber');

        if (errorCodes.includes(SortCodeHackError.SortCodeMinLength))
          return t('error.sortCodeTooShort');

        if (errorCodes.includes(SortCodeHackError.AccountNumberMinLength))
          return t('error.ukAccountNumberTooShort');

        if (errorCodes.includes(SortCodeHackError.AccountNumberMaxLength))
          return t('error.ukAccountNumberTooLong');

        throw new Error('Unhandled error code');
      }

      value = electronicFormatIBAN(value);

      const { valid, errorCodes } = validateIBAN(value);

      if (valid) return;

      if (errorCodes.includes(ValidationErrorsIBAN.ChecksumNotNumber))
        return t('error.missingIbanCheckDigits');

      if (errorCodes.includes(ValidationErrorsIBAN.WrongBBANLength))
        return t('error.ibanWrongLength');

      if (errorCodes.includes(ValidationErrorsIBAN.WrongBBANFormat))
        return t('error.invalidBbanCharacters');

      if (
        errorCodes.includes(ValidationErrorsIBAN.WrongAccountBankBranchChecksum)
      )
        return t('error.invalidBankBranchCodeChecksum');

      if (errorCodes.includes(ValidationErrorsIBAN.WrongIBANChecksum))
        return t('error.invalidIbanChecksum');

      return t('error.invalidIban');
    }
  };

/**
 * Require number to be above (only applies when a non-empty value is present)
 */
export const min =
  (t: TFunction) =>
  (minimum: number): Validator =>
  (value) => {
    if (value && typeof value === 'string') value = parseFloat(value);
    if (typeof value === 'number' && value < minimum) {
      return t('error.min', { minimum });
    }
  };

/**
 * Require number to be below (only applies when a non-empty value is present)
 */
export const max =
  (t: TFunction) =>
  (maximum: number): Validator =>
  (value) => {
    if (value && typeof value === 'string') value = parseFloat(value);
    if (typeof value === 'number' && value > maximum) {
      return t('error.max', { maximum });
    }
  };

/**
 * Require a minimum length (only applies when a non-empty value is present)
 */
export const minLength =
  (t: TFunction) =>
  (length: number): Validator =>
  (value) => {
    if (typeof value === 'string' && value.length < length) {
      return t('error.minLength', { length });
    }
  };
/**
 * Limit field to a maximum length
 */
export const maxLength =
  (t: TFunction) =>
  (length: number): Validator =>
  (value) => {
    if (typeof value === 'string' && value.length > length) {
      return t('error.maxLength', { length });
    }
  };

/**
 * Validate a confirm password field (needs to be used with fieldValue to get the other field's value)
 */
export const confirmPassword =
  (t: TFunction) =>
  (newPassword: string | undefined): Validator =>
  (value) => {
    if (newPassword && value !== newPassword) {
      return t('error.passwordNotMatch');
    }
  };
