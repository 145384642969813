import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  isBusinessRulesFailedError,
  isPermissionError,
  isResourceAlreadyExistsError,
} from './errors';

/**
 * Get a localized error message for a common errors
 */
export function getCommonErrorMessage(
  t: TFunction,
  err: unknown
): string | undefined {
  if (isBusinessRulesFailedError(err)) {
    return err.messages?.join('\n');
  }
  if (isResourceAlreadyExistsError(err)) {
    return err.details;
  }
  if (isPermissionError(err)) {
    return t('snackbar.authorizationFailed');
  }
}

/**
 * Get a callback that will emit a localized snackbar message for a common/unexpected error
 */
export function useCommonErrorMessageSnackbar(): (err: unknown) => void {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(
    (err: unknown) => {
      enqueueSnackbar(getCommonErrorMessage(t, err) ?? t('unexpectedError'), {
        variant: 'error',
      });
    },
    [enqueueSnackbar, t]
  );
}
