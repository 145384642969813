import { styled } from '@mui/material';
import { BrandingLogo } from '../branding';
import { SplashLayoutLogo, SplashLayoutRoot } from './ui/SplashLayout';

const StaticSplashRoot = styled(SplashLayoutRoot, {
  name: 'SplashLayout',
  slot: 'Root',
})(({ theme }) => ({
  // Hover over top of all UI so testing stuff is not captured
  position: 'absolute',
  zIndex: 999999,

  // @fixme Implement palette color for this
  backgroundColor: '#011a28',
  color: theme.palette.getContrastText('#011a28'),
}));

const SplashLayoutSiteName = styled('div', {
  name: 'SplashLayout',
  slot: 'SiteName',
})(({ theme }) => ({
  gridArea: 'sitename',
  ...theme.typography.body1,
  marginTop: theme.spacing(2),
  textAlign: 'center',
  opacity: theme.palette.action.disabledOpacity,
  textTransform: 'uppercase',
}));

export interface SplashLayoutProps {
  siteName?: string;
}

export const StaticSplash = (props: SplashLayoutProps) => {
  const { siteName } = props;

  return (
    <StaticSplashRoot>
      <SplashLayoutLogo>
        <BrandingLogo />
      </SplashLayoutLogo>
      {siteName && <SplashLayoutSiteName>{siteName}</SplashLayoutSiteName>}
    </StaticSplashRoot>
  );
};
