import {
  ButtonBase,
  ButtonBaseTypeMap,
  ExtendButtonBase,
  Fade,
  styled,
  Toolbar,
} from '@mui/material';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useBranding } from '../../branding';
import { BrandingLogo } from '../../branding/BrandingLogo';

const LogoToolbar = styled(Toolbar, {
  name: 'LogoBar',
  slot: 'Root',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  height: 72,
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '&.expanded': {
    height: 72 * 2,
  },
}));

const LogoButton = styled(ButtonBase, {
  name: 'LogoBar',
  slot: 'Button',
})(({ theme }) => ({
  position: 'relative',
  flex: 1,
  borderBottomRightRadius: theme.shape.borderRadius,

  // Link fix
  display: 'block',
  color: 'inherit',
  textDecoration: 'none',
})) as ExtendButtonBase<ButtonBaseTypeMap>;

const Layer = styled('div', { name: 'LogoBar', slot: 'Layer' })(
  ({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  })
);

const IconContainer = styled('div', { name: 'LogoBar', slot: 'IconContainer' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 56,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  })
);

const LogoContainer = styled('div', { name: 'LogoBar', slot: 'LogoContainer' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export interface LogoBarProps {
  expanded?: boolean;
}

/**
 * Toolbar size branding logo area
 */
const LogoBar = (props: LogoBarProps) => {
  const { expanded = false } = props;
  const { siteName } = useBranding();

  return (
    <LogoToolbar role="banner" className={clsx({ expanded })} disableGutters>
      <LogoButton component={Link} to="/" aria-label={siteName}>
        <Fade in={!expanded}>
          <Layer>
            <IconContainer>
              <BrandingLogo variant="icon" />
            </IconContainer>
          </Layer>
        </Fade>
        <Fade in={expanded}>
          <Layer>
            <LogoContainer>
              <BrandingLogo variant="logo" />
            </LogoContainer>
          </Layer>
        </Fade>
      </LogoButton>
    </LogoToolbar>
  );
};

export default LogoBar;
