import ModalProvider from 'mui-modal-provider';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApiProvider, DownloadProgressProvider } from './api';
import { AuthProvider } from './auth';
import { DemoBrandingProvider } from './branding';
import branding from './branding/branding';
import { DatePickersProvider } from './date';
import { DevToolsProvider } from './devtools';
import { IntlLocaleProvider } from './intl';
import { NotificationsProvider } from './notifications';
import AppRoutes from './routes/AppRoutes';
import { RawLoadingSplash, ThemedLoadingSplash } from './splash';
import { ReportWatcher } from './table-page';
import { ThemeProvider } from './theme';
import { TimeZoneSettingProvider } from './time';
import { composeProviders } from './utils/composeProviders';
import { PUBLIC_URL } from './utils/env';

function App() {
  return composeProviders(
    <Suspense fallback={<RawLoadingSplash />} />,
    <ApiProvider />,
    <TimeZoneSettingProvider />,
    <IntlLocaleProvider />,
    <DownloadProgressProvider />,
    <DemoBrandingProvider branding={branding} />,
    <DatePickersProvider children={null} />,
    <ThemeProvider children={null} />,
    <Suspense fallback={<ThemedLoadingSplash />} />,
    <SnackbarProvider maxSnack={3} children={null} />,
    <Router basename={PUBLIC_URL} />,
    <DevToolsProvider />,
    <AuthProvider />,
    <ModalProvider children={null} />,
    <NotificationsProvider />,
    <ReportWatcher />,
    <AppRoutes />
  );
}

export default App;
