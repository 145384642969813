import { PreviewImage, PreviewRoot } from './ui/PreviewRoot';
import { PreviewTitle } from './ui/PreviewTitle';

export interface ImagePreviewProps {
  name: string;
  src: string;
  onRemove?: () => void;
}

/**
 * Preview for images
 */
export const ImagePreview = (props: ImagePreviewProps) => {
  const { name, src, onRemove } = props;

  return (
    <PreviewRoot>
      <PreviewTitle title={name} onRemove={onRemove} />
      <PreviewImage>
        <img src={src} alt={name} />
      </PreviewImage>
    </PreviewRoot>
  );
};
