import { styled, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactElement, ReactNode, useCallback } from 'react';
import {
  Cell,
  CellProps,
  ColumnWithLooseAccessor,
  ColumnWithStrictAccessor,
  Row,
} from 'react-table';
import { Money } from '../api';
import {
  useCollator,
  useCountryNames,
  useCurrencyFormatters,
  useCurrencyName,
  useLuxonLocaleFormatter,
  useNumberFormatter,
  usePercentFormatter,
} from '../intl';
import { useYesNoFormatter } from '../intl/yesNo';
import MonoSpan from '../ui/MonoSpan';
import { ChipRenderer } from './cells/ChipRenderer';
import {
  DeleteIconRenderer,
  DeleteIconRendererProps,
} from './cells/DeleteIconRenderer';
import { DataTableColumn } from './useDataTable';
import { sortRowByComparator } from './utils/sortRowBy';

export interface DataTableColumnTypeInterface {
  sortable?: boolean;
}

export type DataTableColumnTypeWithLooseAccessor<D extends object> =
  DataTableColumnTypeInterface & ColumnWithLooseAccessor<D>;
export type DataTableColumnTypeWithStrictAccessor<D extends object> =
  DataTableColumnTypeInterface & ColumnWithStrictAccessor<D>;

export type DataTableColumnType<D extends object> =
  | DataTableColumnTypeWithLooseAccessor<D>
  | DataTableColumnTypeWithStrictAccessor<D>;

/**
 * Apply column options common to all column types
 */
function commonColumn<D extends object>({
  sortable,
  filter,
  ...column
}: DataTableColumnType<D>): DataTableColumn<D> {
  return {
    disableFilters: !filter,
    filter,
    disableSortBy: !sortable,
    noWrap: true,
    ...column,
  };
}

const TooltipUnderline = styled('span')(
  {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
  },
  { name: 'TooltipUnderline' }
);

/**
 * Add a tooltip
 */
export function withTooltip(value: string, tooltip: string): ReactElement;
export function withTooltip(
  value: ReactNode,
  tooltip: string,
  key: string
): ReactElement;
export function withTooltip(
  value: ReactNode,
  tooltip: string,
  key?: string
): ReactElement {
  if (!key && typeof value === 'string') key = value;

  return (
    <Tooltip key={key} title={tooltip}>
      <TooltipUnderline>{value}</TooltipUnderline>
    </Tooltip>
  );
}

/**
 * Handle a column that may be a string or an array of strings using a render function for each item, separating items by commas if it's an array
 */
export function maybeStringList(
  value: string | string[] | undefined | null,
  renderValue: (value: string, index: number) => ReactElement
): ReactElement {
  const values = Array.isArray(value) ? value : [value ?? ''];
  const items = values.map((value, index) => renderValue(value, index));
  const output = [];
  for (let i = 0; i < items.length; i++) {
    if (i !== 0) output.push(', ');
    output.push(items[i]);
  }

  return <>{output}</>;
}

/**
 * Create parameters for a normal text column
 */
export function useTextColumnType<D extends object>() {
  const { compare } = useCollator();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, unknown>) => String(value ?? ''),
      Export: ({ value }: Cell<D>) => String(value ?? ''),
      sortType: sortRowByComparator<string | undefined>((x, y) =>
        compare(x ?? '', y ?? '')
      ),
      ...commonColumn(column),
    }),
    [compare]
  );
}

/**
 * Create parameters for a text column with monospace font
 */
export function useIdentifierColumnType<D extends object>() {
  const { compare } = useCollator();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, unknown>) => (
        <MonoSpan>{String(value ?? '')}</MonoSpan>
      ),
      Export: ({ value }: Cell<D>) => String(value ?? ''),
      sortType: sortRowByComparator<string | undefined>((x, y) =>
        compare(x ?? '', y ?? '')
      ),
      ...commonColumn(column),
    }),
    [compare]
  );
}

/**
 * Create parameters for a yesNo column
 */
export function useYesNoColumnType<D extends object>() {
  const formatYesNo = useYesNoFormatter();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, unknown>) => formatYesNo(value),
      Export: ({ value }: Cell<D>) => formatYesNo(value),
      ...commonColumn(column),
    }),
    [formatYesNo]
  );
}

/**
 * Create parameters for a formatted number column
 */
export function useNumberColumn<D extends object>() {
  const formatNumber = useNumberFormatter();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, number | undefined>) =>
        value == null ? '' : formatNumber(value),
      Export: ({ value }: Cell<D>) => String(value ?? ''),
      sortType: 'number',
      align: 'end',
      ...commonColumn(column),
    }),
    [formatNumber]
  );
}

/**
 * Create parameters for a formatted percentage column
 */
export function usePercentColumn<D extends object>(type: 'ratio' | 'raw') {
  const formatPercent = usePercentFormatter();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, number | undefined>) =>
        value == null
          ? ''
          : formatPercent(type === 'raw' ? value / 100 : value),
      Export: ({ value }: Cell<D>) => String(value ?? ''),
      sortType: 'number',
      align: 'end',
      ...commonColumn(column),
    }),
    [formatPercent, type]
  );
}

/**
 * Create parameters to display the formatted ammount of a Money object
 */
export function useCurrencyAmountColumnType<D extends object>() {
  const formatCurrency = useCurrencyFormatters();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, Money | undefined>) =>
        typeof value?.Value === 'number'
          ? formatCurrency(value.Currency ?? '', value.Value)
          : '',
      Export: ({ value }: Cell<D>) => String(value.Value ?? ''),
      sortType: 'currencyAmount',
      align: 'end',
      ...commonColumn(column),
    }),
    [formatCurrency]
  );
}

/**
 * Create parameters to display a currency type (e.g. EUR)
 */
export function useCurrencyTypeColumnType<D extends object>() {
  const currencyName = useCurrencyName();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, string>) =>
        withTooltip(String(value ?? ''), value ? currencyName(value) : ''),
      Export: ({ value }: Cell<D>) => String(value ?? ''),
      ...commonColumn(column),
    }),
    [currencyName]
  );
}

/**
 * Create parameters for a dateTime column
 */
export function useDateTimeColumnType<D extends object>() {
  const formatDateTime = useLuxonLocaleFormatter(
    DateTime.DATETIME_SHORT_WITH_SECONDS
  );
  const formatFullDateTime = useLuxonLocaleFormatter(
    DateTime.DATETIME_FULL_WITH_SECONDS
  );

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, string | undefined>) =>
        value
          ? withTooltip(
              formatDateTime(DateTime.fromISO(value).toUTC()),
              formatFullDateTime(DateTime.fromISO(value).toUTC())
            )
          : '',
      Export: ({ value }: Cell<D>) => String(value ?? ''),
      sortType: 'DateTime',
      ...commonColumn(column),
    }),
    [formatDateTime, formatFullDateTime]
  );
}

/**
 * Create parameters for a date column
 */
export function useDateColumnType<D extends object>() {
  const formatDate = useLuxonLocaleFormatter(DateTime.DATE_SHORT);
  const formatFullDate = useLuxonLocaleFormatter(DateTime.DATE_FULL);

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, string | undefined>) =>
        value
          ? withTooltip(
              formatDate(DateTime.fromISO(value)),
              formatFullDate(DateTime.fromISO(value))
            )
          : '',
      Export: ({ value }: Cell<D>) =>
        value ? DateTime.fromISO(value).toISODate() : '',
      sortType: 'DateTime',
      ...commonColumn(column),
    }),
    [formatDate, formatFullDate]
  );
}

/**
 * Create parameters for a country column
 */
export function useCountryColumnType<D extends object>() {
  const countryName = useCountryNames();
  // const { compare } = useCollator();

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, string | string[] | undefined>) =>
        maybeStringList(value, (value) =>
          withTooltip(String(value ?? ''), value ? countryName(value) : '')
        ),
      Export: ({ value }: Cell<D>) => String(value ?? ''),
      // sortBy: sortRowByComparator<string | undefined>((x, y) =>
      //   compare(countryName(x ?? ''), countryName(y ?? ''))
      // ),
      ...commonColumn(column),
    }),
    [countryName]
  );
}

/**
 * Create parameters for an email array column
 */
export function useEmailArrayColumnType<D extends object>() {
  const formatEmails = useCallback((value: string[] | undefined | null) => {
    return value ? value.join(', ') : '';
  }, []);

  return useCallback(
    (column: DataTableColumnType<D>): DataTableColumn<D> => ({
      Cell: ({ value }: CellProps<object, string[] | undefined>) =>
        formatEmails(value),
      Export: ({ value }: Cell<D>) => formatEmails(value),
      ...commonColumn(column),
    }),
    [formatEmails]
  );
}

export interface UseEnumColumnTypeParams {
  refData: Record<string, string>;
}

/**
 * Create parameters for an enum column
 */
export function useEnumColumnType<D extends object>() {
  return useCallback(
    (
      params: DataTableColumnType<D> & UseEnumColumnTypeParams
    ): DataTableColumn<D> => {
      const { refData, ...column } = params;

      const format = (value: string | string[] | undefined) => {
        if (!value) return '';
        value = Array.isArray(value) ? value : [value];

        return value.map((v) => refData?.[v] ?? v).join(', ');
      };

      return {
        Cell: ({ value }: CellProps<object, string | string[] | undefined>) =>
          format(value),
        Export: ({ value }: Cell<D>) => format(value),
        ...commonColumn(column),
      };
    },
    []
  );
}

export interface UseChipColumnTypeParams<V extends unknown> {
  formatValue?: (value: V) => string;
  refData?: Record<string, string>;
  colorRefData?: Record<string, string>;
}

/**
 * Create parameters for a chip column
 */
export function useChipColumnType<D extends object>() {
  return useCallback(
    (
      params: DataTableColumnType<D> &
        UseChipColumnTypeParams<string | undefined>
    ): DataTableColumn<D> => {
      const { formatValue, refData, colorRefData, ...column } = params;

      return {
        Cell: ({ value }: CellProps<object, string | undefined>) => (
          <ChipRenderer
            value={value}
            {...{ formatValue, refData, colorRefData }}
          />
        ),
        Export: ({ value }: Cell<D>) =>
          value ? refData?.[value] ?? value ?? '' : '',
        ...commonColumn(column),
      };
    },
    []
  );
}

/**
 * Create parameters for a delete action icon column
 */
export function useDeleteColumnType<D extends object>() {
  return useCallback(
    (
      params: DataTableColumnType<D> &
        UseChipColumnTypeParams<string | undefined> & {
          disabled?: boolean;
          deleteIcon?: DeleteIconRendererProps['deleteIcon'];
          onDelete?: (row: Row<D>) => void;
        }
    ): DataTableColumn<D> => {
      const {
        disabled = false,
        deleteIcon,
        onDelete,
        formatValue,
        refData,
        colorRefData,
        ...column
      } = params;

      return {
        Cell: ({ row }: CellProps<D, any>) => (
          <DeleteIconRenderer
            disabled={disabled}
            deleteIcon={deleteIcon}
            onClick={() => onDelete?.(row)}
          />
        ),
        Export: () => '',
        ...commonColumn({
          id: 'delete',
          Header: () => null,
          ...column,
        }),
      };
    },
    []
  );
}
