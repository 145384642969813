import { GlobalStyles } from '@mui/material';
import { BrandingLogo } from '../branding';

/**
 * A basic loading splash screen based on initial-splash that doesn't depend on MUI
 */
export const RawLoadingSplash = () => {
  return (
    <div className="initial-splash">
      <div className="splash-logo">
        <BrandingLogo />
      </div>

      <div className="progress-bar">
        <div className="progress-value"></div>
      </div>
    </div>
  );
};

/**
 * A loading splash screen based on initial-splash that also applies CSS variables from the MUI theme
 */
export const ThemedLoadingSplash = () => {
  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          ':root:root': {
            '--splash-background': theme.palette.background.default,
            '--splash-logo': theme.palette.text.disabled,
            '--splash-text': theme.palette.text.primary,
            '--splash-progress': theme.palette.text.disabled,
          },
        })}
      />
      <RawLoadingSplash />
    </>
  );
};
