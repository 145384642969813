import { CSSProperties } from '@mui/styles';

export const thinScrollbar: CSSProperties = {
  MsOverflowStyle: '-ms-autohiding-scrollbar',
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'thin',
};

/**
 * Truncate one line text with an ellipsis
 */
export const truncateEllipsis: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
