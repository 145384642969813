import { forwardRef } from 'react';
import { ActiveType } from '../../../api';
import { useActiveTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ActiveTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a ActiveType
 */
export const ActiveTypeField = forwardRef(function ActiveTypeField(
  props: ActiveTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(ActiveType, useActiveTypeLabels());

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
