import { DateTime, DateTimeFormatOptions, ToRelativeOptions } from 'luxon';
import { useCallback } from 'react';
import { useJsonMemo } from '../utils/useJsonMemo';
import { useLocale } from './locale';
import { useSetTimeZone } from '../time/zone/hooks';

/**
 * Make a formatter callback that formats a value according to the current locale
 */
export function useIntlFormatter<V, R>(
  format: (locale: string, value: V) => R
) {
  const locale = useLocale();
  return useCallback((value: V) => format(locale, value), [format, locale]);
}

/**
 * Get a formatter callback that formats a luxon DateTime using toLocaleString
 */
export function useLuxonLocaleFormatter(
  options: DateTimeFormatOptions
): (date: DateTime) => string {
  options = useJsonMemo(options);
  const zone = useSetTimeZone();

  return useIntlFormatter(
    useCallback(
      (locale, date: DateTime) =>
        zone(date).setLocale(locale).toLocaleString(options),
      [options, zone]
    )
  );
}

/**
 * Get a formatter callback that formats a luxon DateTime with a custom format string
 */
export function useLuxonDateFormater(
  format: string
): (date: DateTime) => string {
  const zone = useSetTimeZone();

  return useIntlFormatter(
    useCallback(
      (locale, date: DateTime) => zone(date).setLocale(locale).toFormat(format),
      [format, zone]
    )
  );
}

/**
 * Get a formatter callback that formats a luxon DateTime with toRelative
 */
export function useLuxonRelativeFormater(
  options?: ToRelativeOptions
): (date: DateTime) => string {
  options = useJsonMemo(options);
  const zone = useSetTimeZone();

  return useIntlFormatter(
    useCallback(
      (locale, date: DateTime) =>
        zone(date).setLocale(locale).toRelative(options) ?? '',
      [options, zone]
    )
  );
}
