import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { TableArrowDown as ExportTableIcon } from 'mdi-material-ui';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * IconButton for table export
 */
export const ExportIconButton = forwardRef(function ExportIconButton(
  props: IconButtonProps,
  ref: any
) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('button.exportDownload') as string}>
      <span>
        <IconButton
          ref={ref}
          aria-label={t('button.export')}
          color="inherit"
          {...props}
        >
          <ExportTableIcon fontSize="large" />
        </IconButton>
      </span>
    </Tooltip>
  );
});
