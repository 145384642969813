import preval from 'preval.macro';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n
  // https://github.com/i18next/i18next-resources-to-backend#you-can-also-lazy-load-the-in-memory-translations-ie-when-using-webpack
  .use(
    resourcesToBackend((locale, namespace, callback) => {
      import(`../locales/${locale}/${namespace}.json`)
        .then(({ default: resources }) => {
          callback(null, resources);
        })
        .catch((err) => {
          callback(err, null);
        });
    })
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    // Dynamically fetch the list of locales we have messages for
    supportedLngs: preval`
      const path = require('path');
      const fs = require('fs');
      module.exports = fs.readdirSync(path.join(__dirname, '..', 'locales'))
        .filter(locale => /^[a-z]{2}(-[A-Z]{2})?$/.test(locale));
    `,
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'messages',
    ns: ['messages'],
    fallbackNS: ['messages'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

if (process.env.REACT_APP_DEVTOOLS === 'true') {
  (window as any).i18next = i18n;
}

export default i18n;
