import { Menu, MenuItemProps, MenuProps } from '@mui/material';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';
import { useJsonMemo } from '../utils/useJsonMemo';

export interface VirtualMenuProps<Item extends unknown> extends MenuProps {
  items: Item[] | undefined | null;
  renderItem: (
    item: Item,
    props: Pick<MenuItemProps, 'key' | 'style'>
  ) => ReactElement;
  itemWidth: number | string;
  itemHeight?: number;
  isDivider?: (item: Item) => boolean;
  /**
   * @warning This function is not updated after initial render
   */
  getItemKey?: (item: Item) => number | string;
}

/**
 * A variant of <Menu> that uses useVirtual to virtualize a long list of items
 * @warning <Menu>'s behaviour of focusing the selected item may not work as expected
 */
export const VirtualMenu = <Item extends unknown>(
  props: VirtualMenuProps<Item>
) => {
  const {
    items,
    renderItem,
    itemWidth,
    itemHeight = 36,
    isDivider,
    getItemKey: getItemKeyProp,
    ...menuProps
  } = props;
  // Ref for the "parent" MenuList scroll container
  // @note useVirtual reads the .current from the ref on an effect and as a result will
  //       not work correctly if the component does not re-render so we need to hack it with useState.
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [currentParentRef, setParentRef] = useState<HTMLDivElement | null>(
    null
  );
  parentRef.current = currentParentRef;

  // Memoize the getItemKey on first render, ignore changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getItemKey = useMemo(() => getItemKeyProp, []);

  const keyExtractor = useMemo(
    () =>
      getItemKey && items
        ? (index: number) => getItemKey(items[index])
        : undefined,
    [getItemKey, items]
  );

  // Memoize a Set of divider indexes so we can do dividier checks in a way that reduces re-renders
  const dividerIndexes = useJsonMemo(
    useMemo(
      () =>
        isDivider
          ? items
              ?.map((item, index) => (isDivider(item) ? index : null))
              ?.filter((index): index is number => typeof index === 'number') ??
            []
          : [],
      [isDivider, items]
    )
  );
  const dividers = useMemo(() => new Set(dividerIndexes), [dividerIndexes]);

  // Virtualize
  const { totalSize, virtualItems } = useVirtual({
    size: items?.length ?? 0,
    parentRef,
    estimateSize: useCallback(
      (index: number) => (dividers.has(index) ? 1 : itemHeight),
      [dividers, itemHeight]
    ),
    keyExtractor,
  });

  return (
    <Menu
      {...menuProps}
      PaperProps={{
        ref: setParentRef,
      }}
      MenuListProps={{
        style: { height: totalSize, width: itemWidth, position: 'relative' },
      }}
    >
      {items &&
        virtualItems.map(({ index, size, start }) =>
          renderItem(items[index], {
            key: getItemKey ? getItemKey(items[index]) : index,
            style: {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: size,
              transform: `translateY(${start}px)`,
            },
          })
        )}
    </Menu>
  );
};
