import {
  AccountBalanceOutlined as AccountBalanceIcon,
  ContactsOutlined as ContactsIcon,
  DashboardOutlined as DashboardIcon,
  HelpOutlineOutlined as HelpOutlineIcon,
  PaymentsOutlined as PaymentsIcon,
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material';
import {
  AccountGroupOutline as AccountGroupIcon,
  Cogs as CogsIcon,
  OfficeBuildingOutline,
} from 'mdi-material-ui';

export const DashboardPageIcon = DashboardIcon;
export const AccountsPageIcon = AccountBalanceIcon;
export const ActivitiesPageIcon = AccountsPageIcon;
export const PaymentsPageIcon = PaymentsIcon;
export const ContactsPageIcon = ContactsIcon;
export const ProfilePageIcon = OfficeBuildingOutline;
export const SettingsPageIcon = SettingsIcon;
export const ClientsPageIcon = AccountGroupIcon;
export const OffersAndServicesPageIcon = CogsIcon;
export const HelpPageIcon = HelpOutlineIcon;
