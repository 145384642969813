import { Button, IconButton } from '@mui/material';
import { ReactNode } from 'react';
import { Link, useMatch } from 'react-router-dom';

export interface SidebarHelpButtonProps {
  expanded: boolean;
  to: string;
  icon: ReactNode;
  label: string;
}

export const SidebarHelpButton = (props: SidebarHelpButtonProps) => {
  const { expanded, to, icon, label } = props;

  const active = useMatch(to);

  if (expanded) {
    return (
      <Button
        variant={active ? 'contained' : 'outlined'}
        fullWidth
        component={Link}
        to={to}
      >
        {label}
      </Button>
    );
  }

  return (
    <IconButton aria-label={label} component={Link} to={to}>
      {icon}
    </IconButton>
  );
};
