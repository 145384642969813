import invariant from 'invariant';

// Root URL the app is served from
export const PUBLIC_URL = import.meta.env.BASE_URL.replace(/\/$/, '');

export enum AppEnvironment {
  Local = 'local',
  Development = 'development',
  Testing = 'testing',
  Staging = 'staging',
  Sandbox = 'sandbox',
  Production = 'production',
}

invariant(
  process.env.REACT_APP_ENVIRONMENT,
  'REACT_APP_ENVIRONMENT was not defined'
);
export const APP_ENV = process.env.REACT_APP_ENVIRONMENT as AppEnvironment;
