import { IconButton, IconButtonProps } from '@mui/material';
import { EditOutlined as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/**
 * IconButton for initiating editing
 */
export const EditIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton aria-label={t('button.edit')} color="primary" {...props}>
      <EditIcon />
    </IconButton>
  );
};
