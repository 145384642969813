import {
  alpha,
  LinearProgress,
  styled,
  Table,
  TableCell,
  TableHead,
  TableProps,
  TableTypeMap,
} from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';

export const DataTableRoot = styled(Table, { name: 'DataTable', slot: 'Root' })(
  {}
);

export type DataTableProps<
  D extends React.ElementType = TableTypeMap['defaultComponent'],
  P = {}
> = TableProps<D, P> & {
  layout?: 'static' | 'flex';
  tableLayout?: 'table' | 'flex';
  /**
   * Is the table empty (used to force flex tables to stretch)
   */
  empty?: boolean;
};

export const DataTable = (props: DataTableProps) => {
  const {
    layout = 'static',
    tableLayout = 'table',
    empty = false,
    className,
    ...tableProps
  } = props;

  return (
    <DataTableRoot
      className={clsx(
        className,
        `DataTable-${layout}`,
        `DataTable-${tableLayout}Layout`,
        {
          'DataTable-empty': empty,
        }
      )}
      {...tableProps}
    />
  );
};

export const DataTableTableHead = styled(TableHead, {
  name: 'DataTable',
  slot: 'TableHead',
})(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

export const DataTableHeaderContainer = styled('div', {
  name: 'DataTable',
  slot: 'HeaderContainer',
})({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const DataTableHeaderResizer = styled('div', {
  name: 'DataTable',
  slot: 'HeaderResizer',
})(({ theme }) => ({
  display: 'inline-flex',
  height: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  // prevents from scrolling while dragging on touch devices
  touchAction: 'none',

  width: 8,
  '&:before': {
    content: '""',
    display: 'block',
    width: 1,
    height: '1.5em',
    backgroundColor: theme.palette.divider,
  },

  '&.DataTableHeaderResizer-isResizing': {},
}));

export const DataTableFilterRow = styled('tr', {
  name: 'DataTable',
  slot: 'FilterRow',
})({
  '& > .MuiTableCell-root': {
    position: 'static',
  },
});

export const DataTableProgressRoot = styled('tr', {
  name: 'DataTableProgress',
  slot: 'Root',
})({
  position: 'sticky',
});
export const DataTableProgressCell = styled('td', {
  name: 'DataTableProgress',
  slot: 'Cell',
})({});
export const DataTableProgressBar = styled(LinearProgress, {
  name: 'DataTableProgress',
  slot: 'Bar',
})({
  marginBottom: -4,
});

export interface DataTableProgressProps {
  colSpan?: number;
  loading?: boolean;
}

export const DataTableProgress = (props: DataTableProgressProps) => {
  const { colSpan, loading = true } = props;

  return (
    <DataTableProgressRoot>
      <DataTableProgressCell colSpan={colSpan}>
        {loading && <DataTableProgressBar variant="indeterminate" />}
      </DataTableProgressCell>
    </DataTableProgressRoot>
  );
};

export const DataTableEmptyCell = styled(TableCell, {
  name: 'DataTable',
  slot: 'EmptyCell',
})({
  textAlign: 'center',
  borderBottom: 'none',
});

export const DataTableCell = styled(TableCell, {
  name: 'DataTable',
  slot: 'Cell',
})(({ theme }) => ({
  '.DataTable-flexLayout &': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '.DataTableRow-selected > &': {
    backgroundColor: theme.palette.action.selected,
  },
  '&.DataTableCell-highlighted': {
    backgroundColor: alpha(
      theme.palette.primary.light,
      theme.palette.action.selectedOpacity
    ),
  },
  '&.DataTableCell-active': {
    backgroundColor: alpha(
      theme.palette.primary.light,
      theme.palette.action.activatedOpacity
    ),
  },
  '&.DataTableCell-selection': {
    padding: theme.spacing(1, 0),
  },
  '&.DataTableCell-nowrap > .DataTableCell-content': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  '&.DataTableCell-start': {
    justifyContent: 'flex-start',
    '& > .DataTableCell-content': { textAlign: 'left' },
  },
  '&.DataTableCell-center': {
    justifyContent: 'center',
    '& > .DataTableCell-content': { textAlign: 'center' },
  },
  '&.DataTableCell-end': {
    justifyContent: 'flex-end',
    '& > .DataTableCell-content': { textAlign: 'right' },
  },
  '&.DataTableCell-visible > .DataTableCell-content': { overflow: 'visible' },
  '&.DataTableCell-hidden > .DataTableCell-content': { overflow: 'hidden' },
  '&.DataTableCell-auto > .DataTableCell-content': { overflow: 'auto' },

  '& > .DataTableCell-content': {},
}));
