import { getIn, useValues } from '@johnrom/formik-v3';
import { AddCircleOutline as AddCircleIcon } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryField, SimpleField } from '../fields';
import { getBaseAddressFieldNames } from './common-values';

export interface AddressFormPartProps {
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  name: string;
}

/**
 * Form prefab with address fields
 */
export const AddressFormPart = (props: AddressFormPartProps) => {
  const {
    required: requiredProp,
    disabled = false,
    readOnly = false,
    name,
  } = props;
  const { t } = useTranslation();

  const nameMap = getBaseAddressFieldNames(name);
  const values = useValues<Record<string, any>>();
  const [streetLines, setStreetLines] = useState(1);
  const hasStreet1 = !!getIn(values, nameMap.street1);
  const hasStreet2 = !!getIn(values, nameMap.street2);
  const hasStreet3 = !!getIn(values, nameMap.street3);
  const hasCity = !!getIn(values, nameMap.city);
  const hasCountry = !!getIn(values, nameMap.country);
  const minStreetLines = hasStreet3 ? 3 : hasStreet2 ? 2 : 1;
  useEffect(() => {
    if (streetLines < minStreetLines) setStreetLines(minStreetLines);
  }, [minStreetLines, streetLines]);

  const isRequired = requiredProp || hasStreet1 || hasCity || hasCountry;
  const requiredProps = useMemo(
    () =>
      isRequired
        ? {
            required: true,
          }
        : {},
    [isRequired]
  );

  return (
    <>
      <SimpleField
        name={nameMap.street1}
        label={
          streetLines === 1
            ? t('inputLabel.street')
            : t('inputLabel.streetLine1')
        }
        {...requiredProps}
        disabled={disabled}
        readOnly={readOnly}
      />
      {streetLines === 1 && (
        <Button
          disabled={disabled || readOnly}
          startIcon={<AddCircleIcon color="inherit" />}
          onClick={() => setStreetLines(2)}
          color="inherit"
        >
          {t('button.addStreetLine2')}
        </Button>
      )}
      {streetLines >= 2 && (
        <SimpleField
          name={nameMap.street2}
          label={t('inputLabel.streetLine2')}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {streetLines === 2 && (
        <Button
          disabled={disabled || readOnly}
          startIcon={<AddCircleIcon color="inherit" />}
          onClick={() => setStreetLines(3)}
          color="inherit"
        >
          {t('button.addStreetLine3')}
        </Button>
      )}
      {streetLines >= 3 && (
        <SimpleField
          name={nameMap.street3}
          label={t('inputLabel.streetLine3')}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SimpleField
            name={nameMap.city}
            label={t('inputLabel.city')}
            {...requiredProps}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <SimpleField
            name={nameMap.region}
            label={t('inputLabel.region')}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CountryField
            name={nameMap.country}
            label={t('inputLabel.country')}
            {...requiredProps}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <SimpleField
            name={nameMap.postal}
            label={t('inputLabel.postalCode')}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
    </>
  );
};
