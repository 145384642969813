import { QueryClient } from 'react-query';
import {
  isAuthenticationFailedError,
  isBusinessRulesFailedError,
  isMustChangePasswordError,
  isPermissionError,
  isResourceAlreadyExistsError,
  isResourceNotFoundError,
} from '../errors';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Retry up to 3 times
        if (failureCount >= 3) return false;

        // Don't retry certain errors that won't go away
        // Authentication, permission, and business rules errors
        if (isAuthenticationFailedError(error)) return false;
        if (isPermissionError(error)) return false;
        if (isMustChangePasswordError(error)) return false;
        if (isBusinessRulesFailedError(error)) return false;
        if (isResourceAlreadyExistsError(error)) return false;
        if (isResourceNotFoundError(error)) return false;

        return true;
      },
      refetchOnWindowFocus: false,
    },
  },
});
