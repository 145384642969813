import { MoreVertOutlined as MoreVertIcon } from '@mui/icons-material';
import { Divider, MenuItem } from '@mui/material';
import { Path } from 'history';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useCan, useLogout } from '../auth';
import { AuthState } from '../auth/types';
import {
  SetStagingToolsHiddenContext,
  StagingToolsHiddenContext,
} from '../devtools';
import MenuOpener from '../utils/MenuOpener';
import { useBreakpointDown } from '../utils/useBreakpoint';
import HeaderMenuButton from './ui/HeaderMenuButton';

export interface HeaderUserMenuProps {
  uid: AuthState['uid'];
  returnTo: Path;
}

/**
 * Simplify a UID (email) for smaller screens
 */
function simplifyUid(uid: string): string {
  if (!uid) return '';

  const name = uid.replace(/@.+$/, '');

  if (name.length > 10) {
    return name.substring(0, 8) + '…';
  }

  return name;
}

const HeaderUserMenu = (props: HeaderUserMenuProps) => {
  const { uid, returnTo } = props;
  const { t } = useTranslation();
  const can = useCan();
  const logout = useLogout();

  const stagingToolsHidden = useContext(StagingToolsHiddenContext);
  const setStagingToolsHidden = useContext(SetStagingToolsHiddenContext);

  const isXS = useBreakpointDown('sm');

  const displayName = isXS ? simplifyUid(uid ?? '') : uid;

  return (
    <MenuOpener
      button={
        <HeaderMenuButton icon={<MoreVertIcon />} sx={{ mr: -2 }}>
          {displayName}
        </HeaderMenuButton>
      }
      menuProps={{
        keepMounted: true,
        variant: 'menu',
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
      }}
    >
      <MenuItem component={Link} to="/user/profile" state={{ returnTo }}>
        {t('button.myProfile')}
      </MenuItem>
      {can.read('Webhooks') && (
        <MenuItem component={Link} to="/user/apikeys">
          {t('button.myApiKeys')}
        </MenuItem>
      )}
      <MenuItem component={Link} to="/user/changepassword">
        {t('button.changePassword')}
      </MenuItem>
      <MenuItem onClick={() => logout()}>{t('button.signOut')}</MenuItem>
      {process.env.REACT_APP_DEVTOOLS === 'true' && [
        <Divider key="divider" />,
        <MenuItem
          key="unhide-staging-tools"
          disabled={!stagingToolsHidden}
          onClick={() => setStagingToolsHidden(false)}
        >
          {t('button.unhideStagingTools')}
        </MenuItem>,
      ]}
    </MenuOpener>
  );
};

export default HeaderUserMenu;
