import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Authority } from '../../api';

/**
 * Provide localized labels for the Authority enum
 */
export function useAuthorityLabels(): Record<Authority, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<Authority, string> => ({
      AccountInformation: t('enumLabel.authority.accountInformation'),
      Admin: t('enumLabel.authority.admin'),
      ChangePassword: t('enumLabel.authority.changePassword'),
      ClientAdmin: t('enumLabel.authority.clientAdmin'),
      ClientReporting: t('enumLabel.authority.clientReporting'),
      Compliance: t('enumLabel.authority.compliance'),
      Payment: t('enumLabel.authority.payment'),
      PaymentOfficer: t('enumLabel.authority.paymentOfficer'),
      Reporting: t('enumLabel.authority.reporting'),
      SuperAdmin: t('enumLabel.authority.superAdmin'),
    }),
    [t]
  );
}
