import { ComplianceStatus } from '../../api';

/**
 * Severity color for compliance statuses
 */
export type ComplianceStatusSeverity =
  | 'neutral'
  | 'success'
  | 'warning'
  | 'failure';

const ComplianceStatusSeverityMap: Record<
  ComplianceStatus,
  ComplianceStatusSeverity
> = {
  [ComplianceStatus.Compliant]: 'success',
  [ComplianceStatus.NonCompliant]: 'failure',
  [ComplianceStatus.UnderReview]: 'neutral',
  [ComplianceStatus.NeedsReview]: 'neutral',
};

/**
 * Get the severity color for a compliance status
 */
export function getComplianceStatusSeverity(
  status: ComplianceStatus | undefined
): ComplianceStatusSeverity {
  if (!status) return 'neutral';

  return ComplianceStatusSeverityMap[status];
}
