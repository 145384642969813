import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { Button, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { forwardRef, ReactElement, Ref } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useFieldDisabled } from '../disabled';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';

const FileDropZone = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  }),
  {
    name: 'FileDropZone',
  }
);

export type FileValue<Multiple> = Multiple extends undefined | false
  ? File
  : File[];

export type FileFieldProps<Multiple extends boolean | undefined = undefined> =
  FieldHookConfig<FileValue<Multiple>> & {
    disabled?: boolean;
    multiple?: Multiple;
    accept?: string;
  } & CommonValidatorProps;

/**
 * Custom upload field with drag and drop support
 */
export const FileField = forwardRef(function FileField<
  Multiple extends boolean | undefined = undefined
>(props: FileFieldProps<Multiple>, ref: Ref<any>) {
  const { disabled: disabledProp = false, multiple = false, accept } = props;
  const validate = useCommonValidate(props);
  const disabled = useFieldDisabled(disabledProp);
  const [field, , { setValue }] = useField<any>({
    ...props,
    validate,
  });
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { name } = field;

  const { getRootProps, getInputProps, open } = useDropzone({
    disabled,
    multiple,
    accept,
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (files) => {
      setValue(multiple ? files : files[0]);
    },
    onDropRejected: (fileRejections) => {
      const message = multiple
        ? t('snackbar.invalidFilesRejected', {
            count: fileRejections.length,
          })
        : t('snackbar.invalidFile');
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  return (
    <FileDropZone ref={ref} {...getRootProps()}>
      <input name={name} {...getInputProps()} />
      <Typography component="p" paragraph>
        {multiple ? t('dropFilesHere') : t('dropFileHere')}
      </Typography>
      <Button color="primary" onClick={open}>
        {t('openFileDialog')}
      </Button>
    </FileDropZone>
  );
}) as <Multiple extends boolean | undefined = undefined>(
  props: FileFieldProps<Multiple>
) => ReactElement;
