import { styled } from '@mui/material';
import { forwardRef, SVGProps } from 'react';

const HorizontalLineRoot = styled('svg', {
  name: 'HorizontalLine',
  slot: 'Root',
})({
  userSelect: 'none',
  display: 'inline-block',
  fill: 'currentColor',
  stroke: 'currentColor',
  verticalAlign: 'middle',
});

export interface HorizontalLineProps extends SVGProps<SVGElement> {
  width: number | string;
  strokeThickness: number;
}

/**
 * A SVG based horizontal line
 */
export const HorizontalLine = forwardRef(function HorizontalLine(
  props: HorizontalLineProps,
  ref: any
) {
  const { width, strokeThickness, ...other } = props;

  return (
    <>
      <HorizontalLineRoot
        ref={ref}
        focusable={false}
        aria-hidden="true"
        {...other}
        width={width}
        height={`${strokeThickness * 2}px`}
        viewBox={`0 0 100 ${strokeThickness * 2}`}
        preserveAspectRatio="none"
      >
        <line
          y1={Math.floor(strokeThickness)}
          y2={Math.floor(strokeThickness)}
          x1={0}
          x2={100}
          strokeWidth={strokeThickness}
        />
      </HorizontalLineRoot>
    </>
  );
});
