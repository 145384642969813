import { ReactNode, useContext, useMemo } from 'react';
import { useResolvedPath } from 'react-router-dom';
import { BreadcrumbsContext } from './internal/context';
import { Breadcrumb } from './types';

export interface BreadcrumbProviderProps
  extends Pick<Breadcrumb, 'id' | 'title' | 'loading' | 'params'> {
  children?: ReactNode;
}

/**
 * Recursively provide a breadcrumb array
 */
export const BreadcrumbProvider = (props: BreadcrumbProviderProps) => {
  const { id, title, loading, params, children } = props;
  const parentBreadcrumbs = useContext(BreadcrumbsContext);
  const to = useResolvedPath('');
  const breadcrumb = useMemo<Breadcrumb>(
    () => ({
      id,
      to,
      title,
      loading,
      params,
    }),
    [id, loading, params, title, to]
  );
  const breadcrumbs = useMemo<Breadcrumb[]>(
    () => [...parentBreadcrumbs, breadcrumb],
    [breadcrumb, parentBreadcrumbs]
  );

  return (
    <BreadcrumbsContext.Provider value={breadcrumbs}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
