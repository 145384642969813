import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReviewStatus } from '../../api';

/**
 * Provide localized labels for the ReviewStatus enum
 */
export function useReviewStatusLabels(): Record<ReviewStatus, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ReviewStatus, string> => ({
      Approved: t('enumLabel.reviewStatus.approved'),
      InProgress: t('enumLabel.reviewStatus.inProgress'),
      Rejected: t('enumLabel.reviewStatus.rejected'),
      UnReviewed: t('enumLabel.reviewStatus.unReviewed'),
      WaitingOnClient: t('enumLabel.reviewStatus.waitingOnClient'),
    }),
    [t]
  );
}
