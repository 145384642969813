import { MenuItem, SelectProps, TextField } from '@mui/material';
import {
  forwardRef,
  isValidElement,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import { LoadingAdornment } from '../loading';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import { mapToOptions } from '../utils';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type SelectFieldProps<Value extends string = string> = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  Pick<SelectProps, 'displayEmpty'> &
  CommonValidatorProps & {
    readOnly?: boolean;
    /**
     * Label to include in a default ('') value option
     */
    defaultValueLabel?: ReactNode;
    /**
     * Display spinner indicating the options are loading
     */
    loading?: boolean;
    /**
     * Options to include in the select
     */
    options: Value[] | ReactNode[];
    /**
     * Get get a label for an option
     */
    getOptionLabel?: (value: Value) => string | undefined;
    /**
     * Get get a label for an option
     */
    renderOptionLabel?: (value: Value) => ReactNode | undefined;
  };

/**
 * A <Select> field
 * @fixme Switch `options` to use object arrays (maybe Autocomplete-like props) and update the *ToOptions to output that
 */
export const SelectField = forwardRef(function SelectField<
  Value extends string = string
>(props: SelectFieldProps<Value>, ref: Ref<HTMLInputElement>) {
  const {
    disabled = false,
    loading = false,
    defaultValueLabel,
    options: optionsProp,
    getOptionLabel,
    renderOptionLabel,
    displayEmpty,
    ...other
  } = usePropsWithId(props);
  const validate = useCommonValidate(props);

  const isFirstLoading = loading && !(optionsProp && optionsProp.length > 0);

  let options: ReactNode[] = optionsProp;
  if ((getOptionLabel || renderOptionLabel) && optionsProp) {
    const valueOptions: Value[] = optionsProp as Value[];
    options = mapToOptions(valueOptions, {
      getOptionValue: (value) => value,
      getOptionLabel: renderOptionLabel || getOptionLabel,
    });
  }

  const textFieldProps = useTextFieldProps({
    ...other,
    validate,
    select: !isFirstLoading,
    disabled: disabled || isFirstLoading,
    InputLabelProps: {
      shrink: displayEmpty,
      ...other.InputLabelProps,
    },
    InputProps: loading
      ? {
          ...other.InputProps,
          endAdornment: <LoadingAdornment />,
        }
      : other.InputProps,
    SelectProps: {
      displayEmpty,
      ...other.SelectProps,
    },
  });

  if (props.readOnly) {
    const option = options?.find((option) =>
      typeof option === 'string'
        ? option === textFieldProps.value
        : isValidElement(option)
        ? option?.props?.value === textFieldProps.value
        : false
    );
    return (
      <ReadOnlyField
        ref={ref}
        {...textFieldProps}
        children={
          typeof option === 'string'
            ? option
            : isValidElement(option)
            ? option?.props?.children
            : ''
        }
      />
    );
  }

  return (
    <TextField ref={ref} {...textFieldProps}>
      {defaultValueLabel && <MenuItem value="">{defaultValueLabel}</MenuItem>}
      {options}
    </TextField>
  );
}) as <Value extends string = string>(
  props: SelectFieldProps<Value>
) => ReactElement;
