import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type JurisdictionFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a jurisdiction
 */
export const JurisdictionField = forwardRef(function JurisdictionField(
  props: JurisdictionFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [jurisdictions, loadingJurisdictions] = useCodes('jurisdictions');
  const optionProps = useCodeOptionsProps(jurisdictions);

  return (
    <SelectField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingJurisdictions}
    />
  );
});
