import { forwardRef } from 'react';
import { ComplianceFrequency } from '../../../api';
import { useComplianceFrequencyLabels } from '../../../intl/enum/complianceFrequency';
import { useEnumOptions } from '../../utils';
import { TagArrayField, TagArrayFieldProps } from '../TagArrayField';

export type ComplianceFrequencyArrayFieldProps = Omit<
  TagArrayFieldProps<ComplianceFrequency, false>,
  'options'
>;

/**
 * A Tag array combobox field for selecting a list of Authorities
 */
export const ComplianceFrequencyArrayField = forwardRef(
  function ComplianceFrequencyArrayField(
    props: ComplianceFrequencyArrayFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const optionsProps = useEnumOptions(
      ComplianceFrequency,
      useComplianceFrequencyLabels()
    );

    return <TagArrayField ref={ref} {...other} {...optionsProps} />;
  }
);
