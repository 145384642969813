import { forwardRef } from 'react';
import { ContactPhoneNumberType } from '../../../api';
import { useContactPhoneNumberTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ContactPhoneNumberTypeFieldProps = Omit<
  SelectFieldProps,
  'options'
>;

/**
 * A <Select> field for selecting a ContactPhoneNumberType
 */
export const ContactPhoneNumberTypeField = forwardRef(
  function ContactPhoneNumberTypeField(
    props: ContactPhoneNumberTypeFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const optionsProps = useEnumOptions(
      ContactPhoneNumberType,
      useContactPhoneNumberTypeLabels()
    );

    return <SelectField ref={ref} {...other} {...optionsProps} />;
  }
);
