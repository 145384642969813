import { SvgIcon, SvgIconProps } from '@mui/material';
import { DoneOutlined as DoneIcon } from '@mui/icons-material';
import {
  AccountClockOutline as AccountClockIcon,
  CircleSmall as CircleSmallIcon,
  CloseCircleOutline as CloseCircleIcon,
  ProgressClock as ProgressClockIcon,
  TextBoxSearchOutline as TextBoxSearchIcon,
} from 'mdi-material-ui';
import { cloneElement, forwardRef, ReactElement } from 'react';
import { ReviewStatus } from '../../api';

export interface ReviewStatusIconProps {
  status: ReviewStatus | undefined;
  fontSize?: SvgIconProps['fontSize'];
}

const FallbackReviewStatusIcon = (
  <CircleSmallIcon sx={{ color: 'text.secondary', display: 'flex' }} />
);
const ReviewStatusIcons: Record<ReviewStatus, ReactElement> = {
  [ReviewStatus.Approved]: (
    <DoneIcon sx={{ color: 'success.main', display: 'flex' }} />
  ),
  [ReviewStatus.Rejected]: (
    <CloseCircleIcon sx={{ color: 'error.main', display: 'flex' }} />
  ),
  [ReviewStatus.InProgress]: (
    <ProgressClockIcon sx={{ color: 'text.secondary', display: 'flex' }} />
  ),
  [ReviewStatus.UnReviewed]: (
    <TextBoxSearchIcon sx={{ color: 'primary.main', display: 'flex' }} />
  ),
  [ReviewStatus.WaitingOnClient]: (
    <AccountClockIcon sx={{ color: 'warning.main', display: 'flex' }} />
  ),
};

/**
 * Icon for a review status
 */
export const ReviewStatusIcon = forwardRef(function ReviewStatusIcon(
  props: ReviewStatusIconProps,
  ref: any
) {
  const { status, ...other } = props;

  if (!status) return cloneElement(FallbackReviewStatusIcon, { ...other, ref });

  return cloneElement(ReviewStatusIcons[status], { ...other, ref });
});
(ReviewStatusIcon as any).muiName = (SvgIcon as any).muiName;
