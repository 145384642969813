import { styled } from '@mui/material';
import { ComponentProps } from 'react';

const PageBannerRoot = styled('div', { name: 'PageBanner', slot: 'Root' })(
  ({ theme }) => ({
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  })
);

export type PageBannerProps = ComponentProps<typeof PageBannerRoot>;

export const PageBanner = (props: PageBannerProps) => (
  <PageBannerRoot {...props} />
);
