import { AutocompleteRenderOptionState } from '@mui/material';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';

/**
 * Map an options (option values) array
 */
export function getOptions<T extends unknown, V extends string = string>(
  rawOptions: T[] | undefined | null,
  getValue: (rawOption: T) => V
): V[] {
  return rawOptions?.map((rawOption) => getValue(rawOption)) ?? [];
}

/**
 * Get an options (option values) array (memoized)
 */
export function useOptions<T extends unknown, V extends string = string>(
  rawOptions: T[] | undefined | null,
  getValue: (rawOption: T) => V
): V[] {
  return useMemo(
    () => getOptions(rawOptions, (rawOption) => getValue(rawOption)),
    [getValue, rawOptions]
  );
}

/**
 * Get a function that given an option value will output its label
 */
export function useOptionLabel<T extends unknown>(
  rawOptions: T[] | undefined | null,
  getValue: (rawOption: T) => string,
  getLabel: (rawOption: T) => string | undefined | null,
  getUnknownLabel: (option: string) => string | undefined | null = () => ''
) {
  return useMemo(() => {
    const labels = new Map(
      rawOptions?.map((rawOption) => [
        getValue(rawOption),
        getLabel(rawOption) ?? '',
      ])
    );
    return (id: string): string =>
      labels.has(id) ? labels.get(id) ?? '' : getUnknownLabel(id) ?? '';
  }, [getLabel, getUnknownLabel, getValue, rawOptions]);
}

/**
 * Get a function that given an option value will output its label
 */
export function useRenderOption<T extends unknown>(
  rawOptions: T[] | undefined | null,
  getValue: (rawOption: T) => string,
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement>,
    rawOption: T,
    state: AutocompleteRenderOptionState
  ) => ReactNode,
  renderUnknownOption: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: string,
    state: AutocompleteRenderOptionState
  ) => ReactNode = () => ''
) {
  return useMemo(() => {
    const options = new Map(
      rawOptions?.map((rawOption) => [getValue(rawOption), rawOption])
    );
    return (
      props: React.HTMLAttributes<HTMLLIElement>,
      id: string,
      state: AutocompleteRenderOptionState
    ): ReactNode =>
      options.has(id)
        ? renderOption(props, options.get(id) as T, state)
        : renderUnknownOption(props, id, state);
  }, [getValue, rawOptions, renderUnknownOption, renderOption]);
}
