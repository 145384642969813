import { forwardRef } from 'react';
import { QualityOfService } from '../../../api';
import { useQualityOfServiceLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type QualityOfServiceFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a QualityOfService
 */
export const QualityOfServiceField = forwardRef(function QualityOfServiceField(
  props: QualityOfServiceFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    QualityOfService,
    useQualityOfServiceLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
