import { FadeProps, LinearProgressProps } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { Map } from 'immutable';
import ms from 'ms';
import { useCallback } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import useConstant from 'use-constant';
import { v1 as uuid1 } from 'uuid';
import { createReducerContext } from '../reducer-context/createReducerContext';
import { useDelayedToggle } from '../utils/useDelayedToggle';

interface DownloadProgress {
  progress: number;
}

type DownloadProgressAction =
  | {
      id: string;
      type: 'PROGRESS';
      progress: number;
    }
  | {
      id: string;
      type: 'DELETE';
    };

const {
  useReducerDispatch: useDownloadProgressDispatch,
  useReducerState: useDownloadState,
  Provider: DownloadProgressProvider,
} = createReducerContext(
  'DownloadProgress',
  (state: Map<string, DownloadProgress>, action: DownloadProgressAction) => {
    if (action.type === 'PROGRESS') {
      state = state.set(action.id, { progress: action.progress });
    } else if (action.type === 'DELETE') {
      state = state.delete(action.id);
    }

    return state;
  },
  Map<string, DownloadProgress>()
);

export { DownloadProgressProvider };

/**
 * Get helpers to track the progress of an API query as download progress
 */
export function useDownloadProgress(): AxiosRequestConfig {
  const dispatch = useDownloadProgressDispatch();

  const id = useConstant(() => uuid1());
  const onDownloadProgress = useCallback(
    (e: ProgressEvent) => {
      if (e.lengthComputable && e.loaded < e.total) {
        dispatch({
          id,
          type: 'PROGRESS',
          progress: e.loaded / e.total,
        });
      } else {
        dispatch({
          id,
          type: 'DELETE',
        });
      }
    },
    [dispatch, id]
  );

  return {
    onDownloadProgress,
  };
}

/**
 * Get the current progress of the lest downloaded query
 */
function useSmallestDownloadProgress(): DownloadProgress | undefined {
  const downloadState = useDownloadState();

  return downloadState.reduce<DownloadProgress | undefined>((a, b) => {
    if (!a) return b;
    if (b.progress < a.progress) return b;
    return a;
  });
}

/**
 * Helper that returns <LinearProgress> properties for
 */
export function useQueryProgress(): Pick<FadeProps, 'in'> &
  Pick<LinearProgressProps, 'variant' | 'value' | 'valueBuffer'> {
  let inProp: FadeProps['in'] = false;
  let variant: LinearProgressProps['variant'] = 'indeterminate';
  let value: LinearProgressProps['value'] = 0;

  const fetching = useDelayedToggle(
    useIsFetching({
      predicate: (query) => {
        const rootKey = Array.isArray(query.queryKey)
          ? query.queryKey[0]
          : query.queryKey;

        // Don't show progress bars for periodic notification fetching
        if (rootKey === 'NotificationsApiGetAllNotifications') {
          return false;
        }

        return true;
      },
    }) > 0,
    {
      // Only enable indeterminate progress when querries have been running for over 1s
      // https://www.nngroup.com/articles/response-times-3-important-limits/
      delay: ms('1 second'),
    }
  );
  const mutating = useDelayedToggle(useIsMutating() > 0, {
    // Only enable indeterminate progress when mutations have been running for over .6s
    // https://www.nngroup.com/articles/response-times-3-important-limits/
    delay: ms('0.6 second'),
  });

  const downloadProgress = useSmallestDownloadProgress();

  if (downloadProgress) {
    inProp = true;
    variant = 'determinate';
    value = downloadProgress.progress * 100;
  } else if (mutating) {
    inProp = true;
    variant = 'indeterminate';
  } else if (fetching) {
    inProp = true;
    variant = 'query';
  }

  return {
    in: inProp,
    variant,
    value,
  };
}
