import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/es/hooks';
import useHtmlId from '../utils/useHtmlId';
import NotificationPopover from './NotificationPopover';
import { NotificationButton } from './ui';
import { useMarkNotificationsAsRead } from './utils';
import { useNotifications } from './utils/useNotifications';
import { useState } from 'react';

export interface NotificationsProps {
  name?: string;
}

/**
 * Notifications button and popover
 */
export const Notifications = (props: NotificationsProps) => {
  const { name } = props;
  const popupId = useHtmlId(name);
  const popupState = usePopupState({ variant: 'popover', popupId });

  const [currentTab, onChangeTab] = useState('unread');
  const { notifications, unreadCount, loading, error } = useNotifications({
    isRead: currentTab === 'unread' ? false : undefined,
  });
  const markAsRead = useMarkNotificationsAsRead();

  return (
    <>
      <NotificationButton
        notificationCount={unreadCount}
        loading={loading}
        error={!!error}
        {...bindTrigger(popupState)}
      />
      <NotificationPopover
        {...bindPopover(popupState)}
        {...{
          currentTab,
          onChangeTab,
          notifications,
          unreadCount,
          loading,
          error: !!error,
          markAsRead,
        }}
      />
    </>
  );
};
