import { forwardRef } from 'react';
import { EventType } from '../../../api';
import { useEventTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type EventTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a EventType
 */
export const EventTypeField = forwardRef(function EventTypeField(
  props: EventTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(EventType, useEventTypeLabels());

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
