import { Paper, PaperProps, styled } from '@mui/material';
import { surfaceElevation } from '../../theme';

const OverviewSheetRoot = styled(Paper, {
  name: 'OverviewSheet',
  slot: 'Root',
})(({ theme }) => ({
  padding: theme.spacing(2),
}));

export type OverviewSheetProps = Omit<PaperProps, 'variant'>;

/**
 * A common UI component for an overview sheet on a page with a paper background and standard padding
 */
export const OverviewSheet = (props: OverviewSheetProps) => {
  const { children, elevation = surfaceElevation, ...other } = props;

  return (
    <OverviewSheetRoot elevation={elevation} {...other}>
      {children}
    </OverviewSheetRoot>
  );
};
