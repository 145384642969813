import { IconButton, IconButtonProps } from '@mui/material';
import { CloseOutlined as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/**
 * IconButton for dismissing things
 */
export const DismissIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton aria-label={t('button.dismiss')} color="primary" {...props}>
      <CloseIcon />
    </IconButton>
  );
};
