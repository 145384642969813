import { forwardRef, ReactElement, Ref } from 'react';
import ListControl from '../controls/ListControl';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import {
  useListFieldProps,
  UseListFieldProps,
} from './internal/useListFieldProps';

export type SelectListFieldProps<Option = unknown> = Omit<
  UseListFieldProps<Option>,
  'ref'
> &
  CommonValidatorProps;

/**
 * A <ListControl> allowing you to search and select from a list of options
 */
export const SelectListField = forwardRef(function SelectListField<
  Option extends unknown = unknown
>(props: SelectListFieldProps<Option>, ref: Ref<any>) {
  const validate = useCommonValidate(props);
  const fieldProps = useListFieldProps({
    ...props,
    validate,
  });

  return (
    <ListControl
      ref={ref}
      margin="dense" // theme
      fullWidth // theme
      filterOptions={{
        ignoreAccents: true,
        ignoreCase: true,
        matchFrom: 'any',
      }}
      {...fieldProps}
    />
  );
}) as <Option extends unknown = unknown>(
  props: SelectListFieldProps<Option>
) => ReactElement;
