import { styled } from '@mui/material';

export const ImageRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  { name: 'ImageRoot' }
);
export interface ImageViewerProps {
  name: string;
  src: string;
}

/**
 * Viewer for images
 */
export const ImageViewer = (props: ImageViewerProps) => {
  const { name, src } = props;

  return (
    <ImageRoot>
      <img src={src} alt={name} />
    </ImageRoot>
  );
};
