import { ReactNode, useMemo } from 'react';

export interface MessageFormatterProps {
  message: string | undefined | null;
}

/**
 * Formatter for displaing a basic user message/comment with very basic formatting (just line breaks)
 */
const MessageFormatter = (props: MessageFormatterProps) => {
  const { message } = props;

  return useMemo(() => {
    if (!message) return null;

    const paragraphs = message
      .trim()
      .split(/\n[ \t]*\n/g)
      .map((paragraph, index) => {
        const lines: ReactNode[] = [];
        let first = true;
        for (const line of paragraph.split(/\n/g)) {
          if (!first) lines.push(<br key={`${index}-br`} />);
          lines.push(line);
          first = false;
        }

        return <p key={index}>{lines}</p>;
      });

    return <>{paragraphs}</>;
  }, [message]);
};

export default MessageFormatter;
