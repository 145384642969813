import EnumChip from '../../ui/EnumChip';

export interface ChipRendererProps<V extends unknown> {
  value: V;
  formatValue?: (value: V) => string;
  refData?: Record<string, string>;
  colorRefData?: Record<string, string>;
}

/**
 * Cell renderer that displays a chip with the value
 */
export const ChipRenderer = <V extends unknown>(
  props: ChipRendererProps<V>
) => {
  const { value, formatValue: formatValueProp, refData, colorRefData } = props;

  let formatValue = formatValueProp;
  if (!formatValue) {
    if (refData) {
      formatValue = (value) => refData[String(value)] ?? value;
    } else {
      formatValue = (value) => String(value);
    }
  }

  if (!value) return null;
  return (
    <EnumChip
      value={value as string}
      label={formatValue(value)}
      colorRefData={colorRefData}
    />
  );
};
