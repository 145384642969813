import { FieldHookConfig, useField, useIsSubmitting } from '@johnrom/formik-v3';
import {
  AutocompleteInputChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  CircularProgress,
  styled,
  TextFieldProps,
} from '@mui/material';
import { AutocompleteValue } from '@mui/material/useAutocomplete';
import { createElement, useCallback } from 'react';
import { useFieldDisabled } from '../../disabled';

const AutocompleteEndAdornment = styled('div')(
  ({ theme }) => ({
    // We use a position absolute to support wrapping tags.
    position: 'absolute',
    right: theme.spacing(1),
    top: 'calc(50% - 14px)', // Center vertically
  }),
  { name: 'AutocompleteEndAdornment' }
);

export type UseAutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<
  FieldHookConfig<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>,
  'value'
> &
  Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'name' | 'value' | 'defaultValue' | 'renderInput'
  > &
  Pick<TextFieldProps, 'helperText'> & {
    onChangeValue?: (
      value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
    ) => void;
    /**
     * Label to include in a default (null) value option
     */
    defaultValueLabel?: string;
  };

export interface UseAutocompleteTextFieldResult<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> {
  /**
   * Props for <Autocomplete>
   */
  getAutocompleteProps: () => Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  >;
  /**
   * Props for a <TextField> inside `renderInput`
   */
  getTextFieldProps: (params: AutocompleteRenderInputParams) => TextFieldProps;
}

/**
 * useField wrapper that includes fixed variants of formik-material-ui's fieldToAutocomplete customized for an Autocomplete using TextField
 */
export function useAutocompleteTextFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: UseAutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>
): UseAutocompleteTextFieldResult<T, Multiple, DisableClearable, FreeSolo> {
  const {
    ref,
    name: _name,
    disabled: disabledProp,
    helperText,
    type,
    validate,
    options,
    loading,
    onInputChange: onInputChangeProp,
    onChangeValue,
    defaultValueLabel,
    ...other
  } = props;
  const isSubmitting = useIsSubmitting();
  const disabled = useFieldDisabled(disabledProp);

  const [
    { multiple: _multiple, name, onChange: _onChange, value, ...field },
    meta,
    { setValue },
  ] = useField<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>({
    name: _name,
    validate,
    ...other,
    as: undefined,
  });
  // Replace onChange with one aware of Autocomplete's second arg
  const onChange = useCallback(
    (e, value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>) => {
      setValue(value);
      onChangeValue?.(value);
    },
    [onChangeValue, setValue]
  );
  const onInputChange = useCallback(
    (e, value: string, reason: AutocompleteInputChangeReason) => {
      // @note This was attempted, but isn't currently possible as updating the value seems to reset the input
      // if (other.freeSolo) {
      //   setValue(value as any);
      // }
      if (onInputChangeProp) {
        onInputChangeProp(e, value, reason);
      }
    },
    [onInputChangeProp]
  );

  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  return {
    getAutocompleteProps: () => ({
      disabled: disabled || isSubmitting,
      ...field,
      // @note If options are not loaded don't set the value,
      //       otherwise MUI will not reset inputValue when options load
      value:
        (options?.length ?? 0) !== 0 || !loading
          ? value
          : other.multiple
          ? []
          : (null as any),
      options,
      loading,
      ...other,
      onChange,
      onInputChange,
    }),
    getTextFieldProps: (params) => ({
      name,
      error: showError,
      helperText: showError ? fieldError : helperText,
      ...params,
      placeholder: defaultValueLabel,
      InputLabelProps: defaultValueLabel
        ? { ...params.InputLabelProps, shrink: true }
        : params.InputLabelProps,
      InputProps: loading
        ? {
            ...params.InputProps,
            endAdornment: createElement(
              AutocompleteEndAdornment,
              {},
              createElement(CircularProgress, { size: 24 })
            ),
          }
        : params.InputProps,
    }),
  };
}
