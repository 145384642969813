export interface ExtractedIban {
  countryCode: string;
  checksum: string;
  bban: string;
}

/**
 * Separate the countryCode, checksum, and bban from an IBAN
 * IBAN must be pre-formated using electronicFormatIBAN with no separator
 */
export function extractElectronicIBAN(iban: string): ExtractedIban | null {
  const m = /^([A-Z]{2})(\d{2})(\w+)$/.exec(iban);
  if (!m) return null;

  return {
    countryCode: m[1],
    checksum: m[2],
    bban: m[3],
  };
}
