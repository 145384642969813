import invariant from 'invariant';
import { useTranslation } from 'react-i18next';
import { useLocale } from '.';

/**
 * Use Intl.getCanonicalLocales to normalize a single locale
 */
const getCanonicalLocale = (locale: string): string => {
  const locales = Intl.getCanonicalLocales([locale]);
  invariant(locales.length > 0, 'expected a valid locale');
  return locales[0];
};

/**
 * Get an `Accept-Language` header for the current locale
 * - Locale from useLocale is highest priority
 * - Locale from i18next is second highest priority
 * - Other locales in navigator.languages are added as lowest priority
 */
export function useAcceptLanguage() {
  const locale = getCanonicalLocale(useLocale());
  const { i18n } = useTranslation();
  const messageLocale = getCanonicalLocale(i18n.language);
  const [userMainLocale, ...userSecondaryLocales] = Intl.getCanonicalLocales(
    navigator.languages ? navigator.languages.slice() : [navigator.language]
  );

  const acceptsLanguage: [locale: string, q: number][] = [];
  // Current locale as top priority (1.0)
  acceptsLanguage.push([locale, 1.0]);
  // If it's different, message locale as secondary priority (0.9)
  if (messageLocale !== locale) {
    acceptsLanguage.push([messageLocale, 0.9]);
  }
  // If the user's preferred language is different, give it a higher priority than the others
  if (
    userMainLocale &&
    userMainLocale !== locale &&
    userMainLocale !== messageLocale
  ) {
    acceptsLanguage.push([userMainLocale, 0.8]);
  }
  // Give the remaining locales a lower priority
  for (const userLocale of userSecondaryLocales) {
    if (userLocale === locale || userLocale === messageLocale) continue;
    acceptsLanguage.push([userLocale, 0.5]);
  }
  // Allow any language with the lowest priority
  acceptsLanguage.push(['*', 0.1]);

  // Format the Accept-Language header
  const acceptsLanguageHeader = acceptsLanguage
    .map(([locale, q]) => (q === 1.0 ? locale : `${locale};q=${q.toFixed(1)}`))
    .join(',');

  return acceptsLanguageHeader;
}
