// eslint-disable-next-line no-restricted-imports
import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { forwardRef, useCallback } from 'react';
import { Attachment } from '../../api';
import { useFieldDisabled } from '../../form';
import { CommonValidatorProps } from '../../form/fields/internal/useCommonValidate';
import { ContractDocumentCard, ContractDocumentCardProps } from '../ui';

export type ContractDocumentFieldProps = FieldHookConfig<
  Attachment | undefined
> &
  CommonValidatorProps & {
    orgId?: string | undefined | null;
    label: ContractDocumentCardProps['label'];
    noDocumentLabel: ContractDocumentCardProps['noDocumentLabel'];
    disabled?: boolean;
    readOnly?: boolean;
    /**
     * Should the user be able to upload an original contract, or just replace an unsigned one
     */
    canUploadOriginal?: boolean;
  };

export const ContractDocumentField = forwardRef(function ContractDocumentField(
  props: ContractDocumentFieldProps,
  ref: any
) {
  const {
    orgId,
    label,
    noDocumentLabel,
    disabled: disabledProp,
    validate,
    readOnly,
    canUploadOriginal = false,
  } = props;
  const disabled = useFieldDisabled(disabledProp);
  const [{ value }, , { setValue }] = useField<Attachment | undefined>({
    validate,
    ...props,
  });

  // @todo Should we lock the upload button for clients after it has already been approved? How?
  const canUpload = !!(canUploadOriginal || value);

  const upload = useCallback(
    (attachment: Attachment) => {
      setValue(attachment);
    },
    [setValue]
  );

  return (
    <ContractDocumentCard
      ref={ref}
      orgId={orgId}
      label={label}
      noDocumentLabel={noDocumentLabel}
      attachment={value}
      readOnly={readOnly || disabled || !canUpload}
      onUpload={upload}
    />
  );
});
