import { useFieldHelpers } from '@johnrom/formik-v3';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactAddressType, LegalAddressType } from '../../api';
import { DoneIconButton, EditIconButton, RemoveIconButton } from '../buttons';
import { AddressCard, AddressCardProps } from '../cards';
import { useFieldDisabled } from '../disabled';
import { ContactAddressTypeField, LegalAddressTypeField } from '../fields';
import { FieldSet, FieldSetButtons } from '../layout';
import { AddressValues } from '../prefabs';
import { usePartialSubmitFor } from '../utils';
import { AddressFormPart } from './AddressFormPart';
import {
  addressToValues,
  getTypedAddressFieldNames,
} from './common-values/address';

export const emptyAddressValues: AddressValues<any> = {
  editing: true,
  type: '',
  ...addressToValues(undefined),
};

export interface EditableLegalAddressCardPrefabProps {
  variant: 'legal';
  address?: AddressValues<LegalAddressType>;
}

export interface EditableContactAddressCardPrefabProps {
  variant: 'contact';
  address?: AddressValues<ContactAddressType>;
}

export type EditableAddressCardPrefabProps = {
  variant: AddressCardProps['variant'];
  name: string;
  onRemove?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
} & (
  | EditableLegalAddressCardPrefabProps
  | EditableContactAddressCardPrefabProps
);

/**
 * "Editable" address that switches between card and prefab form formats
 */
export const EditableAddressCardPrefab = (
  props: EditableAddressCardPrefabProps
) => {
  const {
    variant,
    name,
    address,
    onRemove,
    disabled: disabledProp = false,
    readOnly,
  } = props;
  const { t } = useTranslation();
  const { setValue: setEditing } = useFieldHelpers(`${name}.editing`);
  const disabled = useFieldDisabled(disabledProp);

  const partialSubmit = usePartialSubmitFor(
    useMemo(() => getTypedAddressFieldNames(name), [name])
  );

  if (address?.editing && !disabled) {
    return (
      <FieldSet title={t('title.editEddress')}>
        {variant === 'legal' && (
          <LegalAddressTypeField
            name={`${name}.type`}
            label={t('inputLabel.type')}
            required
          />
        )}
        {variant === 'contact' && (
          <ContactAddressTypeField
            name={`${name}.type`}
            label={t('inputLabel.type')}
            required
          />
        )}

        <AddressFormPart
          name={name}
          required
          disabled={disabled}
          readOnly={readOnly}
        />

        {!disabled && (
          <FieldSetButtons>
            {onRemove && <RemoveIconButton onClick={() => onRemove()} />}
            <DoneIconButton
              onClick={async () => {
                if (await partialSubmit()) {
                  setEditing(false);
                }
              }}
            />
          </FieldSetButtons>
        )}
      </FieldSet>
    );
  }

  return (
    <AddressCard
      variant={variant}
      address={address as any}
      actions={
        !disabled && (
          <>
            {onRemove && <RemoveIconButton onClick={() => onRemove()} />}
            <EditIconButton onClick={() => setEditing(true)} />
          </>
        )
      }
    />
  );
};
