import { useAuthState } from '../../../auth';
import { ApiHookOptions } from '../types';

/**
 * Get the current access token
 *
 * Skips returning the auth code if the auth=NONE option is used.
 */
export function useAccessToken(
  auth: ApiHookOptions['auth']
): string | undefined {
  const { token: rawAuthToken } = useAuthState();
  auth = auth ?? 'AUTHENTICATED';

  if (auth !== 'NONE' && rawAuthToken) {
    return rawAuthToken;
  }

  return undefined;
}
