import { Collapse, Popper, styled, Typography } from '@mui/material';
import {
  bindHover,
  bindPopper,
  PopupState,
  usePopupState,
} from 'material-ui-popup-state/es/hooks';
import { Children, cloneElement, isValidElement } from 'react';
import useHtmlId from '../../utils/useHtmlId';
import SidebarNavSection, {
  SidebarNavSectionProps,
} from '../ui/SidebarNavSection';

const SidebarListSubnavHoverGuard = styled('div', {
  name: 'SidebarListSubnav',
  slot: 'HoverGuard',
})(({ theme }) => ({
  // On some pages <Grid> or another page element may invisibly overlap the sidebar resulting in a gap
  // in between the sidebar button and menu popup where the page overlaps it and results in a mouseout event
  // This guard hovers over any potential gap ensuring the mouse stays within the button/popover while moving between the two
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: theme.spacing(-(3 + 3 * 2)),
  height: 48,
}));

const SidebarListSubnavPaper = styled('div', {
  name: 'SidebarListSubnav',
  slot: 'Paper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(-1),
  '@media (pointer: fine)': {
    marginTop: theme.spacing(1.5),
  },
}));

const SidebarListSubnavCollapse = styled(Collapse, {
  name: 'SidebarListSubnav',
  slot: 'Collapse',
})(({ theme }) => ({
  '& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner': {
    marginBottom: theme.spacing(1.5),
  },
})) as typeof Collapse;

export type SidebarListSubnavProps = SidebarNavSectionProps & {
  sidebarExpanded?: boolean;
  sectionName?: string;
  expanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
  disableExpansion?: boolean;
};

function bindSidebarHover(popupState: PopupState) {
  // This can help debugging
  // return bindTrigger(popupState);

  return {
    ...bindHover(popupState),
  };
}
function bindPopperHover(popupState: PopupState) {
  return {
    ...bindPopper(popupState),
  };
}

/**
 * Sidebar navigation list item with subnav items
 */
const SidebarListSubnav = (props: SidebarListSubnavProps) => {
  const {
    sidebarExpanded,
    sectionName,
    expanded,
    onExpandedChange,
    disableExpansion = false,
    children,
    active = false,
    to,
    ...itemProps
  } = props;
  const collapseId = useHtmlId(sectionName);
  const popupId = useHtmlId(sectionName);
  const popupState = usePopupState({ variant: 'popover', popupId });

  return (
    <>
      <SidebarNavSection
        collapseIcon={sidebarExpanded}
        selected={popupState.isOpen}
        {...itemProps}
        {...(sidebarExpanded ? { to } : bindSidebarHover(popupState))}
        active={active}
        expandButton={
          disableExpansion
            ? undefined
            : {
                enabled: sidebarExpanded,
                'aria-controls': collapseId,
                expanded,
                onExpandedChange,
              }
        }
      />
      <SidebarListSubnavCollapse
        id={collapseId}
        in={expanded && sidebarExpanded}
      >
        {Children.map(
          children,
          (child) =>
            isValidElement(child) &&
            cloneElement(child, {
              variant: 'list',
            })
        )}
      </SidebarListSubnavCollapse>
      <Popper
        {...bindPopperHover(popupState)}
        placement="right-start"
        onClick={popupState.close}
        sx={{ zIndex: (theme) => theme.zIndex.modal }}
      >
        <SidebarListSubnavHoverGuard />
        <SidebarListSubnavPaper>
          <Typography component="span" variant="subtitle2" sx={{ px: 1 }}>
            {itemProps.primary}
          </Typography>
          {Children.map(
            children,
            (child) =>
              isValidElement(child) &&
              cloneElement(child, {
                variant: 'menu',
              })
          )}
        </SidebarListSubnavPaper>
      </Popper>
    </>
  );
};

export default SidebarListSubnav;
