import jsFileDownload from 'js-file-download';

/**
 * Download a client generated file
 */
export default function download(
  payload: string,
  { filename, mimeType }: { filename: string; mimeType: string }
): boolean {
  jsFileDownload(payload, filename, mimeType);
  return true;
}
