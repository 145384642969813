import { FilterAltOutlined as FilterIcon } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type SearchOptionsButtonProps = Omit<
  ButtonProps,
  'variant' | 'onClick' | 'onChange'
> & {
  open?: boolean;
  onChange?: (open: boolean) => void;
  filterCount?: number;
};

/**
 * "Search Options" button for table pages
 */
export const SearchOptionsButton = (props: SearchOptionsButtonProps) => {
  const { open = false, onChange, filterCount = 0, ...other } = props;
  const { t } = useTranslation();

  return (
    <Button
      {...other}
      variant="outlined"
      onClick={() => onChange?.(!open)}
      endIcon={<FilterIcon />}
    >
      {filterCount > 0
        ? t('searchOptionsCount', { total: filterCount })
        : t('searchOptions')}
    </Button>
  );
};
