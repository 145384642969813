import { SearchOutlined as SearchIcon } from '@mui/icons-material';
import { IconButton, Paper, Popper, styled, Tooltip } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useHtmlId from '../../utils/useHtmlId';
import { DataTableQuickSearchBox } from './DataTableQuickSearchBox';

interface OwnerState {}

const DataTableQuickSearchRoot = styled('div', {
  name: 'DataTableQuickSearch',
  slot: 'Root',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  position: 'relative',
}));

export interface DataTableQuickSearchProps {
  className?: string;
  value: string;
  onChange: (quickSearch: string) => void;
  matchIndex?: number;
  matchTotal?: number;
  onPrevMatch?: () => void;
  onNextMatch?: () => void;
}

/**
 * UI for doing a quick search of a table
 */
export let DataTableQuickSearch = (props: DataTableQuickSearchProps) => {
  const {
    value,
    onChange,
    matchIndex,
    matchTotal,
    onPrevMatch,
    onNextMatch,
    className,
  } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const id = useHtmlId();
  const popperId = `${id}-popper`;

  const ownerState: OwnerState = {};

  const onClear = useCallback(() => {
    onChange('');
    setOpen(false);
  }, [onChange]);

  return (
    <DataTableQuickSearchRoot ownerState={ownerState} className={className}>
      <Tooltip title={t('button.quickSearch') as string} disableInteractive>
        <span>
          <IconButton
            component="button"
            ref={setAnchorEl}
            onClick={() => {
              setOpen(true);
            }}
            aria-label={t('button.quickSearch')}
            disabled={open}
          >
            <SearchIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Popper
        id={popperId}
        anchorEl={anchorEl}
        open={open}
        placement="bottom-start"
        sx={{
          zIndex: (theme) => theme.zIndex.modal,
        }}
      >
        <Paper elevation={1}>
          <DataTableQuickSearchBox
            {...{
              value,
              onChange,
              onClear,
              matchIndex,
              matchTotal,
              onPrevMatch,
              onNextMatch,
            }}
          />
        </Paper>
      </Popper>
    </DataTableQuickSearchRoot>
  );
};
DataTableQuickSearch = memo(DataTableQuickSearch) as any;
