import { createContext, createElement, ReactNode, useContext } from 'react';

const ProfileOrgIdContext = createContext<string | null>(null);

export interface ProfileOrgIdProps {
  orgId: string | null;
  children?: ReactNode;
}

/**
 * Provide an Organization ID for the profile elements inside
 */
export const ProfileOrgId = (props: ProfileOrgIdProps) => {
  const { orgId, children } = props;

  return createElement(
    ProfileOrgIdContext.Provider,
    { value: orgId },
    children
  );
};

/**
 * Get the Organization ID for the current profile
 *
 * This is null on /profile indicating the current user's org, on Client Details it's the client ID
 */
export function useProfileOrgId(): string | null {
  return useContext(ProfileOrgIdContext);
}
