/**
 * Memoization-like helper to simplify `import()` code that wants to ensure only one Promise instance for the import ever exists
 */
export function loadOnce<T>(load: () => Promise<T>): () => Promise<T> {
  let result: { current: Promise<T> } | undefined;

  return () => {
    if (!result) {
      result = { current: load() };
    }

    return result.current;
  };
}
