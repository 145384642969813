import {
  CloseOutlined as CloseIcon,
  DeleteForeverOutlined as DeleteForeverIcon,
  DeleteOutlineOutlined as DeleteOutlineIcon,
} from '@mui/icons-material';
import { IconButton, IconButtonProps, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ErrorIconButton = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.error.main,
  }),
  { name: 'ErrorIconButton' }
);

export interface RemoveIconButtonProps extends IconButtonProps {
  variant?: 'delete' | 'deleteForever' | 'close';
}

/**
 * IconButton for removing things
 */
export const RemoveIconButton = (props: RemoveIconButtonProps) => {
  const { t } = useTranslation();
  const { color, variant = 'delete' } = props;

  let icon = <DeleteOutlineIcon />;
  if (variant === 'close') icon = <CloseIcon />;
  else if (variant === 'deleteForever') icon = <DeleteForeverIcon />;

  const IconButtonComponent = color ? IconButton : ErrorIconButton;

  return (
    <IconButtonComponent aria-label={t('button.remove')} {...props}>
      {icon}
    </IconButtonComponent>
  );
};
