import { useFieldMeta } from '@johnrom/formik-v3';
import { MenuItem } from '@mui/material';
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  AdminApiQueryKey,
  ClientShortSummary,
  isResourceNotFoundError,
  makeGetClientsSummariesFilterArgs,
  useAdminApi,
} from '../../api';
import { useOpenState } from '../../utils/useOpenState';
import { useOptionLabel, useOptions, useRenderOption } from '../utils';
import {
  DynamicComboBoxField,
  DynamicComboBoxFieldProps,
  useComboBoxApiQuery,
  useInputTextState,
  useOptionsWithCurrent,
} from './internal/DynamicComboBox';

export type ClientFieldProps = Omit<
  DynamicComboBoxFieldProps,
  | 'options'
  | 'getOptionLabel'
  | 'renderOption'
  | 'getOptionDisabled'
  | 'open'
  | 'onOpen'
  | 'onClose'
  | 'onInputChange'
  | 'loading'
>;

interface ClientShortSummaryValue extends ClientShortSummary {
  notFound?: boolean;
  loading?: boolean;
}

/**
 * A combo box field for selecting a client
 */
export const ClientField = forwardRef(function ClientField(
  props: ClientFieldProps,
  ref: any
) {
  const { ...other } = props;
  const { open, openProps } = useOpenState();
  const [inputText, onInputChange] = useInputTextState();
  const searchText = inputText || undefined;
  const { value } = useFieldMeta<string | null>(other.name); // @fixme Combobox should be a hook so we can access the value

  const queryClient = useQueryClient();
  const adminApi = useAdminApi();

  const {
    oneData: oneClient,
    oneError: oneClientError,
    allData: allClients,
    loadingOne: loadingOneClient,
    loading,
    hasNextPage,
  } = useComboBoxApiQuery<ClientShortSummary>({
    value,
    open,
    searchText,
    getOneByValue: (value) => adminApi.getClientSummaryById(value),
    searchAllByValue: (searchText) =>
      adminApi.getClientsSummaries(
        ...makeGetClientsSummariesFilterArgs({ searchText })
      ),
    preCacheOneItem: (client) => {
      queryClient.setQueryData(
        AdminApiQueryKey.getClientSummaryById(client.Id ?? ''),
        client
      );
    },
  });

  const selectedClient = useMemo<ClientShortSummaryValue | null>(() => {
    if (oneClient) return oneClient;

    if (value) {
      return {
        Id: value,
        LegalName: value,
        notFound: isResourceNotFoundError(oneClientError),
        loading: loadingOneClient,
      };
    }

    return null;
  }, [loadingOneClient, oneClient, oneClientError, value]);

  const getId = useCallback(
    (client: ClientShortSummary) => client.Id ?? '',
    []
  );
  const clients = useOptionsWithCurrent(allClients, selectedClient, getId);
  const options = useOptions(clients, getId);
  const getLabelForClient = useCallback(
    (client: ClientShortSummary) => client.LegalName ?? '',
    []
  );
  const getOptionLabel = useOptionLabel(clients, getId, getLabelForClient);
  const renderClientOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, client: ClientShortSummary) => (
      <MenuItem {...props}>{getLabelForClient(client)}</MenuItem>
    ),
    [getLabelForClient]
  );
  const renderOption = useRenderOption(clients, getId, renderClientOption);

  let inputPrefix: ReactNode;
  if (selectedClient?.notFound) {
    inputPrefix = '❌';
  } else if (selectedClient?.loading) {
    inputPrefix = '⋯';
  }

  return (
    <DynamicComboBoxField
      ref={ref}
      {...other}
      {...{
        options,
        loading,
        hasNextPage,
        getOptionLabel,
        renderOption,
        onInputChange,
        inputPrefix,
      }}
      {...openProps}
    />
  );
});
