import invariant from 'invariant';
import { DateTime } from 'luxon';
import {
  Attachment,
  CorporateId,
  CorporateIdType,
  PersonalId,
  PersonalIdType,
} from '../../../api';

export type CommonIdentification = Pick<
  CorporateId | PersonalId,
  | 'Number'
  | 'IssuedDate'
  | 'ExpiryDate'
  | 'IssuingCountry'
  | 'IssuingRegion'
  | 'SupportingDocument'
>;

export interface CommonIdentificationValues {
  type: PersonalIdType | CorporateIdType | '';
  number: string;
  issuedDate: DateTime | null;
  expiryDate: DateTime | null;
  issuingCountry: string | null;
  issuingRegion: string;
  registry: string;
  supportingDocument: Attachment | null;
}

export interface IdentificationValues extends CommonIdentificationValues {
  type: PersonalIdType | CorporateIdType | '';
}

/**
 * An empty CommonIdentificationValues
 */
export const emptyIdentificationValues: CommonIdentificationValues =
  Object.freeze({
    type: '',
    number: '',
    issuedDate: null,
    expiryDate: null,
    issuingCountry: null,
    issuingRegion: '',
    registry: '',
    supportingDocument: null,
  });

/**
 * Make an PersonalIdValues from an PersonalId or CorporateId object
 */
export function identificationToValues(
  identification: PersonalId | CorporateId | undefined
): IdentificationValues {
  return {
    type: identification?.Type ?? '',
    number: identification?.Number ?? '',
    issuedDate: identification?.IssuedDate
      ? DateTime.fromISO(identification?.IssuedDate).toUTC()
      : null,
    expiryDate: identification?.ExpiryDate
      ? DateTime.fromISO(identification?.ExpiryDate).toUTC()
      : null,
    issuingCountry: identification?.IssuingCountry ?? null,
    issuingRegion: identification?.IssuingRegion ?? '',
    registry:
      identification && 'Registry' in identification && identification.Registry
        ? identification.Registry
        : '',
    supportingDocument: identification?.SupportingDocument ?? null,
  };
}

const isPersonalIdType = (
  id: PersonalIdType | CorporateIdType | undefined
): id is PersonalIdType => !!id && id in PersonalIdType;
const isCorporateIdType = (
  id: PersonalIdType | CorporateIdType | undefined
): id is CorporateIdType => !!id && id in CorporateIdType;

/**
 * Convert IdentificationValues to an PersonalId and CorporateId
 */
export function identificationValuesToIdentification(
  values: IdentificationValues,
  type: 'PersonalId'
): PersonalId;
export function identificationValuesToIdentification(
  values: IdentificationValues,
  type: 'CorporateId'
): CorporateId;
export function identificationValuesToIdentification(
  ...args:
    | [values: IdentificationValues, type: 'PersonalId']
    | [values: IdentificationValues, type: 'CorporateId']
): PersonalId | CorporateId {
  const [values, type] = args;
  const optional = <T extends unknown = string>(value: T | null | '') =>
    value || undefined;

  invariant(values.type, 'type is required');

  const common = {
    Number: optional(values.number),
    IssuedDate: optional(values.issuedDate?.toISODate()),
    ExpiryDate: optional(values.expiryDate?.toISODate()),
    IssuingCountry: optional(values.issuingCountry),
    IssuingRegion: optional(values.issuingRegion),
    SupportingDocument: optional(values.supportingDocument),
  };

  if (type === 'PersonalId') {
    invariant(isPersonalIdType(values.type), 'type must be a PersonalIdType');
    const { Number, IssuingCountry } = common;
    invariant(Number, 'number is required');
    invariant(IssuingCountry, 'issuingCountry is required');
    return {
      Type: values.type,
      ...common,
      Number,
      IssuingCountry,
    };
  }

  if (type === 'CorporateId') {
    invariant(isCorporateIdType(values.type), 'type must be a CorporateIdType');
    const { registry } = values;
    return {
      Type: values.type,
      ...common,
      Registry: optional(registry),
    } as CorporateId;
  }

  throw new Error('Missing identification type');
}
