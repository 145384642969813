import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppEnvironment } from '../utils/env';

/**
 * Provide localized labels for the AppEnvironment enum
 */
export function useAppEnvironmentLabels(): Record<AppEnvironment, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<AppEnvironment, string> => ({
      local: t('enumLabel.appEnvironment.local'),
      development: t('enumLabel.appEnvironment.development'),
      testing: t('enumLabel.appEnvironment.testing'),
      staging: t('enumLabel.appEnvironment.staging'),
      sandbox: t('enumLabel.appEnvironment.sandbox'),
      production: t('enumLabel.appEnvironment.production'),
    }),
    [t]
  );
}
