import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface NotificationTabsProps {
  /**
   * Number of unread notifications
   */
  unreadCount?: number;
  /**
   * Currently selected tab
   */
  currentTab?: string;
  /**
   * Event fired when current tab changes
   */
  onChangeTab?: (currentTab: string) => void;
}

/**
 * Tabs header for notifications views
 */
export const NotificationTabs = (props: NotificationTabsProps) => {
  const { unreadCount = 0, currentTab, onChangeTab } = props;
  const { t } = useTranslation();

  return (
    <Tabs
      variant="fullWidth"
      value={currentTab}
      onChange={(e, value) => onChangeTab?.(value)}
    >
      <Tab
        value="unread"
        label={t('tabLabel.unread', { total: unreadCount })}
      />
      <Tab value="all" label={t('tabLabel.all')} />
    </Tabs>
  );
};
