import {
  ReactNode,
  createContext,
  createElement,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useIntlPolyfills } from '../polyfill';
import { detectLocale, lookupLocale } from './detect';

const IntlLocaleContext = createContext<string>('en');
const ChangeIntlLocaleContext = createContext<(locale: string) => void>(() => {
  throw new Error('Locale is setup as static and cannot be changed');
});

export interface IntlLocaleProviderProps {
  children?: ReactNode;
}

export interface MockIntlLocaleProviderProps extends IntlLocaleProviderProps {
  locale: string;
}

/**
 * Provides the current locale via context
 */
export const IntlLocaleProvider = ({ children }: IntlLocaleProviderProps) => {
  const [locale, setLocale] = useState(detectLocale);
  useIntlPolyfills(locale);

  useEffect(() => {
    if (process.env.REACT_APP_DEVTOOLS === 'true') {
      const LocaleOverride = 'locale-override';
      const localeOverride = sessionStorage.getItem(LocaleOverride);
      if (localeOverride) {
        setLocale(lookupLocale(localeOverride.split(',')));
      }

      /**
       * (Dev-only) global function for overriding the current locale setting
       */
      (window as any).setLocaleOverride = function setLocaleOverride(
        localeOverride?: string | null
      ) {
        if (localeOverride)
          sessionStorage.setItem(LocaleOverride, localeOverride);
        else sessionStorage.removeItem(LocaleOverride);
        setLocale(
          localeOverride
            ? lookupLocale(localeOverride.split(','))
            : detectLocale()
        );
      };

      return () => {
        delete (window as any).setLocaleOverride;
      };
    }
  }, []);

  return createElement(
    ChangeIntlLocaleContext.Provider,
    { value: setLocale },
    createElement(IntlLocaleContext.Provider, { value: locale }, children)
  );
};

/**
 * Mock version of the IntlLocaleProvider that provides a static locale for tests and storybook
 */
export const MockIntlLocaleProvider = ({
  locale,
  children,
}: MockIntlLocaleProviderProps) => {
  return createElement(IntlLocaleContext.Provider, { value: locale }, children);
};

/**
 * Get the current locale
 */
export function useLocale(): string {
  return useContext(IntlLocaleContext);
}
