import { styled } from '@mui/material';
import { cloneElement, ElementType, ReactElement, ReactNode } from 'react';
import { SingleCheckboxFieldProps } from '../fields';

interface OwnerState {
  variant: 'section' | 'subsection';
  withTitle: boolean;
  withCheckbox: boolean;
  withText: boolean;
}

const FormSectionTitle = styled('div', { name: 'FormSection', slot: 'Title' })<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  margin: 0,
  marginBottom: '0.35em',
  ...(ownerState.variant === 'section' && {
    ...theme.typography.subtitle1,
  }),
  ...(ownerState.variant === 'subsection' && {
    ...theme.typography.subtitle2,
  }),
}));

const FormSectionCheckbox = styled('div', {
  name: 'FormSection',
  slot: 'Checkbox',
})<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  margin: 0,
  ...(ownerState.withText
    ? {
        ...(ownerState.variant === 'section' && {
          ...theme.typography.subtitle1,
        }),
        ...(ownerState.variant === 'subsection' && {
          ...theme.typography.subtitle2,
        }),
      }
    : {
        ...(ownerState.variant === 'section' && {
          ...theme.typography.body1,
        }),
        ...(ownerState.variant === 'subsection' && {
          ...theme.typography.body2,
        }),
      }),
}));

const FormSectionText = styled('div', { name: 'FormSection', slot: 'Text' })<{
  ownerState: OwnerState;
}>(({ theme, ownerState }) => ({
  ...(ownerState.withCheckbox && {
    paddingLeft: theme.spacing(4),
    '&:not(:empty)': {
      marginTop: theme.spacing(-2),
    },
  }),
  '& > p': {
    margin: `0.35em 0`,
    ...(ownerState.variant === 'section' && {
      ...theme.typography.body1,
    }),
    ...(ownerState.variant === 'subsection' && {
      ...theme.typography.body2,
    }),
  },
}));

export interface FormSectionProps {
  variant?: 'section' | 'subsection';
  title?: ReactNode;
  checkbox?: ReactElement<SingleCheckboxFieldProps>;
  TitleComponent?: ElementType;
  children?: ReactNode;
}

/**
 * Typography styling for help sections in form pages
 */
export const FormSection = (props: FormSectionProps) => {
  const {
    variant = 'section',
    title,
    checkbox,
    TitleComponent = 'p',
    children,
  } = props;
  const withTitle = !!title;
  const withText = !!children;
  const withCheckbox = !!checkbox;
  const ownerState: OwnerState = { variant, withTitle, withCheckbox, withText };

  return (
    <>
      {title && (
        <FormSectionTitle as={TitleComponent} ownerState={ownerState}>
          {title}
        </FormSectionTitle>
      )}
      {checkbox && (
        <FormSectionCheckbox as={TitleComponent} ownerState={ownerState}>
          {cloneElement(checkbox, {
            LabelProps: {
              ...checkbox.props.LabelProps,
              disableTypography: true,
              // componentsProps: {
              //   ...checkbox.props.LabelProps?.componentsProps,
              //   typography: {
              //     ...checkbox.props.LabelProps?.componentsProps?.typography,
              //     variant: variant === 'subsection' ? 'subtitle2' : 'subtitle1',
              //   },
              // },
            },
          })}
        </FormSectionCheckbox>
      )}
      {children && (
        <FormSectionText ownerState={ownerState}>{children}</FormSectionText>
      )}
    </>
  );
};
