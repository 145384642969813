import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QualityOfService } from '../../api';

/**
 * Provide localized labels for the QualityOfService enum
 */
export function useQualityOfServiceLabels(): Record<QualityOfService, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<QualityOfService, string> => ({
      Instant: t('enumLabel.qualityOfService.instant'),
      ASAP: t('enumLabel.qualityOfService.asap'),
    }),
    [t]
  );
}
