import ms from 'ms';
import { useApiQuery } from '../api';
import { Code, MiscApiGetCodesArgs, useMiscApi } from './client';

/**
 * String enum of possible code typesf
 */
export type CodeType = MiscApiGetCodesArgs[0];

const ONE_HOUR = ms('1 hour');
const FIVE_MINUTES = ms('5 minutes');

/**
 * Default stale times (ms) for code
 *
 * @see QueryObserverOptions.staleTime
 */
export const CodeDefaultStaleTimes: { [key in CodeType]?: number } = {
  accounts: FIVE_MINUTES, // this is based on dynamic data so it should refresh more often
};

/**
 * Simplified wrapper around useApiQuery+MiscApi.getCodes
 * - Only bothers returning the codes and loading state in a simple response
 * - Provides individual stale default times to reduce fetches
 */
export function useCodes(
  codeType: CodeType | null
): [Code[] | null | undefined, boolean] {
  const miscApi = useMiscApi();
  const { data, loading } = useApiQuery(
    codeType ? miscApi.getCodes(codeType) : null,
    {
      staleTime: (codeType && CodeDefaultStaleTimes[codeType]) ?? ONE_HOUR,
      refetchOnLocale: true,
    }
  );

  return [data, loading];
}
