import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type RegulatorFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a regulator
 */
export const RegulatorField = forwardRef(function RegulatorField(
  props: RegulatorFieldProps,
  ref: any
) {
  const { ...other } = props;
  const [regulators, loadingRegulators] = useCodes('regulators');
  const optionProps = useCodeOptionsProps(regulators);

  return (
    <SelectField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingRegulators}
    />
  );
});
