import { ContactEmailAddress, ContactEmailAddressType } from '../../../api';

export interface EmailAddressValues {
  type: ContactEmailAddressType | '';
  email: string;
}

/**
 * An empty EmailAddressValues
 */
export const emptyEmailAddressValues: EmailAddressValues = Object.freeze({
  type: '',
  email: '',
});

/**
 * Make an EmailAddressValues from an ContactEmailAddress object
 */
export function emailAddressesToValues(
  email: ContactEmailAddress
): EmailAddressValues {
  return {
    type: email.Type ?? '',
    email: email.EmailAddress ?? '',
  };
}

/**
 * Convert EmailAddressValues to an ContactEmailAddress object
 */
export function emailAddressesValuesToEmailAddress(
  values: EmailAddressValues
): ContactEmailAddress {
  return {
    Type: values.type as ContactEmailAddressType,
    EmailAddress: values.email,
  };
}
