import { Chip, useTheme } from '@mui/material';
import { CSSProperties } from 'react';

export interface EnumChipProps {
  value: string;
  label: string;
  bgcolor?: string;
  colorRefData?: Record<string, string>;
}

/**
 * Chip for representing an enum in a table column
 */
const EnumChip = (props: EnumChipProps) => {
  const { value, label, bgcolor: bgcolorProp, colorRefData } = props;
  const theme = useTheme();

  let bgcolor = bgcolorProp;
  if (!bgcolor) {
    bgcolor = colorRefData?.[value];
  }
  let style: CSSProperties | undefined = undefined;
  if (bgcolor) {
    style = {
      backgroundColor: bgcolor,
      color: theme.palette.getContrastText(bgcolor),
    };
  }

  return (
    <Chip sx={{ maxWidth: '100%' }} size="small" label={label} style={style} />
  );
};

export default EnumChip;
