import {
  ArrowDropDown as ArrowDropDownIcon,
  Flag as FlagIcon,
} from '@mui/icons-material';
import {
  ButtonBase,
  Divider,
  InputAdornment,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import { difference, intersection } from 'lodash-es';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/es/hooks';
import { forwardRef, memo, useMemo } from 'react';
import { Flag } from '../../../flags';
import { useCountryNames } from '../../../intl';
import { CountryCode, useLibPhoneNumber } from '../../../libphonenumber';
import { VirtualMenu } from '../../../ui/VirtualMenu';
import { featFlag } from '../../../utils/featFlags';
import useHtmlId from '../../../utils/useHtmlId';
import { useSortedOptions } from '../../utils';

const PhoneFlagButton = styled(ButtonBase)(
  ({ theme }) => ({
    // @fixme Account for variants other than outlined and other input size densities?
    padding: theme.spacing(2, 0),
    marginLeft: -12,
    paddingLeft: 12,
    borderTopLeftRadius: theme.shape.borderRadius,
  }),
  {
    name: 'PhoneFlagButton',
  }
);

const CountryCodeMenuItemItem = styled(MenuItem)(
  ({ theme }) => ({
    '& > .flag': {
      height: 24,
      width: 24,
      marginRight: theme.spacing(2),
    },
    '& > .callingCode': {
      marginLeft: theme.spacing(0.5),
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
  }),
  { name: 'CountryCodeMenuItemItem' }
);

const CountryCodeMenuItem = memo(
  forwardRef(function CountryCodeMenuItem(
    props: {
      country: CountryCode;
      localizedName: string;
      selected: boolean;
      onChange?: (value: CountryCode) => void;
    },
    ref: any
  ) {
    const { country, localizedName, onChange, ...other } = props;
    const { getCountryCallingCode } = useLibPhoneNumber();

    // @fixme We have localized country names, but like https://intl-tel-input.com/ we should probably also find a way to show country names in their native language
    // useMemo(() => {
    //   /// XXX: Use preval to extract the data we want from world-countries without compiling the whole JSON
    //   const c = worldCountries.find((c) => c.cca2 === country);
    //   if (!c) return [];
    //   const nativeNames = Object.values(c.name.native).map(
    //     (name: { official: string; common: string }) => name.common
    //   );
    //   return uniq(nativeNames.filter((name) => name !== localizedName));
    // }, [country, localizedName]);
    return (
      <CountryCodeMenuItemItem
        ref={ref}
        {...other}
        onClick={() => onChange?.(country)}
      >
        <Flag
          className="flag"
          code={country}
          fallback={<FlagIcon className="flag" />}
        />
        {localizedName}
        <span className="callingCode">
          {'+' + getCountryCallingCode(country)}
        </span>
      </CountryCodeMenuItemItem>
    );
  })
);

export interface PhoneFlagSelectProps {
  name?: string;
  value?: CountryCode;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (value: CountryCode) => void;
}

const preferredCountries: CountryCode[] = ['GB', 'US', 'CA'];

/**
 * An international phone number prefix selecting input
 */
export const PhoneFlagSelect = (props: PhoneFlagSelectProps) => {
  const { name, value, readOnly = false, disabled = false, onChange } = props;
  const { getCountries } = useLibPhoneNumber();
  const countryName = useCountryNames();
  const countries = useSortedOptions(
    // Memoize getCountries since it's implemented using Object.keys and .filter
    useMemo(() => getCountries(), [getCountries]),
    countryName
  );
  const countryOptions = useMemo<(CountryCode | null)[]>(() => {
    const preferred = intersection(countries, preferredCountries);
    const other = difference(countries, preferredCountries);

    if (preferred.length > 0) {
      return [...preferred, null, ...other];
    } else {
      return other;
    }
  }, [countries]);
  const popupId = useHtmlId(name ?? 'phone-flag');
  const popupState = usePopupState({ variant: 'popover', popupId });

  // const flag = (
  //   <Flag
  //     code={value}
  //     height="24"
  //     width="24"
  //     fallback={<FlagIcon fontSize="small" />}
  //   />
  // );
  const flag = <Flag code={value} fallback={<FlagIcon fontSize="small" />} />;

  return (
    <>
      {disabled || readOnly ? (
        <PhoneFlagButton as="div">
          <InputAdornment position="start">{flag}</InputAdornment>
        </PhoneFlagButton>
      ) : (
        <PhoneFlagButton {...bindTrigger(popupState)}>
          <InputAdornment position="start">
            {flag}
            <ArrowDropDownIcon fontSize="small" />
          </InputAdornment>
        </PhoneFlagButton>
      )}
      {featFlag('virtualizePhoneCountries') ? (
        <VirtualMenu
          onClick={popupState.close}
          {...bindMenu(popupState)}
          items={countryOptions}
          itemWidth={320}
          isDivider={(country) => country === null}
          getItemKey={(country) => country ?? 'divider'}
          renderItem={(country, props) =>
            country ? (
              <CountryCodeMenuItem
                {...props}
                country={country}
                localizedName={countryName(country)}
                selected={value === country}
                onChange={onChange}
              />
            ) : (
              <Divider {...props} />
            )
          }
        />
      ) : (
        <Menu onClick={popupState.close} {...bindMenu(popupState)}>
          {countryOptions?.map((country) =>
            country ? (
              <CountryCodeMenuItem
                key={country}
                country={country}
                localizedName={countryName(country)}
                selected={value === country}
                onChange={onChange}
              />
            ) : (
              <Divider key="divider" />
            )
          )}
        </Menu>
      )}
    </>
  );
};
