import { useFieldMeta } from '@johnrom/formik-v3';
import { MenuItem } from '@mui/material';
import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import {
  AdminApiQueryKey,
  getRiskSurveyId,
  isResourceNotFoundError,
  makeGetRiskSurveySummariesFilterArgs,
  RiskSurveyShortSummary,
  useAdminApi,
} from '../../api';
import { useOpenState } from '../../utils/useOpenState';
import { useOptionLabel, useOptions, useRenderOption } from '../utils';
import {
  DynamicComboBoxField,
  DynamicComboBoxFieldProps,
  useComboBoxApiQuery,
  useInputTextState,
  useOptionsWithCurrent,
} from './internal/DynamicComboBox';

export type SettingsRiskSurveyFieldProps = Omit<
  DynamicComboBoxFieldProps,
  | 'options'
  | 'getOptionLabel'
  | 'renderOption'
  | 'getOptionDisabled'
  | 'open'
  | 'onOpen'
  | 'onClose'
  | 'onInputChange'
  | 'loading'
> & {
  preload?: boolean;
};

interface RiskSurveyShortSummaryValue extends RiskSurveyShortSummary {
  notFound?: boolean;
  loading?: boolean;
}

/**
 * A combobox field for selecting a Settings Risk Survey
 */
export const SettingsRiskSurveyField = forwardRef(
  function SettingsRiskSurveyField(
    props: SettingsRiskSurveyFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const { open, openProps } = useOpenState();
    const [inputText, onInputChange] = useInputTextState();
    const searchText = inputText || undefined;
    const { value } = useFieldMeta<string | null>(other.name); // @fixme Combobox should be a hook so we can access the value

    const queryClient = useQueryClient();
    const adminApi = useAdminApi();

    const {
      oneData: oneRiskSurvey,
      oneError: oneRiskSurveyError,
      allData: allRiskSurveys,
      loadingOne: loadingOneRiskSurvey,
      loading,
      hasNextPage,
    } = useComboBoxApiQuery<RiskSurveyShortSummary>({
      value,
      open,
      searchText,
      getOneByValue: (value) => adminApi.getRiskSurveySummaryById(value),
      searchAllByValue: (searchText) =>
        adminApi.getRiskSurveySummaries(
          ...makeGetRiskSurveySummariesFilterArgs({ searchText })
        ),
      preCacheOneItem: (riskSurvey) => {
        queryClient.setQueryData(
          AdminApiQueryKey.getRiskSurveySummaryById(riskSurvey.Id ?? ''),
          riskSurvey
        );
      },
    });

    const selectedRiskSurvey =
      useMemo<RiskSurveyShortSummaryValue | null>(() => {
        if (oneRiskSurvey) return oneRiskSurvey;

        if (value) {
          return {
            Id: value,
            Name: value,
            notFound: isResourceNotFoundError(oneRiskSurveyError),
            loading: loadingOneRiskSurvey,
          };
        }

        return null;
      }, [loadingOneRiskSurvey, oneRiskSurvey, oneRiskSurveyError, value]);

    const getId = getRiskSurveyId;
    const RiskSurveys = useOptionsWithCurrent(
      allRiskSurveys,
      selectedRiskSurvey,
      getId
    );
    const options = useOptions(RiskSurveys, getId);
    const getLabelForRiskSurvey = useCallback(
      (riskSurvey: RiskSurveyShortSummary) => riskSurvey.Name ?? '',
      []
    );
    const getOptionLabel = useOptionLabel(
      RiskSurveys,
      getId,
      getLabelForRiskSurvey
    );
    const renderRiskSurveyOption = useCallback(
      (
        props: HTMLAttributes<HTMLLIElement>,
        riskSurvey: RiskSurveyShortSummary
      ) => <MenuItem {...props}>{getLabelForRiskSurvey(riskSurvey)}</MenuItem>,
      [getLabelForRiskSurvey]
    );
    const renderOption = useRenderOption(
      RiskSurveys,
      getId,
      renderRiskSurveyOption
    );

    let inputPrefix: ReactNode;
    if (selectedRiskSurvey?.notFound) {
      inputPrefix = '❌';
    } else if (selectedRiskSurvey?.loading) {
      inputPrefix = '⋯';
    }

    return (
      <DynamicComboBoxField
        ref={ref}
        {...other}
        {...{
          options,
          loading,
          hasNextPage,
          getOptionLabel,
          renderOption,
          onInputChange,
          inputPrefix,
        }}
        {...openProps}
      />
    );
  }
);
