import { dateFilterMap } from '../comparator-mappers';
import { Contains, EQ, GT, GTE, LT, LTE } from '../comparators';
import { parseDateTime, parseString } from '../qs-parser';
import { QueryStringFilterValidator } from '../qs-validator';
import { FilterReturnArg, makeFilter } from '../utils';
import { DateTime } from 'luxon';

export interface GetClientsFilters {
  searchText?: string;
  status?: EQ;
  complianceStatus?: EQ;
  supplier?: EQ;
  legalName?: EQ & Contains;
  informalName?: EQ & Contains;
  incorporationLoc?: EQ<string | null>;
  incorporationNumber?: EQ & Contains;
  incorporationDate?: LT<DateTime | null> &
    GT<DateTime | null> &
    LTE<DateTime | null> &
    GTE<DateTime | null>;
  sic?: EQ<string | null>;
  lastKycReview?: LT<DateTime | null> &
    GT<DateTime | null> &
    LTE<DateTime | null> &
    GTE<DateTime | null>;
  clientProfileComplianceLevel?: EQ;
  startDate?: LT<DateTime | null> &
    GT<DateTime | null> &
    LTE<DateTime | null> &
    GTE<DateTime | null>;
  webSite?: EQ & Contains;
  clientProfile?: EQ<string | null>;
  referrer?: EQ & Contains;
}

/**
 * Cast query string arguments to GetClientsFilters
 * @note Generated by filtergen
 */
export function castGetClientsFilters(filters: qs.ParsedQs): GetClientsFilters {
  const f = new QueryStringFilterValidator(filters);

  return {
    searchText: f.searchText('searchText'),

    status: {
      eq: f.eq('status', parseString),
    },

    complianceStatus: {
      eq: f.eq('complianceStatus', parseString),
    },

    supplier: {
      eq: f.eq('supplier', parseString),
    },

    legalName: {
      eq: f.eq('legalName', parseString),
      contains: f.contains('legalName', parseString),
    },

    informalName: {
      eq: f.eq('informalName', parseString),
      contains: f.contains('informalName', parseString),
    },

    incorporationLoc: {
      eq: f.eq('incorporationLoc', parseString),
    },

    incorporationNumber: {
      eq: f.eq('incorporationNumber', parseString),
      contains: f.contains('incorporationNumber', parseString),
    },

    incorporationDate: {
      lt: f.lt('incorporationDate', parseDateTime),
      gt: f.gt('incorporationDate', parseDateTime),
      lte: f.lte('incorporationDate', parseDateTime),
      gte: f.gte('incorporationDate', parseDateTime),
    },

    sic: {
      eq: f.eq('sic', parseString),
    },

    lastKycReview: {
      lt: f.lt('lastKycReview', parseDateTime),
      gt: f.gt('lastKycReview', parseDateTime),
      lte: f.lte('lastKycReview', parseDateTime),
      gte: f.gte('lastKycReview', parseDateTime),
    },

    clientProfileComplianceLevel: {
      eq: f.eq('clientProfileComplianceLevel', parseString),
    },

    startDate: {
      lt: f.lt('startDate', parseDateTime),
      gt: f.gt('startDate', parseDateTime),
      lte: f.lte('startDate', parseDateTime),
      gte: f.gte('startDate', parseDateTime),
    },

    webSite: {
      eq: f.eq('webSite', parseString),
      contains: f.contains('webSite', parseString),
    },

    clientProfile: {
      eq: f.eq('clientProfile', parseString),
    },

    referrer: {
      eq: f.eq('referrer', parseString),
      contains: f.contains('referrer', parseString),
    },
  };
}

/**
 * Make args for the GetClientsSummaries query params
 * @see GET /clients/orgs/summaries
 * @note Generated by filtergen
 */
export function makeGetClientsSummariesFilterArgs(
  filters: GetClientsFilters
): [
  searchText: string | undefined,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg,
  supplier: FilterReturnArg,
  legalName: FilterReturnArg,
  informalName: FilterReturnArg,
  incorporationLoc: FilterReturnArg,
  incorporationNumber: FilterReturnArg,
  incorporationDate: FilterReturnArg,
  sic: FilterReturnArg,
  lastKycReview: FilterReturnArg,
  clientProfileComplianceLevel: FilterReturnArg,
  startDate: FilterReturnArg,
  webSite: FilterReturnArg,
  clientProfile: FilterReturnArg,
  referrer: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
    /**
     * @note Supplier
     */ makeFilter(filters.supplier),
    /**
     * @note LegalName
     */ makeFilter(filters.legalName),
    /**
     * @note InformalName
     */ makeFilter(filters.informalName),
    /**
     * @note IncorporationLoc
     */ makeFilter(filters.incorporationLoc),
    /**
     * @note IncorporationNumber
     */ makeFilter(filters.incorporationNumber),
    /**
     * @note IncorporationDate
     */ makeFilter(filters.incorporationDate, dateFilterMap),
    /**
     * @note SIC
     */ makeFilter(filters.sic),
    /**
     * @note LastKycReview
     */ makeFilter(filters.lastKycReview, dateFilterMap),
    /**
     * @note ClientProfileComplianceLevel
     */ makeFilter(filters.clientProfileComplianceLevel),
    /**
     * @note StartDate
     */ makeFilter(filters.startDate, dateFilterMap),
    /**
     * @note WebSite
     */ makeFilter(filters.webSite),
    /**
     * @note ClientProfile
     */ makeFilter(filters.clientProfile),
    /**
     * @note Referrer
     */ makeFilter(filters.referrer),
  ];
}

/**
 * Make args for the ExportClients query params
 * @see GET /clients/orgs/export
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeExportClientsFilterArgs(
  filters: GetClientsFilters
): [
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg,
  supplier: FilterReturnArg,
  legalName: FilterReturnArg,
  informalName: FilterReturnArg,
  incorporationLoc: FilterReturnArg,
  incorporationNumber: FilterReturnArg,
  incorporationDate: FilterReturnArg,
  sic: FilterReturnArg,
  lastKycReview: FilterReturnArg,
  clientProfileComplianceLevel: FilterReturnArg,
  startDate: FilterReturnArg,
  webSite: FilterReturnArg,
  clientProfile: FilterReturnArg,
  referrer: FilterReturnArg
] {
  return [
    /**
     * @note Status
     */
    makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
    /**
     * @note Supplier
     */ makeFilter(filters.supplier),
    /**
     * @note LegalName
     */ makeFilter(filters.legalName),
    /**
     * @note InformalName
     */ makeFilter(filters.informalName),
    /**
     * @note IncorporationLoc
     */ makeFilter(filters.incorporationLoc),
    /**
     * @note IncorporationNumber
     */ makeFilter(filters.incorporationNumber),
    /**
     * @note IncorporationDate
     */ makeFilter(filters.incorporationDate, dateFilterMap),
    /**
     * @note SIC
     */ makeFilter(filters.sic),
    /**
     * @note LastKycReview
     */ makeFilter(filters.lastKycReview, dateFilterMap),
    /**
     * @note ClientProfileComplianceLevel
     */ makeFilter(filters.clientProfileComplianceLevel),
    /**
     * @note StartDate
     */ makeFilter(filters.startDate, dateFilterMap),
    /**
     * @note WebSite
     */ makeFilter(filters.webSite),
    /**
     * @note ClientProfile
     */ makeFilter(filters.clientProfile),
    /**
     * @note Referrer
     */ makeFilter(filters.referrer),
  ];
}

/**
 * Make args for the GetClients query params
 * @see GET /clients/orgs
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetClientsFilterArgs(
  filters: GetClientsFilters
): [
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg,
  supplier: FilterReturnArg,
  legalName: FilterReturnArg,
  informalName: FilterReturnArg,
  incorporationLoc: FilterReturnArg,
  incorporationNumber: FilterReturnArg,
  incorporationDate: FilterReturnArg,
  sic: FilterReturnArg,
  lastKycReview: FilterReturnArg,
  clientProfileComplianceLevel: FilterReturnArg,
  startDate: FilterReturnArg,
  webSite: FilterReturnArg,
  clientProfile: FilterReturnArg,
  referrer: FilterReturnArg
] {
  return [
    /**
     * @note Status
     */
    makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
    /**
     * @note Supplier
     */ makeFilter(filters.supplier),
    /**
     * @note LegalName
     */ makeFilter(filters.legalName),
    /**
     * @note InformalName
     */ makeFilter(filters.informalName),
    /**
     * @note IncorporationLoc
     */ makeFilter(filters.incorporationLoc),
    /**
     * @note IncorporationNumber
     */ makeFilter(filters.incorporationNumber),
    /**
     * @note IncorporationDate
     */ makeFilter(filters.incorporationDate, dateFilterMap),
    /**
     * @note SIC
     */ makeFilter(filters.sic),
    /**
     * @note LastKycReview
     */ makeFilter(filters.lastKycReview, dateFilterMap),
    /**
     * @note ClientProfileComplianceLevel
     */ makeFilter(filters.clientProfileComplianceLevel),
    /**
     * @note StartDate
     */ makeFilter(filters.startDate, dateFilterMap),
    /**
     * @note WebSite
     */ makeFilter(filters.webSite),
    /**
     * @note ClientProfile
     */ makeFilter(filters.clientProfile),
    /**
     * @note Referrer
     */ makeFilter(filters.referrer),
  ];
}
