import { FileChip } from '../../preview';
import { Attachment } from '../../api';

export interface DocumentFileChipProps {
  attachment: Attachment;
}

export const DocumentFileChip = (props: DocumentFileChipProps) => {
  const { attachment } = props;
  const { FileType, FileSize } = attachment;

  return (
    <FileChip
      variant="outlined"
      size="small"
      fileType={FileType}
      fileSize={FileSize ? parseFloat(FileSize) : undefined}
    />
  );
};
