import { forwardRef } from 'react';
import { AccountBlockStatus } from '../../../api';
import { useAccountBlockStatusLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type AccountBlockStatusFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a AccountBlockStatus
 */
export const AccountBlockStatusField = forwardRef(
  function AccountBlockStatusField(
    props: AccountBlockStatusFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const optionsProps = useEnumOptions(
      AccountBlockStatus,
      useAccountBlockStatusLabels()
    );

    return <SelectField ref={ref} {...other} {...optionsProps} />;
  }
);
