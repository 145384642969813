import { Paper, PaperProps, styled } from '@mui/material';
import { surfaceElevation } from '../../theme';

const PageSheetRoot = styled(Paper, {
  name: 'PageSheet',
  slot: 'Root',
})<{ ownerState: { variant: 'default' | 'stretch' } }>(
  ({ theme, ownerState }) => ({
    padding: theme.spacing(2),
    ...(ownerState.variant === 'stretch' && {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }),
  })
);

export type PageSheetProps = Omit<PaperProps, 'variant'> & {
  variant?: 'default' | 'stretch';
};

/**
 * A common UI component for the main sheet on a page with a paper background and standard padding
 */
export const PageSheet = (props: PageSheetProps) => {
  const {
    children,
    variant = 'default',
    elevation = surfaceElevation,
    ...other
  } = props;

  return (
    <PageSheetRoot elevation={elevation} {...other} ownerState={{ variant }}>
      {children}
    </PageSheetRoot>
  );
};
