import { Menu, MenuProps } from '@mui/material';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/es/hooks';
import { cloneElement, ElementType, FC, ReactElement, ReactNode } from 'react';
import useHtmlId from './useHtmlId';

export interface MenuOpenerProps {
  name?: string;
  button: ReactElement;
  MenuComponent?: ElementType<MenuProps>;
  menuProps?: Omit<MenuProps, 'open'>;
  children?: ReactNode;
}

/**
 * Helper to handle open a menu when a button is clicked
 */
export const MenuOpener: FC<MenuOpenerProps> = ({
  MenuComponent = Menu,
  name,
  button,
  menuProps,
  children,
}) => {
  const popupId = useHtmlId(name);
  const popupState = usePopupState({ variant: 'popover', popupId });

  return (
    <>
      {cloneElement(button, bindTrigger(popupState))}
      <MenuComponent
        {...menuProps}
        {...bindMenu(popupState)}
        onClick={popupState.close}
      >
        {children}
      </MenuComponent>
    </>
  );
};

export default MenuOpener;
