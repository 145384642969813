import { Consumer, createContext, ReactElement, useContext } from 'react';
import {
  createDispatchedStateProvider,
  DispatchedStateProviderProps,
} from './DispatchedStateProvider';

export interface DispatchedStateContext<S, D> {
  Provider: (props: DispatchedStateProviderProps<S, D>) => ReactElement;
  StateConsumer: Consumer<S>;
  /**
   * Get the dispatch function for a reducer context
   */
  useReducerDispatch: () => D;
  /**
   * Get the current state for a reducer context
   */
  useReducerState: () => S;
}

/**
 * Helper utility that creates a dispatcher+state pair and exports both a DispatchedStateProvider and useContext hooks
 */
export function createDispatchedStateContext<S, D>(
  displayName: string,
  defaultState: S,
  defaultDispatch: D
): DispatchedStateContext<S, D> {
  const DispatchContext = createContext<D>(defaultDispatch);
  DispatchContext.displayName = `${displayName}Dispatch`;
  const StateContext = createContext<S>(defaultState);
  StateContext.displayName = `${displayName}State`;
  const Provider = createDispatchedStateProvider(StateContext, DispatchContext);
  const StateConsumer = StateContext.Consumer;

  /**
   * Get the dispatch function for a reducer context
   */
  function useReducerDispatch(): D {
    return useContext(DispatchContext);
  }

  /**
   * Get the current state for a reducer context
   */
  function useReducerState(): S {
    return useContext(StateContext);
  }

  return { Provider, StateConsumer, useReducerDispatch, useReducerState };
}
