import { useDocumentTitle } from '@react-hookz/web/esm';
import { useBranding } from '../branding';
import { useAppEnvironmentLabels, useLabelFn } from '../intl';
import { AppEnvironment, APP_ENV } from './env';

/**
 * Helper to set the document's <title> for a page, while automatically including the site name
 */
export function usePageTitle(pageTitle: string | undefined | null) {
  const { siteName } = useBranding();
  const appEnvironmentLabel = useLabelFn(useAppEnvironmentLabels());
  let domTitle = pageTitle ? `${pageTitle} | ${siteName}` : siteName;
  if (APP_ENV !== AppEnvironment.Production) {
    domTitle += ` (${appEnvironmentLabel(APP_ENV)})`;
  }

  useDocumentTitle(domTitle);
}
