import { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Params, useParams } from 'react-router-dom';
import { ApiQueryHandler, useApiQuery } from '../api';
import LoadingEllipsis from '../ui/LoadingEllipsis';
import MonoSpan from '../ui/MonoSpan';
import { useJsonMemo } from '../utils/useJsonMemo';
import { Breadcrumb, BreadcrumbParams } from './types';

export type BreadcrumbTitle = Pick<Breadcrumb, 'title' | 'loading' | 'params'>;

export interface BreadcrumbTitleComponentProps {
  children: (param: BreadcrumbTitle) => ReactElement;
}
export type BreadcrumbTitleComponent = (
  props: BreadcrumbTitleComponentProps
) => ReactElement;

/**
 * Make a breadcrumb title component that uses a simple localized title
 */
export function breadcrumbTitle(title: string): BreadcrumbTitleComponent {
  return function BreadcrumbTitle(props) {
    const { children } = props;
    const { t } = useTranslation();
    return children(useMemo(() => ({ title: t(title), loading: false }), [t]));
  };
}

/**
 * Make a breadcrumb title component that uses page parameters
 */
export function breadcrumbTitleParameterized(
  useTitle: (
    params: Params
  ) =>
    | string
    | [title: string | undefined, loading: boolean, params?: BreadcrumbParams]
): BreadcrumbTitleComponent {
  return function BreadcrumbTitle(props) {
    const { children } = props;
    const params = useParams();
    let title: string | undefined = undefined,
      loading = false,
      breadcrumbParams: BreadcrumbParams | undefined;
    const res = useTitle(params);
    if (Array.isArray(res)) {
      title = res[0];
      loading = res[1];
      breadcrumbParams = res[2];
    } else {
      title = res;
    }
    breadcrumbParams = useJsonMemo(breadcrumbParams);
    return children(
      useMemo(
        () => ({ title, loading, params: breadcrumbParams }),
        [breadcrumbParams, loading, title]
      )
    );
  };
}

/**
 * Wrapper for useApiQuery that eliminates common breadcrumb title boilerplate
 *
 * @see {@link useApiQuery}
 */
export function useBreadcrumbApiQuery<TResult>(
  handler: ApiQueryHandler<TResult>,
  titleFn: (data: TResult) => string | undefined
): [title: string | undefined, loading: boolean] {
  const { data, loading } = useApiQuery<TResult>(handler, {
    useErrorBoundary: false,
  });
  return [data ? titleFn(data) : undefined, loading];
}

/**
 * Get a textual title to use for a breadcrumb
 */
export function getBreadcrumbText(breadcrumb: Breadcrumb | string): string {
  if (typeof breadcrumb === 'string') return breadcrumb;
  return breadcrumb.title || '…';
}

/**
 * Get a ReactNode title to use for a breadcrumb
 */
export function getBreadcrumbTitle(
  breadcrumbOrTitle: Breadcrumb | string
): ReactNode {
  const breadcrumb: Partial<Breadcrumb> =
    typeof breadcrumbOrTitle === 'string'
      ? { title: breadcrumbOrTitle }
      : breadcrumbOrTitle;
  let title: ReactNode = breadcrumb.title;

  if (!title) {
    return <LoadingEllipsis loading={breadcrumb.loading ?? false} />;
  }

  if (breadcrumb.params?.variant === 'id') {
    title = <MonoSpan>{title}</MonoSpan>;
  }

  return title;
}
