import {
  IconButton,
  IconButtonProps,
  styled,
  useMediaQuery,
} from '@mui/material';
import { ExpandMoreOutlined as ExpandMoreIcon } from '@mui/icons-material';

const ExpandIconButtonIconButton = styled(IconButton)(
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '& .icon': {
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    '&[aria-expanded="true"] .icon': {
      transform: 'rotate(-180deg)',
    },
    '&.Mui-focusVisible': {
      color: theme.palette.primary.main,
    },
  }),
  { name: 'ExpandIconButtonIconButton' }
);

/**
 * IconButton for initiating editing
 */
export const ExpandIconButton = ({
  in: inProp = false,
  ...props
}: IconButtonProps & { in?: boolean }) => {
  const isFinePointer = useMediaQuery('(pointer: fine)');

  return (
    <ExpandIconButtonIconButton
      color="primary"
      disableRipple={isFinePointer}
      {...props}
      aria-expanded={inProp}
      disableFocusRipple={false}
    >
      <ExpandMoreIcon className="icon" />
    </ExpandIconButtonIconButton>
  );
};
