import { useCallback, useMemo } from 'react';
import awaitPromise from '../utils/awaitPromise';
import { loadOnce } from '../utils/loadOnce';
import { CountryCode } from './types';
import { useLibPhoneNumber } from './useLibPhoneNumber';

const loadPhoneNumberExamples = loadOnce(() =>
  import('libphonenumber-js/examples.mobile.json').then(
    (module) => module.default
  )
);

/**
 * Simplified version of {@link import('libphonenumber-js').getExampleNumber} that loads data via suspense
 */
export function useExamplePhoneNumbers(): (
  country: CountryCode,
  format?: 'national' | 'international'
) => string | undefined {
  const { getExampleNumber } = useLibPhoneNumber();

  // Asynchronusly load example data needed by getExampleNumber
  const phoneNumberExamples = awaitPromise(
    useMemo(() => loadPhoneNumberExamples(), [])
  );

  return useCallback(
    (
      country: CountryCode,
      format: 'national' | 'international' = 'national'
    ) => {
      const number = getExampleNumber(country, phoneNumberExamples);
      return format === 'international'
        ? number?.formatInternational()
        : number?.formatNational();
    },
    [getExampleNumber, phoneNumberExamples]
  );
}
