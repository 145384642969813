import { styled } from '@mui/material';

/**
 * A custom grid layout for Issued Date / Expiry Date field pairs
 */
export const IssuedExpiryFieldLayout = styled('div')(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
    gridGap: theme.spacing(0, 2),
  }),
  {
    name: 'IssuedExpiryFieldLayout',
  }
);
