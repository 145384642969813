import { createReducerContext } from '../../../reducer-context';

const { Provider, useReducerState, useReducerDispatch } = createReducerContext(
  'ExpandedReviewState',
  (state: string | null, newState: string | null) => {
    return newState;
  },
  null
);

export const ExpandedReviewStateProvider = Provider;
export const useExpandedReviewState = (): [
  string | null,
  (state: string | null) => void
] => {
  return [useReducerState(), useReducerDispatch()];
};
