import { DateTime } from 'luxon';

/**
 * Get a timestamp string in the format expected by the server
 * The server accepts ISO timestamps, but it ignores the timezone and assumes everything
 * is in UTC so we have to convert to UTC before formatting.
 */
export function toServerTimestamp(dateTime: DateTime): string;
export function toServerTimestamp(
  dateTime: DateTime | undefined
): string | undefined;
export function toServerTimestamp(
  dateTime: DateTime | undefined | null
): string | undefined | null;
export function toServerTimestamp(
  dateTime: DateTime | undefined | null
): string | undefined | null {
  return dateTime?.toUTC()?.toISO();
}
