import { loadOnce } from '../../utils/loadOnce';

export type LibPhoneNumber = typeof import('libphonenumber-js');

/**
 * Load libphonenumber-js
 */
export const getLibPhoneNumber = loadOnce<LibPhoneNumber>(
  () => import('libphonenumber-js')
);
