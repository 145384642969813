import { useFieldHelpers, useFieldMeta } from '@johnrom/formik-v3';
import { useSnackbar } from 'notistack';
import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type GovIdIssuerFieldProps = Omit<SelectFieldProps, 'options'> & {
  govIssuerVariant?: 'default' | 'contact' | 'company';
};

/**
 * A <Select> field for selecting a goverment id issuer
 */
export const GovIdIssuerField = forwardRef(function GovIdIssuerField(
  props: GovIdIssuerFieldProps,
  ref: any
) {
  const { govIssuerVariant = 'default', ...other } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { value } = useFieldMeta<string>(props.name);
  const { setValue } = useFieldHelpers(props.name);
  const [governmentIdIssuers, loadingGovernmentIdIssuers] =
    useCodes('govtidissuers');
  const [contactGovernmentIdIssuers, loadingContactGovernmentIdIssuers] =
    useCodes('govtcontactidissuers');
  const [companyGovernmentIdIssuers, loadingCompanyGovernmentIdIssuers] =
    useCodes('govtcompanyidissuers');

  let idIssuers = governmentIdIssuers;
  let loadingIdIssuers = loadingGovernmentIdIssuers;
  if (govIssuerVariant === 'contact') {
    idIssuers = contactGovernmentIdIssuers;
    loadingIdIssuers = loadingContactGovernmentIdIssuers;
  } else if (govIssuerVariant === 'company') {
    idIssuers = companyGovernmentIdIssuers;
    loadingIdIssuers = loadingCompanyGovernmentIdIssuers;
  }

  useEffect(() => {
    if (!idIssuers || !governmentIdIssuers) return;

    if (
      !idIssuers.some((issuer) => issuer.Id === value) &&
      governmentIdIssuers.some((issuer) => issuer.Id === value)
    ) {
      setValue('');
      enqueueSnackbar(t('error.invalidGovernmentIdIssuer'), {
        variant: 'error',
      });
    }
  }, [
    enqueueSnackbar,
    governmentIdIssuers,
    idIssuers,
    props.name,
    setValue,
    t,
    value,
  ]);

  const optionProps = useCodeOptionsProps(idIssuers);

  return (
    <SelectField
      ref={ref}
      {...other}
      {...optionProps}
      loading={loadingIdIssuers}
    />
  );
});
