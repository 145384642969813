import {
  BehaviourDownload,
  BehaviourReport,
  Behaviours,
  NotificationResponse,
} from '../../api';

function getBehaviours<Type extends keyof Behaviours>(
  notification: NotificationResponse,
  key: Type
): NonNullable<Behaviours[Type]> {
  if (notification.BehavioursAsMap && notification.BehavioursAsMap[key]) {
    return notification.BehavioursAsMap[key]!;
  }

  return [] as any;
}

export function getNotificationSingleDownloadBehaviour(
  notification: NotificationResponse
): BehaviourDownload | undefined {
  const downloads = getBehaviours(notification, 'Download');
  return downloads.length === 1 ? downloads[0] : undefined;
}

export function getNotificationSingleReportBehaviour(
  notification: NotificationResponse
): BehaviourReport | undefined {
  const reports = getBehaviours(notification, 'Report');
  return reports.length === 1 ? reports[0] : undefined;
}
