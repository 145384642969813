import invariant from 'invariant';

invariant(
  process.env.REACT_APP_API_BASE,
  'REACT_APP_API_BASE was not configured'
);
export const API_BASE =
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_API_USEPROXY === 'true'
    ? '/api'
    : process.env.REACT_APP_API_BASE;
