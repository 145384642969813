import { forwardRef } from 'react';
import { ThirdPartyType } from '../../../api';
import { useThirdPartyTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ThirdPartyTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a ThirdPartyType
 */
export const ThirdPartyTypeField = forwardRef(function ThirdPartyTypeField(
  props: ThirdPartyTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    ThirdPartyType,
    useThirdPartyTypeLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
