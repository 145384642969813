import { InputAdornment, TextField } from '@mui/material';
import { forwardRef, Ref, useMemo } from 'react';
import { NumberFormatProps } from 'react-number-format';
import { useCurrencyFormatters, useLocale } from '../../intl';
import { NumberInput, NumberInputProps } from '../input';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type CurrencyAmountFieldProps = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  CommonValidatorProps &
  Pick<NumberInputProps, 'min' | 'max' | 'step'> & {
    readOnly?: boolean;
    currency?: string;
  };

/**
 * A <TextField> for a numeric amount with currency formatting
 */
export const CurrencyAmountField = forwardRef(function CurrencyAmountField(
  props: CurrencyAmountFieldProps,
  ref: Ref<HTMLInputElement>
) {
  const { min, max, step, currency, ...other } = usePropsWithId(props);
  const validate = useCommonValidate(props);

  const formatCurrency = useCurrencyFormatters();
  const locale = useLocale();
  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat(
        locale,
        currency ? { style: 'currency', currency } : { style: 'decimal' }
      ),
    [currency, locale]
  );
  const numberProps = useMemo<NumberFormatProps>(() => {
    // const fraction = (parts: Intl.NumberFormatPart[]) =>
    //   parts.filter((part) => part.type === 'fraction').join('');
    // const minimumFractionDigits = fraction(
    //   currencyFormatter.formatToParts(123456789)
    // ).length;
    // const maximumFractionDigits = fraction(
    //   currencyFormatter.formatToParts(123456789.0123456789)
    // ).length;
    // @note NumberFormat supports a fixed decimalScale but not a min-max fraction range
    // @todo Investigate how many currency/locale ranges have minimumFractionDigits===maximumFractionDigits and if it's useful adding decimalScale in those circumstances
    return {};
  }, []);
  const { prefix, suffix } = useMemo(() => {
    const p = currencyFormatter.formatToParts(123456789.01);
    const symbol = (part: Intl.NumberFormatPart) =>
      part.type === 'currency' ? part.value : undefined;
    const prefix = symbol(p[0]);
    const suffix = symbol(p[p.length - 1]);

    return { prefix, suffix };
  }, [currencyFormatter]);

  const textFieldProps = useTextFieldProps({
    ...other,
    validate,
    InputProps: {
      startAdornment: prefix && (
        <InputAdornment position="start">{prefix}</InputAdornment>
      ),
      endAdornment: suffix && (
        <InputAdornment position="end">{suffix}</InputAdornment>
      ),
      ...other.InputProps,
      inputComponent: NumberInput,
    },
    inputProps: {
      ...numberProps,
      min,
      max,
      step,
      ...other.inputProps,
    },
  });

  if (props.readOnly)
    return (
      <ReadOnlyField
        ref={ref}
        {...textFieldProps}
        value={
          currency
            ? formatCurrency(currency, parseFloat(textFieldProps.value as any))
            : textFieldProps.value
        }
      />
    );

  return <TextField ref={ref} {...textFieldProps} />;
});
