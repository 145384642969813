import { CircularProgress, styled } from '@mui/material';
import { ComponentProps, ReactNode, Suspense } from 'react';

export const FieldSetContainer = styled('fieldset')(
  ({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.23)'
        : 'rgba(255, 255, 255, 0.23)',
    padding: theme.spacing(1),
  }),
  { name: 'FieldSetContainer' }
);
export const FieldSetLegend = styled('legend')(
  ({ theme }) => ({
    padding: theme.spacing(0, 1),
  }),
  { name: 'FieldSetLegend' }
);
const FieldSetLoading = styled('div', { name: 'FieldSet', slot: 'Loading' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export type FieldSetProps = ComponentProps<typeof FieldSetContainer> & {
  title?: ReactNode;
  children?: ReactNode;
  legendProps?: ComponentProps<typeof FieldSetLegend>;
  required?: boolean;
  suspense?: boolean;
};

/**
 * <fieldset> modeled after the OutlinedInput style
 */
export const FieldSet = (props: FieldSetProps) => {
  const {
    title,
    children,
    legendProps,
    required,
    suspense = false,
    ...containerProps
  } = props;

  let body = children;
  if (suspense) {
    body = (
      <Suspense
        fallback={
          <FieldSetLoading>
            <CircularProgress size={64} />
          </FieldSetLoading>
        }
      >
        {body}
      </Suspense>
    );
  }

  return (
    <FieldSetContainer {...containerProps}>
      {title && (
        <FieldSetLegend {...legendProps}>
          {title}
          {required ? ' *' : null}
        </FieldSetLegend>
      )}
      {body}
    </FieldSetContainer>
  );
};
