import { AxiosRequestConfig } from 'axios';
import { useCallback, useMemo } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import {
  Account,
  AccountAdminBody,
  AccountsApiActivateAccountArgs,
  AccountsApiQueryKey,
  AccountsApiTerminateAccountArgs,
  AdminApiActivateClientAccountArgs,
  AdminApiQueryKey,
  AdminApiTerminateClientAccountArgs,
  ApiMutationOptions,
  ApiMutationState,
  ApiQueryOptions,
  ApiQueryState,
  MiscApiQueryKey,
  useAccountsApi,
  useAdminApi,
  useApiMutation,
  useApiQuery,
} from '../client';
import { mergeMutationState } from './internal/mergeMutationState';

/**
 * Handle cache updates for an Account
 */
export function updateCachedAccount(
  queryClient: QueryClient,
  clientId: string | undefined,
  accountId: string,
  account?: Account | AccountAdminBody
): void {
  // Client
  if (account) {
    queryClient.setQueryData(
      AccountsApiQueryKey.getAccount(accountId),
      account
    );
  } else {
    queryClient.invalidateQueries(AccountsApiQueryKey.getAccount(accountId));
  }
  queryClient.invalidateQueries(MiscApiQueryKey.getCodes('accounts'));
  queryClient.invalidateQueries(AccountsApiQueryKey.getAccountsRoot);
  queryClient.invalidateQueries(AccountsApiQueryKey.getAllAccountSummariesRoot);

  // Admin
  if (clientId) {
    // @fixme Try and get a ClientId from Account as a fallback
    if (account) {
      queryClient.setQueryData(
        AdminApiQueryKey.getClientAccount(clientId, accountId),
        account
      );
    } else {
      queryClient.invalidateQueries(
        AdminApiQueryKey.getClientAccount(clientId, accountId)
      );
    }
    queryClient.invalidateQueries([
      AdminApiQueryKey.getAllClientAccountsRoot,
      clientId,
    ]);
    queryClient.invalidateQueries(AdminApiQueryKey.getAllClientsAccountsRoot);
    queryClient.invalidateQueries(
      AdminApiQueryKey.getAllClientAccountSummariesRoot
    );
  }
}

/**
 * Get an Account
 *
 * @see {@link AdminApiMaker.getClientAccount}
 * @see {@link AccountsApiMaker.getAccount}
 */
export function useAccount(
  clientId: string | undefined | null,
  accountId: string,
  options: ApiQueryOptions<Account | AccountAdminBody> = {}
): ApiQueryState<Account | AccountAdminBody> {
  const accountsApi = useAccountsApi();
  const adminApi = useAdminApi();

  return useApiQuery(
    useMemo(() => {
      return clientId
        ? adminApi.getClientAccount(clientId ?? '', accountId)
        : accountsApi.getAccount(accountId);
    }, [accountId, accountsApi, adminApi, clientId]),
    options
  );
}

/**
 * Reactivate an account (client and account compatible)
 *
 * @see {@link AccountsApiMaker.activateAccountMutation}
 * @see {@link AdminApiMaker.activateClientAccountMutation}
 */
export function useActivateAccountMutation(
  options?: ApiMutationOptions<
    AccountsApiActivateAccountArgs | AdminApiActivateClientAccountArgs,
    Account | AccountAdminBody
  >
): [
  (
    clientId: string | undefined,
    accountId: string
  ) => Promise<Account | AccountAdminBody>,
  ApiMutationState<Account | AccountAdminBody>
] {
  const queryClient = useQueryClient();
  const [activateAccount, clientMutationState] = useApiMutation({
    ...options,
    ...useAccountsApi().activateAccountMutation(),
    onSuccess(...args) {
      const [account, [accountId]] = args;
      updateCachedAccount(queryClient, undefined, accountId, account);
      options?.onSuccess?.(...args);
    },
  });
  const [activateClientAccount, adminMutationState] = useApiMutation({
    ...options,
    ...useAdminApi().activateClientAccountMutation(),
    onSuccess(...args) {
      const [account, [clientId, accountId]] = args;
      updateCachedAccount(queryClient, clientId, accountId, account);
      options?.onSuccess?.(...args);
    },
  });

  return [
    useCallback(
      async (
        clientId: string | undefined,
        accountId: string,
        axiosOptions?: AxiosRequestConfig
      ) => {
        if (clientId) {
          return await activateClientAccount(clientId, accountId, axiosOptions);
        } else {
          return await activateAccount(accountId, axiosOptions);
        }
      },
      [activateAccount, activateClientAccount]
    ),
    mergeMutationState<Account | AccountAdminBody>(
      clientMutationState,
      adminMutationState
    ),
  ];
}

/**
 * Temporarily disable an account (client and account compatible)
 *
 * @see {@link AccountsApiMaker.suspendAccountMutation}
 */
export function useSuspendAccountMutation(
  options?: ApiMutationOptions<
    AccountsApiTerminateAccountArgs | AdminApiTerminateClientAccountArgs,
    Account | AccountAdminBody
  >
): [
  (
    clientId: string | undefined,
    accountId: string
  ) => Promise<Account | AccountAdminBody>,
  ApiMutationState<Account | AccountAdminBody>
] {
  const queryClient = useQueryClient();
  const [suspendAccount, clientMutationState] = useApiMutation({
    ...options,
    ...useAccountsApi().suspendAccountMutation(),
    onSuccess(...args) {
      const [account, [accountId]] = args;
      updateCachedAccount(queryClient, undefined, accountId, account);
      options?.onSuccess?.(...args);
    },
  });
  const [suspendClientAccount, adminMutationState] = useApiMutation({
    ...options,
    ...useAdminApi().suspendClientAccountMutation(),
    onSuccess(...args) {
      const [account, [clientId, accountId]] = args;
      updateCachedAccount(queryClient, clientId, accountId, account);
      options?.onSuccess?.(...args);
    },
  });

  return [
    useCallback(
      async (
        clientId: string | undefined,
        accountId: string,
        axiosOptions?: AxiosRequestConfig
      ) => {
        if (clientId) {
          return await suspendClientAccount(clientId, accountId, axiosOptions);
        } else {
          return await suspendAccount(accountId, axiosOptions);
        }
      },
      [suspendAccount, suspendClientAccount]
    ),
    mergeMutationState<Account | AccountAdminBody>(
      clientMutationState,
      adminMutationState
    ),
  ];
}

/**
 * Permanently close an account (client and account compatible)
 *
 * @see {@link AccountsApiMaker.terminateAccountMutation}
 * @see {@link AdminApiMaker.terminateClientAccountMutation}
 */
export function useTerminateAccountMutation(
  options?: ApiMutationOptions<
    AccountsApiTerminateAccountArgs | AdminApiTerminateClientAccountArgs,
    Account | AccountAdminBody
  >
): [
  (
    clientId: string | undefined,
    accountId: string
  ) => Promise<Account | AccountAdminBody>,
  ApiMutationState<Account | AccountAdminBody>
] {
  const queryClient = useQueryClient();

  const [terminateAccount, clientMutationState] = useApiMutation({
    ...options,
    ...useAccountsApi().terminateAccountMutation(),
    onSuccess(...args) {
      const [account, [accountId]] = args;
      updateCachedAccount(queryClient, undefined, accountId, account);
      options?.onSuccess?.(...args);
    },
  });
  const [terminateClientAccount, adminMutationState] = useApiMutation({
    ...options,
    ...useAdminApi().terminateClientAccountMutation(),
    onSuccess(...args) {
      const [account, [clientId, accountId]] = args;
      updateCachedAccount(queryClient, clientId, accountId, account);
      options?.onSuccess?.(...args);
    },
  });

  return [
    useCallback(
      async (
        clientId: string | undefined,
        accountId: string,
        axiosOptions?: AxiosRequestConfig
      ) => {
        if (clientId) {
          return await terminateClientAccount(
            clientId,
            accountId,
            axiosOptions
          );
        } else {
          return await terminateAccount(accountId, axiosOptions);
        }
      },
      [terminateAccount, terminateClientAccount]
    ),
    mergeMutationState<Account | AccountAdminBody>(
      clientMutationState,
      adminMutationState
    ),
  ];
}
