import { Popover, PopoverProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { thinScrollbar } from '../utils/scroll';
import SrOnly from '../utils/SrOnly';
import { NotificationTabs, NotificationTabsProps } from './NotificationTabs';
import { NotificationList, NotificationListProps } from './ui';

export type NotificationPopoverProps = Pick<
  NotificationTabsProps,
  'unreadCount' | 'currentTab' | 'onChangeTab'
> &
  PopoverProps &
  NotificationListProps;

/**
 * Popover with the notifications list
 */
const NotificationPopover = (props: NotificationPopoverProps) => {
  const {
    currentTab,
    onChangeTab,
    notifications,
    unreadCount,
    loading,
    error,
    markAsRead,
    ...popoverProps
  } = props;
  const { t } = useTranslation();

  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      {...popoverProps}
      PaperProps={{
        sx: {
          width: 500,
          maxWidth: '95vw',
          ...thinScrollbar,
        },
      }}
    >
      <SrOnly as="h2">{t('title.notifications')}</SrOnly>
      <NotificationTabs {...{ unreadCount, currentTab, onChangeTab }} />
      <NotificationList {...{ notifications, loading, error, markAsRead }} />
    </Popover>
  );
};

export default NotificationPopover;
