import {
  AutocompleteRenderOptionState,
  createFilterOptions,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import { useDebouncedState } from '@react-hookz/web/esm';
import ms from 'ms';
import {
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApiInfiniteQueryState,
  ApiQueryHandler,
  ApiQueryState,
  isHttpNotFoundError,
  isResourceNotFoundError,
  useApiInfiniteQuery,
  useApiQuery,
} from '../../../api';
import { dataTableMaxItemCount } from '../../../table';
import {
  AutocompleteField,
  AutocompleteFieldProps,
} from '../AutocompleteField';

const filter = createFilterOptions<string>();
export const NEXT_PAGE_MESSAGE = '\u001B__NEXT_PAGE_MESSAGE';

/**
 * State helper that manages a debounced version of an autocomplete's input text
 */
export function useInputTextState(
  delay = 300,
  maxWait = 800
): [
  inputText: string,
  onInputChange: (event: React.SyntheticEvent, value: string) => void
] {
  const [inputText, setInputText] = useDebouncedState('', delay, maxWait);

  const onInputChange = useCallback(
    (e: React.SyntheticEvent, inputValue: string) => {
      setInputText(inputValue ?? '');
    },
    [setInputText]
  );

  return [inputText, onInputChange];
}

export interface UseComboBoxApiQueryOptions<T> {
  value: string | null;
  open: boolean;
  searchText: string | undefined;
  getOneByValue: (value: string) => ApiQueryHandler<T>;
  searchAllByValue: (searchText: string | undefined) => ApiQueryHandler<T[]>;
  preCacheOneItem: (item: T) => void;
  preload?: boolean;
}

export interface UseComboBoxApiQueryResult<T> {
  oneData: ApiQueryState<T>['data'];
  oneError: ApiQueryState<T>['error'];
  loadingOne: ApiQueryState<T>['loading'];
  allData: ApiInfiniteQueryState<T[]>['data'];
  allError: ApiInfiniteQueryState<T[]>['error'];
  loadingAll: ApiInfiniteQueryState<T[]>['loading'];
  hasNextPage: ApiInfiniteQueryState<T[]>['hasNextPage'];
  loading: boolean;
}

export function useComboBoxApiQuery<T>(
  options: UseComboBoxApiQueryOptions<T>
): UseComboBoxApiQueryResult<T> {
  const {
    value,
    open,
    searchText,
    getOneByValue,
    searchAllByValue,
    preCacheOneItem,
    preload = false,
  } = options;

  const {
    data: oneData,
    error: oneError,
    loading: loadingOne,
  } = useApiQuery<T>(
    (() => {
      if (!value) return null;
      return getOneByValue(value);
    })(),
    {
      staleTime: ms('10 minutes'),
      useErrorBoundary: (err) =>
        !isResourceNotFoundError(err) && !isHttpNotFoundError(err),
    }
  );

  const {
    data: allData,
    error: allError,
    loading: loadingAll,
    hasNextPage,
  } = useApiInfiniteQuery<T>(searchAllByValue(searchText), {
    skip: !preload && !open,
    maxItemCount: searchText ? 10 : dataTableMaxItemCount,
    delay: ms('200ms'),
    staleTime: ms('10 minutes'),
    keepPreviousData: true,
    onSuccess({ pages }) {
      const data = pages.flatMap((page) => page);
      // Pre-cache oneData with the results

      for (const item of data) {
        preCacheOneItem(item);
      }
    },
  });

  return {
    oneData,
    oneError,
    loadingOne,
    allData,
    allError,
    loadingAll,
    hasNextPage,
    loading: loadingAll || loadingOne,
  };
}

/**
 * Takes an options list and a "current" option, returns the option list making sure the "current" option is present.
 * Prepends the "current" option if it is missing.
 */
export function useOptionsWithCurrent<T>(
  allOptions: T[] | undefined | null,
  currentOption: T | undefined | null,
  getId: (option: T) => unknown
): T[] {
  return useMemo(() => {
    const options = allOptions ?? [];

    if (currentOption) {
      if (
        !getId(currentOption) ||
        !options.some((option) => getId(option) === getId(currentOption))
      ) {
        return allOptions ? [currentOption, ...allOptions] : [currentOption];
      }
    }

    return options;
  }, [allOptions, currentOption, getId]);
}

export type DynamicComboBoxFieldProps = Omit<
  AutocompleteFieldProps<string, false, false>,
  | 'onChange'
  | 'freeSolo'
  | 'disableClearable'
  | 'freeSolo'
  | 'disabledItemsFocusable'
  | 'forcePopupIcon'
  | 'handleHomeEndKeys'
  | 'openOnFocus'
  | 'selectOnFocus'
> & {
  hasNextPage?: boolean;
  renderNextPageOption?: (props: HTMLAttributes<HTMLLIElement>) => ReactNode;
  inputPrefix?: ReactNode;
};

/**
 * An Autocomplete field setup to make a single-selection combobox for dynamic options
 */
export const DynamicComboBoxField = forwardRef(function DynamicComboBoxField(
  props: DynamicComboBoxFieldProps,
  ref: Ref<any>
) {
  const {
    getOptionDisabled: getOptionDisabledProp,
    renderOption: renderOptionProp,
    renderNextPageOption,
    hasNextPage,
    inputPrefix,
    ...other
  } = props;
  const { t } = useTranslation();

  const getOptionDisabled = useCallback(
    (option: string): boolean => {
      if (option === NEXT_PAGE_MESSAGE) {
        return true;
      }

      if (getOptionDisabledProp) {
        return getOptionDisabledProp(option);
      }

      return false;
    },
    [getOptionDisabledProp]
  );

  const renderOption = useCallback(
    (
      props: HTMLAttributes<HTMLLIElement>,
      option: string,
      state: AutocompleteRenderOptionState
    ): ReactNode => {
      if (option === NEXT_PAGE_MESSAGE) {
        if (renderNextPageOption) {
          return renderNextPageOption(props);
        } else {
          return (
            <MenuItem
              {...props}
              sx={{ color: 'text.secondary' }}
              key="next-page-message"
            >
              {t('label.refineSearchToSeeMore')}
            </MenuItem>
          );
        }
      }

      if (renderOptionProp) {
        return renderOptionProp(props, option, state);
      }
    },
    [renderNextPageOption, renderOptionProp, t]
  );

  return (
    <AutocompleteField<string, false, false>
      ref={ref}
      {...other}
      {...{ getOptionDisabled, renderOption }}
      InputProps={{
        ...other.InputProps,
        startAdornment:
          !props.open && inputPrefix ? (
            <InputAdornment
              position="start"
              sx={{
                // Workaround for https://github.com/mui/material-ui/issues/22544
                '&.MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled:not(.MuiInputAdornment-hiddenLabel)':
                  {
                    mt: 0,
                  },
              }}
            >
              {inputPrefix}
            </InputAdornment>
          ) : (
            other.InputProps?.startAdornment
          ),
      }}
      freeSolo={false}
      disabledItemsFocusable
      forcePopupIcon
      handleHomeEndKeys={false}
      openOnFocus
      selectOnFocus
      filterOptions={(options, params) => {
        const filtered = props.filterOptions
          ? props.filterOptions(options, params)
          : filter(options, params);

        if (hasNextPage) {
          filtered.push(NEXT_PAGE_MESSAGE);
        }

        return filtered;
      }}
    />
  );
}) as (props: DynamicComboBoxFieldProps & { ref?: Ref<any> }) => ReactElement;
