import { useLocalStorageValue } from '@react-hookz/web/esm';
import { useCallback } from 'react';
import { createReducedStateContext } from '../../reducer-context';

export enum TimeZoneSetting {
  Local = 'local',
  UTC = 'utc',
}

const { Provider, useReducerDispatch, useReducerState } =
  createReducedStateContext(
    'TimeZone',
    () => {
      const [timeZoneSetting, setTimeZoneSetting] =
        useLocalStorageValue<TimeZoneSetting>(
          'transactive-timezone-setting',
          TimeZoneSetting.Local
        );

      const dispatch = useCallback(
        (action: TimeZoneSetting) => {
          setTimeZoneSetting(action);
        },
        [setTimeZoneSetting]
      );

      return [timeZoneSetting, dispatch];
    },
    TimeZoneSetting.Local,
    () => {
      console.warn(
        `Default timezone displatcher called, TimeZone root may not be setup.`
      );
    }
  );

/**
 * Provides timezone setting
 */
export const TimeZoneSettingProvider = Provider;

/**
 * Get a dispatcher to update timezone setting
 */
export const useTimeZoneSettingDispatch = () => useReducerDispatch();

/**
 * Get the current timezone setting
 */
export const useTimeZoneSetting = () => useReducerState();
