import { DateIOFormats, IUtils } from '@date-io/core/IUtils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { ReactNode, useMemo } from 'react';
import { useLocale } from '../intl/locale';
import { useTimeZoneSetting } from '../time';
import LuxonUtils from './internal/LuxonAdapter';

export interface DatePickersIntlProviderProps {
  children: ReactNode;
}

export interface DateIoAdapter {
  new (options: {
    formats?: Partial<DateIOFormats>;
    locale?: string;
  }): IUtils<DateTime>;
}

/**
 * Provides luxon and a locale to MUI lab's LocalizationProvider used by date pickers
 */
export const DatePickersProvider = ({
  children,
}: DatePickersIntlProviderProps) => {
  const locale = useLocale();
  const timeZone = useTimeZoneSetting();
  const Adapter = useMemo<DateIoAdapter>(() => {
    class Adapter extends LuxonUtils {
      constructor(options: {
        formats?: Partial<DateIOFormats>;
        locale?: string;
      }) {
        super({ ...options, timeZone });
      }
    }

    return Adapter;
  }, [timeZone]);

  return (
    <LocalizationProvider dateAdapter={Adapter} locale={locale}>
      {children}
    </LocalizationProvider>
  );
};
