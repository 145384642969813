import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { RemoveIconButton } from '../../form';

export interface PreviewTitleProps {
  children?: ReactNode;
  title?: ReactNode;
  onRemove?: () => void;
}

export const PreviewTitle = (props: PreviewTitleProps) => {
  const { title, children, onRemove } = props;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {title && <Typography variant="body2">{title}</Typography>}
      {children && (
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      )}
      <Box flex={1} />
      {onRemove && (
        <RemoveIconButton
          variant="close"
          color="default"
          size="small"
          onClick={() => onRemove()}
        />
      )}
    </Box>
  );
};
