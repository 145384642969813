/**
 * Forked from https://github.com/dmtrKovalenko/date-io/blob/b54e7d1/packages/luxon/src/luxon-utils.ts
 */
import { DateTime, Settings, Info } from 'luxon';
import { IUtils, DateIOFormats, Unit } from '@date-io/core/IUtils';

const defaultFormats: DateIOFormats = {
  dayOfMonth: 'd',
  fullDate: 'DD',
  fullDateWithWeekday: 'DDDD',
  fullDateTime: 'ff',
  fullDateTime12h: 'DD, hh:mm a',
  fullDateTime24h: 'DD, T',
  fullTime: 't',
  fullTime12h: 'hh:mm a',
  fullTime24h: 'HH:mm',
  hours12h: 'hh',
  hours24h: 'HH',
  keyboardDate: 'D',
  keyboardDateTime: 'D t',
  keyboardDateTime12h: 'D hh:mm a',
  keyboardDateTime24h: 'D T',
  minutes: 'mm',
  seconds: 'ss',
  month: 'LLLL',
  monthAndDate: 'MMMM d',
  monthAndYear: 'LLLL yyyy',
  monthShort: 'MMM',
  weekday: 'cccc',
  weekdayShort: 'ccc',
  normalDate: 'd MMMM',
  normalDateWithWeekday: 'EEE, MMM d',
  shortDate: 'MMM d',
  year: 'yyyy',
};

interface DateMethod {
  (): DateTime;
  (value: undefined | string | DateTime | Date): DateTime;
  (value: undefined | string | DateTime | Date | null): DateTime | null;
}

function isLocalZone(zone: string | undefined): boolean {
  return !zone || zone === 'local' || zone === 'system';
}

function isUtcZone(zone: string | undefined): boolean {
  return zone?.toLowerCase() === 'utc';
}

export default class LuxonUtils implements IUtils<DateTime> {
  public lib = 'luxon';
  public locale: string;
  public timeZone: string;
  public formats: DateIOFormats;

  constructor({
    timeZone,
    locale,
    formats,
  }: {
    formats?: Partial<DateIOFormats>;
    locale?: string;
    timeZone?: string;
  } = {}) {
    this.timeZone = timeZone || 'local';
    this.locale = locale || 'en-US';
    this.formats = Object.assign({}, defaultFormats, formats);
  }

  public toZone = (value: DateTime): DateTime => {
    if (isLocalZone(this.timeZone)) return value.toLocal();
    if (isUtcZone(this.timeZone)) return value.toUTC();
    return value.setZone(this.timeZone);
  };

  public date: DateMethod = (value?: any): any => {
    if (typeof value === 'undefined') {
      if (isLocalZone(this.timeZone)) return DateTime.local();
      if (isUtcZone(this.timeZone)) return DateTime.utc();
      return this.toZone(DateTime.local());
    }

    if (value === null) {
      return null;
    }

    if (typeof value === 'string') {
      return this.toZone(
        DateTime.fromJSDate(new Date(value)).setLocale(this.locale)
      );
    }

    if (value instanceof DateTime) {
      return this.toZone(value);
    }

    return this.toZone(DateTime.fromJSDate(value).setLocale(this.locale));
  };

  public toJsDate = (value: DateTime) => {
    return value.toJSDate();
  };

  public parseISO = (isoString: string) => {
    return DateTime.fromISO(isoString, {
      locale: this.locale,
      zone: this.timeZone,
    });
  };

  public toISO = (value: DateTime) => {
    return this.toZone(value).toISO({ format: 'extended' });
  };

  public parse = (value: string, formatString: string) => {
    if (value === '') {
      return null;
    }

    return this.toZone(
      DateTime.fromFormat(value, formatString, { locale: this.locale })
    );
  };

  /* istanbul ignore next */
  public is12HourCycleInCurrentLocale = () => {
    if (
      typeof Intl === 'undefined' ||
      typeof Intl.DateTimeFormat === 'undefined'
    ) {
      return true; // Luxon defaults to en-US if Intl not found
    }

    return Boolean(
      new Intl.DateTimeFormat(this.locale, {
        hour: 'numeric',
      })?.resolvedOptions()?.hour12
    );
  };

  public getFormatHelperText = (format: string) => {
    // Unfortunately there is no way for luxon to retrieve readable formats from localized format
    return '';
  };

  /* istanbul ignore next */
  public getCurrentLocaleCode = () => {
    return this.locale || Settings.defaultLocale;
  };

  public addSeconds = (date: DateTime, count: number) => {
    return this.toZone(date).plus({ seconds: count });
  };

  public addMinutes = (date: DateTime, count: number) => {
    return this.toZone(date).plus({ minutes: count });
  };

  public addHours = (date: DateTime, count: number) => {
    return this.toZone(date).plus({ hours: count });
  };

  public addDays = (date: DateTime, count: number) => {
    return this.toZone(date).plus({ days: count });
  };

  public addWeeks = (date: DateTime, count: number) => {
    return this.toZone(date).plus({ weeks: count });
  };

  public addMonths = (date: DateTime, count: number) => {
    return this.toZone(date).plus({ months: count });
  };

  public isValid = (value: any) => {
    if (value instanceof DateTime) {
      return value.isValid;
    }

    if (value === null) {
      return false;
    }

    return this.date(value).isValid;
  };

  public isEqual = (value: any, comparing: any) => {
    if (value === null && comparing === null) {
      return true;
    }

    // make sure that null will not be passed to this.date
    if (value === null || comparing === null) {
      return false;
    }

    return this.date(value).equals(this.date(comparing));
  };

  public isSameDay = (date: DateTime, comparing: DateTime) => {
    return this.toZone(date).hasSame(comparing, 'day');
  };

  public isSameMonth = (date: DateTime, comparing: DateTime) => {
    return this.toZone(date).hasSame(comparing, 'month');
  };

  public isSameYear = (date: DateTime, comparing: DateTime) => {
    return this.toZone(date).hasSame(comparing, 'year');
  };

  public isSameHour = (date: DateTime, comparing: DateTime) => {
    return this.toZone(date).hasSame(comparing, 'hour');
  };

  public isAfter = (value: DateTime, comparing: DateTime) => {
    return value > comparing;
  };

  public isBefore = (value: DateTime, comparing: DateTime) => {
    return value < comparing;
  };

  public isBeforeDay = (value: DateTime, comparing: DateTime) => {
    const diff = this.toZone(value)
      .diff(this.toZone(comparing).startOf('day'), 'days')
      .toObject();
    return diff.days! < 0;
  };

  public isAfterDay = (value: DateTime, comparing: DateTime) => {
    const diff = this.toZone(value)
      .diff(this.toZone(comparing).endOf('day'), 'days')
      .toObject();
    return diff.days! > 0;
  };

  public isBeforeYear = (value: DateTime, comparing: DateTime) => {
    const diff = this.toZone(value)
      .diff(this.toZone(comparing).startOf('year'), 'years')
      .toObject();
    return diff.years! < 0;
  };

  public isAfterYear = (value: DateTime, comparing: DateTime) => {
    const diff = this.toZone(value)
      .diff(this.toZone(comparing).endOf('year'), 'years')
      .toObject();
    return diff.years! > 0;
  };

  public getDiff = (
    value: DateTime,
    comparing: DateTime | string,
    unit?: Unit
  ) => {
    if (typeof comparing === 'string') {
      comparing = this.date(comparing);
    }

    if (unit) {
      return Math.floor(this.toZone(value).diff(comparing).as(unit));
    }
    return this.toZone(value).diff(comparing).as('millisecond');
  };

  public startOfDay = (value: DateTime) => {
    return this.toZone(value).startOf('day');
  };

  public endOfDay = (value: DateTime) => {
    return this.toZone(value).endOf('day');
  };

  public format = (date: DateTime, formatKey: keyof DateIOFormats) => {
    return this.formatByString(this.toZone(date), this.formats[formatKey]);
  };

  public formatByString = (date: DateTime, format: string) => {
    return this.toZone(date).setLocale(this.locale).toFormat(format);
  };

  public formatNumber = (numberToFormat: string) => {
    return numberToFormat;
  };

  public getHours = (value: DateTime) => {
    return this.toZone(value).get('hour');
  };

  public setHours = (value: DateTime, count: number) => {
    return this.toZone(value).set({ hour: count });
  };

  public getMinutes = (value: DateTime) => {
    return this.toZone(value).get('minute');
  };

  public setMinutes = (value: DateTime, count: number) => {
    return this.toZone(value).set({ minute: count });
  };

  public getSeconds = (value: DateTime) => {
    return this.toZone(value).get('second');
  };

  public setSeconds = (value: DateTime, count: number) => {
    return this.toZone(value).set({ second: count });
  };

  public getMonth = (value: DateTime) => {
    // See https://github.com/moment/luxon/blob/master/docs/moment.md#major-functional-differences
    return this.toZone(value).get('month') - 1;
  };

  public getDaysInMonth = (value: DateTime) => {
    return this.toZone(value).daysInMonth;
  };

  public setMonth = (value: DateTime, count: number) => {
    return this.toZone(value).set({ month: count + 1 });
  };

  public getYear = (value: DateTime) => {
    return this.toZone(value).get('year');
  };

  public setYear = (value: DateTime, year: number) => {
    return this.toZone(value).set({ year });
  };

  public mergeDateAndTime = (date: DateTime, time: DateTime) => {
    return this.toZone(date).set({
      second: time.second,
      hour: time.hour,
      minute: time.minute,
    });
  };

  public startOfYear = (value: DateTime) => {
    return this.toZone(value).startOf('year');
  };

  public endOfYear = (value: DateTime) => {
    return this.toZone(value).endOf('year');
  };

  public startOfMonth = (value: DateTime) => {
    return this.toZone(value).startOf('month');
  };

  public endOfMonth = (value: DateTime) => {
    return this.toZone(value).endOf('month');
  };

  public startOfWeek = (value: DateTime) => {
    return this.toZone(value).startOf('week');
  };

  public endOfWeek = (value: DateTime) => {
    return this.toZone(value).endOf('week');
  };

  public getNextMonth = (value: DateTime) => {
    return this.toZone(value).plus({ months: 1 });
  };

  public getPreviousMonth = (value: DateTime) => {
    return this.toZone(value).minus({ months: 1 });
  };

  public getMonthArray = (date: DateTime) => {
    const firstMonth = this.date(date).startOf('year');
    const monthArray = [firstMonth];

    while (monthArray.length < 12) {
      const prevMonth = monthArray[monthArray.length - 1];
      monthArray.push(this.getNextMonth(prevMonth));
    }

    return monthArray;
  };

  public getWeekdays = () => {
    return Info.weekdaysFormat('narrow', { locale: this.locale });
  };

  public getWeekArray = (date: DateTime) => {
    date = this.toZone(date);
    const { days } = date
      .endOf('month')
      .endOf('week')
      .diff(date.startOf('month').startOf('week'), 'days')
      .toObject();

    const weeks: DateTime[][] = [];
    new Array<number>(Math.round(days!))
      .fill(0)
      .map((_, i) => i)
      .map((day) => date.startOf('month').startOf('week').plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    return weeks;
  };

  public getYearRange = (start: DateTime, end: DateTime) => {
    const startDate = this.date(start).startOf('year');
    const endDate = this.date(end).endOf('year');

    let current = startDate;
    const years: DateTime[] = [];

    while (current < endDate) {
      years.push(current);
      current = current.plus({ year: 1 });
    }

    return years;
  };

  public getMeridiemText = (ampm: 'am' | 'pm') => {
    return Info.meridiems({ locale: this.locale }).find(
      (v) => v.toLowerCase() === ampm.toLowerCase()
    )!;
  };

  public isNull = (date: DateTime | null) => {
    return date === null;
  };

  public isWithinRange = (
    date: DateTime,
    [start, end]: [DateTime, DateTime]
  ) => {
    date = this.toZone(date);
    return (
      date.equals(start) ||
      date.equals(end) ||
      (this.isAfter(date, start) && this.isBefore(date, end))
    );
  };
}
