import { GlobalStyles, styled } from '@mui/material';
import { useLayoutEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { gridAreas } from '../utils/css-grid';
import BaseLayout from './BaseLayout';
import Footer from './Footer';
import NavigationSidebar from './NavigationSidebar';
import { SidebarExpansionProvider } from './sidebar-expansion';
import SiteHeader from './SiteHeader';
import SiteProgress from './SiteProgress';
import SkipLinks from './SkipLinks';

const LayoutRoot = styled(BaseLayout, { name: 'Layout', slot: 'Root' })(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateAreas: gridAreas(['sidebar header', 'sidebar content']),
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
  })
);

const LayoutProgress = styled(SiteProgress, {
  name: 'Layout',
  slot: 'Progress',
})(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
}));

const LayoutHeader = styled(SiteHeader, { name: 'Layout', slot: 'Header' })(
  ({ theme }) => ({
    gridArea: 'header',
  })
);

const LayoutSidebar = styled(NavigationSidebar, {
  name: 'Layout',
  slot: 'Sidebar',
})(({ theme }) => ({
  gridArea: 'sidebar',
}));

const LayoutContent = styled('div', { name: 'Layout', slot: 'Content' })(
  ({ theme }) => ({
    gridArea: 'content',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: 100,
    zIndex: 1,
  })
);

/**
 * Core site layout common to all pages
 */
const Layout = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (contentRef.current) {
      contentRef.current.removeAttribute('tabindex');
    }
  }, []);

  return (
    <SidebarExpansionProvider>
      <LayoutRoot>
        <GlobalStyles
          styles={(theme) => ({
            'html, body': {
              scrollPaddingTop: 72,
            },
          })}
        />
        <SkipLinks />
        <LayoutProgress />
        <LayoutHeader />
        <LayoutSidebar />
        <LayoutContent ref={contentRef} tabIndex={-1} id="content">
          <Outlet />

          <Footer />
        </LayoutContent>
      </LayoutRoot>
    </SidebarExpansionProvider>
  );
};

export default Layout;
