import { ComponentProps, forwardRef, Ref, useCallback } from 'react';

export type BicInputProps = ComponentProps<'input'> & {
  inputRef: any;
};

/**
 * An <Input> compatible input that formats (upper-cases) BIC codes
 */
export const BicInput = forwardRef(function BicInput(
  props: BicInputProps,
  ref: Ref<any>
) {
  const { onBlur, onChange, ...other } = props;

  const blur = useCallback(
    (e) => {
      onChange?.({
        target: { name: e.target.name, value: e.target.value.toUpperCase() },
      } as any);
    },
    [onChange]
  );

  return <input ref={ref} {...other} onChange={onChange} onBlur={blur} />;
});
