import { useLocation } from 'react-router-dom';
import {
  objectInputType,
  objectOutputType,
  z,
  ZodRawShape,
  ZodTypeAny,
} from 'zod';
import { useJsonMemo } from './useJsonMemo';

const emptyState = Object.freeze({});

/**
 * Gets the state from {@link useLocation} and return just the state with type validation
 *
 * @see {@location useLocation}
 */
export function useLocationState<
  T extends ZodRawShape,
  Catchall extends ZodTypeAny = ZodTypeAny,
  Output = objectOutputType<T, Catchall>,
  Input = objectInputType<T, Catchall>
>(
  schema: z.ZodObject<T, 'strip', Catchall, Output, Input>
): z.infer<z.ZodObject<T, 'strip', Catchall, Output, Input>> {
  const { state } = useLocation();

  const res = schema.safeParse(state ?? emptyState);
  if (!res.success) {
    const err = res.error.flatten();
    console.warn(
      'Invalid location state, returning empty state\n' +
        [
          ...err.formErrors,
          ...Object.entries(err.fieldErrors).flatMap(([key, errors]) =>
            errors.map((error) => `${key}: ${error}`)
          ),
        ].join('\n')
    );
  }

  // Make sure data remains referntially equal
  return useJsonMemo<Output>(res.success ? res.data : (emptyState as Output));
}
