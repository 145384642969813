// eslint-disable-next-line no-restricted-imports
import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { useModal } from 'mui-modal-provider';
import { forwardRef, ReactNode, useCallback } from 'react';
import { Attachment, AttachmentType } from '../../api';
import { useFieldDisabled } from '../../form';
import { CommonValidatorProps } from '../../form/fields/internal/useCommonValidate';
import { NewDocumentDialog } from '../dialog';
import { DocumentCard } from '../ui';

export type SingleAttachmentFieldProps = FieldHookConfig<
  Attachment | undefined
> &
  CommonValidatorProps & {
    orgId?: string | undefined | null;
    label?: string;
    cardTitle?: ReactNode;
    attachmentType: AttachmentType;
    disabled?: boolean;
    readOnly?: boolean;
  };

export const SingleAttachmentField = forwardRef(function SingleAttachmentField(
  props: SingleAttachmentFieldProps,
  ref: any
) {
  const {
    orgId,
    cardTitle,
    attachmentType,
    disabled: disabledProp,
    validate,
    readOnly: readOnlyProp,
  } = props;
  const disabled = useFieldDisabled(disabledProp);
  const readOnly = disabled || readOnlyProp;
  const { showModal } = useModal();
  const [{ value }, , { setValue }] = useField<Attachment | undefined>({
    validate,
    ...props,
  });

  const upload = useCallback(() => {
    showModal(NewDocumentDialog, {
      orgId,
      attachmentType,
      initialValues: { file: null },
      onUpload: (attachment: Attachment) => {
        setValue(attachment);
      },
    });
  }, [attachmentType, orgId, setValue, showModal]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, [setValue]);

  return (
    <DocumentCard
      ref={ref}
      sx={{ mt: 1, mb: 2 }}
      title={cardTitle}
      attachment={value}
      onUpload={!readOnly ? upload : undefined}
      onRemove={!readOnly && value ? remove : undefined}
    />
  );
});
