import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveType } from '../../api';

/**
 * Provide localized labels for the ActiveType enum
 */
export function useActiveTypeLabels(): Record<ActiveType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ActiveType, string> => ({
      Pending: t('enumLabel.activeType.pending'),
      Active: t('enumLabel.activeType.active'),
      Suspended: t('enumLabel.activeType.suspended'),
      Terminated: t('enumLabel.activeType.terminated'),
    }),
    [t]
  );
}
