import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ComplianceStatus } from '../../api';

/**
 * Provide localized labels for the ComplianceStatus enum
 */
export function useComplianceStatusLabels(): Record<ComplianceStatus, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ComplianceStatus, string> => ({
      Compliant: t('enumLabel.complianceStatus.compliant'),
      NonCompliant: t('enumLabel.complianceStatus.nonCompliant'),
      NeedsReview: t('enumLabel.complianceStatus.needsReview'),
      UnderReview: t('enumLabel.complianceStatus.underReview'),
    }),
    [t]
  );
}
