import { CardHeader, CardHeaderProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Attachment } from '../../api';
import { DocumentFileChip } from './DocumentFileChip';

export type DocumentCardHeaderProps = CardHeaderProps & {
  attachment?: Attachment | undefined;
};

export const DocumentCardHeader = (props: DocumentCardHeaderProps) => {
  const { attachment, title, ...other } = props;
  const { t } = useTranslation();

  return (
    <CardHeader
      {...other}
      title={title ?? t('title.noDocument')}
      subheader={
        attachment ? (
          <>
            {attachment.FileName} <DocumentFileChip attachment={attachment} />
          </>
        ) : undefined
      }
    />
  );
};
