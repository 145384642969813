import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
} from '../../breadcrumbs';
import { useThirdParty } from '../../api';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const AllThirdPartiesTitle = t('page.allThirdParties');
export const OurThirdPartiesTitle = t('page.ourThirdParties');
export const ThirdPartyDetailsTitle = p(({ clientId, thirdPartyId }) => {
  const { data: thirdParty, loading } = useThirdParty(clientId, thirdPartyId, {
    useErrorBoundary: false,
  });

  return [thirdParty?.Name, loading];
});
export const AddThirdPartyTitle = t('page.addThirdParty');
