import { useCallback } from 'react';

/**
 * Get a getLabel function for an object containing labels
 */
export function useLabelFn<K extends string>(
  labels: Record<K, string>
): (value: string) => string {
  return useCallback((value: string) => labels[value as K] ?? value, [labels]);
}
