import { FieldHookConfig, useField } from '@johnrom/formik-v3';
import { TextField, TextFieldProps } from '@mui/material';
import { DateTimePickerProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { createElement } from 'react';
import { useFieldDisabled } from '../../disabled';
import { useDateFieldErrorHack } from './useDateFieldErrorHack';
import {
  useDatePickerOnError,
  UseDatePickerOnErrorParams,
} from './useDatePickerOnError';

export type UseDateTimePickerFieldProps = Omit<
  FieldHookConfig<DateTime | null>,
  'value' | 'type' | 'multiple'
> &
  Pick<TextFieldProps, 'helperText' | 'required'> &
  Omit<
    DateTimePickerProps<DateTime>,
    | 'name'
    | 'value'
    | 'onChange'
    | 'onError'
    | 'error'
    | 'format'
    | 'renderInput'
  > & {
    readOnly?: boolean;
    // Rename KeyboardDateTimePicker's format prop to dateTimeFormat
    dateTimeFormat?: DateTimePickerProps<DateTime>['inputFormat'];
    onChange?: DateTimePickerProps<DateTime>['onChange'];
    getShouldDisableDateError?: UseDatePickerOnErrorParams['getShouldDisableDateError'];
    getShouldDisableTimeError?: UseDatePickerOnErrorParams['getShouldDisableTimeError'];
  };

/**
 * useField wrapper that includes fixed variants of formik-material-ui's fieldToKeyboardDateTimePicker
 */
export function useDateTimePickerFieldProps({
  name,
  disabled: disabledProp,
  required,
  helperText,
  dateTimeFormat,
  validate: validateProp,
  ...props
}: UseDateTimePickerFieldProps): DateTimePickerProps<DateTime> {
  const disabled = useFieldDisabled(disabledProp);
  const [field, meta, { setValue, setError, setTouched }] =
    useDateFieldErrorHack<DateTime | null>({
      validateProp,
      useFieldFn: (validate) =>
        useField({
          name,
          validate,
          ...props,
        }),
    });
  const fieldError = meta.error;
  const showError = meta.touched && !!fieldError;

  const {
    maxDate,
    minDate,
    maxTime,
    minTime,
    getShouldDisableDateError,
    getShouldDisableTimeError,
  } = props;
  const onError = useDatePickerOnError({
    setError,
    maxDate,
    minDate,
    maxTime,
    minTime,
    getShouldDisableDateError,
    getShouldDisableTimeError,
  });

  return {
    ...field,
    disabled,
    onError,
    onChange: (date: DateTime | null) => {
      setValue(date);
      setTouched(true);
    },
    inputFormat: dateTimeFormat,
    ...props,
    renderInput: (props) =>
      createElement(TextField, {
        ...props,
        required,
        error: showError,
        helperText: showError ? fieldError : helperText,
      }),
  };
}
