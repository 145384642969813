import { MenuItem } from '@mui/material';
import { kashe } from 'kashe';
import { ReactNode } from 'react';

/**
 * Get an array of possible enum values
 * @note Memoized
 */
export let enumArray = <E extends string, V extends string>(
  Enum: Record<E, V>
): V[] => {
  return Object.values<V>(Enum);
};
enumArray = kashe(enumArray);

/**
 * Create a helper that will turn an enum into an options object with provided labels
 * The helper is useful as a .map function.
 */
export function enumOptionsFor<E extends string>(
  labels: Record<E, string>
): (value: E) => { value: E; label: string } {
  return (value) => ({ value, label: labels[value] ?? value });
}

/**
 * Turn an enum to a list of menu items for a select
 */
export function enumToOptions<E extends string, V extends string>(
  Enum: Record<E, V>,
  labels: Record<V, string>
): ReactNode[] {
  return Object.values<V>(Enum).map((value: V) => (
    <MenuItem key={value} value={value}>
      {labels[value] ?? value}
    </MenuItem>
  ));
}

/**
 * Variant of {@see enumToOptions} for Autocomplete based fields, until SelectField uses `getOption{Value,Label}` props
 */
export function enumToOptionsObject<E extends string, V extends string>(
  Enum: Record<E, V>
): V[] {
  return Object.values<V>(Enum).map((value: V) => value);
}
