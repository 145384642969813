import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactAddressType } from '../../api';

/**
 * Provide localized labels for the ContactAddressType enum
 */
export function useContactAddressTypeLabels(): Record<
  ContactAddressType,
  string
> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ContactAddressType, string> => ({
      Home: t('enumLabel.contactAddressType.home'),
      Work: t('enumLabel.contactAddressType.work'),
    }),
    [t]
  );
}
