import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { setFutureTask } from '../utils/setFutureTask';
import { useRunLogin } from './login';
import { AuthStateDispatch } from './types';

/**
 * Behaviour that automatically refreshes a token before it expires
 */
export function useTokenAutoRefresh(
  authDispatch: AuthStateDispatch,
  refreshAt: DateTime | undefined,
  token: string | null | undefined
) {
  const { enqueueSnackbar } = useSnackbar();
  const runLogin = useRunLogin(authDispatch);

  useEffect(() => {
    if (refreshAt) {
      return setFutureTask(refreshAt, async () => {
        await runLogin({
          bearerToken: token,
          autoRefreshToken: true,
        });

        if (process.env.REACT_APP_DEVTOOLS === 'true') {
          enqueueSnackbar('Auth token refreshed', { variant: 'info' });
        }
      });
    }
  }, [enqueueSnackbar, refreshAt, runLogin, token]);
}
