import { colors } from '@mui/material';
import { BrandingTheme } from './types';

/**
 * Default/whitelabel light theme palette
 * @note Only primary/secondary are used
 */
export const whitelabelLightTheme: BrandingTheme = {
  primary: colors.green['A700'],
  secondary: colors.lightGreen['A400'],
};

/**
 * Default/whitelabel dark theme palette
 * @note Only primary/secondary are used
 * @note Not speced by Una yet
 */
export const whitelabelDarkTheme: BrandingTheme = whitelabelLightTheme;
