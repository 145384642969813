import { DateTimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation';
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation';
import { ParseableDate } from '@mui/x-date-pickers/internals/models/parseableDate';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLuxonLocaleFormatter } from '../../../intl';

export interface UseDatePickerOnErrorParams {
  setError: (error: string | undefined) => void;
  maxDate?: ParseableDate<DateTime>;
  minDate?: ParseableDate<DateTime>;
  maxTime?: ParseableDate<DateTime>;
  minTime?: ParseableDate<DateTime>;
  getShouldDisableDateError?: (day: DateTime) => string;
  getShouldDisableTimeError?: (
    timeValue: number,
    clockType: 'hours' | 'minutes' | 'seconds'
  ) => string;
}

const parseDateTime = (
  value: ParseableDate<DateTime>
): DateTime | undefined => {
  if (typeof value === 'number') return DateTime.fromMillis(value);
  if (typeof value === 'string') return DateTime.fromISO(value);
  if (value instanceof Date) return DateTime.fromJSDate(value);
  return value || undefined;
};

/**
 * Get a shared onError function to use with DatePicker/DateTimePicker/etc
 */
export function useDatePickerOnError(
  params: UseDatePickerOnErrorParams
): (
  reason: TimeValidationError | DateValidationError | DateTimeValidationError,
  value: ParseableDate<DateTime>
) => void {
  const {
    setError,
    maxDate: maxDateProp,
    minDate: minDateProp,
    maxTime: maxTimeProp,
    minTime: minTimeProp,
    getShouldDisableDateError,
    getShouldDisableTimeError,
  } = params;
  const maxDate = parseDateTime(maxDateProp);
  const minDate = parseDateTime(minDateProp);
  const maxTime = parseDateTime(maxTimeProp);
  const minTime = parseDateTime(minTimeProp);

  const { t } = useTranslation();
  const formatDate = useLuxonLocaleFormatter(DateTime.DATE_SHORT);
  const formatTime = useLuxonLocaleFormatter(DateTime.TIME_WITH_SECONDS);

  return useCallback(
    (
      reason:
        | TimeValidationError
        | DateValidationError
        | DateTimeValidationError,
      value: ParseableDate<DateTime>
    ): void => {
      value = parseDateTime(value);
      switch (reason) {
        case 'invalidDate':
          return setError(t('error.invalidDate'));
        case 'shouldDisableDate':
          return setError(
            (value && getShouldDisableDateError?.(value)) ??
              t('error.shouldDisableDate', {
                value: value ? formatDate(value) : '',
              })
          );
        case 'shouldDisableTime-hours':
          return setError(
            (value && getShouldDisableTimeError?.(value.hour, 'hours')) ??
              t('error.shouldDisableTime')
          );
        case 'shouldDisableTime-minutes':
          return setError(
            (value && getShouldDisableTimeError?.(value.minute, 'minutes')) ??
              t('error.shouldDisableTime')
          );
        case 'shouldDisableTime-seconds':
          return setError(
            (value && getShouldDisableTimeError?.(value.second, 'seconds')) ??
              t('error.shouldDisableTime')
          );
        case 'disablePast':
          return setError(t('error.disablePast'));
        case 'disableFuture':
          return setError(t('error.disableFuture'));
        case 'maxTime':
          return setError(
            t('error.maxTime', { maxTime: maxTime ? formatTime(maxTime) : '' })
          );
        case 'minTime':
          return setError(
            t('error.minTime', { maxTime: minTime ? formatTime(minTime) : '' })
          );
        case 'maxDate':
          return setError(
            t('error.maxDate', { maxDate: maxDate ? formatDate(maxDate) : '' })
          );
        case 'minDate':
          return setError(
            t('error.minDate', { minDate: minDate ? formatDate(minDate) : '' })
          );
        default:
          return setError(undefined);
      }
    },
    [
      formatDate,
      formatTime,
      getShouldDisableDateError,
      getShouldDisableTimeError,
      maxDate,
      maxTime,
      minDate,
      minTime,
      setError,
      t,
    ]
  );
}
