import { EQ, Contains } from '../comparators';

import { parseString } from '../qs-parser';
import { QueryStringFilterValidator } from '../qs-validator';
import { FilterReturnArg, makeFilter } from '../utils';

export interface GetThirdPartiesFilters {
  searchText?: string;
  name?: EQ & Contains;
  type?: EQ;
  status?: EQ;
  complianceStatus?: EQ;
}

/**
 * Cast query string arguments to GetThirdPartiesFilters
 * @note Generated by filtergen
 */
export function castGetThirdPartiesFilters(
  filters: qs.ParsedQs
): GetThirdPartiesFilters {
  const f = new QueryStringFilterValidator(filters);

  return {
    searchText: f.searchText('searchText'),

    name: {
      eq: f.eq('name', parseString),
      contains: f.contains('name', parseString),
    },

    type: {
      eq: f.eq('type', parseString),
    },

    status: {
      eq: f.eq('status', parseString),
    },

    complianceStatus: {
      eq: f.eq('complianceStatus', parseString),
    },
  };
}

/**
 * Make args for the GetThirdPartySummaries query params
 * @see GET /thirdparties/summaries
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetThirdPartySummariesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg,
  searchText: string | undefined
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
    /**
     * @note searchtext
     */ filters.searchText,
  ];
}

/**
 * Make args for the ExportThirdParties query params
 * @see GET /thirdparties/export
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeExportThirdPartiesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}

/**
 * Make args for the GetThirdParties query params
 * @see GET /thirdparties
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetThirdPartiesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}

/**
 * Make args for the GetAllClientThirdPartySummaries query params
 * @see GET /clients/thirdparties/{orgId}/summaries
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientThirdPartySummariesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  searchText: string | undefined,
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note Name
     */ makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}

/**
 * Make args for the ExportAllClientThirdParties query params
 * @see GET /clients/thirdparties/{orgId}/export
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeExportAllClientThirdPartiesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}

/**
 * Make args for the GetAllClientThirdParties query params
 * @see GET /clients/thirdparties/{orgId}
 * @note Excludes the `orgId` and `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientThirdPartiesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}

/**
 * Make args for the GetAllClientsThirdPartySummaries query params
 * @see GET /clients/thirdparties/summaries
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientsThirdPartySummariesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  searchText: string | undefined,
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note searchtext
     */
    filters.searchText,
    /**
     * @note Name
     */ makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}

/**
 * Make args for the ExportAllClientsThirdParties query params
 * @see GET /clients/thirdparties/export
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeExportAllClientsThirdPartiesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}

/**
 * Make args for the GetAllClientsThirdParties query params
 * @see GET /clients/thirdparties
 * @note Excludes the `sort` params
 * @note Generated by filtergen
 */
export function makeGetAllClientsThirdPartiesFilterArgs(
  filters: GetThirdPartiesFilters
): [
  name: FilterReturnArg,
  type: FilterReturnArg,
  status: FilterReturnArg,
  complianceStatus: FilterReturnArg
] {
  return [
    /**
     * @note Name
     */
    makeFilter(filters.name),
    /**
     * @note Type
     */ makeFilter(filters.type),
    /**
     * @note Status
     */ makeFilter(filters.status),
    /**
     * @note ComplianceStatus
     */ makeFilter(filters.complianceStatus),
  ];
}
