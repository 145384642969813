import { useLocale } from './locale';
import { useCallback, useMemo } from 'react';

/**
 * Get a function that will return the name of a country from a country code for the current locale
 */
export function useCountryNames(): (countryCode: string) => string {
  const locale = useLocale();
  const regionFormatter = useMemo(
    () => new Intl.DisplayNames(locale, { type: 'region' }),
    [locale]
  );
  return useCallback(
    (code: string) => regionFormatter.of(code) ?? '',
    [regionFormatter]
  );
}

/**
 * Get a function that will return the name of a currency from a currency code for the current locale
 */
export function useCurrencyName(): (currencyCode: string) => string {
  const locale = useLocale();
  const currencyFormatter = useMemo(
    () => new Intl.DisplayNames(locale, { type: 'currency' }),
    [locale]
  );
  return useCallback(
    (code: string) => currencyFormatter.of(code) ?? '',
    [currencyFormatter]
  );
}
