import invariant from 'invariant';
import { useAccount } from '../../api';
import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
} from '../../breadcrumbs';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const AllAccountsTitle = t('page.allAccounts');
export const OurAccountsTitle = t('page.ourAccounts');
export const AccountDetailsTitle = p(({ clientId, accountId }) => {
  invariant(accountId, 'accountId is expected');
  const { data: account, loading } = useAccount(clientId, accountId, {
    useErrorBoundary: false,
  });
  return [account?.Name, loading];
});
export const AddAccountTitle = t('page.addAccount');
