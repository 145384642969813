import invariant from 'invariant';
import {
  Address,
  ContactAddress,
  ContactAddressType,
  LegalAddress,
  LegalAddressType,
} from '../../../api';

export interface BaseAddressValues {
  street1: string;
  street2: string;
  street3: string;
  city: string;
  postal: string;
  region: string;
  country: null | string;
}

export interface AddressValues<AddressType> extends BaseAddressValues {
  editing: boolean;
  type: AddressType | '';
}

export type LegalAddressValues = AddressValues<LegalAddressType>;
export type ContactAddressValues = AddressValues<ContactAddressType>;

/**
 * Get the field names for a field using the BaseAddressValues structure
 */
export function getBaseAddressFieldNames(
  name: string
): Record<keyof BaseAddressValues, string> {
  return {
    street1: `${name}.street1`,
    street2: `${name}.street2`,
    street3: `${name}.street3`,
    city: `${name}.city`,
    postal: `${name}.postal`,
    region: `${name}.region`,
    country: `${name}.country`,
  };
}

/**
 * Get the field names for a field using the BaseAddressValues structure
 */
export function getTypedAddressFieldNames(
  name: string
): Record<keyof BaseAddressValues | 'type', string> {
  return {
    type: `${name}.type`,
    ...getBaseAddressFieldNames(name),
  };
}

/**
 * When address array is not used, checks whether required address fields have a value
 */
export function hasAddressValues(addr: BaseAddressValues): boolean {
  return !!(addr.street1 || addr.city || addr.country);
}

/**
 * Make a partial AddressValues object from an Address object
 */
export function addressToValues(addr?: Address): BaseAddressValues {
  return {
    street1: addr?.Street1 ?? '',
    street2: addr?.Street2 ?? '',
    street3: addr?.Street3 ?? '',
    city: addr?.City ?? '',
    postal: addr?.Postal ?? '',
    region: addr?.Region ?? '',
    country: addr?.Country ?? null,
  };
}

/**
 * Make an AddressValues object from a LegalAddress object
 */
export function legalAddressToValues(addr?: LegalAddress): LegalAddressValues {
  return {
    editing: false,
    type: addr?.Type ?? '',
    // ...addressToValues(addr),
    street1: addr?.Street1 ?? '',
    street2: addr?.Street2 ?? '',
    street3: addr?.Street3 ?? '',
    city: addr?.City ?? '',
    postal: addr?.Postal ?? '',
    region: addr?.Region ?? '',
    country: addr?.Country ?? null,
  };
}

/**
 * Make an AddressValues object from a ContactAddress object
 */
export function contactAddressToValues(
  addr?: ContactAddress
): ContactAddressValues {
  return {
    editing: false,
    type: addr?.Type ?? '',
    // ...addressToValues(addr),
    street1: addr?.Street1 ?? '',
    street2: addr?.Street2 ?? '',
    street3: addr?.Street3 ?? '',
    city: addr?.City ?? '',
    postal: addr?.Postal ?? '',
    region: addr?.Region ?? '',
    country: addr?.Country ?? null,
  };
}

/**
 * Convert BaseAddressValues to an AddressAddress
 *
 * Typically used in a submit handler to update basic addresses
 */
export function addressValuesToAddress(addr: BaseAddressValues): Address {
  const optional = (value: string | null) => value || undefined;

  return {
    Street1: optional(addr.street1) ?? '',
    Street2: optional(addr.street2),
    Street3: optional(addr.street3),
    City: optional(addr.city) ?? '',
    Postal: optional(addr.postal),
    Region: optional(addr.region),
    Country: optional(addr.country) ?? '',
  };
}

/**
 * Convert LegalAddressValues to a LegalAddress
 *
 * Typically used in a submit handler to update a contact
 */
export function legalAddressValuesToAddress(
  addr: LegalAddressValues
): LegalAddress {
  const optional = (value: string | null) => value || undefined;
  invariant(addr.type, 'Address type expected');

  return {
    Type: addr.type,
    Street1: optional(addr.street1) ?? '',
    Street2: optional(addr.street2),
    Street3: optional(addr.street3),
    City: optional(addr.city) ?? '',
    Postal: optional(addr.postal),
    Region: optional(addr.region),
    Country: optional(addr.country) ?? '',
  };
}

/**
 * Convert ContactAddressValues to a ContactAddress
 *
 * Typically used in a submit handler to update a contact
 */
export function contactAddressValuesToAddress(
  addr: ContactAddressValues
): ContactAddress {
  const optional = (value: string | null) => value || undefined;
  invariant(addr.type, 'Address type expected');

  return {
    Type: addr.type,
    Street1: optional(addr.street1) ?? '',
    Street2: optional(addr.street2),
    Street3: optional(addr.street3),
    City: optional(addr.city) ?? '',
    Postal: optional(addr.postal),
    Region: optional(addr.region),
    Country: optional(addr.country) ?? '',
  };
}
