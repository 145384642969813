import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { TimeZoneSetting, useTimeZoneSetting } from './setting';

/**
 * Set the correct timezone on a DateTime
 */
export function useSetTimeZone(): (dt: DateTime) => DateTime {
  const timeZoneSetting = useTimeZoneSetting();

  return useCallback(
    (dt: DateTime) => {
      if (timeZoneSetting === TimeZoneSetting.UTC) return dt.toUTC();
      if (timeZoneSetting === TimeZoneSetting.Local) return dt.toLocal();
      return dt;
    },
    [timeZoneSetting]
  );
}
