import { FormikErrors, setIn, FormikHelpers } from '@johnrom/formik-v3';
import { FORM_ERROR } from './FormError';
import { ApiError } from '../api';
import { TFunction } from 'i18next';

/**
 * An error that can be thrown in a submit handler to pass an error to multiple fields
 */
export class FieldErrors<Values> extends Error {
  public fieldErrors: FormikErrors<Values>;
  public error: Error | undefined;

  constructor(fieldErrors: FormikErrors<Values>, error?: Error) {
    const message = Object.entries(fieldErrors)
      .map(
        ([fieldName, errorMessage]) =>
          `${fieldName}: ${
            Array.isArray(errorMessage) ? errorMessage.join('\n') : errorMessage
          }`
      )
      .join('\n');
    super(message);
    this.fieldErrors = fieldErrors;
    this.error = error;
  }
}

/**
 * An error that can be thrown in a submit handler to pass an error to a field
 */
export class FieldError<Values> extends FieldErrors<Values> {
  constructor(fieldName: string, errorMessage: string, error?: Error) {
    super(setIn({}, fieldName, errorMessage), error);
  }
}

/**
 * Get the field errors used for a caught error
 */
export function getFieldErrors(
  t: TFunction,
  err: unknown
): string | FormikErrors<any> {
  if (err instanceof FieldErrors) {
    return err.fieldErrors;
  } else if (err instanceof ApiError) {
    return err.message;
  } else {
    return t('unexpectedError');
  }
}

/**
 * Set field errors from a caught error
 */
export function setFieldErrors<Values>(
  t: TFunction,
  formikHelpers: FormikHelpers<Values>,
  err: unknown
): void {
  if (err instanceof FieldErrors) {
    formikHelpers.setErrors(err.fieldErrors);
  } else if (err instanceof ApiError) {
    formikHelpers.setFieldError(FORM_ERROR as any, err.message);
  } else {
    formikHelpers.setFieldError(FORM_ERROR as any, t('unexpectedError'));
  }
}
