import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { TagArrayField, TagArrayFieldProps } from '../TagArrayField';

export type ClearingSystemArrayFieldProps = Omit<
  TagArrayFieldProps<string, false>,
  'options'
>;
/**
 * A Tag array combobox field for selecting a list of clearing systems
 */
export const ClearingSystemArrayField = forwardRef(
  function ClearingSystemArrayField(
    props: ClearingSystemArrayFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const [clearingSystems, loadingClearingSystems] =
      useCodes('clearingsystems');
    const optionProps = useCodeOptionsProps(clearingSystems);

    return (
      <TagArrayField
        ref={ref}
        {...other}
        {...optionProps}
        loading={loadingClearingSystems}
      />
    );
  }
);
