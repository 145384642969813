import { ClientProfile, ClientProfileSummary } from '../client';

/**
 * Get the ID of a Client Profile from either a full client or a summary
 */
export const getClientProfileId = (
  clientProfile: ClientProfile | ClientProfileSummary
): string => {
  if ('Id' in clientProfile && clientProfile.Id) return clientProfile.Id;
  if ('id' in clientProfile && clientProfile.id) return clientProfile.id;
  throw new Error('Client Profile is missing ID');
};
