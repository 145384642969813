/* eslint-disable no-restricted-imports */
import {
  CloseOutlined as CloseIcon,
  KeyboardArrowDownOutlined as KeyboardArrowDownIcon,
  KeyboardArrowUpOutlined as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { KeyboardEvent, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useHtmlId from '../../utils/useHtmlId';

interface OwnerState {}

const DataTableQuickSearchBoxRoot = styled('div', {
  name: 'DataTableQuickSearchBox',
  slot: 'Root',
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  pointerEvents: 'auto',
}));

export interface DataTableQuickSearchBoxProps {
  value: string;
  onChange: (quickSearch: string) => void;
  onClear: () => void;
  matchIndex?: number;
  matchTotal?: number;
  onPrevMatch?: () => void;
  onNextMatch?: () => void;
}

export let DataTableQuickSearchBox = (props: DataTableQuickSearchBoxProps) => {
  const {
    value,
    onChange,
    onClear,
    matchIndex,
    matchTotal,
    onPrevMatch,
    onNextMatch,
  } = props;
  const id = useHtmlId('DataTableQuickSearch');
  const inputId = `${id}-input`;
  const { t } = useTranslation();

  const ownerState: OwnerState = {};

  const keyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        onClear();
      } else if (e.key === 'Enter') {
        e.preventDefault();
        onNextMatch?.();
      }
    },
    [onClear, onNextMatch]
  );

  const matchText =
    matchTotal == null ? undefined : `${matchIndex}/${matchTotal}`;

  return (
    <DataTableQuickSearchBoxRoot ownerState={ownerState}>
      <Box component="label" sx={visuallyHidden} htmlFor={inputId}>
        {t('inputLabel.quickSearch')}
      </Box>
      <TextField
        variant="outlined"
        margin="none"
        sx={{ width: 250 }}
        id={inputId}
        name="quickSearch"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t('inputLabel.quickSearch')}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onKeyDown={keyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ mr: -1 }}>
              {matchText && (
                <Typography variant="caption">{matchText}</Typography>
              )}

              <Box
                sx={(theme) => ({
                  borderLeft: '1px solid',
                  borderColor: 'divider',
                  height: theme.spacing(3),
                  ml: 1,
                })}
              />

              <IconButton
                size="small"
                aria-label={t('button.previous')}
                onClick={onPrevMatch}
                disabled={matchTotal == null}
              >
                <KeyboardArrowUpIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                aria-label={t('button.next')}
                onClick={onNextMatch}
                disabled={matchTotal == null}
              >
                <KeyboardArrowDownIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                aria-label={t('button.clear')}
                onClick={onClear}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{
          'aria-label': t('inputLabel.quickSearch'),
        }}
      />
    </DataTableQuickSearchBoxRoot>
  );
};
DataTableQuickSearchBox = memo(DataTableQuickSearchBox) as any;
