import { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isPermissionError, isResourceNotFoundError } from '../api';
import { ErrorBoundary } from '../utils/ErrorBoundary';
import { PermissionErrorPage } from './error';
import { ErrorPage } from './error/ErrorPage';
import { ResourceNotFoundErrorPage } from './error/ResourceNotFound';

export interface PageErrorBoundaryProps {
  children?: ReactNode;
}

/**
 * Error boundary wrapper with common page error types
 */
export const PageErrorBoundary = ({ children }: PageErrorBoundaryProps) => {
  const permissionErrorBoundary = useRef<ErrorBoundary>(null);
  const resourceNotFoundErrorBoundary = useRef<ErrorBoundary>(null);
  const location = useLocation();
  useEffect(() => {
    resourceNotFoundErrorBoundary.current?.reset();
    permissionErrorBoundary.current?.reset();
  }, [location.pathname]);

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <ErrorBoundary
        ref={permissionErrorBoundary}
        is={(error) => isPermissionError(error)}
        fallback={<PermissionErrorPage />}
      >
        <ErrorBoundary
          ref={resourceNotFoundErrorBoundary}
          is={(error) => isResourceNotFoundError(error)}
          fallback={<ResourceNotFoundErrorPage />}
        >
          {children}
        </ErrorBoundary>
      </ErrorBoundary>
    </ErrorBoundary>
  );
};
