interface PromiseCachePending {
  promise: Promise<void>;
}
interface PromiseCacheResolved<T> {
  promise: Promise<void>;
  result: T;
}
interface PromiseCacheError {
  promise: Promise<void>;
  error: any;
}

type PromiseCache<T> =
  | PromiseCachePending
  | PromiseCacheResolved<T>
  | PromiseCacheError;

const PROMISE_CACHE = new WeakMap<Promise<any>, PromiseCache<any>>();

/**
 * This is a hook that takes a promise and throws it according to the Suspense API
 *
 * @note This is a bit of a hack since this API is really low level and the full version of Suspense isn't released yet
 */
export default function awaitPromise<T>(promise: Promise<T>): T {
  let preCachedPromise: PromiseCache<T> | undefined =
    PROMISE_CACHE.get(promise);

  if (preCachedPromise) {
    if ('error' in preCachedPromise) {
      throw preCachedPromise.error;
    }
    if ('result' in preCachedPromise) {
      return preCachedPromise.result;
    }

    throw preCachedPromise.promise;
  } else {
    const promiseCacheEntry = {
      promise: promise
        .then((result) => {
          Object.assign(promiseCacheEntry, {
            result,
          });
        })
        .catch((error) => {
          Object.assign(promiseCacheEntry, {
            error,
          });
        }),
    };

    PROMISE_CACHE.set(promise, promiseCacheEntry);
    throw promiseCacheEntry.promise;
  }
}
