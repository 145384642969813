import { AxiosRequestConfig } from 'axios';
import { useAcceptLanguage } from '../../../intl/acceptLanguage';
import { ApiPaginationContext, getPaginationHeaders } from '../pagination';
import { ApiInfiniteQueryOptions, ApiQueryOptions } from '../types';

/**
 * Get extra request options to use in a query
 */
export function useQueryAxiosOptions<TData>(
  options: ApiQueryOptions<TData> | ApiInfiniteQueryOptions<TData>
): (context: ApiPaginationContext) => AxiosRequestConfig {
  const acceptLanguage = useAcceptLanguage();

  return (context: ApiPaginationContext) => {
    const headers = {
      'Accept-Language': acceptLanguage,
      ...getPaginationHeaders(options, context),
    };

    return { headers };
  };
}
