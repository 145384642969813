import {
  Button,
  ButtonProps,
  Checkbox,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import * as Immutable from 'immutable';
import { groupBy } from 'lodash-es';
import {
  ElementType,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useMemo,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { thinScrollbar } from '../../utils/scroll';
import { DOUBLE_CHEVRON_LEFT, DOUBLE_CHEVRON_RIGHT } from '../../utils/unicode';
import useHtmlId from '../../utils/useHtmlId';

const TransferListRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  }),
  { name: 'TransferListRoot' }
);
const TransferListLayout = styled('div')(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridGap: theme.spacing(0, 2),

    '& > .middle': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
    },
  }),
  { name: 'TransferListLayout' }
);

const TransferListPaperScroll = styled(Paper)(
  () => ({
    height: 500,
    maxHeight: '75vh',
    overflowY: 'auto',
    ...thinScrollbar,
  }),
  { name: 'TransferListPaperScroll' }
) as any;

const TransferButtonContainer = styled('div')(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(1, 0),
  }),
  { name: 'TransferButtonContainer' }
);

const TransferButtonButton = styled(Button)(() => ({}));
const TransferButton = (props: ButtonProps) => (
  <TransferButtonButton variant="outlined" size="small" {...props} />
);

export interface TransferListControlProps<Option> {
  label?: ReactNode;
  labelComponent?: ElementType;
  error?: boolean;
  helperText?: ReactNode;
  disabled?: boolean;
  options?: Option[];
  getOptionLabel?: (option: Option) => ReactNode;
  onChange?: (value: Option[]) => void;
  value?: Option[] | null;
  id?: string;
  name?: string;
  loading?: boolean;
}

const TransferItem = (props: any) => {
  const {
    value,
    label,
    checked = false,
    disabled = false,
    dispatchChecked,
    ...other
  } = props;
  const labelId = useHtmlId(`transfer-item-${value}`);

  const toggle = () => {
    if (disabled) return;
    dispatchChecked({ items: [value], checked: !checked });
  };

  return (
    <ListItem {...other} role="listitem" button={!disabled} onClick={toggle}>
      <ListItemIcon>
        <Checkbox
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};

const TransferItemList = (props: any) => {
  const {
    disabled = false,
    items,
    checkedItems,
    getOptionLabel,
    dispatchChecked,
    ...other
  } = props;

  return (
    <TransferListPaperScroll {...other}>
      <List dense component="div" role="list">
        {items?.map((item: any) => (
          <TransferItem
            key={item}
            value={item}
            label={getOptionLabel(item)}
            checked={checkedItems.has(item)}
            dispatchChecked={dispatchChecked}
            disabled={disabled}
          />
        ))}
      </List>
    </TransferListPaperScroll>
  );
};

const emptyArray = Object.freeze([] as any[]);

/**
 * Form control with a transfer list UI
 */
export const TransferListControl = forwardRef(function TransferListControl<
  Option extends unknown
>(props: TransferListControlProps<Option>, ref: Ref<any>) {
  const {
    label,
    labelComponent = 'h3',
    error = false,
    helperText,
    disabled,
    getOptionLabel = (option) => String(option),
    options,
    onChange,
    value,
    id,
  } = props;
  const { t } = useTranslation();

  const [checkedItems, dispatchChecked] = useReducer(
    (
      items: Immutable.Set<Option>,
      action: { items: readonly Option[]; checked: boolean } | 'RESET'
    ) => {
      if (action === 'RESET') {
        return Immutable.Set<Option>();
      }

      const { items: toggleItems, checked } = action;
      return toggleItems.reduce((items, toggleItem) => {
        if (checked) {
          return items.add(toggleItem);
        } else {
          return items.delete(toggleItem);
        }
      }, items);
    },
    Immutable.Set<Option>()
  );
  const { unselected = emptyArray, selected = emptyArray } = useMemo(() => {
    const valueSet = new Set(value);

    return groupBy(options, (option) =>
      valueSet.has(option) ? 'selected' : 'unselected'
    );
  }, [options, value]);
  const { unselectedDisabled, selectedDisabled } = useMemo(() => {
    const valueSet = new Set(value);
    const { unselected = emptyArray, selected = emptyArray } = groupBy(
      Array.from(checkedItems.values()),
      (option) => (valueSet.has(option) ? 'selected' : 'unselected')
    );
    return {
      unselectedDisabled: unselected.length === 0,
      selectedDisabled: selected.length === 0,
    };
  }, [checkedItems, value]);

  const selectAll = () => {
    if (options) onChange?.(options);
    dispatchChecked('RESET');
  };
  const selectChecked = () => {
    const newValue = new Set(value);
    checkedItems.forEach((item) => newValue.add(item));
    onChange?.(Array.from(newValue.values()));
    dispatchChecked({
      items: unselected,
      checked: false,
    });
  };
  const unselectChecked = () => {
    onChange?.((value ?? []).filter((option) => !checkedItems.has(option)));
    dispatchChecked({
      items: selected,
      checked: false,
    });
  };
  const unselectAll = () => {
    onChange?.([]);
    dispatchChecked('RESET');
  };

  return (
    <TransferListRoot ref={ref} id={id}>
      {label && (
        <Typography component={labelComponent} variant="h5" gutterBottom>
          {label}
        </Typography>
      )}
      <TransferListLayout>
        <TransferItemList
          items={unselected}
          checkedItems={checkedItems}
          getOptionLabel={getOptionLabel}
          dispatchChecked={dispatchChecked}
          disabled={disabled}
        />
        <div className="middle">
          <TransferButtonContainer>
            <TransferButton
              onClick={selectAll}
              disabled={disabled || unselected.length === 0}
              aria-label={t('button.moveAllRight')}
            >
              {DOUBLE_CHEVRON_RIGHT}
            </TransferButton>
            <TransferButton
              onClick={selectChecked}
              disabled={disabled || unselectedDisabled}
              aria-label={t('button.moveSelectedRight')}
            >
              &gt;
            </TransferButton>
            <TransferButton
              onClick={unselectChecked}
              disabled={disabled || selectedDisabled}
              aria-label={t('button.moveSelectedLeft')}
            >
              &lt;
            </TransferButton>
            <TransferButton
              onClick={unselectAll}
              disabled={disabled || selected.length === 0}
              aria-label={t('button.moveAllLeft')}
            >
              {DOUBLE_CHEVRON_LEFT}
            </TransferButton>
          </TransferButtonContainer>
        </div>
        <TransferItemList
          items={selected}
          checkedItems={checkedItems}
          getOptionLabel={getOptionLabel}
          dispatchChecked={dispatchChecked}
          disabled={disabled}
        />
      </TransferListLayout>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </TransferListRoot>
  );
}) as <Option extends unknown>(
  props: TransferListControlProps<Option> & { ref?: Ref<any> }
) => ReactElement;
