import { styled, SvgIcon } from '@mui/material';
import { useBranding } from '../branding';

export interface BrandingLogoProps {
  variant?: 'logo' | 'icon';
  className?: string;
}

const SvgLogo = styled('div', { name: 'LogoBar', slot: 'SvgLogo' })(
  ({ theme }) => ({
    fill: 'currentColor',
    width: 96,
    height: 96,
  })
);

/**
 * Display the logo/icon from the branding context
 */
export const BrandingLogo = (props: BrandingLogoProps) => {
  const { variant = 'logo', className } = props;
  const { siteName, logoIcon, logo } = useBranding();
  const Image = variant === 'icon' ? logoIcon : logo;

  if (typeof Image === 'string') {
    return (
      <img
        src={Image}
        alt={siteName}
        width={24}
        height={24}
        className={className}
      />
    );
  } else if (Image && variant === 'icon') {
    const SvgImageComponent = Image;
    return (
      <SvgIcon
        aria-label={siteName}
        component={SvgImageComponent}
        className={className}
      />
    );
  } else if (Image) {
    return <SvgLogo as={Image} className={className} />;
  }

  return null;
};
