import { useCallback, useRef } from 'react';
import {
  ApiRequestTiming,
  Token,
  useAccessControlApi,
  useApiMutation,
} from '../api';
import { AuthStateDispatch } from './types';

export interface RunLoginParms {
  basicAuth?: {
    username: string;
    password: string;
  };
  bearerToken?: string | undefined | null;
  autoRefreshToken?: 'checkClock' | boolean;
}

/**
 * Gets a function that will run a login or token refresh request and save the token and metadata to the authState
 */
export function useRunLogin(
  authDispatch: AuthStateDispatch
): (params: RunLoginParms) => Promise<Token> {
  const lastRequestTiming = useRef<ApiRequestTiming | null>(null);
  const [refreshToken] = useApiMutation({
    ...useAccessControlApi().refreshTokenMutation(),
    auth: 'NONE',
    lastRequestTiming,
  });

  return useCallback(
    async (params: RunLoginParms) => {
      const TokenResult = await refreshToken({
        auth: params.basicAuth,
        headers: params.bearerToken
          ? {
              Authorization: 'Bearer ' + params.bearerToken,
            }
          : undefined,
      });
      const { Uid, ContactId, Token, Authorities } = TokenResult;

      let autoRefreshToken = false;
      if (params.autoRefreshToken === 'checkClock') {
        if (lastRequestTiming.current?.requestDateHeader) {
          // @todo Check the magnitude of the client's system clock skew and determine whether to enable autoRefreshToken
        }
        // XXX: Always use autoRefreshToken until we've added `Access-Control-Expose-Headers: Date` to the
        autoRefreshToken = true;
      } else {
        autoRefreshToken = params.autoRefreshToken ?? false;
      }

      authDispatch({
        type: 'CHANGE',
        authState: {
          token: Token,
          authorities: Authorities ?? [],
          uid: Uid,
          contactId: ContactId,
          autoRefreshToken,
        },
      });

      return TokenResult;
    },
    [authDispatch, refreshToken]
  );
}
