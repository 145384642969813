import invariant from 'invariant';
import { useApiQuery, usePaymentsApi } from '../../api';
import {
  breadcrumbTitle,
  breadcrumbTitleParameterized,
} from '../../breadcrumbs';
const t = breadcrumbTitle;
const p = breadcrumbTitleParameterized;

export const BatchPaymentsTitle = t('page.batchPayments');
export const BatchPaymentDetailsTitle = p(({ batchPaymentId }) => {
  invariant(batchPaymentId, 'batchPaymentId is expected');
  const { data: payment, loading } = useApiQuery(
    usePaymentsApi().getBatch(batchPaymentId),
    { useErrorBoundary: false }
  );
  return [payment?.BatchReference, loading];
});
