import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isImage, isPDF } from '../file-types';
import { ImageViewer } from './ImageViewer';
import { PdfViewer } from './PdfViewer';

export interface FileViewProps {
  fileType: string;
  fileName: string;
  src: string;
}

export const FileView = (props: FileViewProps) => {
  const { fileType, fileName, src } = props;
  const { t } = useTranslation();

  if (isImage(fileType)) {
    return <ImageViewer name={fileName} src={src} />;
  }

  if (isPDF(fileType)) {
    return <PdfViewer name={fileName} src={src} />;
  }

  return <Typography>{t('error.unknownFileType')}</Typography>;
};
