import { Chip, ChipProps } from '@mui/material';
import { useFileTypeMeta } from '../file-types';
import { useFileSizeFormatter } from '../intl';

export type FileChipProps = Omit<ChipProps, 'label' | 'icon'> & {
  fileType?: string;
  fileSize?: number;
};

/**
 * Chip displaying the type and size of a file
 */
export const FileChip = (props: FileChipProps) => {
  const { fileType, fileSize, ...other } = props;
  const { Icon: FileIcon, name: fileTypeName } = useFileTypeMeta(fileType);
  const formatFileSize = useFileSizeFormatter({ maximumFractionDigits: 0 });

  return (
    <Chip
      {...other}
      icon={<FileIcon />}
      label={
        fileSize ? `${fileTypeName} ${formatFileSize(fileSize)}` : fileTypeName
      }
    />
  );
};
