import { useMemo } from 'react';
import awaitPromise from '../../utils/awaitPromise';
import { detect, IntlPolyfillTypes } from './detect';

/**
 * These polyfills don't have a ~locale.{locale} version
 */
const NonLocalePolyfills: IntlPolyfillTypes[] = [
  'Locale',
  'getCanonicalLocales',
];

let n = 0;
function loadPolyfills(features: string[]) {
  const polyfillServiceUrl = process.env.REACT_APP_POLYFILL_SERVICE_URL;
  const path =
    process.env.NODE_ENV === 'development'
      ? '/v3/polyfill.js'
      : '/v3/polyfill.min.js';

  const callback = '__POLYFILL_SERVICE_CALLBACK_' + ++n;
  const polyfillUrl = new URL(`${polyfillServiceUrl}${path}`);
  polyfillUrl.searchParams.set('features', features.join(','));
  polyfillUrl.searchParams.set('flags', 'gated');
  polyfillUrl.searchParams.set('callback', callback);

  return new Promise<void>((resolve, reject) => {
    (window as any)[callback] = () => {
      resolve();
    };

    var script = document.createElement('script');
    script.onerror = reject;
    script.src = polyfillUrl.toString();
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}

/**
 * Get list of core polyfills needed for unsupported features
 */
const corePolyfills = Object.entries(detect)
  .filter(([feature, isSupported]) => !isSupported)
  .map(([feature, isSupported]) => `Intl.${feature}`);

/**
 * Get polyfills for a locale
 */
const localePolyfills = (locale: string) =>
  corePolyfills.map((feature) =>
    NonLocalePolyfills.includes(feature as any)
      ? feature
      : `${feature}.~locale.${locale}`
  );

const polyfilledLocales = new Map<string, Promise<void> | null>();
/**
 * Load polyfills for a locale
 */
function loadLocale(locale: string): Promise<void> | null {
  let polyfill = polyfilledLocales.get(locale);
  if (polyfill !== undefined) return polyfill;

  const polyfills = localePolyfills(locale);
  polyfill =
    polyfills.length > 0
      ? loadPolyfills([...corePolyfills, ...localePolyfills(locale)])
      : null;
  polyfilledLocales.set(locale, polyfill);

  return polyfill;
}

/**
 * Hook that uses suspense to ensure that Intl polyfills are loaded
 */
export function useIntlPolyfills(locale: string) {
  const localePolyfill = useMemo(() => loadLocale(locale), [locale]);

  // Wait for the polyfills to load
  if (localePolyfill) awaitPromise(localePolyfill);
}
