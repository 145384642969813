import { forwardRef } from 'react';
import { Authority, ClientAuthority } from '../../../api';
import { useCan } from '../../../auth';
import { useAuthorityLabels, useLabelFn } from '../../../intl';
import { enumArray, useSortedOptions } from '../../utils';
import { TagArrayField, TagArrayFieldProps } from '../TagArrayField';

export interface AuthorityFieldProps
  extends Omit<TagArrayFieldProps<Authority, false>, 'options'> {
  disableAdminAuthorities?: boolean;
}

const ClientVisibleAuthorities = new Set<Authority>(
  Object.values(ClientAuthority) as any
);

/**
 * A Tag array combobox field for selecting a list of Authorities
 */
export const AuthorityArrayField = forwardRef(function AuthorityArrayField(
  props: AuthorityFieldProps,
  ref: any
) {
  const { disableAdminAuthorities = false, ...other } = props;
  const can = useCan();
  const getAuthorityLabel = useLabelFn(useAuthorityLabels());
  const options = useSortedOptions(
    enumArray(Authority),
    getAuthorityLabel
  ).filter((authority) => {
    // Skip authority
    if (authority === Authority.ChangePassword) return false;
    if (
      !ClientVisibleAuthorities.has(authority) &&
      (disableAdminAuthorities || !can.read('PrivContacts'))
    ) {
      // @fixme Ideally we would switch to useField and allow any authority already in the value
      return false;
    }
    return true;
  });

  return (
    <TagArrayField
      ref={ref}
      {...other}
      options={options}
      getOptionLabel={getAuthorityLabel}
    />
  );
});
