import {
  Badge,
  ButtonBase,
  ButtonBaseProps,
  styled,
  Tooltip,
} from '@mui/material';
import {
  AccountAlertOutline,
  AccountClockOutline as AccountClockIcon,
  AccountOffOutline as AccountOffIcon,
  CommentOffOutline as CommentOffIcon,
  CommentOutline as CommentIcon,
  CommentTextMultipleOutline as CommentTextMultipleIcon,
  CommentTextOutline as CommentTextIcon,
} from 'mdi-material-ui';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Author, contactName, Review, ReviewStatus } from '../../../api';
import { useCurrentContactId } from '../../../auth/hooks';
import { useLabelFn, useReviewStatusLabels } from '../../../intl';
import { ReviewStatusIcon } from '../../ui/ReviewStatusIcon';

const ReviewPillButtonWrapper = styled('div', {
  name: 'ReviewPillButton',
  slot: 'Wrapper',
})({
  display: 'flex',
  flexDirection: 'row',
});

const ReviewPillButtonRoot = styled(ButtonBase, {
  name: 'ReviewPillButton',
  slot: 'Root',
})(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderRadius: 9999,
  minHeight: 48,

  '& > .spacer': {
    width: theme.spacing(1),
  },

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export interface ReviewPillButtonProps {
  status?: Review['Status'];
  editable?: boolean;
  commentCount?: number;
  assignedTo?: Author;
  onClick?: ButtonBaseProps['onClick'];
}

export const ReviewPillButton = (props: ReviewPillButtonProps) => {
  const {
    status = ReviewStatus.UnReviewed,
    editable = false,
    commentCount = 0,
    assignedTo,
    onClick,
  } = props;
  const { t } = useTranslation();
  const currentUserContactId = useCurrentContactId();
  const reviewStatusLabel = useLabelFn(useReviewStatusLabels());
  const isAssignedToSelf = currentUserContactId === assignedTo?.ContactId;

  let commentIcon: ReactElement = <CommentTextMultipleIcon fontSize="small" />;
  if (commentCount === 1) commentIcon = <CommentTextIcon fontSize="small" />;
  else if (commentCount === 0) {
    commentIcon = editable ? (
      <CommentIcon fontSize="small" />
    ) : (
      <CommentOffIcon fontSize="small" />
    );
  }

  const statusLabel = reviewStatusLabel(status);
  const commentsLabel = t('tooltip.comments', { count: commentCount });
  // @todo Add an "assigned to myself" state
  const assignmentLabel = isAssignedToSelf
    ? t('tooltip.assignedToYou')
    : assignedTo
    ? t('tooltip.assignedTo', { name: contactName(assignedTo) })
    : t('tooltip.unassigned');

  const tooltip = `${statusLabel}, ${commentsLabel}, ${assignmentLabel}`;
  const ariaLabel = `${t('button.openReview')}, ${tooltip}`;

  const assignmentIcon = isAssignedToSelf ? (
    <AccountAlertOutline fontSize="small" />
  ) : assignedTo ? (
    <AccountClockIcon fontSize="small" />
  ) : (
    <AccountOffIcon fontSize="small" />
  );

  return (
    <Tooltip title={tooltip} disableInteractive>
      <ReviewPillButtonWrapper>
        <ReviewPillButtonRoot
          aria-label={ariaLabel}
          disabled={!editable && commentCount === 0}
          onClick={onClick}
        >
          <ReviewStatusIcon status={status} fontSize="small" />
          <span className="spacer" />
          <Badge badgeContent={commentCount} color="primary">
            {commentIcon}
          </Badge>
          <span className="spacer" />
          {assignmentIcon}
        </ReviewPillButtonRoot>
      </ReviewPillButtonWrapper>
    </Tooltip>
  );
};
