import { AddCircleOutline as AddCircleIcon } from '@mui/icons-material';
import { Button, ButtonProps, ButtonTypeMap } from '@mui/material';
import { ElementType, forwardRef } from 'react';

/**
 * A common "Add" button with a plus icon
 */
export const AddButton = forwardRef(function AddButton<
  D extends ElementType = ButtonTypeMap['defaultComponent'],
  P = {}
>(props: ButtonProps<D, P>, ref: any) {
  return (
    <Button
      ref={ref}
      startIcon={
        <AddCircleIcon color={props.disabled ? 'disabled' : 'primary'} />
      }
      {...props}
    />
  );
}) as <D extends ElementType = ButtonTypeMap['defaultComponent'], P = {}>(
  props: ButtonProps<D, P>
) => JSX.Element;
