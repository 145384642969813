import { useIsSubmitting } from '@johnrom/formik-v3';
import { createContext, ReactNode, useContext } from 'react';

export const DisabledContext = createContext(false);

export interface DisabledProps {
  disabled?: boolean;
  children?: ReactNode;
}

/**
 * Check if the section of a form is contextually disabled
 */
export function useDisabled() {
  return useContext(DisabledContext);
}

/**
 * Component used to contextually disable a section of a form
 */
export const Disabled = (props: DisabledProps) => {
  const { disabled: disabledProps = false, children } = props;
  const disabledInherited = useDisabled();
  const disabled = disabledProps || disabledInherited;

  return (
    <DisabledContext.Provider value={disabled}>
      {children}
    </DisabledContext.Provider>
  );
};

/**
 * Helper to check if a field is disabled
 *
 * Returns true if:
 * - The component's disabled prop is true
 * - The component is contextually disabled by a <Disabled> component or <Form disabled>
 * - The form is currently submitting
 */
export function useFieldDisabled(
  disabledProp: boolean | undefined | null = false
) {
  const isSubmitting = useIsSubmitting();
  const disabledInherited = useDisabled();

  return disabledProp || disabledInherited || isSubmitting;
}
