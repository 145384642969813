import { styled } from '@mui/material';

/**
 * A custom grid layout for array rows with a remove button
 */
export const ArrayRowLayout = styled('div')(
  {
    display: 'grid',
    gridTemplateAreas: `"main remove"`,
    gridTemplateColumns: `1fr 48px`,
    // Remove
    '& > .remove': {
      gridArea: 'remove',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      // Align with dense TextField
      paddingTop: 8,
      paddingBottom: 4,
    },
  },
  { name: 'ArrayRowLayout' }
);
