import {
  Badge,
  BadgeProps,
  CircularProgress,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material';
import { NotificationsNoneOutlined as NotificationsNoneIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export type NotificationButtonProps = IconButtonProps & {
  notificationCount?: number;
  loading?: boolean;
  error?: boolean;
};

export const NotificationButton = (props: NotificationButtonProps) => {
  const { notificationCount = 0, loading, error, ...other } = props;
  const { t } = useTranslation();

  let badgeProps: BadgeProps = {
    variant: 'standard',
    badgeContent: notificationCount,
    color: 'primary',
  };
  if (error) {
    badgeProps = {
      variant: 'dot',
      badgeContent: 1,
      color: 'error',
    };
  }
  if (loading) {
    badgeProps = {
      badgeContent: <CircularProgress variant="indeterminate" size={8} />,
    };
  }

  return (
    <Tooltip title={t('tooltip.notifications') as string}>
      <span>
        <IconButton {...other} color="inherit">
          <Badge {...badgeProps}>
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
      </span>
    </Tooltip>
  );
};
