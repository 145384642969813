import Axios, { AxiosResponse } from 'axios';
import { useAuthStateDispatch, useNavigateChangePassword } from '../../../auth';
import {
  CancelablePromise,
  promiseCancelationPassthrough,
} from '../../../utils/CancelablePromise';
import {
  ApiError,
  isAuthenticationFailedError,
  isMustChangePasswordError,
} from '../../errors';
import { ApiQueryOptions } from '../types';
import { useAccessToken } from './useAccessToken';

/**
 * Handle a query promise, handling errors and returning the result
 */
export function useQueryPromiseHandler<TData>(
  options: Pick<ApiQueryOptions<TData>, 'auth' | 'noErrorRedirect'>
): (
  queryPromise: CancelablePromise<AxiosResponse<TData>>
) => CancelablePromise<TData> {
  const { auth, noErrorRedirect } = options;
  const navigateChangePassword = useNavigateChangePassword();
  const accessToken = useAccessToken(options?.auth);
  const authDispatch = useAuthStateDispatch();

  return (queryPromise) => {
    return promiseCancelationPassthrough<AxiosResponse<TData>, TData>(
      queryPromise,
      (queryPromise) =>
        queryPromise
          .then((response) => response.data)
          .catch((err) => {
            if (Axios.isCancel(err)) {
              // Ignore cancelations, they only happen when there is no component to dispatch to or a new request will start a dispatch
              // However we do need to re-throw these to avoid telling mutations that a request was successful
              throw err;
            }

            // Convert error to ApiError if it matches an intentional API error's data
            if (err?.response?.data?.Reason) {
              err = new ApiError(err.response.data, err);
            }

            // Clear the auth token if an api request failed with an authentication error
            if (isAuthenticationFailedError(err) && auth !== 'NONE') {
              authDispatch({ type: 'AUTH_FAILED', oldToken: accessToken });
            }

            if (isMustChangePasswordError(err) && !noErrorRedirect) {
              navigateChangePassword();
            }

            throw err;
          })
    );
  };
}
