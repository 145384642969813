import { TextField } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import usePropsWithId from './internal/usePropsWithId';
import {
  useTextFieldProps,
  UseTextFieldProps,
} from './internal/useTextFieldProps';

export type SimpleFieldProps = Omit<
  UseTextFieldProps,
  'type' | 'select' | 'multiple'
> &
  CommonValidatorProps & { readOnly?: boolean };

/**
 * A <TextField> for a single line of raw text
 */
export const SimpleField = forwardRef(function SimpleField(
  props: SimpleFieldProps,
  ref: Ref<HTMLInputElement>
) {
  props = usePropsWithId(props);
  const validate = useCommonValidate(props);

  const textFieldProps = useTextFieldProps({
    ...props,
    validate,
    type: 'text',
  });

  if (props.readOnly) return <ReadOnlyField ref={ref} {...textFieldProps} />;

  return <TextField ref={ref} {...textFieldProps} />;
});
