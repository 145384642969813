import { useMemo } from 'react';
import awaitPromise from '../utils/awaitPromise';
import { getLibPhoneNumber, LibPhoneNumber } from './internal/loader';

/**
 * Uses suspense to load libphonenumber-js asynchronusly without including it in the main bundle
 */
export function useLibPhoneNumber(): LibPhoneNumber {
  const libphonenumber = useMemo(() => getLibPhoneNumber(), []);
  return awaitPromise(libphonenumber);
}
