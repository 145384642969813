import { useCallback, useMemo } from 'react';
import { Code } from '../../../api';
import { getOptions, useOptionLabel } from './option';
import { useSortedOptions } from './sort';
import { OptionsProps } from './type';

/**
 * Get a simple options array for an array of Codes
 */
export function useCodeOptions(codes: Code[] | undefined | null): string[] {
  return useMemo(() => getOptions(codes, (code) => code.Id ?? ''), [codes]);
}

/**
 * Get a function that given a Code's Id will output its Name label
 */
export function useCodeLabel(
  codes: Code[] | undefined | null
): (value: string) => string {
  return useOptionLabel(
    codes,
    useCallback((code: Code) => code.Id ?? '', []),
    useCallback((code: Code) => code.Name, [])
  );
}

/**
 * Generate SelectField/Autocomplete options props to use Codes as options
 */
export function useCodeOptionsProps(
  codes: Code[] | undefined | null
): OptionsProps {
  const options = useCodeOptions(codes);
  const getOptionLabel = useCodeLabel(codes);

  return { options, getOptionLabel };
}

/**
 * Generate SelectField/Autocomplete options props to use Codes with custom labels sorted alphabetically as options
 */
export function useLabeledCodeOptionsProps(
  codes: Code[] | undefined | null,
  getLabel: (value: string) => string
): OptionsProps {
  const options = useSortedOptions(useCodeOptions(codes), getLabel);

  return { options, getOptionLabel: getLabel };
}
