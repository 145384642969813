import { TextField } from '@mui/material';
import { forwardRef, Ref, useCallback } from 'react';
import { useLibPhoneNumber } from '../../libphonenumber/useLibPhoneNumber';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import { Validator } from '../validation/validator';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import {
  usePhoneFieldProps,
  UsePhoneFieldProps,
} from './internal/usePhoneFieldProps';
import usePropsWithId from './internal/usePropsWithId';

export type PhoneFieldProps = Omit<UsePhoneFieldProps, 'type'> &
  CommonValidatorProps & { readOnly?: boolean };

/**
 * A <TextField> for a percentage
 */
export const PhoneField = forwardRef(function PhoneField(
  props: PhoneFieldProps,
  ref: Ref<HTMLInputElement>
) {
  const { ...other } = usePropsWithId(props);

  const { validatePhoneNumberLength } = useLibPhoneNumber();
  const validatePhoneNumber = useCallback<Validator>(
    (value: any) => {
      if (!value) return undefined;
      const invalid = validatePhoneNumberLength(value);
      if (invalid === 'INVALID_COUNTRY') return 'Invalid calling code';
      else if (invalid === 'TOO_SHORT') return 'Phone number too short';
      else if (invalid === 'TOO_LONG') return 'Phone number too long';
      else if (invalid === 'INVALID_LENGTH') return 'Bad phone number format';
      else if (invalid) return 'Invalid phone number';
      return undefined;
    },
    [validatePhoneNumberLength]
  );
  const validate = useCommonValidate(props, () => [validatePhoneNumber]);

  const textFieldProps = usePhoneFieldProps({
    ...other,
    validate,
    inputProps: {
      ...other.inputProps,
    },
    type: 'tel',
  });

  if (props.readOnly)
    return (
      <ReadOnlyField
        ref={ref}
        {...textFieldProps}
        enableAdornment
        startAdornment={textFieldProps.InputProps?.startAdornment}
      />
    );

  return <TextField ref={ref} {...textFieldProps} />;
});
