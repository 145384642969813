import { forwardRef } from 'react';
import { NotificationType } from '../../../api';
import { useNotificationTypeLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type NotificationTypeFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a NotificationType
 */
export const NotificationTypeField = forwardRef(function NotificationTypeField(
  props: NotificationTypeFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(
    NotificationType,
    useNotificationTypeLabels()
  );

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
