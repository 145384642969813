import { z } from 'zod';
import { useLocationState } from '../../../utils/location';

/**
 * Get the location state for a create payment page
 */
export function usePaymentLocationState(): { type: 'new' | 'repeat' } {
  const { type = 'new' } = useLocationState(
    z
      .object({
        type: z.enum(['new', 'repeat']).default('new'),
      })
      .partial()
  );

  return { type };
}
