import {
  useIsDirty,
  useIsSubmitting,
  useIsValid,
  useIsValidating,
} from '@johnrom/formik-v3';
import { Button, ButtonProps } from '@mui/material';
import { createElement } from 'react';

export interface SubmitButtonProps
  extends Pick<
    ButtonProps,
    | 'children'
    | 'color'
    | 'disabled'
    | 'disableElevation'
    | 'disableFocusRipple'
    | 'endIcon'
    | 'fullWidth'
    | 'size'
    | 'startIcon'
    | 'variant'
    | 'sx'
  > {
  dirtyOnly?: boolean;
  validOnly?: boolean;
}

/**
 * MUI <Button> to submit a form
 */
export const SubmitButton = (props: SubmitButtonProps) => {
  const {
    children,
    disabled,
    dirtyOnly = false,
    validOnly = false,
    ...other
  } = props;
  const isValidating = useIsValidating();
  const isSubmitting = useIsSubmitting();
  const dirty = useIsDirty();
  const isValid = useIsValid();

  return createElement(
    Button,
    {
      type: 'submit',
      disabled:
        disabled ||
        isValidating ||
        isSubmitting ||
        (dirtyOnly && !dirty) ||
        (validOnly && !isValid),
      ...other,
    },
    children
  );
};
