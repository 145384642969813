import { styled } from '@mui/material';

/**
 * Layout for the help buttons below navigation items
 */
export const SidebarHelpSection = styled('div', { name: 'SidebarHelpSection' })(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: theme.spacing(0, 3),
    paddingTop: theme.spacing(4),
    gap: theme.spacing(1, 0),
  })
);
