import { useMediaQuery, useTheme } from '@mui/material';
import { Breakpoint } from '@mui/system';

/**
 * Is the current viewport larger than or equal to a breakpoint
 * @see [API documentation](https://mui.com/material-ui/customization/breakpoints/#theme-breakpoints-up-key-media-query)
 */
export function useBreakpointUp(key: Breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(key));
}

/**
 * Is the current viewport smaller than to a breakpoint
 * @see [API documentation](https://mui.com/material-ui/customization/breakpoints/#theme-breakpoints-down-key-media-query)
 */
export function useBreakpointDown(key: Breakpoint) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
}
