import { Popover, PopoverProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { thinScrollbar } from '../../utils/scroll';
import SrOnly from '../../utils/SrOnly';

export interface ColumnsPopoverProps extends PopoverProps {}

export const ColumnsPopover = (props: ColumnsPopoverProps) => {
  const { children, ...popoverProps } = props;
  const { t } = useTranslation();

  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      {...popoverProps}
      PaperProps={{
        sx: {
          ...thinScrollbar,
        },
      }}
    >
      <SrOnly as="h2">{t('title.columns')}</SrOnly>
      {children}
    </Popover>
  );
};
