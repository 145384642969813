import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BeneficiaryType } from '../../api';

/**
 * Provide localized labels for the BeneficiaryType enum
 */
export function useBeneficiaryTypeLabels(): Record<BeneficiaryType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<BeneficiaryType, string> => ({
      Company: t('enumLabel.beneficiaryType.company'),
      Person: t('enumLabel.beneficiaryType.person'),
    }),
    [t]
  );
}
