import { useModal } from 'mui-modal-provider';
import { forwardRef, useCallback } from 'react';
import { Attachment } from '../../api';
import { NewContractDocumentDialog } from '../dialog';
import { DocumentCard } from './DocumentCard';

export interface ContractDocumentCardProps {
  label: string;
  noDocumentLabel: string;
  orgId?: string | null;
  attachment?: Attachment;
  onUpload?: (doc: Attachment) => void;
  readOnly?: boolean;
}

export const ContractDocumentCard = forwardRef(function ContractDocumentCard(
  props: ContractDocumentCardProps,
  ref: any
) {
  const {
    orgId,
    label,
    noDocumentLabel,
    attachment,
    onUpload,
    readOnly = false,
  } = props;
  const { showModal } = useModal();

  const upload = useCallback(() => {
    showModal(NewContractDocumentDialog, {
      orgId,
      onUpload,
    });
  }, [onUpload, orgId, showModal]);

  if (attachment) {
    return (
      <DocumentCard
        ref={ref}
        attachment={attachment}
        title={label}
        onUpload={!readOnly ? upload : undefined}
      />
    );
  } else {
    return (
      <DocumentCard
        ref={ref}
        title={noDocumentLabel}
        onUpload={!readOnly ? upload : undefined}
      />
    );
  }
});
