import { EQ, AnyComparator } from './comparators';
import invariant from 'invariant';

export type WithClientFilter<T extends object> = T & {
  client?: EQ<string | null>;
};

export type FilterReturnArg = string | string[] | undefined;

/**
 * @internal
 */
export function makeFilter(
  filter: AnyComparator | undefined
): undefined | string | string[];
export function makeFilter(
  filter: AnyComparator<string | null> | undefined
): undefined | string | string[];
export function makeFilter<T>(
  filter: AnyComparator<T> | undefined,
  map: (value: T, comparator: keyof AnyComparator<T>) => string
): undefined | string | string[];
export function makeFilter<T = string>(
  filter: AnyComparator<T> | undefined,
  map?: (value: T, comparator: keyof AnyComparator<T>) => string
): undefined | string | string[] {
  if (arguments.length === 1) {
    map = (value) => String(value);
  }
  invariant(map, 'map should never be undefined');

  const filters: string[] = [];
  if (filter?.eq) filters.push('eq_' + map(filter.eq, 'eq'));
  if (filter?.contains)
    filters.push('cont_' + map(filter.contains, 'contains'));
  if (filter?.neq) filters.push('neq_' + map(filter.neq, 'neq'));
  if (filter?.in && filter.in.length > 0)
    filters.push('in_' + filter.in.map((item) => map?.(item, 'in')).join(','));
  if (filter?.lt) filters.push('lt_' + map(filter.lt, 'lt'));
  if (filter?.gt) filters.push('gt_' + map(filter.gt, 'gt'));
  if (filter?.lte) filters.push('lte_' + map(filter.lte, 'lte'));
  if (filter?.gte) filters.push('gte_' + map(filter.gte, 'gte'));
  if (filter?.arrcont)
    filters.push('arrcont_' + map(filter.arrcont, 'arrcont'));

  if (filters.length === 0) return undefined;
  else if (filters.length === 1) return filters[0];
  else return filters;
}
