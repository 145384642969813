import { ListItemIcon } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { ReviewStatus } from '../../../api';
import { useLabelFn, useReviewStatusLabels } from '../../../intl';
import { ReviewStatusIcon } from '../../../profile';
import { enumArray } from '../../utils';
import { useSortedOptions } from '../../utils/options';
import { SelectField, SelectFieldProps } from '../SelectField';

export type ReviewStatusFieldProps = Omit<
  SelectFieldProps<ReviewStatus>,
  'options'
>;

/**
 * A <Select> field for selecting a ReviewStatus
 */
export const ReviewStatusField = forwardRef(function ReviewStatusField(
  props: ReviewStatusFieldProps,
  ref: any
) {
  const { ...other } = props;

  const getOptionLabel = useLabelFn(useReviewStatusLabels());
  const renderOptionLabel = useCallback(
    (status: ReviewStatus) => (
      <>
        <ListItemIcon>
          <ReviewStatusIcon status={status} />
        </ListItemIcon>
        {getOptionLabel(status)}
      </>
    ),
    [getOptionLabel]
  );
  const options = useSortedOptions(enumArray(ReviewStatus), getOptionLabel);

  return (
    <SelectField<ReviewStatus>
      ref={ref}
      {...other}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOptionLabel={renderOptionLabel}
    />
  );
});
