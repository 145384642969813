import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventType } from '../../api';

/**
 * Provide localized labels for the EventType enum
 */
export function useEventTypeLabels(): Record<EventType, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<EventType, string> => ({
      Accepted: t('enumLabel.eventType.accepted'),
      Rejected: t('enumLabel.eventType.rejected'),
      Submitted: t('enumLabel.eventType.submitted'),
      Executed: t('enumLabel.eventType.executed'),
      Reversed: t('enumLabel.eventType.reversed'),
      Stopped: t('enumLabel.eventType.stopped'),
      Held: t('enumLabel.eventType.held'),
    }),
    [t]
  );
}
