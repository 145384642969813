import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import FilterTextInput from './ui/FilterTextInput';

export const DefaultColumnFilter = <D extends object, V = any>(
  props: CellProps<D, V>
) => {
  const { filterValue, preFilteredRows, setFilter } = props.column;
  const count = preFilteredRows.length;
  const { t } = useTranslation();

  return (
    <FilterTextInput
      label={t('inputLabel.searchRecords', { count })}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
};
