import { styled } from '@mui/material';

/**
 * A custom grid layout for "typed" field pair (i.e. Type, Value)
 */
export const TypedFieldLayout = styled('div')(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateAreas: `"type value"`,
    gridTemplateColumns: `128px 1fr`,
    gridGap: theme.spacing(0, 2),
  }),
  { name: 'TypedFieldLayout' }
);
