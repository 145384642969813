import { useCallback, useState } from 'react';

export interface OpenStateProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export interface UseOpenStateResult {
  open: boolean;
  openProps: OpenStateProps;
}

/**
 * Returns open/onOpen/onClose props that are useful for managing
 */
export function useOpenState(): UseOpenStateResult {
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const openProps: OpenStateProps = { open, onOpen, onClose };

  return { open, openProps };
}
