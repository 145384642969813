import { useMemo } from 'react';
import { useCurrentAuthoritiesArray } from './hooks';
import { permissionMap, PermissionType } from './permissions';

export interface UseCanResult {
  /**
   * Check if the current user can read something
   */
  read: (permission: PermissionType) => boolean;
  /**
   * Check if the current user can write something
   */
  write: (permission: PermissionType) => boolean;
}

/**
 * Returns a `can.read(permission)` / `can.write(permission)` helper
 * that indicates whether the current user has permission to do something
 */
export function useCan(): UseCanResult {
  const authorities = useCurrentAuthoritiesArray();
  return useMemo(() => {
    const readOnly = new Set<PermissionType>();
    const write = new Set<PermissionType>();
    for (const authority of authorities) {
      for (const permission of permissionMap[authority].readOnly) {
        readOnly.add(permission);
      }
      for (const permission of permissionMap[authority].write) {
        write.add(permission);
      }
    }

    return {
      read: (permission: PermissionType) =>
        readOnly.has(permission) || write.has(permission),
      write: (permission: PermissionType) => write.has(permission),
    };
  }, [authorities]);
}
