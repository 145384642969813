import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ComplianceLevel } from '../../api';

/**
 * Provide localized labels for the ComplianceLevel enum
 */
export function useComplianceLevelLabels(): Record<ComplianceLevel, string> {
  const { t } = useTranslation();

  return useMemo(
    (): Record<ComplianceLevel, string> => ({
      Trial: t('enumLabel.complianceLevel.trial'),
      Level0: t('enumLabel.complianceLevel.level0'),
      Level1: t('enumLabel.complianceLevel.level1'),
      Level2: t('enumLabel.complianceLevel.level2'),
      Level3: t('enumLabel.complianceLevel.level3'),
      Level4: t('enumLabel.complianceLevel.level4'),
      Level5: t('enumLabel.complianceLevel.level5'),
    }),
    [t]
  );
}
