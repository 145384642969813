import { createContext, ReactElement } from 'react';
import { DevToolsCornerBox } from './DevToolsCornerBox';
import { useLocalStorage } from 'react-use';

export const StagingToolsHiddenContext = createContext(false);
export const SetStagingToolsHiddenContext = createContext(
  (stagingToolsHidden: boolean) => {}
);

export interface DevToolsProviderProps {
  children?: ReactElement;
}

/**
 * Provide a development tools control button in the bottom left corner
 */
export const DevToolsProvider = (props: DevToolsProviderProps) => {
  const { children } = props;

  const [stagingToolsHidden, setStagingToolsHidden] = useLocalStorage(
    'hide-staging-tools',
    false
  );

  if (process.env.REACT_APP_DEVTOOLS === 'true') {
    return (
      <SetStagingToolsHiddenContext.Provider value={setStagingToolsHidden}>
        <StagingToolsHiddenContext.Provider value={stagingToolsHidden ?? false}>
          <>
            {children}
            <DevToolsCornerBox
              stagingToolsHidden={stagingToolsHidden ?? false}
              setStagingToolsHidden={(stagingToolsHidden: boolean) =>
                setStagingToolsHidden?.(stagingToolsHidden)
              }
            />
          </>
        </StagingToolsHiddenContext.Provider>
      </SetStagingToolsHiddenContext.Provider>
    );
  }

  return children ?? null;
};
