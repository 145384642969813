import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { forwardRef, Ref } from 'react';
import {
  getMaskForDateTimeFormat,
  useDateFormatString,
  useDateTimeFormatHint,
  useDateTimeFormatString,
} from '../../intl';
import { Time } from '../../utils/Time';
import { ReadOnlyField } from '../ui/ReadOnlyField';
import useCommonValidate, {
  CommonValidatorProps,
} from './internal/useCommonValidate';
import {
  UseDateTimePickerFieldProps,
  useDateTimePickerFieldProps,
} from './internal/useDateTimePickerFieldProps';
import usePropsWithId from './internal/usePropsWithId';

export type DateTimeFieldProps = Omit<
  UseDateTimePickerFieldProps,
  'dateTimeFormat' | 'mask'
> &
  CommonValidatorProps;

/**
 * A <TextField> for a datetime
 */
export const DateTimeField = forwardRef(function DateTimeField(
  props: DateTimeFieldProps,
  ref: Ref<HTMLInputElement>
) {
  props = usePropsWithId(props);
  const dateTimeFormat = useDateTimeFormatString();
  const dateFormat = useDateFormatString();
  const getDateHint = useDateTimeFormatHint();
  const validate = useCommonValidate(props);

  const dateTimePickerProps = useDateTimePickerFieldProps({
    ...props,
    // @note WARNING: It's not guaranteed that dateFormat and dateTimeFormat will use the same date format
    label: `${props.label} (${getDateHint(dateFormat)})`,
    dateTimeFormat,
    mask: getMaskForDateTimeFormat(dateTimeFormat),
    validate: validate,
  });

  if (props.readOnly)
    return (
      <ReadOnlyField ref={ref} {...dateTimePickerProps}>
        {dateTimePickerProps.value && (
          <Time
            format={DateTime.DATETIME_SHORT_WITH_SECONDS}
            dateTime={dateTimePickerProps.value as DateTime}
          />
        )}
      </ReadOnlyField>
    );

  return <DateTimePicker ref={ref} {...dateTimePickerProps} />;
});
