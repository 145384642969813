import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import {
  NotificationResponse,
  NotificationsApiQueryKey,
  useApiMutation,
  useNotificationsApi,
} from '../../api';

/**
 * Get a callback to mark a list of notifications (by id) as read
 */
export function useMarkNotificationsAsRead(): (ids: string[]) => void {
  const queryClient = useQueryClient();
  const [markNotificationsAsRead] = useApiMutation({
    ...useNotificationsApi().markNotificationsAsReadMutation(),
    onSuccess(updatedNotifications) {
      const updatedNotificationMap = new Map(
        updatedNotifications.map((notification) => [
          notification.id,
          notification,
        ])
      );

      for (const ReadArg of [undefined, 'eq_false', 'eq_true']) {
        const notifications = queryClient.getQueryData<NotificationResponse[]>(
          NotificationsApiQueryKey.getAllNotifications(ReadArg)
        );

        if (notifications) {
          queryClient.setQueryData(
            NotificationsApiQueryKey.getAllNotifications(ReadArg),
            notifications
              .map(
                (notification) =>
                  updatedNotificationMap.get(notification.id) ?? notification
              )
              .filter((notification) =>
                ReadArg === 'eq_false'
                  ? !notification.Read
                  : ReadArg === 'eq_true'
                  ? notification.Read
                  : true
              )
          );
        }
      }
    },
  });
  return useCallback(
    (ids: string[]) => {
      markNotificationsAsRead({
        ids,
      });
    },
    [markNotificationsAsRead]
  );
}
