/**
 * Tests if the string is in the UK sort code + account number format such as "GB:40-40-40 12345678"
 */
export function isSortCodeHackString(code: string): boolean {
  return /^GB:/.test(code);
}

/**
 * Remove dashes and spaces from sort code hack
 */
export function electronicFormatSortCodeHack(code: string): string {
  return code.replace(/[\s-]/g, '');
}

export enum SortCodeHackError {
  InvalidCharacters,
  SortCodeMinLength,
  AccountNumberMinLength,
  AccountNumberMaxLength,
}

export interface SortCodeHackResults {
  valid: boolean;
  errorCodes: SortCodeHackError[];
}

/**
 * Validate UK sort code + account number format such as "GB:40-40-40 12345678"
 */
export function validateSortCodeHack(code: string): SortCodeHackResults {
  const error = (...errorCodes: SortCodeHackError[]): SortCodeHackResults => ({
    valid: false,
    errorCodes,
  });
  const SORT_CODE_LENGTH = 6;
  const ACCOUNT_NUMBER_MIN_LENGTH = 6;
  const ACCOUNT_NUMBER_MAX_LENGTH = 8;

  // Strip GB: prefix
  code = code.replace(/^GB:/, '');

  // Invalid characters (digits)
  if (!/^\d*$/.test(code)) return error(SortCodeHackError.InvalidCharacters);

  // Sort code not long enough
  if (code.length < SORT_CODE_LENGTH)
    return error(SortCodeHackError.SortCodeMinLength);

  // Account number too short
  if (code.length < SORT_CODE_LENGTH + ACCOUNT_NUMBER_MIN_LENGTH)
    return error(SortCodeHackError.AccountNumberMinLength);

  // Account number too long
  if (code.length > SORT_CODE_LENGTH + ACCOUNT_NUMBER_MAX_LENGTH)
    return error(SortCodeHackError.AccountNumberMaxLength);

  return { valid: true, errorCodes: [] };
}
