import { useLayoutEffect, useState } from 'react';

// CapsLock state
let isCapsLockDown = false;

// Listeners
const listeners = new Set<(capsLock: boolean) => void>();

/**
 * Key listener
 * @note This is set immediately so we can catch capslock state before we get to a password field that needs it
 */
window.addEventListener('keydown', (e) => {
  // Leave the CapsLock warning off if the browser doesn't support getModifierState
  if (!('getModifierState' in e)) return;

  const prevCapsLock = isCapsLockDown;
  isCapsLockDown = e.getModifierState('CapsLock');

  if (isCapsLockDown !== prevCapsLock) {
    listeners.forEach((handler) => {
      handler(isCapsLockDown);
    });
  }
});

/**
 * A state hook that returns whether the CapsLock key is currently pressed.
 *
 * @note Will always be false if the browser does not support getModifierState
 * @note If the user's CapsLock key is already pressed, will not become true until their first keypress on the page
 */
export function useCapsLockState() {
  const [capsLock, setCapsLock] = useState(isCapsLockDown);

  useLayoutEffect(() => {
    const handler = (capsLock: boolean) => {
      setCapsLock(capsLock);
    };

    listeners.add(handler);
    return () => {
      listeners.delete(handler);
    };
  }, []);

  return capsLock;
}
