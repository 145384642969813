import { Consumer, createElement, ReactElement } from 'react';
import { createDispatchedStateContext } from './DispatchedStateContext';

export interface ReducedStateContext<S, D> {
  Provider: (props: { children?: ReactElement }) => ReactElement;
  StateConsumer: Consumer<S>;
  /**
   * Get the dispatcher function
   */
  useReducerDispatch: () => D;
  /**
   * Get the current state
   */
  useReducerState: () => S;
}

/**
 * A dispatcher+state context that hoists state up in the tree and manages it with a custom hook that exports state and a dispatcher
 */
export function createReducedStateContext<S, D>(
  displayName: string,
  useReducedState: () => [S, D],
  defaultState: S,
  defaultDispatcher: D
): ReducedStateContext<S, D> {
  const {
    Provider: DSProvider,
    StateConsumer,
    useReducerDispatch,
    useReducerState,
  } = createDispatchedStateContext<S, D>(
    displayName,
    defaultState,
    defaultDispatcher
  );

  const Provider = ({ children }: { children?: ReactElement }) => {
    const [state, dispatch] = useReducedState();

    return createElement(DSProvider, { dispatch, state }, children);
  };
  Provider.displayName = displayName;

  return { Provider, StateConsumer, useReducerDispatch, useReducerState };
}
