import {
  ChevronLeftOutlined as ChevronLeftIcon,
  ChevronRightOutlined as ChevronRightIcon,
  FirstPageOutlined as FirstPageIcon,
  LastPageOutlined as LastPageIcon,
  YoutubeSearchedForOutlined,
  ZoomInOutlined as ZoomInIcon,
  ZoomOutOutlined as ZoomOutIcon,
} from '@mui/icons-material';
import { alpha, IconButton, styled, Typography } from '@mui/material';
import { dark } from '@mui/material/styles/createPalette';
import { useTranslation } from 'react-i18next';

export const ViewerControlsRoot = styled('div', {
  name: 'ViewerControls',
  slot: 'Root',
})(({ theme }) => ({
  height: theme.spacing(8 - 2),
  width: '100%',
}));

const ViewerControlsBox = styled('div', {
  name: 'ViewerControls',
  slot: 'Box',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.common.black, 0.9),
  color: theme.palette.getContrastText(theme.palette.common.black),
  borderRadius: 3,
  margin: theme.spacing(1),
  '& .MuiButtonBase-root.Mui-disabled': {
    color: dark.action.disabled,
  },
}));

export interface ViewerControlsProps {
  page?: number;
  total?: number;
  scale?: number;
  goToPage?: (page: number) => void;
  setScale?: (scale: number) => void;
}

const noop = () => {};

/**
 * Zoom and pagination controls for viewers
 */
export const ViewerControls = (props: ViewerControlsProps) => {
  const {
    page = 1,
    total = 1,
    scale = 1,
    goToPage = noop,
    setScale = noop,
  } = props;
  const { t } = useTranslation();

  const hasPrev = page > 1;
  const hasNext = page < total;

  const goFirstPage = () => goToPage(1);
  const goPrevPage = () => goToPage(hasPrev ? page - 1 : page);
  const goNextPage = () => goToPage(hasNext ? page + 1 : page);
  const goLastPage = () => goToPage(total);

  const minScale = 0.1;
  const maxScale = 2;
  const scaleStep = 0.1;
  const isMinScale = scale <= minScale;
  const isMaxScale = scale >= maxScale;
  const resetZoom = () => setScale(1);
  const zoomOut = () => setScale(Math.max(minScale, scale - scaleStep));
  const zoomIn = () => setScale(Math.min(maxScale, scale + scaleStep));

  return (
    <ViewerControlsRoot>
      <ViewerControlsBox sx={{ right: 0, bottom: 0 }}>
        <IconButton
          disabled={!hasPrev}
          aria-label={t('button.firstPage')}
          onClick={goFirstPage}
          color="inherit"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          disabled={!hasPrev}
          aria-label={t('button.previousPage')}
          onClick={goPrevPage}
          color="inherit"
        >
          <ChevronLeftIcon />
        </IconButton>
        <Typography component="span" variant="caption">
          {t('pagination.pagePageTotal', { page, total })}
        </Typography>
        <IconButton
          disabled={!hasNext}
          aria-label={t('button.nextPage')}
          onClick={goNextPage}
          color="inherit"
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          disabled={!hasNext}
          aria-label={t('button.lastPage')}
          onClick={goLastPage}
          color="inherit"
        >
          <LastPageIcon />
        </IconButton>
      </ViewerControlsBox>
      <ViewerControlsBox sx={{ left: 0, bottom: 0 }}>
        <IconButton
          disabled={isMinScale}
          aria-label={t('button.zoomOut')}
          onClick={zoomOut}
          color="inherit"
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          aria-label={t('button.resetZoom')}
          onClick={resetZoom}
          color="inherit"
        >
          <YoutubeSearchedForOutlined />
        </IconButton>
        <IconButton
          disabled={isMaxScale}
          aria-label={t('button.zoomIn')}
          onClick={zoomIn}
          color="inherit"
        >
          <ZoomInIcon />
        </IconButton>
      </ViewerControlsBox>
    </ViewerControlsRoot>
  );
};
