import { forwardRef } from 'react';
import { Role } from '../../../api';
import { useRoleLabels } from '../../../intl';
import { useEnumOptions } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type RoleFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a Role
 */
export const RoleField = forwardRef(function RoleField(
  props: RoleFieldProps,
  ref: any
) {
  const { ...other } = props;
  const optionsProps = useEnumOptions(Role, useRoleLabels());

  return <SelectField ref={ref} {...other} {...optionsProps} />;
});
