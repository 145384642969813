import {
  Button,
  Drawer,
  DrawerProps,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import { FormikHelpers } from '@johnrom/formik-v3';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIconButton, Form, FormError } from '../../form';
import { SubmitButton } from '../../form/SubmitButton';
import { getPaperBackground } from '../../theme';
import useHtmlId from '../../utils/useHtmlId';

const FilterSideSheetBody = styled('div', {
  name: 'FilterSideSheet',
  slot: 'Body',
})(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
}));

export const FilterSideSheetButtonFooter = styled('div', {
  name: 'FilterSideSheet',
  slot: 'ButtonFooter',
})(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(1, 2),
  zIndex: 1,
  ...getPaperBackground(theme, 16),
}));

export type FilterSideSheetProps<FilterValues> = Omit<
  DrawerProps,
  'children' | 'variant' | 'anchor' | 'onChange'
> & {
  filter: FilterValues;
  filterCount?: number;
  onChange: (
    values: FilterValues,
    formikHelpers: FormikHelpers<FilterValues>
  ) => Promise<any>;
  onReset?: () => any;
  children: ReactNode;
};

/**
 * Base implementaiton of side sheet for table filters
 */
export const FilterSideSheet = <FilterValues extends {}>(
  props: FilterSideSheetProps<FilterValues>
) => {
  const {
    filter, // @fixme Rename to values to reflect this is not the actual filters
    filterCount = 0,
    onChange,
    onReset,
    children,
    ...other
  } = props;
  const { t } = useTranslation();
  const id = useHtmlId();
  const headerId = `${id}-header`;

  const submit = async (
    values: FilterValues,
    formikHelpers: FormikHelpers<FilterValues>
  ) => {
    onChange(values, formikHelpers);
    other?.onClose?.({} as any, 'escapeKeyDown');
  };

  return (
    <Drawer
      open
      ModalProps={{ keepMounted: true }}
      {...other}
      PaperProps={{
        sx: {
          width: 600,
          maxWidth: '80vw',
        },
      }}
      anchor="right"
      variant="temporary"
    >
      <Toolbar sx={{ '&': { pr: 0 } }}>
        <Typography
          id={headerId}
          component="h2"
          variant="h6"
          sx={{ flex: 1, mr: 2 }}
        >
          {t('title.filters')}
        </Typography>

        <CloseIconButton
          color="inherit"
          onClick={(e) => other?.onClose?.(e, 'escapeKeyDown')}
        />
      </Toolbar>

      <Form
        enableReinitialize
        disableBlocker
        initialValues={filter}
        onSubmit={submit}
        aria-labelledby={headerId}
      >
        <FilterSideSheetBody>{children}</FilterSideSheetBody>

        <FormError variant="snackbar" />

        <FilterSideSheetButtonFooter>
          <Button
            type="reset"
            variant="text"
            disabled={!onReset}
            onClick={(e) => {
              onReset && onReset();
              other?.onClose?.(e, 'escapeKeyDown');
            }}
          >
            {filterCount > 0
              ? t('button.resetCount', { total: filterCount })
              : t('button.reset')}
          </Button>
          <SubmitButton variant="contained" dirtyOnly>
            {t('button.search')}
          </SubmitButton>
        </FilterSideSheetButtonFooter>
      </Form>
    </Drawer>
  );
};
