import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useSessionStorage } from 'react-use';
import { createReducedStateContext } from '../reducer-context';
import { parseJwtExp } from './parseJwtExp';
import { useTokenAutoRefresh } from './tokenAutoRefresh';
import { AuthState, AuthStateAction, AuthStateDispatch } from './types';

export const UNAUTHENTICATED: AuthState = Object.freeze({
  token: undefined,
  authorities: [],
  uid: undefined,
  contactId: undefined,
  autoRefreshToken: false,
} as AuthState);

const { Provider, useReducerDispatch, useReducerState } =
  createReducedStateContext<AuthState, AuthStateDispatch>(
    'AuthState',
    () => {
      const queryClient = useQueryClient();
      const [authState, setAuthState] = useSessionStorage<AuthState>(
        'transactive-auth-state',
        UNAUTHENTICATED
      );
      const { token, autoRefreshToken, uid, authorities } = authState;

      // @note useSessionStorage does not support the callback form of setState properly, maybe use it if we replace it
      const authStateRef = useRef<AuthState>();
      authStateRef.current = authState;
      const dispatch = useCallback(
        (action: AuthStateAction) => {
          switch (action.type) {
            case 'CHANGE':
              setAuthState(action.authState);

              if (authState.uid !== action.authState.uid) {
                // @fixme This is a temporary workaround till we can get a useSessionStorage implementation for filters
                Object.keys(localStorage)
                  .filter((key) => /^.+-(filter|sort)state--?\d+$/.test(key))
                  .forEach((key) => {
                    localStorage.removeItem(key);
                  });
              }
              break;
            case 'LOGOUT':
              setAuthState(UNAUTHENTICATED);
              break;
            case 'AUTH_FAILED':
              if (action.oldToken === authStateRef.current?.token) {
                setAuthState(UNAUTHENTICATED);
              }
              break;
          }
        },
        [authState.uid, setAuthState]
      );

      // Auto-purge API query cache when
      // - The user changes
      // - The user's authorities changes
      const _authorities = authorities.slice().sort().join('|');
      useEffect(() => {
        // @note Reference the _authorities to ensure it stays in the deps list
        if (_authorities) {
        }
        queryClient.clear();
      }, [_authorities, queryClient, uid]);

      // Token auto-refresh
      const refreshAt = useMemo(() => {
        if (token && autoRefreshToken) {
          const exp = parseJwtExp(token);
          if (exp) {
            return DateTime.fromMillis(exp * 1000).minus({
              minutes: 2,
            });
          }
        }
      }, [autoRefreshToken, token]);
      useTokenAutoRefresh(dispatch, refreshAt, token);

      return [authState, dispatch];
    },
    UNAUTHENTICATED,
    () => {
      console.warn(
        `Default auth displatcher called, auth root may not be setup.`
      );
    }
  );

/**
 * Provides auth state
 */
export const AuthProvider = Provider;

/**
 * Get a dispatcher to update auth state
 * @internal
 */
export const useAuthStateDispatch = () => useReducerDispatch();

/**
 * Get the current auth state
 * @internal
 */
export const useAuthState = () => useReducerState();
