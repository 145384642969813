import { IconButton, IconButtonProps } from '@mui/material';
import { CloseOutlined as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/**
 * IconButton for closing things
 */
export const CloseIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton aria-label={t('button.close')} color="primary" {...props}>
      <CloseIcon />
    </IconButton>
  );
};
