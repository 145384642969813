import { ApiInfiniteQueryOptions, ApiQueryOptions } from '../types';

/**
 * Check if an API call should be skipped according to it's options and access token
 */
export function useSkipOption<TData>(
  accessToken: string | undefined,
  options: ApiQueryOptions<TData> | ApiInfiniteQueryOptions<TData>
): boolean {
  const auth = options.auth ?? 'AUTHENTICATED';

  if (options.skip) return true;
  if (auth === 'AUTHENTICATED' && !accessToken) return true;

  return false;
}
