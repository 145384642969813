import { forwardRef, ReactElement, Ref } from 'react';
import { AutocompleteField, AutocompleteFieldProps } from './AutocompleteField';

export type ComboBoxFieldProps<
  T,
  DisableClearable extends boolean | undefined
> = Omit<
  AutocompleteFieldProps<T, DisableClearable, false>,
  'onChange' | 'freeSolo' | 'renderInput'
>;

/**
 * An Autocomplete field setup to make a single-selection combobox
 */
export const ComboBoxField = forwardRef(function ComboBoxField<
  T,
  DisableClearable extends boolean | undefined
>(props: ComboBoxFieldProps<T, DisableClearable>, ref: Ref<any>) {
  return (
    <AutocompleteField<T, DisableClearable, false>
      ref={ref}
      {...props}
      freeSolo={false}
      disabled={props.disabled || props.loading}
    />
  );
}) as <T, DisableClearable extends boolean | undefined>(
  props: ComboBoxFieldProps<T, DisableClearable> & { ref?: Ref<any> }
) => ReactElement;
