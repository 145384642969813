import {
  FormControl,
  FormControlProps,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useHtmlId from '../utils/useHtmlId';

export type DataTableQuickFilterProps = FormControlProps &
  Pick<
    OutlinedInputProps,
    | 'name'
    | 'autoComplete'
    | 'autoFocus'
    | 'defaultValue'
    | 'disabled'
    | 'error'
    | 'onChange'
    | 'onFocus'
    | 'onKeyDown'
    | 'onKeyUp'
    | 'placeholder'
    | 'readOnly'
    | 'type'
    | 'value'
  > & {
    label?: ReactNode;
    InputProps?: OutlinedInputProps;
  };

/**
 * An outlined input for the data table's "quick filter"
 */
export const DataTableQuickFilter = (props: DataTableQuickFilterProps) => {
  const {
    id: idProp,
    label,
    autoComplete,
    name,
    autoFocus,
    defaultValue,
    disabled,
    error,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly,
    type,
    value,
    InputProps: InputPropsProps,
    ...other
  } = props;
  const { t } = useTranslation();
  const id = useHtmlId(name, idProp);

  const InputProps = {
    name,
    autoComplete,
    autoFocus,
    defaultValue,
    disabled,
    error,
    onChange,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly,
    type,
    value,
    ...InputPropsProps,
  };

  return (
    <FormControl variant="outlined" size="small" {...other}>
      <FormLabel sx={visuallyHidden} data-shrink="true" htmlFor={id}>
        {t('inputLabel.quickFilter')}
      </FormLabel>
      <OutlinedInput
        {...InputProps}
        id={id}
        inputProps={{
          type: 'search',
          ...InputProps.inputProps,
          placeholder: t('inputLabel.quickFilter'),
        }}
      />
    </FormControl>
  );
};
