import { DateTime } from 'luxon';
import { toServerTimestamp } from '../utils';
import { AnyComparator } from './comparators';

/**
 * DateTime value maping for makeFunction
 */
export function dateTimeFilterMap(
  value: DateTime | null,
  comparator: keyof AnyComparator<DateTime | null>
): string {
  return toServerTimestamp(value) ?? '';
}

/**
 * Date-mode DateTime value maping for makeFunction
 *
 * Alters timestamp to start/end of date for gt(e)/lt(e) comparators
 * @fixme Make dateTimestampRangeFilter handle the startOf/endOf day and deprecate this
 */
export function dateFilterMap(
  value: DateTime | null,
  comparator: keyof AnyComparator<DateTime | null>
): string {
  if (comparator === 'lte' || comparator === 'lt')
    return toServerTimestamp(value?.endOf?.('day')) ?? '';
  if (comparator === 'gte' || comparator === 'gt')
    return toServerTimestamp(value?.startOf?.('day')) ?? '';
  if (comparator === 'eq') return value?.toISODate() ?? '';

  return dateTimeFilterMap(value, comparator);
}
