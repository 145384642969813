import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { promiseCancelationPassthrough } from '../../utils/CancelablePromise';

export interface ApiPaginationOptions {
  /**
   * Maximum number of items that should be returned by the server (if the request supports continuations)
   */
  maxItemCount?: number;
}

export interface ApiPaginationContext {
  /**
   * Continuation header value to include
   */
  continuation?: string;
}

/**
 * Get the HTTP headers to include in paginated requests
 */
export function getPaginationHeaders(
  options: ApiPaginationOptions,
  context: ApiPaginationContext
): AxiosRequestHeaders {
  const { maxItemCount } = options;
  const { continuation } = context;

  const headers: AxiosRequestHeaders = {};

  if (maxItemCount) {
    headers['x-ms-max-item-count'] = String(maxItemCount);
    if (continuation) {
      headers['x-ms-continuation'] = continuation;
    }
  }

  return headers;
}

export function wrapPagination(
  continuations: WeakMap<any[], string>
): (
  queryPromise: Promise<AxiosResponse<any[]>>
) => Promise<AxiosResponse<any[]>> {
  return (queryPromise) => {
    return promiseCancelationPassthrough(queryPromise, (queryPromise) =>
      queryPromise.then((response) => {
        const continuation = response.headers['x-ms-continuation'];
        continuations.set(response.data, continuation);
        return response;
      })
    );
  };
}
