import { forwardRef } from 'react';
import { useCodes } from '../../../api';
import { useCodeOptionsProps } from '../../utils';
import { SelectField, SelectFieldProps } from '../SelectField';

export type CorporateRegistryFieldProps = Omit<SelectFieldProps, 'options'>;

/**
 * A <Select> field for selecting a corporate registry
 */
export const CorporateRegistryField = forwardRef(
  function CorporateRegistryField(
    props: CorporateRegistryFieldProps,
    ref: any
  ) {
    const { ...other } = props;
    const [corporateRegistries, loadingCorporateRegistries] = useCodes(
      'corporateregistries'
    );
    const optionProps = useCodeOptionsProps(corporateRegistries);

    return (
      <SelectField
        ref={ref}
        {...other}
        {...optionProps}
        loading={loadingCorporateRegistries}
      />
    );
  }
);
