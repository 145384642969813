import { useMemo } from 'react';
import { useCollator } from '../../../intl';

/**
 * Sort an options array by the option labels
 */
export function useSortedOptions<K extends string>(
  options: K[],
  getLabel: (value: K) => string
): K[] {
  const { compare } = useCollator();
  return useMemo(
    () => options.sort((x, y) => compare(getLabel(x), getLabel(y))),
    [compare, getLabel, options]
  );
}
